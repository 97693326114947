import React, { useEffect, useState } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { useCurrentSite, useTenantSites } from '../../auth/atoms'

export const DepartmentsAutocomplete = ({ setState, state, contentType }) => {
    const currentSite = useCurrentSite()
    const tenantSites = useTenantSites()

    const [departments, setDepartments] = useState<{ label: string; id: string }[]>([])
    const [value, setValue] = useState<{ label: string; id: string }[]>([])
    const [tagsAbort, setTagsAbort] = useState(new AbortController())

    useEffect(() => {
        if (!currentSite) return

        const newController = new AbortController()
        tagsAbort.abort()
        setTagsAbort(newController)
        if (currentSite && currentSite.Type === 'department') {
            setDepartments([{ label: currentSite.Name, id: currentSite.ID }])
            setValue([{ label: currentSite.Name, id: currentSite.ID }])
            setState((prev) => ({
                ...prev,
                departments: [currentSite.ID],
                currentPage: 0
            }))
            return
        }

        const result = tenantSites.reduce((acc: { label: string; id: string }[], iter) => {
            if (iter.Type === 'department' && Array.isArray(iter.Hosts) && iter.Hosts.includes(currentSite?.ID)) {
                acc.push({ label: iter.Name, id: iter.ID })
            }
            return acc
        }, [])
        setDepartments(result)
        state.departments && setValue(result.filter((d) => state.departments.includes(d.id)) || [])
    }, [currentSite])

    function onChange(e, selected) {
        setValue(selected)
        setState((prev) => ({
            ...prev,
            departments: selected?.map(({ id }) => id),
            currentPage: 0
        }))
    }

    return (
        <Autocomplete
            style={{ flex: '1' }}
            disabled={!currentSite || currentSite.Type === 'department'}
            onChange={onChange}
            multiple
            filterSelectedOptions
            options={departments}
            isOptionEqualToValue={(o, v) => o.id === v.id}
            getOptionLabel={(option) => option.label}
            value={value}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant='outlined'
                    label='Departments'
                    placeholder='Departments'
                    helperText='Search for content based on its department'
                />
            )}
        />
    )
}
