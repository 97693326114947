import { useStateWithStorage } from '../../../common/storage.service'
import useSettingsQueryMutation, { deleteSettingsQuery, SettingsSearchQueryParams } from './queries'
import { AddButton, ConfirmAction } from '../../../common/components'
import { useNavigate } from 'react-router-dom'
import { Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Select } from '@mui/material'
import SearchBar from '../../../common/components/SearchBar'
import { SingleSiteSelect } from '../../../common/components/selectors/SingleSiteSelect'
import { GridColDef } from '@mui/x-data-grid'
import { CellLine, CellWrapper, TwoLinesCell, useIDToName } from '../../grid/cells/GridCells'
import { useState } from 'react'
import { CustomMenu, CustomMenuItem } from '../../../common/components/custom-context-menu/CustomMenu'
import CreateIcon from '@mui/icons-material/Create'
import DeleteIcon from '@mui/icons-material/Delete'
import { MoreHoriz } from '@mui/icons-material'
import { useMutation } from '@tanstack/react-query'
import { notify } from '../../../helpers'
import PageContainerWithHeader from '../../../common/components/PageContainerWithHeader'
import { useAppNavigation } from '../../../app/useAppNavigation'
import { defaultPageQuery } from '../../../common/react-query'
import { DataGridBase } from '../../grid/DataGridBase'
import { getPrettySettingsType } from './types'
import SettingsTypeFilter from './SettingsTypeFilter'

const defaultQuery: SettingsSearchQueryParams = {
    ...defaultPageQuery,
    Sites: undefined,
    Search: '',
    Type: null,
    Public: null,
    IgnoreSiteID: true
}

function publicBooleanToLabel(_public: boolean | null | undefined): string {
    return _public === null || _public === undefined ? 'All' : _public === true ? 'Public' : 'Private'
}

function publicLabelToBoolean(_public: string): boolean | null {
    return _public === 'Public' ? true : _public === 'Private' ? false : null
}

function SiteName({ siteId }: { siteId: string }) {
    const name = useIDToName({ tableName: 'site', ID: siteId })
    return <>{name}</>
}

export function getPathToEditSettings(settingsId: string) {
    return `/system/settings/edit/${settingsId}`
}

function SettingsTable() {
    const [query, setQuery] = useStateWithStorage<SettingsSearchQueryParams>(
        'site-settings-custom-query-v2',
        defaultQuery
    )
    const { data, refetch } = useSettingsQueryMutation(query)

    const deleteGroupMutation = useMutation({
        mutationFn: (id: string) => deleteSettingsQuery(id)
    })

    const { navigateTo } = useAppNavigation()

    const columns: GridColDef[] = [
        {
            field: 'Name',
            headerName: 'Name',
            flex: 2,
            sortable: true,
            disableColumnMenu: true,
            renderCell: (params) => (
                <TwoLinesCell
                    l1={`${params.row.Name}`}
                    l2={params.row.ID}
                    path={getPathToEditSettings(params.row.ID)}
                />
            )
        },
        {
            field: 'Description',
            headerName: 'Description',
            flex: 1,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <CellWrapper>
                    <CellLine>{params.row.Description || '-'}</CellLine>
                </CellWrapper>
            )
        },
        {
            field: 'Sites',
            headerName: 'Sites',
            flex: 1,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                // Was unable to apply whiteSpace: normal to CellWrapper
                <Box sx={{ whiteSpace: 'normal' }}>
                    {params.row?.Sites?.length > 1 ? (
                        <>
                            <SiteName siteId={params.row.Sites[0]} /> ... + {params.row.Sites.length - 1} More
                        </>
                    ) : params.row?.Sites?.length == 1 ? (
                        <SiteName siteId={params.row.Sites[0]} />
                    ) : (
                        'District Wide'
                    )}
                </Box>
            )
        },
        {
            field: 'Type',
            headerName: 'Type',
            flex: 1,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <CellWrapper>
                    <CellLine>{getPrettySettingsType(params.row.Type)}</CellLine>
                </CellWrapper>
            )
        },
        {
            field: 'Public',
            headerName: 'Public',
            flex: 0.5,
            disableColumnMenu: true,
            filterable: false,
            renderCell: (params) => (
                <CellWrapper>
                    <CellLine>{params.row.Public ? 'Public' : 'Private'}</CellLine>
                </CellWrapper>
            )
        },
        {
            field: 'Menu',
            headerName: 'Actions',
            headerAlign: 'right',
            align: 'right',
            disableColumnMenu: true,
            filterable: false,
            width: 70,
            sortable: false,
            renderCell: (params) => {
                const [actionMenuAnchor, setActionMenuAnchor] = useState<HTMLElement | null>(null)
                const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false)

                function handleDeleteSetting() {
                    deleteGroupMutation.mutate(params.row.ID, {
                        onSuccess: () => {
                            notify(`Success. Setting ${params.row.Name} was deleted.`, 'info')
                            refetch()
                            setConfirmDeleteDialogOpen(false)
                            setActionMenuAnchor(null)
                        },
                        onError: () => {
                            notify(`Error. Setting ${params.row.Name} was not deleted.`, 'error')
                        }
                    })
                }

                return (
                    <CellWrapper style={{ textAlign: 'right' }}>
                        <IconButton
                            size={'small'}
                            aria-haspopup={'true'}
                            onClick={(e) => {
                                setActionMenuAnchor(e.currentTarget)
                            }}
                        >
                            <MoreHoriz />
                        </IconButton>
                        <CustomMenu
                            key={params.row.ID}
                            anchorElement={actionMenuAnchor}
                            onClose={() => setActionMenuAnchor(null)}
                        >
                            <CustomMenuItem
                                key={params.row.ID}
                                text={'Edit'}
                                onClick={() => {
                                    navigateTo(getPathToEditSettings(params?.row.ID))
                                }}
                            >
                                <CreateIcon />
                            </CustomMenuItem>
                            <CustomMenuItem text={'Delete'} onClick={() => setConfirmDeleteDialogOpen(true)}>
                                <DeleteIcon />
                            </CustomMenuItem>
                        </CustomMenu>
                        <ConfirmAction
                            open={confirmDeleteDialogOpen}
                            title={`Delete "${params?.row.Name}"`}
                            text={'Are you sure you want to delete these settings?'}
                            handleClose={() => setConfirmDeleteDialogOpen(false)}
                            handleDisagree={() => setConfirmDeleteDialogOpen(false)}
                            handleAgree={() => handleDeleteSetting()}
                        />
                    </CellWrapper>
                )
            }
        }
    ]

    return (
        <PageContainerWithHeader
            title='Settings'
            topRightElement={<AddButton title={`add settings`} func={() => navigateTo(`/system/settings/create`)} />}
        >
            <Grid container spacing={2}>
                <Grid item md={3.33}>
                    <SearchBar
                        value={query.Search || ''}
                        onChange={(val) => setQuery((p) => ({ ...p, Search: val, page: 1 }))}
                    />
                </Grid>
                <Grid item md={3.33}>
                    <SettingsTypeFilter
                        value={query.Type || []}
                        onChange={(type) => {
                            setQuery((p) => ({ ...p, Type: type, page: 1 }))
                        }}
                    />
                </Grid>
                <Grid item md={3.33}>
                    <SingleSiteSelect
                        // TODO: Use multi site selector with district wide option
                        initialValue={query?.Sites?.[0] || ''}
                        allSitesOption
                        onChange={(site) => {
                            setQuery((prev) => ({
                                ...prev,
                                Sites: site == 'ALL' ? null : !!site ? [site] : null || null,
                                IgnoreSiteID: !site,
                                page: 1
                            }))
                        }}
                        siteType={'site'}
                    />
                </Grid>
                <Grid item md={2}>
                    <FormControl sx={{ width: '100%' }}>
                        <InputLabel>Public</InputLabel>
                        <Select
                            label={'Public'}
                            value={publicBooleanToLabel(query.Public)}
                            onChange={(e) =>
                                setQuery({
                                    ...query,
                                    Public: publicLabelToBoolean(e.target.value),
                                    page: 1
                                })
                            }
                        >
                            <MenuItem value={'All'}>All</MenuItem>
                            <MenuItem value={'Public'}>Public</MenuItem>
                            <MenuItem value={'Private'}>Private</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item md={12}>
                    <Button
                        style={{ marginTop: '0.25rem', float: 'right' }}
                        onClick={() => {
                            setQuery(defaultQuery)
                        }}
                    >
                        Reset filters
                    </Button>
                </Grid>
                <Grid item md={12}>
                    {data && (
                        <DataGridBase
                            disableColumnFilter
                            disableDensitySelector
                            disableColumnSelector
                            columns={columns}
                            state={data}
                            setQuery={setQuery}
                        />
                    )}
                </Grid>
            </Grid>
        </PageContainerWithHeader>
    )
}

export default SettingsTable
