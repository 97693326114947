import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { useEffect, useState } from 'react'
import { PublishPeriod } from '../../types'
import { getCurrentDate } from '../../../../common/components/dates/helpers'
import { getPublishStatus, publishStatus } from '../ContentEditorSaveCard'

export type PublishOptionsRadioOptions = 'publish' | 'save as draft' | ''

function getPublishOption(publishStatus: publishStatus) {
    if (publishStatus == 'published') {
        return 'publish'
    } else if (publishStatus == 'draft') {
        return 'save as draft'
    }
    return ''
}

interface PublishOptionsRadioProps {
    value: PublishPeriod
    onChange: (value: PublishPeriod) => void

    // can be set by content editor because of permissions or scheduled/expiry publish status
    disabled?: boolean
}

export default function PublishOptionsRadio({ value, onChange, disabled }: PublishOptionsRadioProps) {
    const [radioValue, setRadioValue] = useState<PublishOptionsRadioOptions>(
        getPublishOption(getPublishStatus(value.PublishAt, value.ExpireAt))
    )

    const [publishPeriodDisabled, setPublishPeriodDisabled] = useState(disabled)

    const publishStatus = getPublishStatus(value.PublishAt, value.ExpireAt)

    useEffect(() => {
        if (publishStatus == 'expired' || publishStatus == 'scheduled') {
            setPublishPeriodDisabled(true)
        } else {
            setPublishPeriodDisabled(false)
        }

        setRadioValue(getPublishOption(getPublishStatus(value.PublishAt, value.ExpireAt)))
    }, [publishStatus])

    return (
        <FormControl
            variant='standard'
            component='fieldset'
            style={{ width: '100%' }}
            disabled={disabled || publishPeriodDisabled}
        >
            <RadioGroup
                name='Publish Options'
                aria-disabled={disabled || publishPeriodDisabled}
                value={radioValue}
                sx={{
                    display: 'flex',
                    flexDirection: 'row'
                }}
                onChange={(ev) => {
                    if (ev.target.value == 'publish') {
                        onChange({
                            PublishAt: getCurrentDate().toDate(),
                            ExpireAt: null
                        })
                    } else if (ev.target.value == 'save as draft') {
                        onChange({
                            PublishAt: null,
                            ExpireAt: null
                        })
                    }
                }}
            >
                <FormControlLabel value='publish' control={<Radio />} label='Publish' />
                <FormControlLabel value='save as draft' control={<Radio />} label='Save as Draft' />
            </RadioGroup>
        </FormControl>
    )
}
