import { topics, TopicsQuery } from './types'
import { useQuery } from '@tanstack/react-query'
import { baseQueryConfig } from '../../../common/react-query'
import { httpGet } from '../../../common/client'
import { TopicsAPI } from '../../../common/constants'

export const useTopicsQuery = (q: TopicsQuery) => {
    return useQuery({
        ...baseQueryConfig,
        queryKey: ['topics-check', q],
        queryFn: async () => httpGet(`${TopicsAPI}`, q, topics)
    })
}
