import { z } from 'zod'
import { sharable } from '../types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { httpGet, httpPut } from '../../../common/client'
import { baseQueryConfig } from '../../../common/react-query'
import { BASE } from '../../../common/constants'

const distributed_page = z
    .object({
        Content: z.string()
    })
    .merge(sharable)

export const useDistributedPageQuery = ({ parentID, siteID }: { parentID: string; siteID: string }) => {
    const key = ['distributed-page', parentID, siteID]

    const client = useQueryClient()

    const read = useQuery({
        ...baseQueryConfig,
        queryKey: key,
        queryFn: async () =>
            httpGet(`${BASE}/api/v2/content/distributed-page/${parentID}/${siteID}`, {}, distributed_page)
    })

    const upsert = useMutation({
        mutationFn: async (data: z.infer<typeof distributed_page>) =>
            httpPut(`${BASE}/api/v2/content/distributed-page/${parentID}/${siteID}`, data),
        onSuccess: () => client.invalidateQueries(key)
    })

    return { read, upsert }
}
