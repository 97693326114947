import { FormControlLabel, Grid, Switch } from '@mui/material'
import { TagsSelector } from './selectors/TagsSelector'
import { EditorsSelector } from './selectors/EditorsSelector'
import Button from '@mui/material/Button'
import React, { useEffect, useState } from 'react'
import { storage } from '../../common/storage.service'
import { DepartmentsAutocomplete } from './selectors/DepartmentsAutocomplete'
import { StatusSelector } from '../content/BaseForm'
import SearchBar from '../../common/components/SearchBar'
import { DelayedSearchTimeout } from '../../common/constants'
import { ContentPublishedTypes } from '../content/types'
import { useAppContext } from '@/pkgs/auth/atoms'
import { TagType } from '@/pkgs/system/tags/types'

export const ManagerFilters = ({ paginationState, setPaginationState, contentType, resetFormCallback }) => {
    const appContext = useAppContext()
    const [pageSearchTerm, setPageSearchTerm] = useState<string | null>(null)
    const pageTypeKey = `${appContext.tenantID}-${contentType}-page-type`
    const inactiveKey = `${appContext.tenantID}-${contentType}-inactive`

    useEffect(() => {
        const pageType = storage.getItem(pageTypeKey)
        if (pageType) {
            handleChangePublishedView(pageType)
        }

        const inactive = storage.getItem(inactiveKey)
        if (inactive) {
            setPaginationState((prev) => ({ ...prev, deleted: inactive }))
        }
    }, [])

    useEffect(() => {
        if (pageSearchTerm === null) return
        const timeout = setTimeout(() => {
            setPaginationState((prev) => ({
                ...prev,
                searchTerm: pageSearchTerm,
                currentPage: 0
            }))
        }, DelayedSearchTimeout)

        return () => {
            clearTimeout(timeout)
        }
    }, [pageSearchTerm])

    const handleChangePublishedView = (pageType) => {
        if (Object.entries(ContentPublishedTypes).some((x) => x[1] === pageType)) {
            storage.setItem(pageTypeKey, pageType)
            setPaginationState((prev) => ({
                ...prev,
                publishedState: pageType,
                currentPage: 0
            }))
        }
    }

    const reset = () => {
        resetFormCallback()
        setPageSearchTerm('')
    }

    return (
        <Grid container spacing={2} marginBottom='1em'>
            <Grid item xs={3}>
                <SearchBar
                    value={pageSearchTerm !== null ? pageSearchTerm : paginationState.searchTerm || ''}
                    onChange={(val) => setPageSearchTerm(val)}
                    helperText='Search by title'
                />
            </Grid>
            <Grid item xs={3}>
                <TagsSelector
                    value={paginationState?.tags || []}
                    onChange={(tags) => setPaginationState((p) => ({ ...p, tags }))}
                    tagType={TagType.Content}
                />
            </Grid>
            <Grid item xs={3}>
                <EditorsSelector state={paginationState} setState={setPaginationState} contentType={contentType} />
            </Grid>
            <Grid item xs={3}>
                <DepartmentsAutocomplete
                    state={paginationState}
                    setState={setPaginationState}
                    contentType={contentType}
                />
            </Grid>
            <Grid item xs={3}>
                <StatusSelector
                    value={paginationState.status || ''}
                    onChange={(v) => {
                        setPaginationState((prev) => ({
                            ...prev,
                            status: v,
                            currentPage: 0
                        }))
                    }}
                />
            </Grid>
            <Grid item xs={2}>
                <FormControlLabel
                    disabled={appContext.isCurrentSiteDepartment()}
                    value='start'
                    control={
                        <Switch
                            checked={paginationState.siteOnly || false}
                            onChange={(e) =>
                                setPaginationState((prev) => {
                                    const inactive = e.target.checked
                                    storage.setItem(inactiveKey, inactive)
                                    return {
                                        ...prev,
                                        currentPage: 0,
                                        siteOnly: inactive
                                    }
                                })
                            }
                            color='secondary'
                        />
                    }
                    label='Site Only'
                    labelPlacement='start'
                />
            </Grid>
            <Grid item xs={2}>
                <FormControlLabel
                    value='start'
                    control={
                        <Switch
                            checked={paginationState.inactive || false}
                            onChange={(e) =>
                                setPaginationState((prev) => {
                                    const inactive = e.target.checked
                                    storage.setItem(inactiveKey, inactive)
                                    return {
                                        ...prev,
                                        currentPage: 0,
                                        inactive: inactive
                                    }
                                })
                            }
                            color='secondary'
                        />
                    }
                    label='Deleted'
                    labelPlacement='start'
                />
            </Grid>
            <Grid item xs={5} display='flex' justifyContent='flex-end'>
                <Button onClick={reset} color={'primary'} style={{ marginLeft: 'auto' }}>
                    Reset Filters
                </Button>
            </Grid>
        </Grid>
    )
}
