import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
    $getNodeByKey,
    $getSelection,
    $isRangeSelection,
    $isRootOrShadowRoot,
    CAN_REDO_COMMAND,
    CAN_UNDO_COMMAND,
    REDO_COMMAND,
    SELECTION_CHANGE_COMMAND,
    UNDO_COMMAND
} from 'lexical'
import { $findMatchingParent, $getNearestNodeOfType, mergeRegister } from '@lexical/utils'
import { $isListNode, ListNode } from '@lexical/list'
import { $isHeadingNode } from '@lexical/rich-text'
import { BlockOptionsDropdownList, getBlockTypeIcon, getBlockTypeLabel } from './BlockOptionsDropdownList'
import { FloatingLinkEditor } from '../Link/FloatingLinkEditor'
import { getSelectedNode, LowPriority } from './toolbar.helpers'
import { Box, IconButton, Typography } from '@mui/material'
import { AllTools, Divider, parseToolbarOptions } from './Tool'
import { InsertOptionsDropdownList } from './InsertOptionsDropdownList'
import { InsertTableDialog } from '../Table/InsertTableDialog'
import { InsertImageDialog } from '../Image/InsertImageDialog'
import { ContentExplorerDialog } from '@/pkgs/content/explorer/ContentExplorerDialog'
import { insertContentFragment } from '../ContentFragment/ContentFragmentPlugin'
import { insertCollapsible } from '../Collapsible/CollapsiblePlugin'
import { $isLinkNode } from '../Link/LinkNode'
import { insertMonacoCodeEditor } from '../MonacoCodeEmbed/MonacoCodeEmbedPlugin'
import { $isMonacoCodeEmbedNode, MonacoCodeEmbedNode } from '../MonacoCodeEmbed/MonacoCodeEmbedNode'
import { colours } from '../../../common/colours'
import { $isCodeNode, CODE_LANGUAGE_MAP, CodeNode } from '@lexical/code'
import CodeLanguagesDropdownList from './CodeLanguagesDropdownList'
import { $isTableNode } from '../Table/TablePlugin'
import { ContentType } from '../../content/types'

export const blockTypeToBlockName: Record<string, string> = {
    h2: 'H2',
    h3: 'H3',
    h4: 'H4',
    h5: 'H5',
    h6: 'H6',
    ol: 'Numbered List',
    paragraph: 'Normal',
    quote: 'Quote',
    ul: 'Bulleted List',
    [MonacoCodeEmbedNode.getType()]: 'HTML',
    [CodeNode.getType()]: 'Formatted Code'
}

export type BlockType = keyof typeof blockTypeToBlockName

const supportedBlockTypes = new Set(Object.keys(blockTypeToBlockName))

const rootTypeToRootName = {
    root: 'Root',
    table: 'Table'
}

function Container({ children }: { children: ReactNode }) {
    return (
        <Box
            className='toolbar'
            sx={{
                '& .material-icons-outlined': {
                    fontSize: '18px'
                },
                flexWrap: 'wrap',
                borderBottom: `1px solid ${colours.off_white_but_darker}`
            }}
        >
            <>{children}</>
        </Box>
    )
}

/** Toolbar
 * - Base implementation of toolbar with configurable tool options.
 * - Redo and Undo are unconfigurable.
 * - BlockOptionsDropdown is currently unconfigurable from the template level. this can be revisited if needed (mostly modification of supportedBlockTypes)
 * - ToolbarButtonGroups implements groupings of ToolbarButtons - they are treated as mutually exclusive, a toolbar can't contain (e.g) the single 'bold' and the group 'format' (refer to validateToolbarOptions func)
 * */
interface ToolbarProps {
    toolbarOptions?: string[]
    disabled?: boolean
}

export function Toolbar({ toolbarOptions, disabled }: ToolbarProps) {
    const [editor] = useLexicalComposerContext()
    const insertButtonRef = useRef(null)
    const formatButtonRef = useRef(null)
    const [options, setOptions] = useState({
        isLink: false,
        isBold: false,
        canUndo: false,
        canRedo: false,
        isItalic: false,
        isUnderline: false,
        blockType: 'paragraph',
        isStrikethrough: false,
        disabled: disabled || false
    })
    const [canUndo, setCanUndo] = useState(false)
    const [canRedo, setCanRedo] = useState(false)
    const [blockType, setBlockType] = useState<BlockType>('paragraph')
    const isCodeEmbedBlock = blockType == MonacoCodeEmbedNode.getType()
    const [rootType, setRootType] = useState<keyof typeof rootTypeToRootName>('root')
    const [selectedElementKey, setSelectedElementKey] = useState<string | null>(null)

    const [showBlockOptionsDropDown, setShowBlockOptionsDropDown] = useState(false)
    const [isInsertDropdownVisible, setIsInsertDropdownVisible] = useState(false)
    const [isInsertTableDialogOpen, setIsInsertTableDialogOpen] = useState(false)
    const [contentExplorerDialogIsOpen, setContentExplorerDialogIsOpen] = useState(false)

    const toolbarKeys = useMemo(() => parseToolbarOptions(toolbarOptions), [toolbarOptions])
    const ToolBelt = useMemo(
        () =>
            toolbarKeys
                .map((tool, index) => {
                    const ToolComponent = AllTools[tool]?.(editor, options)
                    return ToolComponent ? React.cloneElement(ToolComponent, { key: `tool-${tool}-${index}` }) : null
                })
                .filter(Boolean),
        [toolbarKeys, editor, options]
    )

    const [codeLanguage, setCodeLanguage] = useState<string>('')

    const updateToolbar = useCallback(() => {
        const selection = $getSelection()
        if ($isMonacoCodeEmbedNode(selection?.getNodes()?.[0])) {
            setBlockType(MonacoCodeEmbedNode.getType())
            setIsInsertDropdownVisible(false)
            setIsInsertTableDialogOpen(false)
            setShowBlockOptionsDropDown(false)
            setContentExplorerDialogIsOpen(false)
        } else if (!supportedBlockTypes.has(selection?.getNodes()?.[0].getType() || '')) {
            // some node that is not a rangeSelection is selected. Reset blockType / toolbar to default
            setBlockType('paragraph')
        }

        if (!$isRangeSelection(selection)) {
            return
        }
        const anchorNode = selection.anchor.getNode()
        let element =
            anchorNode.getKey() === 'root'
                ? anchorNode
                : $findMatchingParent(anchorNode, (e) => {
                      const parent = e.getParent()
                      return parent !== null && $isRootOrShadowRoot(parent)
                  })

        if (element === null) {
            element = anchorNode.getTopLevelElementOrThrow()
        }

        const elementKey = element.getKey()
        const elementDOM = editor.getElementByKey(elementKey)
        if (elementDOM !== null) {
            setSelectedElementKey(elementKey)
            if ($isListNode(element)) {
                const parentList = $getNearestNodeOfType(anchorNode, ListNode)
                const type = parentList ? parentList.getTag() : element.getTag()
                setBlockType(type)
            } else {
                const type = $isHeadingNode(element) ? element.getTag() : element.getType()
                if (type in blockTypeToBlockName) {
                    setBlockType(type as keyof typeof blockTypeToBlockName)
                }

                if ($isCodeNode(element)) {
                    const language = element.getLanguage() as keyof typeof CODE_LANGUAGE_MAP
                    setCodeLanguage(language ? CODE_LANGUAGE_MAP[language] || language : '')
                    return
                }
            }
        }
        const node = getSelectedNode(selection)
        const parent = node.getParent()
        const tableNode = $findMatchingParent(node, $isTableNode)
        if ($isTableNode(tableNode)) {
            setRootType('table')
        } else {
            setRootType('root')
        }

        // Update text format
        setOptions((p) => ({
            ...p,
            blockType: 'paragraph',
            isLink: $isLinkNode(parent) || $isLinkNode(node),
            isBold: selection.hasFormat('bold'),
            isItalic: selection.hasFormat('italic'),
            isUnderline: selection.hasFormat('underline'),
            isStrikethrough: selection.hasFormat('strikethrough')
        }))
    }, [editor])

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    updateToolbar()
                })
            }),
            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                (_payload, newEditor) => {
                    // const selection = $getSelection()
                    // console.log('selection', selection)
                    updateToolbar()
                    return true
                },
                LowPriority
            ),
            editor.registerCommand(
                CAN_UNDO_COMMAND,
                (payload) => {
                    setCanUndo(payload)
                    return true
                },
                LowPriority
            ),
            editor.registerCommand(
                CAN_REDO_COMMAND,
                (payload) => {
                    setCanRedo(payload)
                    return true
                },
                LowPriority
            )
        )
    }, [editor, updateToolbar])

    const [isMediaGalleryOpen, setIsMediaGalleryOpen] = useState(false)

    const insertTools = useMemo(() => {
        if (!toolbarOptions?.length || toolbarOptions?.includes('insert')) {
            return [true, true, true, true]
        }
        return [
            toolbarOptions?.includes('horizontal_rule'),
            toolbarOptions?.includes('image'),
            toolbarOptions?.includes('table'),
            toolbarOptions?.includes('video')
        ]
    }, [toolbarOptions])

    const onCodeLanguageSelect = useCallback(
        (value: string) => {
            editor.update(() => {
                if (selectedElementKey !== null) {
                    const node = $getNodeByKey(selectedElementKey)
                    if ($isCodeNode(node)) {
                        node.setLanguage(value)
                    }
                }
            })
        },
        [editor, selectedElementKey]
    )

    if (isCodeEmbedBlock) {
        return (
            <Container>
                <button
                    disabled={!canUndo}
                    onClick={() => editor.dispatchCommand(UNDO_COMMAND, undefined)}
                    className='toolbar-item spaced'
                    aria-label='Undo'
                >
                    <span className='material-icons-outlined'>undo</span>
                </button>
                <button
                    disabled={!canRedo}
                    onClick={() => editor.dispatchCommand(REDO_COMMAND, undefined)}
                    className='toolbar-item'
                    aria-label='Redo'
                >
                    <span className='material-icons-outlined'>redo</span>
                </button>
            </Container>
        )
    }

    if (blockType == CodeNode.getType()) {
        return (
            <Container>
                <button
                    disabled={!canUndo}
                    onClick={() => editor.dispatchCommand(UNDO_COMMAND, undefined)}
                    className='toolbar-item spaced'
                    aria-label='Undo'
                >
                    <span className='material-icons-outlined'>undo</span>
                </button>
                <button
                    disabled={!canRedo}
                    onClick={() => editor.dispatchCommand(REDO_COMMAND, undefined)}
                    className='toolbar-item'
                    aria-label='Redo'
                >
                    <span className='material-icons-outlined'>redo</span>
                </button>
                {supportedBlockTypes.has(blockType) && (
                    <>
                        <IconButton
                            ref={formatButtonRef}
                            className='toolbar-item block-controls'
                            disabled={disabled}
                            onClick={() => {
                                setShowBlockOptionsDropDown(!showBlockOptionsDropDown)
                                setIsInsertDropdownVisible(false)
                            }}
                            aria-label='Formatting Options'
                        >
                            {getBlockTypeIcon(blockType)}
                            <Typography className='text'>{getBlockTypeLabel(blockType)}</Typography>
                            <span className='material-icons-outlined'>expand_more</span>
                        </IconButton>

                        {showBlockOptionsDropDown && (
                            <BlockOptionsDropdownList
                                editor={editor}
                                blockType={blockType}
                                buttonRef={formatButtonRef}
                                setShowBlockOptionsDropDown={setShowBlockOptionsDropDown}
                            />
                        )}
                        <Divider />
                        <CodeLanguagesDropdownList value={codeLanguage} onChange={onCodeLanguageSelect} />
                    </>
                )}
            </Container>
        )
    }

    return (
        <Container>
            <button
                disabled={!canUndo}
                onClick={() => editor.dispatchCommand(UNDO_COMMAND, undefined)}
                className='toolbar-item spaced'
                aria-label='Undo'
            >
                <span className='material-icons-outlined'>undo</span>
            </button>
            <button
                disabled={!canRedo}
                onClick={() => editor.dispatchCommand(REDO_COMMAND, undefined)}
                className='toolbar-item'
                aria-label='Redo'
            >
                <span className='material-icons-outlined'>redo</span>
            </button>
            <Divider />

            {supportedBlockTypes.has(blockType) && (
                <>
                    <IconButton
                        ref={formatButtonRef}
                        className='toolbar-item block-controls'
                        disabled={disabled}
                        onClick={() => {
                            setShowBlockOptionsDropDown(!showBlockOptionsDropDown)
                            setIsInsertDropdownVisible(false)
                        }}
                        aria-label='Formatting Options'
                    >
                        {getBlockTypeIcon(blockType)}
                        <Typography className='text'>{getBlockTypeLabel(blockType)}</Typography>
                        <span className='material-icons-outlined'>expand_more</span>
                    </IconButton>
                    {showBlockOptionsDropDown && (
                        <BlockOptionsDropdownList
                            editor={editor}
                            blockType={blockType}
                            buttonRef={formatButtonRef}
                            setShowBlockOptionsDropDown={setShowBlockOptionsDropDown}
                        />
                    )}
                    <Divider />
                </>
            )}
            {insertTools.some(Boolean) && (
                <>
                    <button
                        ref={insertButtonRef}
                        className='toolbar-item block-controls'
                        disabled={disabled}
                        onClick={() => {
                            setIsInsertDropdownVisible(!isInsertDropdownVisible)
                            setShowBlockOptionsDropDown(false)
                        }}
                        aria-label='Insert Node Options'
                    >
                        <span className='icon block-type material-icons-outlined'>add</span>
                        <span className='text' style={{ width: '40px' }}>
                            Insert
                        </span>
                        <span className='material-icons-outlined'>expand_more</span>
                    </button>
                    <Divider />
                </>
            )}
            {isInsertDropdownVisible && (
                <InsertOptionsDropdownList
                    editor={editor}
                    blockType={blockType}
                    buttonRef={insertButtonRef}
                    toolbar={insertTools}
                    closeDropdown={() => setIsInsertDropdownVisible(false)}
                    tableButtonOnClick={() => setIsInsertTableDialogOpen(true)}
                    imageButtonOnClick={() => setIsMediaGalleryOpen(true)}
                    contentFragmentButtonOnClick={() => setContentExplorerDialogIsOpen(true)}
                    collapsibleButtonOnClick={() => {
                        insertCollapsible(editor)
                    }}
                    codeEmbedButtonOnClick={() => insertMonacoCodeEditor(editor, '')}
                />
            )}

            <InsertImageDialog
                activeEditor={editor}
                isOpen={isMediaGalleryOpen}
                onClose={() => setIsMediaGalleryOpen(false)}
            />
            {isInsertTableDialogOpen && (
                <InsertTableDialog
                    activeEditor={editor}
                    isOpen={isInsertTableDialogOpen}
                    onClose={() => setIsInsertTableDialogOpen(false)}
                />
            )}
            <ContentExplorerDialog
                isOpen={contentExplorerDialogIsOpen}
                onClose={() => setContentExplorerDialogIsOpen(false)}
                contentTypes={[ContentType.Fragment]}
                sites={[]}
                onSelect={(id, content) => {
                    insertContentFragment(editor, content)
                    setContentExplorerDialogIsOpen(false)
                }}
                structureID={null}
            />
            {ToolBelt}
            {options.isLink && <FloatingLinkEditor />}
        </Container>
    )
}
