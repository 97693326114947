import React, { useEffect } from 'react'
import { GridColDef } from '@mui/x-data-grid'
import { guessErrorMessage } from '../../../helpers/guessErrorMessage'
import Button from '@mui/material/Button'
import { useAppContext, useCurrentSite } from '../../auth/atoms'
import { TagsSelector } from '../../grid/selectors/TagsSelector'
import { Content, ContentType } from '../types'
import { CustomMenuItem } from '../../../common/components/custom-context-menu/CustomMenu'
import CreateIcon from '@mui/icons-material/Create'
import DeleteIcon from '@mui/icons-material/Delete'
import { FragmentEditor } from './FragmentEditor'
import { StructureSelector } from '../../structure/StructureSelector'
import { AddButton } from '../../../common/components'
import { httpDelete, httpPatch } from '../../../common/client'
import { notify } from '../../../helpers'
import { Preview, RestoreFromTrash } from '@mui/icons-material'
import { sitesEventChanel } from '../../../common/components/selectors/SiteSelectorForAccount'
import moment from 'moment'
import { ContentQueryParams, useContentQuery } from '../queries'
import PageContainerWithHeader from '../../../common/components/PageContainerWithHeader'
import SearchBar from '../../../common/components/SearchBar'
import { FormControlLabel, Grid, Switch } from '@mui/material'
import { StatusSelector } from '../BaseForm'
import { useStateWithStorage } from '../../../common/storage.service'
import {
    IDToNameCell,
    MenuLightCell,
    NullableDateCell,
    PublishPeriodCell,
    TwoLinesCell
} from '../../grid/cells/GridCells'
import { DataGridBase } from '../../grid/DataGridBase'
import { BASE } from '../../../common/constants'
import { defaultPageQuery } from '../../../common/react-query'
import { TagType } from '../../system/tags/types'

export const FragmentsGrid: React.FC = () => {
    const currentSite = useCurrentSite()
    const appContext = useAppContext()

    const defaultQuery: ContentQueryParams = {
        ...defaultPageQuery,
        ContentTypes: [ContentType.Fragment],
        Sites: appContext.getDefaultSitesForSelectors(),
        StructureID: null
    }
    useEffect(() => {
        setQuery({ ...query, Sites: appContext.getDefaultSitesForSelectors() })
    }, [currentSite?.ID])

    const [query, setQuery] = useStateWithStorage<ContentQueryParams>('fragments-grid-query', defaultQuery)
    const results = useContentQuery(query)

    const [selectedContent, setSelectedContent] = React.useState<string | undefined>(undefined)
    const [openEditor, setOpenEditor] = React.useState(false)
    const [filterTags, setFilterTags] = React.useState<{ tags: string[]; page: number }>({ tags: [], page: 0 })

    useEffect(() => {
        setQuery({ ...query, Tags: filterTags.tags })
    }, [filterTags])

    useEffect(() => {
        setQuery({ ...query, page: 1 })
    }, [query.Search, query.ContentTypes, query.Sites, query.Tags])

    const deleteContent = (id: string) => {
        if (!confirm('Are you sure you want to delete this fragment?')) return
        httpDelete(`${BASE}/api/v2/content/${id}`)
            .then(() => {
                results.refetch()
            })
            .catch((err) => {
                notify(guessErrorMessage(err), 'error')
            })
    }

    const restoreContent = (id: string) => {
        httpPatch(`${BASE}/api/v2/content/${id}/restore`, null)
            .then(() => {
                results.refetch()
            })
            .catch((err) => {
                notify(guessErrorMessage(err), 'error')
            })
    }

    const menuItems = (content: Content) => {
        return (onClose: () => void) => {
            return content.Active
                ? [
                      <CustomMenuItem
                          key={'Preview'}
                          onClick={() => {
                              window.open(`${BASE}/api/v1/fragments/${content.ID}/compile`, '_blank')
                              onClose()
                          }}
                          text={'Preview'}
                      >
                          <Preview />
                      </CustomMenuItem>,
                      <CustomMenuItem
                          key={'edit'}
                          onClick={() => {
                              setSelectedContent(content.ID)
                              setOpenEditor(true)
                              onClose()
                          }}
                          text={'Edit'}
                      >
                          <CreateIcon />
                      </CustomMenuItem>,
                      <CustomMenuItem
                          key={'delete'}
                          onClick={() => {
                              deleteContent(content.ID)
                              onClose()
                          }}
                          text={'Delete'}
                      >
                          <DeleteIcon />
                      </CustomMenuItem>
                  ]
                : [
                      <CustomMenuItem
                          key={'delete'}
                          onClick={() => {
                              restoreContent(content.ID)
                              onClose()
                          }}
                          text={'Restore'}
                      >
                          <RestoreFromTrash />
                      </CustomMenuItem>
                  ]
        }
    }

    const columns: GridColDef[] = [
        {
            field: 'Title',
            headerName: 'Title',
            flex: 2,
            filterable: false,
            sortable: true,
            renderCell: (params) => <TwoLinesCell l1={`${params.row.Title}`} l2={params.row.ID} />
        },
        {
            field: 'Updated',
            headerName: 'Updated',
            width: 200,
            filterable: false,
            sortable: true,
            renderCell: (params) => {
                const date = moment(params.row.Updated || params.row.Created)
                    .format('YYYY-MM-DD, h:mm:ss a')
                    ?.split(',')
                return <TwoLinesCell l1={date[0]} l2={date[1]} />
            }
        },
        {
            field: 'StructureID',
            headerName: 'Structure',
            width: 300,
            filterable: false,
            sortable: false,
            renderCell: (params) => <IDToNameCell tableName={'structure'} ID={params.row.StructureID} />
        },
        {
            field: 'PublishPeriod',
            headerName: 'Status',
            width: 250,
            sortable: false,
            filterable: false,
            renderCell: (params) => (
                <PublishPeriodCell publishAt={params.row.PublishAt} expireAt={params.row.ExpireAt} />
            )
        },
        {
            field: 'publish_at',
            headerName: 'Publish At',
            flex: 1,
            sortable: true,
            filterable: false,
            renderCell: (params) => <NullableDateCell row={params.row} fieldName={'PublishAt'} />
        },
        {
            field: 'expire_at',
            headerName: 'Expire At',
            flex: 1,
            sortable: true,
            filterable: false,
            renderCell: (params) => <NullableDateCell row={params.row} fieldName={'ExpireAt'} />
        },
        {
            field: 'Menu',
            headerName: '...',
            width: 80,
            sortable: false,
            filterable: false,
            renderCell: (params) => <MenuLightCell itemsFactory={menuItems(params.row)} />
        }
    ]

    return (
        <PageContainerWithHeader
            title={'Fragments'}
            topRightElement={
                <AddButton
                    title={'Add Fragment'}
                    func={() => {
                        setSelectedContent(undefined)
                        setOpenEditor(true)
                    }}
                />
            }
        >
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <SearchBar value={query.Search || ''} onChange={(val) => setQuery({ ...query, Search: val })} />
                </Grid>
                <Grid item xs={4}>
                    <StructureSelector
                        value={query.StructureID}
                        onChange={(v) => setQuery({ ...query, StructureID: v })}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TagsSelector
                        value={filterTags?.tags || []}
                        onChange={(tags) => setFilterTags((p) => ({ ...p, tags }))}
                        tagType={TagType.Content}
                    />
                </Grid>
                <Grid item xs={4}>
                    <StatusSelector value={query.Status} onChange={(v) => setQuery({ ...query, Status: v, page: 1 })} />
                </Grid>
                <Grid item xs={2}>
                    <FormControlLabel
                        value='start'
                        control={
                            <Switch
                                checked={query.SiteOnly || false}
                                onChange={(e) =>
                                    setQuery({ ...query, SiteOnly: e.target.checked, Status: '', page: 1 })
                                }
                                color='secondary'
                            />
                        }
                        label='Site Only'
                        labelPlacement='start'
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormControlLabel
                        value='start'
                        control={
                            <Switch
                                checked={query.Inactive || false}
                                onChange={(e) =>
                                    setQuery({ ...query, Inactive: e.target.checked, Status: '', page: 1 })
                                }
                                color='secondary'
                            />
                        }
                        label='Deleted'
                        labelPlacement='start'
                    />
                </Grid>
                <Grid item xs={4}>
                    <Button
                        style={{ marginTop: '0.25rem', float: 'right' }}
                        onClick={() => {
                            setQuery(defaultQuery)
                            setFilterTags({ tags: [], page: 0 })
                        }}
                    >
                        Reset filters
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    {results.isLoading && <div>Loading...</div>}
                    {results.error && <div>Error: {guessErrorMessage(results.error)}</div>}
                    {results.data && (
                        <DataGridBase
                            // rowHeight={100}
                            columns={columns}
                            state={results.data}
                            setQuery={setQuery}
                        />
                    )}
                </Grid>
            </Grid>
            {openEditor && (
                <FragmentEditor
                    id={selectedContent}
                    open={openEditor}
                    onClose={() => {
                        setOpenEditor(false)
                        results.refetch()
                    }}
                    onSuccessfulSave={() => results.refetch()}
                />
            )}
        </PageContainerWithHeader>
    )
}
