import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { entityScope } from '../entityScope'
import { identityAtom, useAppContext } from '../atoms'
import { useAtom } from 'jotai'
import { Button } from '@mui/material'

export function RequiresAuth({
    entityScope,
    children
}: {
    entityScope?: entityScope | entityScope[]
    children: React.ReactElement
}) {
    const [errorMessage, setErrorMessage] = React.useState('')
    const evaluators = useAppContext()
    const navigate = useNavigate()
    const [identity, setIdentity] = useAtom(identityAtom)

    useEffect(() => {
        if (!entityScope || evaluators.currentSite() === undefined) {
            setErrorMessage('')
            return
        }
        if (Array.isArray(entityScope)) {
            if (!entityScope.some((entityScope) => evaluators.entityScopeAny(entityScope))) {
                console.log(evaluators.identity())
                setErrorMessage(`You are not allowed to access this resource: ${entityScope}`)
                return
            }
        } else {
            if (!evaluators.entityScopeAny(entityScope)) {
                console.log(evaluators.identity())
                setErrorMessage(`You are not allowed to access this resource: ${entityScope}`)
                return
            }
        }
        setErrorMessage('')
    }, [evaluators])

    // Test by dealing with 403 from server (middleware)
    if (!evaluators.isValid() && evaluators.identity()) {
        const account = evaluators.identity() || {}
        return (
            <div>
                <h5>You're not authorized to access Content Manager</h5>
                <pre>{JSON.stringify(account, null, 4)}</pre>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                        // TODO => Implement authentication.service logout
                        //  This method of logging user out will not work for Google
                        //  accounts, need to double check with Azure.
                        setIdentity(undefined)
                        navigate('/login')
                    }}
                >
                    Re-Login
                </Button>
            </div>
        )
    }

    if (evaluators.currentSite() === undefined) {
        return null
    }
    return errorMessage ? (
        <div>
            <h5>{errorMessage}</h5>
            <pre>{JSON.stringify(identity, null, 4)}</pre>
        </div>
    ) : (
        children
    )
}
