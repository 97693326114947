import React, { useEffect, useState } from 'react'
import { departmentService, SetState, SiteZ } from './department.service'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import { is, validateUUID } from '../../helpers'
import { SiteType } from '../../common/constants'
import { isEqual } from 'lodash'
import { SiteSelectorForContent } from '../../common/components/selectors/SiteSelectorForContent'
import { sitesEventChanel } from '../../common/components/selectors/SiteSelectorForAccount'
import { EntityScopeEnum } from '../auth/entityScope'

interface EditWizardProps {
    site: SiteZ | null
    onNext: (d: SiteZ) => void
    onClose: SetState
}

export function EditWizard({ site, onClose, onNext }: EditWizardProps) {
    const [errors, setErrors] = useState({ sites: false, name: false })
    const [siteReference, setSiteReference] = useState<SiteZ | undefined>(undefined)
    const [hostIds, setHostIds] = useState<string[]>([])

    useEffect(() => {
        console.log(site)
        if (site && !validateUUID(site?.id)) {
            return
        }
        if (site?.id === siteReference?.id) {
            if (isEqual(site, siteReference)) {
                return
            }
        }
        setSiteReference(
            site || {
                type: SiteType.Department,
                name: '',
                fescription: '',
                hosts: [],
                id: '',
                active: true,
                tags: [],
                tenantId: ''
            }
        )
        //@ts-ignore
        setHostIds(site?.hosts?.map((x) => x?.id) || [])
    }, [site])

    const handleChange = (evt) => {
        const { name = '', value = '' } = evt?.target || {}
        //@ts-ignore
        setSiteReference((prev) => ({ ...prev, [name]: value }))
        if (errors[name]) {
            setErrors((prev) => ({ ...prev, [name]: false }))
        }
    }

    const handleSave = async () => {
        try {
            const sites = !Boolean(siteReference?.hosts?.length)
            const name = !Boolean(siteReference?.name?.length)
            if (sites || name) {
                setErrors({ sites, name })
                return
            }
            const upsertModel = { ...site, ...siteReference }
            //Type 'string' is not assignable to type 'siteType'
            //@ts-ignore
            upsertModel.type = SiteType.Department
            //Argument of type '{ created: string; tags: string[]; departments: Site[] | SiteBase[]; hosts: Site[] | SiteBase[]; settings: Record<string, any>; secureSettings: Record<string, any>; ... 18 more ...; active: boolean; }' is not assignable to parameter of type 'Site'.
            //   Type '{ created: string; tags: string[]; departments: Site[] | SiteBase[]; hosts: Site[] | SiteBase[]; settings: Record<string, any>; secureSettings: Record<string, any>; ... 18 more ...; active: boolean; }' is missing the following properties from type 'Site': withDepartmentAccess, toRequestModel, isValid, isDepartment
            //@ts-ignore
            const response = await departmentService.upsert(upsertModel)
            if (is.func(onNext) && response) {
                onNext(response)
            }
            if (is.func(onClose)) {
                onClose?.()
            }

            sitesEventChanel.emit('onSitesUpdated')
        } catch (e) {
            console.error(e)
            return Promise.reject(e)
        }
    }
    return (
        <Dialog
            open={site != null}
            onClose={onClose}
            sx={{
                '& .MuiDialog-paperWidthSm': {
                    width: '600px',
                    maxWidth: '600px'
                }
            }}
        >
            <DialogTitle>Department</DialogTitle>
            <DialogContent
                sx={{
                    '& .MuiFormControl-root': {
                        marginTop: '0.5rem',
                        marginBottom: '0.5rem'
                    },
                    paddingBottom: '0'
                }}
            >
                <TextField
                    label={'Name'}
                    name={'name'}
                    variant={'standard'}
                    value={siteReference?.name || ''}
                    error={errors.name}
                    onChange={handleChange}
                />
                <TextField
                    label={'Description'}
                    name={'description'}
                    variant={'standard'}
                    value={siteReference?.description || ''}
                    onChange={handleChange}
                />

                {/* TODO: seems like this never worked? */}
                {/*<FormControl variant={'standard'}>*/}
                {/*    <InputLabel>Tags</InputLabel>*/}
                {/*    <Select placeholder={'Tags'} label={'Tags'} multiple disabled />*/}
                {/*</FormControl>*/}

                {site != null && (
                    <SiteSelectorForContent
                        Selected={hostIds}
                        ContentType={EntityScopeEnum.Department}
                        Label={'Host Sites'}
                        HasError={Object.values(errors).some(Boolean)}
                        OnChange={(value: string[]) => {
                            //@ts-ignore
                            setSiteReference((prev) => ({ ...prev, hosts: value }))
                            setErrors((prev) => ({ ...prev, sites: false }))
                        }}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant={'outlined'} color={'warning'}>
                    Cancel
                </Button>
                <Button
                    disabled={Object.values(errors).some(Boolean)}
                    onClick={handleSave}
                    variant={'outlined'}
                    color={'success'}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}
