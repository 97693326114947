import React, { Fragment, useContext, useEffect, useState } from 'react'
import DocumentFolder from './DocumentFolder'
import DocumentFolderDialog from './DocumentFolderDialog'
import { allFolderContext, documentContext, folderContext } from '../context'
import { Breadcrumbs, Chip, Link } from '@mui/material'
import CreateIcon from '@mui/icons-material/Create'
import _ from 'lodash'
import { documentService } from '../document.service'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { DocumentSearch } from '../DocumentSearch'
import { useAppContext, useCurrentSiteID, useDefaultSitesForSelector } from '../../../auth/atoms'

const folderInterface = {
    sites: [],
    tags: [],
    path: '',
    filename: '',
    type: 'folder',
    privacyLevel: 0
}

const DocumentFolderOutput = ({
    selectFolder,
    setIsFolderDialogOpen,
    isFolderDialogOpen,
    setOpenedFolder,
    saveForDct,
    isForDct,
    isForGallery,
    openedFolder,
    pagination,
    setPagination,
    searchText,
    setSearchText,
    searchValue,
    setSearchValue,
    breadCrumbs,
    setBreadCrumbs,
    loadPaginatedDocuments,
    disabled
}) => {
    const [, setDocuments] = useContext(documentContext)
    const currentSiteID = useCurrentSiteID()
    const defaultSites = useDefaultSitesForSelector()
    const appContext = useAppContext()
    const [folders, setFolders] = useContext(folderContext)
    const [allFolders] = useContext(allFolderContext)
    const [isEditFolder, setIsEditFolder] = useState(false)
    const [chosenFolder, setChosenFolder] = useState([])
    const [folderModel, setFolderModel] = useState({
        sites: defaultSites,
        tags: [],
        path: '',
        filename: '',
        type: 'folder',
        privacyLevel: 0
    })

    useEffect(() => {
        setFolderModel((p) => ({
            ...p,
            sites: defaultSites,
            department_id: appContext?.getCurrentSiteDepartmentID?.() || null
        }))
    }, [currentSiteID])

    const startFolderDialog = () => {
        setIsFolderDialogOpen(true)
    }

    /**
     * @param {boolean} wasDeleted - If the folder was deleted, we want to reset as oppose to refresh
     * */
    const endFolderDialog = async (wasDeleted) => {
        // If we're editing the folder we've opened, we reset the breadcrumbs.
        //  - This function will utilize loadPaginatedDocuments() to keep the pagination
        // If we're not editing the folder we've opened, skip this process.

        // If we're deleting the folder we've opened, we want to reset the folder
        //  - We don't want to maintain pagination, so we use selectFolder()
        if (openedFolder && openedFolder.id === folderModel.id) {
            if (wasDeleted) {
                selectFolder({ path: '', id: '' })
            } else {
                loadPaginatedDocuments()
                const results = await documentService.getDocumentAncestorsById(currentSiteID, openedFolder.id)
                if (results) {
                    setBreadCrumbs(results)
                }
            }
        }
        closeDialog()
    }

    const closeDialog = () => {
        setIsFolderDialogOpen(false)
        setIsEditFolder(false)
        setFolderModel(_.cloneDeep(folderInterface))
        setChosenFolder([])
    }

    const prepareEditFolder = (folder) => {
        const splitPath = folder.path.split('.')
        const parentPath = splitPath.slice(0, splitPath.length - 1).join('.')
        const folderRef = []
        const elementIndex = allFolders.findIndex((x) => x.path === parentPath)
        if (elementIndex >= 0) {
            folderRef.push(allFolders[elementIndex])
        }

        const referencedModel = { ...folder }
        console.log('referencedFolder:', folder)
        setFolderModel(referencedModel)
        setChosenFolder(folderRef)
        setIsEditFolder(true)
        startFolderDialog()
    }

    return (
        <Fragment>
            <div className='flex-row-align-center mb10 full-width'>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize='small' />}
                    aria-label='breadcrumb'
                    style={{ cursor: 'pointer', fontSize: '1.05em' }}
                    color={'primary'}
                >
                    <Link color='inherit' onClick={() => selectFolder({ id: '', path: '' })} underline='hover'>
                        Documents
                    </Link>
                    {breadCrumbs.map((folder, index) => (
                        <Link
                            underline={index === breadCrumbs.length - 1 ? 'none' : 'hover'}
                            style={index === breadCrumbs.length - 1 ? { color: 'grey', cursor: 'default' } : {}}
                            color='inherit'
                            key={folder.id + folder.path}
                            onClick={() => selectFolder(folder)}
                        >
                            {folder.filename}
                        </Link>
                    ))}
                </Breadcrumbs>
                {!isForGallery && openedFolder?.id && breadCrumbs.length > 0 && !disabled && (
                    <CreateIcon
                        style={{
                            cursor: 'pointer',
                            marginLeft: '15px',
                            width: '0.75em',
                            height: '0.75em',
                            color: '#757575'
                        }}
                        onClick={() => prepareEditFolder(openedFolder)}
                    />
                )}
                {openedFolder && openedFolder.privacyLevel > 0 && (
                    <Chip label='Staff' size='small' style={{ marginLeft: '10px' }} />
                )}
            </div>

            <DocumentSearch
                openedFolder={openedFolder}
                setDocuments={setDocuments}
                setFolders={setFolders}
                selectedSite={currentSiteID}
                setPagination={setPagination}
                pagination={pagination}
                searchText={searchText}
                setSearchText={setSearchText}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
            />
            {folders && folders.length > 0 && (
                <Fragment>
                    <p style={{ fontWeight: '500', color: '#5f6368', paddingTop: '8px', paddingBottom: '8px' }}>
                        Folders
                    </p>
                    <div /* ref={scrollRef} */ style={{ flexWrap: 'wrap', display: 'flex', padding: '5px' }}>
                        {folders.map((folder) => (
                            <DocumentFolder
                                folder={folder}
                                selectFolder={selectFolder}
                                saveForDct={saveForDct}
                                isForDct={isForDct}
                                key={folder.id + folder.filename}
                                prepareEditFolder={prepareEditFolder}
                                isFolderDialogOpen={isFolderDialogOpen}
                            />
                        ))}
                    </div>
                </Fragment>
            )}
            {isFolderDialogOpen && (
                <DocumentFolderDialog
                    isEditFolder={isEditFolder}
                    isFolderDialogOpen={isFolderDialogOpen}
                    endFolderDialog={endFolderDialog}
                    closeDialog={closeDialog}
                    openedFolder={openedFolder}
                    setOpenedFolder={setOpenedFolder}
                    folderModel={folderModel}
                    setFolderModel={setFolderModel}
                    chosenFolder={chosenFolder}
                    setChosenFolder={setChosenFolder}
                    refreshDocuments={loadPaginatedDocuments}
                    selectFolder={selectFolder}
                />
            )}
        </Fragment>
    )
}
export default DocumentFolderOutput
