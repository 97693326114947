import React, { useState, useEffect, useCallback } from 'react'
import { TextField, InputAdornment, FormControlLabel, Switch } from '@mui/material'
import { ContentType } from '../../types'

export type RouteEditorProps = {
    value: string
    onChange: (newValue: string) => void
    contentType: ContentType
    error?: string
    disabled?: boolean
}

export const RouteEditor: React.FC<RouteEditorProps> = ({
    value = '',
    onChange,
    contentType,
    error,
    disabled = false
}) => {
    const [alternateRoute, setAlternateRoute] = useState(() => /^https?:\/\//i.test(value))

    const getPrefix = useCallback((type: ContentType, isAlternate: boolean) => {
        if (isAlternate) return 'https://'
        switch (type) {
            case ContentType.News:
                return '/news/'
            case ContentType.Event:
                return '/event/'
            case ContentType.JS:
                return '/scripts/'
            case ContentType.CSS:
                return '/css/'
            default:
                return '/'
        }
    }, [])

    const getSuffix = useCallback((type: ContentType, isAlternate: boolean) => {
        if (isAlternate) return ''
        switch (type) {
            case ContentType.JS:
                return '.js'
            case ContentType.CSS:
                return '.css'
            default:
                return ''
        }
    }, [])

    const sanitize = useCallback(
        (val: string, type: ContentType, isAlternate: boolean, prefix: string, suffix: string) => {
            let sanitizedVal = val
            if (sanitizedVal.startsWith(prefix)) {
                sanitizedVal = sanitizedVal.slice(prefix.length)
            }
            if (suffix && sanitizedVal.endsWith(suffix)) {
                sanitizedVal = sanitizedVal.slice(0, -suffix.length)
            }

            if (isAlternate) {
                return sanitizedVal.replace(/^https?:\/\/|^\/+/i, '')
            }

            if (sanitizedVal === '/scripts/.js' || sanitizedVal === '/css/.css') {
                return ''
            }

            switch (type) {
                case ContentType.CSS:
                case ContentType.JS:
                    return sanitizedVal.replace(/[^-a-zA-Z0-9_.]/g, '')
                default:
                    return sanitizedVal.replace(/[^-a-zA-Z0-9_]/g, '')
            }
        },
        []
    )

    const prefix = getPrefix(contentType, alternateRoute)
    const suffix = getSuffix(contentType, alternateRoute)

    useEffect(() => {
        const sanitizedValue = sanitize(value, contentType, alternateRoute, prefix, suffix)
        if (!value || !value.startsWith(prefix) || !value.endsWith(suffix)) {
            onChange(prefix + sanitizedValue + suffix)
        }
    }, [value, contentType, alternateRoute, prefix, suffix, onChange, sanitize])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = sanitize(event.target.value, contentType, alternateRoute, prefix, suffix)
        onChange(prefix + newValue + suffix)
    }

    const handleAlternateRouteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAlternateRoute(event.target.checked)
    }

    return (
        <>
            <TextField
                label='Route'
                fullWidth
                value={sanitize(value, contentType, alternateRoute, prefix, suffix)}
                onChange={handleInputChange}
                InputProps={{
                    startAdornment: <InputAdornment position='start'>{prefix}</InputAdornment>,
                    endAdornment: <InputAdornment position='end'>{suffix}</InputAdornment>
                }}
                disabled={disabled}
                error={!!error}
            />
            {(alternateRoute || contentType === ContentType.News || contentType === ContentType.Event) && (
                <FormControlLabel
                    control={<Switch checked={alternateRoute} onChange={handleAlternateRouteChange} />}
                    label='Alternate Route'
                    labelPlacement='end'
                    disabled={disabled}
                />
            )}
        </>
    )
}
