import React, { useMemo } from 'react'
import { Alert } from '@mui/material'

// const fields = {
//     site: "Sites that this is shared to can be updated",
//     privacyLevel: "Content Visibility can be updated",
//     published: "Published Status can be updated",
//     tags: "Tags can be updated",
//     title: "Title can be updated",
//     content: "Content can be updated",
//     parent: "Parent Template can be updated",
//     route: "Route can be updated",
// }
const fields = {
    site: 'Sites',
    privacyLevel: 'Content Visibility',
    published: 'Published Status',
    tags: 'Tags',
    title: 'Title',
    content: 'Content',
    parent: 'Parent Template',
    route: 'Route'
}

interface EditableFieldsListProps {
    importInfo: null | {
        editableFieldsSet: Set<string>
        source: string
    }
    hasPermission: boolean
}

export function EditableFieldsList({ importInfo, hasPermission }: EditableFieldsListProps) {
    const editableFields = useMemo(() => {
        const fieldSet = importInfo?.editableFieldsSet
        if (!fieldSet) {
            return null
        }
        return [...fieldSet]
    }, [importInfo])

    if (!importInfo) {
        return null
    }
    return (
        <Alert severity={'info'} style={{ marginTop: '1vh' }}>
            <div>This event was imported from {importInfo?.source} - You don't have full access to this page</div>
            {hasPermission && editableFields != null && editableFields?.length > 0 && (
                <div>
                    <p>The following fields can be updated</p>
                    <ul>
                        {editableFields.map((name) => (
                            <li>{fields[name]}</li>
                        ))}
                    </ul>
                </div>
            )}
        </Alert>
    )
}
