export function toURLSearchParams(queryParams: Record<string, any>) {
    const searchParams = new URLSearchParams()

    Object.entries(queryParams).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            value.forEach(
                (item) => value !== undefined && value != null && searchParams.append(`${key}[]`, String(item))
            )
        } else {
            value !== undefined && value != null && searchParams.append(key, String(value))
        }
    })
    return searchParams
}

export function initiateFileDownload(baseUrl: string, queryParams: Record<string, any>): void {
    const searchParams = toURLSearchParams(queryParams)

    const fullUrl = `${baseUrl}?${searchParams.toString()}`

    const link = document.createElement('a')
    link.href = fullUrl
    link.setAttribute('download', '')
    link.style.display = 'none'
    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
}
