import { Site } from './types'
import { atom, useAtom } from 'jotai'
import { Account } from '../user-management/types'
import { Action, Evaluate, EvaluateEntityScopeOnly, Identity } from './permissions/evaluate'

export const sitesAtom = atom<Site[]>([])
export const identityAtom = atom<Account | undefined>(undefined)
export const currentSiteAtom = atom<Site | undefined>(undefined)
const currentSiteIDAtom = atom<string | undefined>((get) => get(currentSiteAtom)?.ID || undefined)

/* Permissions Testing
   Default behaviour has historically been that Default = [currentSiteID]
   Testers were confused as to why this was happening, and in some cases, Site
   names can be quite long, so it isn't readily apparent that all Sites are selected.
   Also, all sites for tenant are populating Site Selects even for users with single site permissions.

   2024-01-04
   Reverting to old behaviour for testing, can discuss
* */
export const useDefaultSitesForSelector = () => {
    const [currentSite] = useAtom(currentSiteAtom)
    const [sites] = useAtom(sitesAtom)

    if (!sites.length || !currentSite) {
        return []
    }

    const sitesForSelector =
        currentSite.Type === 'department'
            ? sites.filter((site) => currentSite.Hosts.includes(site.ID))
            : [currentSite] /* sites.filter((site) => site.Type !== 'department') */

    return sitesForSelector.map((site) => site.ID)
}
export const useIdentity = () => {
    const [identity] = useAtom(identityAtom)
    return (
        identity ||
        ({
            Email: '',
            IsAdmin: false,
            Groups: []
        } as Identity)
    )
}

export const useCurrentSite = () => {
    const [currentSite] = useAtom(currentSiteAtom)
    return currentSite
}

export const useCurrentSiteID = () => {
    const [currentSite] = useAtom(currentSiteIDAtom)
    return currentSite
}

type configKeys = 'DefaultEditor'

export const useAppContext = () => {
    const [identity] = useAtom(identityAtom)
    const [sites] = useAtom(sitesAtom)
    const [currentSite] = useAtom(currentSiteAtom)

    const currentSiteID = currentSite?.ID || undefined
    const tenantID = currentSite?.TenantID || undefined

    if (!identity || !currentSiteID) {
        return {
            isAdmin: () => false, // if the current user is an tenant admin
            isAccountAdmin: () => false, // if the current user can manage accounts (tenant admin or has an Audience group)
            isValid: () => false, // if the current user is an admin or has any groups
            identity: () => undefined, // the current user's identity object (has Email, IsAdmin, Groups)

            action: (obj: any, action: Action) => false, // if the current user can act on the object
            // The following are for UI purposes only, to check if the current user can see a button, menu item, or other UI element
            actionForEntityScope: (entityScope: string, action: Action) => false, // if the current user can act on the entity scope
            entityScopeOnly: (obj: any) => false, //
            entityScopeAny: (entityScope: string, forSiteID?: string) => false, // if the current user can perform any action on the entity scope (ignores the site-specific/tenant-wide restrictions)

            // We should avoid using these in the UI, only in some very specific cases (like the site selector)
            getTenantSites: () => [], // all sites in the tenant, available everywhere and loaded on login
            isCurrentSiteDepartment: () => false,
            getCurrentSiteDepartmentID: () => null,
            currentSite: () => undefined,
            currentSiteID: undefined, // we don't need this anymore because we have an interceptor that sets the current site
            tenantID: tenantID,
            getDefaultSitesForSelectors: () => [], // this should be used only in the site selector for content, so in the future we can remove it
            resolveFullUrl: (url: string) => url,
            getConfig: (key: configKeys): any => undefined,
            getSiteName: (siteID: string) => ''
        }
    }
    return {
        isAdmin: () => identity && identity.IsAdmin,
        isAccountAdmin: () =>
            (identity && (identity.IsAdmin || identity.Groups?.some((group) => group.Audience.length > 0))) || false,
        isValid: () => identity && (identity.IsAdmin || identity.Groups?.length > 0),
        identity: () => identity,

        action: (obj: any, action: Action) => Evaluate(identity, obj, action),
        actionForEntityScope: (entityScope: string, action: Action) =>
            Evaluate(identity, { EntityScope: entityScope, Sites: [currentSiteID], DepartmentID: null }, action) ||
            Evaluate(identity, { EntityScope: entityScope, Sites: null, DepartmentID: null }, action),
        entityScopeOnly: (obj: any) => EvaluateEntityScopeOnly(identity, obj),

        entityScopeAny: (entityScope: string, forSiteID?: string) =>
            EvaluateEntityScopeOnly(identity, {
                EntityScope: entityScope,
                Sites: [forSiteID || currentSiteID],
                DepartmentID: null
            }) || EvaluateEntityScopeOnly(identity, { EntityScope: entityScope, Sites: null, DepartmentID: null }),

        getTenantSites: () => sites,
        isCurrentSiteDepartment: () => currentSite?.Type === 'department',
        getCurrentSiteDepartmentID: () => (currentSite?.Type === 'department' ? currentSiteID : null),
        currentSite: () => currentSite,
        currentSiteID: currentSiteID,
        tenantID: tenantID,
        getDefaultSitesForSelectors: () => {
            if (!sites.length || !currentSite) {
                return []
            }

            if (currentSite.Type === 'department') {
                return currentSite.Hosts
            } else {
                return [currentSite.ID]
            }
        },
        resolveFullUrl: (link: string) => {
            if (
                link &&
                (link.toLocaleLowerCase().startsWith('http://') || link.toLocaleLowerCase().startsWith('https://'))
            ) {
                return link
            }

            if (!currentSite) {
                return link
            }

            const domain = currentSite.PrimaryDomain

            const base = `https://${domain || window.location.host}${
                window.location.host.includes('localhost') ? '.localhost' : ''
            }`
            const url = new URL(link, base)
            return url.toString()
        },

        getConfig: (key: configKeys): any => {
            switch (key) {
                case 'DefaultEditor':
                    const tenant = currentSite?.TenantID
                    if (!tenant) return 'ckeditor'

                    return [
                        '10368430-4caa-487f-946e-6257a9b42663', // LivingSky
                        '08077abf-4aa2-4bfa-ac81-9d31c60fcfe4', // CSSD
                        '55d32c5f-f954-47cc-9f89-2aa3816d2eec', // Grasslands
                        'f0886160-d04d-4299-9e65-833312c06c41', // Yellowknife Catholic
                        '87c2b55f-18b3-46ca-a7cd-8ee6efb28e04', // HPSD
                        'd7134a1a-7001-4c98-8167-0b5706e02398', // ECSD
                        '3d0d00c3-4787-45f4-a0b9-c2259df51578', // MHPSD
                        'accce85f-fefc-4fee-822e-a0279c4bc04d', // ImagineEverything
                        'e15ebf67-2f7b-409e-95af-eaf13ae0ce5d', // Redeemer
                        '2f4f4d4d-94b5-4970-bfa8-a5fa7ee941ba', // PrairieRose
                        '149c32f6-f15b-49ae-93e6-6dcb312465cb', // FMCSD
                        'b1a4019a-93f9-4828-a126-17a8d0956c16', // SD91
                        '7241349f-d97a-4e0f-8335-2160a22b45be', // RCSD
                        'ff5d7195-bffd-435c-9cf1-1366603a77be', // SD22
                        'a7e0925c-3021-4ffd-9e4c-5e28ab932c06' // Peel
                    ].includes(tenant)
                        ? 'ckeditor'
                        : 'lexical'
                default:
                    return undefined
            }
        },
        getSiteName: (siteID: string) => {
            const site = sites.find((site) => site.ID === siteID)
            return site?.Name || ''
        }
    }
}

export const useTenantSites = () => {
    const [sites] = useAtom(sitesAtom)
    return sites
}

export const useSitesForAccount = (): Site[] => {
    const [sites] = useAtom(sitesAtom)
    const [identity] = useAtom(identityAtom)

    if (!identity || !sites.length) {
        return []
    }

    const groupSiteIDs = identity.Groups?.map((group) => group.SiteID) || []
    const hasTenantWide = identity.IsAdmin || groupSiteIDs.some((siteID) => siteID === null)

    return sites.filter((site) => hasTenantWide || groupSiteIDs.some((siteID) => siteID === site.ID))
}

type SitesQuery = {
    ParentSitesIDs?: string[]
    ContentType: string
    IgnoreSiteID?: boolean // TODO: reconsider this parameter
}
export type SiteForContent = Site & { RestrictedByParent: boolean }
export const useSitesForContent = (query: SitesQuery): SiteForContent[] => {
    const sites = useTenantSites()
    const evaluators = useAppContext()
    const currentSiteID = useCurrentSiteID()

    if (!sites.length || !currentSiteID) {
        return []
    }

    const currentSite = sites.find((site) => site.ID === currentSiteID)
    if (!currentSite) {
        return []
    }

    const restrictedSet = new Set<string>(query.ParentSitesIDs || [])
    const mapper = (sites: Site[]): SiteForContent[] => {
        return sites
            .filter((s) => s.Type !== 'department')
            .map((site) => ({
                ...site,
                RestrictedByParent: !restrictedSet.has(site.ID)
            }))
    }

    // Unique case for departments. Technically, departments aren't content
    if (query.ContentType === 'cm.site.department') {
        return mapper(sites)
    }

    if (!evaluators.entityScopeAny(query.ContentType)) {
        console.warn(`You have no permissions for ${query.ContentType} in the site`, currentSite)
        return []
    }

    // DEPARTMENTS
    if (currentSite.Type === 'department' && !query.IgnoreSiteID) {
        const hosts = sites.filter((site) => currentSite.Hosts.includes(site.ID))
        return mapper(hosts)
    }

    // REGULAR SITES
    const sitesWithPermissions = sites.filter(
        (site) =>
            site.Type !== 'department' &&
            evaluators.entityScopeOnly({ EntityScope: query.ContentType, Sites: [site.ID], DepartmentID: null })
    )

    return mapper(sitesWithPermissions)
}
