import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Box } from '@mui/system'
import Button from '@mui/material/Button'
import { Paper } from '@mui/material'

export function SortableItem({ id, children }: { id: string; children?: React.ReactNode }) {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: id })

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        margin: '10px 0',
        padding: '10px'
    }

    return (
        <Paper ref={setNodeRef} style={style}>
            <div style={{ display: 'flex' }}>
                <div style={{ flex: '1 1 100%' }}>{children || id}</div>

                <Button
                    onClick={() => console.log('clicked')}
                    {...attributes}
                    {...listeners}
                    style={{ cursor: 'grab' }}
                >
                    <svg viewBox='0 0 20 20' width='12'>
                        <path d='M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z'></path>
                    </svg>
                </Button>
            </div>
        </Paper>
    )
}
