import React, { useState } from 'react'
import { CustomMenuItem } from './CustomMenu'
import { CircularProgress } from '@mui/material'
import { RestoreFromTrashOutlined } from '@mui/icons-material'
import { BaseContextMenuItemProps } from './types'
import axios from 'axios'
import { failure, success } from '../../../helpers/result'
import { ContentAPI } from '../../constants'

interface restoreResponse {
    success: boolean
    failure: boolean
    data: Record<any, any> | null
    error: Record<any, any> | null
}

export const ContentRestoreMenuItem = ({
    id,
    disabled,
    onChange
}: BaseContextMenuItemProps & {
    id: string
}) => {
    const [loading, setLoading] = useState<boolean>(false)

    const restore = async (contentId) => {
        try {
            await axios.patch(`${ContentAPI}/restore/${contentId}`)
            return success(null)
        } catch (error) {
            return failure(error)
        }
    }

    const handleRestore = () => {
        setLoading(true)
        restore(id)
            .then((response: restoreResponse) => onChange?.(response))
            .catch(console.error)
    }
    return (
        <CustomMenuItem text={'Restore'} disabled={Boolean(disabled)} onClick={handleRestore}>
            {loading ? <CircularProgress style={{ height: '24px', width: '24px' }} /> : <RestoreFromTrashOutlined />}
        </CustomMenuItem>
    )
}
