import { Image, MediaExplorerDialog } from './MediaExplorer'
import { Box, Card, CardActions, CardContent, Typography } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import Button from '@mui/material/Button'
import ImageIcon from '@mui/icons-material/Image'
import React from 'react'
import { colours } from '../../../common/colours'
import { MEDIA_THUMBNAILS } from '../../../common/constants'

type MediaIDSelectorProps = {
    value: string | null
    onChange: (id: string | null, image?: Image) => void
}

export const MediaIDSelector = ({ value, onChange }: MediaIDSelectorProps) => {
    const [open, setOpen] = React.useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleSelect = (image: Image) => {
        onChange(image.id, image)
        handleClose()
    }

    const handleClear = () => {
        onChange(null)
    }

    return (
        <>
            <Card sx={{ maxWidth: '320px', p: 1, m: 1 }}>
                <CardContent>{value && <ImagePreview id={value} />}</CardContent>
                <CardActions style={{ justifyContent: 'center' }}>
                    <Button size='small' color='primary' onClick={handleClickOpen} startIcon={<ImageIcon />}>
                        {value ? 'Change' : 'Select'}
                    </Button>
                    {value && (
                        <Button size='small' color='primary' onClick={handleClear} startIcon={<ClearIcon />}>
                            Delete
                        </Button>
                    )}
                </CardActions>
            </Card>

            <MediaExplorerDialog open={open} onClose={() => setOpen(false)} onSelect={handleSelect} />
        </>
    )
}

export const ImagePreview = ({ id, width = 300 }: { id: string | null | undefined; width?: number }) => {
    const height = width * 0.75

    if (!id) {
        return (
            <Box
                sx={{
                    width: `${width}px`,
                    height: `${height}px`,
                    backgroundColor: colours.off_white_but_darker,
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex'
                }}
                title='No image id'
            >
                <Typography variant='caption'>No image id</Typography>
            </Box>
        )
    }

    return (
        <div
            style={{
                transition: '.25s ease-in-out',
                width: `${width}px`,
                height: `${height}px`,
                backgroundImage: `url(${MEDIA_THUMBNAILS}/${id})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundColor: '#f5f5f5'
            }}
        ></div>
    )
}
