import { patternMatch } from './permissions/patternMatch'

export type Scope = {
    label: string
    pattern: string
    children?: Scope[]
}

export const defaultScopes: Scope[] = [
    {
        label: 'Pages Editor',
        pattern: 'cm.content.page/*',
        children: [
            {
                label: 'create',
                pattern: 'cm.content.page/create'
            },
            {
                label: 'update',
                pattern: 'cm.content.page/update'
            },
            {
                label: 'delete',
                pattern: 'cm.content.page/delete'
            }
        ]
    },
    {
        label: 'News Editor',
        pattern: 'cm.content.news/*',
        children: [
            {
                label: 'create',
                pattern: 'cm.content.news/create'
            },
            {
                label: 'update',
                pattern: 'cm.content.news/update'
            },
            {
                label: 'delete',
                pattern: 'cm.content.news/delete'
            }
        ]
    },
    {
        label: 'Events Editor',
        pattern: 'cm.content.event/*',
        children: [
            {
                label: 'create',
                pattern: 'cm.content.event/create'
            },
            {
                label: 'update',
                pattern: 'cm.content.event/update'
            },
            {
                label: 'delete',
                pattern: 'cm.content.event/delete'
            }
        ]
    },
    {
        label: 'Alerts Editor',
        pattern: 'cm.content.alert/*',
        children: [
            {
                label: 'create',
                pattern: 'cm.content.alert/create'
            },
            {
                label: 'update',
                pattern: 'cm.content.alert/update'
            },
            {
                label: 'delete',
                pattern: 'cm.content.alert/delete'
            }
        ]
    },
    {
        label: 'Fragment Manager',
        pattern: 'cm.fragment*/*'
    },
    {
        label: 'Structures Manager',
        pattern: 'cm.structure*/*'
    },
    {
        label: 'Resources Manager',
        pattern: 'cm.resource*/*'
    },
    {
        label: 'Tags Editor',
        pattern: 'cm.tag/*',
        children: [
            {
                label: 'create',
                pattern: 'cm.tag/create'
            },
            {
                label: 'update',
                pattern: 'cm.tag/update'
            },
            {
                label: 'delete',
                pattern: 'cm.tag/delete'
            }
        ]
    },
    {
        label: 'Transportation Admin',
        pattern: 'cm.transportation*',
        children: [
            {
                label: 'Transportation Status Manager',
                pattern: 'cm.transportation.bus_status/*'
            }
        ]
    },
    {
        label: 'Images Editor',
        pattern: 'cm.image/*',
        children: [
            {
                label: 'create',
                pattern: 'cm.image/create'
            },
            {
                label: 'update',
                pattern: 'cm.image/update'
            },
            {
                label: 'delete',
                pattern: 'cm.image/delete'
            }
        ]
    },
    {
        label: 'Documents Editor',
        pattern: 'cm.document*/*',
        children: [
            {
                label: 'create',
                pattern: 'cm.document*/create'
            },
            {
                label: 'update',
                pattern: 'cm.document*/update'
            },
            {
                label: 'delete',
                pattern: 'cm.document*/delete'
            }
        ]
    },
    {
        label: 'Navigation Editor',
        pattern: 'cm.navigation*',
        children: [
            {
                label: 'create',
                pattern: 'cm.navigation*/create'
            },
            {
                label: 'update',
                pattern: 'cm.navigation*/update'
            },
            {
                label: 'delete',
                pattern: 'cm.navigation*/delete'
            },
            {
                label: 'assign primary',
                pattern: 'cm.navigation*/assign_primary'
            }
        ]
    },
    {
        label: 'Sites Editor',
        pattern: 'cm.site*',
        children: [
            {
                label: 'create',
                pattern: 'cm.site*/create'
            },
            {
                label: 'update',
                pattern: 'cm.site*/update'
            },
            {
                label: 'delete',
                pattern: 'cm.site*/delete'
            }
        ]
    },
    {
        label: 'Notifications Manager',
        pattern: 'cm.notification*'
    },
    {
        label: 'Lists Manager',
        pattern: 'cm.list*',
        children: [
            {
                label: 'create',
                pattern: 'cm.list*/create'
            },
            {
                label: 'update',
                pattern: 'cm.list*/update'
            },
            {
                label: 'delete',
                pattern: 'cm.list*/delete'
            }
        ]
    },
    {
        label: 'Settings Editor',
        pattern: 'cm.settings*',
        children: [
            {
                label: 'create',
                pattern: 'cm.settings*/create'
            },
            {
                label: 'update',
                pattern: 'cm.settings*/update'
            },
            {
                label: 'delete',
                pattern: 'cm.settings*/delete'
            },
            {
                label: 'Instagram Editor',
                pattern: 'cm.settings.instagram/*',
                children: [
                    {
                        label: 'create',
                        pattern: 'cm.settings.instagram/create'
                    },
                    {
                        label: 'update',
                        pattern: 'cm.settings.instagram/update'
                    },
                    {
                        label: 'delete',
                        pattern: 'cm.settings.instagram/delete'
                    }
                ]
            }
        ]
    }
]

export function removeScope(scopes: string[], scope: string) {
    return scopes.filter((s) => s !== scope)
}

export function addScope(scopes: string[], scope: string) {
    return [...scopes.filter((s) => !patternMatch(scope, s)), scope]
}
