// @ts-nocheck
import { uuidNil } from '../../common/constants'
import { mergeExistingObjectFields, validateUUID } from '../../helpers'
import { v4 as uuidv4 } from 'uuid'
import { ContentType, PageLayout } from '../content/types'

export enum NavigationType {
    External = 'external_link',
    Page = 'page_route'
}

export class Navigation {
    id = uuidNil
    ContentId = uuidNil
    SiteId = uuidNil
    DepartmentId = null
    path = ''
    type = ''
    visible = false
    active = false
    treeIndex = 0
    ParentId = ''
    children: Navigation[] | undefined = []
    content = new Content({})
    __setPrimacy: Boolean | null = null

    constructor(navigationLikeObject) {
        if (!navigationLikeObject || typeof navigationLikeObject !== 'object') {
            return this
        }
        const clone = { ...navigationLikeObject }
        delete clone.title
        // TODO => Departments Cleaning
        //@ts-ignore
        Object.assign(this, mergeExistingObjectFields(clone, this))
    }

    isDepartment() {
        return validateUUID(this.DepartmentId)
    }

    isValid() {
        return validateUUID(this.id) && validateUUID(this.SiteId) && validateUUID(this.ContentId)
    }

    get isPrimary(): boolean {
        if (this.__setPrimacy != null) {
            return Boolean(this.__setPrimacy)
        }
        return Boolean(this?.content?.settings?.isPrimary)
    }

    get sites(): any[] {
        return this?.content?.sites || []
    }

    set sites(sites) {
        this.content.sites = sites
        return this
    }

    get title(): string {
        return this?.content?.title || ''
    }

    get published(): boolean {
        const status = getPublishStatus(this?.content?.publish_at, this?.content?.expire_at)
        return status === 'published'
    }

    get contentDepartmentId(): string {
        return this?.content?.departmentId || ''
    }

    get parentId() {
        if (!this.path.length) return ''
        return (
            this.path
                .split('.')
                .slice(0, this.path.length - 1)
                .join('.') || this.ParentId
        )
    }

    get settings() {
        return this?.content?.settings || {}
    }

    set settings(object) {
        this.content.settings = object
        return this
    }

    DBModel(): OmitsChildrenAndParentId {
        const clone = { ...this }
        delete clone.children
        delete clone.ParentId
        delete clone.__setPrimacy
        return clone
    }
}

type OmitsChildrenAndParentId = Omit<Navigation, 'children' | 'ParentId'>

// class Content is used by Navigation
// Todo => Remove Content model next time we're working with navigation
// TODO: DELETE
export class Content {
    id = uuidv4()
    type = ContentType.Page
    publisher = ''
    owner = ''
    title = ''
    content = ''
    route = ''
    path = ''
    mediaId = ''
    pageLayout = PageLayout.WYSIWYG
    privacyLevel = 0
    active = false
    publish_at = new Date()
    expire_at = null
    data = null
    structure = null
    sites = []
    settings = {}
    departmentId = ''

    constructor(contentLikeObject) {
        if (!contentLikeObject || typeof contentLikeObject != 'object') return this
        if (contentLikeObject instanceof Content) return contentLikeObject
        // if (contentLikeObject instanceof ContentViewModel) return contentLikeObject?.ToContentModel?.() || this
        else Object.assign(this, mergeExistingObjectFields(contentLikeObject, this))
    }
}
