export function truncateMiddle(input: string, maxLength: number) {
    if (input.length <= maxLength) {
        return input
    }
    const numCharsEachSide = Math.floor((maxLength - 3) / 2)
    if (numCharsEachSide <= 0) {
        return '...'
    }
    const start = input.substring(0, numCharsEachSide)
    const end = input.substring(input.length - numCharsEachSide)

    return `${start}...${end}`
}

export default truncateMiddle
