import { z } from 'zod'
import { httpGet } from '../../common/client'
import axios from 'axios'
import { Site } from '../auth/types'
import { PagingQuery, dateTime, paged, trackable, uuidSchema } from '../../common/react-query'
import { DepartmentAPI } from '../../common/constants'

/** Types */
export type SetState = (x?) => void

// todo => attempt to infer from type again
// export const securityGroupZ = z.object(z.infer<typeof SecurityGroup>)

export type DepartmentQuery = {
    search?: string
    tags?: string[]
    sites?: string[]
} & PagingQuery

export const role = z.object({
    id: uuidSchema,
    name: z.string(),
    mod_core: z.number(),
    mod_pages: z.number(),
    mod_news: z.number(),
    mod_events: z.number(),
    mod_media: z.number(),
    mod_transportation: z.number(),
    mod_alerts: z.number(),
    active: z.boolean()
})

const securityGroupSchema = trackable.extend({
    Name: z.string(),
    Description: z.string(),
    Type: z.union([z.literal('external'), z.literal('manual')]),
    Active: z.boolean(),
    RoleID: uuidSchema,
    Role: role,
    SiteID: uuidSchema,
    ExternalIdList: z.array(z.string()),
    DependsOn: z.array(z.string()),
    Audience: z.array(z.string()),
    AvailableGroups: z.array(z.string())
})

export const securityGroupsQuerySchema = paged.extend({
    Rows: z.array(securityGroupSchema)
})

export type SecurityGroupsZ = z.infer<typeof securityGroupsQuerySchema>

/** Schema */
const baseSiteSchema = z.object({
    id: uuidSchema,
    tenantId: uuidSchema,
    name: z.string(),
    description: z.string(),
    type: z.string(),
    created: dateTime,
    active: z.boolean(),
    tags: z.array(uuidSchema),
    settings: z.object({}),
    secure_settings: z.object({}),
    securityGroups: z.array(securityGroupSchema).optional().nullable()
    // securityGroups  : z.array(securityGroupZ).optional().nullable(),
})
const ZodSiteSchema = baseSiteSchema.extend({
    hosts: z.lazy(() => z.array(ZodSiteSchema)).nullable(),
    departments: z.lazy(() => z.array(ZodSiteSchema)).nullable()
})

export type SiteZ = z.infer<typeof ZodSiteSchema>
export const departmentQuerySchema = paged.extend({
    Rows: z.array(ZodSiteSchema)
})
export type SitesZ = z.infer<typeof departmentQuerySchema>

/** Service Methods */
const queryDepartments = async (query: DepartmentQuery, controller?: AbortController): Promise<SitesZ> => {
    try {
        return (await httpGet(`${DepartmentAPI}`, query, departmentQuerySchema, controller)) as SitesZ
    } catch (e) {
        console.log(e)
        return Promise.reject(e)
    }
}
const getAllDepartments = async () => {}

const upsertDepartment = async (department: Site) => {
    try {
        const response = await axios.put(DepartmentAPI, department)
        //@ts-ignore
        return response?.data?.results || response?.data || []
    } catch (e) {
        console.error(e)
    }
}
export const departmentService = {
    upsert: upsertDepartment,
    query: queryDepartments,
    getAll: getAllDepartments
}
