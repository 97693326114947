import React, { useEffect, useState } from 'react'
import { GridColDef } from '@mui/x-data-grid'
import { httpGet } from '../../../common/client'
import { notify } from '../../../helpers'
import { subscribers, Subscribers, SubscribersQuery } from './types'
import { Grid } from '@mui/material'
import { useStateWithStorage } from '../../../common/storage.service'
import PageContainerWithHeader from '../../../common/components/PageContainerWithHeader'
import SearchBar from '../../../common/components/SearchBar'
import { AdminOrOwnerCell, TwoLinesCell } from '../../grid/cells/GridCells'
import { defaultPageQuery } from '../../../common/react-query'
import { SubscribersAPI } from '../../../common/constants'
import { DataGridBase } from '../../grid/DataGridBase'
import { WhatsNew } from '../../../common/components/WhatsNew'

export const SubscribersGrid = () => {
    const [query, setQuery] = useStateWithStorage<SubscribersQuery>('subscribers-grid-query', { ...defaultPageQuery })
    const [gridState, setGridState] = useState<Subscribers>({
        TotalRecords: 0,
        TotalPages: 0,
        Offset: 0,
        PageSize: 10,
        Page: 1,
        Rows: []
    })

    const columns: GridColDef[] = [
        {
            field: 'Email',
            headerName: 'Email/ID',
            flex: 2,
            sortable: true,
            renderCell: (params) => <TwoLinesCell l1={params.row.Email} l2={params.row.ID} />
        },
        { field: 'EmailConfirmedAt', headerName: 'Confirmed', width: 200, sortable: true },
        {
            field: 'LastName',
            headerName: 'Name',
            width: 200,
            sortable: true,
            renderCell: (params) => <>{`${params.row.FirstName} ${params.row.LastName}`}</>
        },
        { field: 'EmailConfirmationSentAt', headerName: 'ConfirmationSent', width: 200, sortable: true },
        { field: 'CreatedAt', headerName: 'CreatedAt', width: 200, sortable: true },
        {
            field: 'CreatedBy',
            headerName: 'CreatedBy',
            width: 200,
            sortable: false,
            renderCell: (params) => (
                <AdminOrOwnerCell adminID={params.row.CreatedBy} ownerID={params.row.ID} ownerName={params.row.Email} />
            )
        },
        { field: 'UpdatedAt', headerName: 'UpdatedAt', width: 200, sortable: true },
        {
            field: 'UpdatedBy',
            headerName: 'UpdatedBy',
            width: 200,
            sortable: false,
            renderCell: (params) => (
                <AdminOrOwnerCell adminID={params.row.UpdatedBy} ownerID={params.row.ID} ownerName={params.row.Email} />
            )
        }
    ]

    useEffect(() => {
        httpGet(`${SubscribersAPI}`, query, subscribers)
            .then((res) => {
                setGridState(res)
            })
            .catch((err) => {
                console.error(err)
                notify(err)
            })
    }, [query])

    return (
        <PageContainerWithHeader title='Subscribers' titleSlot={<WhatsNew
            link={'https://cdn.cmdesign.imagineeverything.com/notifications-1718912661937?hash=doL25vdGlmaWNhdGlvbnMtMTcxODkxMjY2MTkzNw=='} />}>
            <Grid container spacing={2}>
                <Grid item md={6}>
                    <SearchBar
                        value={query.search || ''}
                        onChange={(val) => setQuery((prev) => ({ ...prev, Search: val }))}
                    />
                </Grid>
                <Grid item md={12}>
                    <DataGridBase
                        columns={columns}
                        state={gridState}
                        setQuery={setQuery}
                        getRowClassName={(params) => (params.row.EmailConfirmedAt ? 'active' : 'inactive')}
                    />
                </Grid>
            </Grid>
        </PageContainerWithHeader>
    )
}
