import { useStructuresQuery } from './queries'
import React, { useEffect } from 'react'
import { Structure } from './types'
import {
    Alert,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    Switch
} from '@mui/material'
import Button from '@mui/material/Button'

interface StructuresForTemplateSelectorProps {
    value: string[] | null | undefined
    onChange: (ids: string[]) => void
    disabled?: boolean
}

export const StructuresForTemplateSelector = ({
    value,
    onChange,
    disabled = false
}: StructuresForTemplateSelectorProps) => {
    const results = useStructuresQuery({ page: 1, pageSize: 1000 })
    const [filteredStructures, setFilteredStructures] = React.useState<Structure[]>([])
    const [open, setOpen] = React.useState(false)

    useEffect(() => {
        if (!results.data?.Rows) return

        setFilteredStructures(results.data.Rows.sort((a, b) => a.Name.localeCompare(b.Name)))
    }, [results.data?.Rows])

    return (
        <>
            <h3>Compatible Structures</h3>
            {!value?.length && (
                <Alert severity={'warning'}>
                    No structures selected. <strong>Any structure</strong> can be used with this template.
                </Alert>
            )}
            {!!value?.length && (
                <ul>
                    {filteredStructures
                        .filter((s) => value?.includes(s.ID))
                        .map((s) => (
                            <li key={s.ID}>{s.Name}</li>
                        ))}
                </ul>
            )}

            <Button variant={'outlined'} fullWidth onClick={() => setOpen(true)} disabled={disabled}>
                Change selection
            </Button>

            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Select structures for template</DialogTitle>
                <DialogContent>
                    <FormGroup>
                        {filteredStructures.map((structure) => {
                            const checked = value?.includes(structure.ID) || false
                            return (
                                <FormControlLabel
                                    key={structure.ID}
                                    control={
                                        <Switch
                                            checked={checked}
                                            onChange={(e) => {
                                                const checked = e.target.checked
                                                if (checked) {
                                                    onChange([...(value || []), structure.ID])
                                                } else {
                                                    onChange(value?.filter((id) => id !== structure.ID) || [])
                                                }
                                            }}
                                        />
                                    }
                                    label={structure.Name}
                                />
                            )
                        })}
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button variant={'contained'} onClick={() => setOpen(false)}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
