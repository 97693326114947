import { useMutation, useQuery } from '@tanstack/react-query'
import { siteService } from '../../pkgs/system/site/site.service'
import axios from 'axios'
import { notify } from '../../helpers'
import { SiteAPI, siteForAccountsQueryKey } from '../constants'

export default function useSiteForAccountsQueryMutation(
    siteId?: string,
    onSuccess?: (data) => void,
    onError?: (e) => void
) {
    const {
        data: siteData,
        error,
        refetch
    } = useQuery({
        queryKey: [siteForAccountsQueryKey + siteId],
        queryFn: () =>
            siteId
                ? siteService.getById(siteId).then((data) => data)
                : siteService.getAll(true, undefined).then((data) => data)
    })

    const mutation = useMutation({
        mutationFn: (updateSiteModel: any) => axios.put(SiteAPI, updateSiteModel, { params: { siteId } }),
        onSuccess: (data) => {
            if (onSuccess) {
                onSuccess(data)
            } else {
                notify('Site settings have been updated!', 'info')
                refetch()
            }
        },
        onError: (e) => {
            if (onError) {
                onError(e)
            } else {
                notify('Whoops! Error updating site settings, please try again later')
            }
        }
    })

    return { siteData: siteData as any, error, refetch, mutation }
}
