import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react'
import { Card, CardContent, TablePagination } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { notify } from '../../../helpers'
import { ToastContainer } from 'react-toastify'
import {
    allFolderContext,
    documentContext,
    folderContext,
    fullAccessFoldersContext,
    selectedDocumentContext
} from './context'
import { disabledContext } from '../../../common/DisabledContext'
import { documentService } from './document.service'
import {
    DocumentBulkActions,
    DocumentEdit,
    DocumentFolderOutput,
    DocumentFolderTree,
    DocumentOutput,
    DocumentUploader
} from './index'
import ConfirmAction from '../../../common/components/ConfirmAction'
import { DefaultLimit, DefaultMax } from '../../../common/constants'
import '../image/Media.css'
import { DocumentOptionsSelect } from './DocumentOptionsSelect'
import { clientStorage, documentsRowsPerPageKey } from '../../../common/client'
import { useAppContext, useCurrentSiteID, useDefaultSitesForSelector } from '../../auth/atoms'
import { DocumentType } from './types'

export function DocumentManager({ view, isFolderDialogOpen, setIsFolderDialogOpen, handleView }) {
    const currentSiteID = useCurrentSiteID()
    const defaultSites = useDefaultSitesForSelector()
    const evaluators = useAppContext()

    const classes = useStyles()
    // Contexts
    const [, setDisabled] = useContext(disabledContext)
    const [folders, setFolders] = useContext(folderContext)
    const [documents, setDocuments] = useContext(documentContext)
    const [allFolders, setAllFolders] = useContext(allFolderContext)
    const [, setSelectedDocuments] = useContext(selectedDocumentContext)
    const [fullAccessFolders, setFullAccessFolders] = useContext(fullAccessFoldersContext)

    // Resources
    const [mediaTags, setMediaTags] = useState([])

    // Folders
    const [openedFolder, setOpenedFolder] = useState({ id: '', path: '' })
    const [breadCrumbs, setBreadCrumbs] = useState([])

    // Views & Booleans
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
    const [bulkActionEnabled, setBulkActionEnabled] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isEnabled, setIsEnabled] = useState(false)
    const [hasMediaPermission, setHasMediaPermission] = useState(false)

    // Temporary Editing States
    const [documentToEdit, setDocumentToEdit] = useState({})

    // Errors
    const [errors, setErrors] = useState({
        tags: false,
        sites: false,
        extension: false,
        privacyLevel: false
    })

    // Create States
    const [chosenFolder, setChosenFolder] = useState([])
    const [documentsToUpload, setDocumentsToUpload] = useState([])
    const [createModel, setCreateModel] = useState({
        tags: [],
        sites: defaultSites,
        privacyLevel: 0
    })
    useEffect(() => {
        setCreateModel((p) => ({ ...p, sites: defaultSites }))
    }, [currentSiteID])

    // Pagination & Search
    const [pagination, setPagination] = useState({
        data: [],
        offset: 0,
        perPage: clientStorage.getItem(documentsRowsPerPageKey) || DefaultLimit,
        currentPage: 0,
        total: 0
    })
    const [searchValue, setSearchValue] = useState([])
    const [searchText, setSearchText] = useState('')

    function getAllDocuments() {
        documentService.getAllByFolderAndType(currentSiteID, DocumentType.Document, '', pagination.perPage, 0).then(
            ({ results, resultset }) => {
                // const docs = results.map(result => ({
                //     ...result,
                //     __canUpdate: evaluators.action(result, 'update'),
                //     __canDelete: evaluators.action(result, 'create')
                // }))
                // console.log("nextDocuments", docs)
                // setDocuments(docs)
                setDocuments(results)
                setPagination((prev) => ({
                    ...prev,
                    total: resultset.total_records,
                    pageCount: Math.ceil(resultset.total_records / pagination.perPage)
                }))
                if (documentToEdit && 'id' in documentToEdit) {
                    const documentBeingEdited = results.find((doc) => doc.id == documentToEdit.id)
                    if (documentBeingEdited) {
                        setDocumentToEdit(results.find((doc) => doc.id == documentToEdit.id))
                    }
                }
            },
            (e) => console.log(e)
        )
    }

    useEffect(() => {
        getAllDocuments()
        documentService.getAllByFolderAndType(currentSiteID, DocumentType.Folder, '', 9999, 0).then(
            ({ results }) => setFolders(results),
            (e) => console.log(e)
        )
        documentService.getAll(currentSiteID, DocumentType.Folder).then(
            ({ results }) => {
                setAllFolders(results)
                const foldersWithAccess = results.filter((folder) => evaluators.action(folder, 'update'))
                setFullAccessFolders(foldersWithAccess)
                // if (evaluators.entityScopeAny(EntityScopeEnum.Document)) {
                //     setFullAccessFolders(results)
                // } else if (evaluators.entityScopeAny(EntityScopeEnum.Document)) {
                //     setFullAccessFolders(results.filter((r) => r.sites.length === 1 && r.sites?.[0] === currentSiteID))
                // }
            },
            (e) => console.log(e)
        )
        selectFolder({ path: '', id: '' })
    }, [currentSiteID])

    useEffect(() => {
        if (view === 'input') {
            setDisabled(true)
        } else {
            setDisabled(false)
            setDocumentsToUpload((prev) => prev.filter((document) => !document?.uploadState?.success))
        }
    }, [view])

    // Automatic validation of sites
    useEffect(() => {
        if (createModel.sites.length === 0) {
            setErrors((prev) => ({ ...prev, sites: true }))
        } else {
            setErrors((prev) => ({ ...prev, sites: false }))
        }
    }, [createModel.sites])

    const handleClose = () => {
        setIsEditDialogOpen(false)
        setIsDeleteDialogOpen(false)
    }

    const handleOpenEditDialog = () => {
        setIsEditDialogOpen(true)
    }

    const handleOpenDeleteDialog = () => {
        setIsDeleteDialogOpen(true)
    }

    const prepareEditDocument = useCallback((document) => {
        setDocumentToEdit({ ...document })
        handleOpenEditDialog()
    }, [])

    const prepareDeleteDocument = (documentId) => {
        setDocumentToEdit((prevItems) => {
            return {
                ...prevItems,
                id: documentId
            }
        })
        handleOpenDeleteDialog()
    }

    const resetTemporaryStates = () => {
        setSelectedDocuments({})
        setBulkActionEnabled(false)
        setSearchText('')
        setSearchValue([])
    }

    const deleteDocument = useCallback(async () => {
        try {
            if (documentToEdit.id) {
                setIsLoading(true)
                await documentService.deleteDocuments(currentSiteID, [documentToEdit.id])
                const filtered = documents.filter((x) => x.id !== documentToEdit.id)
                setDocuments(filtered)
                notify('Document successfully deleted', 'info')
            }
        } catch (e) {
            notify('Error deleting document', 'error')
        } finally {
            setIsLoading(false)
            handleClose()
        }
    }, [documentToEdit])

    const selectFolder = (folder) => {
        if (folder && Object.keys(folder).length > 0) {
            handleView('output')
            setOpenedFolder(folder)
            resetTemporaryStates()

            if (folder.id && folder.id.length) {
                documentService.getDocumentAncestorsById(currentSiteID, folder.id).then((x) => setBreadCrumbs(x))
            } else {
                setBreadCrumbs([])
            }

            documentService
                .getAllByFolderAndType(currentSiteID, DocumentType.Document, folder.id, pagination.perPage, 0)
                .then(
                    ({ results, resultset }) => {
                        setDocuments(results)
                        setPagination((prev) => ({
                            ...prev,
                            total: resultset.total_records,
                            currentPage: 0,
                            offset: 0
                        }))
                    },
                    (e) => console.log(e)
                )
            documentService.getAllByFolderAndType(currentSiteID, DocumentType.Folder, folder.id, DefaultMax, 0).then(
                ({ results }) => {
                    setFolders(results)
                },
                (e) => console.log(e)
            )
        }
    }

    const loadPaginatedDocuments = async (limit, offset) => {
        limit = limit ? limit : pagination.perPage
        const response = await documentService.getByParameter(
            currentSiteID,
            searchValue.map((x) => x.id),
            searchText,
            openedFolder.id,
            DocumentType.Document,
            limit,
            offset
        )
        if (response) {
            const { results } = response
            setDocuments(results)
            setPagination((prev) => ({ ...prev, pageCount: Math.ceil(prev.total / limit) }))
            return results
        }
    }

    const handleChangeRowsPerPage = async (event) => {
        // Reset to the first page and adjust the per-page limit
        const perPage = parseInt(event.target.value, 10)
        setPagination((prev) => ({ ...prev, currentPage: 0, perPage: perPage }))
        await loadPaginatedDocuments(perPage, 0)
    }

    const handleChangePage = async (event, newPage) => {
        let offset = Math.ceil(newPage * pagination.perPage)
        setPagination((prev) => ({ ...prev, currentPage: newPage, offset: offset }))
        const results = await loadPaginatedDocuments(pagination.perPage, offset)
        if (results) {
            const newObj = {}
            for (const item of results) {
                newObj[item.id] = false
            }
            setSelectedDocuments(newObj)
            setBulkActionEnabled(false)
        }
    }

    return (
        <Fragment>
            <div className='col-xs-2'>
                <DocumentFolderTree setOpenedFolder={selectFolder} />
            </div>

            <div className='col-xs-10'>
                <Card>
                    <CardContent>
                        <ToastContainer />
                        {view === 'output' && (
                            <Fragment>
                                <DocumentFolderOutput
                                    folders={folders}
                                    setFolders={setFolders}
                                    selectFolder={selectFolder}
                                    openedFolder={openedFolder}
                                    setOpenedFolder={setOpenedFolder}
                                    allFolders={allFolders}
                                    isFolderDialogOpen={isFolderDialogOpen}
                                    setIsFolderDialogOpen={setIsFolderDialogOpen}
                                    breadCrumbs={breadCrumbs}
                                    setBreadCrumbs={setBreadCrumbs}
                                    disabled={!hasMediaPermission}
                                    pagination={pagination}
                                    setPagination={setPagination}
                                    searchText={searchText}
                                    setSearchText={setSearchText}
                                    searchValue={searchValue}
                                    setSearchValue={setSearchValue}
                                    loadPaginatedDocuments={() =>
                                        loadPaginatedDocuments(pagination.perPage, pagination.currentPage)
                                    }
                                />

                                <p
                                    style={{
                                        fontWeight: '500',
                                        color: '#5f6368',
                                        paddingTop: '8px',
                                        paddingBottom: '8px',
                                        marginBottom: '0'
                                    }}
                                >
                                    Files
                                </p>
                                <DocumentBulkActions
                                    allowedFolders={fullAccessFolders}
                                    bulkActionEnabled={bulkActionEnabled}
                                    setBulkActionEnabled={setBulkActionEnabled}
                                    chosenFolder={chosenFolder}
                                    setChosenFolder={setChosenFolder}
                                    pagination={pagination}
                                    setPagination={setPagination}
                                    loadPaginatedDocuments={loadPaginatedDocuments}
                                />
                                <DocumentOutput
                                    handleEdit={prepareEditDocument}
                                    handleDelete={prepareDeleteDocument}
                                    enableBulkSelection
                                    isEnabled={isEnabled}
                                />
                                <TablePagination
                                    component='div'
                                    style={{ display: 'flex', justifyContent: 'center' }}
                                    count={pagination.total}
                                    page={pagination.currentPage}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={pagination.perPage}
                                    onRowsPerPageChange={(e) => {
                                        handleChangeRowsPerPage(e)
                                        clientStorage.setItem(documentsRowsPerPageKey, parseInt(e.target.value))
                                    }}
                                />
                            </Fragment>
                        )}
                        {/* Using CSS to hide the input manager to allow the components to remain mounted & continue their
                            visual uploading state indicators (uploading,succeed,fail). This avoids having to have an upload status map
                            in the parent component which forces cascading re-renders on update
                         */}
                        {/*<div style={view === 'input' ? {visibility:"visible"} : {visibility:"hidden"}}>*/}
                        {view === 'input' && (
                            <div>
                                <DocumentUploader
                                    errors={errors}
                                    tags={mediaTags}
                                    setDocuments={setDocuments}
                                    chosenFolder={chosenFolder}
                                    chosenBulkTags={createModel.tags}
                                    chosenSites={createModel.sites}
                                    privacyLevel={createModel.privacyLevel}
                                    documentsToUpload={documentsToUpload}
                                    setDocumentsToUpload={setDocumentsToUpload}
                                />
                            </div>
                        )}
                    </CardContent>
                </Card>

                {view === 'input' && (
                    <Card className={classes.options}>
                        <CardContent>
                            <DocumentOptionsSelect
                                isDocumentUpload={true}
                                errors={errors}
                                setErrors={setErrors}
                                folders={allFolders}
                                folderModel={createModel}
                                setFolderModel={setCreateModel}
                                chosenFolder={chosenFolder}
                                setChosenFolder={setChosenFolder}
                                openedFolder={openedFolder}
                            />
                        </CardContent>
                    </Card>
                )}

                {isEditDialogOpen && (
                    <DocumentEdit
                        isEditDialogOpen={isEditDialogOpen}
                        errors={errors}
                        setErrors={setErrors}
                        handleClose={handleClose}
                        documentToEdit={documentToEdit}
                        setDocumentToEdit={setDocumentToEdit}
                        documents={documents}
                        setDocuments={setDocuments}
                        refetchDocuments={getAllDocuments}
                    />
                )}

                <ConfirmAction
                    open={isDeleteDialogOpen}
                    title='Are you sure?'
                    text="Please confirm if you'd like to delete this document"
                    handleDisagree={handleClose}
                    handleAgree={deleteDocument}
                    isLoading={isLoading}
                />
            </div>
        </Fragment>
    )
}

const useStyles = makeStyles({
    textfield: {
        width: '98%',
        margin: '1vh'
    },
    options: {
        marginTop: '2vh'
    },
    dialogStyles: {
        minWidth: '25vw'
    },
    text: {
        maxWidth: '25vw'
    },
    bottomcard: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row'
    },
    auto: {
        width: '40%',
        marginTop: '1vh',
        marginBottom: '1vh'
    },
    siteselect: {
        width: '100%'
    },
    fullMedia: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    card: {
        margin: 'auto'
    },
    basicMargin: {
        marginTop: '3vh',
        marginBottom: '1vh'
    },
    appBar: {
        position: 'relative',
        backgroundColor: '#2196f3'
    },
    optionsFlex: {
        gridColumnStart: '9',
        gridRowStart: '2',
        display: 'flex',
        flexDirection: 'column',
        gridColumnEnd: 'span 2',
        gridRowEnd: 'span 4',
        width: '60%',
        margin: 'auto'
    },
    optionsFlexItems: {
        marginTop: '1vh',
        marginBottom: '1vh'
    },
    dialogGrid: {
        display: 'grid',
        gridTemplateColumns: '10% 10% 10% 10% 10% 10% 10% 10% 10% 10%',
        gridTemplateRows: '10% 10% 10% 10% 10% 10% 10% 10% 10% 10%',
        height: '100%',
        width: '100%'
    }
})
export default DocumentManager
