import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { isEqual } from 'lodash'
import { Autocomplete, TextField } from '@mui/material'
import { storage } from '../../../common/storage.service'
import { EditorsAPI } from '../../../common/constants'
import { useAppContext } from '@/pkgs/auth/atoms'

export const EditorsSelector = ({ setState, state, contentType }) => {
    const appContext = useAppContext()
    const [serverTags, setServerTags] = useState([])
    const [value, setValue] = useState<any[]>([])
    const [tagsAbort, setTagsAbort] = useState(new AbortController())
    const [restored, setRestored] = useState(false)

    const key = `${appContext.tenantID}-${contentType}-selected-editors`

    useEffect(() => {
        if (!restored) return
        if (Array.isArray(state.editors) && state.editors.length === 0 && value.length > 0) {
            onChange(null, [])
        }
    }, [state.editors])

    useEffect(() => {
        const newController = new AbortController()
        tagsAbort.abort()
        setTagsAbort(newController)
        axios
            .get(EditorsAPI, {
                signal: newController.signal
            })
            .then(({ data }) => {
                //@ts-ignore
                const options = data.results.map((o) => ({ label: o.name, id: o.id }))
                setServerTags(options)

                const selected = (storage.getItem(key) || []) as any[]
                setValue(selected)
                if (!isEqual(state.editors, selected)) {
                    setState((prev) => ({
                        ...prev,
                        editors: selected?.map(({ id }) => id),
                        currentPage: 0
                    }))
                }
                setRestored(true)
            })
    }, [])

    function onChange(e, selected) {
        storage.setItem(key, selected)

        setValue(selected)
        setState((prev) => ({
            ...prev,
            editors: selected?.map(({ id }) => id),
            currentPage: 0
        }))
    }

    return (
        <Autocomplete
            style={{ flex: '1' }}
            onChange={onChange}
            multiple
            filterSelectedOptions
            options={serverTags}
            isOptionEqualToValue={(o, v) => o.id === v.id}
            getOptionLabel={(option) => option.label}
            value={value}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant='outlined'
                    label='Editors'
                    placeholder='Editors'
                    helperText='Search for content based on owner or last editor'
                />
            )}
        />
    )
}
