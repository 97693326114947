import React, { useEffect, useState } from 'react'
import {
    Alert,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from '@mui/material'
import axios from 'axios'
import { guessErrorMessage } from '@/helpers/guessErrorMessage.js'
import { LegacyURLsAPI } from '@/common/constants.js'
import { useCurrentSiteID } from '@/pkgs/auth/atoms.js'

type LegacyUrl = {
    id: string
    content_id: string
    url: string
    url_norm: string
    active: boolean
}

type EditUrl = {
    active: boolean
    url: string
    error?: string
}

interface LegacyUrlsProps {
    contentId: string
    hasPermission: boolean
}

export function LegacyUrls({ contentId, hasPermission }: LegacyUrlsProps) {
    const [urls, setUrls] = useState<LegacyUrl[]>([])
    const [editUrls, setEditUrls] = useState<EditUrl[]>([{ active: true, url: '', error: '' }])
    const currentSiteID = useCurrentSiteID()
    const [open, setOpen] = useState(false)
    const [savingError, setSavingError] = useState('')
    const [loading, setLoading] = useState(false)

    const handleClose = (e: React.MouseEvent<HTMLButtonElement>, reason?: string) => {
        if (reason === 'backdropClick') return

        setOpen(false)
        setEditUrls([...urls.map((u) => ({ active: u.active, url: u.url })), { active: true, url: '', error: '' }])
    }

    const load = () => {
        setLoading(true)
        axios.get<LegacyUrl[]>(LegacyURLsAPI + contentId).then(
            (r) => {
                setLoading(false)
                setUrls(r.data)
                const edit = r.data.map((u) => ({ active: u.active, url: u.url }))
                edit.push({ active: true, url: '' })
                setEditUrls(edit)
            },
            (e) => {
                setLoading(false)
                console.log(e)
            }
        )
    }

    const save = () => {
        setSavingError('')
        setLoading(true)
        axios
            .post(
                LegacyURLsAPI + contentId,
                editUrls.filter((u) => u.url)
            )
            .then(
                (r) => {
                    console.log(r)
                    setLoading(false)
                    setOpen(false)
                    load()
                },
                (e) => {
                    setLoading(false)
                    setSavingError(guessErrorMessage(e))
                }
            )
    }

    const handleChange = (elm: keyof EditUrl, index: number, url: EditUrl, value: boolean | string) => {
        const n = { ...url, [elm]: value }
        const arr = [...editUrls]
        arr[index] = n
        const newArr: EditUrl[] = []
        for (let i = 0; i < arr.length - 1; i++) {
            arr[i].url = arr[i].url.trim()
            if (arr[i].url) {
                newArr.push(arr[i])
            }
        }
        newArr.push(arr[arr.length - 1])
        if (newArr[newArr.length - 1].url) {
            newArr.push({ active: true, url: '', error: '' })
        }

        for (let i = 0; i < newArr.length; i++) {
            if (newArr[i].url && !isValidHttpUrl(newArr[i].url)) {
                newArr[i].error = 'Invalid URL. Please, provide absolute URL (https://...)'
            } else if (newArr[i].url) {
                newArr[i].error = ''
            }
        }

        setEditUrls(newArr)
    }

    function isValidHttpUrl(str: string) {
        const pattern = new RegExp(
            '^(https?:\\/\\/)?' + // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$',
            'i'
        ) // fragment locator
        return !!pattern.test(str)
    }

    useEffect(() => {
        load()
    }, [currentSiteID, contentId])

    return (
        <>
            <Button
                onClick={() => setOpen(true)}
                variant='outlined'
                color='primary'
                style={{ display: 'block', width: '100%', marginBottom: '1vh' }}
                disabled={!hasPermission}
            >
                {urls.length > 0 ? 'Edit' : 'Add'} URL Redirects
            </Button>

            {loading && <CircularProgress />}
            {!loading &&
                urls &&
                urls.length > 0 &&
                urls.map((u, index) => {
                    if (u.active) {
                        return (
                            <div className='mono-disabled-text' key={index}>
                                <a style={{ color: 'grey' }} href={u.url} target='_blank' rel='noopener noreferrer'>
                                    {u.url_norm.toLowerCase()}
                                </a>
                            </div>
                        )
                    } else {
                        return (
                            <div className='mono-disabled-text' key={index}>
                                {u.url.toLowerCase()}
                            </div>
                        )
                    }
                })}
            {!loading && urls && urls.length === 0 && <div>No URLs provided</div>}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby='form-dialog-title'
                scroll='paper'
                maxWidth='sm'
                fullWidth
            >
                <DialogTitle id='form-dialog-title'>URL Redirects</DialogTitle>
                <DialogContent>
                    <DialogContentText>Add URLs which should redirect users to this page</DialogContentText>

                    {savingError && <Alert severity='error'>{savingError}</Alert>}
                    {editUrls.map((url, index) => (
                        <div key={index}>
                            <Checkbox
                                checked={url.active}
                                onChange={(event) => handleChange('active', index, url, event.target.checked)}
                            />
                            <TextField
                                variant='standard'
                                value={url.url}
                                error={!!url.error}
                                helperText={url.error}
                                onChange={(event) => {
                                    handleChange('url', index, url, event.target.value)
                                }}
                                style={{ width: '85%' }}
                            />
                        </div>
                    ))}
                </DialogContent>
                <DialogActions>
                    {loading && <CircularProgress />}
                    <Button disabled={loading} onClick={handleClose} color='primary'>
                        Cancel
                    </Button>
                    <Button
                        disabled={loading || Boolean(editUrls?.some((x) => Boolean(x?.error)))}
                        onClick={save}
                        color='primary'
                    >
                        Save changes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
