import {
    DOMConversionMap,
    DOMConversionOutput,
    DOMExportOutput,
    EditorConfig,
    ElementFormatType,
    LexicalEditor,
    LexicalNode,
    NodeKey,
    Spread
} from 'lexical'

import { BlockWithAlignableContents } from '@lexical/react/LexicalBlockWithAlignableContents'
import { DecoratorBlockNode, SerializedDecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode'
import { Content } from '../../content/types'
import ContentFragmentHtml from './ContentFragmentHtml'

const domNodeContentFragmentAttrId = 'data-lexical-content-fragment'
const domNodeContentFragmentHtmlTag = 'ie-fragment'

type PartialContent = Partial<Content>

function getContentFragmentHtml(content: PartialContent) {
    if (!content.ID) {
        console.error(`getContentFragmentHtml missing id`)
        return null
    }
    const element = document.createElement(domNodeContentFragmentHtmlTag)
    element.setAttribute(domNodeContentFragmentAttrId, content.ID)
    element.setAttribute('data-id', content.ID)
    return element
}

type ContentFragmentNodeComponentProps = Readonly<{
    className: Readonly<{
        base: string
        focus: string
    }>
    format: ElementFormatType | null
    nodeKey: NodeKey
    content: PartialContent
}>

function ContentFragmentNodeComponent({ className, format, nodeKey, content }: ContentFragmentNodeComponentProps) {
    return (
        <BlockWithAlignableContents className={className} format={format} nodeKey={nodeKey}>
            {content.ID && <ContentFragmentHtml id={content.ID} nodeKey={nodeKey} />}
        </BlockWithAlignableContents>
    )
}

export type SerializedContentFragmentNode = Spread<
    {
        content: PartialContent
    },
    SerializedDecoratorBlockNode
>

function convertContentFragmentElement(domNode: HTMLElement): null | DOMConversionOutput {
    const parsedContent: PartialContent = {
        ID: domNode.getAttribute(domNodeContentFragmentAttrId) || undefined
    }

    if (!parsedContent?.ID) {
        console.error(`renderContentFragment missing ID:${parsedContent?.ID || ''}`)
        return null
    }

    const node = $createContentFragmentNode(parsedContent)
    return { node }
}

export class ContentFragmentNode extends DecoratorBlockNode {
    content: PartialContent

    constructor(content: PartialContent, format?: ElementFormatType, key?: NodeKey) {
        super(format, key)
        this.content = content
    }

    static getType(): string {
        return 'content-fragment'
    }

    static clone(node: ContentFragmentNode): ContentFragmentNode {
        return new ContentFragmentNode(node.content, node.__format, node.__key)
    }

    static importJSON(serializedNode: SerializedContentFragmentNode): ContentFragmentNode {
        const node = $createContentFragmentNode(serializedNode.content)
        node.setFormat(serializedNode.format)
        return node
    }

    exportJSON(): SerializedContentFragmentNode {
        return {
            ...super.exportJSON(),
            type: 'content-fragment',
            version: 1,
            content: this.content
        }
    }

    exportDOM(): DOMExportOutput {
        return { element: getContentFragmentHtml(this.content) }
    }

    static importDOM(): DOMConversionMap | null {
        return {
            [domNodeContentFragmentHtmlTag]: (domNode: HTMLElement) => {
                if (!domNode.hasAttribute(domNodeContentFragmentAttrId)) {
                    return null
                }

                const conversionTest = convertContentFragmentElement(domNode)
                if (!conversionTest) {
                    console.warn('ContentFragmentNode failed to convert DOM to Node')
                }

                return {
                    conversion: convertContentFragmentElement,
                    priority: 0
                }
            }
        }
    }

    updateDOM(): false {
        return false
    }

    getTextContent(_includeInert?: boolean | undefined, _includeDirectionless?: false | undefined): string {
        return this.content?.Title || ''
    }

    decorate(_editor: LexicalEditor, config: EditorConfig): JSX.Element {
        // TODO: BlockWithAlignableContents depends on className but theme is yet to be defined for ContentFragmentNodes
        // so we just leave this here and should have no impact
        const embedBlockTheme = config.theme.embedBlock || {}
        const className = {
            base: embedBlockTheme.base || '',
            focus: embedBlockTheme.focus || ''
        }
        return (
            <ContentFragmentNodeComponent
                className={className}
                format={this.__format}
                nodeKey={this.getKey()}
                content={this.content}
            />
        )
    }
}

export function $createContentFragmentNode(content: PartialContent): ContentFragmentNode {
    return new ContentFragmentNode(content)
}

export function $isContentFragmentNode(
    node: ContentFragmentNode | LexicalNode | null | undefined
): node is ContentFragmentNode {
    return node instanceof ContentFragmentNode
}
