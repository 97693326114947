import { LexicalNode } from 'lexical'

export function $findClosestInstanceOf<T extends LexicalNode>(
    targetType: new (...args: any[]) => T,
    node: LexicalNode | null
): T | null {
    while (node !== null) {
        if (node instanceof targetType) {
            return node
        }
        node = node.getParent()
    }
    return null
}
