import React from 'react'
import { createRoot } from 'react-dom/client'
import { App } from './app/App'

// import * as serviceWorker from './serviceWorker'
import 'react-datepicker/dist/react-datepicker-cssmodules.min.css'
import './index.css'
import axios from 'axios'

axios.interceptors.request.use(
    function (config) {
        config.withCredentials = true
        return config
    },
    function (error) {
        return Promise.reject(error)
    }
)

// React 18
// @ts-ignore
createRoot(document.getElementById('root')).render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
