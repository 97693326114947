import { Box, FormLabel, MenuItem, Popover, SxProps } from '@mui/material'
import { DatePicker, TimeField } from '@mui/x-date-pickers'
import { DigitalClock } from '@mui/x-date-pickers/DigitalClock'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { IconButton } from '@mui/material'
import moment, { Moment } from 'moment-timezone'

function getTimePastMinutes(start: Moment, end: Moment) {
    return Math.floor(moment.duration(end.diff(start)).asMinutes())
}

function isPast24Hours(minutes: number) {
    return minutes > 1440
}

function roundNonWholeNumber(num: number) {
    const hasDecimal = String(num).split('.')[1]?.length
    if (hasDecimal) return num.toFixed(2)
    return num
}

type NullableMoment = Moment | null | undefined

interface DigitalClockMenuItemProps {
    itemProps: any
    startDate?: NullableMoment
    endDate?: NullableMoment
    showHelperText?: boolean
}

// Adds helper text to the time picker menu items: "9:45 AM (2 hours)"
function DigitalClockMenuItem({ itemProps, startDate, endDate, showHelperText = false }: DigitalClockMenuItemProps) {
    function getHelperText() {
        if (!startDate || !showHelperText) return ''

        let minutes = -1
        if (endDate) {
            if (endDate.isBefore(startDate) && !endDate.isSame(startDate, 'day')) {
                return ''
            }

            const actualMinutes = getTimePastMinutes(
                startDate,
                setMomentDateTime(endDate.clone(), moment(itemProps.children, 'hh:mm A'))
            )
            if (isPast24Hours(actualMinutes) || actualMinutes < 0) return ''
            minutes = actualMinutes
        } else {
            minutes = getTimePastMinutes(
                startDate,
                setMomentDateTime(startDate.clone(), moment(itemProps.children, 'hh:mm A'))
            )
        }

        // if minutes < 0, the end date used to compute minutes is earlier than the start date
        // because there is no end date input yet, we assume the end date will be within 24 hours (today/tomorrow). Thus if minutes is negative, we assume it's the following day and continue display helper text
        if (minutes < 0) {
            minutes += 1440
        }
        const shouldDisplayHour = minutes > 59
        return ` - ${shouldDisplayHour ? roundNonWholeNumber(minutes / 60) : minutes} ${
            shouldDisplayHour ? (minutes > 120 ? 'hrs' : 'hr') : 'mins'
        }`
    }

    if (itemProps.disabled) {
        return null
    }

    const helperText = getHelperText()
    const highlight = helperText == ' - 0 mins'
    return (
        <MenuItem {...itemProps} sx={highlight ? { backgroundColor: '#E5F6FD' } : undefined}>
            {itemProps.children} {helperText}
        </MenuItem>
    )
}

interface EventTimePickerProps {
    id: string
    timeStep: number
    onChange: (e: any) => void
    timeFieldValue?: NullableMoment
    startDate?: NullableMoment
    endDate?: NullableMoment
    showHelperText?: boolean
    disablePast?: boolean
}

export function EventTimePicker({
    id,
    timeStep,
    timeFieldValue,
    onChange,
    startDate,
    endDate,
    showHelperText,
    disablePast
}: EventTimePickerProps) {
    const timeField = useRef(null)
    const [open, setOpen] = useState(false)
    return (
        <>
            <Box ref={timeField} aria-describedby={id} sx={{ position: 'relative' }}>
                <TimeField
                    value={timeFieldValue}
                    slotProps={{
                        textField: {
                            size: 'small',
                            variant: 'standard'
                        }
                    }}
                    onChange={onChange}
                />
                <IconButton
                    aria-label='delete'
                    onClick={() => setOpen(!open)}
                    sx={{ position: 'absolute', right: 0, top: -8 }}
                >
                    <AccessTimeIcon />
                </IconButton>
            </Box>
            <Popover
                id={id}
                open={open}
                anchorEl={timeField.current}
                onClose={() => setOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <DigitalClock
                    timeStep={timeStep}
                    minTime={disablePast ? startDate : undefined}
                    disableIgnoringDatePartForTimeValidation={disablePast ? true : false}
                    slots={{
                        digitalClockItem: (itemProps) => (
                            <DigitalClockMenuItem
                                itemProps={itemProps}
                                startDate={startDate}
                                endDate={endDate}
                                showHelperText={showHelperText}
                            />
                        )
                    }}
                    onChange={(e: any) => {
                        onChange(e)
                        setOpen(false)
                    }}
                />
            </Popover>
        </>
    )
}

function setMomentDateTime(date: Moment, dateWithNewTime: Date | Moment | string) {
    const momentDateWithNewTime = moment(dateWithNewTime)
    return date.clone().set({
        hour: momentDateWithNewTime.hour(),
        minute: momentDateWithNewTime.minute()
    })
}

function setMomentDateDay(date: Moment, dateWithNewDay: Date | Moment | string) {
    const momentDateWithNewDay = moment(dateWithNewDay)
    return date.clone().set({
        date: momentDateWithNewDay.date(),
        month: momentDateWithNewDay.month(),
        year: momentDateWithNewDay.year()
    })
}

export function SingleDateTimePicker({
    date,
    dateOnChange,
    timeStep = 15,
    disabled,
    disableTime,
    required,
    label,
    helperText,
    error,
    handleOnError,
    format,
    sx
}: {
    date: string
    dateOnChange: (d: string) => void
    timeStep?: number
    disabled?: boolean
    disableTime?: boolean
    required?: boolean
    label?: string
    helperText?: string
    error?: boolean
    handleOnError?: (error: string | null) => void
    format?: string
    sx?: SxProps
}) {
    const momentDate = useMemo(() => (date ? moment(date) : null), [date])

    // attempt to colourize startDate on date picker
    // useEffect(() => {
    //     if (startDate) {
    //         const items = document.getElementsByClassName('MuiPickersDay-root')
    //         for (let i = 0; i < items.length; i++) {
    //             const htmlEl = items.item(i) as HTMLElement
    //             if (htmlEl.dataset?.timestamp == startDate?.valueOf().toString()) {
    //                 htmlEl.style.backgroundColor = 'red !important'
    //             }
    //         }
    //     }

    // })

    const _dateOnChange = useCallback(
        (m: Moment) => {
            dateOnChange(m?.toDate().toString())
        },
        [dateOnChange]
    )

    return (
        <Box display='flex' flexDirection='column' gap='12px' sx={sx}>
            <Box display='flex' gap='12px'>
                <DatePicker
                    disabled={disabled}
                    format={format}
                    value={momentDate}
                    label={label}
                    onError={handleOnError} // "invalidDate" or null
                    slotProps={{
                        textField: {
                            size: 'small',
                            variant: 'standard',
                            required: required,
                            helperText: helperText,
                            error: error
                        }
                    }}
                    onChange={(val: moment.Moment | null) => {
                        if (val?.isValid()) {
                            if (momentDate) {
                                _dateOnChange(setMomentDateDay(momentDate, val))
                            } else {
                                _dateOnChange(val)
                            }
                        }
                    }}
                />
                {!disableTime && (
                    <EventTimePicker
                        id={'event-startDate'}
                        timeStep={timeStep}
                        startDate={momentDate}
                        timeFieldValue={momentDate}
                        onChange={(e) => {
                            if (momentDate) {
                                _dateOnChange(setMomentDateTime(momentDate, e))
                            } else {
                                _dateOnChange(e)
                            }
                        }}
                    />
                )}
            </Box>
        </Box>
    )
}
