import { Box, Grid, List, ListItemButton, ListItemText, Stack, Typography } from '@mui/material'
import { ReactNode, useState } from 'react'
import PageContainerWithHeader from './PageContainerWithHeader'

interface SidebarContainerTabs {
    name: string
    content: ReactNode
}

interface SidebarContainerProps {
    tabs: SidebarContainerTabs[] // navigation
    title: string
    subtitle?: string
    topRightElement?: JSX.Element | null
}

function SidebarContainer({ tabs, title, subtitle, topRightElement }: SidebarContainerProps) {
    const [currentTabIndex, setCurrentTabIndex] = useState(0)

    return (
        <PageContainerWithHeader title={title} topRightElement={topRightElement}>
            {subtitle && (
                <Typography variant='h6' sx={{ pb: '1em' }}>
                    {subtitle}
                </Typography>
            )}
            <div className={'row'}>
                <div className={'col-xs-12 col-md-2'} style={{ borderRight: '0.5px solid grey', paddingRight: '1rem' }}>
                    <List>
                        {tabs.map((tab, idx) => {
                            return (
                                <ListItemButton
                                    key={tab.name}
                                    selected={idx == currentTabIndex}
                                    onClick={() => setCurrentTabIndex(idx)}
                                >
                                    <ListItemText primary={tab.name} style={{ textTransform: 'capitalize' }} />
                                </ListItemButton>
                            )
                        })}
                    </List>
                </div>
                <Box
                    className={'col-xs-12 col-md-10'}
                    paddingLeft='20px'
                    sx={{
                        '& .sidebarContainer-row:hover': {
                            backgroundColor: '#f0f0f0',
                            transition: 'background-color 100ms linear'
                        },
                        // '& .MuiBox-root': {
                        //     maxWidth: '700px',
                        // },
                        paddingLeft: '1rem'
                    }}
                >
                    {tabs?.[currentTabIndex]?.content}
                </Box>
            </div>
        </PageContainerWithHeader>
    )
}

export default SidebarContainer
