import { $getNodeByKey, LexicalEditor, LexicalNode, NodeKey, TextNode } from 'lexical'

// Copied over functions from lexical/LexicalUtils

export function getNodeKeyFromDOM(
    // Note that node here refers to a DOM Node, not an Lexical Node
    dom: Node,
    editor: LexicalEditor
): NodeKey | null {
    let node: Node | null = dom
    while (node != null) {
        // @ts-ignore We intentionally add this to the Node.
        const key: NodeKey = node[`__lexicalKey_${editor._key}`]
        if (key !== undefined) {
            return key
        }
        node = getParentElement(node)
    }
    return null
}

export function $getNodeFromDOM(dom: Node, editor: LexicalEditor): null | LexicalNode {
    const nodeKey = getNodeKeyFromDOM(dom, editor)
    if (nodeKey === null) {
        const rootElement = editor.getRootElement()
        if (dom === rootElement) {
            return $getNodeByKey('root')
        }
        return null
    }
    return $getNodeByKey(nodeKey)
}

// From lexical/LexicalUtils.ts
export function getTextNodeOffset(node: TextNode, moveSelectionToEnd: boolean): number {
    return moveSelectionToEnd ? node.getTextContentSize() : 0
}

// From lexical/LexicalUtils.ts
export function getParentElement(node: Node): HTMLElement | null {
    const parentElement = (node as HTMLSlotElement).assignedSlot || node.parentElement
    return parentElement !== null && parentElement.nodeType === 11
        ? ((parentElement as unknown as ShadowRoot).host as HTMLElement)
        : parentElement
}
