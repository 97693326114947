import React, { useEffect, useState } from 'react'
import { GridColDef } from '@mui/x-data-grid'
import { httpGet } from '../../../common/client'
import { is, notify } from '../../../helpers'
import { relays, Relays, RelaysQuery } from './types'
import { AddButton, DialogFormAction } from '../../../common/components'
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { ToastContainer } from 'react-toastify'
import EditIcon from '@mui/icons-material/Edit'
import axios from 'axios'
import { useStateWithStorage } from '../../../common/storage.service'
import PageContainerWithHeader from '../../../common/components/PageContainerWithHeader'
import { defaultPageQuery } from '../../../common/react-query'
import { RelaysAPI } from '../../../common/constants'
import { DataGridBase } from '../../grid/DataGridBase'
import { WhatsNew } from '../../../common/components/WhatsNew'

interface RelayModel {
    ID?: string
    Type: string | ''
    XData: {
        [key: string]: string | number
        Host: string
        Port: number
        Password: string
        Username: string
        Sender: string
        ReplyTo: string
    }
}

const SMTPTypes = {
    plain: {
        value: 'plain_smtp',
        label: 'Plain SMTP'
    },
    api: {
        value: 'sendgrid',
        label: 'API Key (SendGrid)'
    }
}
const columns: GridColDef[] = [
    { field: 'ID', headerName: 'ID', width: 200, sortable: true },
    { field: 'Type', headerName: 'Type', width: 200, sortable: true },
    {
        headerName: 'Host',
        field: 'Host',
        disableColumnMenu: true,
        flex: 1,
        renderCell: (cellParams) => <CellFromObjectField object={cellParams.row.XData} field={'Host'} />
    },
    {
        headerName: 'Sender',
        field: 'Sender',
        disableColumnMenu: true,
        flex: 1,
        renderCell: (cellParams) => <CellFromObjectField object={cellParams.row.XData} field={'Sender'} />
    },
    {
        headerName: 'Username',
        field: 'Username',
        disableColumnMenu: true,
        flex: 1,
        renderCell: (cellParams) => <CellFromObjectField object={cellParams.row.XData} field={'Username'} />
    }
]

const CellFromObjectField = (cellParams: { object: Record<any, any>; field: any }) => {
    const { object, field } = cellParams
    return <div>{object[field] || ''}</div>
}

const NilRelay = {
    Type: '',
    XData: {
        Host: '',
        Port: 0,
        Password: '',
        Username: '',
        Sender: '',
        ReplyTo: ''
    }
}
const actionMenuCell = (onClickFunction: (x?: any) => any) => {
    if (!is.func(onClickFunction)) {
        return {}
    }
    return {
        headerName: 'Actions',
        field: 'menu',
        disableColumnMenu: true,
        filterable: false,
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        renderCell: (cellParams: any) => (
            <div style={{ opacity: '0.55', cursor: 'pointer' }} onClick={() => onClickFunction(cellParams?.row?.ID)}>
                <EditIcon />
            </div>
        )
    }
}
export const RelaysGrid = () => {
    const [query, setQuery] = useStateWithStorage<RelaysQuery>('relays-grid-query', { ...defaultPageQuery })
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
    const [contextModel, setContextModel] = useState<RelayModel>(NilRelay)
    const [contextModelErrors, setContextModelErrors] = useState<Record<string, boolean>>({})
    const [gridState, setGridState] = useState<Relays>({
        TotalRecords: 0,
        TotalPages: 0,
        Offset: 0,
        PageSize: 10,
        Page: 1,
        Rows: []
    })

    useEffect(() => {
        getRecords()
    }, [query])

    function resetContextModel() {
        setContextModel(NilRelay)
    }

    function openRelayDialog(id?: string) {
        if (id) {
            const elementIndex = gridState.Rows.findIndex((row) => row.ID === id)
            if (elementIndex > -1) {
                console.log(gridState.Rows[elementIndex])
                //@ts-ignore
                setContextModel(gridState.Rows[elementIndex])
            }
        }
        setIsDialogOpen(true)
    }

    function closeRelayDialog(event?: MouseEvent, reason?: string) {
        if (reason && reason === 'backdropClick') {
            return
        }
        setIsDialogOpen(false)
        resetContextModel()
    }

    function toggleOffError(name: string) {
        if (Boolean(contextModelErrors?.[name])) {
            setContextModelErrors((prev) => ({
                ...prev,
                [name]: false
            }))
        }
    }

    function handleXDataOnChange(evt: any) {
        const { name, value } = evt?.target || {}
        setContextModel((prev) => ({
            ...prev,
            XData: {
                ...prev.XData,
                [name]: value
            }
        }))
        toggleOffError(name)
    }

    function findRelayErrors() {
        const errors: Record<string, boolean> = {}
        let anyErrors = false
        if (!contextModel.Type?.length) {
            errors['type'] = true
            anyErrors = true
        }
        for (const field in NilRelay.XData) {
            const value = contextModel.XData?.[field]
            if (!value) {
                errors[field] = true
                anyErrors = true
            }
        }
        setContextModelErrors(errors)
        return anyErrors
    }

    function isUpdateContext() {
        return Boolean(contextModel?.ID?.length)
    }

    async function getRecords() {
        return httpGet(`${RelaysAPI}`, query, relays)
            .then((res) => {
                setGridState(res)
            })
            .catch((err) => {
                console.error(err)
                notify(err)
            })
    }

    async function upsertRelay() {
        try {
            if (findRelayErrors()) {
                notify('Whoops! Some values are missing', 'error')
                return
            }
            // MUI (V5) TextField returns a string when Type is Numeric
            // Even though Port: Number, it gets stringified through the TextField
            // https://mui.com/material-ui/react-text-field/#type-quot-number-quot
            if (typeof contextModel.XData.Port === 'string') {
                contextModel.XData.Port = isNaN(Number(contextModel.XData.Port)) ? 0 : Number(contextModel.XData.Port)
            }
            if (isUpdateContext()) {
                return await axios
                    .patch(`${RelaysAPI}/${contextModel.ID}`, contextModel)
                    .then(() => getRecords())
                    .then(() => closeRelayDialog())
                    .catch((e) => console.log(e))

                // return await httpPatch(`${RelaysAPI}/${contextModel.ID}`, contextModel, relays)
                // .then(() => getRecords())
                // .then(() => closeRelayDialog())
                // .catch((e) => console.log(e))
            } else {
                return await axios
                    .post(RelaysAPI, contextModel)
                    .then(() => getRecords())
                    .then(() => closeRelayDialog())
                    .catch((e) => console.log(e))

                // return await httpPost(RelaysAPI, contextModel, relays)
                //     .then(() => getRecords())
                //     .then(() => closeRelayDialog())
                //     .catch((e) => console.log(e))
            }
        } catch (e) {
            return e
        }
    }

    return (
        <PageContainerWithHeader
            title='Relays'
            titleSlot={<WhatsNew
                link={'https://cdn.cmdesign.imagineeverything.com/notifications-1718912661937?hash=doL25vdGlmaWNhdGlvbnMtMTcxODkxMjY2MTkzNw=='} />}
            topRightElement={<AddButton title={'Add Relay'} func={openRelayDialog} />}
        >
            <ToastContainer />
            <DataGridBase
                columns={[
                    //@ts-ignore
                    ...columns,
                    //@ts-ignore
                    actionMenuCell(openRelayDialog)
                ]}
                state={gridState}
                setQuery={setQuery}
            />

            <DialogFormAction
                item={
                    <div>
                        <FormControl className='max-width' style={{ margin: '0.35rem' }}>
                            <InputLabel>Relay Type</InputLabel>
                            <Select
                                label='Relay Type'
                                value={contextModel.Type!}
                                renderValue={(selected: any) => {
                                    switch (selected) {
                                        case SMTPTypes.plain.value:
                                            return SMTPTypes.plain.label
                                        case SMTPTypes.api.value:
                                            return SMTPTypes.api.label
                                    }
                                }}
                                error={Boolean(contextModelErrors?.type)}
                                disabled={isUpdateContext()}
                                onChange={(event) => {
                                    setContextModel((prev) => ({
                                        ...prev,
                                        Type: event?.target?.value || SMTPTypes.plain.value
                                    }))
                                    toggleOffError('Type')
                                }}
                            >
                                {Object.values(SMTPTypes).map(({ value, label }) => (
                                    <MenuItem key={`${value}-${label}`} value={value}>
                                        {label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {Object.entries(NilRelay.XData).map(([field, value]) => {
                            return (
                                <TextField
                                    key={`${field}-${value}`}
                                    value={contextModel.XData?.[field] || ''}
                                    onChange={handleXDataOnChange}
                                    name={field}
                                    label={field}
                                    type={typeof value === 'number' ? 'number' : 'text'}
                                    style={{ margin: '0.25rem' }}
                                    error={Boolean(contextModelErrors?.[field])}
                                />
                            )
                        })}
                    </div>
                }
                open={isDialogOpen}
                title={`${isUpdateContext() ? 'Update' : 'Add'} SMTP Configuration`}
                buttonDisagreeLabel='Cancel'
                buttonAgreeLabel={isUpdateContext() ? 'Save' : 'Create'}
                handleDisagree={closeRelayDialog}
                handleClose={closeRelayDialog}
                handleAgree={upsertRelay}
                fullWidth
                text={undefined}
                alternate={undefined}
                alternateAction={undefined}
                headerComponent={undefined}
            />
        </PageContainerWithHeader>
    )
}
