import { Accordion, AccordionDetails, AccordionSummary, alpha } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { CSSProperties } from 'react'
import { colours } from '../colours'

interface AppAccordion {
    summary: JSX.Element
    details: JSX.Element
    expanded?: boolean // controller
    defaultExpanded?: boolean
    onChangeHandler?: (expanded: boolean) => void
    styles?: CSSProperties
    withoutPadding?: boolean
    unmountOnExit?: boolean
}

export function AppAccordion({
    expanded,
    defaultExpanded,
    onChangeHandler,
    summary,
    details,
    styles,
    withoutPadding,
    unmountOnExit
}: AppAccordion) {
    return (
        <Accordion
            TransitionProps={{
                unmountOnExit: unmountOnExit
            }}
            defaultExpanded={defaultExpanded === undefined ? true : defaultExpanded}
            expanded={expanded}
            onChange={(ev, expanded) => onChangeHandler?.(expanded)}
            sx={{
                '&.MuiAccordion-root': {
                    marginTop: '0.75em',
                    ...styles
                },
                marginBottom: '0.75em',
                ...styles
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                    '&:hover': { backgroundColor: alpha(colours.base_blue, 0.25) }
                }}
            >
                {summary}
            </AccordionSummary>
            <AccordionDetails sx={withoutPadding ? { padding: 0 } : undefined}>{details}</AccordionDetails>
        </Accordion>
    )
}

export default AppAccordion
