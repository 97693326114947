import { useTemplatesQuery } from '../queries'
import React from 'react'
import { Alert, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import { guessErrorMessage } from '@/helpers/guessErrorMessage'
import { Content, ContentType } from '../../types'

export type BaseTemplate = Pick<Content, 'ID' | 'Path' | 'Title' | 'Structures'>

type TemplateSelectorProps = {
    ownerID: string
    path: string
    onChange: (path: string, template: BaseTemplate | undefined) => void
    onLoaded?: (template: BaseTemplate) => void
    classifications?: string[]
    templateType: 'dct' | 'formless' | 'all'
    disabled?: boolean
    error: string | undefined | null
    ignoreNotFound?: boolean
    label?: string
    required?: boolean
}

export const TemplateSelector = ({
    ownerID,
    path,
    onChange,
    classifications = [ContentType.Page],
    templateType,
    disabled,
    error,
    ignoreNotFound = false,
    onLoaded,
    label = 'Template',
    required
}: TemplateSelectorProps) => {
    const suffix = normalizeSuffix(ownerID)
    path = path || suffix

    const [query, setQuery] = React.useState({
        page: 1,
        pageSize: 1000,
        TemplateType: templateType,
        Classifications: classifications
    })

    const result = useTemplatesQuery(query)

    React.useEffect(() => {
        setQuery({ ...query, TemplateType: templateType, Classifications: classifications })
    }, [templateType, classifications])

    const templates = React.useMemo<BaseTemplate[] | undefined>(() => {
        if (!result.data) {
            return undefined
        }

        const filteredTemplates = result.data.Rows.filter((t) => {
            const tplID = normalizeSuffix(t.ID)
            const normOwnerID = normalizeSuffix(ownerID)

            if (tplID === normOwnerID) {
                return false
            }

            if (normOwnerID && t.Path.includes(normOwnerID)) {
                return false
            }

            return true
        })

        if (path === suffix) {
            return filteredTemplates
        }

        const template = filteredTemplates.find((t) => t.Path === removeSuffix(path))
        if (template) {
            onLoaded && setTimeout(() => onLoaded(template), 50)
            return filteredTemplates
        } else if (ignoreNotFound) {
            filteredTemplates[0] &&
                setTimeout(() => onChange(addSuffix(filteredTemplates[0].Path), filteredTemplates[0]), 50)
            return filteredTemplates
        }

        // when we can't ignore `ignoreNotFound` we should add a currently selected template to the list
        const newTemplates: BaseTemplate[] = filteredTemplates
        newTemplates.push({
            Title: `[Not found]: ${removeSuffix(path)}`,
            Path: removeSuffix(path),
            Structures: null,
            ID: ''
        })

        return newTemplates
    }, [result.data, path, ownerID, ignoreNotFound, onLoaded, onChange])

    function normalizeSuffix(suffix: string) {
        return suffix.replaceAll('-', '')
    }

    function removeSuffix(path: string) {
        if (!suffix) {
            return path
        }

        if (!path.includes(suffix)) {
            return path
        }
        return path.replaceAll(suffix, '').replace(/\.$/, '')
    }

    function addSuffix(path: string) {
        if (!suffix) {
            return path
        }

        if (path.includes(suffix)) {
            return path
        }
        return `${path}.${suffix}`
    }

    React.useEffect(() => {
        if (required && templates?.length === 1 && path != templates[0].Path) {
            setTimeout(() => onChange(addSuffix(templates[0].Path), templates[0]), 100)
        }
    }, [templates])

    return (
        <>
            {result.isLoading && <div>Loading...</div>}
            {result.error && <Alert severity='error'>{guessErrorMessage(result.error)}</Alert>}
            {templates && Array.isArray(templates) && templates.length === 0 && (
                <Alert severity='info'>No templates found</Alert>
            )}
            {templates && (
                <FormControl variant={'outlined'} sx={{ width: '100%' }}>
                    <InputLabel required={required} error={!!error}>
                        {label}
                    </InputLabel>
                    <Select
                        value={removeSuffix(path)}
                        onChange={(e) => {
                            const newValue = e.target.value as string
                            const template = templates.find((t) => t.Path === newValue)
                            onChange(addSuffix(newValue), template)
                        }}
                        renderValue={(value: string) => {
                            if (!value || value === suffix) {
                                return <em>None</em>
                            }
                            const template = templates.find((t) => t.Path === removeSuffix(value))
                            return template?.Title
                        }}
                        label={label}
                        error={!!error}
                        disabled={disabled}
                    >
                        <MenuItem value={suffix}>
                            <em>None</em>
                        </MenuItem>
                        {templates.map((t) => (
                            <MenuItem key={t.Path} value={t.Path}>
                                {t.Title}
                            </MenuItem>
                        ))}
                    </Select>
                    {error ? <FormHelperText error={true}>{error}</FormHelperText> : undefined}
                </FormControl>
            )}
        </>
    )
}
