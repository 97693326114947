import { Box, Button, Link, Stack, Typography } from '@mui/material'
import { IDToNameCell } from '../grid/cells/GridCells'
import moment from 'moment'
import { useAppNavigation } from '../../app/useAppNavigation'
import { colours } from '../../common/colours'
import CenteredSpinner from '../../common/components/CenteredSpinner'
import { HistoryType, sanitizeSysPeriod, useHistoryQuery } from '../history/queries'
import { getPublishStatus, publishStatusColour } from './editor/ContentEditorSaveCard'
import { Content } from '@/pkgs/content/types'
import { ObjectAsJson } from '@/common/ObjectAsJson'
import React from 'react'

function Dot({ styles }) {
    const size = '8px'
    return (
        <span
            style={{
                height: size,
                width: size,
                backgroundColor: colours.orange,
                borderRadius: '50%',
                display: 'inline-block',
                marginLeft: '5px',
                ...styles
            }}
        ></span>
    )
}

interface RevisionHistoryListProps {
    content: Content
}

export function RevisionHistoryList({ content }: RevisionHistoryListProps) {
    const { data, isLoading } = useHistoryQuery(content.ID, HistoryType.CONTENT)
    const [asJson, setAsJson] = React.useState(undefined)
    const { getRelativePath } = useAppNavigation()
    const revisionHistoryExists = !!data?.Rows

    if (isLoading) {
        return <CenteredSpinner />
    }

    return (
        <Box maxHeight={'500px'} overflow={'auto'}>
            <Box
                sx={{
                    padding: '12px',
                    '&:hover': {
                        backgroundColor: colours.off_white
                    }
                }}
            >
                <Typography variant={'subtitle1'}>Current version:</Typography>
                <Box display={'flex'} gap={'8px'} alignItems={'center'} flexWrap={'wrap'}>
                    <Typography variant={'subtitle2'}>Created:</Typography>
                    {moment(content.Created).format('MM/DD/YYYY, h:mm a')}
                    <Typography variant={'subtitle2'}>Updated:</Typography>
                    {moment(content.Updated).format('MM/DD/YYYY, h:mm a')}
                </Box>
                {content.Owner === content.Publisher ? (
                    <Box>
                        <Typography variant={'subtitle2'}>Owner, Publisher:</Typography>
                        <IDToNameCell tableName={'account'} ID={content.Owner} />
                    </Box>
                ) : (
                    <>
                        <Box>
                            <Typography variant={'subtitle2'}>Owner:</Typography>
                            <IDToNameCell tableName={'account'} ID={content.Owner} />
                        </Box>
                        <Box>
                            <Typography variant={'subtitle2'}>Publisher:</Typography>
                            <IDToNameCell tableName={'account'} ID={content.Publisher} />
                        </Box>
                    </>
                )}
                <Button variant={'outlined'} onClick={() => setAsJson(content.Settings)}>
                    Settings column
                </Button>
            </Box>

            {revisionHistoryExists &&
                data.Rows.map((row, idx) => {
                    const publishStatus = getPublishStatus(row.publish_at, row.expire_at)
                    const dotColour = publishStatusColour[publishStatus]

                    return (
                        <Box
                            key={row.sys_period}
                            sx={{
                                padding: '12px',
                                '&:hover': {
                                    backgroundColor: colours.off_white
                                }
                            }}
                        >
                            <Stack direction='row' spacing='8px' sx={{ alignItems: 'center' }}>
                                <Dot styles={{ backgroundColor: dotColour }} />
                                <Typography variant='subtitle1'>
                                    {moment(sanitizeSysPeriod(row.sys_period)).format('MM/DD/YYYY, h:mm a')}
                                </Typography>
                            </Stack>
                            <Link
                                href={getRelativePath(`/user-management/accounts/${row.publisher}`)}
                                target='_blank'
                                sx={{
                                    textDecoration: 'none',
                                    '&:hover': {
                                        textDecoration: 'underline'
                                    }
                                }}
                            >
                                <IDToNameCell tableName={'account'} ID={row.publisher} />
                            </Link>
                        </Box>
                    )
                })}

            {asJson && <ObjectAsJson obj={asJson} title={`Settings column`} onClose={() => setAsJson(undefined)} />}
        </Box>
    )
}
