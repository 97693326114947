import { useMemo } from 'react'
import { FORMAT_ELEMENT_COMMAND, FORMAT_TEXT_COMMAND } from 'lexical'
import { TOGGLE_LINK_COMMAND } from '../Link/LinkNode'

// Ensure that there aren't overlapping Tools|Groups in the requested toolbar
export const parseToolbarOptions = (toolbarOptions?: string[]): string[] => {
    if (!toolbarOptions || !Array.isArray(toolbarOptions) || toolbarOptions.length === 0) {
        return Object.keys(ToolButtons)
    }
    const ref = toolbarOptions.reduce((acc, iter) => ({ ...acc, [iter]: true }), {} as Record<string, boolean>)
    if (ref['format']) {
        delete ref['bold']
        delete ref['italic']
        delete ref['underline']
        delete ref['strikethrough']
    }
    if (ref['align']) {
        delete ref['left']
        delete ref['right']
        delete ref['center']
        delete ref['justify']
    }
    return Object.keys(ref)
}

type Tool =
    | 'bold'
    | 'italic'
    | 'underline'
    | 'strikethrough'
    | 'link'
    | 'divider'
    | 'left'
    | 'right'
    | 'center'
    | 'justify'
type ToolGroup = 'align' | 'format'

interface ToolbarButtonProps {
    onClick: () => void
    label: string
    icon: string
    active?: boolean
    disabled?: boolean
}

export function Divider() {
    return <div className='divider' />
}

export const ToolbarButton = ({ onClick, label, icon, active = false, disabled = false }: ToolbarButtonProps) => {
    return (
        <button
            key={`${label}.${icon}.toolbarButton`}
            onClick={onClick}
            className={`toolbar-item spaced ${active ? 'active' : ''}`}
            aria-label={label}
            disabled={disabled}
        >
            <span className={'material-icons-outlined'} style={{ fontSize: '21px', paddingRight: 5 }}>
                {icon}
            </span>
        </button>
    )
}
// This can be cleaned up - ToolButtons & Group is in Lower Case to match up with expectation from Page Components / DCT Template.
// This means that useMemo can't be used in (e.g) ToolButtons.bold as the return of the function. (useMemo can only be used in React component)
// Memoizer is a lazy implementation, an extra layer to allow for better performance and reduction of re-rendering unnecessarily.
export const ToolbarButtonMemo = ({ active = false, ...rest }: ToolbarButtonProps) => {
    return useMemo(() => <ToolbarButton active={active} {...rest} />, [active])
}

export const ToolButtons = {
    bold: (editor, options) => (
        <ToolbarButtonMemo
            key={'toolbar-button-memo-bold'}
            onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')}
            label={'Bold'}
            icon={'format_bold'}
            active={options.isBold}
            disabled={options.disabled}
        />
    ),
    italic: (editor, options) => (
        <ToolbarButtonMemo
            key={'toolbar-button-memo-italic'}
            onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')}
            label={'Italics'}
            icon={'format_italic'}
            active={options.isItalic}
            disabled={options.disabled}
        />
    ),
    underline: (editor, options) => (
        <ToolbarButtonMemo
            key={'toolbar-button-memo-underline'}
            onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')}
            label={'Underline'}
            icon={'format_underlined'}
            active={options.isUnderline}
            disabled={options.disabled}
        />
    ),
    strikethrough: (editor, options) => (
        <ToolbarButtonMemo
            key={'toolbar-button-memo-strikethrough'}
            onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough')}
            label={'Strikethrough'}
            icon={'strikethrough_s'}
            active={options.isStrikethrough}
            disabled={options.disabled}
        />
    ),
    link: (editor, options) => (
        <ToolbarButtonMemo
            key={'toolbar-button-memo-link'}
            onClick={() => editor.dispatchCommand(TOGGLE_LINK_COMMAND, options.isLink ? null : '')}
            label={'Insert Link'}
            icon={'insert_link'}
            active={options.isLink}
            disabled={options.disabled}
        />
    ),
    divider: () => <Divider key='divider' />,
    left: (editor, options) => (
        <ToolbarButtonMemo
            key={'toolbar-button-memo-left'}
            onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left')}
            label={'Left Align'}
            icon={'format_align_left'}
            disabled={options.disabled}
        />
    ),
    right: (editor, options) => (
        <ToolbarButtonMemo
            key={'toolbar-button-memo-right'}
            onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right')}
            label={'Right Align'}
            icon={'format_align_right'}
            disabled={options.disabled}
        />
    ),
    center: (editor, options) => (
        <ToolbarButtonMemo
            key={'toolbar-button-memo-center'}
            onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center')}
            label={'Center Align'}
            icon={'format_align_center'}
            disabled={options.disabled}
        />
    ),
    justify: (editor, options) => (
        <ToolbarButtonMemo
            key={'toolbar-button-memo-justify'}
            onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify')}
            label={'Justify Align'}
            icon={'format_align_justify'}
            disabled={options.disabled}
        />
    )
}

export const ToolButtonGroups = {
    align: (editor, options) => (
        <>
            {ToolButtons.left(editor, options)}
            {ToolButtons.right(editor, options)}
            {ToolButtons.center(editor, options)}
            {ToolButtons.justify(editor, options)}
        </>
    ),
    format: (editor, options) => (
        <>
            {ToolButtons.bold(editor, options)}
            {ToolButtons.italic(editor, options)}
            {ToolButtons.underline(editor, options)}
            {ToolButtons.strikethrough(editor, options)}
        </>
    )
}
export const AllTools = {
    ...ToolButtons,
    ...ToolButtonGroups
}
