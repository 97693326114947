import { Box, Typography } from '@mui/material'
import { PageContainer, PageContainerProps } from './PageContainer'
import React from 'react'

interface PageContainerWithHeaderProps extends PageContainerProps {
    title: string
    titleSlot?: React.ReactNode | null
    topRightElement?: JSX.Element | null
    isSubtitle?: boolean
}

// 'auto auto 30px auto'
export function PageContainerWithHeader({
    children,
    title,
    titleSlot,
    topRightElement,
    isSubtitle
}: PageContainerWithHeaderProps) {
    return (
        <PageContainer>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingTop: '0.75rem',
                    marginTop: 'auto',
                    marginRight: 'auto',
                    marginBottom: '30px',
                    marginLeft: 'auto'
                }}
            >
                {isSubtitle ? (
                    <>
                        <Typography variant='h6'>{title}</Typography>
                        {titleSlot != null && <>{titleSlot}</>}
                    </>
                ) : (
                    <div className={'flex-row-align-center'}>
                        <Typography variant='h5' fontWeight={300}>
                            {title}
                        </Typography>
                        {titleSlot != null && <div style={{ marginLeft: '0.5rem' }}>{titleSlot}</div>}
                    </div>
                )}
                <div
                    style={{
                        justifyContent: 'flex-end'
                    }}
                >
                    {topRightElement}
                </div>
            </Box>

            {children}
        </PageContainer>
    )
}

export default PageContainerWithHeader
