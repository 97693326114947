import React, { useState } from 'react'
import axios from 'axios'
import { notify } from '../../../helpers'
import { guessErrorMessage } from '../../../helpers/guessErrorMessage'
import { Switch } from '@mui/material'
import { ManageAccountsAPI } from '../../../common/constants'

export interface AssignmentCellProps {
    accountID: string
    groupID: string
    initialState: boolean
    forType: 'native' | 'manual'
    onChange: () => void
}

export const AssignmentCell = ({ accountID, groupID, forType, initialState, onChange }: AssignmentCellProps) => {
    const [assigned, setAssigned] = useState<boolean>(initialState)
    const [loading, setLoading] = useState<boolean>(false)

    const assign = async (newState: boolean) => {
        try {
            setAssigned(newState)
            setLoading(true)
            await axios[newState ? 'patch' : 'delete'](`${ManageAccountsAPI}/${accountID}/${forType}_groups/${groupID}`)
            onChange()
        } catch (e) {
            setAssigned(!newState)
            notify(guessErrorMessage(e))
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <Switch
                disabled={loading}
                checked={assigned}
                onChange={async (e) => {
                    await assign(e.target.checked)
                }}
            />
        </>
    )
}
