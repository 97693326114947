import { createTheme } from '@mui/material'
import { renderSelectedValue } from '../helpers'
import { PaletteColorOptions, Theme } from '@mui/material/styles'

// required to support theme.spacing()
// https://stackoverflow.com/questions/70697666/material-ui-v5-property-spacing-does-not-exist-on-type-defaulttheme-when-u
declare module '@mui/styles/defaultTheme' {
    interface DefaultTheme extends Theme {}
}

declare module '@mui/material/styles' {
    interface Palette {
        department: PaletteColorOptions
    }

    interface PaletteOptions {
        department: PaletteColorOptions
    }
}
const SELECT_ITEM_HEIGHT = 48
const SELECT_ITEM_PADDING_TOP = 8

export const primaryTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#4c84ff'
        },
        secondary: {
            main: '#FF7F66'
        },
        error: {
            main: '#f44336'
        },
        warning: {
            main: '#ffb900'
        },
        department: {
            main: '#4E01D5'
        }
    },
    typography: {
        // fontFamily: 'montserrat',
        fontWeightLight: 300,
        h2: {
            fontWeight: 600
        },
        h1: {
            fontWeight: 600
        },
        h3: {
            fontWeight: 700
        },
        h4: {
            fontWeight: 600
        },
        h5: {
            fontWeight: 600
        },
        h6: {
            fontWeight: 700
        },
        subtitle1: {
            fontWeight: 600
        },
        button: {
            fontWeight: 600,
            textTransform: undefined
        }
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    // backgroundColor: "#212a39",
                    // backgroundColor: '#233350'
                    backgroundColor: '#191970'
                }
            }
        },
        MuiSelect: {
            defaultProps: {
                renderValue: renderSelectedValue,
                MenuProps: {
                    PaperProps: {
                        style: {
                            maxHeight: SELECT_ITEM_HEIGHT * 4.5 + SELECT_ITEM_PADDING_TOP,
                            width: 250
                        }
                    }
                }
            },
            styleOverrides: {
                root: {
                    width: '100%'
                }
            } as any
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'uppercase'
                }
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {}
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    width: '100%'
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    width: '100%'
                }
            }
        },
        MuiTooltip: {
            defaultProps: {
                arrow: true
            }
        }
    },
    spacing: 8,
    shape: {
        borderRadius: 2
    }
})

// palette: {
//     type: 'dark',
//     primary: {
//         main: '#2a2f4e',
//         light: '#45485e',
//         dark: '#111320',
//         contrastText: '#ffffff',
//     },
//     secondary: {
//         main: '#e69806',
//         light: '#efb23f',
//         dark: '#7f5303',
//         contrastText: 'rgba(245,242,242,0.87)',
//     },
//     error: {
//         main: '#c5291e',
//         light: '#ce5149',
//         dark: '#8a1c15',
//         contrastText: '#f7f2f2',
//     },
// },
// components: {
//     MuiButton: {
//         defaultProps: {
//             // color:"secondary"
//         }
//     }
// }

// import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
//
// export const themeOptions: ThemeOptions = {
//     palette: {
//         type: 'light',
//         primary: {
//             main: '#4c84ff',
//         },
//         secondary: {
//             main: '#FF7F66',
//         },
//         error: {
//             main: '#f44336',
//         },
//         warning: {
//             main: '#ffb900',
//         },
//     },
//     typography: {
//         fontFamily: 'montserrat',
//         fontWeightLight: 300,
//         h2: {
//             fontWeight: 600,
//         },
//         h1: {
//             fontWeight: 600,
//         },
//         h3: {
//             fontWeight: 700,
//         },
//         h4: {
//             fontWeight: 600,
//         },
//         h5: {
//             fontWeight: 600,
//         },
//         h6: {
//             fontWeight: 700,
//         },
//         subtitle1: {
//             fontWeight: 600,
//         },
//         button: {
//             fontWeight: 600,
//             textTransform: null,
//         },
//     },
//     overrides: {
//         MuiAppBar: {
//             colorInherit: {
//                 backgroundColor: '#212a39',
//                 color: '#fff',
//             },
//         },
//     },
//     props: {
//         MuiAppBar: {
//             color: 'inherit',
//         },
//         MuiTooltip: {
//             arrow: true,
//         },
//     },
//     spacing: 8,
//     shape: {
//         borderRadius: 2,
//     },
// };
