import { Account, Group } from '../types'
import React from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { AssignGroups } from './AssignGroups'
import { useIdentity } from '../../auth/atoms'
import { ObjectAsJson } from '../../../common/ObjectAsJson'

export const AccountGroups = ({ account, onChange }: { account: Account; onChange: () => void }) => {
    const [asJson, setAsJson] = React.useState<Group | undefined>(undefined)
    const identity = useIdentity() // no need to refactor
    const self = identity?.Email === account.Email
    const canManageManual =
        !self && (identity.IsAdmin || identity.Groups?.some((g) => Array.isArray(g.Audience) && g.Audience.length > 0))
    const canManageNative = !self && identity.IsAdmin

    return (
        <>
            {/* Currently applied groups */}
            <Accordion defaultExpanded={false} TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant={'h5'}>Groups in-use</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {Array.isArray(account.GroupsWarnings) && account.GroupsWarnings.length > 0 && (
                        <Alert severity='warning'>
                            <h3>Warnings</h3>
                            <p>Some groups weren't assigned to the account:</p>
                            <ul>
                                {account.GroupsWarnings.map((w, i) => (
                                    <li key={i}>{w}</li>
                                ))}
                            </ul>
                        </Alert>
                    )}

                    {account.Groups && account.Groups.length > 0 ? (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Site</TableCell>
                                    <TableCell>Role</TableCell>
                                    <TableCell>ID</TableCell>
                                    <TableCell>&nbsp;</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {account.Groups.sort((a, b) =>
                                    a.Name.toLowerCase() < b.Name.toLowerCase() ? -1 : 0
                                ).map((g) => (
                                    <TableRow key={g.ID} hover>
                                        <TableCell>{g.Name}</TableCell>
                                        <TableCell>{g.Type}</TableCell>
                                        <TableCell>{g.SiteID ? g.SiteID : 'Tenant-wide'}</TableCell>
                                        <TableCell>{g.Role.name}</TableCell>
                                        <TableCell>{g.ID}</TableCell>
                                        <TableCell>
                                            <Button onClick={() => setAsJson(g)}>Details</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ) : (
                        'No groups'
                    )}
                </AccordionDetails>
            </Accordion>

            {/* External groups*/}
            <Accordion defaultExpanded={false} TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant={'h5'}>External groups</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Alert severity='info'>
                        External groups are maintained by external system (like Azure or Google) and can't be modified
                        in CM
                    </Alert>
                    <pre>
                        {account.ExternalGroups.length > 0 ? (
                            <ul>
                                {account.ExternalGroups.map((s) => (
                                    <li key={s}>{s}</li>
                                ))}
                            </ul>
                        ) : (
                            'No external groups'
                        )}
                    </pre>
                </AccordionDetails>
            </Accordion>

            {/* Manual groups */}
            <Accordion defaultExpanded={false} TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant={'h5'}>Manual groups</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {canManageManual && <AssignGroups account={account} forType={'manual'} onChange={onChange} />}
                    {!canManageManual && (
                        <pre>
                            {account.ManualGroups.length > 0 ? (
                                <ul>
                                    {account.ManualGroups.map((s) => (
                                        <li key={s}>{s}</li>
                                    ))}
                                </ul>
                            ) : (
                                'No Native groups'
                            )}
                        </pre>
                    )}
                </AccordionDetails>
            </Accordion>

            {/* Native groups */}
            <Accordion defaultExpanded={false} TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant={'h5'}>Native groups</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {canManageNative && <AssignGroups account={account} forType={'native'} onChange={onChange} />}
                    {!canManageNative && (
                        <pre>
                            {account.NativeGroups.length > 0 ? (
                                <ul>
                                    {account.NativeGroups.map((s) => (
                                        <li key={s}>{s}</li>
                                    ))}
                                </ul>
                            ) : (
                                'No Native groups'
                            )}
                        </pre>
                    )}
                </AccordionDetails>
            </Accordion>

            {asJson && (
                <ObjectAsJson obj={asJson} title={`Group: ${asJson.Name}`} onClose={() => setAsJson(undefined)} />
            )}
        </>
    )
}
