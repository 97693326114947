import { Account, account } from '../user-management/types'
import { httpGet } from '../../common/client'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAtom } from 'jotai'
import { identityAtom, sitesAtom } from './atoms'
import { site } from './types'
import { useEffect } from 'react'
import { baseQueryConfig } from '../../common/react-query'
import { BASE } from '../../common/constants'

const identityQueryKey = 'identity'

export const useIdentityQuery = () => {
    const query = useQuery({
        ...baseQueryConfig,
        queryKey: [identityQueryKey],
        queryFn: async () => httpGet(`${BASE}/api/v1/identity`, {}, account)
    })
    return query
}

/*Test =>
    On logout, queryClient still has the old data, which is hard to then reconcile.

    On login, we have valid querydata but invalid Identity
    on logout, we have valid queryData but invalid Identity
    we cant effectively handle the logic afaik with current logout mechanism.

    export const useLogout = () => {
        const [,setIdentity] = useAtom(identityAtom)
        setIdentity(null)
        const qClient = useQueryClient()
        qClient.invalidateQueries({queryKey: [identityQueryKey]})
        authenticationService.logout()
    }
 *
 */

export const useIdentityAndSitesQuery = () => {
    const [identity, setIdentity] = useAtom(identityAtom)
    const [sites, setSites] = useAtom(sitesAtom)
    const identityQuery = useQuery({
        ...baseQueryConfig,
        queryKey: [identityQueryKey],
        queryFn: async () => httpGet(`${BASE}/api/v1/identity`, {}, account),
        retry: (failureCount, error: AxiosError) => {
            // Retries should be done if the server response is unexpected, e.g 5xx.
            const statusCode = error?.response?.status
            if (statusCode == 401 || statusCode == 403) {
                return false
            }
            return failureCount > 2
        }
    })

    // 403 / User has no groups
    // can process this information here before sitesQuery is started.

    const isIdentityQueryDone = Boolean(identityQuery?.data?.ID)

    const sitesQuery = useQuery({
        ...baseQueryConfig,
        queryKey: ['tenant-sites-query'],
        enabled: isIdentityQueryDone,
        queryFn: async () => httpGet(`${BASE}/api/v1/sites-for-tenant`, {}, site.array())
    })

    const refetch = () => {
        identityQuery.refetch()
        sitesQuery.refetch()
    }
    useEffect(() => {
        setIdentity((identityQuery.data as Account) || null)
    }, [identityQuery.data])
    useEffect(() => {
        if (identity === undefined && !!identityQuery.data) {
            refetch()
        }
    }, [identity])
    useEffect(() => {
        setSites(sitesQuery.data || [])
    }, [sitesQuery.data])

    // issue: On Logout, IdentityQuery still has the last query with valid user object.
    const inProgress =
        identityQuery.isLoading || (sitesQuery.isLoading && isIdentityQueryDone) /* || !identity === undefined */
    return {
        identityQuery,
        sitesQuery,
        refetch,
        hasCompleted: !inProgress
    }
}
