import { useContext, useEffect, useState } from 'react'
import { clientStorage } from '../../client'
import { Autocomplete, TextField } from '@mui/material'
import { darken, lighten, styled } from '@mui/system'
import { matchSorter } from 'match-sorter'
import { useNavigate } from 'react-router-dom'
import { useAtom } from 'jotai'
import axios from 'axios'
import { disabledContext } from '../../DisabledContext'
import { eventbus } from '../../../helpers/eventbus'
import { currentSiteAtom, identityAtom, useAppContext, useSitesForAccount } from '../../../pkgs/auth/atoms'
import { Site } from '../../../pkgs/auth/types'

export const sitesEventChanel = eventbus<{
    onSitesUpdated: () => void
    onSiteUpdated: (siteId: string | undefined | null) => void
    onSiteSecureSettingsUpdated: () => void
}>()

export const fromOptional = (value: string | null | undefined | { UUID: string; Valid: boolean }) => {
    if (!value) return null
    if (typeof value === 'string') return value
    if (value.Valid) return value.UUID
    return null
}

export type contentTypes =
    | 'department'
    | 'template'
    | 'css'
    | 'js'
    | 'page'
    | 'distributed_page'
    | 'news'
    | 'event'
    | 'alert'
    | 'navigation'
    | 'external_link'
    | 'transportation'
    | 'transportation-admin'
    | 'media'
    | 'image'
    | 'document'
    | 'folder'
    | 'tag'
    | 'account'
    | 'site-settings'
    | 'instagram'

let interceptor = -1
export const initInterceptor = (selectedSite: string | null) => {
    if (interceptor > 0) axios.interceptors.request.eject(interceptor)
    interceptor = axios.interceptors.request.use(
        function (config) {
            selectedSite = selectedSite || new URLSearchParams(window.location.search).get('siteId')
            if (!selectedSite) return config

            config.params = config.params || {}
            if (config.params instanceof URLSearchParams) {
                if (!config.params.get('siteId')) {
                    config.params.append('siteId', selectedSite)
                }
            } else if (!config.params['siteId']) {
                const siteId = selectedSite
                config.params['siteId'] = siteId
            }
            return config
        },
        function (error) {
            return Promise.reject(error)
        }
    )
}

export const SiteSelectorForAccount = () => {
    const [disabled] = useContext(disabledContext)
    const [currentSite, setCurrentSite] = useAtom(currentSiteAtom)
    // const identity = useIdentity()
    const sitesForAccount = useSitesForAccount()

    const [sites, setSites] = useState<Site[]>([])
    const [selected, setSelected] = useState<Site | null>(null)

    const navigate = useNavigate()
    const [identity] = useAtom(identityAtom)

    const handleSiteChange = (s: Site | null) => {
        sitesEventChanel.emit('onSiteUpdated', s?.ID)
        if (!s) {
            setCurrentSite(undefined)
            setSelected(null)
            initInterceptor(null)
            return
        }
        clientStorage.setItem(`--------siteId`, s.ID) //${/*identity.Email*/}
        initInterceptor(s.ID)
        setCurrentSite(s)
        const value = s.ID
        // Intentionally ignore promise
        axios.patch("/api/v1/identity/preferences", {siteId: value})

        const currentParams = new URLSearchParams(window.location.search)
        currentParams.set('siteId', value)
        const newUrl = `${window.location.pathname}?${currentParams.toString()}`
        navigate(newUrl)
        setSelected(s)
    }

    const filterOptions = (options: Site[], { inputValue }): Site[] => {
        const found = matchSorter(options, inputValue, { keys: ['Name', 'ID', 'Tags', 'Type', 'TenantID'] })
        return found
            .filter((site) => site.Type === 'department')
            .concat(found.filter((site) => site.Type !== 'department'))
    }

    const group = (options: Site[]) => {
        const x = options
            .filter((site) => site.Type === 'department')
            .concat(options.filter((site) => site.Type !== 'department'))
        return x
    }
    const processSites = (sites: Site[]) => {
        const groupped = group(sites)
        setSites(groupped)

        // restore state
        let siteId = new URLSearchParams(window.location.search).get('siteId') || currentSite?.ID || ''
        if (siteId) {
            const site = sites.find((site) => site.ID === siteId)
            if (site) {
                handleSiteChange(site)
                return
            }
        }

        /** clientStorage approach doesn't work correctly for cross-tenant users
         * Since we store siteId on contentmanager.imagineeverything.com, it is a shared key - value pair.
         * When a cross-tenant user logs in, they get a siteId that doesn't exist at that site and it will pick the first in the list.
         * We could use tenantId in the key, or use account.Settings / preferences.
         * Stale data potentially due to React Query makes it so that if I log out of Tenant 1 and into Tenant 2, I still have the old tenants data in scope, making it invalid.
         * Proceeded with preferences to move along, I think it could be useful in other areas too.
         * */
        siteId = identity?.Settings?.siteId
            || clientStorage.getItem(`--------siteId`)
            || ''

        if (siteId) {
            const site = sites.find((site) => site.ID === siteId)
            if (site) {
                handleSiteChange(site)
                return
            }
        }

        handleSiteChange(sites[0])
    }

    useEffect(() => {
        if (sites.length === sitesForAccount.length) return
        processSites(sitesForAccount)
    }, [sitesForAccount])

    if (sites.length > 0 && selected) {
        return (
            <Autocomplete
                disabled={!!disabled}
                disableClearable
                filterOptions={filterOptions}
                options={sites}
                value={selected}
                onChange={(event, newValue) => {
                    handleSiteChange(newValue)
                }}
                groupBy={(option) => (option.Type === 'department' ? 'Departments' : 'Sites')}
                getOptionLabel={(option) => option.Name}
                isOptionEqualToValue={(option, value) => option.ID === value.ID}
                sx={{
                    width: 300,
                    '& .MuiInputBase-root': {
                        borderRadius: '10px',
                        padding: '6px'
                    }
                }}
                renderInput={(params) => <TextField {...params} /* label="With categories"*/ />}
                renderGroup={(params) => (
                    <li key={params.key}>
                        <GroupHeader>{params.group}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                    </li>
                )}
            />
        )
    } else {
        return <>loading sites</>
    }
}

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor:
        theme.palette.mode === 'light'
            ? lighten(theme.palette.primary.light, 0.85)
            : darken(theme.palette.primary.main, 0.8)
}))

const GroupItems = styled('ul')({
    padding: 0
})
