import React from 'react'
import AddIcon from '@mui/icons-material/Add'
import { LoadingButton } from '@mui/lab'

export default function AddButton({ title, func, style = {}, loading = false }) {
    return (
        <LoadingButton
            loading={loading}
            variant={'contained'}
            size={'medium'}
            startIcon={<AddIcon />}
            onClick={func}
            style={style || {}}
        >
            {title && <>{title.toUpperCase()}</>}
        </LoadingButton>
    )
}
