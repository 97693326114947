import React from 'react'
import { LexicalEditor } from './RichText'
import { CKEditor } from './CKEditor'
import { FormControl, MenuItem, Select } from '@mui/material'
import { useAppContext } from '../auth/atoms'
import { CodeEditor } from '../monaco/code-editor'

type RichTextValue = {
    html: string
    json: Record<any, any> | null
    engine: 'ckeditor' | 'lexical' | 'html'
}

interface StructuredRichTextEditorProps {
    value: string | RichTextValue
    onChange: (value: RichTextValue) => void
    disabled: boolean | undefined
    editorKey?: string
    options: {
        engine?: 'lexical' | 'ckeditor'
        toolbar?: string[]
    }
}

// TODO => We haven't been using lexical json
export function StructuredRichTextEditor({
    disabled,
    options,
    value,
    onChange,
    editorKey
}: StructuredRichTextEditorProps) {
    const appContext = useAppContext()
    const defaultEditor = appContext.getConfig('DefaultEditor') as 'lexical' | 'ckeditor' | 'html'
    const getInitialEngine = () => {
        switch (value?.['engine']) {
            case 'ckeditor':
                return 'ckeditor'
            case 'html':
                return 'html'
            case 'lexical':
                return 'lexical'
            default:
                return defaultEditor
        }
    }
    const [engine, setEngine] = React.useState<'lexical' | 'ckeditor' | 'html'>(getInitialEngine())

    const initialEditorStateHTML = typeof value === 'string' ? value : value?.html || ''

    // check if the `json` is a valid lexical object (we stored json for CKEditor and HTML as {}), so we check if json has a root key
    // editor state is considered empty if root exists and has no children
    const initialEditorStateJson =
        value && typeof value !== 'string' && value.json && value.json.root && value.json.root?.children?.length
            ? value.json
            : undefined

    return (
        <>
            {engine === 'lexical' && (
                <LexicalEditor
                    onChange={(html, json) => onChange({ html, json, engine: 'lexical' })}
                    value={initialEditorStateHTML}
                    initialEditorState={initialEditorStateJson ? JSON.stringify(initialEditorStateJson) : undefined}
                    disabled={Boolean(disabled)}
                    config={{
                        toolbar: options?.toolbar
                    }}
                    key={editorKey}
                />
            )}

            {engine === 'ckeditor' && (
                <CKEditor
                    value={initialEditorStateHTML}
                    disabled={Boolean(disabled)}
                    onChange={(value: string) => {
                        onChange?.({
                            html: value,
                            json: {},
                            engine: 'ckeditor'
                        })
                    }}
                />
            )}

            {engine === 'html' && (
                <CodeEditor
                    value={initialEditorStateHTML || ''}
                    language={'html'}
                    onChange={(v) => onChange({ html: v || '', engine: 'html', json: {} })}
                    height={'60vh'}
                    disabled={Boolean(disabled)}
                />
            )}

            <div style={{ textAlign: 'right' }}>
                <FormControl size={'small'} sx={{ mt: 2 }}>
                    <Select
                        value={engine}
                        onChange={(e) => {
                            if (
                                !confirm(
                                    'Are you sure you want to change the editor? The current content of the editor will be modified and some data or formatting could be lost. '
                                )
                            )
                                return

                            const newEngine = e.target.value as 'lexical' | 'ckeditor' | 'html'
                            setEngine(newEngine)
                            // @ts-ignore
                            onChange({ ...value, engine: newEngine })
                        }}
                    >
                        <MenuItem value='lexical'>Lexical</MenuItem>
                        <MenuItem value='ckeditor'>CKEditor</MenuItem>
                        <MenuItem value='html'>HTML</MenuItem>
                    </Select>
                </FormControl>
            </div>
        </>
    )
}
