import type {
    DOMConversionMap,
    DOMConversionOutput,
    DOMExportOutput,
    EditorConfig,
    LexicalNode,
    NodeKey,
    SerializedLexicalNode,
    Spread
} from 'lexical'

import { $applyNodeReplacement, DecoratorNode, isHTMLElement } from 'lexical'
import MonacoCodeEmbedView from './MonacoCodeEmbedView'

export interface MonacoCodeEmbedPayload {
    code: string
    key?: NodeKey
}

export const MONACO_CODE_EMBED_TYPE = 'monaco-code-embed'

function isCKEditorRawHTMLEmbed(divElement: HTMLDivElement) {
    return divElement.classList.contains('raw-html-embed')
}

function convertDivElement(domNode: Node): null | DOMConversionOutput {
    console.log('monacoCodeEmbedNode convertDivElement')
    const code = (domNode as HTMLDivElement)?.innerHTML || ''
    const node = $createMonacoCodeEmbedNode({ code })
    return { node }
}

export type SerializedMonacoCodeEmbedNode = Spread<
    {
        code: string
    },
    SerializedLexicalNode
>

export class MonacoCodeEmbedNode extends DecoratorNode<JSX.Element> {
    __code: string

    static getType(): string {
        return MONACO_CODE_EMBED_TYPE
    }

    static clone(node: MonacoCodeEmbedNode): MonacoCodeEmbedNode {
        return new MonacoCodeEmbedNode(node.__code, node.__key)
    }

    static importJSON(serializedNode: SerializedMonacoCodeEmbedNode): MonacoCodeEmbedNode {
        const { code } = serializedNode
        const node = $createMonacoCodeEmbedNode({
            code
        })
        return node
    }

    exportDOM(): DOMExportOutput {
        const element = document.createElement('div')
        element.setAttribute(MONACO_CODE_EMBED_TYPE, '')

        element.innerHTML = this.__code
        return { element }
    }

    static importDOM(): DOMConversionMap | null {
        return {
            div: (node: Node) => {
                if (
                    !isHTMLElement(node) ||
                    (!isMonacoCodeEmbedElement(node as HTMLDivElement) &&
                        !isCKEditorRawHTMLEmbed(node as HTMLDivElement))
                ) {
                    return null
                }

                return {
                    conversion: convertDivElement,
                    priority: 4
                }
            }
        }
    }

    constructor(code: string, key?: NodeKey) {
        super(key)
        this.__code = code
    }

    exportJSON(): SerializedMonacoCodeEmbedNode {
        return {
            code: this.__code,
            type: MONACO_CODE_EMBED_TYPE,
            version: 1
        }
    }

    setCode(code: string) {
        const writable = this.getWritable()
        writable.__code = code
    }

    createDOM(config: EditorConfig): HTMLElement {
        const div = document.createElement('div')
        div.setAttribute(MONACO_CODE_EMBED_TYPE, '')
        div.setAttribute('node-key', this?.__key)
        return div
    }

    updateDOM(): false {
        return false
    }

    getCode(): string {
        return this.__code
    }

    decorate(): JSX.Element {
        return <MonacoCodeEmbedView nodeKey={this.__key} value={this.__code} language={'html'} onChange={(v) => {}} />
    }
}

export function $createMonacoCodeEmbedNode({ code, key }: MonacoCodeEmbedPayload): MonacoCodeEmbedNode {
    return $applyNodeReplacement(new MonacoCodeEmbedNode(code, key))
}

export function $isMonacoCodeEmbedNode(node: LexicalNode | null | undefined): node is MonacoCodeEmbedNode {
    return node instanceof MonacoCodeEmbedNode
}

export function isMonacoCodeEmbedElement(div: HTMLElement): boolean {
    return div.hasAttribute(MONACO_CODE_EMBED_TYPE)
}
