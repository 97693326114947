import axios from 'axios'
import validateUUID from '../../helpers/validateUUID'
import { Format, newUrlParam, sanitizePathString } from '../../helpers'
import { v4 } from 'uuid'
import { failure, success } from '../../helpers/result'
import { AsShareable } from '../auth/permissions/evaluate'
import { ContentAPI, ContentByIdAPI, TemplateAPI } from '../../common/constants'
import { ContentPublishedTypes, ContentType } from './types'
import { NavigationType } from '../navigation/types'

/**
 * @param {AbortController}       abortController   - An AbortController with the signal property used to cancel in-flight requests
 * @param {string}            selectedSite      - UUID of the SelectedSite, can be obtained via selectedSiteContext
 * @param {string || number}  page              - Pagination Value
 * @param {string || number}  limit             - Pagination Value
 * @param {string}            searchTerm        - Searches content.Title
 * @param {boolean}           isPublished
 * @param {boolean}           ignorePublished   -
 * @return {Promise<any>}
 * */
const getAllEvents = (
    abortController,
    { siteId, page, limit, searchTerm, isPublished, ignorePublished, expiration }
) => {
    // if (!siteId || !validateUUID(siteId)) return Promise.reject("Invalid Site Selected")

    return axios.get(ContentAPI, {
        signal: abortController.signal,
        params: {
            contentType: ContentType.Event,
            siteId,
            page: page * limit,
            limit,
            searchTerm,
            isPublished,
            ignorePublished,
            expiration
        }
    })
}
/**
 * @param {AbortController}       abortController   - An AbortController with the signal property used to cancel in-flight requests
 * @param {string}            selectedSite      - UUID of the SelectedSite, can be obtained via selectedSiteContext
 * @param {string || number}  page              - Pagination Value
 * @param {string || number}  limit             - Pagination Value
 * @param {string}            searchTerm        - Searches content.Title
 * @param {boolean}           isPublished
 * @param {boolean}           ignorePublished
 * @param {boolean}           usePriority
 * @param {boolean}           expiration        - ContentExpirationsState types
 * @return {Promise<any>}
 * */
const getAllNews = (
    abortController,
    { siteId, page, limit, searchTerm, isPublished, ignorePublished, usePriority, expiration }
) => {
    // if (!siteId || !validateUUID(siteId)) return Promise.reject("Invalid Site Selected")

    return axios.get(ContentAPI, {
        signal: abortController.signal,
        params: {
            contentType: ContentType.News,
            siteId,
            page: page * limit,
            limit,
            searchTerm,
            isPublished,
            ignorePublished,
            usePriority,
            expiration
        }
    })
}

const getPinnedNews = async (selectedSite) => {
    // if (!selectedSite || !validateUUID(selectedSite)) return Promise.reject("Invalid Site Selected")

    return axios
        .get(ContentAPI, {
            params: {
                siteId: selectedSite,
                contentType: ContentType.News,
                page: 0,
                limit: 999,
                usePriority: true
            }
        })
        .then((r) => r.data)
}

const getAllContents = (
    abortController,
    {
        selectedSite,
        page,
        limit,
        searchTerm,
        isPublished,
        ignorePublished,
        tags,
        editors,
        template,
        contentType,
        sortings,
        filters,
        editable,
        deleted,
        usePriority,
        expiration,
        withPermissions,
        departments,
        status,
        inactive,
        siteOnly
    }
) => {
    // if (!selectedSite || !validateUUID(selectedSite)) return Promise.reject("Invalid Site Selected")
    return axios
        .get(ContentAPI, {
            signal: abortController.signal,
            params: {
                siteId: selectedSite,
                contentType,
                page: page * limit,
                limit,
                searchTerm,
                isPublished,
                ignorePublished,
                tags,
                editors,
                template,
                sortings,
                filters,
                editable,
                deleted,
                usePriority,
                expiration,
                withPermissions,
                departments,
                status,
                inactive,
                siteOnly
            }
        })
        .then((r) => r.data)
}
/**
 * @param {string}                      selectedSite   - UUID of the SelectedSite, can be obtained via selectedSiteContext
 * @param {string[]}                    contentType    - Constants - [event,page,news,template,navigation,external_link,alert,js,css]
 * @param {string || number}            page           - Pagination Value
 * @param {string || number}            limit          - Pagination Value
 * @param {string}                      searchTerm     - Searches content.Title
 * @param {string}                      pagePublishState - Constants - [all, published, draft]
 * @return {Promise<any>}
 * */
const getAll = async (selectedSite, contentType, page, limit, searchTerm, pagePublishState) => {
    // if (!selectedSite || !validateUUID(selectedSite)) return Promise.reject("Invalid Site Selected")
    const params = newUrlParam(
        selectedSite,
        limit,
        page * limit,
        searchTerm,
        'contentType',
        [...contentType],
        null,
        pagePublishState
    )
    // 2024-01-08: URLSearchParams and multiple keys (e.g classification, or contentType) might not work as expected
    // toString() method shows a successful addition of two keys ( e.g ?contentType=event&contentType=news )
    // entries() turns the URLSearchParams into an iterator which can be turned into an object like Object.fromEntries(params.entries())
    // This causes an issue, as repeated keys get overwritten, as object keys must be unique.
    // Need to find out why backend only accepts things in the following way and standardize across app for consistency.
    const response = await axios.get(ContentAPI + '?' + params.toString())
    return response.data
}
const search = async (contentType, page, limit, searchTerm, rest) => {
    const { pagePublishState = ContentPublishedTypes.all, hideDepartmentContent = false } = rest
    const params = newUrlParam(
        '',
        limit,
        page * limit,
        searchTerm,
        'contentType',
        [...contentType],
        null,
        pagePublishState
    )
    if (hideDepartmentContent) {
        params.append('hideDepartmentContent', true)
    }
    const response = await axios.get(ContentAPI, {
        params
    })
    return response.data
}
/**
 * @param {string[]}                    contentType    - Constants - [event,page,news,template,navigation,external_link,alert,js,css]
 * @param {string || number}            page           - Pagination Value
 * @param {string || number}            limit          - Pagination Value
 * @param {string}                      searchTerm     - Searches content.Title
 * @param {string}                      pagePublishState - Constants - [all, published, draft]
 * @return {Promise<any>}
 * */
const standardGetAll = async (contentType, page, limit, searchTerm, pagePublishState) => {
    const params = newUrlParam(
        '',
        limit,
        Math.ceil(page * limit),
        searchTerm,
        'contentType',
        [...contentType],
        null,
        pagePublishState
    )
    // 2024-01-08: URLSearchParams and multiple keys (e.g classification, or contentType) might not work as expected
    // toString() method shows a successful addition of two keys ( e.g ?contentType=event&contentType=news )
    // entries() turns the URLSearchParams into an iterator which can be turned into an object like Object.fromEntries(params.entries())
    // This causes an issue, as repeated keys get overwritten, as object keys must be unique.
    // Need to find out why backend only accepts things in the following way and standardize across app for consistency.
    const response = await axios.get(ContentAPI + '?' + params.toString())
    return response.data
}
/**
 * @param {string}              selectedSite   - UUID of the SelectedSite, can be obtained via selectedSiteContext
 * @param {string}              classification - Constants are available in Conf
 * @param {boolean}             onlyGetDct     - Only get DCT templates
 * @return {Promise<any>}
 * */
const getAllByClassification = async (selectedSite, classification, onlyGetDct) => {
    // if (!selectedSite || !validateUUID(selectedSite)) return Promise.reject("Invalid Site Selected")
    const params = {
        siteId: selectedSite,
        classification,
        onlyGetDct,
        page: 0,
        limit: 9999
    }
    const response = await axios.get(ContentAPI, { params })
    return response.data
}
/**
 * @param {Array}        classification   - array of content type
 * @param {string}       templateType - a string of type TemplateType
 *                          AllTemplates      TemplateType = "all"
 *                          DCTTemplates      TemplateType = "dct"
 *                          FormlessTemplates TemplateType = "formless"
 * @return {Promise<any>}
 *
 * */

const getTemplates = async (classification = [], templateType = '') => {
    try {
        // TODO => NST-128
        const params = new URLSearchParams()
        classification.forEach((x) => params.append('classification', x))
        // Hotfix/2023-11-02
        // It seems like using a URLSearchParams instance blocks the interceptor from working
        // Within interceptor.use, parameters are correctly applied, but they don't take apply
        // to the request.
        if (templateType && ['all', 'dct', 'formless'].includes(templateType.toLowerCase())) {
            params.append('type', templateType.toLowerCase())
        }
        // 2024-01-08: URLSearchParams and multiple keys (e.g classification, or contentType) might not work as expected
        // toString() method shows a successful addition of two keys ( e.g ?contentType=event&contentType=news )
        // entries() turns the URLSearchParams into an iterator which can be turned into an object like Object.fromEntries(params.entries())
        // This causes an issue, as repeated keys get overwritten, as object keys must be unique.
        // Need to find out why backend only accepts things in the following way and standardize across app for consistency.
        const response = await axios.get(TemplateAPI + '?' + params.toString())
        return response?.data?.results || []
    } catch (e) {
        const { message } = e || {}
        return Promise.reject({ message })
    }
}
/**
 * @param {string}            selectedSite - UUID of the SelectedSite, can be obtained via selectedSiteContext
 * @param {string}            id           - UUID of the Content
 * @return {Promise<any>}
 * */
const getContentById = async (selectedSite, id) => {
    if (!id || !validateUUID(id)) return Promise.reject(`Invalid Site: [${selectedSite}] or Nil resource ID: [${id}] `)
    const params = {
        siteId: selectedSite
    }
    const response = await axios.get(`${ContentAPI}/${id}`, {
        params
    })
    return response.data
}
/**
 * @param {string}            selectedSite - UUID of the SelectedSite, can be obtained via selectedSiteContext
 * @param {Object}            data         - Must contain a Content_ID, and any fields that you want to replace
 * @return {Promise<any>}
 * */
const patchContent = async (selectedSite, data) => {
    const reqId = data?.id || data?.ID || data?.Id
    console.log(data, reqId)
    if (!validateUUID(reqId)) {
        return Promise.reject({ message: `invalid ID for request: [${reqId}]` })
    }
    // if (!selectedSite || !id || !validateUUID(id) || !validateUUID(selectedSite)) return Promise.reject("Invalid Site or Nil resource ID")
    const params = {
        siteId: selectedSite
    }
    return axios.patch(`${ContentByIdAPI}${reqId}`, data, {
        params
    })
}
/**
 * @param {string}  selectedSite - uuid
 * @param {Object[]} children    - array of content models
 * @return {Record}
 * */
const patchUpdatedDistributedPages = async (selectedSite, children) => {
    for await (const child of children) {
        try {
            if (!child.editor) {
                break
            }
            const altered = await child.editor.getData()
            if (altered !== child.content) {
                const { data: content } = await contentService.patchContent(selectedSite, {
                    ...AsShareable(child),
                    content: altered
                })
                const index = children.indexOf(child)
                children[index].content = content?.content || ''
            }
        } catch (err) {
            console.error(err)
            const { message = '' } = err || {}
            return Promise.reject({ message })
        }
    }
    return children
}
/**
 * @param {string}            selectedSite - UUID of the SelectedSite, can be obtained via selectedSiteContext
 * @param {Object}            data  - The Content model
 * @return {Promise<any>}
 * */
const createContent = async (selectedSite, data) => {
    // if (!selectedSite || !validateUUID(selectedSite)) return Promise.reject("Invalid Site Selected")
    const params = {}
    if (selectedSite) {
        params['siteId'] = selectedSite
    }

    const response = await axios.post(ContentAPI, data, {
        params
    })
    return response.data
}
/**
 * @param {string}            selectedSite - UUID of the SelectedSite, can be obtained via selectedSiteContext
 * @param {Object}            data  - The Content model
 * @return {Promise<any>}
 * */
const updateContent = async (selectedSite, data) => {
    // if (!selectedSite || !validateUUID(selectedSite)) return Promise.reject("Invalid Site Selected")
    const params = {
        siteId: selectedSite
    }

    const response = await axios.put(ContentAPI, data)
    return response.data
}
/**
 * @param {string}            selectedSite - UUID of the SelectedSite, can be obtained via selectedSiteContext
 * @param {Object}            data  - The Content model
 * @return {Promise<any>}
 * */
export const deleteContent = async (selectedSite, data) => {
    const params = {
        siteId: selectedSite
    }
    const response = await axios.delete(ContentAPI, {
        data,
        params
    })
    return response.data
}

const createExternalLinkContent = async (selectedSite, title, route, sites, departmentId, settings, rest) => {
    const newID = v4()
    const newExternalLinkContent = {
        id: newID,
        content: '',
        title: title,
        sites: sites.length && typeof sites[0] === 'string' ? sites : Format.objectsToIds(sites),
        departmentId: Format.toNullUUID(departmentId),
        route: route,
        path: sanitizePathString(newID),
        type: NavigationType.External,
        privacy_level: 0,
        settings: settings ? settings : {},
        publish_at: new Date(),
        ...(rest || {})
    }

    return axios
        .post(ContentAPI, newExternalLinkContent, { params: { siteId: selectedSite } })
        .then((x) => (typeof x.config.data === 'string' ? JSON.parse(x.config.data) : x.config.data))
}

const clone = async (contentId) => {
    try {
        const res = await axios.post(`${ContentAPI}/clone/${contentId}`)
        return success(res.data)
    } catch (error) {
        return failure(error)
    }
}

const restore = async (contentId) => {
    try {
        await axios.patch(`${ContentAPI}/restore/${contentId}`)
        return success(null)
    } catch (error) {
        return failure(error)
    }
}

export const contentService = {
    getAll,
    standardGetAll,
    search,
    getAllEvents,
    getAllNews,
    getPinnedNews,
    getAllContents,
    getAllByClassification,
    getContentById,
    getTemplates,
    patchContent,
    createExternalLinkContent,
    createContent,
    updateContent,
    deleteContent,
    // previewContent,
    patchUpdatedDistributedPages,
    clone,
    restore
    // getAllTemplates,
    // getAllResources
}
