import { Content } from '../../types'
import { Alert, Card, CardContent, FormControlLabel, FormGroup, Stack, Switch } from '@mui/material'
import TextField from '@mui/material/TextField'
import React from 'react'
import { useTopicsQuery } from '../../../notifications/topics/queries'
import { guessErrorMessage } from '../../../../helpers/guessErrorMessage'

export function AlertNotification(props: {
    state: Content
    onChange: (e) => void
    hasPermission: boolean
    notificationErrors: Partial<Record<string, string>>
    onChange1: (e) => void
    onChange2: (e) => void
}) {
    const topicsQuery = useTopicsQuery({
        Active: true,
        TopicType: 'alert',
        pageSize: 1
    })

    if (topicsQuery.isLoading) return null
    if (topicsQuery.isError)
        return <Alert severity='error'>Error loading topics: {guessErrorMessage(topicsQuery.error)}</Alert>

    if (topicsQuery.data.Rows.length === 0) return null

    return (
        <Card>
            <CardContent>
                {props.state.PrivacyLevel !== 0 && (
                    <span style={{ color: 'color: rgba(0, 0, 0, 0.38) !important' }}>
                        Email notifications must be public
                    </span>
                )}
                <FormGroup aria-label='position' row>
                    <FormControlLabel
                        name='hasEmailNotification'
                        control={
                            <Switch
                                color='primary'
                                checked={props.state.Settings?.HasEmailNotification || false}
                                onChange={props.onChange}
                            />
                        }
                        label='Email Notification'
                        labelPlacement='start'
                        disabled={!props.hasPermission}
                    />
                </FormGroup>
                <FormGroup>
                    {props.state?.Settings?.HasEmailNotification && (
                        <Stack spacing={2}>
                            <TextField
                                disabled={!props.hasPermission}
                                helperText={'max 140 characters'}
                                error={!!props.notificationErrors.EmailSubject}
                                label='Email Subject'
                                value={props.state?.Settings?.EmailSubject || ''}
                                name={'notificationSubject'}
                                variant='outlined'
                                onChange={props.onChange1}
                            />
                            <TextField
                                disabled={!props.hasPermission}
                                error={!!props.notificationErrors.EmailBody}
                                label='Email Body'
                                value={props.state?.Settings?.EmailBody || ''}
                                name={'notificationBody'}
                                variant='outlined'
                                multiline={true}
                                rows={4}
                                onChange={props.onChange2}
                            />
                        </Stack>
                    )}
                </FormGroup>
            </CardContent>
        </Card>
    )
}
