import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { isEqual } from 'lodash'
import { storage } from '../../common/storage.service'
import { getColumns, getColumnsTypes } from './cells/columnsFactory'
import './Manager.css'
import { clientStorage, eventsRowsPerPageKey, newsRowsPerPageKey } from '../../common/client'
import { ContentType } from '../content/types'
import { useAppContext } from '@/pkgs/auth/atoms'

export const Manager = ({
    paginationState,
    setPaginationState,
    contentType,
    resetSignal,
    searchLoading,
    setMenuElement
}) => {
    const appContext = useAppContext()
    const cacheKey = 'v3-' + window.location.pathname
    const rowHeight = 78
    const [gridHeight, setGridHeight] = useState<number>(600)
    const [sortModel, setSortModel] = useState<any[]>([])
    const [filterModel, setFilterModel] = useState({ items: [] })

    const sortKey = `${appContext.tenantID}-${contentType}-sort-model`
    const filterKey = `${appContext.tenantID}-${contentType}-filter-model`

    const [visibilityModel, setVisibilityModel] = useState(
        clientStorage.getItem(`${cacheKey}.columnVisibilityModel`) || {}
    )

    useEffect(() => {
        const sort = storage.getItem(sortKey)
        if (Array.isArray(sort)) {
            setSortModel(sort)
        }

        const filter = storage.getItem(filterKey)
        if (filter) {
            //@ts-ignore
            setFilterModel(filter)
        }
    }, [])

    useEffect(() => {
        if (Array.isArray(paginationState.data)) {
            setGridHeight(rowHeight * paginationState.data.length + 180)
        }
    }, [paginationState.data])

    useEffect(() => {
        handleSortingModelChange([])
        handleFilterModelChange({ items: [] })
    }, [resetSignal])

    function handleFilterModelChange(model) {
        if (!isEqual(model, filterModel)) {
            storage.setItem(filterKey, model)
            setFilterModel(model)
        }
    }

    function handleSortingModelChange(sort) {
        if (!isEqual(sort, sortModel)) {
            storage.setItem(sortKey, sort)
            setSortModel(sort)
        }
    }

    useEffect(() => {
        setPaginationState((prev) => ({
            ...prev,
            sortings: sortModel,
            currentPage: 0,
            page: 0
        }))
    }, [sortModel])

    useEffect(() => {
        setPaginationState((prev) => ({
            ...prev,
            filters: filterModel.items,
            currentPage: 0,
            page: 0
        }))
    }, [filterModel])

    const { columns, persistColumnVisibility } = getColumns(contentType, setMenuElement)

    return (
        <div className='scaffolding'>
            <div style={{ height: gridHeight, width: '100%' }}>
                <DataGrid
                    //@ts-ignore
                    columns={columns}
                    columnTypes={getColumnsTypes()}
                    rows={paginationState.data}
                    disableSelectionOnClick
                    rowHeight={rowHeight}
                    componentsProps={{
                        filterPanel: {
                            sx: {
                                '& .MuiDataGrid-filterFormDeleteIcon': {
                                    width: 'auto'
                                }
                            }
                        }
                    }}
                    filterMode='server'
                    paginationMode='server'
                    sortingMode='server'
                    onFilterModelChange={handleFilterModelChange}
                    filterModel={filterModel}
                    onSortModelChange={handleSortingModelChange}
                    sortModel={sortModel}
                    columnVisibilityModel={visibilityModel}
                    onColumnVisibilityModelChange={(model) => {
                        clientStorage.setItem(`${cacheKey}.columnVisibilityModel`, model)
                        setVisibilityModel(model)
                    }}
                    slots={{
                        toolbar: DataGridCustomToolbar
                    }}
                    loading={searchLoading}
                    rowCount={paginationState.total}
                    paginationModel={{
                        pageSize: paginationState.perPage || 10,
                        page: paginationState.currentPage || 0
                    }}
                    pageSizeOptions={[10, 25, 50, 100]}
                    onPaginationModelChange={(model) => {
                        if (model.pageSize == paginationState.perPage && model.page == paginationState.currentPage) {
                            return
                        }

                        const page = model.pageSize !== paginationState.perPage ? 0 : model.page
                        const offset = Math.ceil(page * paginationState.perPage)
                        clientStorage.setItem(
                            contentType === ContentType.Event ? eventsRowsPerPageKey : newsRowsPerPageKey,
                            model.pageSize
                        )
                        setPaginationState((prev) => ({
                            ...prev,
                            currentPage: page,
                            page,
                            perPage: model.pageSize,
                            offset: offset
                        }))
                    }}
                />
            </div>
        </div>
    )
}

export const DataGridCustomToolbar = () => {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton style={{ marginLeft: 'auto' }} />
        </GridToolbarContainer>
    )
}
