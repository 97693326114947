import DOMPurify from 'dompurify'

const invalidEmptyTags = new Set(['TABLE', 'THEAD', 'TBODY', 'TFOOT', 'TR', 'UL', 'OL', 'DL'])
const inlineTags = new Set([
    'A',
    'ABBR',
    'ACRONYM',
    'B',
    'BR',
    'BUTTON',
    'CITE',
    'EM',
    'I',
    'IMG',
    'SMALL',
    'SPAN',
    'STRONG',
    'SUB',
    'SUP'
])

const blockTags = new Set([
    'ADDRESS',
    'ARTICLE',
    'ASIDE',
    'BLOCKQUOTE',
    'CANVAS',
    'DD',
    'DIV',
    'DL',
    'DT',
    'FIELDSET',
    'FIGCAPTION',
    'FIGURE',
    'FOOTER',
    'FORM',
    'H1',
    'H2',
    'H3',
    'H4',
    'H5',
    'H6',
    'HEADER',
    'HR',
    'LI',
    'MAIN',
    'NAV',
    'NOSCRIPT',
    'OL',
    'P',
    'PRE',
    'SECTION',
    'TABLE',
    'TFOOT',
    'UL',
    'VIDEO'
])

export function sanitizeHTML(htmlString: string) {
    DOMPurify.addHook('afterSanitizeElements', (node: Element) => {
        // Remove specific empty elements
        if (invalidEmptyTags.has(node.nodeName)) {
            if (isNodeEmpty(node)) {
                node.parentNode?.removeChild(node)
                return
            }
        }

        // Wrap inline elements not inside block-level elements
        if (inlineTags.has(node.nodeName)) {
            let parent = node.parentElement

            while (parent && !blockTags.has(parent.nodeName) && parent.nodeName !== 'BODY') {
                parent = parent.parentElement
            }

            if (!parent || (parent && parent.nodeName === 'BODY')) {
                const p = node.ownerDocument.createElement('p')

                node.parentNode?.replaceChild(p, node)
                p.appendChild(node)
            }
        }
    })

    const sanitizedHTML = DOMPurify.sanitize(htmlString, {
        USE_PROFILES: { html: true },
        ADD_ATTR: ['target', 'style'],
        ADD_TAGS: ['br']
    })

    DOMPurify.removeAllHooks()
    return sanitizedHTML
}

function isNodeEmpty(node: Element): boolean {
    if (node.children.length > 0) {
        for (const child of node.children) {
            if (!invalidEmptyTags.has(child.nodeName)) {
                return false
            }
            if (!isNodeEmpty(child)) {
                return false
            }
        }
    }

    return true
}
