import React, { useEffect, useState } from 'react'
import { FormControl, FormControlLabel, FormGroup, FormLabel, Radio } from '@mui/material'
import { anyKeyIsTrue } from '../../helpers'

/**
 * @param {function}    setPrivacyLevel   : Controlled setState in the parent component
 * @param {function}    setErrors         : Controlled setState in the parent component
 * @param {number}      privacyLevel      : Controlled value from parent component
 * @param {boolean}     errors            : Controlled value from parent component
 * @param {boolean}     horizontal        : Determines position
 * @param {boolean}     isDisabled        : Is Disabled
 * @param {string}      customLabel       : A custom label that will appear instead of "Content Visibility"
 * @returns             {JSX.Element}
 */

export const PrivacyLevelCheckbox = ({
    privacyLevel,
    setPrivacyLevel,
    errors,
    setErrors,
    isDisabled,
    horizontal,
    customLabel
}) => {
    const [checked, setChecked] = useState({
        public: false,
        parent: false,
        student: false,
        staff: false
    })
    const [isEnabled, setIsEnabled] = useState(false)
    const privacyLevels = {
        parent: 16,
        student: 8,
        volunteer: 4,
        staff: 2,
        admin: 1,
        public: 0
    }

    useEffect(() => {
        if (privacyLevel !== null) {
            switch (privacyLevel) {
                case 18:
                    setChecked({
                        public: false,
                        staff: true,
                        parent: true
                    })
                    break
                case 2:
                    setChecked({
                        public: false,
                        staff: true
                    })
                    break
                case 0:
                    setChecked({
                        public: true,
                        staff: false
                    })
                    break
            }
        }
        setIsEnabled(true)
    }, [privacyLevel])

    useEffect(() => {
        if (isEnabled) {
            let pl = 0
            Object.keys(checked).forEach((each) => {
                if (checked[each]) {
                    pl += privacyLevels[each]
                }
            })

            setErrors((prev) => ({ ...prev, privacyLevel: !anyKeyIsTrue(checked) }))
            setPrivacyLevel(pl)
        }
    }, [checked])

    const handleChangePublic = () => {
        setChecked({
            public: !checked.public,
            parent: false,
            staff: false
        })
    }

    const handleChangeStaff = () => {
        setChecked((prev) => ({
            ...prev,
            public: false,
            staff: !checked.staff
        }))
    }

    const handleChangeParents = () => {
        setChecked((prev) => ({
            ...prev,
            public: false,
            parent: !checked.parent
        }))
    }

    return (
        <div>
            <FormControl variant='standard' component='fieldset' disabled={isDisabled}>
                <FormLabel error={errors.privacyLevel && !isDisabled} component='legend'>
                    {customLabel ? customLabel : 'Content Visibility'}
                </FormLabel>
                <div className={horizontal ? 'flex-row' : ''}>
                    <FormGroup>
                        <FormControlLabel
                            control={<Radio onChange={handleChangePublic} checked={checked.public} name='first' />}
                            label='Public'
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel
                            control={<Radio onChange={handleChangeStaff} checked={checked.staff} name='first' />}
                            label='Staff'
                        />
                    </FormGroup>
                    {/*<FormGroup>*/}
                    {/*    <FormControlLabel*/}
                    {/*        control={<Checkbox onChange={handleChangeParents} checked={checked.parent} name="first" />}*/}
                    {/*        label="Parents"*/}
                    {/*    />*/}
                    {/*</FormGroup>*/}
                </div>
            </FormControl>
        </div>
    )
}

export default PrivacyLevelCheckbox
