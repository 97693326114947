import { DateTimeNullable } from '../../../../common/components/dates/DateTimeNullable'
import { addDays } from '../../../../common/components/dates/helpers'
import { PublishPeriod } from '../../types'

type PublishPeriodDatesProps = {
    value: PublishPeriod
    onChange: (v: PublishPeriod) => void
    errors?: Record<string, string>
    disabled?: boolean
}

export const PublishPeriodControl = ({ value, onChange, errors, disabled }: PublishPeriodDatesProps) => {
    return (
        <div>
            <DateTimeNullable
                value={value.PublishAt}
                onChange={(date) => onChange({ ...value, PublishAt: date })}
                label={'Publish at'}
                max={value.ExpireAt}
                defaultValue={addDays(value.ExpireAt, -1)}
                disabled={disabled}
            />
            <DateTimeNullable
                value={value.ExpireAt}
                onChange={(date) => onChange({ ...value, ExpireAt: date })}
                label={'Expire at'}
                min={value.PublishAt}
                defaultValue={addDays(value.PublishAt, 90)}
                disabled={disabled}
            />
        </div>
    )
}
