import React from 'react'
import { useDistributedPageQuery } from './queries'
import Button from '@mui/material/Button'
import { useCurrentSite } from '../../auth/atoms'
import CKEditor from '../../lexical-editor/CKEditor'

export interface DistributedPageEditorRef {
    save: () => void
}

interface DistributedPageEditorProps {
    parentID: string
    siteID: string
    onChanges: (hasChanges: boolean) => void
    label: string
    disabled?: boolean
}

export const DistributedPageEditor = React.forwardRef(
    (
        { parentID, siteID, onChanges, label, disabled }: DistributedPageEditorProps,
        ref: React.Ref<DistributedPageEditorRef>
    ) => {
        const { read, upsert } = useDistributedPageQuery({ parentID, siteID })
        const [content, setContent] = React.useState<string | undefined>(read.data?.Content)
        const currentSite = useCurrentSite()

        React.useEffect(() => {
            if (read.data?.Content !== content) {
                setContent(read.data?.Content)
            }
            onChanges(content != read.data?.Content)
        }, [read.data?.Content])

        React.useImperativeHandle(ref, () => ({
            save
        }))

        function save() {
            if (content === undefined) {
                console.warn('No content to save')
                return
            }
            upsert.mutate({ Content: content || '', Sites: [siteID], DepartmentID: null })
        }

        return (
            <div>
                <h3>{label}</h3>
                {(content || content === '') && (
                    <CKEditor
                        onChange={(value) => {
                            onChanges(value != read.data?.Content)
                            setContent(value)
                        }}
                        value={content}
                        disabled={Boolean(disabled)}
                    />
                    // <LexicalEditor
                    //     onChange={(value) => {
                    //         onChanges(value != read.data?.Content)
                    //         setContent(value)
                    //     }}
                    //     value={content}
                    //     disabled={disabled}
                    // />
                )}
                {!content && content !== '' && !read.isLoading && !upsert.isLoading && (
                    <Button disabled={disabled} onClick={() => setContent('')}>
                        Create
                    </Button>
                )}
            </div>
        )
    }
)
