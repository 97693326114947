import axios from 'axios'
import { GOOGLE_CLIENT_ID as client_id, GoogleAuthAPI, LoginAPI, LogoutAPI } from '../../common/constants'

export const login = async (email, password) => {
    const requestOptions = {
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
        credentials: 'include',
        redirect: 'manual'
    }
    try {
        await axios.post(LoginAPI, { email, password }, requestOptions)
        return true
    } catch (e) {
        return Promise.reject(e)
    }
}
export const logout = async () => {
    try {
        //@ts-ignore
        if (window?.gapi) {
            //@ts-ignore
            await window.gapi.load('auth2', endGoogleSession)
        }
        await axios.get(LogoutAPI)
    } catch (e) {
        console.log(e)
    }
}
const loginWithGoogle = async (gClaim, controller) => {
    try {
        const params = new URLSearchParams()
        params.append('id_token', gClaim?.getAuthResponse().id_token)
        // params.append("email", gClaim?.getBasicProfile()?.getEmail())
        await axios.post(GoogleAuthAPI, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            signal: controller ? controller.signal : undefined
        })
        return true
    } catch (e) {
        return Promise.reject(e)
    }
}
const endGoogleSession = async () => {
    try {
        //@ts-ignore
        if (!window?.gapi) return Promise.reject({ message: 'invalid google api' })
        //@ts-ignore
        await window.gapi?.auth2?.init({ client_id })
        //@ts-ignore
        await window.gapi?.auth2?.getAuthInstance()?.signOut()
        return Promise.resolve({ message: 'success' })
    } catch (e) {
        return Promise.reject(e)
    }
}

export const authenticationService = {
    login,
    loginWithGoogle,
    logout
}
