import { useQuery } from '@tanstack/react-query'
import { httpGet } from '../../common/client'
import { list, lists, ListsQuery } from './types'
import { baseQueryConfig } from '../../common/react-query'
import { BASE } from '../../common/constants'

export const useListsQuery = (q: ListsQuery) => {
    const query = useQuery({
        ...baseQueryConfig,
        queryKey: ['lists-query', q],
        queryFn: async () => httpGet(`${BASE}/api/v1/lists`, q, lists)
    })

    return query
}

export const useListDetailsQuery = (id: string) => {
    const query = useQuery({
        ...baseQueryConfig,
        queryKey: [`list-details-query`, id],
        queryFn: async () => httpGet(`${BASE}/api/v1/lists/${id}`, null, list)
    })

    return query
}
