import { z } from 'zod'

export const site = z.object({
    ID: z.string(),
    TenantID: z.string(),
    Name: z.string(),
    PrimaryDomain: z.string(),
    Description: z.string(),
    Type: z.string(),
    Active: z.boolean(),
    Tags: z.array(z.string()),
    Hosts: z.array(z.string())
})
export type Site = z.infer<typeof site>
