import React from 'react'
import { ArrowForward, CheckCircleOutline, Event } from '@mui/icons-material'
import { renderNextThirdThursday } from '../../helpers/date'
import { colours } from '../../common/colours'
import './Announcement.css'

export function DashboardAnnouncement({ additionalContent }) {
    const hasNavigatedToSurvey = () => {
        return false
    }
    const iconProps = {
        style: { fontSize: '1.7rem', paddingLeft: '30px', color: 'white' }
    }

    return (
        <div
            className='col-md-12 col-lg-5 col-lg-offset-2 first-xs md-size'
            style={{ marginBottom: '1rem', fontSize: '1.3em' }}
        >
            <div
                className='LegacyMuiCard-Wrapper MuiPaper-root MuiCard-root jss14 MuiPaper-elevation1 MuiPaper-rounded'
                style={{
                    backgroundColor: colours.orange,
                    paddingTop: '15px',
                    paddingBottom: '15px',
                    marginBottom: '1.5rem',
                    cursor: 'pointer'
                }}
                onClick={() => {
                    window.open('https://forms.gle/fA7mNATq8X2WzQQK7', '_blank')
                }}
            >
                <li className='LegacyMuiListItem-Root LegacyMuiListItem-Gutters'>
                    <div className='flex-row-align-center'>
                        <h3 className='typography no-margin' style={{ color: colours.white, fontSize: '1.2rem' }}>
                            {hasNavigatedToSurvey()
                                ? 'Thank you for filling out our Community Survey'
                                : 'Fill out our Content Manager Community Survey'}
                        </h3>
                        {hasNavigatedToSurvey() ? (
                            <CheckCircleOutline {...iconProps} />
                        ) : (
                            <ArrowForward {...iconProps} />
                        )}
                    </div>
                </li>
            </div>

            <div
                className='LegacyMuiCard-Wrapper MuiPaper-root MuiCard-root jss14 MuiPaper-elevation1 MuiPaper-rounded'
                style={{ backgroundColor: colours.weird_blue }}
            >
                <li className='LegacyMuiListItem-Root LegacyMuiListItem-Gutters'>
                    <div
                        className='MuiListItemAvatar-root'
                        style={{ position: 'absolute', right: '10px', top: '10px' }}
                    >
                        <Event
                            style={{
                                fontSize: '2rem',
                                color: 'white'
                            }}
                        />
                    </div>

                    <div>
                        <h3 className='typography no-margin' style={{ color: colours.white, fontSize: '1.2rem' }}>
                            Content Manager Community Drop-in Training Sessions
                        </h3>
                        <div
                            className='typography subtitle'
                            style={{ color: colours.white, marginTop: '1rem', fontSize: '1rem' }}
                        >
                            <strong>Join us on the third Thursday of each month for a focused training session.</strong>
                            <br />
                        </div>
                        <div
                            className='typography subtitle'
                            style={{ color: colours.white, marginTop: '1rem', fontSize: '1rem' }}
                        >
                            <strong>Returning after the summer break on September 19th at 10 am - 10:30 am MST</strong>
                            {/*<strong>{renderNextThirdThursday()} at 10 am - 10:30 am MST</strong>*/}
                            <br />
                            <br />
                            <a
                                target='_blank'
                                href='https://us02web.zoom.us/j/82806268932?pwd=cVNTdElzeHIySVJsb280SWJvRENPQT09'
                                style={{ color: colours.orange, textDecoration: 'none' }}
                            >
                                Join Zoom Meeting
                            </a>
                            <br />
                            <br />
                            <a
                                target='_blank'
                                href='https://cdn.imagineeverything.com/incase-you-missed-it'
                                style={{ color: colours.orange, textDecoration: 'none' }}
                            >
                                In case you missed it...
                            </a>
                        </div>
                    </div>
                </li>
            </div>
            {additionalContent && (
                <div className=' MuiPaper-root MuiCard-root jss14 MuiPaper-elevation1 MuiPaper-rounded'>
                    {additionalContent}
                </div>
            )}
        </div>
    )
}
