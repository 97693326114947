import React, { Fragment, useEffect, useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { Format, is } from '../../helpers'
import { departmentService } from './department.service'
import Alert from '@mui/material/Alert'
import { fromOptional } from '../../common/components/selectors/SiteSelectorForAccount'

/**
 * DepartmentSelector
 * This component differs from DepartmentsAutocomplete in that it targets two
 * different kinds of sites.
 * - DepartmentSelector is for the transferring of ownership between departments, all departments
 *   that a user has access to should appear in the list
 * - DepartmentsAutocomplete is for the filtering of content within a selected site context - Departments not linked
 *   to the currently selected site should not appear.
 * */
interface DepartmentSelectorProps {
    departmentId: string | { UUID: string; Valid: boolean } | null
    onChange: (v: string) => void
}

export const DepartmentSelector = ({ departmentId, onChange }: DepartmentSelectorProps) => {
    const [departments, setDepartments] = useState<any>([])
    const [requestController, setRequestController] = useState(new AbortController())
    const [renderMap, setRenderMap] = useState({})
    const originalValue = fromOptional(departmentId)

    useEffect(() => {
        const newController = new AbortController()
        requestController.abort()
        setRequestController(newController)
        // TODO => Departments
        departmentService.query({ page: 1, pageSize: 9999 }).then((r) => {
            const results = r?.Rows || []
            if (is.objectArray(results)) {
                const rm = {}
                for (let i = 0; i < results.length; i++) {
                    //TODO => NST-128
                    results[i] = results[i]
                    rm[results[i]?.ID || ''] = results[i]
                }
                setRenderMap(rm)
            }
            setDepartments(results)
        })
        return () => {
            requestController.abort()
            newController.abort()
        }
    }, [])

    function hasBeenModified() {
        return true
    }

    function onChangeAdapter(e) {
        if (is.func(onChange)) {
            onChange(e?.target?.value || '')
        }
    }

    return (
        <Fragment>
            {hasBeenModified() && (
                <div style={{ marginBottom: '8px' }}>
                    <Alert style={{ paddingBottom: 0 }} severity={'warning'}>
                        Transferring ownership of this page will have the following effects
                    </Alert>
                    <Alert style={{ paddingTop: 0 }} severity={'warning'} icon={false}>
                        <div>{alertWarningComponent()}</div>
                    </Alert>
                </div>
            )}
            <FormControl key={'department-selector'} style={{ width: '100%' }}>
                <InputLabel variant={'standard'}>Department</InputLabel>
                <Select
                    variant='standard'
                    renderValue={(selected) => {
                        const look = renderMap?.[selected]?.name
                        if (look) return look
                        return departments.find((x) => x?.id === selected)?.name || ''
                    }}
                    value={Format.toUUIDStringOr(departmentId, '')}
                    onChange={onChangeAdapter}
                >
                    {departments.map((x) => (
                        <MenuItem key={'departments-' + x.id + 'key'} value={x.id}>
                            {x.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Fragment>
    )
}

const alertWarningComponent = () => {
    return (
        <div className={'flex-row'}>
            <ul>
                <li> The page will be re-shared to the Hosts of the chosen department.</li>
                <li> The page will have its navigation removed from any site.</li>
                <li> The page will be set as a draft</li>
            </ul>
        </div>
    )
}
