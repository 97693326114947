import { ignoredKeys, mandatoryFields } from '../Sites'
import { useMemo, useState } from 'react'
import { isEqual } from 'lodash'
import { socialMediaTypes } from './EditSettingsSocialMedia'
import useSiteForAccountsQueryMutation from '../../../../common/query-hooks/useSiteForAccountsQueryMutation'
import { CustomFieldsForm } from '../../../../common/components/CustomFieldsForm'
import { useParams } from 'react-router-dom'

// fields that are NOT custom / user-generated
const ignoredFields = mandatoryFields
    .concat(ignoredKeys)
    .concat(socialMediaTypes)
    .concat(['fax', 'Primary', 'Secondary', 'LogoSrc'])

// returns a new object with non deleted fields
function deleteObjectFields(object: Record<string, any>, fields: string[]) {
    const objectCopy = { ...object }
    for (let i = 0; i < ignoredFields.length; i++) {
        if (fields[i] in objectCopy) {
            delete objectCopy[ignoredFields[i]]
        }
    }
    return objectCopy
}

const entryLengthErrorMsg = 'Entry must be at least 2 characters long'
const entryLengthErrorDeleteMsg = entryLengthErrorMsg + '. Update or delete this field'
const keyExistsErrorMsg = 'This key already exists'

function EditSettingsCustomFields() {
    const params = useParams()
    const { siteData, mutation } = useSiteForAccountsQueryMutation(params.id)

    // make a copy of siteData with filtered site settings fields
    // set that is initial form data
    const defaultFormDataValues = useMemo(() => {
        return deleteObjectFields(siteData.settings, ignoredFields)
    }, [siteData])

    const [formData, setFormData] = useState<Record<string, string>>(defaultFormDataValues)
    const formDataHasChanges = useMemo(
        () => !isEqual(formData, defaultFormDataValues),
        [defaultFormDataValues, formData]
    )
    const [formDataError, setFormDataError] = useState<any | null>({
        customFieldKeyErrorMsg: '',
        customFieldValueErrorMsg: ''
    })
    const disableSaveButton = useMemo(
        () => !formDataHasChanges || !!(formDataError && Object.values(formDataError).find((v) => !!v)),
        [formDataError, formDataHasChanges]
    )

    const [removedFields, setRemovedFields] = useState<string[]>([])

    function handleRemoveSetting(customFieldKey) {
        const formDataCopy = { ...formData }
        delete formDataCopy[customFieldKey]
        setFormData(formDataCopy)
        setRemovedFields([...removedFields, customFieldKey])
        setFormDataError({
            customFieldKeyErrorMsg: '',
            customFieldValueErrorMsg: ''
        })
    }

    function handleAddSetting(newCustomFieldKey: string, newCustomFieldValue: any, successCallback: () => void) {
        if (newCustomFieldKey.length <= 1 || newCustomFieldValue.length <= 1 || formData[newCustomFieldKey]) {
            setFormDataError({
                customFieldKeyErrorMsg:
                    newCustomFieldKey.length <= 1
                        ? entryLengthErrorMsg
                        : formData[newCustomFieldKey]
                          ? keyExistsErrorMsg
                          : '',

                customFieldValueErrorMsg: newCustomFieldValue.length <= 1 ? entryLengthErrorMsg : ''
            })
        } else {
            setFormData((prev) => ({
                ...prev,
                [newCustomFieldKey]: newCustomFieldValue
            }))

            setFormDataError({
                customFieldKeyErrorMsg: '',
                customFieldValueErrorMsg: ''
            })

            successCallback()
        }
    }

    function handleCustomFieldValueOnChange(customFieldKey, newValue) {
        setFormData((prev) => ({
            ...prev,
            [customFieldKey]: newValue
        }))

        setFormDataError({
            customFieldKeyErrorMsg: formDataError.customFieldKeyErrorMsg,
            customFieldValueErrorMsg: formDataError.customFieldValueErrorMsg
        })
    }

    function handleSubmit() {
        // check for fields with values of length <= 1
        // update formDataError with error msg for each wrong field
        let keysWithInvalidValues: Record<string, any> = {}
        Object.keys(formData).forEach((key) => {
            if (formData[key].length <= 1) {
                keysWithInvalidValues[key] = entryLengthErrorDeleteMsg
            }
        })

        if (Object.keys(keysWithInvalidValues).length) {
            setFormDataError((prev) => ({
                ...prev,
                ...keysWithInvalidValues
            }))
        } else {
            // Ensure not to remove fields within settings object that are not used in this component
            const updateSettingsJsonObject = {
                ...siteData.settings,
                ...formData
            }

            // remove
            removedFields.forEach((key) => delete updateSettingsJsonObject?.[key])

            mutation.mutate({
                id: siteData.id,
                settings: updateSettingsJsonObject
            })
        }
    }

    if (!siteData) return null

    return (
        <CustomFieldsForm
            title='What settings would you like to add?'
            isSaveDisabled={disableSaveButton}
            value={formData}
            valueOnChangeHandler={(key, value) => handleCustomFieldValueOnChange(key, value)}
            valueOnAddHandler={(key, value, successCallback) => handleAddSetting(key, value, successCallback)}
            valueOnRemoveHandler={(key) => handleRemoveSetting(key)}
            valueErrors={formDataError}
            customFieldKeyErrorMsg={formDataError.customFieldKeyErrorMsg}
            customFieldValueErrorMsg={formDataError.customFieldValueErrorMsg}
            onSubmit={() => handleSubmit()}
            onReset={() => {
                setFormData(defaultFormDataValues)
                setRemovedFields([])
            }}
        />
    )
}

export default EditSettingsCustomFields
