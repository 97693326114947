import React, { HTMLAttributeAnchorTarget, useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Box, IconButton, Popper } from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined'
import { HelpTooltip } from '../../../common/components'
import { Get } from '../../../helpers'
import moment from 'moment'
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {
    formatAllDayEndDateTimestamp,
    formatAllDayStartDateTimestamp,
    formatRegularTimestamp
} from '../../../helpers/timestamp'
import { getContentEditorPath, useAppNavigation } from '../../../app/useAppNavigation'
import { nameZ$ } from '../../../common/client'
import { Link } from 'react-router-dom'
import { getPublishStatus, publishStatusColour } from '../../content/editor/ContentEditorSaveCard'
import { Trackable } from '../../../common/react-query'
import { useAppContext } from '@/pkgs/auth/atoms'

const uuidNil = '00000000-0000-0000-0000-000000000000'

export * from './MenuLightCell'

export const TitleCell = ({ row }) => {
    const { getRelativePath } = useAppNavigation()

    const { title, tags, navParents } = row
    let parents = []
    if (navParents) parents = navParents.filter(({ id }) => id !== row.id && id !== uuidNil)
    return (
        <CellWrapper>
            {navParents && navParents.length > 0 && (
                <CellLine>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {parents && parents.length > 0 && (
                            <AccountTreeOutlinedIcon
                                style={{ fontSize: '1rem', opacity: 0.5, marginRight: '0.2rem' }}
                            />
                        )}
                        <span style={{ lineHeight: 1.8, opacity: 0.5 }}>
                            {parents.map(({ title }) => title).join(' > ')}
                        </span>
                    </div>
                </CellLine>
            )}
            <CellLine href={getRelativePath(getContentEditorPath(row) || '') || undefined}>
                <span style={{ fontSize: 18 }}>{title}</span>
            </CellLine>
            {tags.length > 0 && (
                <CellLine>
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            lineHeight: 1.8,
                            opacity: 0.5
                        }}
                    >
                        <LabelOutlinedIcon style={{ fontSize: 15 }} />
                        <span>&nbsp;{tags.map(({ name }) => name).join(', ')}</span>
                    </div>
                </CellLine>
            )}
        </CellWrapper>
    )
}

export const TemplateCell = ({ row }) => {
    const { templateTitle } = row
    return (
        <CellWrapper>
            <CellLine>{templateTitle}</CellLine>
        </CellWrapper>
    )
}

export const PrivacyLevelCell = ({ row }) => {
    const { privacyLevel } = row
    let text = 'undefined'
    switch (privacyLevel) {
        case 2:
            text = 'staff'
            break
        case 0:
            text = 'public'
            break
    }
    return (
        <CellWrapper>
            <CellLine>{text}</CellLine>
        </CellWrapper>
    )
}

export const EditorsCell = ({ row }) => {
    const { ownerName, publisherName, owner, publisher } = row
    return (
        <CellWrapper>
            <CellLine>{ownerName ? ownerName.Firstname + ' ' + ownerName.Lastname : owner}</CellLine>
            <CellLine>{publisherName ? publisherName.Firstname + ' ' + publisherName.Lastname : publisher}</CellLine>
        </CellWrapper>
    )
}

export const DateCell = ({ row }) => {
    const created = Date.parse(row['created'])
    const updated = Date.parse(row['updated'])
    // TODO => Mismatch here
    const label = created === updated ? 'Created' : 'Modified'
    const value = moment(updated).format('YYYY-MM-DD, h:mm:ss a')?.split(',')
    const date = value?.[0]
    const time = value?.[1]
    return (
        <div style={{ lineHeight: 'normal', fontSize: '0.75rem' }}>
            <div style={{ lineHeight: 'normal' }}>
                <strong>{label}</strong>
            </div>
            <div style={{ lineHeight: 'normal' }}>
                {date}
                <br />
                {time}
            </div>
        </div>
    )
}

export const NullableDateCell = ({ row, fieldName }) => {
    const updated = Date.parse(row[fieldName])
    // TODO => Mismatch here
    const value = updated ? moment(updated).format('YYYY-MM-DD, h:mm:ss a') : 'not set'
    const [date, time] = value.split(',')

    return (
        <div style={{ lineHeight: 'normal' }}>
            <div style={{ lineHeight: 'normal' }}>
                {date}
                {time ? <br /> : ''}
                {time}
            </div>
        </div>
    )
}

// for news only
export const SettingsDatesCell = ({ row, settingsName }) => {
    const value = moment(row?.['settings']?.[settingsName])
        .format('YYYY-MM-DD, h:mm:ss a')
        ?.split(',')
    const date = value?.[0]
    const time = value?.[1]
    return date && time ? (
        <div style={{ lineHeight: 'normal', fontSize: '0.75rem' }}>
            <div style={{ lineHeight: 'normal' }}>
                {date}
                <br></br>
                {time}
            </div>
        </div>
    ) : (
        <div style={{ lineHeight: 'normal', fontSize: '0.75rem' }}>
            <div style={{ lineHeight: 'normal' }}>&nbsp;</div>
        </div>
    )
}

// for events only
// if isAllDay is true, set end date to the previous day
// EventEdtior.js for more info
export const EventsDatesCell = ({ row, settingsName }) => {
    const isAllDay = row?.settings?.isAllDay || false
    const timestamp = row?.settings?.[settingsName]
    const fmtTimestamp = isAllDay
        ? settingsName === 'enddate'
            ? formatAllDayEndDateTimestamp(timestamp)
            : formatAllDayStartDateTimestamp(timestamp)
        : formatRegularTimestamp(timestamp)

    const [date, time] = fmtTimestamp.split(',')
    return date || time ? (
        <div style={{ lineHeight: 'normal', fontSize: '0.75rem' }}>
            <div style={{ lineHeight: 'normal' }}>
                {date}
                {isAllDay && settingsName == 'enddate' ? '*' : ''}
                <br></br>
                {time}
            </div>
        </div>
    ) : (
        <div style={{ lineHeight: 'normal', fontSize: '0.75rem' }}>
            <div style={{ lineHeight: 'normal' }}>&nbsp;</div>
        </div>
    )
}

export const NotesCell = ({ row, style }) => {
    const state = row
    const status = getPublishStatus(state.publish_at, state.expire_at)
    const statusMap = {
        draft: 'edit_note',
        published: 'done',
        scheduled: 'schedule_send',
        expired: 'auto_delete'
    }
    return (
        <div style={{ lineHeight: 'normal', ...style }}>
            <HelpTooltip
                customIcon={
                    <span
                        className='material-icons-outlined'
                        style={{ fontSize: '1.5rem', color: '#6c6c6c', cursor: 'help' }}
                    >
                        {statusMap[status]}
                    </span>
                }
                contents={[status]}
                position={{ width: '10rem', left: 0 }}
                removeDecoration
            />
            {Get.isDepartment(state) && (
                <HelpTooltip
                    customIcon={
                        <span
                            className='material-icons-outlined'
                            style={{ fontSize: '1.3rem', color: '#4E01D5', cursor: 'help' }}
                        >
                            corporate_fare
                        </span>
                    }
                    contents={[`This page belongs to ${state?.department?.name}`]}
                    position={{ width: '10rem', left: 0, whiteSpace: 'normal' }}
                    removeDecoration
                />
            )}
            {state.sites.length === 0 && (
                <HelpTooltip
                    customIcon={
                        <span
                            className='material-icons-outlined'
                            style={{ fontSize: '1.4rem', color: '#6c6c6c', cursor: 'help' }}
                        >
                            person_off
                        </span>
                    }
                    contents={['This page is not shared with any sites']}
                    position={{ width: '10rem', left: 0, whiteSpace: 'normal' }}
                    removeDecoration
                />
            )}
            {state.sites.length > 1 && (
                <HelpTooltip
                    customIcon={
                        <span
                            className='material-icons-outlined'
                            style={{ fontSize: '1.4rem', color: '#6c6c6c', cursor: 'help' }}
                        >
                            group
                        </span>
                    }
                    contents={[
                        'This page is shared with more than one site and only editable with the right Permissions'
                    ]}
                    position={{ width: '10rem', left: 0, whiteSpace: 'normal' }}
                    removeDecoration
                />
            )}

            {Get.isElapsed(state, 'expirationDate') && (
                <HelpTooltip
                    customIcon={
                        <span
                            className='material-icons-outlined'
                            style={{ fontSize: '1.3rem', color: '#6c6c6c', cursor: 'help' }}
                        >
                            timer_off
                        </span>
                    }
                    contents={['This page is expired/unpublished']}
                    position={{ width: '10rem', left: 0 }}
                    removeDecoration
                />
            )}
            {Get.isDistrict(state) && (
                <HelpTooltip
                    customIcon={
                        <span
                            className='material-icons-outlined'
                            style={{ fontSize: '1.3rem', color: '#6c6c6c', cursor: 'help' }}
                        >
                            hotel_class
                        </span>
                    }
                    contents={[
                        'This page is shared with more than one site and includes a portion of content which is specific to this site'
                    ]}
                    position={{ width: '10rem', left: 0, whiteSpace: 'normal' }}
                    removeDecoration
                />
            )}
            {state?.settings?.imported && (
                <HelpTooltip
                    customIcon={
                        <span
                            className='material-icons-outlined'
                            style={{ fontSize: '1.5rem', color: '#6c6c6c', cursor: 'help' }}
                        >
                            transit_enterexit
                        </span>
                    }
                    contents={[
                        `This ${state?.type === 'news' ? 'article' : 'event'} is imported ${
                            state?.settings?.importInfo?.source ? `from ${state.settings.importInfo.source}` : ''
                        }`,
                        'Changes made to imported content may not persist through the import process'
                    ]}
                    position={{ width: '10rem', left: 0 }}
                    removeDecoration
                />
            )}
            {!!Get.migrationInfo(state) && (
                <HelpTooltip
                    customIcon={
                        <span
                            className='material-icons-outlined'
                            style={{ fontSize: '1.5rem', color: '#6c6c6c', cursor: 'help' }}
                        >
                            move_down
                        </span>
                    }
                    contents={[`Content created by Automated Migration`]}
                    position={{ width: '10rem', left: 0 }}
                    removeDecoration
                />
            )}
        </div>
    )
}

export const CellWrapper = (props) => {
    return <div style={{ lineHeight: 'normal', width: '100%', ...props.style }}>{props.children}</div>
}

const useStylesCellLine = makeStyles((theme) => ({
    paper: {
        border: '1px solid',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper
    }
}))

export const CellLine = (props) => {
    const classes = useStylesCellLine()
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

    function isEllipsisActive(e) {
        return e.offsetWidth < e.scrollWidth
    }

    if (!!props?.href) {
        return (
            <>
                <Link
                    to={props.href}
                    style={{
                        color: 'black',
                        lineHeight: 'normal',
                        width: '100%',
                        textOverflow: 'ellipsis',
                        display: 'block',
                        overflow: 'hidden',
                        textDecoration: 'underline'
                    }}
                    onMouseEnter={(event) =>
                        setAnchorEl(isEllipsisActive(event.currentTarget) ? event.currentTarget : null)
                    }
                    onMouseLeave={() => setAnchorEl(null)}
                >
                    {props.children}
                </Link>
                {/*@ts-ignore*/}
                <Popper open={!!anchorEl} anchorEl={anchorEl}>
                    <div className={classes.paper}>{props.children}</div>
                </Popper>
            </>
        )
    }

    return (
        <>
            <div
                //@ts-ignore
                component={props?.href ? 'a' : undefined}
                //@ts-ignore
                href={props?.href || undefined}
                style={{
                    lineHeight: 'normal',
                    width: '100%',
                    textOverflow: 'ellipsis',
                    display: 'block',
                    overflow: 'hidden',
                    textDecoration: !!props?.href ? 'underline' : undefined
                }}
                onMouseEnter={(event) =>
                    setAnchorEl(isEllipsisActive(event.currentTarget) ? event.currentTarget : null)
                }
                onMouseLeave={() => setAnchorEl(null)}
            >
                {props.children}
            </div>
            {/*@ts-ignore*/}
            <Popper open={!!anchorEl} anchorEl={anchorEl}>
                <div className={classes.paper}>{props.children}</div>
            </Popper>
        </>
    )
}

export const CellMenu = ({ row, setMenu }) => {
    return (
        setMenu && (
            <div className={'site-options icon'}>
                <IconButton
                    aria-controls={'pageMenu'}
                    aria-haspopup='true'
                    onClick={(e) => setMenu(e, row)}
                    size='large'
                >
                    <MoreHorizIcon />
                </IconButton>
            </div>
        )
    )
}
export const ReorderMenu = ({ row, pinnedNews, handleReorder }) => {
    const index = pinnedNews.indexOf(row)
    const disabled = row.disabled

    const lookBack = (index) => {
        if (!pinnedNews?.[index]) {
            return -1
        }
        for (let i = index; i > -1; i--) {
            if (pinnedNews?.[i]?.disabled === false) {
                return i
            }
        }
        return -1
    }
    const lookAhead = (index) => {
        if (!pinnedNews?.[index]) {
            return -1
        }
        for (let i = index; i < pinnedNews.length; i++) {
            if (pinnedNews?.[i]?.disabled === false) {
                return i
            }
        }
        return -1
    }

    const swap = (newIndex) => {
        const clone = [...pinnedNews]
        clone[index] = pinnedNews[newIndex]
        clone[newIndex] = row
        for (let i = 0; i < clone.length; i++) clone[i].settings.priority = i
        handleReorder(clone)
    }
    const moveDown = () => {
        const downIndex = lookAhead(index + 1)
        if (downIndex === -1) {
            return
        }
        swap(downIndex)
    }
    const moveUp = () => {
        const upIndex = lookBack(index - 1)
        if (upIndex === -1) {
            return
        }
        swap(upIndex)
    }

    return (
        <div className='column-seven flex-column'>
            <ArrowDropUpIcon
                onClick={moveUp}
                style={{
                    cursor: disabled ? 'default' : 'pointer',
                    opacity: disabled ? '0.3' : '1'
                }}
            />
            <ArrowDropDownIcon
                onClick={moveDown}
                style={{
                    cursor: disabled ? 'default' : 'pointer',
                    opacity: disabled ? '0.3' : '1'
                }}
            />
        </div>
    )
}

export const AdminCell = ({ ID }: { ID: string }) => {
    const [name, setName] = useState<string>('...')
    useEffect(() => {
        const subs = nameZ$('account', ID).subscribe((res) => {
            if (res && res.ID === ID) {
                setName(res.Name)
                subs.unsubscribe()
            }
        })
        return () => {
            subs.unsubscribe()
        }
    }, [])

    return (
        <div className={'MuiDataGrid-cellContent'}>
            <div className={'MuiDataGrid-cellContent'} title={name}>
                {name}
            </div>
            <div className={'MuiDataGrid-cellContent'} title={ID} style={{ fontSize: '0.7rem', color: '#7e7e7e' }}>
                {ID}
            </div>
        </div>
    )
}

export const TrackableCell = ({ trackable, ownerID }: { trackable: Trackable; ownerID?: string }) => {
    const [name, setName] = useState<string>(trackable.UpdatedBy)

    const created = trackable.CreatedAt === trackable.UpdatedAt
    const prefix = created ? 'Created' : 'Updated'
    const adminID = created ? trackable.CreatedBy : trackable.UpdatedBy

    useEffect(() => {
        if (ownerID && adminID === ownerID) {
            setName('Self')
            return () => {}
        }
        if (adminID === '00000000-0000-0000-0000-000000000000' || adminID === '45f06f48-a93c-414e-b9a0-7582e0abc085') {
            setName('System')
            return () => {}
        }

        const subs = nameZ$('account', adminID).subscribe((res) => {
            if (res && res.ID === adminID) {
                setName(res.Name)
                subs.unsubscribe()
            }
        })
        return () => {
            subs.unsubscribe()
        }
    }, [])

    const dateStr = moment(trackable.UpdatedAt).format('YYYY-MM-DD HH:mm:ss')
    const dateStrHuman = moment(trackable.UpdatedAt).fromNow()

    return (
        <div className={'MuiDataGrid-cellContent'}>
            <div className={'MuiDataGrid-cellContent'} title={dateStr}>
                {dateStrHuman}
            </div>
            <div className={'MuiDataGrid-cellContent'} title={adminID} style={{ fontSize: '0.7rem', color: '#7e7e7e' }}>
                {`${prefix} by ${name} [${adminID}]`}
            </div>
        </div>
    )
}

export const AdminOrOwnerCell = ({
    adminID,
    ownerID,
    ownerName
}: {
    adminID: string
    ownerID: string
    ownerName: string
}) => {
    const [name, setName] = useState<string>('...')

    useEffect(() => {
        if (adminID === ownerID || adminID === '00000000-0000-0000-0000-000000000000') {
            setName(ownerName)
            return () => {}
        }

        const subs = nameZ$('account', adminID).subscribe((res) => {
            if (res && res.ID === adminID) {
                setName(res.Name)
                subs.unsubscribe()
            }
        })
        return () => {
            subs.unsubscribe()
        }
    }, [])

    return (
        <div className={'MuiDataGrid-cellContent'}>
            <div className={'MuiDataGrid-cellContent'} title={name}>
                {name}
            </div>
            <div className={'MuiDataGrid-cellContent'} title={adminID} style={{ fontSize: '0.7rem', color: '#7e7e7e' }}>
                {adminID}
            </div>
        </div>
    )
}

interface IDToNameProps {
    tableName: string
    ID: string
}

export function useIDToName({ tableName, ID }: IDToNameProps) {
    const [name, setName] = useState<string>(ID ? ID : '')
    const appContext = useAppContext()

    useEffect(() => {
        if (!ID) return () => {}

        // all tenant sites are already loaded, so we can use the context
        if (tableName === 'site') {
            const siteName = appContext.getSiteName(ID)
            if (siteName) {
                setName(siteName)
                return () => {}
            }
        }

        const subs = nameZ$(tableName, ID).subscribe((res) => {
            if (res && res.ID === ID) {
                setName(res.Name)
                subs.unsubscribe()
            }
        })
        return () => {
            subs.unsubscribe()
        }
    }, [])

    return name
}

export const IDToNameCell = ({ tableName, ID }: IDToNameProps) => {
    const name = useIDToName({ tableName, ID })

    return (
        <div className={'MuiDataGrid-cellContent'}>
            <div className={'MuiDataGrid-cellContent'} title={name}>
                {name || '-'}
            </div>
            <div className={'MuiDataGrid-cellContent'} title={ID} style={{ fontSize: '0.7rem', color: '#7e7e7e' }}>
                {ID}
            </div>
        </div>
    )
}

export const TwoLinesCell = ({
    l1,
    l2,
    path,
    target
}: {
    l1: string
    l2: string
    path?: string
    target?: HTMLAttributeAnchorTarget
}) => {
    return (
        <Box
            className={'MuiDataGrid-cellContent'}
            component={path ? 'a' : undefined!}
            href={path ? path : undefined}
            target={target ? target : undefined}
            sx={
                path
                    ? {
                          textDecoration: 'none',
                          color: 'black',
                          '&:hover': {
                              cursor: 'pointer',
                              textDecoration: 'underline'
                          }
                      }
                    : undefined
            }
        >
            <div className={'MuiDataGrid-cellContent'} title={l1}>
                {l1}
            </div>
            <div className={'MuiDataGrid-cellContent'} title={l2} style={{ fontSize: '0.7rem', color: '#7e7e7e' }}>
                {l2}
            </div>
        </Box>
    )
}

export function PublishPeriodCell({
    publishAt,
    expireAt
}: {
    publishAt: string | Date | null | undefined
    expireAt: string | Date | null | undefined
}) {
    const status = getPublishStatus(publishAt, expireAt)
    return (
        <div style={{ lineHeight: 'normal', fontSize: '0.75rem' }}>
            <div style={{ lineHeight: 'normal' }}>
                <strong style={{ color: publishStatusColour[status] }}>{status}</strong>
            </div>
            <div style={{ lineHeight: 'normal' }}>
                <strong>pub:</strong> {publishAt ? moment(publishAt).format('YYYY-MM-DD, h:mm:ss a') : 'not set'}
                <br />
                <strong>exp:</strong> {expireAt ? moment(expireAt).format('YYYY-MM-DD, h:mm:ss a') : 'not set'}
            </div>
        </div>
    )
}
