import { z } from 'zod'
import { PagingQuery, SortingQuery, dateTime, paged, trackable, uuidSchema } from '../../../common/react-query'

export type IssuesQuery = {
    Search?: string
    Statuses?: string[]
    CreationTypes?: string[]
    TopicID?: string
} & PagingQuery &
    SortingQuery

const issue = trackable.extend({
    CreationType: z.string().optional(),
    Status: z.string(),
    TopicID: z.string().optional(),
    TotalSent: z.number(),
    Subject: z.string().optional(),
    Domain: z.string().optional(),
    StartsAt: dateTime.optional().nullable()
})
export const issues = paged.extend({
    Rows: z.array(issue)
})
export type Issue = z.infer<typeof issue>
export type Issues = z.infer<typeof issues>
