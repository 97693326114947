import React, { useEffect, useState } from 'react'
import { z } from 'zod'
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectProps,
    Switch,
    TextFieldProps
} from '@mui/material'
import { tagService } from '../../../pkgs/system/tags/tag.service'
import { TagsSelectorForSites } from './TagsSelectorForSites'
import { atom, useAtom } from 'jotai'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import { isRouteExternal } from '../../../helpers'
import { CustomMenuItem } from '../custom-context-menu/CustomMenu'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { useAppContext, useCurrentSite, useSitesForContent } from '../../../pkgs/auth/atoms'
import { entityScope, typeToEntityScope } from '../../../pkgs/auth/entityScope'
import { asSecured } from '../../../pkgs/auth/permissions/securityMapping'
import { Site } from '../../../pkgs/auth/types'
import { BASE } from '../../constants'
import { TagType } from '../../../pkgs/system/tags/types'

const site = z.object({
    ID: z.string(),
    Name: z.string(),
    PrimaryDomain: z.string(),
    Tags: z.array(z.string()),
    RestrictedByParent: z.boolean()
})
const sitesForSelector = z.array(site)
export type SitesForSelector = z.infer<typeof sitesForSelector>
export type SiteForSelector = z.infer<typeof site>

export type SiteSelectorForContentProps = {
    Selected: string[] | undefined
    ParentSitesIDs?: string[] // parent site IDs
    ContentType: entityScope
    IgnoreSiteID?: boolean
    OnChange?: (selected: string[], departmentID: string | null) => void
    Disabled?: boolean
    HasError?: boolean
    Label?: string
    Multi?: boolean
    Required?: boolean
    Variant?: 'outlined' | 'standard'
    ShowDistrictWideSwitch?: boolean
}

// should be in content
export const SiteSelectorForContent = ({
    Selected,
    OnChange = () => {},
    ContentType,
    ParentSitesIDs,
    Disabled = false,
    HasError = false,
    IgnoreSiteID = false,
    Label = 'Sites',
    Multi = true,
    Required = false,
    Variant = 'outlined',
    ShowDistrictWideSwitch
}: SiteSelectorForContentProps) => {
    const evaluators = useAppContext()
    const currentSite = useCurrentSite()
    const sitesForContent = useSitesForContent({
        ContentType: typeToEntityScope(ContentType),
        ParentSitesIDs: ParentSitesIDs,
        IgnoreSiteID
    })
    const [sites, setSites] = useState<SitesForSelector | undefined>(undefined)
    const [selected, setSelected] = useState<string[]>(Selected || evaluators.getDefaultSitesForSelectors())
    const [serverTags, setServerTags] = useState<{ id: string; name: string }[]>([])
    const [tagsAbort, setTagsAbort] = useState(new AbortController())

    const [districtWideSwitchIsEnabled, setDistrictWideSwitchIsEnabled] = useState(false)

    useEffect(() => {
        if (!sites) return

        tagsAbort.abort()
        const newController = new AbortController()
        setTagsAbort(newController)

        const run = async () => {
            const res = await tagService.getAllAlt(newController.signal, { tagType: TagType.Site })
            if (res.failure) {
                return
            }

            const options = res.data.results.map((o) => ({ name: o.name, id: o.id }))
            setServerTags(options)
        }
        run().catch(console.error)
    }, [sites])

    useEffect(() => {
        updateValue(Selected || evaluators.getDefaultSitesForSelectors())
    }, [Selected])

    const updateValue = (v: string[]) => {
        setSelected(v)
        OnChange(v, currentSite?.Type === 'department' ? currentSite.ID : null)
    }

    const updateSites = (s: SitesForSelector) => {
        const newSites = s.sort((a, b) => a.Name.localeCompare(b.Name))
        setSites(newSites)
    }

    useEffect(() => {
        if (sitesForContent.length) {
            updateSites(sitesForContent)
        }
    }, [ContentType, ParentSitesIDs])

    return !Array.isArray(sites) || sites.length === 0 ? null : (
        <div style={{ width: '100%' }}>
            <FormControl sx={{ width: '100%', display: 'flex' }} variant={Variant}>
                <InputLabel required={Required}>{Label}</InputLabel>
                <Select
                    error={Boolean(HasError)}
                    disabled={Boolean(Disabled) || districtWideSwitchIsEnabled === true}
                    multiple={Multi}
                    value={selected}
                    onChange={(e) => {
                        updateValue(e.target.value as string[])
                    }}
                    input={Variant == 'outlined' ? <OutlinedInput /* label="Sites" */ label={Label} /> : undefined}
                    renderValue={(selected) =>
                        sites
                            ?.filter((s) => selected.indexOf(s.ID) > -1)
                            .map((s) => s.Name)
                            .join(', ')
                    }
                    // MenuProps={MenuProps}
                >
                    {sites.map((site) => (
                        <MenuItem key={site.ID} value={site.ID}>
                            {Multi && <Checkbox checked={selected.indexOf(site.ID) > -1} />}
                            <ListItemText primary={site.Name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Box sx={{ display: 'flex' }}>
                {Multi && (
                    <FormControl>
                        <TagsSelectorForSites
                            sites={sites}
                            selectedSiteIDs={selected}
                            serverTags={serverTags}
                            onChange={(command, siteIDs) => {
                                if (command === 'select') {
                                    updateValue(Array.from(new Set([...selected, ...siteIDs])))
                                } else {
                                    updateValue(selected.filter((s) => siteIDs.indexOf(s) === -1))
                                }
                            }}
                            disabled={Boolean(Disabled) || districtWideSwitchIsEnabled === true}
                        />
                    </FormControl>
                )}
                {ShowDistrictWideSwitch && (
                    <FormControlLabel
                        sx={{ alignSelf: 'flex-start' }}
                        disabled={Disabled}
                        label={'District Wide'}
                        control={
                            <Switch
                                disabled={Disabled}
                                checked={districtWideSwitchIsEnabled}
                                onChange={(e) => {
                                    setDistrictWideSwitchIsEnabled(e.target.checked)
                                    if (e.target.checked) {
                                        OnChange?.([], null)
                                    }
                                }}
                            />
                        }
                    />
                )}
            </Box>
        </div>
    )
}

type SiteSelectorForPreviewParams = {
    contentID: string
    parentSitesIDs?: string[] // parent site IDs
    contentType: string
    onClick?: () => void
}
export const SiteSelectorForPreview = ({
    contentID,
    contentType,
    parentSitesIDs,
    onClick = () => {}
}: SiteSelectorForPreviewParams) => {
    console.log('parentSitesIDs', parentSitesIDs)
    const currentSite = useCurrentSite()
    const sitesForContent = useSitesForContent({
        ContentType: contentType,
        ParentSitesIDs: parentSitesIDs
    })

    const [sites, setSites] = useState<SitesForSelector | undefined>(undefined)
    const [loading, setLoading] = useState<boolean>(false)

    const updateSites = (s: SitesForSelector) => {
        setSites(s.sort((a, b) => a.Name.localeCompare(b.Name)))
        setLoading(false)
    }

    useEffect(() => {
        if (!currentSite || !currentSite.ID || !contentType) return
        updateSites(sitesForContent)
    }, [currentSite, contentType, parentSitesIDs, sitesForContent])

    return loading ? (
        <p>Loading...</p>
    ) : (
        <ul>
            {sites?.map((site) => (
                <li key={site.ID}>
                    <a
                        href={`${BASE}/api/v1/content/preview/${contentID}?previewSiteID=${site.ID}`}
                        target='_blank'
                        onClick={onClick}
                    >
                        {site.Name}
                    </a>
                </li>
            ))}
        </ul>
    )
}

async function handlePreview(
    this: { openDialog: (content: contentLike) => Promise<void>; currentSite: Site | undefined },
    state: contentLike
) {
    if (isRouteExternal(state.route)) {
        return window.open(state.route)
    }
    if (this.currentSite?.Type === 'department') {
        return await this.openDialog(state)
    }

    return window.open(`${BASE}/api/v1/content/preview/${state.id}?previewSiteID=${this.currentSite?.ID}`, '_blank')
}

const handlePreviewContextAtom = atom({
    openDialog: (content: contentLike) => Promise.resolve(),
    currentSite: undefined as Site | undefined
})
export const handlePreviewAtom = atom((get) => handlePreview.bind(get(handlePreviewContextAtom)))

type contentLike = { id: string; type: string; sites: string[]; route: string }
export const PreviewForDepartmentDialogAlt = () => {
    const [content, setContent] = useState<{ id: string; type: string; sites: string[] } | undefined>(undefined)
    const [open, setOpen] = useState<boolean>(false)
    const currentSite = useCurrentSite()
    const [, setHandlePreviewContext] = useAtom(handlePreviewContextAtom)
    const resolve = () => {}
    const reject = () => {}

    const openDialog = (content: contentLike) => {
        if (!content) return Promise.resolve()
        setContent(content)
        setOpen(true)
        return new Promise<void>((res, rej) => {
            res = resolve
            rej = reject
        })
    }
    useEffect(() => {
        if (!currentSite) return
        try {
            setHandlePreviewContext({ openDialog, currentSite })
        } catch (e) {
            console.error(e)
        }
    }, [currentSite])

    const secured = asSecured(content)
    return (
        <Dialog
            open={open}
            onClose={() => {
                setOpen(false)
                resolve()
            }}
        >
            <DialogTitle>Select a site for preview</DialogTitle>
            <DialogContent>
                {content && (
                    <SiteSelectorForPreview
                        contentID={content.id}
                        contentType={secured.EntityScope}
                        parentSitesIDs={content.sites}
                        onClick={() => {
                            setOpen(false)
                            resolve()
                        }}
                    />
                )}
            </DialogContent>
        </Dialog>
    )
}

export const PreviewMenuItem = ({ content, onClick = () => {} }: { content: contentLike; onClick: () => void }) => {
    const [handlePreview] = useAtom(handlePreviewAtom)
    const handle = () => {
        handlePreview(content)
        onClick()
    }
    return (
        <CustomMenuItem text={'Preview'} onClick={handle}>
            <VisibilityIcon />
        </CustomMenuItem>
    )
}
