import {
    Button,
    ButtonGroup,
    ButtonProps,
    ClickAwayListener,
    Grow,
    MenuItem,
    MenuItemProps,
    MenuList,
    Paper,
    Popper,
    SxProps,
    Typography
} from '@mui/material'
import { useRef, useState } from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

interface SplitButtonProps {
    button: {
        label: string
        buttonProps: ButtonProps
    }
    options: {
        label: string
        menuItemProps: MenuItemProps
    }[]
    sx: SxProps
}

const defaultButtonProps: ButtonProps = {
    size: 'small',
    variant: 'text'
}

export default function SplitButton({ button, options, sx }: SplitButtonProps) {
    const anchorRef = useRef<HTMLDivElement | null>(null)
    const [open, setOpen] = useState(false)

    return (
        <>
            <ButtonGroup ref={anchorRef} sx={sx}>
                <Button color='inherit' {...defaultButtonProps} {...button.buttonProps}>
                    {button.label}
                </Button>
                <Button
                    color='inherit'
                    variant='text'
                    size='small'
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label='select merge strategy'
                    aria-haspopup='menu'
                    onClick={() => {
                        setOpen((p) => !p)
                    }}
                >
                    <ArrowDropDownIcon />
                </Button>
                {/*@ts-ignore*/}
                <Popper
                    sx={{
                        zIndex: 1
                    }}
                    anchorEl={anchorRef?.current}
                    open={open}
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={() => setOpen(false)}>
                                    <MenuList autoFocusItem sx={{ pt: 0, pb: 0 }}>
                                        {options.map((option, index) => {
                                            return (
                                                <MenuItem
                                                    key={option.label}
                                                    {...option.menuItemProps}
                                                    style={index !== 0 ? { borderTop: '1px lightgray solid' } : {}}
                                                >
                                                    <Typography>{option.label}</Typography>
                                                </MenuItem>
                                            )
                                        })}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </ButtonGroup>
        </>
    )
}
