import React from 'react'
import { Link } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import SettingsIcon from '@mui/icons-material/Settings'
import { MenuItem } from '@mui/material'
import { useCurrentSiteID } from '../../pkgs/auth/atoms'

export const AppbarSettingsIcon = ({ mobile }) => {
    const currentSiteID = useCurrentSiteID()
    if (mobile) {
        return (
            <MenuItem component={Link} to={`/settings?siteId=${currentSiteID}`}>
                <IconButton aria-label='Settings' color='inherit' size='large'>
                    <SettingsIcon />
                </IconButton>
                <p>Settings</p>
            </MenuItem>
        )
    } else {
        return (
            <Link style={{ color: 'inherit' }} to={`/settings?siteId=${currentSiteID}`}>
                <IconButton aria-label='Settings' color='inherit' size='large'>
                    <SettingsIcon />
                </IconButton>
            </Link>
        )
    }
}

export default AppbarSettingsIcon
