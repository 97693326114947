export function mapEditorInstanceToMatch(editorSourceArray, editorAssignTargetArray) {
    try {
        for (let i = 0; i < editorAssignTargetArray.length; i++) {
            const sourceIndex = editorSourceArray.findIndex((src) => src.id === editorAssignTargetArray[i].id)
            if (sourceIndex > -1 && editorSourceArray[sourceIndex]?.editor) {
                editorAssignTargetArray[i].editor = editorSourceArray[sourceIndex].editor
            }
        }
        return editorAssignTargetArray
    } catch (e) {
        console.log(e)
        return []
    }
}

export function initializeEditorInstance(instance, setInstance, hasContentPermission) {
    setInstance(instance)
    if (!hasContentPermission) {
        const elements = document.querySelectorAll('.ck-toolbar')
        if (elements && elements.length) {
            for (const el of elements) {
                el.style.backgroundColor = '#e5e5e5'
            }
        }
    }
}

export function patchEditorContentIfNeeded(content, editor) {
    try {
        if (
            editor?.plugins?._availablePlugins.has('LinkImage') &&
            editor?.plugins?._availablePlugins.has('GeneralHtmlSupport')
        ) {
            /** LinkImage with GeneralHtmlSupport is currently losing link attributes on upcast
             *  https://github.com/ckeditor/ckeditor5/issues/12831
             *  CK5 V35xx, we are currently on 34xx
             * */
            const html = new DOMParser().parseFromString(content, 'text/html')
            // Lift the link from inside the <figure> to wrap around it, this currently
            // bypasses upcast bug
            html.querySelectorAll('figure > a').forEach((a) => {
                let f = a.parentNode
                Array.from(a.children).forEach((c) => {
                    f.appendChild(c)
                })
                f.parentNode.insertBefore(a, f)
                a.appendChild(f)
            })
            const body = html.getElementsByTagName('body')?.[0]
            if (body && body?.innerHTML) {
                return body.innerHTML
            }
        }

        return content
    } catch (e) {
        console.info({ error: e, source: 'ckeditor' })
        return content
    }
}

export function insertMediaIntoEditor(img, ckeditor) {
    const { id, alt } = img
    ckeditor.model.change((writer) => {
        const imageElement = writer.createElement('imageInline', {
            src: `/images/${id}`,
            alt
        })
        ckeditor.model.insertContent(imageElement, ckeditor.model.document.selection)
    })
}
