import { patternMatch } from './permissions/patternMatch'

export const EntityScopeEnum = {
    Page: 'cm.content.page',
    News: 'cm.content.news',
    Alert: 'cm.content.alert',
    Event: 'cm.content.event',
    Resource: 'cm.resource', // js, css, template
    Structure: 'cm.structure', // ? different user-defined types ...
    Tag: 'cm.tag', // media, content, site
    BusRoute: 'cm.transportation.bus_route',
    BusArea: 'cm.transportation.bus_area',
    BusStatus: 'cm.transportation.bus_status',
    Image: 'cm.image',
    Document: 'cm.document', // document, folder
    Navigation: 'cm.navigation', // page_route, external_link
    Site: 'cm.site.site',
    Department: 'cm.site.department',
    Notification: 'cm.notification', // issue, subscription, subscriber, ...
    NotificationTopic: 'cm.notification.topic', // issue, subscription, subscriber, ...
    NotificationIssue: 'cm.notification.issue', // issue, subscription, subscriber, ...
    NotificationSubscriber: 'cm.notification.subscriber', // issue, subscription, subscriber, ...
    NotificationSchedule: 'cm.notification.schedule', // issue, subscription, subscriber, ...
    Settings: 'cm.settings', // ...
    InstagramSettings: 'cm.settings.instagram',
    List: 'cm.list',
    Fragment: 'cm.fragment'
} as const

export type entityScope = (typeof EntityScopeEnum)[keyof typeof EntityScopeEnum]

export function typeToEntityScope(type: string): entityScope {
    if (type.startsWith('cm.')) return type as entityScope

    switch (type) {
        // Site types
        case 'department':
        case 'elementary':
        case 'other':
        case 'high school':
        case 'middle school':
        case 'junior high - high school':
        case 'k-12 school':
        case 'elementary - junior high':
        case 'central office':
        case 'district':
            return 'cm.site' as entityScope
        // Media types
        case 'image':
            return 'cm.image'
        case 'document':
        case 'folder':
            return ('cm.document.' + type) as entityScope
        // Content types
        case 'alert':
            return 'cm.content.alert'
        case 'news':
            return 'cm.content.news'
        case 'event':
            return 'cm.content.event'
        case 'fragment':
            return 'cm.fragment'
        case 'page':
        case 'distributed_page':
        case 'external_link':
            return 'cm.content.page'
        case 'template':
        case 'css':
        case 'js':
            return 'cm.resource'
        // Tags
        case 'media':
        case 'content':
        case 'site':
            return 'cm.tag'
        case 'plain_smtp':
        case 'sendgrid':
            return 'cm.notification.relay' as entityScope
        default:
            return `cant-map-entity-scope-${type}` as entityScope
    }
}

const tenantWideEntityScopesPatterns = [
    'cm.site*',
    'cm.tag*',
    'cm.transportation*',
    'cm.notification*',
    'cm.notification*',
    'cm.structure*'
]

export const isTenantWideEntityScope = (entityScope: string): boolean => {
    return tenantWideEntityScopesPatterns.some((pattern) => patternMatch(pattern, entityScope))
}
