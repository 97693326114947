import React, { useEffect, useState } from 'react'
import { DepartmentFilters } from './DepartmentFilters'
import { Box } from '@mui/material'
import { EditWizard } from './DepartmentWizard'
import { AddButton } from '../../common/components'
import { SiteType } from '../../common/constants'
import { DepartmentQuery, departmentService, SitesZ, SiteZ } from './department.service'
import { DepartmentRowContainer } from './DepartmentRow'
import { defaultPageQuery } from '../../common/react-query'

export const DepartmentList = () => {
    const [department, setDepartment] = useState<SiteZ | null>(null)
    const [query, setQuery] = useState<DepartmentQuery>({ ...defaultPageQuery })
    const [siteGridState, setSiteGridState] = useState<SitesZ>({
        TotalRecords: 0,
        TotalPages: 0,
        Offset: 0,
        PageSize: 10,
        Page: 1,
        Rows: []
    })

    useEffect(() => {
        getRecords()
    }, [query])

    const getRecords = async () => {
        try {
            const response = await departmentService.query(query)
            console.log(response)
            setSiteGridState(response)
        } catch (e) {
            console.error(e)
        }
    }

    const close = () => {
        setDepartment(null)
    }
    const getNext = (site?: SiteZ): SiteZ => {
        if (site) {
            return site
        }

        return {
            type: SiteType.Department,
            name: '',
            description: '',
            hosts: [],
            id: '',
            active: true,
            tags: [],
            tenantId: ''
        }
    }
    const startDialogue = async (site?: SiteZ) => {
        try {
            const next = getNext(site)
            console.log(next)
            if (next?.type !== SiteType.Department) {
                return Promise.reject(`invalid site provided with id:[${next?.id || ''}] and type:[${next?.type}]`)
            }
            setDepartment(next)
        } catch (e) {
            return Promise.reject(e)
        }
    }
    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                <AddButton
                    title={`add department`}
                    func={() => startDialogue()}
                    style={{ marginTop: '0.75rem', marginBottom: '0.75rem', backgroundColor: '#4E01D5' }}
                />
            </div>
            <DepartmentFilters state={query} setState={setQuery} />
            <Box style={{ width: '100%', alignItems: 'center', margin: '0.25rem 2rem' }}>
                <div
                    className={'row'}
                    style={{ width: '100%', margin: '0.25rem', alignItems: 'center', padding: '0px 16px' }}
                >
                    <div className='col-xs-3'>
                        <h4>Name</h4>
                    </div>
                    <div className='col-xs-8'>
                        <h4>Description</h4>
                    </div>
                    <div className='col-xs-1'>
                        <h4>Actions</h4>
                    </div>
                </div>
                <DepartmentRowContainer rows={siteGridState.Rows} handleOpen={startDialogue} />
                <EditWizard site={department} onNext={getRecords} onClose={close} />
            </Box>
        </>
    )
}
