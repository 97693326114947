import { toast } from 'react-toastify'
import { ToastOptions } from 'react-toastify/dist/types'

type toastType = 'info' | 'success' | 'error' | 'warning'

const defaultToastConfig = {
    autoClose: 5000,
    pauseOnHover: false,
    closeOnClick: true,
    hideProgressBar: true,
    theme: 'colored'
}

export default function notify(content: any, type: toastType = 'error', options?: ToastOptions): void {
    let f: any
    switch (type) {
        case 'info':
            f = toast.info
            break
        case 'success':
            f = toast.success
            break
        case 'warning':
            f = toast.warning
            break
        case 'error':
            f = toast.error
            break
    }
    f?.(content, {
        ...defaultToastConfig,
        ...options
    } as ToastOptions)
}
