import { FormControlLabel, Switch } from '@mui/material'
import { useState } from 'react'
import { httpGet } from '@/common/client'
import { BASE } from '@/common/constants'
import { z } from 'zod'
import { notify } from '@/helpers'
import { guessErrorMessage } from '@/helpers/guessErrorMessage'

type PinNewsProps = {
    value: number | null
    onChange: (newValue: number | null) => void
    disabled?: boolean
}

export const PinNews = ({ value, onChange, disabled = false }: PinNewsProps) => {
    const [prevValue, setPrevValue] = useState<number | null>(value)
    const [loading, setLoading] = useState(false)

    const handleChange = (isPinned: boolean) => {
        if (!isPinned) {
            onChange(null)
            return
        }

        if (Number.isFinite(prevValue)) {
            onChange(prevValue)
            return
        }

        setLoading(true)
        httpGet(`${BASE}/api/v2/content/priority/max`, undefined, z.number())
            .then((data) => {
                const newPriority = data + 1
                setPrevValue(newPriority)
                onChange(newPriority)
            })
            .catch((error) => {
                console.error(error)
                notify(`Failed to get max priority: ${guessErrorMessage(error)}`, 'error')
            })
            .finally(() => setLoading(false))
    }

    return (
        <FormControlLabel
            control={<Switch checked={value !== null} onChange={(_, checked) => handleChange(checked)} />}
            label={'Pin News'}
            labelPlacement={'end'}
            disabled={disabled || loading}
            title={loading ? 'Loading...' : ''}
        />
    )
}
