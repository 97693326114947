import { fromOptional } from '../../../common/components/selectors/SiteSelectorForAccount'
import { Secured } from './evaluate'
import { entityScope, typeToEntityScope } from '../entityScope'

export function asSecured(obj: any): Secured {
    const result: Secured = {
        Sites: null,
        DepartmentID: null,
        EntityScope: 'cant-map-entity-scope' as any
    }
    if (!obj) return result

    const props = new Set<string>()
    let objType = ''
    for (const prop in obj) {
        const lowerProp = prop.toLowerCase()
        props.add(lowerProp)

        switch (lowerProp) {
            case 'sites':
                result.Sites = obj[prop] // Navigation handled bellow
                break
            case 'departmentid':
            case 'department_id':
                result.DepartmentID = fromOptional(obj[prop])
                break
            case 'type':
                objType = obj[prop]
                break
        }
    }

    if (/* for tests */ haveAll(props, 'EntityScope')) {
        result.EntityScope = getProperty(obj, 'EntityScope')
        //
    } else if (/* Site */ haveAll(props, 'TenantID', 'Tags', 'Hosts', 'Type')) {
        result.EntityScope = getProperty(obj, 'Type') === 'department' ? 'cm.site.department' : 'cm.site.site'

        result.Sites = null
        result.DepartmentID = null
        //
    } else if (/* Navigation */ haveAll(props, 'visible', 'TreeIndex')) {
        result.EntityScope = ('cm.navigation.' + objType) as any
        result.Sites = [getProperty(obj, 'SiteID')]
        //
    } else if (/* Settings */ haveAll(props, 'data', 'type', 'name', 'public') && !props.has('route')) {
        result.EntityScope = ('cm.settings.' + objType) as any
        const siteID = getProperty(obj, 'SiteID')
        result.Sites = siteID ? [siteID] : null
        //
    } else if (/* Topic */ haveAll(props, 'RelayID', 'TopicType', 'BusRouteID')) {
        result.EntityScope = 'cm.notification.topic'
        //
    } else if (/* Issue */ haveAll(props, 'CreationType', 'TopicID', 'Subject')) {
        result.EntityScope = 'cm.notification.issue'
        //
    } else if (/* Subscriber */ haveAll(props, 'LastName', 'ManageCode', 'Email')) {
        result.EntityScope = 'cm.notification.subscriber'
        //
    } else if (/* Schedule */ haveAll(props, 'Cron', 'ManageCode', 'Email')) {
        result.EntityScope = 'cm.notification.schedule'
        //
    } else if (/* BusStatus */ haveAll(props, 'routes', 'HasEmailNotification')) {
        result.EntityScope = 'cm.transportation.bus_status'
        //
    } else if (/* BusRoute */ haveAll(props, 'areas', 'sites')) {
        result.EntityScope = 'cm.transportation.bus_route'
        //
    } else if (haveAll(props, 'type')) {
        result.EntityScope = typeToEntityScope(objType) as any
        //
    } else if (/* BusArea */ haveAll(props, 'name')) {
        // BusArea (fragile)
        result.EntityScope = 'cm.transportation.bus_area'
        //
    }

    if (result.EntityScope === ('cant-map-entity-scope' as any) || !result.EntityScope.startsWith('cm.')) {
        console.error(`Can't map entity scope for ${objType}`, obj)
    }

    return result
}

function haveAll(props: Set<string>, ...names: string[]) {
    for (const name of names) {
        if (!props.has(name.toLowerCase())) return false
    }
    return true
}

function getProperty(obj: any, name: string) {
    const lowerName = name.toLowerCase()
    for (const prop in obj) {
        if (prop.toLowerCase() === lowerName) return obj[prop]
    }
    return null
}
