import { is, sanitizePathString, validateUUID } from './index'
import { NIL } from 'uuid'

/**
 * @param  {string}  id  - a null or valid uuid
 * @return {Object}
 * */
function toNullUUID(id) {
    if (is.nullUUID(id)) {
        return id
    }
    const nullUUID = { UUID: NIL, Valid: false }
    if (id && validateUUID(id)) {
        nullUUID.UUID = id
        nullUUID.Valid = true
    }
    return nullUUID
}

/**
 * @param  {Object || string}  id  - a nullUUID or UUID
 * @return {string}
 * */
function fromNullUUID(id) {
    if (id && typeof id === 'object' && id.hasOwnProperty('UUID')) {
        return id.UUID
    } else if (typeof id === 'string') {
        return id
    } else {
        return NIL
    }
}

/**
 * @param  {string}  route - a route string to be formatted for resource content type
 * @return {string}
 * */
function stringToResourceRoute(route) {
    let formattedRoute = route.toLowerCase().replace(/\s/g, '')
    formattedRoute[0] !== '/' && formattedRoute.length > 1
        ? (formattedRoute = '/' + formattedRoute)
        : (formattedRoute = formattedRoute)
    return formattedRoute
}

/**
 * @param  {Object<Array>}  content - The Array of objects which "Parent" originates
 * @param  {string}         parent  - the UUID of the selected parent
 * @param  {string}         id      - The UUID of the resource being edited
 * @return {string}
 * */
function parentIdToPath(content, parent, id) {
    let path = sanitizePathString(id)
    const elementsIndex = content.findIndex((element) => element.id === parent)
    if (elementsIndex > -1 && content[elementsIndex].path) {
        path = `${content[elementsIndex].path}.` + path
    }
    return path
}

/**
 * @param  {Object<Array>}  objects - An array of objects
 * @return {Array<string>}
 * */
function objectsToIds(objects) {
    if (is.stringArray(objects)) {
        return objects
    }
    const stringArray: string[] = []
    for (const object of objects) {
        if (object && object.id && !stringArray.includes(object.id)) {
            stringArray.push(object.id)
        }
    }
    return stringArray
}

const asStringArray = (potential, optionalKey?) => {
    if (is.stringArray(potential)) {
        return potential
    }
    if (is.objectArray(potential)) {
        return potential.map((x) => x?.[optionalKey] || x?.id)
    }
    return []
}
/**
 * @param  {Array<Object>}  entityArray - The incoming entities that need to reference options
 * @param  {Array<Object>}  options     - The options that entityArray needs to reference
 * @param  {String}         entityId    - The key to be used in comparison if entityArray/Options are objects (e.g x["id"] === y["id"])
 * @return {Array<Object>}
 * */
const entitiesByReference = (entityArray, options, entityId) => {
    const ref: any[] = []
    if (!entityArray || !options) return ref

    if (!entityId && is.objectArray(entityArray)) {
        entityId = 'id'
    } else if (entityId && is.stringArray(entityArray)) {
        entityId = undefined
    }
    for (const entity of entityArray) {
        const elementIndex = options.findIndex((x) => x.id === (entityId ? entity[entityId] : entity))
        if (elementIndex > -1) ref.push(options[elementIndex])
    }
    return ref
}
const toUUIDStringOr = (uuid, ifNotUUID) => {
    if (validateUUID(uuid?.UUID) && uuid?.Valid) {
        return uuid.UUID
    }
    if (validateUUID(uuid)) {
        return uuid
    }
    return ifNotUUID
}
const r1 = new RegExp(/[^A-Za-z0-9\\.]/, 'g')

function toPathString(str) {
    return str.replace(r1, '')
}

function Path(base, suffix) {
    if (!base || base.length < 2) {
        return suffix ? toPathString(suffix) : ''
    }

    const start = base.startsWith('.') ? 1 : 0
    const end = base.endsWith('.') ? base.length - 1 : base.length

    base = base.substring(start, end)
    if (typeof suffix !== 'string' || !suffix.length) {
        return base
    }

    const tree = base.split('.')
    toPathString(suffix)
        .split('.')
        .forEach((part) => {
            if (!tree.includes(part)) {
                tree.push(part)
            }
        })
    return tree.join('.')
}

export const Format = {
    objectsToIds,
    parentIdToPath,
    stringToResourceRoute,
    toNullUUID,
    fromNullUUID,
    entitiesByReference,
    toUUIDStringOr,
    asStringArray,
    toPathString,
    Path
}
