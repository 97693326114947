interface Path {
    url: string
    pageId: string
    subPaths: string[] | null
    isCurrentPage: (path: string) => boolean
    isRecord: boolean
}

function isIdString(str: string) {
    return str.replace(/[^-]/g, '').length == 4
}

export default function usePath(): Path {
    // example href: http://localhost:3000/system/templates?siteId=5d2b935d-5435-483c-a8fe-d5f11ec7aa52
    const url = window?.location?.href || '/'

    const split = url.split('/')

    const hasSubPaths = split.length > 4
    const subPaths = hasSubPaths ? split.slice(3, split.length - 1) : null

    let pageId = split[split.length - 1]

    if (pageId.includes('?siteId')) {
        pageId = pageId.split('?')[0]
    }

    function isCurrentPage(path: string) {
        if (path.includes('/')) {
            const subPaths = path.split('/')
            return subPaths[subPaths.length - 1] == pageId
        }
        return path == pageId
    }

    return {
        url,
        pageId,
        subPaths,
        isCurrentPage,
        isRecord: isIdString(pageId)
    }
}
