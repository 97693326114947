import React from 'react'
import { Alert, FormControl, FormLabel, useTheme } from '@mui/material'
import { useAppContext } from '../auth/atoms'
import Button from '@mui/material/Button'
import { parseFormLink, SelectContactFormDialog } from './SelectContactFormDialog'
import { FormFromFragmentPreview } from './FormFromFragmentPreview'
import { FormFromStructurePreview } from './FormFromStructurePreview'
import { Box } from '@mui/system'
import CreateIcon from '@mui/icons-material/Create'
import { DeleteForever } from '@mui/icons-material'
import VisibilityIcon from '@mui/icons-material/Visibility'

interface FormLinkProps {
    value: string
    onChange: (value: string) => void
    disabled?: boolean
    label?: string
    required?: boolean
    error?: string
    types?: Array<'fragment' | 'email'>
    allowedStructures?: string[] | null
}

// For Contact Form DCT Component
export const FormLink = ({
    value,
    onChange,
    disabled = false,
    label = 'Link to a Contact Form',
    required = false,
    error,
    types = ['fragment', 'email'],
    allowedStructures = []
}: FormLinkProps) => {
    const theme = useTheme()
    const appContext = useAppContext()
    const [open, setOpen] = React.useState(false)
    const devSuffix = window?.location?.host.includes('localhost') ? '.localhost' : ''

    const { structure, fragment, email } = parseFormLink(value)

    const link = `https://${appContext.currentSite()?.PrimaryDomain}${devSuffix}${value}`
    return (
        <>
            <FormControl fullWidth sx={{ my: 1 }}>
                <FormLabel required={required} error={!!error}>
                    {label}
                </FormLabel>
                {value && (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ flexGrow: 1 }}>
                            <a href={link} target={'_blank'} title={link}>
                                {link.substring(0, 80)}
                            </a>
                        </Box>
                        <Box
                            sx={{
                                ml: 1,
                                flexDirection: 'row',
                                display: 'flex',
                                '& .MuiButtonBase-root': {
                                    borderRadius: '4px'
                                }
                            }}
                        >
                            <Button
                                variant={'text'}
                                size='small'
                                startIcon={<VisibilityIcon />}
                                onClick={() => window.open(link, '_blank')}
                            >
                                Preview
                            </Button>
                            <Button
                                variant={'text'}
                                size='small'
                                startIcon={<CreateIcon />}
                                onClick={() => setOpen(true)}
                            >
                                Edit
                            </Button>
                            <Button
                                onClick={() => onChange('')}
                                color={'error'}
                                size='small'
                                startIcon={<DeleteForever />}
                            >
                                Delete
                            </Button>
                        </Box>
                    </Box>
                )}
                {error && <Alert severity='error'>{error}</Alert>}
                {fragment && <FormFromFragmentPreview id={fragment} onClick={() => setOpen(true)} />}
                {structure && email && (
                    <FormFromStructurePreview id={structure} email={email} onClick={() => setOpen(true)} />
                )}
                {!value && (
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => setOpen(true)}
                        disabled={disabled}
                        sx={{ mt: 1 }}
                    >
                        Set link to a contact form
                    </Button>
                )}
            </FormControl>
            {open && (
                <SelectContactFormDialog
                    open={open}
                    value={value || ''}
                    onChange={onChange}
                    onClose={() => setOpen(false)}
                    types={types}
                    allowedStructures={allowedStructures}
                />
            )}
        </>
    )
}
