import type {
    DOMConversionMap,
    DOMConversionOutput,
    DOMExportOutput,
    EditorConfig,
    ElementFormatType,
    LexicalEditor,
    LexicalNode,
    NodeKey,
    Spread
} from 'lexical'

import { BlockWithAlignableContents } from '@lexical/react/LexicalBlockWithAlignableContents'
import { DecoratorBlockNode, SerializedDecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode'
import { getIdFromVimeoUrl, getIdFromYoutubeUrl, urlContainsVideoId, vimeoRegex, youtubeRegex } from './AutoEmbedPlugin'
import { useLexicalNodeSelection } from '@lexical/react/useLexicalNodeSelection'
import { Box } from '@mui/material'
import { colours } from '../../../common/colours'
import VideoComponent from './VideoComponent'

export enum VideoType {
    YOUTUBE = 'youtube',
    VIMEO = 'vimeo'
}

// attribute name with value of video id to identify/convert a lexical HTML iframe element
export const videoNodeIframeAttributeName = 'data-lexical-video-id'

export function getVideoType(url: string): VideoType | null {
    if (youtubeRegex.test(url)) {
        return VideoType.YOUTUBE
    } else if (vimeoRegex.test(url)) {
        return VideoType.VIMEO
    }

    return null
}

export function getEmbedVideoSrc(videoId: string, videoType: VideoType) {
    if (videoType == VideoType.YOUTUBE) {
        return `https://www.youtube-nocookie.com/embed/${videoId}`
    } else if (videoType == VideoType.VIMEO) {
        return `https://player.vimeo.com/video/${videoId}`
    }

    return null
}

export class Video {
    __id: string
    type: VideoType
    src: string

    constructor(id: string, type: VideoType) {
        this.__id = id
        this.type = type
        this.src = getEmbedVideoSrc(id, type) || ''
    }

    getType() {
        return this.type
    }

    getId() {
        return this.__id
    }

    getSrc() {
        return this.src
    }

    renderIframe() {
        return (
            <iframe
                width='100%'
                height='480'
                src={this.getSrc()}
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen={true}
                title={`${this.type.charAt(0).toUpperCase() + this.type.slice(1)} video`}
                className={videoNodeIframeAttributeName}
            />
        )
    }

    getIframe() {
        const element = document.createElement('iframe')
        element.setAttribute(videoNodeIframeAttributeName, this.__id)
        element.setAttribute('width', '640')
        element.setAttribute('height', '480')
        element.setAttribute('src', this.src)
        element.setAttribute('frameborder', '0')
        element.setAttribute(
            'allow',
            'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        )
        element.setAttribute('allowfullscreen', 'true')

        element.setAttribute('title', `${this.type.charAt(0).toUpperCase() + this.type.slice(1)} video`)

        return element
    }
}

export type SerializedVideoNode = Spread<
    {
        video: Video
    },
    SerializedDecoratorBlockNode
>

function convertVideoElement(domNode: HTMLElement): null | DOMConversionOutput {
    // oembed uses url. iframe uses src.
    const possibleURL = domNode.getAttribute('src') || domNode.getAttribute('url')
    const videoID =
        domNode.getAttribute(videoNodeIframeAttributeName) ||
        domNode.getAttribute('data-lexical-youtube') ||
        getIdFromYoutubeUrl(possibleURL || '') ||
        getIdFromVimeoUrl(possibleURL || '')

    const videoType = possibleURL ? getVideoType(possibleURL) : null

    if (!videoID || !videoType) {
        return null
    }

    return {
        node: $createVideoNode(new Video(videoID, videoType))
    }
}

const videoNodeType = 'video'

export class VideoNode extends DecoratorBlockNode {
    video: Video

    static getType(): string {
        return videoNodeType
    }

    static clone(node: VideoNode): VideoNode {
        return new VideoNode(node.video, node.__format, node.__key)
    }

    static importJSON(serializedNode: SerializedVideoNode): VideoNode {
        const { __id, type } = serializedNode.video
        const node = $createVideoNode(new Video(__id, type as VideoType))
        node.setFormat(serializedNode.format)
        return node
    }

    exportJSON(): SerializedVideoNode {
        return {
            ...super.exportJSON(),
            type: videoNodeType,
            version: 1,
            video: this.video
        }
    }

    constructor(video: Video, format?: ElementFormatType, key?: NodeKey) {
        super(format, key)
        this.video = video
    }

    exportDOM(): DOMExportOutput {
        return { element: this.video.getIframe() }
    }

    static importDOM(): DOMConversionMap | null {
        return {
            iframe: (domNode: HTMLElement) => {
                // to support older HTML. 'data-lexical-youtube' was used before VideoNode/vimeo support.
                if (
                    !domNode.hasAttribute('data-lexical-youtube') &&
                    !domNode.hasAttribute(videoNodeIframeAttributeName) &&
                    !urlContainsVideoId(domNode.getAttribute('src') || '')
                ) {
                    return null
                }

                return {
                    conversion: convertVideoElement,
                    priority: 1
                }
            },
            oembed: (domNode: HTMLElement) => {
                // CKEditor uses oembed. Url contains id needed to create a VideoNode
                const srcValue = domNode.getAttribute('url')
                if (!srcValue) return null

                const id = getIdFromYoutubeUrl(srcValue) || getIdFromVimeoUrl(srcValue)

                if (!id) return null

                return {
                    conversion: convertVideoElement,
                    priority: 1
                }
            }
        }
    }

    updateDOM(): false {
        return false
    }

    getId(): string {
        return this.video.__id
    }

    getTextContent(_includeInert?: boolean | undefined, _includeDirectionless?: false | undefined): string {
        return this.video.getSrc()
    }

    decorate(_editor: LexicalEditor, config: EditorConfig): JSX.Element {
        const embedBlockTheme = config.theme.embedBlock || {}
        const className = {
            base: embedBlockTheme.base || '',
            focus: embedBlockTheme.focus || ''
        }
        return (
            <VideoComponent className={className} format={this.__format} nodeKey={this.getKey()} video={this.video} />
        )
    }
}

export function $createVideoNode(video: Video): VideoNode {
    return new VideoNode(video)
}

export function $isVideoNode(node: VideoNode | LexicalNode | null | undefined): node is VideoNode {
    return node instanceof VideoNode
}
