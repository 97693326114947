import React from 'react'
import { Box, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'

export const CustomMenu = ({ anchorElement, onClose, children }) => {
    // CustomMenu: TODO => menuId
    const isMenuOpen = Boolean(anchorElement)
    return (
        <Menu
            anchorEl={anchorElement}
            open={isMenuOpen}
            onClose={onClose}
            keepMounted
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 10, horizontal: 80 }}
        >
            <Box
                sx={{
                    minWidth: '10rem',
                    '& .MuiListItemIcon-root': {
                        minWidth: '35px'
                    }
                }}
            >
                {/*<customMenuContext.Provider value={[model, setModel]}>*/}
                {children}
                {/*</customMenuContext.Provider>*/}
            </Box>
        </Menu>
    )
}

interface CustomMenuItemProps {
    text: string
    onClick?: (v?: any) => any
    disabled?: boolean
    children?: any
}

export function CustomMenuItem({ text, onClick, disabled = false, children }: CustomMenuItemProps) {
    return (
        <MenuItem disabled={Boolean(disabled)} onClick={() => onClick?.()}>
            {Boolean(children) && <ListItemIcon>{children}</ListItemIcon>}
            <ListItemText primary={text} />
        </MenuItem>
    )
}

/* customMenuContext:
    Is it possible to have a common "model" that we pass to the CustomMenu parent component, and have it trickle down to the individual menu item components
    -- (e.g) --
    <CustomMenu model={imageObjectFromDB}>
        <CustomMenuItemOne />
        <CustomMenuItemTwo />
    </CustomMenu>
    -- Instead of --
    <CustomMenu >
        <CustomMenuItemOne model={imageObjectFromDB}/>
        <CustomMenuItemTwo model={imageObjectFromDB}/>
    </CustomMenu>
*/
export const customMenuContext = React.createContext([{}, () => {}])
