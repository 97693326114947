import { z } from 'zod'
import { paged, trackable, uuidSchema } from '../../common/react-query'

export enum ROLE_TYPE {
    TENANT = 'tenant',
    SITE = 'site'
}

export const role = z.object({
    id: uuidSchema,
    name: z.string(),
    mod_core: z.number(),
    mod_pages: z.number(),
    mod_news: z.number(),
    mod_events: z.number(),
    mod_media: z.number(),
    mod_transportation: z.number(),
    mod_alerts: z.number(),
    active: z.boolean(),
    Scopes: z.array(z.string()).default([]),
    type: z.nativeEnum(ROLE_TYPE)
})

export const group = trackable.extend({
    Name: z.string(),
    Description: z.string(),
    Type: z.union([z.literal('external'), z.literal('manual')]),
    Active: z.boolean(),
    RoleID: uuidSchema,
    Role: role,
    SiteID: uuidSchema.nullable(),
    ExternalIdList: z.array(z.string()),
    DependsOn: z.array(z.string()),
    Audience: z.array(z.string()),
    AvailableGroups: z.array(z.string())
})
export const groups = paged.extend({
    Rows: z.array(group)
})

export const account = trackable.extend({
    Email: z.string(), // some accounts have invalid emails .email(),
    Firstname: z.string(),
    Lastname: z.string(),
    Active: z.boolean(),
    IsAdmin: z.boolean(),
    ExternalGroups: z.array(z.string()),
    ManualGroups: z.array(z.string()),
    NativeGroups: z.array(z.string()),
    Groups: z.union([z.array(group), z.null()]).transform((val) => (val === null ? [] : val)),
    GroupsWarnings: z.array(z.string()).nullish(),
    Settings: z.any().nullish()
})

export const accounts = paged.extend({
    Rows: z.array(account)
})

export type Account = z.infer<typeof account>
export type Accounts = z.infer<typeof accounts>
export type Role = z.infer<typeof role>
export type Group = z.infer<typeof group>
export type SecurityGroup = z.infer<typeof group>
