import { useStructureDetailsQuery } from '../structure/queries'
import { Alert, Box, Card, CardActionArea, CardContent, Typography } from '@mui/material'
import { guessErrorMessage } from '../../helpers/guessErrorMessage'
import React from 'react'
import { convertEmail } from './SelectContactFormDialog'
import CenteredSpinner from '../../common/components/CenteredSpinner'
import CreateIcon from '@mui/icons-material/Create'

interface FormFromStructurePreviewProps {
    id: string
    email: string
    onClick: () => void
}

export const FormFromStructurePreview = ({ id, email, onClick }: FormFromStructurePreviewProps) => {
    const result = useStructureDetailsQuery(id)
    const [displayEmail, setDisplayEmail] = React.useState<string | undefined>(email)

    React.useEffect(() => {
        ;(async () => {
            const decrypted = await convertEmail('decrypt', email)
            setDisplayEmail(decrypted)
        })()
    }, [])

    if (result.isError) {
        return <Alert severity='error'>Error loading form fragment: {guessErrorMessage(result.error)}</Alert>
    }

    if (result.isLoading || !result.data) {
        return <CenteredSpinner />
    }

    const title = result.data.Name
    const structureID = result.data.ID

    return (
        <Card sx={{ mt: 1 }}>
            <CardActionArea onClick={onClick} title={'Click to edit'}>
                <CardContent>
                    <Box sx={{ display: 'flex', gap: '16px', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant='h5'>{displayEmail}</Typography>
                    </Box>
                    <Typography variant='body1'>Structure: {title}</Typography>
                    <Typography variant='body2'>Structure ID: {structureID}</Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}
