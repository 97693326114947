import { useQuery } from '@tanstack/react-query'
import { httpDelete, httpGet, httpPost, httpPut } from '../../common/client'
import { Structure, structure, structures } from './types'
import { z } from 'zod'
import { PagingQuery, SortingQuery, baseQueryConfig } from '../../common/react-query'
import { StructureAPI } from '../../common/constants'

export type StructureQuery = {
    Search?: string
    Active?: boolean
} & PagingQuery &
    SortingQuery

export function createStructureQuery(newStructure: Partial<Structure>) {
    return httpPost(StructureAPI, newStructure, /* structure // we return ID on create */ z.string())
}

export function updateStructureQuery({ id, structure }: { id: string; structure: Partial<Structure> }) {
    return httpPut(`${StructureAPI}/${id}`, structure, z.string())
}

export function deleteStructureQuery(structureId: string) {
    return httpDelete(`${StructureAPI}/${structureId}`)
}

export const useStructuresQuery = (q: StructureQuery) => {
    const query = useQuery({
        ...baseQueryConfig,
        queryKey: ['structure-query', q],
        queryFn: async () => httpGet(StructureAPI, q, structures)
    })

    return query
}
export const useStructureDetailsQuery = (id: string) => {
    const query = useQuery({
        ...baseQueryConfig,
        queryKey: ['structure-details-query', id],
        queryFn: async () => httpGet(`${StructureAPI}/${id}`, null, structure)
    })

    return query
}

function getStructureById(id) {
    return httpGet(`${StructureAPI}/${id}`, null, structure)
}

export function useStructureByIdQuery(id: string) {
    console.log('id', id)
    const result = useQuery({
        ...baseQueryConfig,
        enabled: !!id,
        queryKey: ['structure-query', id],
        queryFn: () => getStructureById(id)
    })

    return result
}
