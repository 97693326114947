import { TagsSelector } from '../../grid/selectors/TagsSelector'
import React, { useEffect } from 'react'
import { z } from 'zod'
import { SiteSelectorForContent } from '../../../common/components/selectors/SiteSelectorForContent'
import { ImageLoader, MediaObject, mediaObject } from '../upload/ImageLoader'
import {
    Box,
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    Chip,
    FormControl,
    InputAdornment,
    TextField
} from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import { notify } from '../../../helpers'
import axios from 'axios'
import { EntityScopeEnum } from '../../auth/entityScope'
import { useCurrentSite, useDefaultSitesForSelector } from '../../auth/atoms'
import { ImagesAPI } from '../../../common/constants'
import { TagType } from '../../system/tags/types'

export const imageSchema = z.object({
    id: z.string(),
    type: z.string(),
    department_id: z.string().nullish(),
    sites: z.array(z.string()).nullish(),
    thumbnail: z.string(),
    data: z.string(),
    filename: z.string(),
    alt: z.string(),
    tags: z.array(z.string()).nullish()
})

const model = z.object({
    tags: z.array(z.string()).nullish(),
    sites: z.array(z.string()).nullish(),
    departmentId: z.string().nullish(),
    images: z.array(mediaObject)
})
type Image = z.infer<typeof imageSchema>
type Model = z.infer<typeof model>

type MediaUploaderProps = {
    onUpload?: (image: Image) => void
    HeaderSlot?: React.ReactNode
}
export const MediaUploader = ({ onUpload, HeaderSlot }: MediaUploaderProps) => {
    const [state, setState] = React.useState<Model>({ images: [], tags: [], sites: [] })
    const [savingState, setSavingState] = React.useState<Record<string, string>>({})
    const currentSite = useCurrentSite()
    const defaultSites = useDefaultSitesForSelector()

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            departmentId: currentSite?.Type === 'department' ? currentSite.ID : null,
            sites: defaultSites
        }))
    }, [currentSite])

    const addImage = (image: MediaObject) => {
        console.log(state.sites)
        if (state.images.some((i) => i.filename === image.filename)) {
            return
        }
        setState((prev) => ({ ...prev, images: [...prev.images, image] }))
    }
    const deleteImage = (filename: string) => {
        setState((prev) => ({ ...prev, images: prev.images.filter((i) => i.filename !== filename) }))
    }

    const validate = () => {
        if (!Array.isArray(state.sites) || state.sites.length === 0) {
            notify('Please select at least one site', 'error')
            return false
        }
        return true
    }

    const uploadImage = (srcFilename: string, filename: string, alt: string) => {
        if (!validate()) {
            return
        }

        const image = state.images.find((i) => i.filename === srcFilename)
        if (!image) {
            return
        }
        const media: Image = {
            id: uuidv4(),
            type: 'image',
            department_id: state.departmentId,
            sites: state.sites,
            alt: alt,
            tags: state.tags,
            filename: filename,
            thumbnail: image.thumbnail,
            data: image.data
        }

        setSavingState((prev) => ({ ...prev, [srcFilename]: 'saving' }))
        axios
            .post(ImagesAPI, media)
            .then((response) => {
                setSavingState((prev) => ({ ...prev, [srcFilename]: 'saved' }))
                if (onUpload) {
                    onUpload(media)
                }
                deleteImage(srcFilename)
            })
            .catch((error) => {
                notify(error, 'error')
                setSavingState((prev) => ({ ...prev, [srcFilename]: 'error' }))
            })
    }

    return (
        <div>
            <div className='flex-row-between' style={{ margin: 'auto auto 30px auto' }}>
                <h1 className='pages-header'>Upload images</h1>
                {HeaderSlot && HeaderSlot}
            </div>
            <div className={'row'}>
                <div className={'col-md-6'}>
                    <SiteSelectorForContent
                        Disabled={false}
                        HasError={!state?.sites || state.sites.length === 0}
                        ContentType={EntityScopeEnum.Image}
                        Selected={state.sites || []}
                        OnChange={(sites) => {
                            setState((prev) => ({ ...prev, sites: sites }))
                            console.log(sites)
                        }}
                    />
                </div>
                <div className={'col-md-6'}>
                    <TagsSelector
                        value={state?.tags || []}
                        onChange={(tags) => setState((p) => ({ ...p, tags }))}
                        tagType={TagType.Media}
                    />
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-md-12'}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', p: 1, m: 1 }}>
                        {state.images.map((image) => {
                            const disabled =
                                savingState[image.filename] === 'saving' || savingState[image.filename] === 'saved'
                            const hasError = savingState[image.filename] === 'error'
                            return (
                                <ImageCard
                                    key={image.filename}
                                    image={image}
                                    disabled={disabled}
                                    hasError={hasError}
                                    onUpload={uploadImage}
                                    onDelete={deleteImage}
                                />
                            )
                        })}
                    </Box>
                </div>
            </div>
            {state.images.length === 0 && (
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <ImageLoader onChange={addImage} />
                    </div>
                </div>
            )}
        </div>
    )
}

type ImageCardProps = {
    image: MediaObject
    disabled: boolean
    hasError: boolean
    onUpload: (srcFilename: string, filename: string, alt: string) => void
    onDelete: (srcFilename: string) => void
}

const ImageCard = ({ image, disabled, hasError, onDelete, onUpload }: ImageCardProps) => {
    const [alt, setAlt] = React.useState((image.filename.split('.').shift() || '').replaceAll(/[^a-zA-Z0-9]+/gi, ' '))
    const [filename, setFilename] = React.useState(image.filename.split('.').shift() || '')
    const extension = image.filename.split('.').pop()
    const isValidFilename = (filename: string) => {
        return filename && filename.match(/^[\w,\s-]+$/gim)
    }
    const isValidAlt = (alt: string) => {
        return alt && alt.length > 0
    }
    return (
        <Card key={image.filename} sx={{ maxWidth: 330, p: 1, m: 1 }}>
            <CardActionArea>
                <div
                    style={{
                        height: 250,
                        width: 330,
                        backgroundImage: `url(${image.thumbnail})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center'
                    }}
                ></div>
            </CardActionArea>
            <CardContent>
                <FormControl fullWidth>
                    <TextField
                        margin={'dense'}
                        error={!isValidFilename(filename)}
                        label='Filename'
                        value={filename}
                        onChange={(e) => setFilename(e.target.value)}
                        fullWidth
                        disabled={disabled}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position={'end'}>
                                    <Chip label={`.${extension}`} />
                                </InputAdornment>
                            )
                        }}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <TextField
                        margin={'dense'}
                        error={!isValidAlt(alt)}
                        label='Alt'
                        value={alt}
                        onChange={(e) => setAlt(e.target.value)}
                        fullWidth
                        disabled={disabled}
                    />
                </FormControl>
            </CardContent>
            <CardActions style={{ justifyContent: 'center' }}>
                <Button
                    size='small'
                    color='secondary'
                    disabled={disabled}
                    onClick={() => {
                        onDelete(image.filename)
                    }}
                >
                    Delete
                </Button>
                <Button
                    size='small'
                    color='primary'
                    disabled={disabled}
                    onClick={() => {
                        if (!isValidFilename(filename)) {
                            return
                        }
                        if (!isValidAlt(alt)) {
                            return
                        }
                        onUpload(image.filename, `${filename}.${extension}`, alt)
                    }}
                >
                    {hasError ? 'Retry' : disabled ? 'Uploading...' : 'Upload'}
                </Button>
            </CardActions>
        </Card>
    )
}
