import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    TextField
} from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { ImageGallery } from '../../../media/image/ImageGallery'
import { OpenGallery } from '../../../media/image/OpenGallery'
import { isEqual } from 'lodash'
import { useQuery } from '@tanstack/react-query'
import { tagService } from '../../tags/tag.service'
import useSiteForAccountsQueryMutation from '../../../../common/query-hooks/useSiteForAccountsQueryMutation'
import { BoxForm } from '../../../../common/components/BoxForm'
import { useParams } from 'react-router-dom'
import { SiteType, siteTagsQueryKey } from '../../../../common/constants'

interface ITag {
    active: boolean
    id: string
    name: string
    type: string
}

interface IEditSettingsGeneralFormData {
    siteName: string
    description: string
    slogan: string
    address: string
    city: string
    province: string
    postalCode: string
    email: string
    phoneNumber: string
    faxNumber: string
    siteType: string
    Primary: string
    Secondary: string
    LogoSrc: string
    tags: string[]
}

function isValidHexValue(v: string) {
    // https://stackoverflow.com/questions/8027423/how-to-check-if-a-string-is-a-valid-hex-color-representation
    return /^#([0-9A-F]{3}){1,2}$/i.test(v)
}

interface IFieldsConfig {
    [key: string]: {
        label: string
        required: boolean
        helperText?: string
        validator?: (v: string) => string
    }
}

export const fieldsConfig: IFieldsConfig = {
    siteName: {
        label: 'Site Name',
        required: true
    },
    description: {
        label: 'Description',
        required: false,
        helperText:
            'A short 1-3 sentence description which may be used on the Homepage or About page as part of the design'
    },
    slogan: {
        label: 'Slogan',
        required: false
    },
    address: {
        label: 'Address',
        required: true
    },
    city: {
        label: 'City',
        required: true
    },
    province: {
        label: 'Province',
        required: true
    },
    postalCode: {
        label: 'Postal Code',
        required: true
    },
    email: {
        label: 'Email',
        required: true,
        validator: (v: string) => {
            // regex copied: https://stackoverflow.com/questions/46155/how-can-i-validate-an-email-address-in-javascript
            if (
                !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                    v.toLowerCase()
                )
            ) {
                return 'Invalid Email'
            }

            return ''
        }
    },
    phoneNumber: {
        label: 'Phone Number',
        required: true
    },
    faxNumber: {
        label: 'Fax Number',
        required: false
    },
    siteType: {
        label: 'Site Type',
        required: false,
        helperText: 'A site can only have one type - choose what fits best'
    },
    Primary: {
        label: 'Primary Colour',
        required: false,
        validator: (v: string) => (isValidHexValue(v) ? '' : 'Invalid Hex Value')
    },
    Secondary: {
        label: 'Secondary Colour',
        required: false,
        validator: (v: string) => (isValidHexValue(v) ? '' : 'Invalid Hex Value')
    },
    LogoSrc: {
        label: 'Notification Logo',
        required: false,
        helperText: 'Expected dimensions: 200x50'
    },
    tags: {
        label: 'Site Tags',
        required: false,
        helperText: 'Tags can be used when sharing content and media: e.g.: High School or French Immersion'
    }
}

interface EditSettingsGeneralProps {}

// For the "General" tab in /system/sites/edit page
function EditSettingsGeneral({}: EditSettingsGeneralProps) {
    const params = useParams()
    const { siteData, mutation } = useSiteForAccountsQueryMutation(params.id)
    const { data: siteTagsData, refetch: refetchSiteTagsData } = useQuery({
        queryKey: [siteTagsQueryKey],
        queryFn: () => tagService.getAll(params.id, ['site'], '0', '999', undefined)
    })

    const siteTags: ITag[] = (siteTagsData as any)?.results || []

    const tagIdToTagNameMap =
        siteTags?.reduce(
            (a, t) => ({
                ...a,
                [t.id]: t.name
            }),
            {} as Record<string, string>
        ) || null

    const tagNameToTagIdMap =
        siteTags?.reduce(
            (a, t) => ({
                ...a,
                [t.name]: t.id
            }),
            {} as Record<string, string>
        ) || null

    const [isImageGalleryOpen, setIsImageGalleryOpen] = useState(false)

    // state used to update default data when settings are updated
    const defaultFormDataValues: IEditSettingsGeneralFormData = useMemo(
        () => ({
            siteName: siteData?.name || '',
            description: siteData?.description || '',
            slogan: siteData?.settings?.slogan || '',
            address: siteData?.settings?.address || '',
            city: siteData?.settings?.city || '',
            province: siteData?.settings?.province || '',
            postalCode: siteData?.settings?.postal || '',
            email: siteData?.settings?.email || '',
            phoneNumber: siteData?.settings?.phone || '',
            faxNumber: siteData?.settings?.fax || '',
            siteType: siteData?.type || '',
            Primary: siteData?.settings?.Primary || '',
            Secondary: siteData?.settings?.Secondary || '',
            LogoSrc: siteData?.settings?.LogoSrc || '',
            tags: siteData?.tags || [] // array of uuids
        }),
        [siteData]
    )

    const [formData, setFormData] = useState<IEditSettingsGeneralFormData>(defaultFormDataValues)
    const formDataHasChanges = useMemo(
        () => !isEqual(formData, defaultFormDataValues),
        [defaultFormDataValues, formData]
    )
    const [formDataError, setFormDataError] = useState<Record<string, string> | null>(null)
    const disableSaveButton = useMemo(
        () => !formDataHasChanges || !!(formDataError && Object.values(formDataError).find((v) => !!v)),
        [formDataError, formDataHasChanges]
    )

    function textFieldOnChangeHandler(fieldName: string, value: string) {
        setFormData((prev) => ({
            ...prev,
            [fieldName]: value
        }))

        if (fieldsConfig[fieldName]?.validator) {
            const validationError = fieldsConfig[fieldName]?.validator?.(value) || ''
            setFormDataError((prev) => ({
                ...prev,
                [fieldName]: !!validationError ? validationError : ''
            }))
        }
    }

    function handleSubmit() {
        const { siteName, description, siteType, ...rest } = formData
        // Ensure not to remove fields within settings object that are not used in this component
        const updateSettingsJsonObject = {
            ...siteData.settings,
            ...rest,
            postal: rest.postalCode,
            phone: rest.phoneNumber,
            fax: rest.faxNumber
        }

        mutation.mutate({
            id: siteData.id,
            tags: formData.tags,
            description,
            // name: formData?.siteName
            type: siteType,
            settings: updateSettingsJsonObject
        })
        refetchSiteTagsData()
    }

    useEffect(() => {
        // necessary to show siteData type on initial render because Select component does not handle undefined well
        setFormData((prev) => ({ ...prev, siteType: siteData?.type }))
    }, [siteData])

    if (!siteData) return null

    return (
        <BoxForm onSubmit={() => handleSubmit()}>
            <TextField
                disabled
                variant='standard'
                label={fieldsConfig['siteName'].label}
                required={fieldsConfig['siteName'].required}
                error={fieldsConfig['siteName'].required && !formData?.siteName.length}
                aria-disabled
                value={formData?.siteName || ''}
            />
            <FormControl variant='standard'>
                <TextField
                    id='outlined-basic'
                    label={fieldsConfig['description'].label}
                    required={fieldsConfig['description'].required}
                    variant='outlined'
                    minRows={4}
                    maxRows={6}
                    multiline
                    value={formData.description}
                    onChange={(e) => textFieldOnChangeHandler('description', e.target.value)}
                />
                <FormHelperText>{fieldsConfig['description']?.helperText || ''}</FormHelperText>
            </FormControl>
            <TextField
                variant='standard'
                label={fieldsConfig['slogan'].label}
                required={fieldsConfig['slogan'].required}
                value={formData.slogan}
                onChange={(e) => textFieldOnChangeHandler('slogan', e.target.value)}
            />
            <Box className='box-row'>
                <TextField
                    variant='standard'
                    label={fieldsConfig['address'].label}
                    required={fieldsConfig['address'].required}
                    value={formData.address}
                    error={fieldsConfig['address'].required && !formData?.address.length}
                    onChange={(e) => textFieldOnChangeHandler('address', e.target.value)}
                />
                <TextField
                    variant='standard'
                    label={fieldsConfig['city'].label}
                    required={fieldsConfig['city'].required}
                    value={formData.city}
                    onChange={(e) => textFieldOnChangeHandler('city', e.target.value)}
                />
            </Box>
            <Box className='box-row'>
                <TextField
                    variant='standard'
                    label={fieldsConfig['province'].label}
                    required={fieldsConfig['province'].required}
                    value={formData.province}
                    onChange={(e) => textFieldOnChangeHandler('province', e.target.value)}
                />
                <TextField
                    variant='standard'
                    label={fieldsConfig['postalCode'].label}
                    required={fieldsConfig['postalCode'].required}
                    value={formData.postalCode}
                    onChange={(e) => textFieldOnChangeHandler('postalCode', e.target.value)}
                />
            </Box>
            <TextField
                variant='standard'
                label={fieldsConfig['email'].label}
                required={fieldsConfig['email'].required}
                value={formData.email}
                onChange={(e) => textFieldOnChangeHandler('email', e.target.value)}
                error={!!formDataError?.['email']}
                helperText={formDataError?.['email']}
            />
            <Box className='box-row'>
                <TextField
                    variant='standard'
                    label={fieldsConfig['phoneNumber'].label}
                    required={fieldsConfig['phoneNumber'].required}
                    value={formData.phoneNumber}
                    onChange={(e) => textFieldOnChangeHandler('phoneNumber', e.target.value)}
                />
                <TextField
                    variant='standard'
                    label={fieldsConfig['faxNumber'].label}
                    required={fieldsConfig['faxNumber'].required}
                    value={formData.faxNumber}
                    onChange={(e) => textFieldOnChangeHandler('faxNumber', e.target.value)}
                />
            </Box>
            <Box className='box-row' style={{ justifyContent: 'space-between' }}>
                <TextField
                    variant='standard'
                    label={fieldsConfig['Primary'].label}
                    required={fieldsConfig['Primary'].required}
                    value={formData.Primary}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                <div style={{ color: formData.Primary, fontSize: 24 }}>&#9632;</div>
                            </InputAdornment>
                        )
                    }}
                    onChange={(e) => textFieldOnChangeHandler('Primary', e.target.value)}
                    error={!!formDataError?.['Primary']}
                    helperText={formDataError?.['Primary']}
                />
                <TextField
                    variant='standard'
                    label={fieldsConfig['Secondary'].label}
                    required={fieldsConfig['Secondary'].required}
                    value={formData.Secondary}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                <div style={{ color: formData.Secondary, fontSize: 24 }}>&#9632;</div>
                            </InputAdornment>
                        )
                    }}
                    onChange={(e) => textFieldOnChangeHandler('Secondary', e.target.value)}
                    error={!!formDataError?.['Secondary']}
                    helperText={formDataError?.['Secondary']}
                />
            </Box>
            <Box className='box-row' style={{ justifyContent: 'space-between' }}>
                {/* <div>
                <InputLabel>Logo:</InputLabel>
                <OpenGallery
                    title={"Logo"} // settings.Logo
                    error={false}
                    component={false}
                    setCurrentComponent={() => { }}
                    handleOpen={() => setIsImageGalleryOpen(true)}
                    rootStyles={{ maxWidth: "100%" }}
                />
            </div>
            <div>
                <InputLabel>Favicon:</InputLabel>
                <OpenGallery
                    title={"Favicon"}
                    error={false}
                    component={false}
                    setCurrentComponent={() => { }}
                    handleOpen={() => setIsImageGalleryOpen(true)}
                    rootStyles={{ maxWidth: "100%" }}
                />
            </div> */}
                <div data-testid={`${fieldsConfig['LogoSrc'].label}-form`}>
                    <InputLabel data-testid={`${fieldsConfig['LogoSrc'].label}-label`}>Notification Logo:</InputLabel>
                    <OpenGallery
                        title={fieldsConfig['LogoSrc'].label}
                        error={false}
                        component={false}
                        setCurrentComponent={() => {}}
                        value={formData?.LogoSrc}
                        removeSelectedMedia={() => setFormData((prev) => ({ ...prev, LogoSrc: '' }))}
                        handleOpen={() => setIsImageGalleryOpen(true)}
                        rootStyles={{ maxWidth: '100%' }}
                        helperText={fieldsConfig['LogoSrc'].helperText}
                    />
                </div>
            </Box>
            {isImageGalleryOpen && (
                <ImageGallery
                    open={isImageGalleryOpen}
                    close={() => setIsImageGalleryOpen(false)}
                    onChange={(img) =>
                        setFormData((prev) => ({
                            ...prev,
                            LogoSrc: `${img.id}`
                        }))
                    }
                />
            )}
            <FormControl variant='standard' disabled={siteTags.length == 0}>
                <InputLabel id='site-tag-select'>{fieldsConfig['tags'].label}</InputLabel>
                <Select
                    labelId='site-tag-select'
                    id='site-tag-select'
                    label={fieldsConfig['tags'].label}
                    required={fieldsConfig['tags'].required}
                    multiple
                    // if there is no mapping then the tag is not available and may have been deleted tenant wide
                    value={
                        siteTags.length == 0
                            ? ['No site tags configured']
                            : formData.tags.map((id) => tagIdToTagNameMap[id]).filter((name) => !!name)
                    }
                    renderValue={(tag) => tag.join(', ')}
                    onChange={(e) => {
                        const value = e.target.value
                        setFormData((prev) => ({
                            ...prev,
                            tags:
                                typeof value === 'string'
                                    ? value.split(',').map((tagName) => tagNameToTagIdMap[tagName])
                                    : value.map((tagName) => tagNameToTagIdMap[tagName])
                        }))
                    }}
                >
                    {siteTags.map((tag, idx) => (
                        <MenuItem key={tag.name} value={tag.name}>
                            <Checkbox checked={formData.tags.findIndex((t) => tag.id == t) > -1} />
                            <ListItemText primary={tag.name} />
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText>{fieldsConfig['tags']?.helperText || ''}</FormHelperText>
            </FormControl>
            <FormControl variant='standard'>
                <InputLabel id='site-type-select'>Site Type</InputLabel>
                <Select
                    labelId='site-type-select'
                    id='site-type-select'
                    required={fieldsConfig['siteType'].required}
                    label={fieldsConfig['siteType'].label}
                    value={formData.siteType || ''}
                    onChange={(e) => {
                        setFormData((prev) => ({
                            ...prev,
                            siteType: e.target.value
                        }))
                    }}
                >
                    {Object.values(SiteType as Record<string, string>).map((item: string) => (
                        <MenuItem key={item} value={item}>
                            {item}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText>{fieldsConfig['siteType']?.helperText || ''}</FormHelperText>
            </FormControl>
            <Box className='box-row'>
                <Button variant='text' color='primary' type='reset' onClick={() => setFormData(defaultFormDataValues)}>
                    Reset Changes
                </Button>
                <Button
                    disabled={disableSaveButton}
                    variant='contained'
                    color='primary'
                    type='submit'
                    // onClick={handleSubmit}
                >
                    Save
                </Button>
            </Box>
        </BoxForm>
    )
}

export default EditSettingsGeneral
