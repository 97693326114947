import { useEffect, useMemo, useState } from 'react'
import { Box, Button, Card, CardContent, FormHelperText, Typography } from '@mui/material'
import { isEqual } from 'lodash'
import { notify } from '../../../helpers'
import SecurityGroupForm from './SecurityGroupForm'
import { useMutation } from '@tanstack/react-query'
import { SecurityGroup } from '../types'
import { useParams } from 'react-router-dom'
import { BackButton } from '../../../common/components'
import { editGroupQuery, useGroupByIdQuery } from '../queries'

interface EditSecurityGroupProps {}

export function EditSecurityGroup({}: EditSecurityGroupProps) {
    const params = useParams()

    const { data: group, refetch } = useGroupByIdQuery(params.id || '')
    const editMutation = useMutation({
        mutationFn: editGroupQuery
    })

    const defaultFormDataValues = useMemo(
        () => ({
            Type: group?.Type || '',
            Name: group?.Name || '',
            Description: group?.Description || '',
            RoleID: group?.RoleID || '',
            SiteID: group?.SiteID || null,
            ExternalIdList: group?.ExternalIdList || [],
            AvailableGroups: group?.AvailableGroups || [],
            Audience: group?.Audience || [],
            DependsOn: group?.DependsOn || []
        }),
        [group]
    )

    const [formData, setFormData] = useState(defaultFormDataValues)

    const [submitError, setSubmitError] = useState('')
    const formDataHasChanges = !isEqual(formData, defaultFormDataValues)

    useEffect(() => {
        document.title = `${group?.Name} - Edit Security Group`
    }, [group])
    useEffect(() => {
        if (submitError?.length) {
            setSubmitError('')
        }
    }, [formData])

    useEffect(() => {
        setFormData(defaultFormDataValues)
    }, [defaultFormDataValues])

    function handleOnSave() {
        editMutation.mutate({ ...group, ...formData } as SecurityGroup, {
            onSuccess: () => {
                refetch()
                notify('Group was successfully updated!', 'info')
            },
            onError: (err: any) => {
                notify('Oops! Group could not be updated', 'error')
                setSubmitError(err?.response?.data?.ErrorMessage)
            }
        })
    }

    return (
        <div className='container'>
            <div className='flex-row settings-header' style={{ justifyContent: 'space-between' }}>
                <Typography variant='h6'>Edit Group</Typography>
                <BackButton route='/user-management/security-groups' />
            </div>
            <Card>
                <CardContent sx={{ gap: '8px', display: 'flex', flexDirection: 'column' }}>
                    <SecurityGroupForm
                        formType='edit'
                        headerText={group?.Name || ''}
                        formData={formData}
                        setFormData={setFormData}
                        onSubmit={() => handleOnSave()}
                        onReset={() => setFormData(defaultFormDataValues)}
                        formControlSection={
                            <Box display='flex' flexDirection='column' alignItems='flex-end'>
                                <Box className='box-row' justifyContent='flex-end'>
                                    <Button variant='text' color='primary' type='reset'>
                                        Reset Changes
                                    </Button>
                                    <Button
                                        disabled={!formDataHasChanges}
                                        variant='contained'
                                        color='primary'
                                        type='submit'
                                    >
                                        Save
                                    </Button>
                                </Box>
                                {submitError?.length ? (
                                    <FormHelperText error={true}>{submitError}</FormHelperText>
                                ) : null}
                            </Box>
                        }
                    />
                </CardContent>
            </Card>
        </div>
    )
}
