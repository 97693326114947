import React, { useEffect, useState } from 'react'
import { GridColDef } from '@mui/x-data-grid'
import { httpGet } from '../../../common/client'
import { notify } from '../../../helpers'
import { issues, Issues, IssuesQuery } from './types'
import { Grid } from '@mui/material'
import { trackCells } from '../topics/TopicsGrid'
import { MultipleSelectCheckmarks } from '../audit_records/AuditRecordsGrid'
import { useStateWithStorage } from '../../../common/storage.service'
import PageContainerWithHeader from '../../../common/components/PageContainerWithHeader'
import SearchBar from '../../../common/components/SearchBar'
import { IDToNameCell } from '../../grid/cells/GridCells'
import { BASE, IssuesAPI } from '../../../common/constants'
import { defaultPageQuery } from '../../../common/react-query'
import { DataGridBase } from '../../grid/DataGridBase'
import { WhatsNew } from '../../../common/components/WhatsNew'

export const IssuesGrid = () => {
    const [query, setQuery] = useStateWithStorage<IssuesQuery>('issues-grid-query', { ...defaultPageQuery })
    const [gridState, setGridState] = useState<Issues>({
        TotalRecords: 0,
        TotalPages: 0,
        Offset: 0,
        PageSize: 10,
        Page: 1,
        Rows: []
    })

    const columns: GridColDef[] = [
        { field: 'Status', headerName: 'Status', width: 140, sortable: true },
        { field: 'StartsAt', headerName: 'StartsAt', width: 200, sortable: true },
        { field: 'Domain', headerName: 'Domain', width: 200, sortable: true },
        {
            field: 'Subject',
            headerName: 'Subject',
            flex: 2,
            sortable: true,
            renderCell: (params) => <PreviewLinkCell {...params.row} />
        },
        { field: 'TotalSent', headerName: 'TotalSent', width: 200, sortable: true },

        { field: 'CreationType', headerName: 'CreationType', width: 140, sortable: true },
        { field: 'ID', headerName: 'ID', width: 200, sortable: true },

        {
            field: 'TopicID',
            headerName: 'TopicID',
            width: 200,
            sortable: true,
            renderCell: (params) => <IDToNameCell tableName={'topic'} ID={params.row.TopicID} />
        },

        ...trackCells
    ]
    useEffect(() => {
        console.log('query', query)
        httpGet(`${IssuesAPI}`, query, issues)
            .then((res) => {
                setGridState(res)
            })
            .catch((err) => {
                console.error(err)
                notify(err)
            })
    }, [query])

    const [selStatuses, setSelStatuses] = useState<string[]>(query.Statuses || [])
    useEffect(() => {
        setQuery({ ...query, Statuses: selStatuses })
    }, [selStatuses])

    const [selCreationTypes, setCreationTypes] = useState<string[]>(query.CreationTypes || [])
    useEffect(() => {
        setQuery({ ...query, CreationTypes: selCreationTypes })
    }, [selCreationTypes])

    return (
        <PageContainerWithHeader title='Issues' titleSlot={<WhatsNew
            link={'https://cdn.cmdesign.imagineeverything.com/notifications-1718912661937?hash=doL25vdGlmaWNhdGlvbnMtMTcxODkxMjY2MTkzNw=='} />}>
            <Grid container spacing={2}>
                <Grid item md={6}>
                    <SearchBar
                        value={query.Search || ''}
                        onChange={(val) => setQuery((prev) => ({ ...prev, Search: val }))}
                    />
                </Grid>
                <Grid item md={3}>
                    <MultipleSelectCheckmarks
                        label={'Status'}
                        options={['ready', 'sending', 'done', 'stopped']}
                        selected={selStatuses}
                        setSelected={setSelStatuses}
                    />
                </Grid>
                <Grid item md={3}>
                    <MultipleSelectCheckmarks
                        label={'Created By'}
                        options={['cron', 'cm_admin', 'manual']}
                        selected={selCreationTypes}
                        setSelected={setCreationTypes}
                    />
                </Grid>
                <Grid item md={12}>
                    <DataGridBase
                        columns={columns}
                        state={gridState}
                        setQuery={setQuery}
                        getRowClassName={(params) => params.row.Status}
                    />
                </Grid>
            </Grid>
        </PageContainerWithHeader>
    )
}

export const PreviewLinkCell = ({ ID, Subject }: { ID: string; Subject: string }) => {
    return (
        <>
            <a href={`${BASE}/api/v1/notifications/issues/${ID}/preview`} target={'_blank'} rel='noreferrer'>
                {Subject}
            </a>
        </>
    )
}
