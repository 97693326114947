import moment, { Moment } from 'moment'

export function addDays(date: Date | undefined | null, days: number) {
    if (!date) {
        return date
    }

    const result = new Date(date)
    result.setDate(result.getDate() + days)
    return result
}

export function getCurrentDate(args?: { min?: Moment; max?: Moment } | undefined) {
    const { min, max } = args || {}

    const current = moment()

    if (min && current < min) {
        return min
    } else if (max && current > max) {
        return max
    }
    return current
}
