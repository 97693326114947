import React, { Fragment, useContext, useRef, useState } from 'react'
import {
    AppBar,
    Button,
    Dialog,
    FormControl,
    FormHelperText,
    IconButton,
    TextField,
    Toolbar,
    Typography
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { notify } from '../../../../helpers'
import { makeStyles } from '@mui/styles'
import { documentContext } from '../context'
import { documentService } from '../document.service'
import Card from '@mui/material/Card'
import DocumentPreview from './DocumentPreview'
import DocumentUpload from '../../upload/DocumentUpload'
import CircularProgress from '@mui/material/CircularProgress'
import _ from 'lodash'
import { PrivacyLevelCheckbox } from '../../../../common/components/PrivacyLevelCheckbox'
import clsx from 'clsx'
import '../../../../app/App.css'
import { TagsSelectorForContent } from '../../../../common/components/selectors/TagSelectorForContent'
import { SiteSelectorForContent } from '../../../../common/components/selectors/SiteSelectorForContent'
import { useCurrentSiteID } from '../../../auth/atoms'
import { colours } from '../../../../common/colours'
import { TagType } from '../../../system/tags/types'

/**
 * @param {boolean}                 isEditDialogOpen    - Is the edit dialog open
 * @param {Object}                  documentToEdit      - The document object in scope
 * @param {function}                editDocument        - The submit function which performs actions upon the edited document
 * @param {function}                setDocumentToEdit   - The setState for documentToEdit
 * @param {function}                handleClose         - Function handler to set isEditDialogOpen to false
 * @param {Array<Object>}           mediaTags           - Tags with the type of Media
 * @param {Array<Object>}           siteTags            - Tags with the type of Site
 * @param {Array<Object>}           siteOptions         - Sites tailored to the user and their Permissions
 * @param {Array<Object>}           errors              - an object of errors with component named keys
 * @return {JSX}
 * */
const DocumentEdit = ({
    isEditDialogOpen,
    handleClose,
    documentToEdit,
    setDocumentToEdit,
    errors,
    setErrors,
    refetchDocuments
}) => {
    const classes = useStyles()
    const [documents, setDocuments] = useContext(documentContext)
    const currentSiteID = useCurrentSiteID()
    const [optionView, setOptionView] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [editBinary, setEditBinary] = useState('')

    const s = documentToEdit.filename.split('.')
    const extension = s[s.length - 1]

    const editDocument = () => {
        const data = _.cloneDeep(documentToEdit)
        data.filename = documentToEdit.filename
        data.sites = documentToEdit.sites
        data.tags = documentToEdit.tags
        data.department_id = documentToEdit.department_id

        documentService.editDocument(currentSiteID, data).then(
            (r) => {
                setDocumentToEdit((prev) => ({
                    ...prev,
                    filename: r.filename
                }))
                const clone = _.cloneDeep(documents)
                const elIndex = clone.findIndex((x) => x.id === r.id)
                if (elIndex > -1) {
                    clone[elIndex] = r
                    setDocuments(clone)
                }
                notify('Document successfully edited', 'info')
            },
            (e) => console.log(e)
        )
    }

    const replaceDocumentBinary = (binary) => {
        setIsLoading(true)
        documentService.replaceDocument(currentSiteID, documentToEdit.id, binary).then(
            (r) => {
                setEditBinary(r)
                setIsLoading(false)
                refetchDocuments()
                notify('Document Successfully Replaced', 'info')
            },
            (e) => {
                notify('Whoops! Error replacing document', 'error')
                setIsLoading(false)
            }
        )
    }

    const handleChangeOptionView = () => {
        setOptionView(!optionView)
    }

    const appbarRef = useRef()
    return (
        <Dialog
            fullScreen
            open={isEditDialogOpen}
            onClose={handleClose}
            style={{ backgroundColor: colours.off_white }}
            classes={{ paper: classes.paper }}
        >
            <AppBar ref={appbarRef}>
                <Toolbar className={classes.flexRow}>
                    <div className='flex-row'>
                        <IconButton edge='start' color='inherit' onClick={handleClose} aria-label='close' size='large'>
                            <CloseIcon />
                        </IconButton>
                        <Typography variant='h6' className={classes.title}>
                            {documentToEdit.filename}
                        </Typography>
                    </div>

                    {optionView ? (
                        <Button onClick={handleChangeOptionView} color='inherit'>
                            Replace Document
                        </Button>
                    ) : (
                        <Button onClick={handleChangeOptionView} color='inherit'>
                            Back
                        </Button>
                    )}
                </Toolbar>
            </AppBar>
            <div
                style={{
                    minHeight: appbarRef?.current?.clientHeight || appbarRef?.current?.height || '4rem',
                    width: '100%'
                }}
            />

            <div className='flex-row' style={{ alignItems: 'normal', padding: '2rem 0' }}>
                <div className='col-xs-3' />
                <div className='col-xs-6' style={{ margin: 'auto' }}>
                    {extension === 'pdf' ? (
                        <DocumentPreview documentId={documentToEdit.id} editedBinary={editBinary} />
                    ) : (
                        <p style={{ textAlign: 'center' }}>Preview is only available for PDF files</p>
                    )}
                </div>

                <span className='col-xs-3 flex-column p10'>
                    <Card className={classes.options}>
                        {optionView ? (
                            <Fragment>
                                <FormControl variant='standard' className={classes.optionItem} sx={{ my: 1 }}>
                                    <TextField
                                        variant='standard'
                                        margin='dense'
                                        id='name'
                                        label='Filename..'
                                        value={documentToEdit.filename || ''}
                                        type='name'
                                        fullWidth
                                        onChange={(event) => {
                                            setDocumentToEdit((prev) => ({
                                                ...prev,
                                                filename: event.target.value
                                            }))
                                        }}
                                    />
                                </FormControl>

                                {/*Tag*/}
                                <FormControl variant='standard' className={classes.optionItem} sx={{ my: 1 }}>
                                    <TagsSelectorForContent
                                        selected={documentToEdit.tags}
                                        tagType={TagType.Media}
                                        onChange={(tags) => setDocumentToEdit((prev) => ({ ...prev, tags: tags }))}
                                    />
                                </FormControl>

                                {/*Sites*/}
                                <FormControl variant='standard' className={classes.optionItem} sx={{ my: 1 }}>
                                    <SiteSelectorForContent
                                        Selected={documentToEdit.sites}
                                        ContentType={'document'}
                                        OnChange={(sites) => setDocumentToEdit((prev) => ({ ...prev, sites: sites }))}
                                        HasError={!documentToEdit.sites || documentToEdit.sites.length === 0}
                                    />
                                    <PrivacyLevelCheckbox
                                        privacyLevel={documentToEdit.privacyLevel}
                                        setPrivacyLevel={(privacyLevel) =>
                                            setDocumentToEdit((prev) => ({
                                                ...prev,
                                                privacyLevel: privacyLevel
                                            }))
                                        }
                                        errors={errors}
                                        setErrors={setErrors}
                                        horizontal
                                    />
                                </FormControl>
                                <FormHelperText>
                                    Last updated: {new Date(documentToEdit.updated).toLocaleString()}
                                </FormHelperText>
                                <Button
                                    onClick={editDocument}
                                    color='primary'
                                    variant='outlined'
                                    className={classes.optionsFlexItems}
                                >
                                    Submit
                                </Button>
                            </Fragment>
                        ) : (
                            <FormControl variant='standard' className={clsx('flex-column')}>
                                <p className={classes.basicMargin}>
                                    Would you like to replace this document with another?
                                </p>
                                <DocumentUpload
                                    documentLimit={1}
                                    fileType={extension}
                                    replaceDocument={replaceDocumentBinary}
                                />
                                {isLoading && (
                                    <div className={classes.progressDiv}>
                                        <CircularProgress />
                                    </div>
                                )}
                            </FormControl>
                        )}
                    </Card>
                </span>
            </div>
        </Dialog>
    )
}
export default DocumentEdit
const useStyles = makeStyles({
    pdfNavigation: {
        height: '60%',
        gridColumnStart: 5,
        gridColumnEnd: 'span 2',
        justifySelf: 'center',
        gridRowStart: 10,
        zIndex: 10000000
    },
    textfield: {
        width: '98%',
        margin: '1vh'
    },
    basicMargin: {
        marginTop: '3vh',
        marginBottom: '1vh'
    },
    appBar: {
        position: 'relative',
        backgroundColor: '#2196f3'
    },
    options: {
        padding: '15px',
        margin: '15px',
        marginTop: '10vh'
    },
    optionItem: {
        width: '100%'
    },
    paper: {
        backgroundColor: colours.off_white
    },
    optionsFlex: {
        gridColumnStart: '9',
        gridRowStart: '2',
        display: 'flex',
        flexDirection: 'column',
        gridColumnEnd: 'span 2',
        gridRowEnd: 'span 6',
        width: '80%',
        height: '100%',
        padding: '15px'
    },
    optionsFlexItems: {
        marginTop: '1vh',
        marginBottom: '1.5vh'
    },
    dialogGrid: {
        display: 'grid',
        gridTemplateColumns: '10% 10% 10% 10% 10% 10% 10% 10% 10% 10%',
        gridTemplateRows: '10% 10% 10% 10% 10% 10% 10% 10% 10% 10%',
        height: '100%',
        width: '100%',
        backgroundColor: colours.off_white
    },
    page: {
        borderRadius: '4px'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    progressDiv: {
        display: 'flex',
        height: '25vh',
        alignItems: 'center',
        justifyContent: 'center'
    }
})
