import { Button, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { authenticationService } from '../authentication.service'
import { notify } from '../../../helpers'
import { useStateWithStorage } from '../../../common/storage.service'
import './Authenticate.css'

const loginButtonWidth = 275
const useStyles = makeStyles({
    root: {
        borderRadius: 1,
        fontWeight: 400,
        marginTop: '3%',
        color: 'white',
        width: '100%',
        backgroundColor: '#6188f1'
    }
})

export function Authenticate({ onChange }) {
    const classes = useStyles()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [userParams, setUserParams] = useState({ email: '', password: '' })
    const [errors, setErrors] = useState({ email: false, password: false })
    const [hideNativeLogin, setHideNativeLogin] = useStateWithStorage<boolean>('show-native-login-controls', true)

    function handleChange(event) {
        const { name = '', value = '' } = event?.target || {}
        setUserParams((prev) => ({ ...prev, [name]: value }))
        if (!errors[name]) {
            setErrors((p) => ({ ...p, [name]: false }))
        }
    }

    async function handleLoginRequest() {
        try {
            setIsSubmitting(true)
            const { password, email } = userParams
            await authenticationService.login(email, password)
            handleLogin()
        } catch (e) {
            notify('Invalid email and password combination', 'error')
            // todo =>
            //  @ts-ignore
            setErrors((prev) => ({ ...prev, request: e }))
        } finally {
            setIsSubmitting(false)
        }
    }

    function handleLogin() {
        return onChange?.()
    }

    function onPressingEnter(e: any) {
        if (e.key === 'Enter') {
            if (!errors.email && !errors.password) {
                handleLoginRequest()
            }
        }
    }

    return (
        <div className='splash-centered'>
            <div className='background-logo' style={{ marginBottom: '3rem' }} />
            <div className='flex-column-center'>
                {/*Azure Sign-in*/}
                <AzureLoginButton />
                {/*Google Sign-in*/}
                <GoogleLoginButton onChange={handleLogin} />

                <div className='flex-row' style={{ justifyContent: 'space-around' }}>
                    <div className='horizontal-rule' style={{ width: '33%' }} />
                    <p style={{ opacity: 0.5 }}> or </p>
                    <div className='horizontal-rule' style={{ width: '33%' }} />
                </div>

                <>
                    {hideNativeLogin ? (
                        <Button
                            className={'login-buttons'}
                            onClick={() => setHideNativeLogin(false)}
                            data-testid={'native-sign-in'}
                        >
                            Native Sign-in
                        </Button>
                    ) : (
                        <div style={{ width: loginButtonWidth }}>
                            <div className='mt1r'>
                                <TextField
                                    value={userParams.email || ''}
                                    onChange={handleChange}
                                    id='user'
                                    name='email'
                                    placeholder='Enter your email'
                                    error={errors.email}
                                    onKeyUp={onPressingEnter}
                                    className={'login-field form-control' + (errors.email ? ' is-invalid' : '')}
                                    data-testid='email'
                                />
                            </div>
                            <div className='mt1r'>
                                <TextField
                                    value={userParams.password || ''}
                                    onChange={handleChange}
                                    id='pass'
                                    name='password'
                                    type='password'
                                    error={errors.password}
                                    placeholder='Enter your password'
                                    onKeyUp={onPressingEnter}
                                    className={'login-field form-control' + (errors.email ? ' is-invalid' : '')}
                                    data-testid='password'
                                />
                            </div>
                            <div className='mt1r'>
                                <LoadingButton
                                    onClick={handleLoginRequest}
                                    className={classes.root}
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                    data-testid='login'
                                >
                                    Login
                                </LoadingButton>
                            </div>
                        </div>
                    )}
                </>
            </div>
        </div>
    )
}

export function LoadingScreen() {
    return (
        <div className={'splash-centered'}>
            <div
                className='background-logo'
                style={{ marginBottom: '3rem', width: loginButtonWidth + 'px', margin: 'auto' }}
            />
            <p style={{ textAlign: 'center' }}>Loading...</p>
        </div>
    )
}

export const useAbortController = () => {
    const [controller, setController] = useState(new AbortController())
    const nextController = (message?: string): AbortController => {
        const n = new AbortController()
        controller.abort(message)
        setController(n)
        return n
    }
    return { controller, nextController }
}

function GoogleLoginButton({ onChange }) {
    const { controller, nextController } = useAbortController()
    const [buttonMarker, setButtonMarker] = useState(false)

    useEffect(() => {
        //@ts-ignore
        if (window?.gapi) {
            //@ts-ignore
            window?.gapi?.load('auth2', () => {
                //@ts-ignore
                window?.gapi?.signin2?.render('g-signin2', {
                    scope: 'profile email',
                    width: loginButtonWidth,
                    height: 52,
                    longtitle: true,
                    theme: 'dark',
                    onsuccess: onGoogleSignIn,
                    onfailure: (e) => console.error('Error from Google API (GAPI) on login attempt:', e)
                })
            })
        } else {
            setTimeout(() => {
                setButtonMarker(!buttonMarker)
            }, 250)
        }
    }, [buttonMarker])

    function onGoogleSignIn(googleUser) {
        const controller = nextController()
        authenticationService.loginWithGoogle(googleUser, controller).then(
            (r) => onChange?.(),
            (e) => {
                let message = ''
                if (e?.response?.status === 401) {
                    message = `Unauthorized: Your Organization may not have access to Content Manager`
                } else {
                    const { status, statusText } = e?.response || {}
                    if (status && statusText) {
                        message = `${status} : ${statusText} - Please try again later`
                    } else {
                        message = 'Whoops! Something went wrong - Please try again later'
                    }
                }
                console.error(`Failed to login with google: [${message}]`)
                notify(message)
            }
        )
    }

    return (
        <div
            id='g-signin2'
            style={{
                margin: '0.5rem 0',
                alignSelf: 'center'
            }}
        ></div>
    )
}

function AzureLoginButton() {
    const getInternetCodeFromLocation = () => {
        if (window.location.hostname === 'contentmanager.imagineeverything.ca') {
            return {
                code: 'ca',
                state: window.btoa('https://contentmanager.imagineeverything.ca').replace(/=/g, ''),
                cid: '9e77a8ef-09ce-4c5f-bf99-9b4844b660a8'
            }
        }
        return {
            code: 'com',
            state: window.btoa('https://contentmanager.imagineeverything.com').replace(/=/g, ''),
            cid: '7fd5e018-ae0d-488f-afc0-22f57589dba8'
        }
    }
    const { code, state, cid } = getInternetCodeFromLocation()
    return (
        <div style={{ width: loginButtonWidth, margin: '0.5rem 0', alignSelf: 'center' }}>
            <a
                href={`https://login.microsoftonline.com/common/oauth2/authorize?client_id=${cid}&response_type=code&redirect_uri=https://contentmanager.imagineeverything.${code}/api/v1/authorize&response_mode=form_post&state=${state}&scope=openid%20profile%20https%3A//graph.microsoft.com/Group.Read.All%20https%3A//graph.microsoft.com/User.Read%20https%3A//graph.microsoft.com/User.ReadBasic.All%20https%3A//graph.microsoft.com/email%20https%3A//graph.microsoft.com/profile`}
            >
                <img
                    style={{ width: '100%' }}
                    className='logo'
                    role='img'
                    data-bind="imgSrc, attr: { alt: str['MOBILE_STR_Footer_Microsoft'] }"
                    src='https://docs.microsoft.com/en-us/azure/active-directory/develop/media/howto-add-branding-in-azure-ad-apps/ms-symbollockup_signin_light.svg'
                    alt='Microsoft'
                />
            </a>
        </div>
    )
}
