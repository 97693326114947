import { useMemo } from 'react'
import { ImportableContent, StandardizedImportConfig, useImportedContent } from './useImportedContent'
import { Content } from '../../types'
import { useSettingsByTypeQuery } from '@/pkgs/system/settings/queries'
import { getAllIntegrationTypes, Settings } from '@/pkgs/system/settings/types'

export interface useInputDisablerProps {
    content: ImportableContent | Content | undefined
    hasPermission: boolean
    test_settings?: Settings[]
}

export function useInputDisabler({ content, hasPermission, test_settings }: useInputDisablerProps) {
    const { data: allTenantImportSettings } = useSettingsByTypeQuery(getAllIntegrationTypes())
    const { isFieldEnabled, isImported, importInfo } = useImportedContent({
        content: content,
        tenantImportConfigurations:
            (test_settings || allTenantImportSettings?.Rows)?.map((s) => s.Data as StandardizedImportConfig) || []
    })

    const isFullyDisabled = useMemo(() => {
        if (!isImported) {
            return false
        }
        if (importInfo == null) {
            return true
        }
        const set = importInfo?.editableFieldsSet
        if (!set) {
            return true
        }
        return set.size == 0
    }, [importInfo])

    function isInputDisabled(inputName: keyof Content | string) {
        if (!hasPermission) {
            return true
        }
        return !isFieldEnabled(inputName)
    }

    return { isInputDisabled, isImported, importInfo, isFullyDisabled }
    /*
     * importInfo: {
     *   source: "outlook",
     *   editableFields: {
     *       site: false,
     *       published: true,
     *   }
     * }
     * */
}
