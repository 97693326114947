import { useMemo } from 'react'
import { Content } from '../../types'

export interface objectWithSettings {
    ID: string
    Settings: Record<string, any>
    settings?: Record<string, any>
}

export interface ImportableContent {
    Type: string
    Settings: {
        imported?: boolean
        importInfo?: {
            source: string
            calendar?: string
            schoolIds?: string[]
            ids?: string[]
        }
        isAllDay?: boolean
        enddate?: string
        startdate?: string
    }
}

export interface useImportedContentProps {
    content: ImportableContent | Content | undefined
    tenantImportConfigurations: StandardizedImportConfig[]
}

export function useImportedContent({ content, tenantImportConfigurations }: useImportedContentProps) {
    const importInfo = useMemo(
        () => getImportInfoForContent(),
        [content?.Settings?.importInfo, content?.Settings?.importInfo?.source, tenantImportConfigurations]
    )
    const isImported = Boolean(importInfo?.source || content?.Settings?.imported)

    function getImportInfoForContent() {
        if (!content) {
            return null
        }
        // 1. Get the config IDs from content
        const configIdsFromContent = getConfigIdsFromContent(content)
        if (!configIdsFromContent) {
            return null
        }
        const { source, configIds } = configIdsFromContent
        if (!configIds?.length) {
            return null
        }
        // 2. Find the Site Config for related Config IDs
        const config = findConfiguration(tenantImportConfigurations, configIds)
        if (config == null) {
            return null
        }
        // 3. Get by Content Type
        // 4. Return allowed fields
        const editableFieldsSet = getEditableFields(config, content.Type)
        // const editableFieldsSet = getEditableFields(config, content)
        return { source, editableFieldsSet }
    }

    // EditableFields values are true if they are allowed to be altered
    // isFieldEnabled should return true if the content is not imported, the field is not configurable, or the field is configured to be editable
    function isFieldEnabled(inputName) {
        if (!isImported) {
            return true
        }
        // A Site configuration was not found for this piece of content.
        // The content should be disabled as we can't determine what it's configured for.
        if (importInfo == null) {
            return false
        }
        return importInfo.editableFieldsSet.has(inputName)
    }

    return { isFieldEnabled, importInfo, isImported }
}

interface StandardizedFieldConfig {
    default: any
    insertOnly: boolean
    useDefault: boolean
}

export interface StandardizedImportConfig {
    id: string
    news: {
        fields: ConfigurableImportFields<StandardizedFieldConfig>
        templateId: string
    }
    events: {
        fields: ConfigurableImportFields<StandardizedFieldConfig>
        templateId: string
    }
}

interface ConfigurableImportFields<T> {
    site: T
    published: T
    privacyLevel: T
    tags: T
}

function getEditableFields(config: StandardizedImportConfig, contentType: 'news' | 'event' | string) {
    const configForContentType = contentType === 'event' ? config.events : config.news
    const editableFieldsSet: Set<string> = new Set()

    for (const field in configForContentType.fields) {
        if (configForContentType.fields[field].insertOnly === true) {
            editableFieldsSet.add(field)
        }
    }

    // Return Disabled fields instead of Enabled fields.
    // for (const key in content) {
    //     const fieldConf = configForContentType.fields[key]
    //     if (!fieldConf || fieldConf.insertOnly === true) {
    //         editableFieldsSet.add(key)
    //     }
    // }

    return editableFieldsSet
}

function getConfigIdsFromContent(content): { source: string; configIds: string[] } | null {
    const info = content?.Settings?.importInfo
    const source: string = info?.source
    if (!info?.source) {
        return null
    }
    // config ids on content should be consolidated
    // edsby originally  = info.schoolIds: string[]
    // outlook originally = info.calendar: string
    const configIds: string[] = info.ids || info?.schoolIds || [info.calendar]
    return { source, configIds }
}

function findConfiguration(configurations: StandardizedImportConfig[], configIds: string[]) {
    return (
        configurations?.find((config) => {
            for (const searchId of configIds) {
                //@ts-ignore
                if (config?.id === searchId || config?.schoolId === searchId) return config
            }
        }) || null
    )
}
