import { asSecured } from './securityMapping'
import { matchExists, patternMatch, trimAction } from './patternMatch'
import { entityScope } from '../entityScope'
import { Format } from '../../../helpers'
import { NIL } from 'uuid'

const LOG = false

export type Shareable = {
    Sites: string[] | null
    DepartmentID: string | null
}
/* Move this function or rename it - these fields are required for actions like PATCH where we historically only sent ID + changing fields*/
export function AsShareable(input: Record<string, any>): Shareable & { ID: string; Type: string } {
    const departmentId = Format.fromNullUUID(input?.DepartmentID || input?.DepartmentId || input?.departmentId)
    return {
        Sites: input?.sites || input?.Sites || null,
        DepartmentID: departmentId === NIL ? null : departmentId,
        Type: input?.Type || input?.type || '',
        ID: input?.ID || input?.id || ''
    }
}

export type Secured = Shareable & {
    EntityScope: entityScope
}

export type Action = 'create' | 'update' | 'delete' | 'assign_primary'

export type Identity = {
    IsAdmin: boolean
    Email: string
    Groups: {
        SiteID: string | null
        Role: {
            Scopes: string[]
        }
        Audience: string[]
    }[]
}

export function Evaluate(account: Identity, obj: any, action: Action) {
    const secured = asSecured(obj)
    const scope = secured.EntityScope + '/' + action
    return evaluate(account, secured, scope, patternMatch)
}

export function EvaluateEntityScopeOnly(account: Identity, obj: any) {
    const secured = asSecured(obj)
    return evaluate(account, secured, secured.EntityScope, (pattern: string, strToCheck: string) => {
        return patternMatch(trimAction(pattern), strToCheck)
    })
}

function evaluate(account: Identity, sharable: Shareable, scope: string, matcher: (p: string, s: string) => boolean) {
    if (scope === 'cant-map-entity-scope') return false

    if (account.IsAdmin) return true

    if (!account.Groups || !Array.isArray(account.Groups) || !account.Groups.length) {
        LOG && console.warn(`User ${account.Email} has no groups`)
        return false
    }

    if (!sharable.Sites || !Array.isArray(sharable.Sites) || !sharable.Sites.length) {
        //
        // Evaluate tenant-wide permissions
        //
        for (const group of account.Groups) {
            if (group.SiteID) continue

            for (const pattern of group.Role.Scopes) {
                if (matcher(pattern, scope)) return true
            }
        }
        LOG && console.warn(`User ${account.Email} has no tenant-wide permissions for ${scope}`)
        return false
    } else {
        //
        // Evaluate site-specific permissions
        //

        let sites = sharable.Sites
        const sitesNoAccess: string[] = []

        if (sharable.DepartmentID) {
            sites = [sharable.DepartmentID]
        }

        for (const site of sites) {
            const accountScopesForSite: string[] = []

            // get all scopes for this site
            for (const group of account.Groups) {
                if (
                    group.SiteID === null || // tenant-wide group
                    group.SiteID === site // site-specific group
                ) {
                    accountScopesForSite.push(...group.Role.Scopes)
                }
            }

            if (!matchExists(accountScopesForSite, scope, matcher)) {
                sitesNoAccess.push(site)
            }
        }

        if (sitesNoAccess.length) {
            LOG &&
                console.warn(
                    `User ${account.Email} has no permissions for ${scope} on sites ${sitesNoAccess.join(', ')}`
                )
            return false
        }

        return true
    }
}
