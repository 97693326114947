export function patternMatch(pattern: string, strToCheck: string) {
    if (pattern === '*') {
        return true
    }

    const patternParts = pattern.split('*')
    const startsWithWildcard = pattern.startsWith('*')
    const endsWithWildcard = pattern.endsWith('*')

    let currentIndex = 0
    for (let i = 0; i < patternParts.length; i++) {
        const part = patternParts[i]
        if (part === '') {
            continue
        }

        const loc = strToCheck.indexOf(part, currentIndex)
        if (loc === -1) {
            return false
        }

        if (i === 0 && !startsWithWildcard && loc !== 0) {
            return false
        }

        // Update currentIndex to the end of the found part
        currentIndex = loc + part.length
    }

    if (!endsWithWildcard && currentIndex !== strToCheck.length) {
        return false
    }

    return true
}

export function trimAction(pattern: string): string {
    const parts = pattern.split('/')
    return parts[0]
}

export function matchExists(patterns: string[], strToCheck: string, matcher: (p: string, s: string) => boolean) {
    for (const pattern of patterns) {
        if (matcher(pattern, strToCheck)) {
            return true
        }
    }

    return false
}
