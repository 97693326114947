// chosenResources      :     An array of objects   ( sites, accounts, content, etc )
// Returns              :     An array of UUID's
/**
 * Deprecation Notice: this should be replaced with a simple .map, its unnecessary filler or Get.uniqueMapByKey
 * */
export default function formatStateToArrayOfString(chosenResources) {
    let newArray = []
    chosenResources.forEach((resource) => {
        if (resource !== undefined && !newArray.includes(resource.id)) {
            newArray.push(resource.id)
        }
    })
    return newArray
}
