import React from 'react'
import '../../pkgs/content/grids/PageTableHeader.css'
import { useAppContext } from '../../pkgs/auth/atoms'
import { EntityScopeEnum } from '../../pkgs/auth/entityScope'

export const TemplateTableHeader = ({ style, first, second, third, fourth, fifth }) => {
    const evaluators = useAppContext()
    const hasCorrectPermission = evaluators.entityScopeAny(EntityScopeEnum.Resource)
    return (
        <div className='table-item' style={style}>
            <p className='four-font-weight'>{first}</p>

            <p className=' four-font-weight second-column'>{second}</p>

            <p className='third-column four-font-weight'>{third}</p>

            {fourth && hasCorrectPermission && <p className='fourth-column four-font-weight'>{fourth}</p>}

            {fifth && hasCorrectPermission && <p className='fifth-column four-font-weight'>{fifth}</p>}
        </div>
    )
}
