import HelpIcon from '@mui/icons-material/Help'
import React, { ReactNode } from 'react'

interface HelpToolTipProps {
    contents: string[]
    position?: Record<string, string | number> | null
    iconSize?: string | number
    removeDecoration?: boolean
    iconStyles?: React.CSSProperties
    tooltipTextStyles?: React.CSSProperties
    customIcon?: ReactNode
}

const HelpTooltip = ({
    position,
    contents,
    iconSize,
    removeDecoration,
    iconStyles,
    tooltipTextStyles,
    customIcon
}: HelpToolTipProps) => {
    let styles = { height: iconSize ? iconSize : '0.85em' }
    if (iconStyles) styles = Object.assign(styles, iconStyles)
    let tooltipStyles = {}
    if (position) tooltipStyles = Object.assign(tooltipStyles, position)
    if (tooltipTextStyles) tooltipStyles = Object.assign(tooltipStyles, tooltipTextStyles)

    return (
        <span className='tooltip'>
            {customIcon ? <>{customIcon}</> : <HelpIcon style={styles} />}
            <span className='tooltiptext tooltip-notch-none' style={tooltipStyles}>
                <span
                    className='no-margin emulate-ul'
                    style={removeDecoration ? { listStyleType: 'none', padding: '0' } : {}}
                >
                    {contents.map((content) => (
                        <li key={content} className='m5 emulate-li'>
                            {content}
                        </li>
                    ))}
                </span>
            </span>
        </span>
    )
}

export default HelpTooltip
