import { Box, BoxProps, Button, Checkbox, Link, TextField, Typography } from '@mui/material'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { colours } from '../../common/colours'
import {
    LinkAttributes,
    LinkNode,
    TOGGLE_LINK_COMMAND,
    getIdFromPermalink,
    getPermalink,
    isPermalink
} from './Link/LinkNode'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import ContactMailIcon from '@mui/icons-material/ContactMail'
import { useAppContext } from '../auth/atoms'
import { ContentExplorerDialog } from '@/pkgs/content/explorer/ContentExplorerDialog'
import DocumentGallery from '../media/document/DocumentGallery'
import { SelectContactFormDialog } from '../forms/SelectContactFormDialog'
import { BaseSelection } from 'lexical'
import { ContentType } from '../content/types'
import CustomIconButton from '../../common/components/CustomIconButton'
import { positionFloatingMenu } from './toolbar/toolbar.helpers'
import { useGetLinkToDocument } from '../media/copyToClipboard'
import { LightTooltip } from '@/common/components/LightTooltip'

export function linkUrlIsValid(linkUrl: string) {
    if (linkUrl !== '') {
        const isValidUrl = LinkNode.sanitizeUrl(linkUrl) != linkUrl
        return !isValidUrl
    }

    return true
}

export function getFileLink(documentOrFolder) {
    if (!documentOrFolder?.id) {
        console.error('getDocumentLink: document is invalid', documentOrFolder)
    }

    return documentOrFolder.type === 'folder'
        ? `/folder/${documentOrFolder.id}`
        : `/documents/${documentOrFolder.id}/${encodeURIComponent(documentOrFolder.filename)}`
}

function dispatchLinkCommandWithTargetEqBlank(editor: any, link: string) {
    return editor.dispatchCommand(TOGGLE_LINK_COMMAND, {
        url: link,
        target: '_blank'
    })
}

export interface LinkEditorHandlers {
    _setLastSelection: (s: BaseSelection | null) => void
    _getLastSelection: () => BaseSelection | null
    _setIsEditMode: (v: boolean) => void
    _setLinkUrl: (link: string) => void
    _setLinkAttributes: (attributes: LinkAttributes) => void
    _position: (anchorElement: HTMLElement | null, placement?: 'bottom' | 'top', addY?: number) => void
    _hide: () => void
    element: HTMLDivElement | null
}

interface LinkEditorProps {
    boxProps?: BoxProps
    defaultLink?: string
    attributes?: LinkAttributes
    linkOnChangeHandler: (link: string, attributes?: LinkAttributes) => void
    transformDisplayLink?: (linkUrl: string) => string
}

// Lexical Link Editor used for link nodes and image nodes
const LinkEditor = forwardRef<LinkEditorHandlers, LinkEditorProps>(
    ({ boxProps, defaultLink = '', attributes, linkOnChangeHandler, transformDisplayLink }: LinkEditorProps, ref) => {
        const getLinkToDocument = useGetLinkToDocument()
        const linkEditorRef = useRef<HTMLDivElement | null>(null)
        const [editor] = useLexicalComposerContext()
        const appContext = useAppContext()

        const inputRef = useRef<HTMLInputElement | null>(null)
        const [isVisible, setIsVisible] = useState(false)

        const [linkUrl, setLinkUrl] = useState(defaultLink)
        const [prevLinkUrl, setPrevLinkUrl] = useState(defaultLink)
        const [linkUrlError, setLinkUrlError] = useState(false)

        const linkUrlIsEmpty = linkUrl == '' || linkUrl == 'https://'

        const [linkAttributes, setLinkAttributes] = useState<null | LinkAttributes>(attributes || null)

        const [isEditMode, setIsEditMode] = useState(linkUrlIsEmpty)
        const [lastSelection, setLastSelection] = useState<BaseSelection | null>(null)

        const [contentPermalinkDialogIsOpen, setContentPermalinkDialogIsOpen] = useState(false)
        const [contactFormDialogIsOpen, setContactFormDialogIsOpen] = useState(false)
        const [documentGalleryIsOpen, setDocumentGalleryIsOpen] = useState(false)

        useImperativeHandle(ref, () => {
            return {
                _setLastSelection: (s: BaseSelection | null) => setLastSelection(s),
                _getLastSelection: () => lastSelection,
                _setIsEditMode: (v: boolean) => setIsEditMode(v),
                _setLinkUrl: (link: string) => {
                    setLinkUrl(link)
                    setPrevLinkUrl(link)
                },
                _setLinkAttributes: (attributes: LinkAttributes) => setLinkAttributes(attributes),
                _position: (anchorElement: HTMLElement | null, placement?: 'bottom' | 'top', addY?: number) => {
                    placement = placement || 'bottom'
                    if (!anchorElement) {
                        positionFloatingMenu(linkEditorRef?.current, null)
                        setIsVisible(false)
                    } else {
                        positionFloatingMenu(linkEditorRef?.current, anchorElement, placement, { y: addY || 10, x: 0 })
                        setIsVisible(true)
                    }
                },
                _hide: () => {
                    positionFloatingMenu(linkEditorRef?.current, null)
                    setIsVisible(false)
                },
                element: linkEditorRef?.current
            }
        })

        useEffect(() => {
            setLinkUrl(defaultLink)
            setPrevLinkUrl(defaultLink)
        }, [defaultLink])

        const updateLink = useCallback(
            (link, attributes?) => {
                setLinkUrl(link)
                setLinkAttributes(attributes || linkAttributes)
                setPrevLinkUrl(link)
                linkOnChangeHandler(link, attributes || linkAttributes)
            },
            [editor, linkOnChangeHandler]
        )

        return (
            <Box
                ref={linkEditorRef}
                {...boxProps}
                data-testid='lexical-link-editor'
                style={{ display: 'flex', flexDirection: 'column' }}
                sx={{
                    position: 'absolute',
                    zIndex: 1300,
                    top: '-10000px',
                    left: '-10000px',
                    marginTop: '-6px',
                    width: '270px',
                    paddingRight: '8px',
                    opacity: 0,
                    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.3)',
                    backgroundColor: colours.white,
                    borderRadius: '8px',
                    transition: 'opacity 0.5s',
                    alignItems: 'center'
                }}
            >
                <Box display='flex' flexDirection='row' minWidth='100%' height='100%' maxWidth='100%' overflow='auto'>
                    {isEditMode ? (
                        <>
                            <TextField
                                fullWidth
                                multiline
                                ref={inputRef}
                                sx={{
                                    margin: '0.5rem 0rem 0.5rem 0.5rem',
                                    '& .MuiInputBase-root': {
                                        padding: '9.2px 14px'
                                    }
                                }}
                                className='link-input'
                                value={linkUrl}
                                error={linkUrlError}
                                helperText={linkUrlError ? 'Invalid Link URL' : undefined}
                                onChange={(event) => {
                                    setLinkUrlError(false)
                                    setLinkUrl(event.target.value)
                                }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        event.preventDefault()
                                        const _linkUrlIsValid = linkUrlIsValid(linkUrl)
                                        setLinkUrlError(!_linkUrlIsValid)
                                        if (_linkUrlIsValid) {
                                            updateLink(linkUrl)
                                            setIsEditMode(false)
                                        }
                                    } else if (event.key === 'Escape') {
                                        event.preventDefault()
                                        setLinkUrl(prevLinkUrl)
                                        setIsEditMode(false)
                                    }
                                }}
                            />
                            <CustomIconButton
                                tabIndex={0}
                                onMouseDown={(event) => event.preventDefault()}
                                onClick={() => {
                                    const _linkUrlIsValid = linkUrlIsValid(linkUrl)
                                    setLinkUrlError(!_linkUrlIsValid)
                                    if (_linkUrlIsValid) {
                                        updateLink(linkUrl)
                                        setIsEditMode(false)
                                    }
                                }}
                                sx={{
                                    color: colours.base_blue,
                                    display: 'flex',
                                    height: 'auto',
                                    justifyContent: 'center',
                                    borderRadius: '4px',
                                    gap: '4px',
                                    padding: '2px',
                                    marginY: '4px',
                                    marginLeft: '4px'
                                }}
                                title='Save'
                            >
                                <CheckCircleOutlinedIcon />
                            </CustomIconButton>
                        </>
                    ) : (
                        <>
                            <Box
                                sx={{
                                    zIndex: 1300,
                                    flex: 1,
                                    width: '50%',
                                    display: 'block',
                                    margin: '8px 12px',
                                    padding: '8px 12px',
                                    borderRadius: '15px',
                                    backgroundColor: '#eee',
                                    fontSize: '15px',
                                    color: 'rgb(5,5,5)',
                                    border: 0,
                                    outline: 0,
                                    position: 'relative',
                                    fontFamily: 'inherit'
                                }}
                            >
                                {linkUrlIsEmpty ? (
                                    <Button
                                        sx={{
                                            width: '100%',
                                            textAlign: 'center'
                                        }}
                                        onClick={(e) => {
                                            if (linkUrlIsEmpty) {
                                                e.preventDefault()
                                                setIsEditMode(true)
                                            }
                                        }}
                                    >
                                        Click to edit
                                    </Button>
                                ) : (
                                    <Link
                                        target='_blank'
                                        rel='noopener'
                                        title={linkUrl}
                                        href={appContext.resolveFullUrl(linkUrl)}
                                        sx={{
                                            color: colours.base_blue,
                                            textDecoration: 'none',
                                            display: 'block',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            '&:hover': {
                                                textDecoration: 'underline',
                                                cursor: 'pointer'
                                            }
                                        }}
                                    >
                                        {transformDisplayLink?.(linkUrl) || linkUrl}
                                    </Link>
                                )}
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    height: 'auto',
                                    gap: '2px',
                                    paddingX: '2px'
                                }}
                            >
                                <CustomIconButton
                                    tabIndex={0}
                                    onMouseDown={(event) => event.preventDefault()}
                                    onClick={() => setIsEditMode(true)}
                                    title='Manually edit this link'
                                    sx={{ color: 'black', marginY: '4px', borderRadius: '4px', gap: '4px' }}
                                >
                                    <EditOutlinedIcon />
                                </CustomIconButton>
                            </Box>
                        </>
                    )}
                </Box>
                <Box display='flex' width='100%' justifyContent='flex-end' alignItems='center'>
                    <Typography>Open in a new tab:</Typography>
                    <LightTooltip title={"This setting may be overridden by your District's Configurations"}>
                        <Checkbox
                            sx={{ padding: '4px' }}
                            checked={linkAttributes?.target == '_blank'}
                            onChange={(e) => {
                                const newAttributes = {
                                    ...linkAttributes,
                                    target: e.target.checked == true ? '_blank' : undefined
                                }
                                setLinkAttributes(newAttributes)
                                updateLink(linkUrl, newAttributes)
                            }}
                        />
                    </LightTooltip>
                </Box>
                <Box display='flex' gap='8px' width='100%' justifyContent='center'>
                    <CustomIconButton
                        tabIndex={1}
                        onMouseDown={(event) => event.preventDefault()}
                        onClick={() => setContentPermalinkDialogIsOpen(true)}
                        title='Open content explorer to set a permalink'
                        sx={{ borderRadius: '4px', gap: '4px' }}
                    >
                        <ExpandCircleDownOutlinedIcon />
                    </CustomIconButton>
                    <CustomIconButton
                        tabIndex={2}
                        onMouseDown={(event) => event.preventDefault()}
                        onClick={() => setDocumentGalleryIsOpen(true)}
                        title='Open document gallery to set link to document'
                        sx={{ borderRadius: '4px', gap: '4px' }}
                    >
                        <AssignmentOutlinedIcon />
                    </CustomIconButton>
                    <CustomIconButton
                        tabIndex={3}
                        onMouseDown={(event) => event.preventDefault()}
                        onClick={() => setContactFormDialogIsOpen(true)}
                        title='Insert contact form link'
                        sx={{ borderRadius: '4px', gap: '4px' }}
                    >
                        <ContactMailIcon />
                    </CustomIconButton>
                </Box>
                <ContentExplorerDialog
                    isOpen={contentPermalinkDialogIsOpen}
                    onClose={() => setContentPermalinkDialogIsOpen(false)}
                    contentTypes={[ContentType.News, ContentType.Event, ContentType.Page]}
                    sites={[]}
                    onSelect={(id, content) => {
                        updateLink(getPermalink(id))
                        setIsEditMode(false)
                        setContentPermalinkDialogIsOpen(false)
                    }}
                    search={isPermalink(linkUrl) ? getIdFromPermalink(linkUrl)! : undefined}
                    selected={isPermalink(linkUrl) ? [getIdFromPermalink(linkUrl)!] : undefined}
                    structureID={null}
                />

                <DocumentGallery
                    isGalleryOpen={documentGalleryIsOpen}
                    setGalleryClose={() => setDocumentGalleryIsOpen(false)}
                    isForDct={true}
                    saveForDct={(doc) => {
                        updateLink(getLinkToDocument({ doc: doc, fullUrl: false, by: 'id' }))
                        setDocumentGalleryIsOpen(false)
                    }}
                />
                {contactFormDialogIsOpen && (
                    <SelectContactFormDialog
                        value={linkUrl}
                        open={contactFormDialogIsOpen}
                        onClose={() => setContactFormDialogIsOpen(false)}
                        onChange={(value) => {
                            updateLink(value)
                            setContactFormDialogIsOpen(false)
                            setIsEditMode(false)
                        }}
                    />
                )}
            </Box>
        )
    }
)

export default LinkEditor
