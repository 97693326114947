import { Content, PublishPeriod } from '../types'
import React, { useMemo, useState } from 'react'
import _ from 'lodash'
import { handlePreviewAtom } from '../../../common/components/selectors/SiteSelectorForContent'
import { useAtom } from 'jotai'
import { Card, FormControl, FormLabel } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import Button from '@mui/material/Button'
import PublishStatusLabel from './components/PublishStatusLabel'
import PublishOptionsRadio from './components/PublishOptionsRadio'
import { ColourHexValue, colourWithOpacity, colours } from '../../../common/colours'
import { useInputDisabler } from './imported-content/useInputDisabler'
import { useAppContext } from '../../auth/atoms'
import { DiffViewer } from '../../monaco/diff-viewer'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

export type publishStatus = 'draft' | 'published' | 'scheduled' | 'expired'
export const publishStatusColour: Record<publishStatus, string> = {
    draft: colours.warning,
    published: colours.published,
    scheduled: colours.base_blue,
    expired: colours.expired
}

export function getPublishStatus(
    publishAt: string | Date | null | undefined,
    expireAt: string | Date | null | undefined
): publishStatus {
    if (!publishAt) return 'draft'

    const now = new Date()
    if (publishAt && new Date(publishAt) > now) return 'scheduled'
    if (expireAt && new Date(expireAt) < now) return 'expired'
    if (publishAt) return 'published'
    return 'draft'
}

export const changeStatusLabel: { [key in publishStatus]: { [key in publishStatus]: string } } = {
    draft: {
        draft: 'Save as Draft',
        published: 'Publish',
        scheduled: 'Schedule',
        expired: 'Expire'
    },
    published: {
        draft: 'Unpublish and Save as Draft',
        published: 'Update Publication',
        scheduled: 'Schedule',
        expired: 'Expire'
    },
    scheduled: {
        draft: 'Unschedule and Save as Draft',
        published: 'Publish Now',
        scheduled: 'Schedule',
        expired: 'Expire'
    },
    expired: {
        draft: 'Save as Draft',
        published: 'Publish',
        scheduled: 'Schedule',
        expired: 'Expire'
    }
}

interface ContentEditorSaveCardProps<T extends Content> {
    serverValue: T
    value: T

    onAction(action: 'save' | 'publish' | 'draft', state: T): void

    hasExternalChanges: boolean
    disabled?: boolean
    onChange?: (v: PublishPeriod) => void
}

export function ContentEditorSaveCard<T extends Content>({
    serverValue,
    value,
    onAction,
    disabled,
    hasExternalChanges,
    onChange
}: ContentEditorSaveCardProps<T>) {
    const [changed, setChanged] = useState(false)
    const [changes, setChanges] = useState<any>(undefined)

    const [handlePreview] = useAtom(handlePreviewAtom)

    const publishStatus = useMemo(() => getPublishStatus(value.PublishAt, value.ExpireAt), [value])

    const refreshChangesThrottled = () => {
        let stateWithoutUpdated = omitIrrelevantProps(serverValue)
        let originalStateWithoutUpdated = omitIrrelevantProps(value)

        const changed = hasExternalChanges || !_.isEqual(stateWithoutUpdated, originalStateWithoutUpdated)
        setChanged(changed)

        // Uncomment for debugging:
        // changed && console.log('-------------------- difference -----------------')
        // changed && console.log(differenceObject(stateWithoutUpdated, originalStateWithoutUpdated))
    }

    React.useEffect(() => {
        refreshChangesThrottled()
    }, [value, serverValue, hasExternalChanges])

    const current = getPublishStatus(value.PublishAt, value.ExpireAt)
    const original = getPublishStatus(serverValue.PublishAt, serverValue.ExpireAt)
    const createLabel = () => {
        return changeStatusLabel[original][current]
    }
    const evaluators = useAppContext()
    const hasPermission = React.useMemo(() => evaluators.action(value, 'update'), [value])
    const { isInputDisabled, isFullyDisabled } = useInputDisabler({ content: value, hasPermission })

    return (
        <>
            <div style={{ position: 'fixed', bottom: '2vh', right: '1vw', width: '400px', zIndex: 1001 }}>
                {changed ? (
                    <Button
                        color={'warning'}
                        variant={'contained'}
                        fullWidth
                        onClick={() => {
                            let stateWithoutUpdated = omitIrrelevantProps(serverValue)
                            let originalStateWithoutUpdated = omitIrrelevantProps(value)
                            /* Check:
                             * 1. Have a published page
                             * 2. Set to Draft using radio
                             * 3. Set to Published using radio
                             * 4. Set to Draft using Radio
                             * 5. Set to Published using radio
                             * 6. Click "View Changes"
                             * 7. Investigate thrown errors
                             * */
                            const c1 = differenceObject(stateWithoutUpdated, originalStateWithoutUpdated)
                            const c2 = differenceObject(originalStateWithoutUpdated, stateWithoutUpdated)
                            setChanges({ Before: c1, After: c2 })
                        }}
                    >
                        You have unsaved changes
                    </Button>
                ) : null}
                <Card style={{ padding: '0.75em', overflow: 'visible' }}>
                    <FormControl variant='standard' component='fieldset' style={{ width: '100%' }}>
                        <div className='flex-row-align-center' style={{ justifyContent: 'space-between' }}>
                            <FormLabel component='legend' sx={{ display: 'flex', alignItems: 'center' }}>
                                Status:&nbsp;
                                <PublishStatusLabel publishAt={value.PublishAt} expireAt={value.ExpireAt} />
                            </FormLabel>
                            <FormLabel component='legend'></FormLabel>
                            <VisibilityIcon
                                style={{
                                    color: colourWithOpacity(publishStatusColour[current] as ColourHexValue, 0.85),
                                    fontSize: '2em',
                                    cursor: 'pointer'
                                }}
                                sx={{
                                    '&:hover': {
                                        backgroundColor: colourWithOpacity(
                                            publishStatusColour[current] as ColourHexValue,
                                            0.15
                                        ),
                                        color: publishStatusColour[current],
                                        borderRadius: '8px'
                                    }
                                }}
                                onClick={() =>
                                    handlePreview({
                                        id: value.ID,
                                        type: value.Type,
                                        route: value.Route,
                                        sites: value.Sites
                                    })
                                }
                            />
                        </div>
                        <PublishOptionsRadio
                            value={value}
                            onChange={(v) => {
                                onChange?.(v)
                            }}
                            disabled={Boolean(isInputDisabled('published') || disabled)}
                        />
                        <div className='flex-row-align-center' style={{ justifyContent: 'space-between' }}>
                            <Button
                                variant='contained'
                                sx={{
                                    backgroundColor: colourWithOpacity(
                                        publishStatusColour[publishStatus] as ColourHexValue,
                                        0.9
                                    ),
                                    '&:hover': {
                                        backgroundColor: publishStatusColour[publishStatus]
                                    }
                                }}
                                style={{ marginTop: '5px', marginBottom: '5px', marginRight: '5px', width: '100%' }}
                                onClick={() => onAction('save', value)}
                                disabled={Boolean(disabled) || isFullyDisabled}
                            >
                                {createLabel()}
                            </Button>
                        </div>
                    </FormControl>
                </Card>
            </div>

            {Boolean(changes) && (
                <Dialog open={Boolean(changes)} onClose={() => setChanges(undefined)} fullWidth maxWidth={'xl'}>
                    <DialogContent sx={{ minHeight: '600px' }}>
                        <DiffViewer original={changes?.Before} modified={changes?.After} />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setChanges(undefined)
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    )
}

function differenceObject(object: any, base: any) {
    function changes(object: any, base: any) {
        return _.transform(object, (result: any, value, key) => {
            if (!_.isEqual(value, base[key])) {
                if (_.isObject(value) && _.isObject(base[key])) {
                    if (value instanceof Date && base[key] instanceof Date) {
                        if (value.getTime() !== base[key].getTime()) {
                            result[key] = value
                        }
                    } else {
                        result[key] = changes(value, base[key])
                    }
                } else {
                    result[key] = value
                }
            }
        })
    }

    return changes(object, base)
}

function omitIrrelevantProps(value: any) {
    // Specify the keys to omit directly
    const directlyOmittedKeys = ['Created', 'Deleted', 'Updated', 'Publisher', 'Owner']

    const allPaths = rKeys(value)

    // From the user perspective we can ingore the Data.*.*.json and Data.*.*.engine keys (the relevant information is in the Data.*.*.html key)
    // Collect keys that match the Data.*.*.json pattern
    const isDataJsonKey = (key: string): boolean => /^Data\.[^.]+\.[^.]+\.json/.test(key)
    const dataJsonKeys = allPaths.filter((key) => isDataJsonKey(key)).map((key) => key.split('.json.')[0] + '.json')

    // Collect keys that match the Data.*.*.engine pattern
    const isEngineJsonKey = (key: string): boolean => /^Data\.[^.]+\.[^.]+\.engine/.test(key)
    const engineJsonKeys = allPaths.filter((key) => isEngineJsonKey(key))

    // Combine the directly omitted keys with the dynamically found keys
    const keysToOmit = [...directlyOmittedKeys, ...[...new Set(dataJsonKeys)], ...[...new Set(engineJsonKeys)]]
    // console.log('dataJsonKeys', dataJsonKeys)

    // Omit the specified keys from the value
    return _.omit(value, keysToOmit)
}

function rKeys(o: any) {
    if (!o || typeof o !== 'object') return []

    const paths: string[] = []
    const stack: { obj: any; path: string[] }[] = [{ obj: o, path: [] }]

    while (stack.length > 0) {
        const { obj, path } = stack.pop() as { obj: any; path: string[] }

        if (typeof obj === 'object' && obj !== null) {
            for (const key in obj) {
                stack.push({ obj: obj[key], path: [...path, key] })
            }
        } else {
            paths.push(path.join('.') + '')
        }
    }

    return paths
}
