import React, { useContext, useMemo } from 'react'
import { DocumentRow } from './DocumentRow'
import { is } from '../../../../helpers'
import { ToastContainer } from 'react-toastify'
import { documentContext } from '../context'

const DocumentOutput = ({ handleEdit, handleDelete, isForDct, handleSave, enableBulkSelection, isEnabled }) => {
    const [documents] = useContext(documentContext)

    const selectDocument = is.func(handleSave) ? handleSave : undefined

    return (
        <div className='flex-column'>
            <ToastContainer />
            <div className='document-gallery'>
                {useMemo(() => {
                    return documents?.map((document) => {
                        return (
                            <DocumentRow
                                key={document.filename + document.id}
                                document={document}
                                isForDct={isForDct}
                                showTimestamp={true}
                                handleEdit={handleEdit}
                                handleRemove={handleDelete}
                                selectDocument={selectDocument}
                                enableBulkSelection={enableBulkSelection}
                            />
                        )
                    })
                }, [documents, handleEdit])}

                {isEnabled && documents && documents.length === 0 && (
                    <div className='row' style={{ justifyContent: 'center' }}>
                        <p>No documents found</p>
                    </div>
                )}
            </div>
        </div>
    )
}
export default DocumentOutput
