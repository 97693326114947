import React, { Dispatch, useCallback, useMemo, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import { ImageGallery } from '../media/image/ImageGallery'
import DocumentGallery from '../media/document/DocumentGallery'
import getJsonDctSchema from '../../helpers/getJsonDctSchema'
import DctComponentList from './DctComponentList'
import AppAccordion from '../../common/components/AppAccordion'
import { Typography } from '@mui/material'
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault'
import { colours } from '../../common/colours'
import 'react-toastify/dist/ReactToastify.css'
import { useGetLinkToDocument } from '../media/copyToClipboard'

interface DctSectionProps {
    expanded?: boolean
    expandedOnChangeHandler?: (expanded: boolean) => void
    disabled: boolean
    section: any
    contentModelData: any
    contentModelDataOnChange: (value: any) => void
    contentModelDataErrors: Record<string, any>
    setContentModelDataErrors: Dispatch<Record<string, any>>
}

function getDefaultValueByComponentType(type: string) {
    if (type === 'rich-text') {
        return { html: '' }
    }
    if (type === 'image') {
        return { src: '', alt: '' }
    }
    return ''
}

export function DctSection({
    expanded,
    expandedOnChangeHandler,
    disabled,
    section,
    contentModelData,
    contentModelDataOnChange,
    contentModelDataErrors,
    setContentModelDataErrors
}: DctSectionProps) {
    const getLinkToDocument = useGetLinkToDocument()
    const sectionComponents = getJsonDctSchema(section.components)

    const [isImageGalleryOpen, setIsImageGalleryOpen] = useState(false)
    const [currentComponent, setCurrentComponent] = useState<any | null>(null)
    const [isDocumentGalleryOpen, setIsDocumentGalleryOpen] = useState(false)
    const emptySectionData = useMemo(
        () =>
            sectionComponents.reduce(
                (a, component) => ({
                    ...a,
                    [component.name]: getDefaultValueByComponentType(component.type)
                }),
                {} as Record<string, any>
            ),
        [sectionComponents]
    )

    const updateContentModelData = useCallback(
        (field: string, value: any) => {
            contentModelDataOnChange({
                // exp
                // ...(contentModelData || {}),
                ...(contentModelData?.[section.name] || {}),
                [field]: value
            })
        },
        [contentModelData, section.name, contentModelDataOnChange]
    )

    const updateContentModelDataErrors = useCallback(
        (field: string, value: boolean) => {
            setContentModelDataErrors((p) => ({
                ...p,
                [section.name]: {
                    // exp
                    // ...contentModelDataErrors,
                    ...contentModelDataErrors?.[section.name],
                    [field]: value
                }
            }))
        },
        [contentModelDataErrors, section.name, setContentModelDataErrors]
    )

    // exp
    // const localState = Object.keys(contentModelData || {}).length
    //     ? contentModelData
    //     : emptySectionData
    const localState = Object.keys(contentModelData?.[section.name] || {}).length
        ? contentModelData?.[section.name]
        : emptySectionData

    const errorState = contentModelDataErrors?.[section.name]

    const insertIntoCard = (img) => {
        if (currentComponent) {
            const { alt, id } = img
            // setErrorState(prev => ({ ...prev, [currentComponent.name]: false }))
            updateContentModelDataErrors(currentComponent.name, false)
            updateContentModelData(currentComponent.name, {
                src: `/images/${id}`,
                alt
            })
        }
    }
    const insertDocumentIntoComponentState = (document, isFolder) => {
        if (document && document.id && currentComponent) {
            updateContentModelData(
                currentComponent.name,
                getLinkToDocument({ doc: document, fullUrl: false, by: 'id' })
            )
            setIsDocumentGalleryOpen(false)
        }
    }

    return (
        <>
            <ToastContainer />
            <AppAccordion
                expanded={expanded || false}
                summary={
                    <Typography
                        component='p'
                        variant='h5'
                        sx={
                            disabled
                                ? {
                                      color: colours.disabled
                                  }
                                : undefined
                        }
                    >
                        {section.title}
                        {disabled ? (
                            <DisabledByDefaultIcon sx={{ fontSize: '16px' }} titleAccess='Disabled' />
                        ) : undefined}
                    </Typography>
                }
                onChangeHandler={expandedOnChangeHandler}
                details={
                    <>
                        {section.description && <p className='no-margin  mb10'>{section.description}</p>}
                        <DctComponentList
                            sectionComponents={sectionComponents}
                            disabled={disabled}
                            localState={localState}
                            setLocalState={(field: string, value: any) => updateContentModelData(field, value)}
                            errorState={errorState}
                            setErrorState={(field: string, value: boolean) =>
                                updateContentModelDataErrors(field, value)
                            }
                            imageGalleryOpenHandler={setIsImageGalleryOpen}
                            currentComponentOnChangeHandler={setCurrentComponent}
                            documentGalleryOpenHandler={setIsDocumentGalleryOpen}
                        />
                    </>
                }
            />

            {isImageGalleryOpen && !disabled && (
                <ImageGallery
                    open={isImageGalleryOpen}
                    close={() => setIsImageGalleryOpen(false)}
                    onChange={insertIntoCard}
                />
            )}

            {isDocumentGalleryOpen && !disabled && (
                <DocumentGallery
                    isForDct
                    saveForDct={insertDocumentIntoComponentState}
                    isGalleryOpen={isDocumentGalleryOpen}
                    setGalleryClose={() => setIsDocumentGalleryOpen(false)}
                />
            )}
        </>
    )
}
