import { CODE_LANGUAGE_FRIENDLY_NAME_MAP } from '@lexical/code'
import { IconButton, Typography } from '@mui/material'
import { colours } from '../../../common/colours'
import { useEffect, useRef, useState } from 'react'
import { positionFloatingMenu } from './toolbar.helpers'

function getCodeLanguageOptions(): [string, string][] {
    const options: [string, string][] = []

    for (const [lang, friendlyName] of Object.entries(CODE_LANGUAGE_FRIENDLY_NAME_MAP)) {
        options.push([lang, friendlyName])
    }

    return options
}

const CODE_LANGUAGE_OPTIONS = getCodeLanguageOptions()

interface CodeLanguagesDropdownListProps {
    value: string
    onChange: (value: string) => void
}
export default function CodeLanguagesDropdownList({ value, onChange }: CodeLanguagesDropdownListProps) {
    const anchorButtonRef = useRef(null)
    const dropdownRef = useRef(null)
    const [isCodeLanguageDropdownOpen, setIsCodeLanguageDropdownOpen] = useState(false)

    useEffect(() => {
        positionFloatingMenu(dropdownRef?.current, anchorButtonRef?.current, 'bottom-start')
    }, [anchorButtonRef, isCodeLanguageDropdownOpen])

    return (
        <>
            <IconButton
                ref={anchorButtonRef}
                className='toolbar-item block-controls'
                disabled={false}
                onClick={() => {
                    setIsCodeLanguageDropdownOpen(!isCodeLanguageDropdownOpen)
                }}
                aria-label='Formatting Options'
            >
                <Typography className='text'>
                    {CODE_LANGUAGE_FRIENDLY_NAME_MAP?.[value] || value.toUpperCase()}
                </Typography>
                <span className='material-icons-outlined'>expand_more</span>
            </IconButton>
            {isCodeLanguageDropdownOpen && (
                <div className='dropdown' ref={dropdownRef}>
                    {CODE_LANGUAGE_OPTIONS.map(([_value, name]) => {
                        const isActive = _value == value

                        return (
                            <IconButton
                                className={`item`}
                                style={
                                    isActive
                                        ? {
                                              backgroundColor: colours.off_white
                                          }
                                        : undefined
                                }
                                onClick={() => {
                                    onChange(_value)
                                    setIsCodeLanguageDropdownOpen(false)
                                }}
                                key={_value}
                            >
                                <span className='text'>{name}</span>
                            </IconButton>
                        )
                    })}
                </div>
            )}
        </>
    )
}
