import { useState } from 'react'
import {
    Card,
    CardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    TextField
} from '@mui/material'
import { ToastContainer } from 'react-toastify'
import { notify } from '../../helpers'
import TableHeader from '../../common/components/TableHeader'
import CreateIcon from '@mui/icons-material/Create'
import DeleteIcon from '@mui/icons-material/Delete'
import DialogFormAction from '../../common/components/DialogFormAction'
import ErrorIcon from '@mui/icons-material/Error'
import ConfirmAction from '../../common/components/ConfirmAction'
import { SiteSelectorForContent } from '../../common/components/selectors/SiteSelectorForContent'
import { useCurrentSiteID } from '../auth/atoms'
import { EntityScopeEnum } from '../auth/entityScope'
import { useBusAreaQueryMutation, useBusRouteQueryMutation } from './queries'

export const BusRoutes = (props) => {
    const { open, classes, handleClose, handleTextField, handleSelectWithName } = props
    const currentSiteID = useCurrentSiteID()
    const [isAllChecked, setIsAllChecked] = useState(false)

    const { busAreaData, getAreaIdToArea } = useBusAreaQueryMutation(currentSiteID)
    const areaIdToAreaMap = getAreaIdToArea()

    const {
        busRouteData,
        refetch,
        error,
        createMutation: createBusRouteMutation,
        editMutation: editBusRouteMutation,
        deleteMutation: deleteBusRouteMutation
    } = useBusRouteQueryMutation(
        currentSiteID,
        !isAllChecked,
        () => {
            notify('Success! Bus Route has been created', 'info')
            handleClose()
            resetFields('create')
            refetch()
        },
        undefined,
        () => {
            notify('Success! Bus Route has been updated', 'info')
            handleDialogueDisagree()
            resetFields('edit')
            refetch()
        },
        undefined,
        () => {
            notify('Success! Bus Route has been deleted', 'info')
            handleDialogueDisagree()
            setEditState({
                name: '',
                id: ''
            })
            refetch()
        },
        undefined
    )

    // Dialog States
    const [editDialogOpen, setEditDialogOpen] = useState(false)
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

    // Create
    const [errors, setErrors] = useState({
        name: false,
        sites: false,
        areas: false
    })
    const [createState, setCreateState] = useState({
        name: ''
    })

    // array of ids
    const [chosenSites, setChosenSites] = useState([])
    const [chosenAreas, setChosenAreas] = useState([])

    // Edit
    const [editState, setEditState] = useState({
        id: '',
        name: ''
    })

    const validate = (state) => {
        let util = true
        let arr = Object.keys(state)

        for (const item of arr) {
            if (state[item].length === 0) {
                setErrors((prev) => ({
                    ...prev,
                    [item]: true
                }))
                util = false
            }
        }

        return Boolean(util)
    }

    const resetFields = (whichState) => {
        setChosenAreas([])
        setChosenSites([])
        if (whichState === 'create') {
            setCreateState((prev) => ({
                ...prev,
                name: ''
            }))
        } else if (whichState === 'edit') {
            setEditState({
                id: '',
                name: ''
            })
        }
    }

    // CRUD
    const handleCreate = () => {
        const obj = {
            name: createState.name,
            sites: chosenSites,
            areas: chosenAreas
        }
        if (!validate(obj)) {
            return notify('Whoops! Some values are missing', 'error')
        }
        createBusRouteMutation.mutate(obj)
    }

    const handleEdit = () => {
        const obj = {
            name: editState.name,
            sites: chosenSites,
            areas: chosenAreas
        }
        if (!validate(obj)) {
            return notify('Whoops! Some values are missing', 'error')
        }
        editBusRouteMutation.mutate({ ...obj, id: editState.id, active: true })
    }

    const handleDelete = () => {
        deleteBusRouteMutation.mutate(editState.id)
    }

    // Generics
    const handleDialogueDisagree = () => {
        setEditDialogOpen(false)
        setDeleteDialogOpen(false)
        resetFields('edit')
        if (errors.name) {
            setErrors((prev) => ({
                ...prev,
                name: false
            }))
        }
    }

    // Icons found on rows
    const Icons = (routeData) => {
        const handleEditOpen = () => {
            setEditDialogOpen(true)
            setEditState({
                id: routeData.id,
                name: routeData.name
            })
            setChosenSites(routeData.sites)
            setChosenAreas(routeData.areas)
        }

        const handleDeleteOpen = () => {
            setEditState(routeData)
            setDeleteDialogOpen(true)
        }

        return (
            <div>
                {routeData.areas.length === 0 && (
                    <div className='tooltip'>
                        <ErrorIcon className='create-icon' style={{ color: '#f3004f' }} />
                        <span className='tooltiptext'>This route has no assigned area</span>
                    </div>
                )}
                <CreateIcon onClick={handleEditOpen} className='create-icon' />
                <DeleteIcon onClick={handleDeleteOpen} className='create-icon' />
            </div>
        )
    }

    return (
        <div>
            <Card className='sub-container'>
                <CardContent>
                    <div className='flex-row' style={{ justifyContent: 'space-between' }}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={() => {
                                            setIsAllChecked(!isAllChecked)
                                        }}
                                        checked={isAllChecked}
                                        name='first'
                                    />
                                }
                                label='Show All District Routes'
                            />
                        </FormGroup>
                    </div>

                    <ToastContainer />

                    {!busRouteData || busRouteData.length < 1 ? (
                        <p className='empty-section'>There are no routes associated with this school</p>
                    ) : (
                        <div className='table-container'>
                            <TableHeader first='Route Name' second='Options' alternative={true} />
                            {busRouteData.map((routeData) => (
                                <div key={routeData?.name + routeData?.id} className='table-item'>
                                    <p className='site-name'>{routeData.name}</p>
                                    <div className='icon site-options'>{Icons(routeData)}</div>
                                </div>
                            ))}
                        </div>
                    )}
                </CardContent>
            </Card>

            {/*Create*/}
            <DialogFormAction
                item={
                    <div>
                        <TextField
                            variant='standard'
                            error={errors.name}
                            value={createState.name}
                            name='name'
                            onChange={(event) => handleTextField(event, setCreateState, setErrors)}
                            className={classes.textfield}
                            id='standard-basic'
                            label='Name'
                        />
                        <div style={{ marginTop: '1em' }}>
                            <SiteSelectorForContent
                                Selected={chosenSites}
                                ContentType={EntityScopeEnum.BusRoute}
                                OnChange={(sites) => {
                                    if (sites?.length) {
                                        setErrors((prev) => ({ ...prev, sites: sites.length === 0 }))
                                    }
                                    setChosenSites(sites)
                                }}
                                HasError={errors.sites}
                            />
                        </div>
                        <FormControl variant={'outlined'} className={classes.form}>
                            <InputLabel error={errors.areas}>Areas</InputLabel>
                            <Select
                                variant={'outlined'}
                                multiple
                                error={errors.areas}
                                name='areas'
                                value={chosenAreas}
                                onChange={(event) => handleSelectWithName(event, setChosenAreas, setErrors)}
                                input={<OutlinedInput label={'Areas'} />}
                                renderValue={(areaIds) => {
                                    console.log({ areaIds, areaIdToAreaMap })
                                    return areaIds.map((id) => areaIdToAreaMap[id].name).join(', ')
                                }}
                            >
                                {areaIdToAreaMap &&
                                    Object.keys(areaIdToAreaMap).map((areaId) => (
                                        <MenuItem key={areaId} value={areaId}>
                                            <Checkbox checked={chosenAreas.includes(areaId)} />
                                            <ListItemText primary={areaIdToAreaMap[areaId].name} />
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </div>
                }
                title='Create a bus route'
                // text="What would you like to create?"
                buttonAgreeLabel='Create'
                buttonDisagreeLabel='Cancel'
                open={open}
                handleDisagree={handleClose}
                handleAgree={handleCreate}
                handleClose={handleClose}
                fullWidth={true}
            />

            {/*Edit*/}
            <DialogFormAction
                item={
                    <div>
                        <TextField
                            variant='standard'
                            error={errors.name}
                            value={editState.name}
                            name='name'
                            onChange={(event) => handleTextField(event, setEditState, setErrors)}
                            className={classes.textfield}
                            id='standard-basic'
                            label='Name'
                        />

                        <div className='flex-row' style={{ justifyContent: 'space-between' }}>
                            <FormControl variant='standard' className={classes.formWithSpace}>
                                <SiteSelectorForContent
                                    Selected={chosenSites}
                                    ContentType={EntityScopeEnum.BusRoute}
                                    OnChange={(sites) => {
                                        if (sites?.length) {
                                            setErrors((prev) => ({ ...prev, sites: sites.length === 0 }))
                                        }
                                        setChosenSites(sites)
                                    }}
                                    HasError={errors.sites}
                                />
                            </FormControl>
                        </div>

                        <FormControl variant={'outlined'} className={classes.form}>
                            <InputLabel error={errors.areas}>Areas</InputLabel>
                            <Select
                                variant={'outlined'}
                                multiple
                                error={errors.areas}
                                name='areas'
                                value={chosenAreas}
                                onChange={(event) => handleSelectWithName(event, setChosenAreas, setErrors)}
                                input={<OutlinedInput label={'Areas'} />}
                                renderValue={(areaIds) => {
                                    console.log({ areaIds, areaIdToAreaMap })
                                    return areaIds.map((id) => areaIdToAreaMap[id].name).join(', ')
                                }}
                            >
                                {areaIdToAreaMap &&
                                    Object.keys(areaIdToAreaMap).map((areaId) => (
                                        <MenuItem key={areaId} value={areaId}>
                                            <Checkbox checked={chosenAreas.includes(areaId)} />
                                            <ListItemText primary={areaIdToAreaMap[areaId].name} />
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </div>
                }
                title='Edit a bus route'
                // text="What would you like to create?"
                buttonAgreeLabel='Save'
                buttonDisagreeLabel='Cancel'
                open={editDialogOpen}
                handleDisagree={handleDialogueDisagree}
                handleAgree={handleEdit}
                handleClose={handleDialogueDisagree}
                fullWidth={true}
            />

            {/*Delete*/}
            <ConfirmAction
                open={deleteDialogOpen}
                title='Are you sure?'
                text="Please confirm if you'd like to delete this area"
                handleDisagree={handleDialogueDisagree}
                handleAgree={handleDelete}
                handleClose={handleDialogueDisagree}
            />
        </div>
    )
}
export default BusRoutes
