import { CSSProperties, useEffect, useMemo, useState } from 'react'
import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { colours } from '../../common/colours'
import { useStateWithStorage } from '../../common/storage.service'
import { useAppContext } from '../auth/atoms'

const customMarkdownStyles: Record<string, CSSProperties> = {
    img: {
        maxWidth: '100%'
    },
    h1: {
        paddingBottom: '14px',
        borderBottom: `1px solid ${colours.off_white_but_darker}`
    },
    li: {
        fontWeight: 400,
        paddingTop: '4px'
    }
}

export function ReleaseNoteItem({ notes }: { notes: string | null }) {
    return (
        <Markdown
            className={'reactMarkdown'}
            rehypePlugins={[rehypeRaw]}
            components={{
                img: ({ node, ...props }) => <img style={customMarkdownStyles.img} {...props} />,
                h1: ({ node, ...props }) => <h1 style={customMarkdownStyles.h1} {...props} />,
                h2: ({ node, ...props }) => <h2 style={customMarkdownStyles.h2} {...props} />,
                h3: ({ node, ...props }) => <h3 style={customMarkdownStyles.h3} {...props} />,
                li: ({ node, ...props }) => <li style={customMarkdownStyles.li} {...props} />
            }}
        >
            {notes}
        </Markdown>
    )
}

// run in ApplicationWrapper.tsx on load
export function useReleaseNotesIsRead() {
    const latestReleaseNotes = useLatestReleaseNotes()
    const firstLine = useMemo(() => latestReleaseNotes?.split('\n')[0], [latestReleaseNotes])
    const [storedReleaseNotes, setStoredReleaseNotes] = useStateWithStorage('latest-release-notes-read', {
        isRead: !firstLine,
        checkValue: firstLine || ''
    })

    useEffect(() => {
        if (firstLine && storedReleaseNotes.checkValue != firstLine) {
            setStoredReleaseNotes({
                isRead: false,
                checkValue: firstLine
            })
        }
    }, [firstLine, storedReleaseNotes])

    function read() {
        setStoredReleaseNotes((p) => ({
            ...p,
            isRead: true
        }))
    }

    return { isRead: storedReleaseNotes.isRead, read }
}

// returns ./ReleaseNotes.md as text
function useLatestReleaseNotes() {
    const [notes, setNotes] = useState<string | null>(null)

    useEffect(() => {
        async function loadNotes() {
            try {
                const response = await fetch(window.location.origin + '/releaseNotes/releasenotes/ReleaseNotes.md')
                if (response.ok) {
                    const text = await response.text()
                    setNotes(text)
                } else {
                    console.warn('Failed to load latest release notes')
                }
            } catch (err) {
                console.warn(err)
            }
        }

        loadNotes()
    }, [])

    return notes
}

function ReleaseNotes() {
    const notes = useLatestReleaseNotes()

    return <ReleaseNoteItem notes={notes} />
}

export default ReleaseNotes
