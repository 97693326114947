import React, { useState } from 'react'

const fullAccessFoldersContext = React.createContext([{}, (r) => {}])

const FullAccessFoldersContextProvider = (props) => {
    const [fullAccessFolders, setFullAccessFolders] = useState([])
    return (
        <fullAccessFoldersContext.Provider value={[fullAccessFolders, setFullAccessFolders]}>
            {props.children}
        </fullAccessFoldersContext.Provider>
    )
}
export { fullAccessFoldersContext, FullAccessFoldersContextProvider }
