import { Account, Group } from '../types'
import React, { useEffect } from 'react'
import { useDebounce } from '../../../common/useDebounce'
import { GridColDef } from '@mui/x-data-grid'
import { CellLine, CellWrapper, IDToNameCell, TwoLinesCell } from '../../grid/cells/GridCells'
import { AssignmentCell } from './AssignmentCell'
import { Alert, MenuItem, Select, TextField } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import { guessErrorMessage } from '../../../helpers/guessErrorMessage'
import { defaultPageQuery } from '../../../common/react-query'
import { DataGridBase } from '../../grid/DataGridBase'
import { GroupsForAssignmentQuery, useGroupsForAssignment } from '../queries'

interface AssignGroupsProps {
    account: Account
    forType: 'native' | 'manual'
    onChange: () => void
}

export const AssignGroups = ({ account, forType, onChange }: AssignGroupsProps) => {
    const assigned = forType === 'native' ? account.NativeGroups : account.ManualGroups
    const validGroups =
        account.Groups?.reduce((acc, g) => {
            acc[g.ID] = g
            return acc
        }, new Map<string, Group>()) || new Map<string, Group>()
    const defaultQuery: GroupsForAssignmentQuery = {
        ...defaultPageQuery,
        pageSize: 100,
        Search: '',
        Assignment: 'assigned',
        Account: account.ID,
        Assigned: assigned,
        ForType: forType
    } as GroupsForAssignmentQuery
    const [query, setQuery] = React.useState<GroupsForAssignmentQuery>(defaultQuery)
    const debouncedQuery = useDebounce(query, 300)

    const q = useGroupsForAssignment(debouncedQuery)

    useEffect(() => {
        // @ts-ignore
        q.refetch({ options: debouncedQuery })
    }, [debouncedQuery])

    useEffect(() => {
        setQuery({ ...query, Assigned: assigned })
    }, [assigned])

    const columns: GridColDef[] = [
        {
            field: 'Name',
            headerName: 'Name/ID',
            flex: 2,
            sortable: true,
            renderCell: ({ row }) => <TwoLinesCell l1={`${row.Name}`} l2={row.ID} />
        },
        {
            field: 'Type',
            headerName: 'Type',
            width: 100,
            sortable: false,
            renderCell: ({ row }) => (
                <CellWrapper>
                    <CellLine>{row.Type}</CellLine>
                </CellWrapper>
            )
        },
        {
            field: 'Site',
            headerName: 'Site',
            width: 300,
            sortable: false,
            filterable: false,
            renderCell: ({ row }) => <IDToNameCell tableName={'site'} ID={row.SiteID} />
        },
        {
            field: 'Role',
            headerName: 'Role',
            flex: 2,
            sortable: false,
            renderCell: ({ row }) => (
                <CellWrapper>
                    <CellLine>{row.Role.name}</CellLine>
                </CellWrapper>
            )
        },
        // { field: 'UpdatedAt', headerName: 'Updated/by', width: 300, sortable: true, renderCell: (params) => <TrackableCell trackable={params.row} ownerID={params.row.ID} /> },
        {
            field: 'Assign',
            headerName: 'Assigned',
            width: 150,
            sortable: false,
            renderCell: ({ row }) => (
                <AssignmentCell
                    onChange={onChange}
                    groupID={row.ID}
                    accountID={account.ID}
                    forType={forType}
                    initialState={assigned.indexOf(row.ID) > -1}
                />
            )
        }
    ]

    return (
        <>
            <div className={'container'}>
                <div className={'row'}>
                    <div className={'col-md-8'}>
                        <TextField
                            data-testid={`${forType}-group-search`}
                            style={{ flex: '1' }}
                            onChange={(e) => setQuery({ ...query, Search: e.target.value })}
                            value={query.Search}
                            label={'Search'}
                            placeholder='Search'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position={'start'}>
                                        <SearchIcon></SearchIcon>
                                    </InputAdornment>
                                )
                            }}
                            variant={'outlined'}
                        />
                    </div>
                    <div className={'col-md-4'}>
                        <Select
                            label={'State'}
                            id='demo-simple-select'
                            data-testid={`${forType}-state-select`}
                            value={query.Assignment}
                            onChange={(e) => setQuery({ ...query, Assignment: e.target.value as any })}
                        >
                            <MenuItem value={'assigned'}>assigned</MenuItem>
                            <MenuItem value={'unassigned'}>unassigned</MenuItem>
                            <MenuItem value={'all'}>all</MenuItem>
                        </Select>
                    </div>
                </div>
                {q.isLoading && <div>Loading...</div>}
                {q.isError && (
                    <Alert severity={'error'}>Error loading groups for assignment: {guessErrorMessage(q.error)}</Alert>
                )}
                {q.data && (
                    <DataGridBase
                        loading={q.isLoading || q.isRefetching}
                        columns={columns}
                        state={q.data}
                        // @ts-ignore
                        setQuery={setQuery}
                        getRowClassName={({ row }) => (row.Active ? 'active' : 'inactive')}
                    />
                )}
            </div>
        </>
    )
}
