//@ts-nocheck
import React, { useContext, useEffect, useState } from 'react'

/** Emitter State */
const emittedEventContext = React.createContext([{}, (p) => null])
const EmittedEventContextProvider = (props) => {
    const [emittedEvent, emitEvent] = useState<Record<string, any> | null>(null)
    return (
        <emittedEventContext.Provider value={[emittedEvent, emitEvent]}>{props.children}</emittedEventContext.Provider>
    )
}
/** Emitter
 *  Events should be named consistently in PascalCase
 *  (e.g) a 'tree change' event should be named "TreeChange"
 * */

export const useEmitter = (listenTo?: string[]) => {
    const [event, emitEvent] = useContext(emittedEventContext)
    const [eventClone, setEventClone] = useState<Record<string, any> | null>(null)

    useEffect(() => {
        if (event && (!listenTo || (listenTo && event?.message?.includes(listenTo)))) {
            setEventClone(event)
        }
    }, [event])

    function done() {
        setEventClone(null)
        //@ts-ignore
        emitEvent(null)
    }

    function test(arr: Record<string, any>[], key?: string) {
        const id = event?.id || event
        if (!id) return -1
        const i = arr.findIndex((x) => (x?.[key] || x?.id || x) === id)
        return i
    }

    return { event: eventClone, done, test }
}
export { emittedEventContext, EmittedEventContextProvider }
