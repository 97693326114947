import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Button, CardActions, CardMedia, Checkbox, Chip, IconButton, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { notify, renderLastModified } from '../../../../helpers'
import { selectedDocumentContext } from '../context'
import { LoadingButton } from '@mui/lab'
import PdfSVG from '../../../../assets/pdf_logo.svg'
import MsSVG from '../../../../assets/mso.svg'
import 'flexboxgrid'
import '../../../../app/App.css'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import CreateIcon from '@mui/icons-material/Create'
import DeleteIcon from '@mui/icons-material/Delete'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import UploadIcon from '@mui/icons-material/Upload'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { useAppContext } from '../../../auth/atoms'
import { colours } from '../../../../common/colours'
import SplitButton from '../../../../common/components/SplitButton'
import { copyToClipboard, useGetLinkToDocument } from '../../copyToClipboard'
import { AddLocationAltRounded, FolderZip, InsertDriveFile } from '@mui/icons-material'

const documentRowConfig = {
    buttonDefaults: {
        variant: 'outlined',
        size: 'small',
        color: 'primary',
        children: <span>Save</span>
    },
    maxUploadAttempts: 3
}

export const DocumentRow = React.memo(
    ({
        document,
        handleEdit,
        handleSave,
        handleRemove,
        error,
        showTimestamp,
        selectDocument,
        isForDct,
        enableBulkSelection
    }) => {
        const evaluators = useAppContext()
        const [canUpdate, canDelete] = useMemo(
            () => [
                /*In these two cases, some variables are missing, causing evaluate.action to throw errors*/
                document?.data || isForDct ? true : evaluators.action(document, 'update'),
                document?.data || isForDct ? true : evaluators.action(document, 'delete')
            ],
            [evaluators, document]
        )
        const [selectedDocuments, setSelectedDocuments] = useContext(selectedDocumentContext)
        const [isOptionsDisabled, setIsOptionsDisabled] = useState(true)
        const [uploadState, setUploadState] = useState({
            loading: false,
            failed: false,
            failCount: 0,
            success: false,
            message: ''
        })
        const isPerformingUpdate = Boolean(document?.active) && Boolean(document?.created) && !Boolean(document?.data)

        function handleSelect(event) {
            setSelectedDocuments((prev) => ({
                ...prev,
                [document.id]: event.target.checked
            }))
        }

        function handleCheck(event) {
            handleSelect(event, document.id)
        }

        const handleEditDocument = !handleEdit
            ? undefined
            : () => {
                  if (isOptionsDisabled) return
                  handleEdit(document)
              }
        const handleRemoveDocument = !handleRemove
            ? undefined
            : () => {
                  if (isOptionsDisabled) return
                  handleRemove(document.id)
              }
        const handleSaveDocument = !handleSave
            ? undefined
            : async () => {
                  if (isOptionsDisabled) return
                  // setUploadState(prev => ({...prev, loading: true, failed: false }))
                  try {
                      await handleSave(document)
                      // setUploadState(prev => ({...prev, success: true, message:"Success!"}))
                  } catch (e) {
                      // console.log(document)
                      // const { response: {status, statusText} } = getSafeErrorObject(e)
                      // let errMessage = "Document Upload Failed - "
                      // if (status === 500 && statusText === "Internal Server Error") {
                      //     errMessage += "Timeout: Please try reducing the file size or try again"
                      // } else {
                      //     if ( !statusText?.length ) errMessage += "Timeout: Please try again"
                      //     errMessage += statusText
                      // }
                      notify(e?.message || '', 'error')
                      // setUploadState(prev => ({...prev, failed: true, failCount: typeof prev.failCount === 'number' ? prev.failCount + 1 : 1, message: errMessage }))
                  } finally {
                      setUploadState((prev) => ({ ...prev, loading: false }))
                  }
              }

        useEffect(() => {
            const uploadState = document?.uploadState
            if (uploadState && Object.keys(uploadState).length && typeof uploadState === 'object') {
                setUploadState((prev) => ({ ...prev, ...uploadState }))
            }

            // if ( uploadState === "success" ) setUploadState(prev => ({ ...prev, loading: false, failed: false, success: true,  message :"Success!", failCount: 0}))
            // if ( uploadState === "loading" ) setUploadState(prev => ({ ...prev, loading: true,  failed: false, success: false, message: "", failCount: prev.failCount}))
            // if ( uploadState === "failed"  ) setUploadState(prev => ({ ...prev, loading: false, failed: true,  success: false, message: "Document Upload Failed", failCount: prev.failCount + 1}))
        }, [document.uploadState])

        useEffect(() => {
            if (!document || !Object.keys(document).length) {
                setIsOptionsDisabled(false)
            } else if (document?.data) {
                setIsOptionsDisabled(false)
            } else if (isForDct) {
                setIsOptionsDisabled(false)
            } else {
                setIsOptionsDisabled(false)
            }
        }, [evaluators, document?.id])

        const classes = useStyles()

        return (
            <div className='document-row' style={{ backgroundColor: 'white' }}>
                {enableBulkSelection && (
                    <Checkbox
                        checked={selectedDocuments[document.id] || false}
                        onChange={handleCheck}
                        disabled={!canUpdate && !canDelete}
                    />
                )}
                <div className='col-xs-6 flex-row'>
                    {getIconByExtension(document.filename)}
                    {/*<CardMedia*/}
                    {/*    className={classes.media}*/}
                    {/*    image={document.filename && document.filename.includes('.pdf') ? PdfSVG : MsSVG}*/}
                    {/*    title={document.filename}*/}
                    {/*/>*/}
                    <h4 style={{ fontWeight: 400 }}>{document.filename}</h4>
                    {document.privacyLevel > 0 && <Chip size='small' style={{ marginLeft: '10px' }} label={'Staff'} />}
                </div>
                {showTimestamp ? (
                    <div className='col-xs-2'>{renderLastModified(document?.created, document?.updated)}</div>
                ) : (
                    <div className='col-xs-2' />
                )}

                {isPerformingUpdate ? (
                    <UpdateToolbar
                        selectionLabel={isForDct ? 'Select Document' : 'Copy Link'}
                        selectDocument={selectDocument ? () => selectDocument(document) : undefined}
                        handleEdit={handleEditDocument}
                        handleRemove={handleRemoveDocument}
                        document={document}
                        canUpdate={canUpdate && !isOptionsDisabled}
                        canDelete={canDelete && !isOptionsDisabled}
                        classes={classes}
                    />
                ) : (
                    <CreateToolbar
                        uploadState={uploadState}
                        handleSave={handleSaveDocument}
                        handleRemove={handleRemoveDocument}
                        error={error}
                        isDisabled={isOptionsDisabled}
                        classes={classes}
                    />
                )}
            </div>
        )
    }
)

// const TooltipStartIcon = ({ message }) => (
//     <span className="tooltip-flex-wrapper">
//         <HelpTooltip
//             removeDecoration
//             customIcon={<ErrorOutlineIcon style={{fontSize:"18px"}}/>}
//             tooltipTextStyles={{lineHeight: "initial", fontWeight: "initial", textTransform: "none", fontSize: "0.8em", width: "10rem"}}
//             contents={[message]}
//         />
//     </span>
// )

const iconMediaStyle = {
    width: 50,
    minWidth: 50,
    height: 35,
    backgroundSize: 'contain',
    alignSelf: 'center'
}

function getIconByExtension(filename) {
    if (!filename) {
        return null
    }

    const extIndex = filename.lastIndexOf('.')
    if (extIndex < 0) {
        return null
    }
    const extension = filename.slice(extIndex)
    if (extension === '.pdf') {
        return <CardMedia image={PdfSVG} title={filename} style={iconMediaStyle} />
    }
    if (['.kml', '.kmz'].includes(extension)) {
        return <AddLocationAltRounded style={iconMediaStyle} />
    }
    if (['.ppt', '.pptx', '.doc', '.docx', '.xls', '.xlsx'].includes(extension)) {
        return <CardMedia image={MsSVG} title={filename} style={iconMediaStyle} />
    }
    if (extension === '.zip') {
        return <FolderZip style={{ ...iconMediaStyle, color: '#FCD699' }} />
    }
    return <InsertDriveFile style={{ ...iconMediaStyle, color: '#FCD699' }} />
}

const CreateToolbar = ({ handleSave, handleRemove, isDisabled, classes, error, uploadState }) => {
    const { loading, message, success, failed, failCount } = uploadState || {}

    const getButtonState = () => {
        const props = { ...documentRowConfig.buttonDefaults, loading, disabled: error || isDisabled }
        if (failed) {
            props['disabled'] = failCount >= documentRowConfig.maxUploadAttempts
            props['onClick'] = handleSave
            props['color'] = 'error'
            props['startIcon'] = <ErrorOutlineIcon style={{ fontSize: '18px' }} />
            // props["startIcon"] = <TooltipStartIcon message={message} />
        } else if (success) {
            props['color'] = 'success'
            props['disableRipple'] = true
            props['disableElevation'] = true
            props['onClick'] = handleRemove
            props['children'] = <CheckCircleOutlineIcon />
        } else {
            props['onClick'] = handleSave
            props['startIcon'] = <UploadIcon />
        }
        return props
    }
    const getTooltipMessage = () => {
        if (failCount >= documentRowConfig.maxUploadAttempts) {
            return "Document Upload Failed - This document couldn't be successfully uploaded. Please try reducing the file size, or try again later."
        }
        return message
    }
    return (
        <CardActions
            className={classes.colXs4}
            sx={{
                '& .MuiButtonBase-root': {
                    minWidth: '5rem',
                    minHeight: '2rem'
                }
            }}
        >
            {handleSave && (
                <Tooltip title={getTooltipMessage()}>
                    <span style={{ margin: '0 0.5rem' }}>
                        <LoadingButton {...getButtonState()} />
                    </span>
                </Tooltip>
            )}
            {handleRemove && (
                <Button
                    disabled={loading || success}
                    variant='outlined'
                    size='small'
                    onClick={handleRemove}
                    color={'warning'}
                    startIcon={<DeleteIcon />}
                >
                    Delete
                </Button>
            )}
        </CardActions>
    )
}

const UpdateToolbar = ({
    document,
    selectDocument,
    handleEdit,
    handleRemove,
    canUpdate,
    canDelete,
    classes,
    selectionLabel
}) => {
    const getLinkToDocument = useGetLinkToDocument()
    return (
        <CardActions className={classes.colXs4}>
            <SplitButton
                sx={{
                    color: '#757575'
                }}
                button={{
                    label: selectionLabel,
                    buttonProps: {
                        onClick: selectDocument
                            ? selectDocument
                            : (e) =>
                                  copyToClipboard(
                                      getLinkToDocument({
                                          doc: document,
                                          fullUrl: false,
                                          by: 'id'
                                      })
                                  ),
                        startIcon: <FileCopyIcon />
                    }
                }}
                options={[
                    {
                        label: 'Share Link (Name)',
                        menuItemProps: {
                            onClick: () =>
                                copyToClipboard(
                                    getLinkToDocument({
                                        doc: document,
                                        fullUrl: true,
                                        by: 'name'
                                    })
                                )
                        }
                    },
                    {
                        label: 'Share Link (ID)',
                        menuItemProps: {
                            onClick: () =>
                                copyToClipboard(
                                    getLinkToDocument({
                                        doc: document,
                                        fullUrl: true,
                                        by: 'id'
                                    })
                                )
                        }
                    }
                ]}
            />
            {handleEdit && (
                <IconButton disabled={!canUpdate} onClick={handleEdit}>
                    <CreateIcon
                        className={!canUpdate ? classes.disabledIconColor : classes.iconColor}
                        classes={!canUpdate ? {} : { root: classes.hover }}
                    />
                </IconButton>
            )}
            {handleRemove && (
                <IconButton disabled={!canDelete} onClick={handleRemove}>
                    <DeleteIcon
                        className={!canDelete ? classes.disabledIconColor : classes.deleteIcon}
                        classes={!canDelete ? {} : { root: classes.deleteHover }}
                    />
                </IconButton>
            )}
        </CardActions>
    )
}

const useStyles = makeStyles({
    root: {
        margin: '0.65vh',
        width: '98.5%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignContent: 'center',
        alignItems: 'center'
    },
    media: {
        width: 50,
        height: 40,
        backgroundSize: 'contain'
    },
    colXs4: {
        flexBasis: '33.33333333%',
        maxWidth: '33.33333333%',
        textAlign: 'right',
        padding: 0,
        boxSizing: 'border-box',
        flex: '0 0 auto',
        paddingRight: '0.5rem',
        paddingLeft: '0.5rem',
        justifyContent: 'center'
    },
    row: {
        boxSizing: 'border-box',
        display: 'flex',
        flex: '0 1 auto',
        flexDirection: 'row',
        flexWrap: 'wrap',
        margin: '5px'
    },
    iconColor: {
        color: '#757575',
        cursor: 'pointer',
        transition: 'all .2s ease-in-out'
    },
    disabledIconColor: {
        color: colours.off_white
    },
    hover: {
        '&:hover': {
            filter: 'brightness(60%)'
        }
    },
    deleteHover: {
        '&:hover': {
            filter: 'brightness(125%)'
        }
    },
    deleteIcon: {
        color: '#e66262',
        cursor: 'pointer',
        transition: 'all .2s ease-in-out'
    }
})
