import { Accordion, AccordionSummary, AccordionDetails, Button } from '@mui/material'
import _ from 'lodash'
import moment from 'moment'
import React, { useState, useContext, useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { v4 } from 'uuid'
import { disabledContext } from '../../../common/DisabledContext'
import { clientStorage, eventsRowsPerPageKey, newsRowsPerPageKey } from '../../../common/client'
import { AddButton, ConfirmAction } from '../../../common/components'
import PageContainerWithHeader from '../../../common/components/PageContainerWithHeader'
import { CloneCustomMenuItem } from '../../../common/components/custom-context-menu/ContentCloneMenuItem'
import { DeleteCustomMenuItem } from '../../../common/components/custom-context-menu/ContentDeleteMenuItem'
import { ExpireToggleCustomMenuItem } from '../../../common/components/custom-context-menu/ContentExpireToggleMenuItem'
import { PinnedToggleCustomMenuItem } from '../../../common/components/custom-context-menu/ContentPinnedToggleMenuItem'
import { ContentRestoreMenuItem } from '../../../common/components/custom-context-menu/ContentRestoreMenuItem'
import { TransferDepartmentMenuItem } from '../../../common/components/custom-context-menu/ContentTransferDepartmentMenuItem'
import { CustomMenu, CustomMenuItem } from '../../../common/components/custom-context-menu/CustomMenu'
import { fromOptional } from '../../../common/components/selectors/SiteSelectorForAccount'
import { PreviewMenuItem } from '../../../common/components/selectors/SiteSelectorForContent'
import usePinnedNewsQueryMutation from '../../../common/query-hooks/usePinnedNewsQueryMutation'
import { notify, Get } from '../../../helpers'
import { useAppContext, useCurrentSiteID } from '../../auth/atoms'
import { EntityScopeEnum } from '../../auth/entityScope'
import { ManagerWithFilters } from '../../grid/ManagerWithFilters'
import { contentService } from '../content.service'
import { getPublishStatus } from '../editor/ContentEditorSaveCard'
import { CreateStructuredContent } from '../editor/CreateStructuredContent'
import { ContentExpirationsState, ContentPublishedTypes, ContentType } from '../types'
import { PinnedNews } from './PinnedNews'
import { DelayedSearchTimeout } from '../../../common/constants'
import ReadMoreIcon from '@mui/icons-material/ReadMore'
import CreateIcon from '@mui/icons-material/Create'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { ImportForm } from '../explorer/ImportForm'

const getDefaultState = (contentType) => ({
    data: [],
    contentType: contentType || ContentType.News,
    offset: 0,
    perPage: clientStorage.getItem(contentType === ContentType.Event ? eventsRowsPerPageKey : newsRowsPerPageKey) || 10,
    currentPage: 0,
    total: 0,
    searchTerm: null,
    publishedState: ContentPublishedTypes.all,
    tags: [],
    editors: [],
    template: null,
    filters: { items: [] },
    sortings: [],
    editable: false,
    deleted: null,
    expiration: ContentExpirationsState.all,
    departments: [],
    withPermissions: true,
    status: '',
    inactive: false,
    siteOnly: false
})

export function CombinedNewsEvents({ contentType }) {
    const editorRoute = contentType === ContentType.Event ? '/event' : '/article'
    const location = useLocation()
    const evaluators = useAppContext()
    const navigate = useNavigate()

    const [newPage, setNewPage] = useState(false)
    const [importOpen, setImportOpen] = useState(false)

    const currentSiteID = useCurrentSiteID()
    const [, setDisabled] = useContext(disabledContext)

    const [searchLoading, setSearchLoading] = useState(false)
    const [paginationLoading, setPaginationLoading] = useState(true)
    const [expirationDateConfirmation, setExpirationDateConfirmation] = useState(false)

    const [eventSearchTerm, setEventSearchTerm] = useState(null)
    const [paginationState, setPaginationState] = useState(getDefaultState(contentType))
    const { refetch: refetchPinnedNews } = usePinnedNewsQueryMutation(currentSiteID, undefined, undefined, false)
    const [controller, setController] = useState(new AbortController())

    // Enable SelectSite in Nav & Authentication &
    useEffect(() => {
        setDisabled(false)
    }, [])

    const loadDataFromServer = () => {
        const newController = new AbortController()
        controller.abort()
        setController(newController)
        contentService
            .getAllContents(newController, {
                selectedSite: currentSiteID,
                ...paginationState,
                page: paginationState.currentPage,
                limit: paginationState.perPage,
                searchTerm: paginationState.searchTerm,
                isPublished: paginationState.publishedState === ContentPublishedTypes.published,
                ignorePublished: paginationState.publishedState === ContentPublishedTypes.all,
                expiration: paginationState.expiration,
                contentType: contentType,
                deleted:
                    paginationState.publishedState === ContentPublishedTypes.deleted
                        ? moment().subtract(3, 'months').format('YYYY-MM-DD')
                        : null
            })
            .then(
                ({ results, resultset }) => {
                    setPaginationState((prev) => ({
                        ...prev,
                        data: results,
                        total: resultset.total_records,
                        pageCount: Math.ceil(resultset.total_records / resultset.limit) // Total count x limit
                    }))
                    setSearchLoading(false)
                },
                (e) => {
                    setSearchLoading(false)
                }
            )
    }

    // onInit requests
    useEffect(() => {
        if (location.state) {
            setPaginationState((prev) => ({
                ...prev,
                ...location.state
            }))
        }
        setPaginationLoading(false)
        if (evaluators.isCurrentSiteDepartment() && paginationState.siteOnly === true) {
            setPaginationState((prev) => ({
                ...prev,
                siteOnly: false
            }))
        }

        if (!evaluators.isCurrentSiteDepartment()) {
            setPaginationState((prev) => ({
                ...prev,
                departments: null
            }))
        }
    }, [currentSiteID])

    useEffect(() => {
        if (paginationLoading) return
        loadDataFromServer()
        return () => {
            controller.abort()
        }
    }, [
        paginationState.currentPage,
        paginationState.perPage,
        paginationState.publishedState,
        paginationState.searchTerm,
        paginationState.tags,
        paginationState.editors,
        paginationState.template,
        paginationState.sortings,
        paginationState.filters,
        paginationState.editable,
        paginationState.departments,
        currentSiteID,
        paginationLoading,
        paginationState.expiration,
        paginationState.status,
        paginationState.inactive,
        paginationState.siteOnly,
        contentType
    ])

    useEffect(() => {
        if (eventSearchTerm === null) return
        setSearchLoading(true)
        const timeout = setTimeout(() => {
            setPaginationState((prev) => ({
                ...prev,
                searchTerm: eventSearchTerm,
                currentPage: 0
            }))
        }, DelayedSearchTimeout)

        return () => {
            clearTimeout(timeout)
            controller.abort()
        }
    }, [eventSearchTerm])

    const handleSendToEditor = () =>
        navigate(`${editorRoute}/${v4()}?siteId=${currentSiteID}`, { state: { paginationState } })

    const handleSetExpiration = async () => {
        if (!actionItem.settings) return
        const { id, settings } = actionItem
        const expireContent = { id, settings: { ...settings, expirationDate: new Date().toISOString() } }
        try {
            const response = await contentService.patchContent(currentSiteID, expireContent)
            if (response) {
                const elementIndex = paginationState.data.findIndex((x) => x.id === actionItem.id)
                if (elementIndex < 0) return
                const { data } = response
                let clone = _.cloneDeep(paginationState)
                clone.data.splice(elementIndex, 1, data)
                setPaginationState(clone)
                setExpirationDateConfirmation(false)
            }
        } catch (e) {
            notify('Whoops! Error expiring News', 'error')
        }
    }

    const [actionItem, setActionItem] = useState(null)
    const [anchorElement, setAnchorElement] = useState(null)
    const close = () => setAnchorElement(null)

    const setMenuElement = async (event, state) => {
        setActionItem(state)
        setAnchorElement(event.currentTarget)
    }

    const resetForm = () => {
        setPaginationState(getDefaultState(contentType))
    }

    const isNews = contentType === ContentType.News
    const handleSendEdit = (state) => {
        navigate(`/content-editor/${state?.id}?siteId=${currentSiteID}`)
    }
    const handleResult = (result, onSuccess) => {
        if (result.success) {
            onSuccess?.()
        } else {
            notify(`Whoops! Can't clone the news with error: ${result?.error?.message}`, 'error')
        }
        close()
    }
    const handleDeleteResult = () => {
        if (paginationState.data.length === 1 && paginationState.currentPage) {
            setPaginationState((prev) => ({ ...prev, currentPage: prev.currentPage - 1 }))
        } else {
            loadDataFromServer()
            if (isNews) refetchPinnedNews()
        }
    }

    const ctxItemPermission = useMemo(
        () => ({
            update: actionItem == null ? false : evaluators.action(actionItem, 'update'),
            create: actionItem == null ? false : evaluators.action(actionItem, 'create'),
            delete: actionItem == null ? false : evaluators.action(actionItem, 'delete')
        }),
        [actionItem]
    )
    return (
        <>
            <CustomMenu anchorElement={anchorElement} onClose={close} value={actionItem}>
                {Boolean(actionItem?.active) ? (
                    <>
                        <PreviewMenuItem content={actionItem} onClick={() => setAnchorElement(null)} />
                        <CustomMenuItem
                            text={ctxItemPermission.update || actionItem.settings.isDistrictPage ? 'Edit' : 'View'}
                            onClick={() => handleSendEdit(actionItem)}
                        >
                            {ctxItemPermission.update || actionItem.settings.isDistrictPage ? (
                                <CreateIcon />
                            ) : (
                                <ReadMoreIcon />
                            )}
                        </CustomMenuItem>
                        <CloneCustomMenuItem
                            id={actionItem?.id}
                            onChange={(r) => handleResult(r, () => handleSendEdit(r.data))}
                            disabled={!ctxItemPermission.create}
                        />
                        {isNews && (
                            <PinnedToggleCustomMenuItem
                                value={actionItem}
                                disabled={!ctxItemPermission.update}
                                onChange={() => {
                                    close()
                                    refetchPinnedNews()
                                    loadDataFromServer()
                                }}
                            />
                        )}
                        {getPublishStatus(actionItem?.publish_at, actionItem?.expire_at) !== 'draft' && (
                            <ExpireToggleCustomMenuItem
                                value={actionItem}
                                disabled={!ctxItemPermission.update}
                                onChange={() => {
                                    notify(
                                        `Success, the expiry date for ${actionItem?.title || ''} has been updated`,
                                        'info'
                                    )
                                    setActionItem(null)
                                    loadDataFromServer()
                                    close()
                                }}
                            />
                        )}
                        <DeleteCustomMenuItem
                            contentModel={actionItem}
                            onChange={handleDeleteResult}
                            closeMenu={close}
                            disabled={!ctxItemPermission.delete}
                        />
                        {evaluators.isAdmin() && (
                            <TransferDepartmentMenuItem
                                contentModel={{
                                    id: actionItem?.id,
                                    departmentId: fromOptional(actionItem?.departmentId),
                                    contentType: contentType,
                                    sites: actionItem?.sites || [],
                                    settings: actionItem?.settings
                                }}
                                onChange={() => {
                                    if (isNews) {
                                        refetchPinnedNews()
                                    }
                                    loadDataFromServer()
                                }}
                                closeMenu={close}
                            />
                        )}
                    </>
                ) : (
                    <ContentRestoreMenuItem
                        id={actionItem?.id}
                        onChange={(r) => handleResult(r, loadDataFromServer)}
                        disabled={!ctxItemPermission.delete}
                    />
                )}
            </CustomMenu>

            <ToastContainer />

            <PageContainerWithHeader
                title={isNews ? 'News' : 'Events'}
                topRightElement={
                    isNews
                        ? evaluators.actionForEntityScope(EntityScopeEnum.News, 'create') && (
                              <AddButton title='ADD NEWS' func={() => setNewPage(true)} style={{ marginRight: 10 }} />
                          )
                        : evaluators.actionForEntityScope(EntityScopeEnum.Event, 'create') && (
                              <>
                                  <AddButton
                                      title='ADD EVENT'
                                      func={() => setNewPage(true)}
                                      style={{ marginRight: 10 }}
                                  />
                              </>
                          )
                }
            >
                {isNews && (
                    <div style={{ marginBottom: 50 }}>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <h3 style={{ margin: '5px 0' }}>Pinned News</h3>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div style={{ width: '100%' }}>
                                    <PinnedNews setMenuElement={setMenuElement} />
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                )}
                <ManagerWithFilters
                    contentType={contentType}
                    setPaginationState={setPaginationState}
                    paginationState={paginationState}
                    searchLoading={searchLoading}
                    resetFormCallback={resetForm}
                    setMenuElement={setMenuElement}
                />
            </PageContainerWithHeader>

            <ConfirmAction
                open={expirationDateConfirmation}
                handleClose={() => setExpirationDateConfirmation(false)}
                title='Are you sure?'
                text={`This item will be expired and its end date will be set to ${moment().format(
                    'dddd, MMMM Do YYYY, h:mm:ss a'
                )}`}
                handleDisagree={() => setExpirationDateConfirmation(false)}
                handleAgree={handleSetExpiration}
                isLoading={false}
            />

            {newPage && (
                <CreateStructuredContent
                    availableTypes={isNews ? ['news'] : ['event']}
                    open={newPage}
                    onClose={() => setNewPage(false)}
                    onImport={() => {
                        setNewPage(false)
                        setImportOpen(true)
                    }}
                />
            )}

            {importOpen && (
                <ImportForm
                    open={importOpen}
                    onClose={() => setImportOpen(false)}
                    contentTypes={isNews ? ['news'] : ['event']}
                />
            )}
        </>
    )
}
