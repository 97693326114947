import { Box, Button, Link, TextField, Typography } from '@mui/material'
import { notify } from '../../../../helpers'
import { useEffect, useState } from 'react'
import { BoxForm } from '../../../../common/components/BoxForm'
import { useInstagramSettingQueryMutation } from '../../settings/settings.service'
import moment from 'moment/moment'
import { LoadingButton } from '@mui/lab'
import { useParams } from 'react-router-dom'

const IEteamUrl = 'mailto:support@imagineeverything.com'
const tokenInstructionsUrl =
    'https://developers.facebook.com/docs/instagram-basic-display-api/guides/long-lived-access-tokens/'
const tokenEllipses = '....'

function EditSettingsIntegrations() {
    const params = useParams()
    const { instagramSetting, mutation, isLoading } = useInstagramSettingQueryMutation(
        params.id || '',
        undefined,
        (err: any) => {
            if (
                err.response?.status === 400 &&
                err?.response?.data?.ErrorMessage?.includes('instagramAccessToken is invalid')
            ) {
                setFormDataError(err.response.data.ErrorMessage)
            } else {
                console.error({ status: err.response?.status, ...(err?.response?.data || {}) })
            }
            notify('Whoops! Error updating Instagram Settings, please try again later')
        }
    )
    const getTruncatedToken = () => {
        const token =
            instagramSetting?.Data?.instagramExtended?.Token || instagramSetting?.Data?.instagramAccessToken || ''
        const truncated = token.substring(0, 12 /* Arbitrary length*/)
        return truncated + (truncated.length ? tokenEllipses : '')
    }
    const [formData, setFormData] = useState<string>(getTruncatedToken())
    const [formDataError, setFormDataError] = useState<string>('')
    const disableSaveButton =
        formData === instagramSetting?.Data?.instagramAccessToken || formData.endsWith(tokenEllipses)

    useEffect(() => {
        setFormData(getTruncatedToken())
    }, [instagramSetting?.Data?.instagramAccessToken, instagramSetting?.ID])
    console.log(mutation, mutation.isLoading)

    function handleSubmit() {
        mutation.mutate(formData)
    }

    function getHelperText() {
        if (formDataError) {
            return formDataError
        }
        if (instagramSetting?.Data?.instagramExtended != null) {
            const lastError = instagramSetting?.Data?.instagramExtended?.LastError
            if (lastError) {
                if (lastError === 'invalid token') {
                    return 'This token is invalid - Please enter a new Access Token'
                }
                return lastError
            }
            const lastUsed = instagramSetting?.Data?.instagramExtended?.LastUsedAt
            if (lastUsed) {
                return 'Last used at: ' + moment(lastUsed).format('MMMM Do YYYY, h:mm:ss')
            }
        }
        if (!instagramSetting?.Data?.instagramAccessToken?.length) {
            return 'Enter a valid Instagram Access Token'
        }
        return ' '
    }

    return (
        <BoxForm>
            <Typography>
                1. If you are not already using Instagram as part of your site, or did not have an Instagram section
                created as part of your design process, then please email the
                <Link href={IEteamUrl} underline='hover' target='_blank' display='inline' color='orangered'>
                    {' '}
                    IE team{' '}
                </Link>
                for assistance
            </Typography>

            <Typography>
                2. Follow the
                <Link href={tokenInstructionsUrl} underline='hover' target='_blank' display='inline' color='orangered'>
                    {' '}
                    step by step instructions{' '}
                </Link>
                to create/update your Instagram Access Token
            </Typography>

            <Typography>3. Enter the Instagram Token below</Typography>

            <div className={'flex-row-align-center'}>
                <TextField
                    name={'instagramAccessToken'}
                    label={'Instagram Access Token'}
                    disabled={Boolean(mutation.isLoading)}
                    value={formData}
                    error={Boolean(formDataError || instagramSetting?.Data?.instagramExtended?.LastError)}
                    helperText={getHelperText()}
                    onChange={(e) => {
                        setFormData(e.target.value)
                        setFormDataError('')
                    }}
                />
            </div>

            <Box className='box-row'>
                <Button
                    variant='text'
                    color='primary'
                    type='reset'
                    onClick={() => {
                        setFormData(getTruncatedToken())
                        setFormDataError('')
                    }}
                >
                    Reset Changes
                </Button>
                <LoadingButton
                    disabled={disableSaveButton}
                    loading={Boolean(mutation.isLoading)}
                    variant='contained'
                    color='primary'
                    type='submit'
                    onClick={handleSubmit}
                >
                    Save
                </LoadingButton>
            </Box>
        </BoxForm>
    )
}

export default EditSettingsIntegrations
