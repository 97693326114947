import React, { useEffect, useState } from 'react'
import LockIcon from '@mui/icons-material/Lock'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { IconButton } from '@mui/material'
import { useAppContext } from '../../pkgs/auth/atoms'
import { EntityScopeEnum } from '../../pkgs/auth/entityScope'

// Created with content in mind (i.e type, title values)

// Including a fifth element will push the options to position 5, and insert the "code" prop into position 4
// standard : 1-4 positional column values
// w/ fifth : 1-5 positional column values

export const TemplateRow = (props) => {
    const { state, code, fifth, setMenu } = props
    const evaluators = useAppContext()
    const [isOptionsDisabled, setIsOptionsDisabled] = useState(true)
    const [isCodeDisabled, setIsCodeDisabled] = useState(true)
    const [isFifthDisabled, setIsFifthDisabled] = useState(true)

    // TODO => Refactor
    useEffect(() => {
        if (!evaluators.entityScopeAny(EntityScopeEnum.Resource)) {
            setIsOptionsDisabled(true)
            setIsCodeDisabled(true)
            setIsFifthDisabled(true)
        } else {
            if (!fifth) {
                setIsOptionsDisabled(false)
            } else {
                setIsCodeDisabled(false)
                setIsFifthDisabled(false)
            }
        }
    }, [evaluators])

    return (
        <div className='table-item'>
            <p>{state.type}</p>

            <p className='span-from-column-three'>{state.title}</p>

            <div className='icon third-column'>
                {state.sites.length > 1 && <LockIcon className='disabled-icon icon' disabled />}
            </div>
            {!isOptionsDisabled && (
                <div className='icon fourth-column'>
                    <IconButton
                        aria-controls={'pageMenu'}
                        aria-haspopup='true'
                        onClick={(e) => setMenu(e, state)}
                        size='large'
                    >
                        <MoreHorizIcon />
                    </IconButton>
                </div>
            )}

            {!isCodeDisabled && <div className='icon fourth-column'>{code}</div>}

            {!isFifthDisabled && (
                <div className='icon fifth-column'>
                    <IconButton
                        aria-controls={'pageMenu'}
                        aria-haspopup='true'
                        onClick={(e) => setMenu(e, state)}
                        size='large'
                    >
                        <MoreHorizIcon />
                    </IconButton>
                </div>
            )}
        </div>
    )
}
