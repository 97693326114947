import React, { Fragment, useContext, useEffect, useState } from 'react'
import { TreeItem, TreeView } from '@mui/lab'
import { makeStyles } from '@mui/styles'
import { CircularProgress, Typography } from '@mui/material'
import { allFolderContext } from '../context'
import { navigationHelpers } from '../../../navigation/navigation.helpers'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import FolderIcon from '@mui/icons-material/Folder'

function StyledTreeItem(props) {
    const classes = useTreeItemStyles()
    const {
        folder,
        setOpenedFolder,
        prepareEdit,
        label,
        labelInfo,
        color,
        bgColor,
        children,
        depth = 0,
        ...other
    } = props
    return (
        <TreeItem
            className={props.children ? classes.parentNode : undefined}
            label={
                <div style={{ justifyContent: 'space-between' }}>
                    <div className={classes.labelRoot}>
                        <FolderIcon style={{ fontSize: '1em', marginRight: '1.5px' }} />
                        <Typography variant='body2' className={classes.labelText}>
                            {label}
                        </Typography>
                    </div>
                </div>
            }
            style={{
                '--node-depth': depth
            }}
            onClick={() => setOpenedFolder(folder)}
            classes={{
                root: classes.root,
                content: classes.content,
                expanded: classes.expanded,
                selected: classes.selected,
                group: classes.group,
                label: classes.label
            }}
            {...other}
        >
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, { depth: depth + 1 })
            })}
        </TreeItem>
    )
}

export default function DocumentFolderTree({ setOpenedFolder }) {
    const [tree, setTree] = useState([])
    const [allFolders] = useContext(allFolderContext)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (allFolders) {
            setIsLoading(true)
            const tree = navigationHelpers.createTreeFromFlatData({
                flatData: allFolders,
                getKey: (node) => node.path,
                getParentKey: (node) => {
                    const segments = node.path.split('.')
                    segments.pop()
                    return segments.join('.')
                },
                rootKey: ''
            })
            setTree(tree.nested)
        }
        setIsLoading(false)
    }, [allFolders])

    const getTreeItemsFromData = (folderTree) => {
        return folderTree.map((treeItem) => {
            let children = undefined
            if (treeItem.children && treeItem.children.length > 0) {
                children = getTreeItemsFromData(treeItem.children)
            }
            return (
                <StyledTreeItem
                    key={treeItem.id}
                    nodeId={treeItem.id}
                    label={treeItem.filename}
                    folder={treeItem}
                    children={children}
                    setOpenedFolder={setOpenedFolder}
                />
            )
        })
    }

    return (
        <Fragment>
            {isLoading ? (
                <CircularProgress style={{ margin: 'auto', display: 'block' }} />
            ) : (
                <TreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
                    {tree && tree.length > 0 && getTreeItemsFromData(tree)}
                </TreeView>
            )}
        </Fragment>
    )
}

const useTreeItemStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.text.secondary,
        '&:hover > $content': {
            backgroundColor: theme.palette.action.hover
        },
        '&:focus > $content, &$selected > $content': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
            color: 'var(--tree-view-color)'
        },
        '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
            backgroundColor: 'transparent'
        }
    },
    content: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '$expanded > &': {
            fontWeight: theme.typography.fontWeightRegular
        },
        width: 'auto'
    },
    group: {
        marginLeft: 0,
        '& $content': {
            paddingLeft: theme.spacing(2)
        }
    },
    expanded: {},
    selected: {},
    label: {
        borderRadius: '20px',
        fontWeight: 'inherit',
        color: 'inherit'
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0)
        // flexBasis:"83.33333333%",
        // maxWidth:"83.33333333%",
    },
    labelIcon: {
        marginRight: theme.spacing(1)
    },
    labelText: {
        fontWeight: 'inherit',
        flexGrow: 1
    },
    parentNode: {
        '& ul li $content': {
            // child left padding
            paddingLeft: `calc(var(--node-depth) * ${theme.spacing(2)})`
        }
    },
    colXs2: {
        flexBasis: '16.66666667%',
        maxWidth: '16.66666667%'
    },
    colXs10: {
        flexBasis: '83.33333333%',
        maxWidth: '83.33333333%'
    }
}))
