import React, { useContext, useEffect, useState } from 'react'
import { documentService } from './document.service'
import { CircularProgress, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { DelayedSearchTimeout } from '../../../common/constants'
import { selectedDocumentContext } from './context/SelectedDocumentContext'
import SearchIcon from '@mui/icons-material/Search'
import { TagsSelectorForContent } from '../../../common/components/selectors/TagSelectorForContent'
import { DocumentType } from './types'
import { TagType } from '../../system/tags/types'

export const DocumentSearch = ({
    selectedSite,
    tags,
    setDocuments,
    setFolders,
    openedFolder,
    setSearchValue,
    searchValue,
    searchText,
    setSearchText,
    pagination,
    setPagination
}) => {
    const [, setSelectedDocuments] = useContext(selectedDocumentContext)
    const [isLoading, setIsLoading] = useState(false)
    // Search
    const handleSearchTextChange = (event) => {
        event.preventDefault()
        setSearchText(event.target.value)
    }

    useEffect(() => {
        if (!selectedSite) return
        setIsLoading(true)
        const typingTimer = setTimeout(() => {
            setPagination((prev) => ({ ...prev, currentPage: 0 }))
            const ids = searchValue
            const folderId = openedFolder && openedFolder.id ? openedFolder.id : ''
            const getDocuments = documentService
                .getByParameter(selectedSite, ids, searchText, folderId, DocumentType.Document, pagination.perPage, 0)
                .then(({ results, resultset }) => {
                    const obj = {}
                    for (const result of results || []) {
                        obj[result.id] = false
                    }
                    setSelectedDocuments(obj)
                    setDocuments(results)
                    setPagination((prev) => ({
                        ...prev,
                        total: resultset.total_records,
                        pageCount: Math.ceil(resultset.total_records / prev.perPage)
                    }))
                })
            const getFolders = documentService
                .getByParameter(selectedSite, ids, searchText, folderId, DocumentType.Folder, 9999, 0)
                .then(({ results }) => setFolders(results))
            Promise.all([getDocuments, getFolders]).then(() => {
                setIsLoading(false)
            })
        }, DelayedSearchTimeout)
        return () => {
            clearTimeout(typingTimer)
        }
    }, [searchValue, searchText])

    return (
        <div className='flex-row full-width' style={{ justifyContent: 'space-between' }}>
            <div className='row full-width' style={{ alignItems: 'flex-end' }}>
                <div className='col-xs-12 col-md-8 col-lg-8 start-md start-lg start-lg'>
                    <div className='flex-row' style={{ alignItems: 'flex-end' }}>
                        <SearchIcon className='mr5' />
                        <TextField
                            variant='standard'
                            label='Search by folder or file name'
                            // className="full-width"
                            value={searchText}
                            onChange={handleSearchTextChange}
                        />
                    </div>
                </div>
                <div className='col-xs-12 col-md-4 col-lg-4'>
                    <div className='flex-row' style={{ alignItems: 'flex-end' }}>
                        <div style={{ width: '25px' }} className='mr5'>
                            {isLoading && <CircularProgress style={{ height: '100%', width: '100%' }} />}
                        </div>
                        <TagsSelectorForContent
                            selected={searchValue?.tags || []}
                            tagType={TagType.Media}
                            onChange={setSearchValue}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles({
    textfield: {
        width: '58%'
    },
    autoComplete: {
        width: '38%'
    }
})
