import { IconButton, IconButtonProps } from '@mui/material'
import { ReactNode } from 'react'

interface CustomIconButton extends IconButtonProps {
    label?: string
    children: JSX.Element
}

const defaultBorderRadius = '6px'
export default function CustomIconButton({ label, children, ...iconButtonProps }: CustomIconButton) {
    iconButtonProps.sx = {
        ...iconButtonProps.sx,
        borderRadius: defaultBorderRadius,
        gap: '4px',
        '.MuiTouchRipple-ripple .MuiTouchRipple-child': {
            borderRadius: defaultBorderRadius
        }
    }

    return (
        <IconButton
            {...iconButtonProps}
            aria-label={iconButtonProps?.['aria-label'] || label}
            title={iconButtonProps?.title || label}
        >
            {children}
        </IconButton>
    )
}
