import { Box, Button, Card, CardContent, FormHelperText } from '@mui/material'
import { useEffect, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { createSettingsQuery } from './queries'
import { notify } from '../../../helpers'
import SettingsForm, { ISettingsFormData } from './SettingsForm'
import { safeJSONParse } from './EditSettings'
import PageContainerWithHeader from '../../../common/components/PageContainerWithHeader'
import { tryParseJson } from '../../../helpers/parseJSON'
import { useAppNavigation } from '../../../app/useAppNavigation'
import { BackButton } from '../../../common/components'
import { Settings, SettingsDTO, SettingsType } from './types'

const defaultSettingsFormData = {
    Name: '',
    Description: '',
    Type: SettingsType.SiteSettingsCustom,
    // SiteID: null,
    Sites: null,
    Data: {},
    Public: null,
    Active: true
}

function CreateSettings() {
    const { navigateTo } = useAppNavigation()
    const createSettingsMutation = useMutation({
        mutationFn: (settings: SettingsDTO) => createSettingsQuery(settings)
    })

    const [settingsFormData, setSettingsFormData] = useState<ISettingsFormData>(defaultSettingsFormData)

    // const settingsFormJsonDefault =
    const [settingsFormJson, setSettingsFormJson] = useState<string | null>(null)
    const [settingsFormJsonIsInvalid, setSettingsFormJsonIsInvalid] = useState(false)

    const [settingsFormDataErrors, setSettingsFormDataErrors] = useState<Partial<ISettingsFormData> | undefined>(
        undefined
    )

    const [submitError, setSubmitError] = useState('')

    function handleCreate() {
        const { error, data } = tryParseJson(settingsFormJson || '')
        if (error) {
            setSubmitError(data?.message || '')
        } else {
            const settingsFormDataWithJson = {
                ...settingsFormData,
                Data: safeJSONParse(settingsFormJson) || null
            }

            createSettingsMutation.mutate(settingsFormDataWithJson, {
                onSuccess: (data) => {
                    notify('Setting successfuly created', 'info')
                    setSubmitError('')
                    const settingId = data
                    if (typeof settingId === 'string') {
                        navigateTo(`/system/settings/edit/${settingId}`)
                    }
                },
                onError: (err: any) => {
                    notify('Oops! Setting could not be created', 'error')
                    const errorMessage = err?.response?.data?.ErrorMessage
                    console.log(errorMessage)
                    if (errorMessage.includes('duplicate key') && errorMessage.includes('name')) {
                        setSettingsFormDataErrors((prev) => ({ ...prev, Name: 'This name already exists.' }))
                    } else {
                        setSubmitError(errorMessage)
                    }
                }
            })
        }
    }

    useEffect(() => {
        console.log({
            settingsFormData,
            settingsFormJson,
            submitError
        })
        const x = safeJSONParse(settingsFormJson) === null
        if (settingsFormJson != null && x) {
            setSettingsFormJsonIsInvalid(true)
        } else {
            setSettingsFormJsonIsInvalid(false)
        }

        if (submitError?.length) {
            setSubmitError('')
        }
    }, [settingsFormData, settingsFormJson])
    return (
        <PageContainerWithHeader
            isSubtitle
            title='Create Settings'
            topRightElement={<BackButton route='/system/settings' />}
        >
            <Card>
                <CardContent sx={{ gap: '8px', display: 'flex', flexDirection: 'column' }}>
                    <SettingsForm
                        formData={settingsFormData}
                        setFormData={setSettingsFormData}
                        settingsFormJson={settingsFormJson || ''}
                        setSettingsFormJson={() => console.log("")}
                        formDataErrors={settingsFormDataErrors}
                        setFormDataErrors={setSettingsFormDataErrors}
                        formControlSection={
                            <Box display='flex' flexDirection='column' alignItems='flex-end'>
                                <div style={{height:"25px"}}>
                                    {submitError?.length ? (
                                        <FormHelperText error={true}>{submitError}</FormHelperText>
                                    ) : null}
                                </div>
                                <Box className='box-row' justifyContent='flex-end'>
                                    <Button
                                        disabled={settingsFormJsonIsInvalid}
                                        variant='contained'
                                        color='primary'
                                        type='submit'
                                    >
                                        Create
                                    </Button>
                                </Box>
                            </Box>
                        }
                        onSubmit={() => handleCreate()}
                        onReset={() => setSettingsFormData(defaultSettingsFormData)}
                    />
                </CardContent>
            </Card>
        </PageContainerWithHeader>
    )
}

export default CreateSettings
