import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import {
    AllFolderContextProvider,
    DocumentContextProvider,
    FolderContextProvider,
    FullAccessFoldersContextProvider
} from '../pkgs/media/document/context'
import { DisabledContextProvider } from '../common/DisabledContext'
import { StyledEngineProvider, ThemeProvider } from '@mui/material'
import { primaryTheme } from './theme'
import '../styles/shortcuts.css'
import './App.css'
import { ToastContainer } from 'react-toastify'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ApplicationWrapper } from './ApplicationWrapper'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

const queryClient = new QueryClient()

export function App() {


    // Custom Menu / Dialogs
    // Global function
    // Provide it a message, available actions and parameters for actions
    // notify("message", "type")
    // use Toast as an example
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={primaryTheme}>

                            <DisabledContextProvider>
                                <DocumentContextProvider>
                                    <FullAccessFoldersContextProvider>
                                        <FolderContextProvider>
                                            <AllFolderContextProvider>
                                                <ToastContainer />
                                                {
                                                    <ApplicationWrapper />
                                                }
                                            </AllFolderContextProvider>
                                        </FolderContextProvider>
                                    </FullAccessFoldersContextProvider>
                                </DocumentContextProvider>
                            </DisabledContextProvider>

                        </ThemeProvider>
                    </StyledEngineProvider>
                </BrowserRouter>
            </QueryClientProvider>
        </LocalizationProvider>
    )
}


