import { useMutation, useQuery } from '@tanstack/react-query'
import { PagingQuery, SortingQuery, paged } from '../../common/react-query'
import { BASE, GroupAPI, ManageAccountsAPI, RoleAPI, groupQueryKey, roleQueryKey } from '../../common/constants'
import { httpGet, httpPost } from '../../common/client'
import { ROLE_TYPE, Role, SecurityGroup, account, accounts, groups } from './types'
import axios from 'axios'
import { z } from 'zod'
import { SecurityGroupsZ } from '../departments/department.service'
import { DISTRICT_WIDE_VALUE } from '../../common/components/selectors/SingleSiteSelect'
import { notify } from '../../helpers'

export type AccountsQuery = {
    Search?: string
    Active?: boolean | null
} & PagingQuery &
    SortingQuery

export const useAccounts = (q: AccountsQuery) => {
    const query = useQuery({
        queryKey: ['accounts', q],
        queryFn: async () => httpGet(ManageAccountsAPI, q, accounts)
    })

    return query
}

export const useAccountDetails = (id: string) => {
    const query = useQuery({
        queryKey: ['account', id],
        queryFn: async () => httpGet(`${BASE}/api/v1/identity/${id}`, {}, account)
    })

    return query
}

export type GroupsForAssignmentQuery = {
    Search?: string
    ForType: 'manual' | 'native'
    Account: string
    Assigned: string[]
    Assignment: 'all' | 'assigned' | 'unassigned'
} & PagingQuery &
    SortingQuery

export type GroupsQuery = {
    Search?: string
    Type?: 'external' | 'manual' | ''
    Active?: boolean | null
    RoleID?: string | null
    QSiteID?: string | null
} & PagingQuery &
    SortingQuery

export const useGroups = (q: GroupsQuery) => {
    const query = useQuery({
        queryKey: ['groups', q],
        keepPreviousData: true,
        queryFn: async () => httpGet(GroupAPI, q, groups)
    })

    return query
}

export const useGroupsForAssignment = (q: GroupsForAssignmentQuery) => {
    const query = useQuery({
        queryKey: ['groups-for-assignment', q],
        keepPreviousData: true,
        queryFn: async () => httpPost(`${ManageAccountsAPI}/groups-for-assignment`, q, groups)
    })

    return query
}

export const groupSearchQueryParams = paged.extend({
    Search: z.string().optional(),
    Type: z.string().optional(),
    Active: z.boolean().optional(),
    RoleID: z.string().optional(),
    QSiteID: z.string().nullish()
})

export type GroupQueryParams = z.infer<typeof groupSearchQueryParams>

function searchGroups(queryParams) {
    return axios.get(GroupAPI, { params: queryParams }).then((res) => res.data as SecurityGroupsZ)
}

function getGroupById(id) {
    return axios.get(`${GroupAPI}/${id}`).then((res) => res.data as SecurityGroup)
}

function beforeGroupQuery<T>(group: SecurityGroup, query: (group: SecurityGroup) => T) {
    if (group.SiteID == DISTRICT_WIDE_VALUE) {
        // "District-Wide"
        group.SiteID = null
    }

    return query(group)
}

export function createGroupQuery(group: SecurityGroup) {
    return beforeGroupQuery(group, (group) => axios.post(GroupAPI, group))
}

export function editGroupQuery(group: SecurityGroup) {
    return beforeGroupQuery(group, (group) => axios.patch(`${GroupAPI}/${group.ID}`, group))
}

export function deleteGroupQuery(group: any) {
    return axios.delete(`${GroupAPI}/${group.ID}`, { data: group })
}

export default function useGroupQueryMutation(queryParams?: GroupQueryParams) {
    const { data, error, refetch, isLoading } = useQuery({
        queryKey: [groupQueryKey + JSON.stringify(queryParams)],
        queryFn: () => searchGroups(queryParams)
    })

    const createMutation = useMutation({
        mutationFn: createGroupQuery
    })

    const editMutation = useMutation({
        mutationFn: editGroupQuery
    })

    const deleteMutation = useMutation({
        mutationFn: deleteGroupQuery
    })

    function getGroupIdToGroup() {
        return data?.Rows?.reduce(
            (a, group) => ({
                ...a,
                [group.ID]: group
            }),
            ({} as Record<string, any>) || null
        )
    }

    return {
        data,
        isLoading,
        error,
        refetch,
        createMutation,
        editMutation,
        deleteMutation,
        getGroupIdToGroup
    }
}

export function useGroupByIdQuery(groupID: string) {
    const result = useQuery({
        queryKey: [groupQueryKey + groupID],
        queryFn: () => getGroupById(groupID)
    })

    return result
}

export function useRoleQueryMutation(onEditSuccess?: (data) => void, onEditError?: (e) => void) {
    const { data, error, refetch } = useQuery({
        queryKey: [roleQueryKey],
        queryFn: () =>
            axios.get(RoleAPI).then((res) => {
                const result = res.data as Role[]
                result.sort((a, b) => a.name.localeCompare(b.name))
                return result
            })
    })

    const createMutation = useMutation({
        mutationFn: (data: any) => axios.post(RoleAPI, data)
    })

    const editMutation = useMutation({
        mutationFn: (role: any) => axios.put(RoleAPI, role),
        onSuccess: (data) => {
            if (onEditSuccess) {
                onEditSuccess(data)
            } else {
                notify('Success! Role has been updated', 'info')
                refetch()
            }
        },
        onError: (err: any) => {
            notify('Oops! Role could not be updated', 'error')
        }
    })

    function getRoleIdToRole(type?: ROLE_TYPE) {
        return data
            ?.filter((role) => (type ? role.type == type : role))
            .reduce(
                (a, role) => ({
                    ...a,
                    [role.id]: role
                }),
                ({} as Record<string, Role>) || null
            )
    }

    return { data, error, refetch, createMutation, editMutation, getRoleIdToRole }
}
