import { useState } from 'react'
import { Box, Button, Card, CardContent, FormHelperText, Typography } from '@mui/material'
import { isEqual } from 'lodash'
import { notify } from '../../../helpers'
import SecurityGroupForm, { ISecurityGroupFormData } from './SecurityGroupForm'
import { useNavigate } from 'react-router'
import { useMutation } from '@tanstack/react-query'
import { SecurityGroup } from '../types'
import { useAppNavigation } from '../../../app/useAppNavigation'
import { BackButton } from '../../../common/components'
import { createGroupQuery } from '../queries'

const defaultFormDataValues: ISecurityGroupFormData = {
    Type: '',
    Name: '',
    Description: '',
    RoleID: '',
    SiteID: null,
    ExternalIdList: [],
    AvailableGroups: [],
    Audience: [],
    DependsOn: []
}

interface CreateSecurityGroupProps {}

export function CreateSecurityGroup({}: CreateSecurityGroupProps) {
    const { navigateTo } = useAppNavigation()
    const createMutation = useMutation({
        mutationFn: createGroupQuery
    })

    const [formData, setFormData] = useState(defaultFormDataValues)
    const [submitError, setSubmitError] = useState('')
    const formDataHasChanges = !isEqual(formData, defaultFormDataValues)

    function handleOnSave() {
        createMutation.mutate({ ...formData } as SecurityGroup, {
            onSuccess: (res) => {
                notify('Group was successfully created!', 'info')
                navigateTo(`/user-management/security-groups${res.data ? `/edit/${res.data}` : ''}`)
            },
            onError: (err: any) => {
                notify('Oops! Group could not be created', 'error')
                setSubmitError(err?.response?.data?.ErrorMessage)
            }
        })
    }

    return (
        <div className='container'>
            <div className='flex-row settings-header' style={{ justifyContent: 'space-between' }}>
                <Typography variant='h6'>Create Group</Typography>
                <BackButton route='/user-management/security-groups' />
            </div>
            <Card>
                <CardContent sx={{ gap: '8px', display: 'flex', flexDirection: 'column' }}>
                    <SecurityGroupForm
                        formType='create'
                        headerText={''}
                        formData={formData}
                        setFormData={setFormData}
                        onReset={() => setFormData(defaultFormDataValues)}
                        onSubmit={() => handleOnSave()}
                        formControlSection={
                            <Box display='flex' flexDirection='column' alignItems='flex-end'>
                                <Box className='box-row' justifyContent='flex-end'>
                                    <Button variant='text' color='primary' type='reset'>
                                        Clear
                                    </Button>
                                    <Button
                                        disabled={!formDataHasChanges}
                                        variant='contained'
                                        color='primary'
                                        type='submit'
                                    >
                                        Create
                                    </Button>
                                </Box>
                                {submitError?.length ? (
                                    <FormHelperText error={true}>{submitError}</FormHelperText>
                                ) : null}
                            </Box>
                        }
                    />
                </CardContent>
            </Card>
        </div>
    )
}
