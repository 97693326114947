import { BoxForm } from '@/common/components/BoxForm'
import { _isEqual } from '@/helpers'
import { CodeEditor, CodeEditorHandler, CodeEditorProps } from '@/pkgs/monaco/code-editor'
import { Box, Button, Dialog, DialogActions, DialogContent, FormHelperText } from '@mui/material'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

interface MonacoCodeEmbedEditorDialogProps extends Omit<CodeEditorProps, 'onChange'> {
    isOpen: boolean
    onSaveHandler: (v: string) => void
    onCloseHandler: () => void
}

function MonacoCodeEmbedEditorDialog({
    isOpen,
    onSaveHandler,
    onCloseHandler,
    ...codeEditorProps
}: MonacoCodeEmbedEditorDialogProps) {
    const monacoCodeEditorRef = useRef<CodeEditorHandler | null>(null)

    const [value, setValue] = useState<string>(codeEditorProps.value)

    const hasChanges = useMemo(() => !_isEqual(codeEditorProps.value, value), [value, codeEditorProps?.value])

    const _onSaveHandler = useCallback(() => {
        onSaveHandler(value)
    }, [value])

    const codeEditorOnChangeHandler = useCallback(
        (v) => {
            setValue(v || '')
        },
        [monacoCodeEditorRef?.current]
    )

    useEffect(() => {
        setValue(codeEditorProps.value)
    }, [isOpen])

    return (
        <Dialog
            open={isOpen}
            onClose={onCloseHandler}
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                '& .MuiPaper-root': {
                    minWidth: '70%'
                }
            }}
        >
            <BoxForm fullWidth>
                <DialogContent>
                    <CodeEditor
                        {...codeEditorProps}
                        value={value}
                        onChange={codeEditorOnChangeHandler}
                        height='50vh'
                        onValidate={(iMarkers) => console.log('imarkers', iMarkers)}
                    />
                </DialogContent>
                <DialogActions
                    sx={{
                        padding: '0 25px 15px 25px',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end'
                    }}
                >
                    <Box className='box-row'>
                        <Button disabled={codeEditorProps.disabled} onClick={onCloseHandler} color='primary'>
                            Close
                        </Button>
                        <Button
                            disabled={codeEditorProps.disabled || hasChanges == false}
                            onClick={_onSaveHandler}
                            color='primary'
                            variant='contained'
                        >
                            Save Changes
                        </Button>
                    </Box>
                </DialogActions>
            </BoxForm>
        </Dialog>
    )
}

export default MonacoCodeEmbedEditorDialog
