import { useBatchedContentDetails } from '../content/queries'
import { Item } from './types'
import { CustomMenuItem } from '@/common/components/custom-context-menu/CustomMenu'
import CreateIcon from '@mui/icons-material/Create'
import DeleteIcon from '@mui/icons-material/Delete'
import { ImagePreview } from '../media/image/MediaIDSelector'
import { Alert, Box, Typography } from '@mui/material'
import { MenuLightCell } from '../grid/cells/GridCells'
import { useAppContext } from '@/pkgs/auth/atoms'

export const ItemView = ({
    item,
    onAction
}: {
    item: Item
    onAction: (id: string, action: 'delete' | 'edit') => void
}) => {
    const result = useBatchedContentDetails(item.ContentID)
    const appContext = useAppContext()

    const menuItems = (id: string) => {
        return (onClose: () => void) => {
            return [
                <CustomMenuItem
                    key={'0'}
                    text={'Edit Item'}
                    onClick={() => {
                        onAction(id, 'edit')
                        onClose()
                    }}
                >
                    <CreateIcon />
                </CustomMenuItem>,
                <CustomMenuItem
                    key={'1'}
                    text={'Delete Item'}
                    onClick={() => {
                        onAction(id, 'delete')
                        onClose()
                    }}
                >
                    <DeleteIcon />
                </CustomMenuItem>
            ]
        }
    }

    return (
        <>
            {result.error && (
                <Alert
                    sx={{ flex: '1 1 100%' }}
                    severity='error'
                >{`Unable to locate an item with the content ID: ${item.ContentID}. This may be due to the item being deleted, saved as a draft, or having expired.`}</Alert>
            )}
            {result.isLoading && <div>Loading...</div>}
            <div style={{ display: 'flex' }}>
                {result.data && (
                    <>
                        <div style={{ flex: '0 0 100px' }}>
                            <ImagePreview id={item?.Overrides['ContentMedia'] || result.data.MediaID} width={90} />
                        </div>
                        <div style={{ flex: '1 1 100%' }}>
                            <Typography variant='h6'>{item?.Overrides['ContentTitle'] || result.data.Title}</Typography>

                            {!result.data.Sites.includes(appContext.currentSiteID || '') && (
                                <Alert severity='warning'>
                                    This item is not available on <em>{appContext.currentSite()?.Name}</em> site.
                                </Alert>
                            )}

                            <Typography display='flex' gap='8px'>
                                Route:
                                <Typography component={'span'} fontFamily='monospace'>
                                    {result.data?.Route || 'N/A'}
                                </Typography>
                            </Typography>
                            <div style={{ fontSize: '10px' }}>{item.ContentID}</div>
                        </div>
                    </>
                )}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <MenuLightCell itemsFactory={menuItems(item.ContentID)} />
                </Box>
            </div>
        </>
    )
}
