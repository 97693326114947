import React, { useEffect, useState } from 'react'
import { Alert, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { z } from 'zod'
import { BusRouteAPI } from '../../common/constants'
import { httpGet } from '../../common/client'
import { notify } from '../../helpers'

const busRoute = z.object({
    id: z.string(),
    name: z.string(),
    areas: z.array(z.string()),
    active: z.boolean()
})
const busRoutes = z.array(busRoute)
type BusRoute = z.infer<typeof busRoute>

type BusRouteSelectorProps = {
    selected?: string | null
    onChange?: (selected: string) => void
}
export const BusRouteSelector = ({ selected, onChange }: BusRouteSelectorProps) => {
    const [options, setOptions] = useState<BusRoute[]>([])
    const [value, setValue] = useState<string>(selected || '')
    const [noBusRoutes, setNoBusRoutes] = useState('')

    async function getRoutes() {
        setNoBusRoutes('')
        return httpGet(`${BusRouteAPI}?useSiteId=0`, null, busRoutes)
            .then((res) => {
                setOptions(res)
                if (Array.isArray(res) && res.length === 0) {
                    setNoBusRoutes('No Bus Routes Found. You can`t create a Topic for a Bus Route without a Bus Route.')
                }
            })
            .catch((err) => {
                console.error(err)
                notify(err)
            })
    }

    useEffect(() => {
        getRoutes()
    }, [])
    useEffect(() => {
        if (options.length === 0) return
        if (selected) {
            setValue(selected)
        } else {
            setValue(options[0].id)
            onChange && onChange(options[0].id)
        }
    }, [options])

    useEffect(() => {
        if (selected && options.some((o) => o.id === selected)) {
            setValue(selected)
        }
    }, [selected])

    if (options.length === 0 && noBusRoutes) return <Alert severity='error'>{noBusRoutes}</Alert>
    if (options.length === 0) return <div>Loading...</div>

    return (
        <FormControl fullWidth>
            <InputLabel>Bus Route</InputLabel>
            <Select
                label='Bus Route'
                value={value}
                renderValue={(v) => {
                    const sel = options.filter((o) => o.id === v)[0]
                    return sel.name
                }}
                onChange={(v) => onChange && onChange(v.target.value as string)}
            >
                {options.map((o, i) => (
                    <MenuItem key={o.id} value={o.id}>
                        {o.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
