import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react'
import { BASE } from '@/common/constants'
import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Typography } from '@mui/material'
import { BaseTemplate, TemplateSelector } from '@/pkgs/content/editor/components/TemplateSelector'
import { StructureSelector } from '@/pkgs/structure/StructureSelector'
import { FormStructure } from '@/pkgs/structure/types'
import { CloudUpload } from '@mui/icons-material'
import { styled } from '@mui/system'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { ContentTypeSelector } from '@/pkgs/content/ContentTypeSelector'
import { Content, ContentEssentials, ContentLike, ContentType } from '@/pkgs/content/types'
import { asSecured } from '@/pkgs/auth/permissions/securityMapping'
import { BaseForm } from '@/pkgs/content/BaseForm'
import { TagsSelectorForContent } from '@/common/components/selectors/TagSelectorForContent'
import { TagType } from '@/pkgs/system/tags/types'
import Alert from '@mui/material/Alert'
import { contentValidators } from '@/pkgs/content/validators'
import { guessErrorMessage } from '@/helpers/guessErrorMessage'
import { z } from 'zod'
import { httpPost } from '@/common/client'
import { useTimezone } from '@/common/editor/EventDateTimePickerV2'

const successSchema = z.object({
    Success: z.literal(true),
    Message: z.string(),
    XLSXImportID: z.string(),
    Rows: z.number()
})

const errorSchema = z.object({
    Success: z.literal(false),
    ErrorMessage: z.string()
})

const importResult = z.discriminatedUnion('Success', [successSchema, errorSchema])
type ImportResult = z.infer<typeof importResult>

interface ImportFormParams {
    open: boolean
    onClose: () => void
    contentTypes?: ContentLike[]
}

const defaultState: ContentEssentials = {
    Path: '',
    PrivacyLevel: 0,
    Type: 'event',
    Sites: [],
    DepartmentID: null,
    PublishAt: null,
    ExpireAt: null,
    StructureID: null,
    Tags: []
}
export const ImportForm = ({ open, onClose, contentTypes }: ImportFormParams) => {
    const timezone = useTimezone()
    if (contentTypes && Array.isArray(contentTypes) && contentTypes.length > 0) {
        defaultState.Type = contentTypes[0]
    }

    const [file, setFile] = useState<File | null>(null)
    const [state, setState] = useState<ContentEssentials>(defaultState)
    const [errors, setErrors] = useState<Partial<Record<keyof ContentEssentials, string>>>({})
    const [result, setResult] = useState<ImportResult | undefined>(undefined)

    const [structure, setStructure] = useState<FormStructure[] | undefined>(undefined)
    const [selectedTemplate, setSelectedTemplate] = useState<BaseTemplate | undefined>(undefined)

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            console.log(e.target.files[0])
            setFile(e.target.files[0])
        }
    }

    useEffect(() => {
        if (Object.keys(errors).length) {
            validateForm()
        }
    }, [state])

    const validateForm = () => {
        setErrors({})
        let errors: Partial<Record<keyof ContentEssentials, string>> = {}
        Object.entries(state).forEach(([key, value]) => {
            errors = { ...errors, ...contentValidators[key as keyof ContentEssentials](state as Content) }
        })
        setErrors(errors)
        return Object.values(errors).every((v) => !v)
    }

    const handleSubmit = async () => {
        // Validate form using contentValidators
        const isValid = validateForm()
        if (!isValid) {
            return
        }

        if (!file) {
            alert('Please select a file')
            return
        }

        const data = new FormData()

        data.append('File', file)
        data.append('Timezone', timezone)
        Object.entries(state).forEach(([key, value]) => {
            if (value) {
                if (Array.isArray(value)) {
                    value.forEach((v) => data.append(key, v))
                } else if (value instanceof Date) {
                    data.append(key, value.toISOString())
                } else if (Number.isFinite(value)) {
                    data.append(key, value.toString())
                } else if (typeof value === 'string') {
                    data.append(key, value)
                }
            }
        })

        try {
            const response = await fetch(`${BASE}/api/v2/content/import/xlsx`, {
                method: 'POST',
                body: data
            })

            setFile(null)

            const rdata = await response.json()
            setResult(importResult.parse(rdata))
        } catch (error) {
            console.error('Error:', error)
            alert(`An error occurred while uploading the file: ${guessErrorMessage(error)}`)
        }
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>Import XLSX</DialogTitle>
            <IconButton
                aria-label='close'
                onClick={() => onClose()}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500]
                }}
            >
                <CloseIcon />
            </IconButton>

            <DialogContent>
                <div>
                    {result && (
                        <FormControl fullWidth sx={{ my: 1 }}>
                            <Typography variant='h5'>Result</Typography>
                            {result.Success ? (
                                <Alert severity='success' onClose={() => setResult(undefined)}>
                                    {result.Rows} rows imported.
                                    <br />
                                    You can search for imported events using the query:
                                    <pre>settings.XLSXImportID:{result.XLSXImportID}</pre>
                                </Alert>
                            ) : (
                                <Alert severity='error'>
                                    {result.ErrorMessage.split('|').map((l, index) => (
                                        <li key={index}>{l}</li>
                                    ))}
                                </Alert>
                            )}
                        </FormControl>
                    )}
                    {!result && (
                        <>
                            <div>
                                <Button
                                    component='label'
                                    role={undefined}
                                    variant='contained'
                                    tabIndex={-1}
                                    startIcon={<CloudUpload />}
                                >
                                    Select file
                                    <VisuallyHiddenInput type='file' onChange={handleFileChange} required />
                                </Button>
                                <span style={{ marginLeft: 8 }}>{file ? file.name : 'No file selected'}</span>
                            </div>

                            <FormControl fullWidth sx={{ my: 1 }}>
                                <Typography variant='h5'>Defaults</Typography>
                                <Alert severity='info'>
                                    The dates will be parsed in the timezone: <strong>{timezone}</strong>
                                </Alert>
                                <Alert severity='info'>
                                    These values will be applied to all imported content if not specified in the file.
                                </Alert>
                            </FormControl>

                            <FormControl fullWidth sx={{ my: 1 }}>
                                <ContentTypeSelector
                                    availableTypes={contentTypes ? contentTypes : [ContentType.Event, ContentType.News]}
                                    value={state.Type as ContentLike}
                                    onChange={(t) => setState({ ...state, Type: t, Path: '' })}
                                />
                            </FormControl>

                            <FormControl fullWidth sx={{ my: 1 }}>
                                <TemplateSelector
                                    required
                                    ownerID={''}
                                    path={state.Path}
                                    onChange={(path, tpl) => {
                                        setState({ ...state, Path: path })
                                        setSelectedTemplate(tpl)
                                    }}
                                    onLoaded={(tpl) => setSelectedTemplate(tpl)}
                                    templateType={'all'}
                                    classifications={[state.Type]}
                                    error={errors.Path}
                                />
                            </FormControl>
                            <FormControl fullWidth sx={{ my: 1 }}>
                                {state.Path && (
                                    <StructureSelector
                                        value={state.StructureID}
                                        onChange={(v, s) => {
                                            setState({ ...state, StructureID: v || null })
                                            setStructure(s?.FormStructure)
                                        }}
                                        selectedStructure={(s) => !structure && setStructure(s.FormStructure)}
                                        allowedStructures={selectedTemplate?.Structures || []}
                                        error={errors.StructureID}
                                        required={true}
                                    />
                                )}
                                {!state.Path && (
                                    <Alert severity={'warning'}>Select a template to choose a structure</Alert>
                                )}
                            </FormControl>

                            <FormControl fullWidth sx={{ my: 1 }}>
                                <BaseForm
                                    value={{
                                        PublishAt: state.PublishAt,
                                        ExpireAt: state.ExpireAt,
                                        PrivacyLevel: state.PrivacyLevel,
                                        Sites: state.Sites,
                                        DepartmentID: state.DepartmentID
                                    }}
                                    onChange={(b) => {
                                        setState((prev) => ({
                                            ...prev,
                                            PublishAt: b.PublishAt,
                                            ExpireAt: b.ExpireAt,
                                            PrivacyLevel: b.PrivacyLevel,
                                            Sites: b.Sites,
                                            DepartmentID: b.DepartmentID
                                        }))
                                    }}
                                    contentType={asSecured(state).EntityScope}
                                    errors={errors}
                                />
                            </FormControl>

                            <FormControl fullWidth sx={{ my: 1 }}>
                                <TagsSelectorForContent
                                    selected={state.Tags || []}
                                    tagType={TagType.Content}
                                    onChange={(tags) => {
                                        tags && setState({ ...state, Tags: tags })
                                    }}
                                />
                            </FormControl>
                        </>
                    )}
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant='text' onClick={() => onClose()}>
                    Close
                </Button>

                {!result && (
                    <Button onClick={handleSubmit} size={'medium'} variant={'contained'}>
                        Import
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1
})
