import { useEffect, useState } from 'react'
import { updateStructureQuery, useStructureByIdQuery } from './queries'
import { Box, Button, Card, CardContent, FormHelperText } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { notify } from '../../helpers'
import StructureForm, { StructureDTO } from './StructureForm'
import PageContainerWithHeader from '../../common/components/PageContainerWithHeader'
import { tryParseJson } from '../../helpers/parseJSON'
import { useParams } from 'react-router-dom'
import { FormStructure, parseFormStructure } from './types'
import { BackButton } from '../../common/components'

function EditStructure() {
    const params = useParams()
    const { data, refetch } = useStructureByIdQuery(params.id || '')

    const [submitError, setSubmitError] = useState('')
    const structureMutation = useMutation({
        mutationFn: updateStructureQuery,
        onSuccess: (data) => {
            notify('Success! Structure has been updated', 'info')
            refetch()
        },
        onError: (err: any) => {
            notify('Oops! Structure could not be updated', 'error')
            const errorMessage = err?.response?.data?.ErrorMessage
            setSubmitError(errorMessage)
        }
    })

    const safeStringify = (data: any) => {
        if (!data) {
            return ''
        }
        try {
            return JSON.stringify(data, null, 4)
        } catch (e) {
            console.error('Cant stringify data')
            console.error(e)
            return ''
        }
    }

    const dtoFromData = {
        Name: data?.Name || '',
        Template: data?.Template || '',
        FormStructure: safeStringify(data?.FormStructure)
    }
    const [structureFormData, setStructureFormData] = useState<StructureDTO>(dtoFromData)

    useEffect(() => {
        setStructureFormData(dtoFromData)
        setSubmitError('')
    }, [data])

    function handleSave() {
        if (structureFormData) {
            const { error, data } = parseFormStructure(structureFormData.FormStructure)
            if (error) {
                setSubmitError(error.message || '')
                notify(`Oops! Structure could not be updated: ${error.message}`, 'error')
            } else {
                structureMutation.mutate({
                    id: params.id || '',
                    structure: {
                        Name: structureFormData.Name,
                        Template: structureFormData.Template,
                        FormStructure: data
                    }
                })
            }
        }
    }

    return (
        <PageContainerWithHeader isSubtitle title='Edit Structure' topRightElement={<BackButton route='/structure' />}>
            <Card sx={{ overflow: 'visible' }}>
                <CardContent className='no-padding'>
                    <StructureForm
                        value={structureFormData}
                        onChange={(value: StructureDTO) => setStructureFormData(value)}
                        onSubmit={handleSave}
                        onReset={() => {
                            setStructureFormData(dtoFromData)
                        }}
                        formControlSection={
                            <Box display='flex' flexDirection='column' alignItems='flex-end'>
                                <Box className='box-row' justifyContent='flex-end'>
                                    <Button variant='text' color='primary' type='reset'>
                                        Reset Changes
                                    </Button>
                                    <Button variant='contained' color='primary' type='submit'>
                                        Save
                                    </Button>
                                </Box>
                                {submitError?.length ? (
                                    <FormHelperText error={true}>{submitError}</FormHelperText>
                                ) : null}
                            </Box>
                        }
                    />
                </CardContent>
            </Card>
        </PageContainerWithHeader>
    )
}

export default EditStructure
