import React, { Fragment, useState } from 'react'
import { IconButton, Menu, MenuItem } from '@mui/material'
import { authenticationService } from '../../pkgs/auth/authentication.service'
import { AppbarHelp } from './AppbarHelp'
import AppbarSettings from './AppbarSettings'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import MoreIcon from '@mui/icons-material/MoreVert'

// TODO => Sign out from mobile icon is not operational

const mobileMenuId = 'primary-search-account-menu-mobile'
export const AppbarMobileMenu = () => {
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null)
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget)
    }
    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null)
    }
    const endSession = () => {
        authenticationService.logout()
    }

    return (
        <Fragment>
            <IconButton
                aria-label='show more'
                aria-controls={mobileMenuId}
                aria-haspopup='true'
                onClick={handleMobileMenuOpen}
                color='inherit'
                size='large'
            >
                <MoreIcon />
            </IconButton>

            <Menu
                anchorEl={mobileMoreAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id={mobileMenuId}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMobileMenuOpen}
                onClose={handleMobileMenuClose}
            >
                <AppbarSettings mobile />
                <AppbarHelp mobile />

                <MenuItem onClick={endSession}>
                    <IconButton aria-label='Logout' color='inherit' size='large' data-testid={'logout-mobile'}>
                        <PowerSettingsNewIcon />
                    </IconButton>
                    <p>Logout</p>
                </MenuItem>
            </Menu>
        </Fragment>
    )
}
