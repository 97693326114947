import { httpGet } from '../../../common/client'
import { Relay, relays } from './types'
import { notify } from '../../../helpers'
import React, { useEffect, useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { RelaysAPI } from '../../../common/constants'

type RelaySelectorProps = {
    selected?: string | null
    onChange?: (selected: string) => void
}
export const RelaySelector = ({ selected, onChange }: RelaySelectorProps) => {
    const [options, setOptions] = useState<Relay[]>([])
    const [value, setValue] = useState<string>('')

    async function getRelays() {
        return httpGet(`${RelaysAPI}`, { page: 1, pageSize: 100 }, relays)
            .then((res) => {
                setOptions(res.Rows)
            })
            .catch((err) => {
                console.error(err)
                notify(err)
            })
    }

    useEffect(() => {
        getRelays()
    }, [])
    useEffect(() => {
        if (options.length === 0) return
        if (selected) {
            setValue(selected)
        } else {
            setValue(options[0].ID)
            onChange && onChange(options[0].ID)
        }
    }, [options])

    if (options.length === 0) return <div>Loading...</div>

    return (
        <FormControl fullWidth>
            <InputLabel>Relay</InputLabel>
            <Select
                value={value}
                renderValue={(v) => {
                    const sel = options.filter((o) => o.ID === v)[0]
                    return `${sel.XData.Username}@${sel.XData.Host}`
                }}
                label='State'
                onChange={(v) => onChange && onChange(v.target.value as string)}
            >
                {options.map((o, i) => (
                    <MenuItem key={o.ID} value={o.ID}>{`${o.XData.Username}@${o.XData.Host}`}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
