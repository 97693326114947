import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'

import Editor, { Monaco, useMonaco } from '@monaco-editor/react'
import { editor, IKeyboardEvent } from 'monaco-editor'
import IStandaloneCodeEditor = editor.IStandaloneCodeEditor

type language =
    | 'javascript'
    | 'typescript'
    | 'json'
    | 'css'
    // no validation for the following types
    | 'html'
    | 'handlebars'
    | 'markdown'
    | 'xml'
    | 'yaml'
    | 'plaintext'
    | 'sql'

export interface CodeEditorProps {
    value: string
    language: language
    onChange: (v: string | undefined) => void
    onValidate?: (v: editor.IMarker[]) => void
    disabled?: boolean
    height?: string
}

export interface CodeEditorHandler {
    getEditor: () => IStandaloneCodeEditor | null
}

// Monaco code editor
export const CodeEditor = forwardRef<CodeEditorHandler, CodeEditorProps>(
    ({ value, language, onChange, onValidate, disabled, height = '40vh' }: CodeEditorProps, ref) => {
        const editorRef = useRef<IStandaloneCodeEditor | null>(null)
        const monaco = useMonaco()

        useEffect(() => {
            // do conditional chaining
            monaco?.languages.typescript.javascriptDefaults.setEagerModelSync(true)
            // or make sure that it exists by other ways
            if (monaco) {
                console.log('here is the monaco instance:', monaco)
            }
        }, [monaco, disabled])

        function handleEditorDidMount(editor: IStandaloneCodeEditor, monaco: Monaco) {
            editorRef.current = editor
            editor.onKeyDown(selfClosingTagsListener(editor, monaco, language))
        }

        useImperativeHandle(ref, () => {
            return {
                getEditor: () => editorRef?.current
            }
        })

        return (
            <Editor
                keepCurrentModel={true}
                height={height}
                value={value}
                language={language}
                theme='vs-dark'
                onChange={(v, e) => {
                    onChange(v)
                    if (language === 'html' && v) {
                        const result = isStringValidHtml(v, 'text/html')
                        if (result.isParseErrorAvailable) {
                            console.log('parse error:', result)
                        }
                    }
                }}
                onValidate={(ee) => onValidate && onValidate(ee)}
                options={{
                    readOnly: disabled,
                    minimap: {
                        enabled: !disabled
                    }
                }}
                onMount={(e, m) => handleEditorDidMount(e, m)}
            />
        )
    }
)

export function isStringValidHtml(
    html: string,
    mimeType: DOMParserSupportedType = 'application/xml'
): { [key: string]: any } {
    const domParser: DOMParser = new DOMParser()
    const doc: Document = domParser.parseFromString(html, mimeType)
    const parseError: Element | null = doc.documentElement.querySelector('parsererror')
    const result: { [key: string]: any } = {
        isParseErrorAvailable: parseError !== null,
        isStringValidHtml: false,
        parsedDocument: ''
    }

    if (parseError !== null && parseError.nodeType === Node.ELEMENT_NODE) {
        console.error('parse error:', parseError)
        result.parsedDocument = parseError.outerHTML
    } else {
        result.isStringValidHtml = true
        result.parsedDocument =
            typeof doc.documentElement.textContent === 'string' ? doc.documentElement.textContent : ''
    }

    return result
}

/*
monaco.editor.setModelMarkers(window.editor.getModel(), 'owner', [
    {
        message: "import not found",
        severity: monaco.MarkerSeverity.Error,
        startLineNumber: 0,
        startColumn: 0,
        endLineNumber: 0,
        endColumn: 2,
    },
]);
* */

export const selfClosingTagsListener =
    (editor: IStandaloneCodeEditor, monaco: Monaco, language: language) => (event: IKeyboardEvent) => {
        // select enabled languages
        const enabledLanguages = ['html', 'markdown', 'javascript', 'typescript'] // enable js & ts for jsx & tsx

        const model = editor.getModel()
        if (!enabledLanguages.includes(language)) {
            return
        }

        const isSelfClosing = (tag: string) =>
            [
                'area',
                'base',
                'br',
                'col',
                'command',
                'embed',
                'hr',
                'img',
                'input',
                'keygen',
                'link',
                'meta',
                'param',
                'source',
                'track',
                'wbr',
                'circle',
                'ellipse',
                'line',
                'path',
                'polygon',
                'polyline',
                'rect',
                'stop',
                'use'
            ].includes(tag)

        // when the user enters '>'
        if (event.browserEvent.key === '>' && model && !model.isDisposed()) {
            const currentSelections = editor.getSelections() || []

            const edits: any[] = []
            const newSelections: any[] = []
            // potentially insert the ending tag at each of the selections
            for (const selection of currentSelections) {
                // shift the selection over by one to account for the new character
                newSelections.push(
                    new monaco.Selection(
                        selection.selectionStartLineNumber,
                        selection.selectionStartColumn + 1,
                        selection.endLineNumber,
                        selection.endColumn + 1
                    )
                )
                // grab the content before the cursor
                const contentBeforeChange = model.getValueInRange({
                    startLineNumber: 1,
                    startColumn: 1,
                    endLineNumber: selection.endLineNumber,
                    endColumn: selection.endColumn
                })

                // if ends with a HTML tag we are currently closing
                const match = contentBeforeChange.match(/<([\w-]+)(?![^>]*\/>)[^>]*$/)
                if (!match) {
                    continue
                }

                const [fullMatch, tag] = match

                // skip self-closing tags like <br> or <img>
                if (isSelfClosing(tag) || fullMatch.trim().endsWith('/')) {
                    continue
                }

                // add in the closing tag
                edits.push({
                    range: {
                        startLineNumber: selection.endLineNumber,
                        startColumn: selection.endColumn + 1, // add 1 to offset for the inserting '>' character
                        endLineNumber: selection.endLineNumber,
                        endColumn: selection.endColumn + 1
                    },
                    text: `</${tag}>`
                })
            }

            // wait for next tick to avoid it being an invalid operation
            setTimeout(() => {
                editor.executeEdits(model.getValue(), edits, newSelections)
            }, 0)
        }
    }
