import { Image } from './MediaExplorer'
import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    InputAdornment,
    TextField,
    Typography
} from '@mui/material'
import React, { useEffect } from 'react'
import { SiteSelectorForContent } from '../../../common/components/selectors/SiteSelectorForContent'
import { z } from 'zod'
import { TagsSelectorForContent } from '../../../common/components/selectors/TagSelectorForContent'
import axios from 'axios'
import { notify } from '../../../helpers'
import { ImageLoader, MediaObject } from '../upload/ImageLoader'
import { EntityScopeEnum } from '../../auth/entityScope'
import { useAppContext } from '../../auth/atoms'
import { ImagesAPI, MEDIA_THUMBNAILS } from '../../../common/constants'
import { TagType } from '../../system/tags/types'

const imageSchema = z.object({
    id: z.string(),
    type: z.string(),
    department_id: z.string().nullish(),
    sites: z.array(z.string()).nonempty('Please select at least one site'),
    filename: z
        .string()
        .regex(/^[a-zA-Z0-9-_ ]+\.[a-z]{3,4}$/i, 'Filename can only contain letters, numbers, dashes and underscores'),
    alt: z.string().min(3, 'Alt text must be at least 3 characters long'),
    tags: z.array(z.string()).nullish(),
    thumbnail: z.string().nullish(),
    data: z.string().nullish()
})

type ImageData = z.infer<typeof imageSchema>

type MediaEditorProps = {
    image: Image
    onClose?: () => void
}
export const MediaEditorDialog = ({ image, onClose }: MediaEditorProps) => {
    const [state, setState] = React.useState(image)
    const [newMedia, setNewMedia] = React.useState<MediaObject | null>(null)
    const [errors, setErrors] = React.useState<Record<string, string>>({})
    const [loading, setLoading] = React.useState(false)
    const [savingError, setSavingError] = React.useState(false)
    const appContext = useAppContext()

    const extension = state.filename.split('.').pop()

    useEffect(() => {
        try {
            imageSchema.parse(state)
            setErrors({})
        } catch (e) {
            const zError = e as z.ZodError
            zError.errors.forEach((err) => {
                setErrors((prev) => ({ ...prev, [err.path[0]]: err.message }))
            })
        }
    }, [state])

    const update = async (media: ImageData) => {
        setLoading(true)
        try {
            await axios.put(ImagesAPI, media)
            notify('Media updated', 'info')
            onClose && onClose()
        } catch (error) {
            notify(error, 'error')
            setSavingError(true)
        } finally {
            setLoading(false)
        }
    }
    const deleteImage = (id: string) => {
        setLoading(true)
        axios
            .delete(`${ImagesAPI}/${id}`)
            .then((response) => {
                notify('Media deleted', 'info')
                onClose && onClose()
            })
            .catch((error) => {
                notify(error, 'error')
                setLoading(false)
                setSavingError(true)
            })
    }

    const preview = newMedia
        ? `url(${newMedia.thumbnail})`
        : `url(${MEDIA_THUMBNAILS}/${image.id}?updated=${image.updated.toISOString()})`
    return (
        <Dialog open={true} onClose={onClose} fullWidth={true} sx={{ maxHeight: '80vh' }}>
            {image && (
                <DialogContent>
                    <ImageLoader
                        preview={preview}
                        onChange={(media) => {
                            console.log(media)
                            setNewMedia(media)
                            // setState((prev) => (prev && {...prev, ...media}))
                        }}
                        additionalText='Click on image to replace'
                        maxFiles={1}
                    />
                    <FormControl fullWidth sx={{ my: 1 }}>
                        <TextField
                            margin={'dense'}
                            error={!!errors.alt}
                            label='Alt'
                            value={state.alt}
                            onChange={(e) => setState((prev) => prev && { ...prev, alt: e.target.value })}
                            fullWidth
                        />
                        {errors.alt && (
                            <Typography variant={'caption'} color={'error'}>
                                {errors.alt}
                            </Typography>
                        )}
                    </FormControl>
                    <FormControl fullWidth sx={{ my: 1 }}>
                        <SiteSelectorForContent
                            HasError={!!errors.sites}
                            ContentType={EntityScopeEnum.Image}
                            Selected={state.sites}
                            OnChange={(sites) => setState((prev) => prev && { ...prev, sites: sites })}
                        />
                        {errors.sites && (
                            <Typography variant={'caption'} color={'error'}>
                                {errors.sites}
                            </Typography>
                        )}
                    </FormControl>
                    <FormControl fullWidth sx={{ my: 1 }}>
                        <TagsSelectorForContent
                            selected={state?.tags || []}
                            disabled={false}
                            tagType={TagType.Media}
                            onChange={(tags) => setState((prev) => prev && { ...prev, tags: tags })}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ my: 1 }}>
                        <TextField
                            error={!!errors.filename}
                            margin={'dense'}
                            label='Filename'
                            value={state?.filename.split('.').shift()}
                            onChange={(e) =>
                                setState((prev) => prev && { ...prev, filename: `${e.target.value}.${extension}` })
                            }
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position={'end'}>
                                        <Chip label={`.${extension}`} />
                                    </InputAdornment>
                                )
                            }}
                        />
                        {errors.filename && (
                            <Typography variant={'caption'} color={'error'}>
                                {errors.filename}
                            </Typography>
                        )}
                    </FormControl>
                </DialogContent>
            )}
            <DialogActions>
                <Box sx={{ display: 'flex', width: '100%', px: 2 }}>
                    <Button
                        disabled={loading || !appContext.action(state, 'delete')}
                        style={{ marginRight: 'auto' }}
                        variant='outlined'
                        color='error'
                        onClick={() => {
                            if (window.confirm('Are you sure you want to delete this image?')) {
                                deleteImage(state.id)
                            }
                        }}
                    >
                        Delete
                    </Button>

                    <Button onClick={onClose} disabled={loading}>
                        Cancel
                    </Button>

                    <Button
                        disabled={loading || Object.keys(errors).length > 0 || !appContext.action(state, 'update')}
                        variant='contained'
                        color='success'
                        autoFocus
                        onClick={() => {
                            const data = imageSchema.parse(state)
                            if (newMedia) {
                                data.data = newMedia.data
                                data.thumbnail = newMedia.thumbnail
                            }
                            update(data)
                        }}
                    >
                        {loading ? 'Saving...' : savingError ? 'Retry' : newMedia ? 'Replace' : 'Save'}
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
}
