/**
 * @param {Object} object - An object with keys to be checked for truthfulness
 * */
export function anyKeyIsTrue(object) {
    for (const item in object) {
        if (object[item]) {
            return true
        }
    }
    return false
}
