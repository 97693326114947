import { INSERT_HORIZONTAL_RULE_COMMAND } from '@lexical/react/LexicalHorizontalRuleNode'
import { LexicalEditor } from 'lexical'
import { MutableRefObject, useEffect, useRef } from 'react'
import { INSERT_EMBED_COMMAND } from '@lexical/react/LexicalAutoEmbedPlugin'
import { EmbedConfigs } from '../Embed/AutoEmbedPlugin'

// src: https://codesandbox.io/s/lexical-rich-text-example-5tncvy?from-embed=&file=/src/Editor.js

interface InsertOptionsDropdownListProps {
    editor: LexicalEditor
    blockType: string
    buttonRef: MutableRefObject<HTMLElement | null>
    toolbar?: boolean[]
    closeDropdown: () => void
    tableButtonOnClick: () => void
    imageButtonOnClick: () => void
    contentFragmentButtonOnClick: () => void
    collapsibleButtonOnClick: () => void
    codeEmbedButtonOnClick: () => void
}

export function InsertOptionsDropdownList({
    editor,
    blockType,
    buttonRef,
    closeDropdown,
    tableButtonOnClick,
    imageButtonOnClick,
    contentFragmentButtonOnClick,
    collapsibleButtonOnClick,
    codeEmbedButtonOnClick,
    toolbar
}: InsertOptionsDropdownListProps) {
    const dropDownRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        const button = buttonRef?.current
        const dropDown = dropDownRef.current
        if (button !== null && dropDown !== null) {
            dropDown.style.top = `${button.offsetTop + 40}px`
            dropDown.style.left = `${button.offsetLeft}px`
        }
    }, [dropDownRef, buttonRef])

    useEffect(() => {
        const dropDown = dropDownRef.current
        const button = buttonRef.current

        if (dropDown !== null && button !== null) {
            const handle = (event) => {
                const target = event.target

                if (!dropDown.contains(target) && !button.contains(target)) {
                    closeDropdown()
                }
            }
            document.addEventListener('click', handle)

            return () => {
                document.removeEventListener('click', handle)
            }
        }
    }, [dropDownRef, closeDropdown, buttonRef])

    // TODO: Refactor below
    const [horizontal_rule, image, table, video] = toolbar || []

    return (
        <div className='dropdown' ref={dropDownRef}>
            {horizontal_rule && (
                <button
                    className='item'
                    onClick={() => {
                        editor.dispatchCommand(INSERT_HORIZONTAL_RULE_COMMAND, undefined)
                        closeDropdown()
                    }}
                >
                    <span className='material-icons-outlined' style={{ fontSize: '21px', paddingRight: 5 }}>
                        horizontal_rule
                    </span>
                    <span className='text'>Divider</span>
                </button>
            )}
            {table && (
                <button className='item' onClick={tableButtonOnClick}>
                    <span className='material-icons-outlined' style={{ fontSize: '21px', paddingRight: 5 }}>
                        calendar_view_month
                    </span>
                    <span className='text'>Table</span>
                </button>
            )}
            {image && (
                <button className='item' onClick={imageButtonOnClick}>
                    <span className='material-icons-outlined' style={{ fontSize: '21px', paddingRight: 5 }}>
                        image
                    </span>
                    <span className='text'>Image</span>
                </button>
            )}

            {video && (
                <>
                    {EmbedConfigs.map((embedConfig) => {
                        return (
                            <button
                                key={embedConfig.contentName}
                                className='item'
                                onClick={() => {
                                    editor.dispatchCommand(INSERT_EMBED_COMMAND, embedConfig.type)
                                }}
                            >
                                {embedConfig.icon}
                                <span className='text' style={{ textAlign: 'left' }}>
                                    {embedConfig.contentName}
                                </span>
                            </button>
                        )
                    })}
                </>
            )}
            <button className='item' onClick={contentFragmentButtonOnClick}>
                <span className='material-icons-outlined' style={{ fontSize: '21px', paddingRight: 5 }}>
                    dashboard
                </span>
                <span className='text' style={{ textAlign: 'left' }}>
                    Content Fragment
                </span>
            </button>
            <button className='item' onClick={collapsibleButtonOnClick}>
                <span className='material-icons-outlined' style={{ fontSize: '21px', paddingRight: 5 }}>
                    expand_circle_down
                </span>
                <span className='text' style={{ textAlign: 'left' }}>
                    Collapsible Box
                </span>
            </button>
            <button className='item' onClick={codeEmbedButtonOnClick}>
                <span className='material-icons-outlined' style={{ fontSize: '21px', paddingRight: 5 }}>
                    code
                </span>
                <span className='text' style={{ textAlign: 'left' }}>
                    Embed HTML
                </span>
            </button>
        </div>
    )
}

export default InsertOptionsDropdownList
