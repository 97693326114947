import { Item, List } from './types'
import { useContentDetailsQuery } from '../content/queries'
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Switch,
    TextField,
    Typography
} from '@mui/material'
import Button from '@mui/material/Button'
import React from 'react'
import FormRenderer from '../form-renderer/FormRenderer'
import { notify } from '../../helpers'
import { DateTimeNullable } from '../../common/components/dates/DateTimeNullable'
import { ImagePreview, MediaIDSelector } from '../media/image/MediaIDSelector'
import { guessErrorMessage } from '../../helpers/guessErrorMessage'
import { ContentType } from '../content/types'

const defaultOverrides: Record<string, any> = {}

type ItemEditorProps = {
    item: Item
    onSave: (item: Item) => void
    onCancel: () => void
    list: List
    overridables: string[]
}

export function ItemEditor({ item, onSave, overridables, onCancel, list }: ItemEditorProps) {
    const isContentLike = !list.ContentTypes.includes(ContentType.Fragment)
    item.Overrides = item.Overrides || defaultOverrides

    const [activeOverrides, setActiveOverrides] = React.useState<string[]>(
        item.Overrides ? Object.keys(item.Overrides) : []
    )
    const [open, setOpen] = React.useState(!!item)
    const [state, setState] = React.useState(item)
    const formRendererRefs = React.useRef<any[]>([])

    const result = useContentDetailsQuery(item.ContentID)

    React.useEffect(() => {
        formRendererRefs.current =
            list.Structure?.FormStructure.map((_, i) => formRendererRefs.current[i] ?? React.createRef()) || []
    }, [list])

    const canBeOverridden = (key: string) => {
        return overridables.includes(key)
    }

    const getDefaultValue = (key: string) => {
        switch (key) {
            case 'ContentTitle':
                return result?.data?.Title || ''
            case 'ContentExpireAt':
                return result?.data?.ExpireAt || null
            case 'ContentDescription':
                return result?.data?.Settings?.seoDescription || ''
            case 'ContentMedia':
                return result?.data?.MediaID || null
            default:
                if (result?.data?.Data && result.data.Data[key]) return result?.data?.Data[key]

                const allowMultiple = !!list?.Structure?.FormStructure.find((s) => s.name === key)?.allowMultiple
                return allowMultiple ? [] : {}
        }
    }
    const toggleOverride = (key: string) => {
        if (activeOverrides.includes(key)) {
            setActiveOverrides(activeOverrides.filter((o) => o !== key))
            const newOverrides = { ...state.Overrides }
            delete newOverrides[key]
            setState({
                ...state,
                Overrides: newOverrides
            })
        } else {
            setActiveOverrides([...activeOverrides, key])
            setState({
                ...state,
                Overrides: {
                    ...state.Overrides,
                    [key]: getDefaultValue(key)
                }
            })
        }
    }

    return (
        <Dialog open={open} onClose={onCancel} fullWidth maxWidth={'xl'}>
            <DialogTitle>Item Editor</DialogTitle>
            <DialogContent>
                {/* TODO: decide, what to display here... */}
                {result.data && (
                    <div>
                        The item is based on the content:
                        <br />
                        Title: <strong>{result.data.Title}</strong>
                        <br />
                        Type: {result.data.Type}
                    </div>
                )}
                <Typography fontSize={13}>Content id: {state.ContentID}</Typography>

                {result.isLoading && <div>Loading...</div>}
                {result.error && <div>Error: {guessErrorMessage(result.error)}</div>}
                {result.data && (
                    <div>
                        <h2>Content sections</h2>

                        {/* Title */}
                        <Box
                            sx={{
                                display: 'flex',
                                px: 1,
                                alignItems: 'start',
                                justifyContent: 'space-between',
                                marginBottom: 3
                            }}
                        >
                            <div style={{ flex: 'auto 1 1', paddingRight: '20px' }}>
                                <Typography variant={'h5'}>Title</Typography>
                                {canBeOverridden('ContentTitle') && activeOverrides.includes('ContentTitle') ? (
                                    <TextField
                                        style={{ flex: '1' }}
                                        onChange={(event) =>
                                            setState({
                                                ...state,
                                                Overrides: {
                                                    ...state.Overrides,
                                                    ContentTitle: event.target.value || ''
                                                }
                                            })
                                        }
                                        value={state.Overrides['ContentTitle'] || ''}
                                        label={'Override Title'}
                                        placeholder='Override Title'
                                        variant={'outlined'}
                                    />
                                ) : (
                                    <div>{result.data.Title}</div>
                                )}
                            </div>
                            {canBeOverridden('ContentTitle') && (
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={activeOverrides.includes('ContentTitle')}
                                            onChange={() => toggleOverride('ContentTitle')}
                                        />
                                    }
                                    label={'override'}
                                    labelPlacement={'end'}
                                />
                            )}
                        </Box>

                        {/* Content Media */}
                        <Box
                            sx={{
                                display: 'flex',
                                px: 1,
                                alignItems: 'start',
                                justifyContent: 'space-between',
                                marginBottom: 3
                            }}
                        >
                            <div style={{ flex: 'auto 1 1', paddingRight: '20px' }}>
                                <Typography variant={'h5'}>Media</Typography>
                                <div className='flex-row-between'>
                                    {canBeOverridden('ContentMedia') && activeOverrides.includes('ContentMedia') ? (
                                        <MediaIDSelector
                                            value={state.Overrides['ContentMedia'] || null}
                                            onChange={(id) =>
                                                setState({
                                                    ...state,
                                                    Overrides: {
                                                        ...state.Overrides,
                                                        ContentMedia: id
                                                    }
                                                })
                                            }
                                        />
                                    ) : (
                                        <>
                                            {result.data.MediaID && <ImagePreview id={result.data.MediaID} />}
                                            {!result.data.MediaID && <div>No media</div>}
                                        </>
                                    )}
                                </div>
                            </div>
                            {canBeOverridden('ContentMedia') && (
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={activeOverrides.includes('ContentMedia')}
                                            onChange={() => toggleOverride('ContentMedia')}
                                        />
                                    }
                                    label={'override'}
                                    labelPlacement={'end'}
                                />
                            )}
                        </Box>

                        {/* Expire At */}
                        <Box
                            sx={{
                                display: 'flex',
                                px: 1,
                                alignItems: 'start',
                                justifyContent: 'space-between',
                                marginBottom: 3
                            }}
                        >
                            <div style={{ flex: 'auto 1 1', paddingRight: '20px' }}>
                                <Typography variant={'h5'}>Expire at</Typography>
                                <div className='flex-row-between'>
                                    {canBeOverridden('ContentExpireAt') &&
                                    activeOverrides.includes('ContentExpireAt') ? (
                                        <DateTimeNullable
                                            value={state.Overrides['ContentExpireAt'] || null}
                                            onChange={(date) =>
                                                setState({
                                                    ...state,
                                                    Overrides: {
                                                        ...state.Overrides,
                                                        ContentExpireAt: date
                                                    }
                                                })
                                            }
                                            label={'Expire at'}
                                            min={result.data.PublishAt}
                                            max={result.data.ExpireAt}
                                        />
                                    ) : (
                                        <h5>{result.data.ExpireAt?.toString() || 'No expiration date'}</h5>
                                    )}
                                </div>
                            </div>
                            {canBeOverridden('ContentExpireAt') && (
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={activeOverrides.includes('ContentExpireAt')}
                                            onChange={() => toggleOverride('ContentExpireAt')}
                                        />
                                    }
                                    label={'override'}
                                    labelPlacement={'end'}
                                />
                            )}
                        </Box>

                        {/* Structure */}
                        <h2>Structure sections</h2>
                        {list.Structure?.FormStructure.map((s, index) => {
                            const key = s.name
                            const label = s.title
                            const isOverridable = canBeOverridden(key)
                            const isOverridden = activeOverrides.includes(key) || isContentLike
                            const toggle = () => toggleOverride(key)
                            return (
                                <div key={key}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            px: 1,
                                            alignItems: 'start',
                                            justifyContent: 'space-between',
                                            marginBottom: 3
                                        }}
                                    >
                                        <div style={{ flex: 'auto 1 1', paddingRight: '20px' }}>
                                            <div>
                                                <FormRenderer
                                                    ref={formRendererRefs.current[index]}
                                                    value={
                                                        state.Overrides[key]
                                                            ? state.Overrides
                                                            : { ...state.Overrides, [key]: getDefaultValue(key) }
                                                    }
                                                    onChange={(v) =>
                                                        isOverridden
                                                            ? setState({
                                                                  ...state,
                                                                  Overrides: { ...state.Overrides, ...v }
                                                              })
                                                            : {}
                                                    }
                                                    formStructure={[s]}
                                                    disabled={!isOverridden}
                                                />
                                            </div>
                                        </div>
                                        {isOverridable && (
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={isOverridden}
                                                        onChange={toggle}
                                                        disabled={isContentLike}
                                                    />
                                                }
                                                label={'override'}
                                                labelPlacement={'end'}
                                            />
                                        )}
                                    </Box>
                                </div>
                            )
                        })}
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    sx={{ mr: 2 }}
                    onClick={() => {
                        setOpen(false)
                        onCancel()
                    }}
                >
                    Cancel
                </Button>
                <Button
                    variant={'contained'}
                    onClick={() => {
                        let hasErrors = false
                        formRendererRefs.current.forEach((formRendererRef) => {
                            if (
                                formRendererRef.current &&
                                typeof formRendererRef.current.validateAndReturnErrors === 'function'
                            ) {
                                const [components] = formRendererRef.current.validateAndReturnErrors()
                                if (Array.isArray(components) && components.length) {
                                    hasErrors = true
                                }
                            }
                        })

                        if (hasErrors) {
                            notify('Please fill in all required fields', 'error')
                            return
                        }
                        onSave(state)
                    }}
                >
                    Save changes
                </Button>
            </DialogActions>
        </Dialog>
    )
}
