import _ from 'lodash'
import axios from 'axios'
import { EdsbyAuthVerifyAPI, SiteAPI } from '../../../../common/constants'

/**
 * Deprecation Notice
 * This logic has been folded into integration.service.js
 * This has been left for backwards compatibility, we should migrate existing uses of edsbyService to integrationService
 * *Keeping in mind* that while the method names are the same, the functionality has been expanded to be modular by integration.
 * (e.g)
 *  -> edsbyService.unmarshal(site) would return an array of type Config from  settings.edsby
 *  -> integrationService.unmarshal(site) now takes in a site and transforms settings.outlook & settings.edsby into arrays of configs BUT RETURNS THE SITE
 *  -> integrationService.unmarshalArray(someArray) implements the old edsbyService.unmarshal logic and returns the provided array as an array of Config
 *      -> integrationService.unmarshalArray(site.settings.edsby) is equivalent to the old edsbyService.unmarshal(site)
 * */

/**
 * Defaults (insertOnly, useDefault, default) will be applied for RCSD requirements
 * When user interface for configuring field/default combinations is completed
 * this should be reverted to all falsy values.
 * */
const Field = (useDefault, defaultValue) => ({
    insertOnly: true,
    default: defaultValue ? defaultValue : '',
    useDefault: Boolean(useDefault)
})
const ContentType = () => ({
    templateId: '',
    fields: {
        tags: Field(true),
        site: Field(false),
        privacyLevel: Field(true, '2'),
        published: Field(true, 'true')
    }
})

function Config(edsbyConfig) {
    this.schoolId = ''
    this.news = ContentType()
    this.events = ContentType()

    this.isValid = function () {
        return this.schoolId.length && Object.keys(this.news).length && Object.keys(this.events).length
    }
    this.unmarshal = function (config) {
        if (!config) return this
        const { news, events, schoolId } = config
        this.news = _.merge(this.news, news)
        this.events = _.merge(this.events, events)
        this.schoolId = schoolId || this.schoolId
        return this
    }
    this.ids = function () {
        return {
            schoolId: this.schoolId || '',
            news: this.news?.templateId || '',
            events: this.events?.templateId || ''
        }
    }

    if (edsbyConfig instanceof Config && edsbyConfig.isValid()) {
        return edsbyConfig
    } else if (typeof edsbyConfig === 'object') {
        this.unmarshal(edsbyConfig)
    }
}

const unmarshal = (site) => {
    const configurations = site?.settings?.edsby || []
    for (let i = 0; i < configurations.length; i++) {
        const config = new Config(configurations[i])
        if (config.isValid()) configurations[i] = config
    }
    // for ( const ec of configurations ) {
    //     const config = new Config(ec)
    //     const i = configurations.findIndex(c => (c.schoolId === ec.schoolId) || (c.id === ec.id))
    //     if (config.isValid() && i > -1) configurations[i] = config
    // }
    return configurations
}
const update = async (sites) => {
    try {
        const response = await axios.patch(SiteAPI, sites)
        if (response) return response.data
        else return Promise.reject()
    } catch (e) {
        return Promise.reject({ message: 'failed to update Edsby config' })
    }
}
const isTenantConfigured = async () => {
    try {
        return Boolean(await axios.get(EdsbyAuthVerifyAPI))
    } catch (e) {
        return false
    }
}
export const edsbyService = {
    Config,
    ContentType,
    Field,
    unmarshal,
    update,
    isTenantConfigured
}
