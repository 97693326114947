import React, { Suspense, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Box, IconButton } from '@mui/material'
import AppTopBar, { drawerWidth } from './AppTopBar/AppTopBar'
import { useAtom } from 'jotai'
import { bannerHeightAtom } from './AppTopBar/InstagramErrorsBanner'
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined'
import { CSSProperties } from '@emotion/serialize'
import { PreviewForDepartmentDialogAlt } from '../common/components/selectors/SiteSelectorForContent'
import { Route, Routes } from 'react-router-dom'
import { RequiresAuth } from '../pkgs/auth/components/RequiresAuth'
import { RequiresAdmin } from '../pkgs/auth/components/RequiresAdmin'

import { useAppContext, useIdentity } from '../pkgs/auth/atoms'
import { useIdentityAndSitesQuery } from '../pkgs/auth/queries'
import { sitesEventChanel } from '../common/components/selectors/SiteSelectorForAccount'
import { notify } from '../helpers'
import { routes } from './routes'
import { Authenticate, LoadingScreen } from '../pkgs/auth/components/Authenticate'
import '../pkgs/auth/components/Authenticate.css'
import { ContentEditor } from '../pkgs/content/editor/ContentEditor'
import { RelatedContentGrid } from '../pkgs/structure/RelatedContentGrid'
import { EntityScopeEnum } from '../pkgs/auth/entityScope'
import { clientStorage } from '../common/client'
import { colours } from '../common/colours'
import { LexicalTesting } from '../pkgs/lexical-editor/LexicalTesting'

// basically just padding to ensure content is not behind top bar
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
}))

type MainProps = {
    open: boolean
}

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<MainProps>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: `${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0
    })
}))

export function ScrollToTopIconButton({
    onClick,
    iconButtonSxProps
}: {
    onClick: () => void
    iconButtonSxProps?: CSSProperties
}) {
    return (
        <IconButton
            title='Scroll to Top'
            onClick={onClick}
            size='large'
            sx={{
                backgroundColor: colours.base_blue,
                position: 'fixed',
                bottom: '24px',
                right: '50%',
                '&:hover': {
                    backgroundColor: colours.base_blue_hover
                },
                ...iconButtonSxProps
            }}
        >
            <ArrowUpwardOutlinedIcon sx={{ color: colours.white }} />
        </IconButton>
    )
}

function Helm({ children, title }: { children: JSX.Element; title: string }): JSX.Element {
    useEffect(() => {
        document.title = title
    }, [title])

    return children
}

function TenantIDRequired({ children }: { children: JSX.Element }): JSX.Element {
    const appContext = useAppContext()
    return appContext.tenantID ? children : <LoadingScreen />
}

export const ApplicationWrapper = () => {
    const [visible, setVisible] = useState(false)
    const identity = useIdentity()
    const isValid = Boolean(identity?.Email)
    const [bannerHeight] = useAtom(bannerHeightAtom)

    const { identityQuery, sitesQuery, hasCompleted, refetch } = useIdentityAndSitesQuery()

    const clientStorageKey = 'isPrimaryDrawerOpen'
    const [isDrawerOpen, setIsDrawerOpen] = useState(clientStorage.getItem(clientStorageKey) === true)

    const toggleDrawer = () => {
        const next = !Boolean(isDrawerOpen)
        setIsDrawerOpen(next)
        clientStorage.setItem(clientStorageKey, next)
        console.log({ current: isDrawerOpen, next, currentStorage: clientStorage.getItem(clientStorageKey) })
    }

    useEffect(() => {
        const handler = sitesEventChanel.on('onSitesUpdated', sitesQuery.refetch)
        return () => {
            sitesEventChanel.off('onSitesUpdated', handler)
        }
    }, [])

    useEffect(() => {
        if (!hasCompleted) {
            return
        }
        if (identityQuery.isError) {
            if (identityQuery.error?.message.includes('403')) {
                return notify(
                    <div>
                        <h3>Denied Access to Content Manager</h3>
                        <p>Please provide the following to your support team</p>
                        <pre>{JSON.stringify(identityQuery.error?.response?.data || {}, null, 4)}</pre>
                    </div>,
                    'error',
                    {
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        autoClose: 15000
                    }
                )
            }
        }
    }, [identityQuery.isLoading, identityQuery.data, identityQuery.isError])

    useEffect(() => {
        window.addEventListener('scroll', toggleVisible)
        return () => {
            window.removeEventListener('scroll', toggleVisible)
        }
    }, [])

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop
        if (scrolled) setVisible(scrolled > 300)
    }
    const scrollToTop = () =>
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })

    if (window.location.pathname === '/lexical-testing') {
        return <LexicalTesting />
    }

    if (!hasCompleted) {
        return <LoadingScreen />
    }
    if (!isValid) {
        return <Authenticate onChange={refetch} />
    }

    const isOpen = isValid && isDrawerOpen
    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <AppTopBar open={isOpen} toggleDrawer={toggleDrawer} />
                <Main open={!isOpen}>
                    <DrawerHeader sx={{ paddingTop: `${bannerHeight}px` }} />
                    <Suspense fallback={<p>loading...</p>}>
                        <PreviewForDepartmentDialogAlt />
                    </Suspense>
                    <Routes>
                        {routes.map((page) => {
                            let element = <Helm title={page.title}>{page.element()}</Helm>

                            if (page.authorization.requires == 'auth') {
                                element = (
                                    <RequiresAuth entityScope={page.authorization.entityScope || undefined}>
                                        {element}
                                    </RequiresAuth>
                                )
                            } else if (page.authorization.requires == 'admin') {
                                element = <RequiresAdmin>{element}</RequiresAdmin>
                            }

                            if (page.strict) {
                                element = <React.StrictMode>{element}</React.StrictMode>
                            }

                            element = <TenantIDRequired>{element}</TenantIDRequired>
                            return <Route key={page.path} path={page.path} element={element} />
                        })}
                    </Routes>
                    {visible && <ScrollToTopIconButton onClick={scrollToTop} />}
                </Main>
            </Box>
        </>
    )
}
