import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel } from '@mui/material'
import React from 'react'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import moment from 'moment/moment'
import { Moment } from 'moment-timezone'
import { getCurrentDate } from './helpers'

type DateTimeNullableProps = {
    value: Date | null | undefined
    min?: Date | null
    max?: Date | null
    defaultValue?: Date | null
    onChange: (date: Date | null) => void
    label: string
    disabled?: boolean
    error?: string
}

export const DateTimeNullable = ({
    value,
    onChange,
    label,
    disabled = false,
    min,
    max,
    defaultValue,
    error
}: DateTimeNullableProps) => {
    const minMoment = min ? moment(min) : undefined
    const maxMoment = max ? moment(max) : undefined
    const defaultValueMoment = defaultValue ? moment(defaultValue) : undefined

    const getDefaultValue = (): Moment => {
        if (defaultValueMoment) {
            return defaultValueMoment
        }

        return getCurrentDate({ min: minMoment, max: maxMoment })
    }

    return (
        <div>
            <FormControl variant='standard' component='fieldset' disabled={disabled}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={disabled}
                                onChange={(v) => onChange(v.target.checked ? getDefaultValue().toDate() : null)}
                                checked={!!value}
                            />
                        }
                        label={label}
                    />
                </FormGroup>
            </FormControl>
            {value && (
                <DateTimePicker
                    value={moment(value)}
                    onChange={(date) => onChange(date?.toDate() || null)}
                    minDateTime={minMoment}
                    maxDateTime={maxMoment}
                    disabled={disabled}
                />
            )}
        </div>
    )
}
