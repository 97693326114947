import { httpGet } from '../../../common/client'
import { z } from 'zod'
import { useQuery } from '@tanstack/react-query'
import { BASE } from '../../../common/constants'

export function getCompileFragmentByIdUrl(id: string | undefined) {
    if (!id) {
        console.warn('getCompileByFragmentsByIdUrl no id')
        return null
    }
    return `${BASE}/api/v1/fragments/${id}/compile`
}

export function useCompileFragmentById(id: string) {
    const result = useQuery({
        enabled: !!id,
        queryKey: ['compile-fragment', id],
        queryFn: () => httpGet(getCompileFragmentByIdUrl(id) || '', undefined, z.string())
    })

    return result
}
