import { useCallback, useEffect, useRef } from 'react'
import { $getSelection, $isRangeSelection, SELECTION_CHANGE_COMMAND } from 'lexical'
import { mergeRegister } from '@lexical/utils'
import { getSelectedNode, LowPriority } from '../toolbar/toolbar.helpers'
import { $isLinkNode, TOGGLE_LINK_COMMAND } from './LinkNode'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import truncateMiddle from '../../../helpers/truncateMiddle'
import LinkEditor, { LinkEditorHandlers } from '../LinkEditor'
import { _createPortal } from '../helpers/helpers'

// src: https://codesandbox.io/s/lexical-rich-text-example-5tncvy?from-embed=&file=/src/Editor.js

export function FloatingLinkEditor({ ...props }) {
    const [editor] = useLexicalComposerContext()
    const editorRef = useRef<LinkEditorHandlers>(null)

    const hideFloatingLinkEditor = useCallback(() => {
        if (editorRef?.current) {
            editorRef.current._hide()
            editorRef.current._setLastSelection(null)
            editorRef.current._setIsEditMode(false)
            editorRef.current._setLinkUrl('')
        }
    }, [editorRef?.current])

    const updateLinkEditor = useCallback(() => {
        let selection = $getSelection()

        if (selection == null || !editorRef.current?.element) {
            return
        }

        if ($isRangeSelection(selection)) {
            const node = getSelectedNode(selection)
            const parent = node.getParent()
            if ($isLinkNode(parent)) {
                editorRef.current._setLinkUrl(parent.getURL())
                editorRef.current._setLinkAttributes(parent.getAttributes())
            } else if ($isLinkNode(node)) {
                editorRef.current._setLinkUrl(node.getURL())
                editorRef.current._setLinkAttributes(node.getAttributes())
            } else {
                editorRef.current._setLinkUrl('')
            }
        }

        const nativeSelection = window.getSelection()
        const activeElement = document.activeElement

        const rootElement = editor.getRootElement()

        if (
            nativeSelection &&
            !nativeSelection?.isCollapsed &&
            rootElement !== null &&
            rootElement.contains(nativeSelection.anchorNode)
        ) {
            const domRange = nativeSelection.getRangeAt(0)
            let selectedLinkElement
            if (nativeSelection && nativeSelection.anchorNode === rootElement) {
                let inner = rootElement
                while (inner.firstElementChild != null) {
                    // @ts-ignore
                    inner = inner.firstElementChild
                }
                selectedLinkElement = inner
            } else {
                selectedLinkElement = domRange
            }

            editorRef.current._position(selectedLinkElement)
            editorRef?.current?._setLastSelection(selection)
        } else if (
            !activeElement ||
            // Because we must execute .focus() onto the textarea element within the link input field
            (activeElement.className !== 'link-input' && activeElement.tagName != 'TEXTAREA')
        ) {
            hideFloatingLinkEditor()
        }

        return true
    }, [editorRef?.current])

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    updateLinkEditor()
                })
            }),

            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                () => {
                    updateLinkEditor()
                    return true
                },
                LowPriority
            )
        )
    }, [editor, updateLinkEditor])

    useEffect(() => {
        editor.getEditorState().read(() => {
            updateLinkEditor()
        })
    }, [editor, updateLinkEditor])

    return _createPortal(
        <LinkEditor
            data-testid='lexical-floating-link-editor'
            boxProps={props}
            ref={editorRef}
            linkOnChangeHandler={(v, attributes) => {
                editor.dispatchCommand(TOGGLE_LINK_COMMAND, {
                    url: v,
                    target: attributes?.target
                })
            }}
            transformDisplayLink={(l) => truncateMiddle(l, 30)}
        />,
        document.body
    )
}
