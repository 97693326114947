import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'
import { notify } from '../../../helpers'

const getColor = (props) => {
    if (props.isDragAccept) {
        return '#00e676'
    }
    if (props.isDragReject) {
        return '#ff1744'
    }
    if (props.isDragActive) {
        return '#2196f3'
    }
    return '#eeeeee'
}

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: ${(props) => getColor(props)};
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
`

/**
 * One of the following two functions must be provided:
 *
 * setDocuments
 * replaceDocument
 *
 * @param {number}    documentLimit   - An optional maximum number of files to be uploaded
 * @param {function}  setDocuments    - Bulk setState for multiple document upload
 * @param {function}  replaceDocument - Single function responsible for replacing an active document
 * */

function DocumentUpload({ setDocuments, replaceDocument, documentLimit, fileType }) {
    const onDrop = useCallback((acceptedFiles) => {
        if (documentLimit && (acceptedFiles.length > documentLimit || acceptedFiles.length === 0)) {
            notify('Whoops! Too many files selected', 'error')
        }
        acceptedFiles.forEach((file) => {
            let documentToUpload = {
                id: uuidv4(),
                filename: file.name,
                tags: []
            }
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onloadend = () => {
                // One of the following functions must be provided
                if (setDocuments) {
                    setDocuments((prev) => [...prev, { ...documentToUpload, data: reader.result }])
                }
                if (replaceDocument) {
                    replaceDocument(reader.result)
                }
            }
        })
    }, [])
    const config = {
        onDrop,
        accept: [
            '.pdf',
            '.docx',
            '.doc',
            '.pptx',
            '.xlsx',
            '.xls',
            '.pub',
            '.ppt',
            '.kmz',
            '.kml',
            '.zip',
            '.woff2',
            '.ttf'
        ]
    }
    if (fileType) {
        config['accept'] = [`.${fileType}`]
    }
    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone(config)
    return (
        <div>
            <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
            </Container>
        </div>
    )
}

export default DocumentUpload
