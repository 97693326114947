import { useMutation, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { notify } from '../../helpers'
import * as Constants from '../../common/constants'

export function useBusAreaQueryMutation(
    siteId: string | undefined,
    createOnSuccess?: (data) => void,
    createOnError?: (err) => void,
    editOnSuccess?: (data) => void,
    editOnError?: (err) => void
) {
    const {
        data: busAreaData,
        error,
        refetch,
        isLoading
    } = useQuery({
        queryKey: [Constants.busAreaQueryKey],
        queryFn: () => axios.get(Constants.BusAreaAPI, { params: { siteId } }).then((res) => res.data as any),
        onError: (err: any) => notify(`${err.response.status} : ${err.response.statusText}`)
    })

    const createMutation = useMutation({
        mutationFn: (createAreaObj: any) => axios.post(Constants.BusAreaAPI, createAreaObj, { params: { siteId } }),
        onSuccess: (data) => {
            if (createOnSuccess) {
                createOnSuccess(data)
            } else {
                notify('Success! Bus area has been created', 'info')
                refetch()
            }
        },
        onError: (err: any) => {
            if (createOnError) {
                createOnError(err)
            } else {
                notify(`${err.response.status} : ${err.response.statusText}`, 'error')
            }
        }
    })

    const editMutation = useMutation({
        mutationFn: (updateAreaObj: any) => axios.put(Constants.BusAreaAPI, updateAreaObj, { params: { siteId } }),
        onSuccess: (data) => {
            if (editOnSuccess) {
                editOnSuccess(data)
            } else {
                notify('Success! Bus area has been updated', 'info')
                refetch()
            }
        },
        onError: (err: any) => {
            if (editOnError) {
                editOnError(err)
            } else {
                notify(`${err.response.status} : ${err.response.statusText}`, 'error')
            }
        }
    })

    const deleteMutation = useMutation({
        mutationFn: (deleteAreaObj: any) => axios.delete(Constants.BusAreaAPI, { data: deleteAreaObj }),
        onSuccess: (data) => {
            notify('Success! Bus area has been deleted', 'info')
            refetch()
        },
        onError: (err: any) => {
            notify(`${err.response.status} : ${err.response.statusText}`, 'error')
        }
    })

    function getAreaIdToArea() {
        return busAreaData?.reduce(
            (a, busArea) => ({
                ...a,
                [busArea.id]: busArea
            }),
            ({} as Record<string, any>) || null
        )
    }

    return { busAreaData, error, refetch, createMutation, editMutation, deleteMutation, getAreaIdToArea, isLoading }
}

export function useBusRouteQueryMutation(
    siteId: string | null | undefined,
    useSiteId: boolean | undefined,
    createOnSuccess?: (data) => void,
    createOnError?: (err) => void,
    editOnSuccess?: (data) => void,
    editOnError?: (err) => void,
    deleteOnSuccess?: (data) => void,
    deleteOnError?: (err) => void
) {
    const {
        data: busRouteData,
        error,
        refetch
    } = useQuery({
        queryKey: [Constants.busRouteQueryKey],
        queryFn: () =>
            axios.get(Constants.BusRouteAPI, { params: { siteId, useSiteId } }).then((res) => res.data as any),
        onError: (err: any) => notify(`${err.response.status} : ${err.response.statusText}`)
    })

    const createMutation = useMutation({
        mutationFn: (createRouteObj: any) => axios.post(Constants.BusRouteAPI, createRouteObj, { params: { siteId } }),
        onSuccess: (data) => {
            if (createOnSuccess) {
                createOnSuccess(data)
            } else {
                notify('Success! Bus route has been created', 'info')
                refetch()
            }
        },
        onError: (err: any) => {
            if (createOnError) {
                createOnError(err)
            } else {
                notify(`${err.response.status} : ${err.response.statusText}`, 'error')
            }
        }
    })

    const editMutation = useMutation({
        mutationFn: (updateRouteObj: any) => axios.put(Constants.BusRouteAPI, updateRouteObj, { params: { siteId } }),
        onSuccess: (data) => {
            if (editOnSuccess) {
                editOnSuccess(data)
            } else {
                notify('Success! Bus route has been updated', 'info')
                refetch()
            }
        },
        onError: (err: any) => {
            if (editOnError) {
                editOnError(err)
            } else {
                notify(`${err.response.status} : ${err.response.statusText}`, 'error')
            }
        }
    })

    const deleteMutation = useMutation({
        mutationFn: (routeId: string) => axios.delete(`${Constants.BusRouteAPI}/${routeId}`),
        onSuccess: (data) => {
            if (deleteOnSuccess) {
                deleteOnSuccess(data)
            } else {
                notify('Success! Bus route has been deleted', 'info')
                refetch()
            }
        },
        onError: (err: any) => {
            notify(`${err.response.status} : ${err.response.statusText}`, 'error')
        }
    })

    function getRouteIdMap() {
        return busRouteData?.reduce(
            (a, busRoute) => ({
                ...a,
                [busRoute.id]: busRoute
            }),
            ({} as Record<string, any>) || null
        )
    }

    return { busRouteData, error, refetch, createMutation, editMutation, deleteMutation, getRouteIdMap }
}

export function useBusStatusQueryMutation(
    siteId: string | undefined | null,
    inactive?: boolean,
    useSiteId?: boolean,
    createOnSuccess?: (data) => void,
    createOnError?: (err) => void,
    deleteOnSuccess?: (data) => void,
    deleteOnError?: (err) => void
) {
    const {
        data: busStatusData,
        error,
        refetch
    } = useQuery({
        queryKey: [Constants.busStatusQueryKey, inactive, useSiteId],
        queryFn: () =>
            axios
                .get(Constants.BusStatusAPI, { params: { siteId, inactive, useSiteId } })
                .then((res) => res.data as any),
        onError: (err: any) => notify(`${err.response.status} : ${err.response.statusText}`)
    })

    const createMutation = useMutation({
        mutationFn: (createStatusObj: any) =>
            axios.post(Constants.BusStatusAPI, createStatusObj, { params: { siteId } }),
        onSuccess: (data) => {
            if (createOnSuccess) {
                createOnSuccess(data)
            } else {
                notify('Success! Bus status has been created', 'info')
                refetch()
            }
        },
        onError: (err: any) => {
            if (createOnError) {
                createOnError(err)
            } else {
                notify(`${err.response.status} : ${err.response.statusText}`, 'error')
            }
        }
    })

    const editMutation = useMutation({
        mutationFn: (editStatusObj: any) =>
            axios.put(`${Constants.BusStatusAPI}/${editStatusObj.id}`, editStatusObj, { params: { siteId } }),
        onSuccess: (data) => {
            notify('Success! Bus status has been updated', 'info')
            refetch()
        },
        onError: (err: any) => {
            notify(`${err.response.status} : ${err.response.statusText}`, 'error')
        }
    })

    const deleteMutation = useMutation({
        mutationFn: (deleteStatusObj: any) => axios.delete(Constants.BusStatusAPI, { data: deleteStatusObj }),
        onSuccess: (data) => {
            if (deleteOnSuccess) {
                deleteOnSuccess(data)
            } else {
                notify('Success! Bus status has been archived', 'info')
                refetch()
            }
        },
        onError: (err: any) => {
            if (deleteOnError) {
                deleteOnError(err)
            } else {
                notify(`${err.response.status} : ${err.response.statusText}`, 'error')
            }
        }
    })

    return { busStatusData, error, refetch, createMutation, editMutation, deleteMutation }
}
