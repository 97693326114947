import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import * as Constants from '../../../common/constants'
import { Settings, SettingsDTO, settingsQuerySchema, SettingsType } from './types'
import { httpDelete, httpGet, httpPost, httpPut } from '../../../common/client'
import { z } from 'zod'
import { PagingQuery, SortingQuery } from '../../../common/react-query'
import { useCurrentSiteID } from '@/pkgs/auth/atoms'

export type SettingsSearchQueryParams = {
    Search?: string
    Type?: SettingsType[] | null
    AdminID?: string | null
    Sites?: string[] | null
    IgnoreSiteID?: boolean | null
    Active?: boolean | null
    Public?: boolean | null
} & PagingQuery &
    SortingQuery

function searchSettings(queryParams) {
    return httpGet(`${Constants.SettingsAPI}`, queryParams, settingsQuerySchema)
}

export const editSettingsQuery = (settings: Settings) => httpPut(`${Constants.SettingsAPI}/${settings.ID}`, settings)
export const deleteSettingsQuery = (settingsId: string) => httpDelete(`${Constants.SettingsAPI}/${settingsId}`)
export const createSettingsQuery = (settings: SettingsDTO) => httpPost(`${Constants.SettingsAPI}`, settings, z.string())

export default function useSettingsQueryMutation(queryParams?: SettingsSearchQueryParams) {
    if (queryParams) {
        queryParams.Sites = !!queryParams?.Sites?.length ? queryParams.Sites : null
    }

    const { data, error, refetch, isLoading } = useQuery({
        queryKey: [Constants.settingsQueryKey, queryParams],
        queryFn: () => searchSettings(queryParams)
    })

    return {
        data,
        isLoading,
        error,
        refetch
    }
}

function getSettingById(id) {
    return axios.get(`${Constants.SettingsAPI}/${id}`).then((res) => res.data as any)
}

export function useSettingByIdQuery(id: string) {
    const result = useQuery({
        queryKey: [Constants.groupQueryKey, id],
        queryFn: () => getSettingById(id)
    })
    return result
}

export function useSettingsByTypeQuery(types: SettingsType[]) {
    const siteId = useCurrentSiteID()
    return useSettingsQueryMutation({
        Type: types,
        page: 1,
        pageSize: 10000,
        Sites: siteId ? [siteId] : null
    })
}
