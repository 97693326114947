import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { useEffect } from 'react'
import { LexicalEditor } from 'lexical'

type OnLoadPluginProps = {
    onLoad?: (editor: LexicalEditor) => void
}

export function OnLoadPlugin({ onLoad }: OnLoadPluginProps) {
    const [editor] = useLexicalComposerContext()
    useEffect(() => {
        onLoad && onLoad(editor)
    }, [editor])
    return null
}
