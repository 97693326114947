import { z } from 'zod'
import { PagingQuery, SortingQuery, paged } from '../../../common/react-query'

export type RelaysQuery = {} & PagingQuery & SortingQuery

const plainSmtpRelay = z.object({
    Host: z.string().optional(),
    Port: z.number(),
    Username: z.string(),
    Sender: z.string().email(),
    ReplyTo: z.string().email()
})
const plainSmtpRelayWithPassword = plainSmtpRelay.extend({
    Password: z.string()
})

const relay = z.object({
    ID: z.string(),
    Type: z.string(),
    XData: plainSmtpRelay
})
export const relays = paged.extend({
    Rows: z.array(relay)
})
export type Relay = z.infer<typeof relay>
export type Relays = z.infer<typeof relays>
