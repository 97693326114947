import { useAppNavigation } from '../../../app/useAppNavigation'
import React, { useEffect, useState } from 'react'
import { Content } from '../types'
import { useStructuredContentValidators } from '../editor/useStructuredContentValidators'
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Stack } from '@mui/material'
import TextField from '@mui/material/TextField'
import { SiteSelectorForContent } from '../../../common/components/selectors/SiteSelectorForContent'
import Button from '@mui/material/Button'
import { httpPost } from '../../../common/client'
import { z } from 'zod'
import { useMutation } from '@tanstack/react-query'
import { BASE } from '../../../common/constants'
import { typeToEntityScope } from '../../auth/entityScope'
import { useDefaultSitesForSelector } from '@/pkgs/auth/atoms'

interface CreateTemplateProps {
    open: boolean
    onClose: () => void
}

export const CreateTemplate = ({ open, onClose }: CreateTemplateProps) => {
    const defaultSites = useDefaultSitesForSelector()
    const now = new Date().toISOString()

    const { navigateTo } = useAppNavigation()

    const create = useMutation([], (content: Content) => httpPost(`${BASE}/api/v2/content`, content, z.string()))

    useEffect(() => {
        if (!create.data) return

        navigateTo(`/template-editor/${create.data}`)
        onClose()
    }, [create.data])

    const [state, setState] = useState<Content>({
        Type: 'template',
        PageLayout: 'HTML',
        Title: '',
        Route: '',
        Sites: defaultSites || [],
        DepartmentID: null,
        Path: '',
        StructureID: null,
        ExpireAt: null,
        PublishAt: now as any,
        // To satisfy the Content read interface
        Meta: {},
        Data: {},
        ID: '',
        Created: now as any,
        Updated: now as any,
        Active: true,
        Deleted: null,
        MediaID: null,
        Owner: '',
        PrivacyLevel: 0,
        Publisher: '',
        Tags: [],
        Settings: {}
    })

    const { validateAll, errors } = useStructuredContentValidators(['Sites', 'Title'], state)

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth={'xl'}>
            <DialogTitle>Create {state.Type}</DialogTitle>
            <DialogContent>
                <Stack spacing={2} direction={'row'}>
                    <FormControl fullWidth sx={{ my: 1 }}>
                        <TextField
                            label='Title'
                            required
                            style={{ width: '100%' }}
                            value={state.Title || ''}
                            onChange={(v) => state && setState({ ...state, Title: v.target.value })}
                            error={!!errors.Title}
                        />
                    </FormControl>
                </Stack>

                <Stack spacing={2} direction={'row'}>
                    <FormControl fullWidth sx={{ m: 0 }}>
                        <SiteSelectorForContent
                            ContentType={typeToEntityScope(state.Type)}
                            Selected={state.Sites || []}
                            OnChange={(sites, departmentID) =>
                                setState({ ...state, Sites: sites, DepartmentID: departmentID })
                            }
                            HasError={!!errors.Sites}
                        />
                    </FormControl>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack direction={'row'} spacing={2}>
                    <Button variant='outlined' color='secondary' onClick={onClose}>
                        Cancel
                    </Button>

                    <Button
                        variant='contained'
                        color='primary'
                        disabled={create.isLoading}
                        onClick={() => {
                            if (!validateAll()) {
                                return
                            }

                            create.mutate(state)
                        }}
                    >
                        Create {state.Type}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    )
}
