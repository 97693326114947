import DialogFormAction from '../../common/components/DialogFormAction'
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Input,
    InputLabel,
    ListItemText,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Switch,
    TextField
} from '@mui/material'
import HelpTooltip from '../../common/components/HelpTooltip'
import DatePicker from 'react-datepicker'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { formatStatusType } from './BusStatus'
import { Format, notify, renderSelectedValue } from '../../helpers'
import { useCurrentSiteID } from '../auth/atoms'
import { useBusAreaQueryMutation, useBusRouteQueryMutation, useBusStatusQueryMutation } from './queries'

function compareDate(dateA, dateB, timeOnly = true) {
    const a = dateA ? new Date(dateA) : null
    const b = dateB ? new Date(dateB) : null

    if (timeOnly) {
        return a?.getUTCHours() == b?.getUTCHours() && a?.getUTCMinutes() == b?.getUTCMinutes()
    }

    return a == b
}

function getNowAtHour(hour?: number) {
    const now = new Date()
    if (hour) {
        now.setHours(hour)
        now.setMinutes(0)
        now.setMilliseconds(0)
    }

    return now
}

const morningHour = 6
const afternoonHour = 12
const eveningHour = 18

export const timeOptions = {
    Morning: () => {
        return {
            startDate: getNowAtHour(morningHour),
            endDate: getNowAtHour(afternoonHour)
        }
    },
    Afternoon: () => {
        return {
            startDate: getNowAtHour(afternoonHour),
            endDate: getNowAtHour(eveningHour)
        }
    },
    'All Day': () => {
        return {
            startDate: getNowAtHour(morningHour),
            endDate: getNowAtHour(eveningHour)
        }
    },
    Ongoing: () => {
        return {
            startDate: getNowAtHour(),
            endDate: null
        }
    },
    // time set via DatePicker
    Other: () => {
        return {
            startDate: null,
            endDate: null
        }
    }
}

const timeOptionLabels = Object.keys(timeOptions)

export function matchDateWithTimeOption(startDate, endDate): string {
    if (!startDate && !endDate) return timeOptionLabels[0]

    // ongoing, end date is null and start date is time the bus status was last modified
    if (!endDate) {
        return 'Ongoing'
    }

    return (
        timeOptionLabels.find((timeOption) => {
            return (
                compareDate(timeOptions[timeOption]().startDate, startDate) &&
                compareDate(timeOptions[timeOption]().endDate, endDate)
            )
        }) || 'Other'
    )
}

type Action = 'create' | 'edit'

interface BusStatusDialogFormActionProps {
    open: boolean
    action: Action
    classes: any
    isAllChecked: boolean
    historicChecked: boolean
    initialBusStatus?: any
    showNotificationFeatureFlag?: boolean
    handleDisagree: () => void
    handleClose: () => void
}

// Used to create or edit bus status. Not delete.
function BusStatusDialogFormAction({
    open,
    action,
    classes,
    isAllChecked,
    historicChecked,
    initialBusStatus,
    showNotificationFeatureFlag,
    handleDisagree,
    handleClose
}: BusStatusDialogFormActionProps) {
    const currentSiteID = useCurrentSiteID()

    // on initial load for action == 'edit'
    // used to prevent auto adding routes based on area ids within already selected routes
    // goto useEffect below
    const [initialEditLoad, setInitialEditLoad] = useState(true)

    const { busAreaData } = useBusAreaQueryMutation(currentSiteID)
    const {
        busRouteData,
        refetch: refetchBusStatus,
        getRouteIdMap
    } = useBusRouteQueryMutation(currentSiteID, !isAllChecked)
    const areaIdsWithRoutes = useMemo(() => busRouteData?.map((route) => route?.areas).flat() || [], [busRouteData])
    const busRouteIdToRoute = getRouteIdMap()

    // ===== all form values
    const [formValues, setFormValues] = useState({
        description: initialBusStatus?.description || '',
        type: initialBusStatus?.type || '',
        hasEmailNotification: initialBusStatus?.hasEmailNotification || false
    })
    const [chosenRoutes, setChosenRoutes] = useState<any[]>(
        initialBusStatus?.routes?.map((routeId) => busRouteIdToRoute?.[routeId]).filter((r) => r) || []
    )
    const [startDate, setStartDate] = useState(() => {
        if (!initialBusStatus) {
            return timeOptions[timeOptionLabels[0]]().startDate
        }

        return initialBusStatus.startdate.Valid && initialBusStatus?.startdate?.Time
            ? new Date(initialBusStatus?.startdate?.Time)
            : ''
    })
    const [endDate, setEndDate] = useState(() => {
        if (!initialBusStatus) {
            return timeOptions[timeOptionLabels[0]]().endDate
        }

        return initialBusStatus.enddate.Valid && initialBusStatus?.enddate?.Time
            ? new Date(initialBusStatus.enddate.Time)
            : ''
    })
    const [selectedRadioOption, setSelectedRadioOption] = useState<string>(matchDateWithTimeOption(startDate, endDate))
    // =====

    const [errors, setErrors] = useState({
        routes: false,
        type: false,
        start: false
    })

    // array of area ids
    const [selectedAreasIds, setSelectedAreaIds] = useState<string[]>(
        Array.from(new Set(chosenRoutes?.flatMap((route) => route.areas) || []))
    )
    const [allRouteChecked, setAllRouteChecked] = useState(false)

    const [isTimeSelectEnabled, setIsTimeSelectEnabled] = useState(selectedRadioOption == 'Other')

    const { createMutation: createBusStatusMutation, editMutation: editBusStatusMutation } = useBusStatusQueryMutation(
        currentSiteID,
        historicChecked,
        !isAllChecked,
        () => {
            notify('Success! Bus status has been created', 'info')
            refetchBusStatus()
            resetFields()
            handleClose()
        }
    )

    useEffect(() => {
        if (!isTimeSelectEnabled) {
            // startDate and endDate are null if isTimeSelectEnabled
            setSelectedRadioOption(matchDateWithTimeOption(startDate, endDate) || timeOptionLabels[0])
        }
    }, [startDate, endDate, isTimeSelectEnabled])

    const resetFields = () => {
        setChosenRoutes([])
        setStartDate('')
        setEndDate('')
        setFormValues({
            description: '',
            type: '',
            hasEmailNotification: formValues.hasEmailNotification
        })
    }

    const handleCreate = () => {
        const status = {
            ...formValues,
            startDate,
            endDate,
            routes: Format.objectsToIds(chosenRoutes)
        }
        if (validation(status)) {
            createBusStatusMutation.mutate(status)
        } else {
            notify('Whoops! Some values are missing', 'error')
        }
    }

    const handleUpdate = () => {
        const updatedStatus = {
            ...initialBusStatus,
            ...formValues,
            startDate,
            endDate,
            routes: Format.objectsToIds(chosenRoutes)
        }
        /*
         * Clean up:
         * API Expects "startDate" (camelcase) for Update or Create
         * API serves Bus Status object with 'startdate' on request.
         * the delete's below are removing the API provided values
         * */
        delete updatedStatus.startdate
        delete updatedStatus.enddate

        if (validation(updatedStatus)) {
            editBusStatusMutation.mutate(updatedStatus)
            handleClose()
        } else {
            notify('Whoops! Some values are missing', 'error')
        }
    }

    const validation = (state) => {
        let util = true
        if (state.type.length === 0) {
            setErrors((prev) => ({
                ...prev,
                type: true
            }))
            util = false
        }
        if (state.startDate.length === 0) {
            setErrors((prev) => ({
                ...prev,
                start: true
            }))
            util = false
        }
        if (chosenRoutes.length === 0) {
            setErrors((prev) => ({
                ...prev,
                routes: true
            }))
            util = false
        }
        if (util) {
            return true
        } else {
            return false
        }
    }

    const CustomDateTimeInput = React.forwardRef(({ value, onClick, label, errorKey }: any, ref) => {
        return (
            <TextField
                variant='standard'
                className={classes.button}
                error={errors[errorKey]}
                onClick={onClick}
                value={value}
                placeholder={label}
                label={label}
                required={label === 'Start Time' ? true : false}
            >
                {value}
            </TextField>
        )
    })

    const handleRadioChange = (event) => {
        let selectedRadioOption = event.target.value
        setSelectedRadioOption(selectedRadioOption)
        if (selectedRadioOption === 'Other') {
            setIsTimeSelectEnabled(true)
            if (initialBusStatus) {
                setStartDate(
                    initialBusStatus.startdate.Valid && initialBusStatus?.startdate?.Time
                        ? new Date(initialBusStatus?.startdate?.Time)
                        : ''
                )
                setEndDate(
                    initialBusStatus.enddate.Valid && initialBusStatus?.enddate?.Time
                        ? new Date(initialBusStatus?.enddate?.Time)
                        : ''
                )
            } else {
                const { startDate, endDate } = timeOptions[selectedRadioOption]()
                setStartDate(startDate)
                setEndDate(endDate)
            }
        } else {
            setIsTimeSelectEnabled(false)
            const { startDate, endDate } = timeOptions[selectedRadioOption]()
            setStartDate(startDate)
            setEndDate(endDate)
        }
    }

    const handleAllRouteCheckbox = (e) => {
        // useEffect with dependency [selectedAreasIds] will handle updating chosenRoutes
        if (e.target.checked) {
            setSelectedAreaIds(areaIdsWithRoutes)
            setErrors((prev) => ({
                ...prev,
                routes: false
            }))
            setAllRouteChecked(true)
        } else {
            setSelectedAreaIds([])
            setAllRouteChecked(false)
        }
    }

    const startRef = useRef(DatePicker)
    const endRef = useRef(DatePicker)
    const onStartChange = () => {
        startRef.current.setOpen(false)
    }
    const onEndChange = () => {
        endRef.current.setOpen(false)
    }

    const [showQuickOptions, setShowQuickOptions] = useState<boolean>(false)

    useEffect(() => {
        if (busRouteData) {
            if (action == 'edit' && initialEditLoad) {
                setInitialEditLoad(false)
            } else {
                const routesWithoutAreas = [...chosenRoutes.filter((route) => route.areas?.length == 0)]
                const newRoutes = [
                    ...busRouteData.filter((route) => !!route.areas.find((areaId) => selectedAreasIds.includes(areaId)))
                ]
                setChosenRoutes(newRoutes.concat(routesWithoutAreas))
                if (!selectedAreasIds?.length) {
                    setAllRouteChecked(false)
                }
            }
        }
    }, [selectedAreasIds])

    return (
        <DialogFormAction
            item={
                <div>
                    {showNotificationFeatureFlag && (
                        <FormControl
                            variant='standard'
                            className={classes.form}
                            style={{ flexDirection: 'row', marginLeft: '0' }}
                        >
                            <FormControlLabel
                                name='hasEmailNotification'
                                control={
                                    <Switch
                                        color='primary'
                                        checked={formValues.hasEmailNotification}
                                        onChange={(ev) => {
                                            setFormValues((prev) => ({
                                                ...prev,
                                                hasEmailNotification: ev.target.checked
                                            }))
                                        }}
                                    />
                                }
                                label='Email Notification'
                                labelPlacement='start'
                                style={{
                                    flexDirection: 'row',
                                    marginLeft: '0px'
                                }}
                            />
                        </FormControl>
                    )}

                    <FormControl variant='standard' className={classes.form}>
                        <InputLabel error={errors.type}>Status Type</InputLabel>

                        <Select
                            variant='standard'
                            error={errors.type}
                            name='type'
                            value={formValues.type}
                            required={true}
                            renderValue={formatStatusType}
                            onChange={(event) => {
                                setFormValues((prev) => ({ ...prev, type: event.target.value }))
                                setErrors((prev) => ({ ...prev, type: false }))
                            }}
                            input={<Input />}
                        >
                            <MenuItem value='on_time'>On Time</MenuItem>
                            <MenuItem value='delayed'>Delayed</MenuItem>
                            <MenuItem value='cancelled'>Cancelled</MenuItem>
                            <MenuItem value='delayed_mechanical'>Delayed - Mechanical</MenuItem>
                            <MenuItem value='cancelled_mechanical'>Cancelled - Mechanical</MenuItem>
                        </Select>
                    </FormControl>
                    <div className='flex-row' style={{ justifyContent: 'space-between' }}>
                        <FormControl variant='standard' className={classes.formWithSpace}>
                            <InputLabel error={errors.routes}>Routes</InputLabel>
                            <Select
                                variant='standard'
                                multiple
                                required={true}
                                error={errors.routes}
                                name='routes'
                                value={chosenRoutes}
                                onChange={(event) => {
                                    // @ts-ignore
                                    setChosenRoutes(event.target.value)
                                    setErrors((prev) => ({
                                        ...prev,
                                        [event.target.name]: false
                                    }))
                                }}
                                input={<Input />}
                                renderValue={renderSelectedValue}
                            >
                                {busRouteData?.map((area) => (
                                    <MenuItem key={area.id} value={area}>
                                        <Checkbox
                                            checked={(chosenRoutes as any).findIndex((x) => x.id === area.id) > -1}
                                        />
                                        <ListItemText primary={area.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <FormGroup style={{ alignSelf: 'flex-end' }}>
                        <FormControlLabel
                            label={showQuickOptions ? 'Hide Areas' : 'Show Areas'}
                            control={
                                <Switch
                                    checked={showQuickOptions}
                                    onChange={(e) => setShowQuickOptions(e.target.checked)}
                                />
                            }
                        />
                        {showQuickOptions && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={handleAllRouteCheckbox}
                                        checked={allRouteChecked}
                                        name='first'
                                    />
                                }
                                label='All'
                            />
                        )}

                        {showQuickOptions &&
                            busAreaData
                                ?.filter((area) => areaIdsWithRoutes.includes(area.id))
                                .map((area) => {
                                    return (
                                        <FormControlLabel
                                            key={area.id}
                                            control={
                                                <Checkbox
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setSelectedAreaIds(
                                                                Array.from(new Set([...selectedAreasIds, area.id]))
                                                            )
                                                        } else {
                                                            // remove
                                                            setSelectedAreaIds([
                                                                ...selectedAreasIds.filter((id) => id !== area.id)
                                                            ])
                                                        }
                                                    }}
                                                    checked={selectedAreasIds.includes(area.id)}
                                                    name='first'
                                                />
                                            }
                                            label={area.name}
                                        />
                                    )
                                })}
                    </FormGroup>

                    <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '2vh' }}>
                        <FormLabel component='legend'>Time</FormLabel>
                        <HelpTooltip
                            contents={[
                                'Morning: 6 AM to 12 PM',
                                'Afternoon: 12 PM to 6 PM',
                                'All Day: 6 AM to 6 PM',
                                'Ongoing: Now to ongoing'
                            ]}
                            tooltipTextStyles={{
                                width: '250px',
                                textAlign: 'left'
                            }}
                        />
                    </Box>
                    <RadioGroup
                        style={{ flexDirection: 'row', justifyContent: 'space-between' }}
                        aria-label='Time Select Radio'
                        name='TimeRadio'
                        value={selectedRadioOption}
                        onChange={handleRadioChange}
                    >
                        {timeOptionLabels.map((option) => (
                            <FormControlLabel key={option} value={option} control={<Radio />} label={option} />
                        ))}
                    </RadioGroup>

                    {isTimeSelectEnabled && (
                        <div>
                            <DatePicker
                                className={classes.auto}
                                selected={startDate}
                                ref={startRef}
                                onChange={(date) => {
                                    setStartDate(date)
                                    setErrors((prev) => ({
                                        ...prev,
                                        start: false
                                    }))
                                }}
                                customInput={<CustomDateTimeInput label='Start Time' errorKey='start' />}
                                startDate={startDate}
                                endDate={endDate}
                                timeFormat='p'
                                timeIntervals={15}
                                dateFormat='MM/dd/yyyy h:mm aa'
                                withPortal
                                selectsStart
                                showTimeInput
                                timeInputLabel='Time:'
                                shouldCloseOnSelect={false}
                            >
                                <div>
                                    <Button
                                        size='small'
                                        color='primary'
                                        variant='outlined'
                                        onClick={onStartChange}
                                        className={classes.datePickerButton}
                                    >
                                        Close
                                    </Button>
                                </div>
                            </DatePicker>
                            <DatePicker
                                className={classes.auto}
                                selected={endDate}
                                ref={endRef}
                                onChange={(date) => {
                                    setEndDate(date)
                                    setErrors((prev) => ({
                                        ...prev,
                                        enddate: false
                                    }))
                                }}
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                customInput={<CustomDateTimeInput label='End Time' errorKey='enddate' />}
                                timeFormat='p'
                                timeIntervals={15}
                                withPortal
                                dateFormat='MM/dd/yyyy h:mm aa'
                                selectsEnd
                                showTimeInput
                                timeInputLabel='Time:'
                                shouldCloseOnSelect={false}
                            >
                                <div>
                                    <Button
                                        size='small'
                                        color='primary'
                                        variant='outlined'
                                        onClick={onEndChange}
                                        className={classes.datePickerButton}
                                    >
                                        Close
                                    </Button>
                                </div>
                            </DatePicker>
                        </div>
                    )}

                    <TextField
                        variant='standard'
                        // error={errors.name}
                        value={formValues.description}
                        name='description'
                        onChange={(event) => {
                            setFormValues((prev) => ({
                                ...prev,
                                description: event.target.value
                            }))
                            setErrors((prev) => ({
                                ...prev,
                                description: false
                            }))
                        }}
                        className={classes.textfield}
                        id='standard-basic'
                        label='Notes'
                    />
                </div>
            }
            title={action == 'create' ? 'Create a bus status' : 'Edit bus status'}
            // text="What would you like to create?"
            buttonAgreeLabel={action == 'create' ? 'Create' : 'Save'}
            buttonDisagreeLabel='Cancel'
            open={open}
            handleDisagree={handleDisagree}
            handleAgree={() => {
                if (action == 'create') {
                    handleCreate()
                } else if (action == 'edit') {
                    // handle edit
                    handleUpdate()
                }
            }}
            handleClose={handleClose}
            fullWidth={true}
            text={undefined}
            alternate={undefined}
            alternateAction={undefined}
            headerComponent={undefined}
        />
    )
}

export default BusStatusDialogFormAction
