import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import Button from '@mui/material/Button'
import { notify, sanitizePathString } from '../../../../helpers'
import { documentService } from '../document.service'
import { allFolderContext, folderContext, fullAccessFoldersContext } from '../context'
import { v4 } from 'uuid'
import CircularProgress from '@mui/material/CircularProgress'
import _ from 'lodash'
import DialogContentText from '@mui/material/DialogContentText'
import { DefaultMax } from '../../../../common/constants'
import { DocumentOptionsSelect } from '../DocumentOptionsSelect'
import { useCurrentSiteID } from '../../../auth/atoms'
import { DocumentType } from '../types'

const DocumentFolderDialog = ({
    isFolderDialogOpen,
    endFolderDialog,
    isEditFolder,
    chosenFolder,
    closeDialog,
    folderModel,
    setFolderModel,
    openedFolder,
    setOpenedFolder,
    setChosenFolder
}) => {
    const currentSiteID = useCurrentSiteID()
    const [, setAllFolders] = useContext(allFolderContext)
    const [, setFolders] = useContext(folderContext)
    const [, setFullAccessFolders] = useContext(fullAccessFoldersContext)
    const [errors, setErrors] = useState({ sites: false, privacyLevel: false })
    const [isLoading, setIsLoading] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [originalFolder, setOriginalFolder] = useState(
        chosenFolder && chosenFolder.length > 0 ? chosenFolder[0] : null
    )
    const [isPrivacyDisabled, setIsPrivacyDisabled] = useState(false)
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

    useEffect(() => {
        setErrors((prev) => ({ ...prev, sites: folderModel.sites.length === 0 }))
    }, [folderModel.sites])

    useEffect(() => {
        if (!isEditFolder) {
            setIsPrivacyDisabled(true)
        } else if ((chosenFolder.length && chosenFolder[0].id) !== (originalFolder && originalFolder.id)) {
            setIsPrivacyDisabled(true)
        } else {
            setIsPrivacyDisabled(false)
        }
    }, [chosenFolder])

    useEffect(() => {}, [folderModel])

    const updateFoldersWithResult = async (r, save) => {
        try {
            const { results } = await documentService.getAllByFolderAndType(
                currentSiteID,
                DocumentType.Folder,
                openedFolder.id ? openedFolder.id : '',
                DefaultMax,
                0
            )
            if (results) setFolders(results)

            // Create Case
            if (setAllFolders && !save) {
                setAllFolders((prev) => [...prev, r])
                setFullAccessFolders((prev) => [...prev, r])
            }
            // Update Case
            if (setAllFolders && save) {
                const { results } = await documentService.getAll(currentSiteID, DocumentType.Folder)
                if (results) setAllFolders(results)
            }
            if (setOpenedFolder && folderModel.id === openedFolder.id) {
                setOpenedFolder(r)
            }
        } catch (e) {
        } finally {
            setIsLoading(false)
            endFolderDialog(false)
        }
    }

    const saveFolder = async () => {
        if ((folderModel?.filename?.length ?? 0) < 3) {
            setErrors((p) => ({ ...p, filename: true }))
            return
        }
        if (!errors.sites) {
            setIsLoading(true)
            const newFolder = _.cloneDeep(folderModel)
            newFolder.id = folderModel.id ? folderModel.id : v4()
            newFolder.path = sanitizePathString(newFolder.id)
            if (chosenFolder.length && chosenFolder[0]) {
                const selectedFolder = chosenFolder[0]
                newFolder.path = selectedFolder.path + '.' + newFolder.path
                // If we're creating a new folder or the folder has changed - Attach a
                // parentId to the model for it to inherit PrivacyLevel and Path
                if (!originalFolder || selectedFolder.id !== originalFolder.id) {
                    newFolder.parentId = selectedFolder.id
                }
            }
            if (isEditFolder) {
                const r = await documentService.editDocument(currentSiteID, newFolder)
                if (r) await updateFoldersWithResult(r, true)
            } else {
                const r = await documentService.createDocument(currentSiteID, newFolder)
                if (r) await updateFoldersWithResult(r, false)
            }
        } else {
            notify('Whoops! Some values are missing', 'error')
        }
    }

    const deleteFolder = async () => {
        try {
            if (!folderModel.id) {
                return
            }
            setIsDeleting(true)
            await documentService.deleteDocuments(currentSiteID, [folderModel.id])
            documentService
                .getAllByFolderAndType(
                    currentSiteID,
                    DocumentType.Folder,
                    openedFolder.id ? openedFolder.id : '',
                    DefaultMax,
                    0
                )
                .then(({ results }) => {
                    setFolders(results)
                })
            documentService.getAll(currentSiteID, DocumentType.Folder).then(({ results }) => {
                setAllFolders(results)
                const ref = results.map((result) => result.id)
                setFullAccessFolders((prev) =>
                    prev.filter((folder) => folder.id !== folderModel.id && ref.includes(folder.id))
                )
            })
            await endFolderDialog(true)
        } catch (err) {
            notify("Whoops! The folder couldn't be deleted, please try again", 'error')
        } finally {
            setIsDeleting(false)
        }
    }

    const openDeleteDialog = () => {
        setIsDeleteDialogOpen(true)
    }

    const closeDeleteDialog = () => {
        setIsDeleteDialogOpen(false)
    }

    return (
        <Dialog open={isFolderDialogOpen} onClose={closeDialog}>
            {isDeleteDialogOpen ? (
                <Fragment>
                    <DialogTitle id='alert-dialog-slide-title'>Are you sure?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id='alert-dialog-slide-description'>
                            Deleting this folder will also delete all of its contents
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDeleteDialog} color='primary'>
                            Disagree
                        </Button>
                        <Button size='small' color='primary' onClick={deleteFolder}>
                            {isDeleting ? <CircularProgress style={{ height: 30, width: 30 }} /> : 'Agree'}
                        </Button>
                    </DialogActions>
                </Fragment>
            ) : (
                <Fragment>
                    <DialogTitle>{isEditFolder ? 'Edit Folder' : 'Create Folder'}</DialogTitle>
                    <DialogContent>
                        <TextField
                            variant='standard'
                            error={Boolean(errors.filename)}
                            style={{ width: '100%', marginBottom: '15px' }}
                            label={'Folder name...'}
                            value={folderModel.filename}
                            onChange={(event) => {
                                setFolderModel((p) => ({ ...p, filename: event.target.value }))
                                setErrors((p) => ({ ...p, filename: false }))
                            }}
                        />

                        <DocumentOptionsSelect
                            isDocumentUpload={false}
                            isEditFolder={isEditFolder}
                            canDisable={isPrivacyDisabled}
                            errors={errors}
                            setErrors={setErrors}
                            chosenFolder={chosenFolder}
                            setChosenFolder={setChosenFolder}
                            folderModel={folderModel}
                            setFolderModel={setFolderModel}
                            openedFolder={openedFolder}
                        />
                        <DialogActions>
                            {isEditFolder && (
                                <Button size={'small'} color={'secondary'} onClick={openDeleteDialog}>
                                    DELETE
                                </Button>
                            )}
                            <Button
                                onClick={saveFolder}
                                size='small'
                                color={'primary'}
                                disabled={errors.sites || (errors.privacyLevel && chosenFolder && !chosenFolder.length)}
                            >
                                {isLoading ? (
                                    <CircularProgress style={{ height: 30, width: 30 }} />
                                ) : isEditFolder ? (
                                    'SAVE'
                                ) : (
                                    'CREATE'
                                )}
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Fragment>
            )}
        </Dialog>
    )
}

export default DocumentFolderDialog
