import { useState } from 'react'
import { createStructureQuery } from './queries'
import { Box, Button, Card, CardContent, FormHelperText } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { notify } from '../../helpers'
import StructureForm, { StructureDTO } from './StructureForm'
import { useNavigate } from 'react-router-dom'
import { FormStructure, parseFormStructure, Structure } from './types'
import PageContainerWithHeader from '../../common/components/PageContainerWithHeader'
import { tryParseJson } from '../../helpers/parseJSON'
import { useAppNavigation } from '../../app/useAppNavigation'
import { BackButton } from '../../common/components'

const defaultStructureFormData: StructureDTO = {
    Name: '',
    Template: '',
    FormStructure: ''
}

function CreateStructure() {
    const { navigateTo } = useAppNavigation()

    const [submitError, setSubmitError] = useState('')
    const structureMutation = useMutation({
        mutationFn: (structure: Partial<Structure>) => createStructureQuery(structure),
        onSuccess: (data) => {
            // redirect to edit structure
            notify('Structure successfuly created', 'info')
            const structureId = data
            if (typeof structureId === 'string') {
                navigateTo(`/structure/${structureId}`)
            }
        },
        onError: (err: any) => {
            notify('Oops! Structure could not be created', 'error')
            const errorMessage = err?.response?.data?.ErrorMessage
            setSubmitError(errorMessage)
        }
    })
    const [structureFormData, setStructureFormData] = useState<StructureDTO>(defaultStructureFormData)

    function handleCreate() {
        if (structureFormData) {
            const { error, data } = parseFormStructure(structureFormData.FormStructure)
            if (error) {
                setSubmitError(error.message)
                notify(`Oops! Structure could not be created: ${error.message}`, 'error')
            } else {
                structureMutation.mutate({
                    Name: structureFormData.Name,
                    Template: structureFormData.Template,
                    FormStructure: data
                })
            }
        }
    }

    return (
        <PageContainerWithHeader
            isSubtitle
            title='Create Structure'
            topRightElement={<BackButton route={'/structure'} />}
        >
            <Card>
                <CardContent className='no-padding'>
                    <StructureForm
                        onSubmit={handleCreate}
                        onReset={() => {
                            setStructureFormData(defaultStructureFormData)
                        }}
                        value={structureFormData}
                        onChange={(value: StructureDTO) => setStructureFormData(value)}
                        formControlSection={
                            <Box display='flex' flexDirection='column' alignItems='flex-end'>
                                <Box className='box-row' justifyContent='flex-end'>
                                    <Button variant='text' color='primary' type='reset'>
                                        Clear
                                    </Button>
                                    <Button variant='contained' color='primary' type='submit'>
                                        Create
                                    </Button>
                                </Box>
                                {submitError?.length ? (
                                    <FormHelperText error={true}>{submitError}</FormHelperText>
                                ) : null}
                            </Box>
                        }
                    />
                </CardContent>
            </Card>
        </PageContainerWithHeader>
    )
}

export default CreateStructure
