import { z } from 'zod'
import { PagingQuery, SortingQuery, dateTime, paged, uuidSchema } from '../../../common/react-query'

export type AuditRecordsQuery = {
    Search?: string
    SubscriberID?: string
    IssueID?: string
    TopicID?: string
    Events?: string[]
} & PagingQuery &
    SortingQuery

const auditRecord = z.object({
    ID: uuidSchema,
    CreatedAt: dateTime.optional().nullable(),
    SubscriberID: z.string().optional().nullable(),
    IssueID: z.string().optional().nullable(),
    TopicID: z.string().optional().nullable(),
    Event: z.string().optional().nullable(),
    Message: z.string().optional().nullable()
})
export const auditRecords = paged.extend({
    Rows: z.array(auditRecord)
})
export type AuditRecord = z.infer<typeof auditRecord>
export type AuditRecords = z.infer<typeof auditRecords>
