import {
    Alert,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    SelectProps
} from '@mui/material'
import { Structure } from './types'
import { useStructuresQuery } from './queries'
import React, { useEffect, useMemo } from 'react'
import { guessErrorMessage } from '../../helpers/guessErrorMessage'
import CenteredSpinner from '../../common/components/CenteredSpinner'

type StructureSelectorProps = {
    value?: string | null // structureID
    onChange: (id: string | undefined, structure: Structure | undefined) => void
    disabled?: boolean
    label?: string
    variant?: SelectProps['variant']
    required?: boolean
    selectedStructure?: (structure: Structure) => void
    error?: string
    allowedStructures?: string[] | null
    onlyContactForms?: boolean
}

const structureSelector = ({
    value, // StructureID
    onChange,
    disabled = false,
    label = 'Structure',
    variant = 'outlined',
    required = false,
    selectedStructure,
    error,
    allowedStructures = [],
    onlyContactForms = false
}: StructureSelectorProps) => {
    const results = useStructuresQuery({ page: 1, pageSize: 1000 })

    // Filter structures based on allowedStructures/onlyContactForms
    const filteredStructures = useMemo(() => {
        if (!results?.data) return []

        let available = results.data.Rows

        if (allowedStructures?.length) {
            available = results.data?.Rows.filter((s) => allowedStructures.includes(s.ID))
        }

        if (onlyContactForms) {
            available = available.filter((s) =>
                s.FormStructure.some((fs) => fs.name === 'contact' && fs.components.some((c) => c.name === 'to'))
            )
        }
        return available
    }, [results, allowedStructures])

    useEffect(() => {
        if (required && filteredStructures.length === 1 && value != filteredStructures[0].ID) {
            setTimeout(() => onChange(filteredStructures[0].ID, filteredStructures[0]), 100)
        }
    }, [required, filteredStructures])

    if (results.isLoading) {
        return <CenteredSpinner />
    }

    if (results.isError) {
        return <Alert severity='error'>Error loading structures: {guessErrorMessage(results.error)}</Alert>
    }

    if (filteredStructures.length === 0) {
        return <Alert severity='warning'>No structures were found. </Alert>
    }

    if (value && !filteredStructures.find((s) => s.ID === value)) {
        setTimeout(() => onChange(undefined, undefined), 100)
    }

    return (
        <FormControl variant={variant} sx={{ width: '100%' }}>
            <InputLabel id='structure-selector' required={required} error={!!error}>
                {label}
            </InputLabel>
            {filteredStructures.length > 0 && (
                <Select
                    error={!!error}
                    renderValue={(value: string) => {
                        if (!value) {
                            return <em>None</em>
                        }
                        const structure = results.data?.Rows.find((s) => s.ID === value)
                        return structure?.Name
                    }}
                    labelId='structure-selector'
                    variant={variant}
                    id='structure-selector-select'
                    value={value || ''}
                    label={label}
                    onChange={(e: SelectChangeEvent) => {
                        const newValue = e.target.value as string
                        if (newValue === value) {
                            return
                        }
                        if (newValue === '') {
                            onChange(undefined, undefined)
                            return
                        }

                        onChange(e.target.value as string, results.data?.Rows.find((s) => s.ID === newValue))
                    }}
                    disabled={disabled}
                >
                    <MenuItem value=''>
                        <em>None</em>
                    </MenuItem>
                    {filteredStructures.map((structure) => {
                        if (selectedStructure && value === structure.ID) {
                            setTimeout(() => selectedStructure(structure), 100)
                        }

                        return (
                            <MenuItem key={structure.ID} value={structure.ID}>
                                {structure.Name}
                            </MenuItem>
                        )
                    })}
                </Select>
            )}
            {error ? <FormHelperText error={true}>{error}</FormHelperText> : undefined}
        </FormControl>
    )
}

export const StructureSelector = React.memo(structureSelector)
