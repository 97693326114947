import { Box, Button, Card, CardContent, FormHelperText } from '@mui/material'
import { useEffect, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { editSettingsQuery, useSettingByIdQuery } from './queries'
import { Settings } from './types'
import { _isEqual, notify } from '../../../helpers'
import SettingsForm, { ISettingsFormData } from './SettingsForm'
import { isEqual } from 'lodash'
import PageContainerWithHeader from '../../../common/components/PageContainerWithHeader'
import { tryParseJson } from '../../../helpers/parseJSON'
import { useParams } from 'react-router-dom'
import { BackButton } from '../../../common/components'
import { colours } from '@/common/colours'

export function safeJSONStringify(val) {
    if (!val) return val

    try {
        return JSON.stringify(val, null, 2)
    } catch (err) {
        console.error(val, err)
        return null
    }
}

export function safeJSONParse(val) {
    if (!val) return val

    try {
        return JSON.parse(val)
    } catch (err) {
        console.error(val, err)
        return null
    }
}

function EditSettings() {
    const params = useParams()
    const { data, refetch } = useSettingByIdQuery(params.id || '')
    const editSettingsMutation = useMutation({
        mutationFn: (settings: Settings) => editSettingsQuery(settings)
    })

    const [settingsFormData, setSettingsFormData] = useState<ISettingsFormData>(data)
    const [settingsFormDataErrors, setSettingsFormDataErrors] = useState<Partial<ISettingsFormData> | undefined>(
        undefined
    )

    const settingsFormJsonDefault = safeJSONStringify(data?.Data || null) || null
    const [settingsFormJson, setSettingsFormJson] = useState<string | null>(settingsFormJsonDefault)
    const [settingsFormJsonIsInvalid, setSettingsFormJsonIsInvalid] = useState(false)

    const formDataHasChanges = !isEqual(data, { ...settingsFormData, Data: safeJSONParse(settingsFormJson) })
    const [submitError, setSubmitError] = useState('')

    useEffect(() => {
        if (settingsFormJson != settingsFormJsonDefault && safeJSONParse(settingsFormJson) === null) {
            setSettingsFormJsonIsInvalid(true)
        } else {
            setSettingsFormJsonIsInvalid(false)
        }

        if (submitError?.length) {
            setSubmitError('')
        }
    }, [settingsFormData, settingsFormJson])

    useEffect(() => {
        setSettingsFormData(data)
        setSettingsFormJson(settingsFormJsonDefault)
    }, [data])

    function handleSave() {
        const { error, data } = tryParseJson(settingsFormJson)
        if (error) {
            setSubmitError(data?.message || '')
        } else {
            const settingsFormDataWithJson = {
                ...settingsFormData,
                Data: data
            }

            editSettingsMutation.mutate(settingsFormDataWithJson as Settings, {
                onSuccess: (res) => {
                    notify('Setting successfuly updated', 'info')
                    setSubmitError('')
                    refetch()
                },
                onError: (err: any) => {
                    notify('Oops! Setting could not be updated', 'error')
                    const errorMessage = err?.response?.data?.ErrorMessage
                    if (errorMessage.includes('duplicate key') && errorMessage.includes('name')) {
                        setSettingsFormDataErrors((prev) => ({ ...prev, Name: 'This name already exists.' }))
                    } else {
                        setSubmitError(errorMessage)
                    }
                }
            })
        }
    }

    return (
        <PageContainerWithHeader
            isSubtitle
            title='Edit Settings'
            topRightElement={<BackButton route='/system/settings' />}
        >
            <Card>
                <CardContent sx={{ gap: '8px', display: 'flex', flexDirection: 'column' }}>
                    {!data || !settingsFormData ? (
                        <></>
                    ) : (
                        <SettingsForm
                            disableSettingsTypeSelector
                            formData={settingsFormData}
                            setFormData={setSettingsFormData}
                            settingsFormJson={settingsFormJson}
                            setSettingsFormJson={setSettingsFormJson}
                            formDataErrors={settingsFormDataErrors}
                            setFormDataErrors={setSettingsFormDataErrors}
                            formControlSection={
                                <Box display='flex' flexDirection='column' alignItems='flex-end'>
                                    <Box className='box-row' justifyContent='flex-end'>
                                        <Button variant='text' color='primary' type='reset'>
                                            Reset Changes
                                        </Button>
                                        <Button
                                            sx={
                                                !!submitError || settingsFormJsonIsInvalid
                                                    ? {
                                                          '&:disabled': {
                                                              backgroundColor: colours.light_red,
                                                              color: colours.orange
                                                          }
                                                      }
                                                    : undefined
                                            }
                                            disabled={!formDataHasChanges || settingsFormJsonIsInvalid}
                                            variant='contained'
                                            color='primary'
                                            type='submit'
                                        >
                                            {settingsFormJsonIsInvalid ? 'Invalid JSON' : 'Save'}
                                        </Button>
                                    </Box>
                                    {submitError?.length ? (
                                        <FormHelperText error={true}>{submitError}</FormHelperText>
                                    ) : null}
                                </Box>
                            }
                            onSubmit={() => handleSave()}
                            onReset={() => {
                                setSettingsFormData(data)
                                setSettingsFormJson(settingsFormJsonDefault)
                            }}
                        />
                    )}
                </CardContent>
            </Card>
        </PageContainerWithHeader>
    )
}

export default EditSettings
