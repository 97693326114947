import { useQuery } from '@tanstack/react-query'
import { httpGet } from '../../common/client'
import { z } from 'zod'
import { baseQueryConfig } from '../../common/react-query'
import { BASE } from '../../common/constants'

export function sanitizeSysPeriod(sysPeriod: string) {
    return JSON.parse(sysPeriod.replace(')', ']'))[1]
}

// enum values are mapped to database table names e.g.:
// commented enum values are tables that track history but are not yet used within this app
export enum HistoryType {
    CONTENT = 'content'
    // DOCUMENT = 'document',
    // MEDIA = 'media',
    // NAVIGATION = 'navigation',
    // REDIRECT_RULE = 'redirect_rule',
    // ROLE = 'role',
    // SECURITY_GROUP = 'security_group',
    // STRUCTURE = 'structure',
}

// id: id of record that you want to retrieve the history for
export function useHistoryQuery(id: string, type: HistoryType) {
    const query = useQuery({
        ...baseQueryConfig,
        queryKey: ['history-query', id],
        queryFn: async () =>
            httpGet(
                `${BASE}/api/v1/history/${type}/${id}`,
                {
                    // hard set to 1000 until we have pagination/infinite scroll UI for revision history
                    pageSize: 1000
                },
                z.any()
            )
    })

    return query
}
