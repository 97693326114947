import { Dialog, DialogTitle, DialogContent } from '@mui/material'
import { ContentExplorer, ContentExplorerProps } from './ContentExplorer'

interface ContentExplorerDialogProps extends ContentExplorerProps {
    isOpen: boolean
    onClose: () => void
}

export function ContentExplorerDialog({ isOpen, onClose, ...contentExplorerProps }: ContentExplorerDialogProps) {
    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth={'xl'}>
            <DialogTitle>Content Explorer</DialogTitle>
            <DialogContent style={{ paddingTop: '8px' }}>
                <ContentExplorer {...contentExplorerProps} />
            </DialogContent>
        </Dialog>
    )
}
