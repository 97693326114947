import React, { useState } from 'react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Stack,
    Switch
} from '@mui/material'
import TextField from '@mui/material/TextField'
import { SiteSelectorForContent } from '../../../common/components/selectors/SiteSelectorForContent'
import { BaseTemplate, TemplateSelector } from './components/TemplateSelector'
import { StructureSelector } from '../../structure/StructureSelector'
import Button from '@mui/material/Button'
import { Content, ContentLike, getAllContentLikeContent } from '../types'
import { useStructuredContentValidators } from './useStructuredContentValidators'
import { httpPost } from '../../../common/client'
import { z } from 'zod'
import { notify } from '../../../helpers'
import { guessErrorMessage } from '../../../helpers/guessErrorMessage'
import { useAppNavigation } from '../../../app/useAppNavigation'
import { WhatsNew } from '../../../common/components/WhatsNew'
import { ContentTypeSelector } from '../ContentTypeSelector'
import { BASE } from '../../../common/constants'
import { typeToEntityScope } from '../../auth/entityScope'
import { useCurrentSiteID, useDefaultSitesForSelector } from '../../auth/atoms'

interface CreateStructuredContentProps {
    open: boolean
    onClose: () => void
    onImport?: () => void
    onCreate?: (id: string, content: Content) => void
    availableTypes?: ContentLike[]
}

export const CreateStructuredContent = ({
    open,
    onClose,
    onImport,
    onCreate = () => {},
    availableTypes = getAllContentLikeContent()
}: CreateStructuredContentProps) => {
    const defaultSites = useDefaultSitesForSelector()
    const now = Date.now()

    const { navigateTo } = useAppNavigation()
    const [loading, setLoading] = useState(false)
    const [navigateToEditor, setNavigateToEditor] = useState(true)
    const [selectedTemplate, setSelectedTemplate] = useState<BaseTemplate | undefined>(undefined)

    const [state, setState] = useState<Content>({
        Type: availableTypes[0],
        PageLayout: 'DCT',
        Title: '',
        Route: '',
        Sites: defaultSites || [],
        DepartmentID: null,
        Path: '',
        StructureID: null,
        ExpireAt: null,
        PublishAt: null,
        // To satisfy the Content read interface
        Meta: {},
        Data: {},
        ID: '',
        Created: now as any,
        Updated: now as any,
        Active: true,
        Deleted: null,
        MediaID: null,
        Owner: '',
        PrivacyLevel: 0,
        Publisher: '',
        Tags: [],
        Settings: {}
    })

    const { validateAll, errors } = useStructuredContentValidators(
        ['Sites', 'Route', 'Title', 'Path', 'StructureID'],
        state
    )

    React.useEffect(() => {
        const titlePart = state.Title.replace(/[^a-zA-Z0-9]+/g, '-').toLowerCase()
        const suffix = new Date()
            .toISOString()
            .split('.')[0]
            .replaceAll(/[^0-9]+/g, '')

        switch (state.Type) {
            case 'alert':
                setState({ ...state, Route: '' })
                break
            case 'news':
                setState({ ...state, Route: `/news/${titlePart}-${suffix}` })
                break
            case 'event':
                setState({ ...state, Route: `/event/${titlePart}-${suffix}` })
                break
            default:
                setState({ ...state, Route: `/${titlePart}` })
        }
    }, [state.Title, state.Type])

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth={'md'}>
            <div className={'flex-row-align-center'}>
                <DialogTitle style={{ marginRight: '0.5rem' }}>Create {state.Type}</DialogTitle>
                <WhatsNew link={'/what-is-a-structure?hash=doL3doYXQtaXMtYS1zdHJ1Y3R1cmU='} />
            </div>

            <DialogContent>
                <Stack spacing={2} direction={'column'}>
                    <FormControl fullWidth sx={{ my: 1 }}>
                        <TextField
                            label='Title'
                            required
                            style={{ width: '100%' }}
                            value={state.Title || ''}
                            onChange={(v) => state && setState({ ...state, Title: v.target.value })}
                            error={!!errors.Title}
                        />
                    </FormControl>

                    {availableTypes.length > 1 && (
                        <FormControl fullWidth sx={{ my: 1 }}>
                            <ContentTypeSelector
                                value={state.Type as ContentLike}
                                onChange={(v) => setState({ ...state, Type: v })}
                                availableTypes={availableTypes}
                            />
                        </FormControl>
                    )}
                    <FormControl fullWidth sx={{ m: 0 }}>
                        <SiteSelectorForContent
                            ContentType={typeToEntityScope(state.Type)}
                            Selected={state.Sites || []}
                            OnChange={(sites, departmentID) =>
                                setState({ ...state, Sites: sites, DepartmentID: departmentID })
                            }
                            HasError={!!errors.Sites}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ my: 1 }}>
                        <TemplateSelector
                            required
                            ownerID={''}
                            path={state.Path}
                            onChange={(path, tpl) => {
                                setState({ ...state, Path: path })
                                setSelectedTemplate(tpl)
                            }}
                            templateType={'all'}
                            classifications={[state.Type]}
                            error={errors.Path}
                            ignoreNotFound={true}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ my: 1 }}>
                        <StructureSelector
                            required
                            value={state.StructureID}
                            onChange={(v, s) => {
                                setState({ ...state, StructureID: v || null })
                            }}
                            error={errors.StructureID}
                            allowedStructures={selectedTemplate?.Structures}
                        />
                    </FormControl>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack direction='row' justifyContent='space-between' alignItems='flex-end' gap='32px' width={'100%'}>
                    {!!onImport && <Button onClick={onImport}>Import</Button>}

                    <Button variant='outlined' color='secondary' onClick={onClose} style={{ marginLeft: 'auto' }}>
                        Cancel
                    </Button>
                    <Stack direction={'column'} spacing={2} justifyContent='flex-end' alignItems='flex-end'>
                        <FormControlLabel
                            control={<Switch />}
                            label={'Open in editor'}
                            labelPlacement={'end'}
                            onChange={() => setNavigateToEditor(!navigateToEditor)}
                            checked={navigateToEditor}
                        />
                        <Button
                            fullWidth
                            variant='contained'
                            color='primary'
                            disabled={loading}
                            onClick={() => {
                                if (!validateAll()) {
                                    return
                                }

                                httpPost(`${BASE}/api/v2/content`, state, z.string())
                                    .then((newID) => {
                                        notify(`${state.Type} created: ${state?.Title || ''}, id: ${newID}`, 'info')
                                        setLoading(false)
                                        newID && onCreate(newID, state)
                                        if (navigateToEditor) navigateTo(`/content-editor/${newID}`)
                                        onClose()
                                    })
                                    .catch((e) => {
                                        setLoading(false)
                                        notify(guessErrorMessage(e), 'error')
                                    })
                            }}
                        >
                            Create {state.Type}
                        </Button>
                    </Stack>
                </Stack>
            </DialogActions>
        </Dialog>
    )
}
