import React, { useContext, useEffect, useState } from 'react'
import { Alert, Button, Card, CardContent } from '@mui/material'
import { Format, notify, sanitizePathString } from '../../helpers'
import { makeStyles } from '@mui/styles'
import { BackButton } from '../components'
import { ToastContainer } from 'react-toastify'
import axios from 'axios'
import { disabledContext } from '../DisabledContext'
import { contentService } from '../../pkgs/content/content.service'
import { tagService } from '../../pkgs/system/tags/tag.service'
import { useRouterState } from '../useParams'
import 'react-toastify/dist/ReactToastify.css'
import './ResourceEditor.css'
import { SiteSelectorForContent } from '../components/selectors/SiteSelectorForContent'
import { useAppContext, useCurrentSite, useCurrentSiteID } from '../../pkgs/auth/atoms'
import { CodeEditor } from '../../pkgs/monaco/code-editor'
import { mustString } from '../../helpers/parseJSON'
import { useAppNavigation } from '../../app/useAppNavigation'
import { TagType } from '../../pkgs/system/tags/types'
import { DefaultPage, DefaultMax, ContentAPI } from '../constants'
import { EntityScopeEnum } from '../../pkgs/auth/entityScope'

//TODO: Notify should appear to the user
// - in the templates overview page after being pushed by navigate

export default function ResourceEditor(props) {
    const params = useRouterState()
    const { navigateTo } = useAppNavigation()
    const classes = useStyles()
    const currentSite = useCurrentSite()
    const evaluators = useAppContext()
    const currentSiteID = useCurrentSiteID()
    const [, setDisabled] = useContext(disabledContext)
    const [tags, setTags] = useState([])
    const [isEnabled, setIsEnabled] = useState(false)
    const [hasContentPermission, setHasContentPermission] = useState(false)

    const [errors, setErrors] = useState({
        sites: false
    })

    // content
    const [state, setState] = useState<any>({
        active: false,
        content: '',
        id: params?.id || '',
        path: '',
        route: params?.title || '',
        title: params?.title || '',
        type: params?.type || '',
        sites: []
    })

    useEffect(() => {
        setDisabled(true)
    }, [])

    useEffect(() => {
        document.title = `${state?.title ? `${String(state.title)}-` : ''} Resource Editor`
    }, [state])

    // Deferred Object Load
    useEffect(() => {
        if (!currentSiteID) return
        Promise.all([
            tagService
                .getAll(currentSiteID, [TagType.Site], DefaultPage, DefaultMax, {})
                .then((r) => (r as any).results),
            contentService.getContentById(currentSiteID, state.id)
        ]).then(([tenantTags, thisContent]) => {
            if (thisContent.active) {
                if (evaluators.action(thisContent, 'update')) {
                    setHasContentPermission(true)
                }
                setState(thisContent)
            } else {
                setHasContentPermission(true)
            }

            if (currentSite?.Type === 'department') {
                setHasContentPermission(false)
                notify('Department Templates are not available', 'error')
            }
            setTags(tenantTags)
            setIsEnabled(true)
        })
    }, [currentSiteID])

    // On Publish or Edit
    // TODO => refactor
    const newContent = (contentRoute, method) => {
        if (method === 'post') {
            const data = {
                ...state,
                route: Format.stringToResourceRoute(contentRoute),
                path: sanitizePathString(state.id),
                tags: [],
                settings: {}
            }

            axios
                .post(ContentAPI, data, { params: { siteId: currentSiteID } })
                .then(() => {
                    notify('Template successfully created!', 'info')
                    navigateTo('/system/resources', { state: params.paginationState })
                })
                .catch((err) =>
                    notify(`${err.response.status} : ${err.response.statusText}, Please try again later`, 'error')
                )
        } else if (method === 'put') {
            let data = {
                ...state,
                // content: value,
                route: Format.stringToResourceRoute(contentRoute),
                path: sanitizePathString(state.id),
                tags: [],
                settings: {}
            }

            axios
                .put(ContentAPI, data, { params: { siteId: currentSiteID } })
                .then(() => notify('Template successfully edited!', 'info'))
                .catch((err) =>
                    notify(`${err.response.status} : ${err.response.statusText}, Please try again later`, 'error')
                )
        }
    }

    // Validate Calls the handleSave function passed to it -> Thus the properties it accesses must be provided to Validate.
    const validate = (handleSave, contentRoute, method) => {
        if (state.content.length < 4) {
            notify("Couldn't save an empty file", 'error')
            return 'error'
        } else if (state.sites.length === 0) {
            setErrors((prev) => ({ ...prev, sites: true }))
            return notify('Whoops! Some values are missing', 'error')
        } else {
            handleSave(contentRoute, method)
        }
    }

    return (
        <div className='container-fluid'>
            <div className='row'>
                <ToastContainer />
                <div className='col-xs-12 col-lg-9 first-lg'>
                    <Card style={{ padding: '1rem', margin: '1rem' }}>
                        <CardContent style={{ padding: 0 }}>
                            <h2 className='editor-title'>{state.title}</h2>
                            <CodeEditor
                                height={'70vh'}
                                value={mustString(state?.content)}
                                language={params?.mode === 'js' ? 'javascript' : 'css'}
                                onChange={(value) => {
                                    setState((prev) => ({ ...prev, content: value || '' }))
                                }}
                            />
                        </CardContent>
                    </Card>
                </div>
                <div className='col-xs-12 col-lg-3 first-xs'>
                    <div className='flex-row header-container'>
                        <div className='transparent'></div>
                        <BackButton route={`/system/resources`} state={params?.paginationState || {}} />
                    </div>

                    {!hasContentPermission && (
                        <Alert severity={'info'}>
                            You don't have access to this resource - This is a read-only view
                        </Alert>
                    )}

                    {isEnabled && (
                        <div className='flex-column-center'>
                            <SiteSelectorForContent
                                HasError={!state?.sites || state.sites.length === 0}
                                ContentType={EntityScopeEnum.Resource} // The permissions are the same for 'template' | 'css' | 'js'
                                Selected={state.sites || []}
                                OnChange={(sites) => {
                                    setState((prev) => ({ ...prev, sites: sites as any }))
                                }}
                            />

                            {/*TODO => refactor*/}
                            {hasContentPermission && (
                                <Button
                                    disableElevation
                                    variant='outlined'
                                    color='primary'
                                    size='small'
                                    className={classes.form}
                                    onClick={() =>
                                        state.created === undefined || state.created === null
                                            ? validate(newContent, state.route, 'post')
                                            : validate(newContent, state.route, 'put')
                                    }
                                >
                                    {state.created === undefined || state.created === null ? 'NEW' : 'SAVE'}
                                </Button>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles({
    root: {
        borderRadius: 1,
        fontWeight: 400,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'center',
        paddingRight: '7%',
        paddingLeft: '7%',
        verticalAlign: 'middle',
        width: '7vw',
        color: 'white',
        backgroundColor: '#6188f1'
    },
    auto: {
        width: '90%'
    },
    form: {
        height: '5%',
        margin: '0.5vh',
        width: '90%'
    },
    select: {
        width: '100%',
        marginTop: '1vh',
        marginBottom: '1vh'
    }
})
