import { useMemo, useState } from 'react'
import { Box, Button, FormControl, FormHelperText, InputLabel, TextField } from '@mui/material'
import { OpenGallery } from '../../../media/image/OpenGallery'
import { ImageGallery } from '../../../media/image/ImageGallery'
import { isEqual } from 'lodash'
import useSiteForAccountsQueryMutation from '../../../../common/query-hooks/useSiteForAccountsQueryMutation'
import { BoxForm } from '../../../../common/components/BoxForm'
import { useParams } from 'react-router-dom'

interface IEditSettingsDefaultSeo {
    defaultSeoTitle: string
    defaultSeoDescription: string
    defaultSeoImage: string
}

function EditSettingsDefaultSeo() {
    const params = useParams()
    const { siteData, mutation } = useSiteForAccountsQueryMutation(params.id)

    const [isImageGalleryOpen, setIsImageGalleryOpen] = useState(false)

    const defaultFormDataValues: IEditSettingsDefaultSeo = useMemo(
        () => ({
            defaultSeoTitle: siteData?.settings?.defaultSeoTitle || '',
            defaultSeoDescription: siteData?.settings?.defaultSeoDescription || '',
            defaultSeoImage: siteData?.settings?.defaultSeoImage || ''
        }),
        [siteData]
    )
    const [formData, setFormData] = useState(defaultFormDataValues)
    const formDataHasChanges = useMemo(
        () => !isEqual(formData, defaultFormDataValues),
        [defaultFormDataValues, formData]
    )

    function textFieldOnChangeHandler(fieldName: string, value: string) {
        setFormData((prev) => ({
            ...prev,
            [fieldName]: value
        }))
    }

    function handleResetFormData() {
        setFormData(defaultFormDataValues)
    }

    function handleSubmit() {
        // Ensure not to remove fields within settings object that are not used in this component
        const updateSettingsJsonObject = {
            ...siteData.settings,
            ...formData
        }

        mutation.mutate({
            id: siteData.id,
            settings: updateSettingsJsonObject
        })
    }

    if (!siteData) return null

    return (
        <BoxForm>
            <TextField
                variant='standard'
                label='SEO Title'
                helperText='A title should be 50-70 characters in length'
                value={formData.defaultSeoTitle}
                onChange={(e) => textFieldOnChangeHandler('defaultSeoTitle', e.target.value)}
            />
            <FormControl variant='standard'>
                <TextField
                    id='outlined-basic'
                    label='SEO Description'
                    variant='outlined'
                    minRows={4}
                    maxRows={6}
                    multiline
                    value={formData.defaultSeoDescription}
                    onChange={(e) => textFieldOnChangeHandler('defaultSeoDescription', e.target.value)}
                />
                <FormHelperText>
                    A description should be about 100-150 characters in length and contain descriptive words
                </FormHelperText>
            </FormControl>
            <div>
                <InputLabel>SEO Image:</InputLabel>
                <OpenGallery
                    title={'Default SEO Image'}
                    error={false}
                    component={false}
                    setCurrentComponent={() => {}}
                    value={formData?.defaultSeoImage ? '/thumbnails/' + formData?.defaultSeoImage : undefined}
                    removeSelectedMedia={() => setFormData((prev) => ({ ...prev, defaultSeoImage: '' }))}
                    handleOpen={() => setIsImageGalleryOpen(true)}
                    rootStyles={{ maxWidth: '100%' }}
                    helperText={'Recommended size: 1200px x 600px with no text near edges'}
                />
            </div>
            {isImageGalleryOpen && (
                <ImageGallery
                    open={isImageGalleryOpen}
                    close={() => setIsImageGalleryOpen(false)}
                    onChange={(img) => setFormData((prev) => ({ ...prev, defaultSeoImage: `${img.id}` }))}
                />
            )}
            <Box className='box-row'>
                <Button variant='text' color='primary' type='reset' onClick={handleResetFormData}>
                    Reset Changes
                </Button>
                <Button
                    disabled={!formDataHasChanges}
                    variant='contained'
                    color='primary'
                    type='submit'
                    onClick={handleSubmit}
                >
                    Save
                </Button>
            </Box>
        </BoxForm>
    )
}

export default EditSettingsDefaultSeo
