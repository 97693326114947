import { Typography } from '@mui/material'
import { getPublishStatus, publishStatusColour } from '../ContentEditorSaveCard'

interface PublishStatusLabelProps {
    publishAt: string | Date | null | undefined
    expireAt: string | Date | null | undefined
}

export default function PublishStatusLabel({ publishAt, expireAt }: PublishStatusLabelProps) {
    const publishStatus = getPublishStatus(publishAt, expireAt)
    return (
        <>
            <Typography variant='subtitle1' color={publishStatusColour[publishStatus]}>
                {publishStatus.toUpperCase()}
            </Typography>
        </>
    )
}
