import React, { useEffect, useState } from 'react'
import { disabledContext } from '../../common/DisabledContext'
import { AddButton, BackButton } from '../../common/components'
import { List, ListItem, ListItemText } from '@mui/material'
import AnnouncementIcon from '@mui/icons-material/Announcement'
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus'
import MapIcon from '@mui/icons-material/Map'
import { BusRoutes } from './BusRoutes'
import { BusAreas } from './BusAreas'
import { BusStatus } from './BusStatus'
import { makeStyles } from '@mui/styles'
import { EntityScopeEnum } from '../auth/entityScope'
import { useAppContext } from '../auth/atoms'
import PageContainerWithHeader from '../../common/components/PageContainerWithHeader'

const TransportationSections = {
    status: {
        permission: EntityScopeEnum.BusStatus,
        name: 'Status',
        button: 'status',
        icon: <AnnouncementIcon className='pages-pages-icon' fontSize='small' />,
        Component: (props) => <BusStatus {...props} />,
        buttonPermission: EntityScopeEnum.BusStatus
    },
    routes: {
        permission: EntityScopeEnum.BusRoute,
        name: 'Routes',
        button: 'route',
        icon: <DirectionsBusIcon className='pages-pages-icon' fontSize='small' />,
        Component: (props) => <BusRoutes {...props} />,
        buttonPermission: EntityScopeEnum.BusRoute
    },
    areas: {
        permission: EntityScopeEnum.BusArea,
        name: 'Areas',
        icon: <MapIcon className='pages-pages-icon' fontSize='small' />,
        Component: (props) => <BusAreas {...props} />,
        button: 'area',
        buttonPermission: EntityScopeEnum.BusArea
    }
}

export default React.memo(Transportation)

function Transportation() {
    const [, setIsDisabled] = useState(disabledContext)
    const evaluators = useAppContext()
    const [view, setView] = useState('status')
    const [open, setOpen] = useState(false)

    useEffect(() => {
        setIsDisabled(false)
    }, [])

    const changeView = async (viewString) => {
        await setView(viewString)
    }

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleTextFieldWithName = (event, setState, setErrors) => {
        setState((prev) => ({ ...prev, [event.target.name]: event.target.value }))
        if (setErrors) {
            setErrors((prev) => ({ ...prev, [event.target.name]: false }))
        }
    }

    const handleSelectWithName = (event, setState, setErrors) => {
        setState(event.target.value)
        setErrors((prev) => ({ ...prev, [event.target.name]: false }))
    }

    function RenderTransportationSectionByView() {
        const section = TransportationSections[view]
        if (!section) return null
        const { Component } = section
        return (
            <Component
                open={open}
                handleClose={handleClose}
                handleTextField={handleTextFieldWithName}
                handleSelectWithName={handleSelectWithName}
                classes={classes}
            />
        )
    }

    function RenderTransportationButtonByView() {
        const section = TransportationSections[view]
        if (section && section.button) {
            const { button, buttonPermission } = section

            if (!evaluators.entityScopeAny(buttonPermission)) return ''
            return <AddButton title={`add ${button}`} func={handleOpen} />
        } else {
            return <BackButton route='/' />
        }
    }

    const classes = useStyles()
    return (
        <PageContainerWithHeader title='Transportation' topRightElement={<RenderTransportationButtonByView />}>
            <div className='row'>
                <div className='col-xs-12 col-md-3'>
                    <List>
                        {Object.entries(TransportationSections).map(([sectionName, { permission, icon, name }]) => {
                            if (!evaluators.entityScopeAny(permission)) return ''
                            return (
                                <ListItem
                                    className='pages-list-item'
                                    onClick={() => changeView(sectionName)}
                                    key={`${name}--${view}`}
                                    selected={view === sectionName}
                                >
                                    {icon}
                                    <ListItemText className='pages-list-text' disableTypography={true}>
                                        {name}
                                    </ListItemText>
                                </ListItem>
                            )
                        })}
                    </List>
                </div>

                <div className='col-xs-12 col-md-9'>
                    <RenderTransportationSectionByView />
                </div>
            </div>
        </PageContainerWithHeader>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            outline: 0,
            marginTop: '0.5vh',
            marginBottom: '1vh',
            margin: 'auto',
            display: 'flex',
            justifyContent: 'column'
        }
    },
    select: {
        width: '100%',
        marginTop: '1vh',
        marginBottom: '1vh'
    },
    textfield: {
        width: '100%',
        margin: 'auto'
    },
    form: {
        marginTop: '1vh',
        height: '5%',
        width: '100%'
    },
    formWithSpace: {
        marginTop: '1vh',
        height: '5%',
        width: '100%'
    },
    button: {
        width: '100%',
        marginTop: '2vh',
        marginBottom: '2vh'
    },
    auto: {
        width: '89.7%',
        marginTop: '1vh',
        marginBottom: '1vh',
        margin: 'auto'
    },
    autoMargin: {
        overflowY: 'auto',
        overflowX: 'hidden',
        margin: 'auto',
        width: '80%'
    },
    datePickerButton: {
        position: 'absolute',
        top: '90%',
        right: '5%'
    }
}))
