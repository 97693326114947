import { Document, Page, pdfjs } from 'react-pdf'
import { Button, ButtonGroup } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import React, { Fragment, useEffect, useState } from 'react'
import { documentService } from '../document.service'
import { makeStyles } from '@mui/styles'
import './DocumentPreview.css'
import { useCurrentSiteID } from '../../../auth/atoms'

const DocumentPreview = ({ documentId, editedBinary }) => {
    const currentSiteID = useCurrentSiteID()
    const [documentBinary, setDocumentBinary] = useState('')
    const [pageNumber, setPageNumber] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const classes = useStyles()

    // fixes react-pdf crash: Uncaught SyntaxError: Unexpected token '<' (at pdf.worker.js:1:1)
    // https://github.com/wojtekmaj/react-pdf/issues/991#issuecomment-1345466418
    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
    }, [])

    useEffect(() => {
        // Document to Edit has changed and needs to be downloaded
        if (documentId) {
            documentService.getPDF(currentSiteID, documentId).then(
                (r) => setDocumentBinary(r),
                (e) => console.log(e)
            )
        }
    }, [documentId])

    useEffect(() => {
        if (!editedBinary || !editedBinary.length) {
            return
        }
        setDocumentBinary(editedBinary)
    }, [editedBinary])

    function onDocumentLoadSuccess({ numPages }) {
        setTotalPages(numPages)
        setPageNumber(1)
        setIsLoading(false)
    }

    function changePage(offset) {
        setPageNumber((prevPageNumber) => prevPageNumber + offset)
    }

    function previousPage() {
        changePage(-1)
    }

    function nextPage() {
        changePage(1)
    }

    return (
        <Fragment>
            <Document
                file={documentBinary}
                onLoadSuccess={onDocumentLoadSuccess}
                style={{ maxWidth: '100%', margin: 'auto' }}
            >
                <Page key={`page_${pageNumber}`} pageNumber={pageNumber} className={classes.page} />
            </Document>
            {!isLoading && (
                <div className='flex-row-center'>
                    <ButtonGroup variant='contained' className={classes.pdfNavigation}>
                        <Button onClick={previousPage} className={classes.button} disabled={pageNumber === 1}>
                            <ArrowBackIosIcon />
                        </Button>
                        <Button style={{ pointerEvents: 'none' }} className={classes.button}>
                            {pageNumber} of {totalPages}
                        </Button>
                        <Button onClick={nextPage} className={classes.button} disabled={pageNumber === totalPages}>
                            <ArrowForwardIosIcon />
                        </Button>
                    </ButtonGroup>
                </div>
            )}
        </Fragment>
    )
}
export default DocumentPreview
const useStyles = makeStyles({
    pdfNavigation: {
        height: '60%',
        gridColumnStart: 5,
        gridColumnEnd: 'span 2',
        justifySelf: 'center',
        gridRowStart: 10,
        zIndex: 10000000
    },
    page: {
        borderRadius: '4px'
    },
    button: {
        backgroundColor: '#5a5a5a',
        color: 'white'
    }
})
