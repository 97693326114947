import React, { useEffect } from 'react'
import { GridColDef } from '@mui/x-data-grid'
import { CellLine, CellWrapper, NullableDateCell, PublishPeriodCell, TwoLinesCell } from '../../grid/cells/GridCells'
import { guessErrorMessage } from '../../../helpers/guessErrorMessage'
import { Box, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, TextField } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import Button from '@mui/material/Button'
import { useAppContext } from '../../auth/atoms'
import { TagsSelector } from '../../grid/selectors/TagsSelector'
import { Content, ContentType } from '../types'
import { ContentQueryParams, useContentQuery } from '../queries'
import { getContentEditorPath } from '../../../app/useAppNavigation'
import { defaultPageQuery } from '../../../common/react-query'
import { DataGridBase } from '../../grid/DataGridBase'
import { TagType } from '../../system/tags/types'

export type ContentExplorerProps = {
    contentTypes: ContentType[]
    sites: string[]
    search?: string
    selected?: string[]
    onSelect?: (id: string, content: Content) => void
    structureID: string | null
    onQueryChange?: (query: ContentQueryParams) => void
}

export const ContentExplorer: React.FC<ContentExplorerProps> = ({
    contentTypes,
    sites,
    search,
    onSelect,
    selected = [],
    structureID,
    onQueryChange = () => {}
}) => {
    const appContext = useAppContext()
    const tenantSites = appContext
        .getTenantSites()
        .filter((s) => s.Type !== 'department')
        .sort((a, b) => a.Name.localeCompare(b.Name))

    // if no sites provided, use all sites except departments
    // otherwise it's impossible to insert link to another site (if I'm on a school site, I can't insert a link to the district site)
    // but by default in Sites selector the current site is preselected, so the default behavior is not changed
    // If some districts want do disable the site selector, we can make it configurable
    sites = sites && sites.length > 1 ? sites : tenantSites.map((s) => s.ID)
    const sitesForSelector = sites && sites.length > 1 ? tenantSites.filter((s) => sites.includes(s.ID)) : tenantSites

    const defaultSite = sites?.length == 1 ? sites[0] : appContext.currentSiteID || ''
    const defaultQuery: ContentQueryParams = {
        ...defaultPageQuery,
        ContentTypes: contentTypes,
        Sites: defaultSite ? [defaultSite] : [],
        StructureID: structureID
    }
    const [query, setQuery] = React.useState<ContentQueryParams>({ ...defaultQuery, Search: search })
    const results = useContentQuery(query)

    const [filterType, setFilterType] = React.useState<ContentType | ''>(
        contentTypes?.length == 1 ? contentTypes[0] : ''
    )
    const [filterSite, setFilterSite] = React.useState<string>(defaultSite)
    const [filterTags, setFilterTags] = React.useState<{ tags: string[]; page: number }>({ tags: [], page: 0 })

    useEffect(() => {
        setQuery({ ...query, ContentTypes: filterType ? [filterType] : contentTypes })
    }, [filterType])

    useEffect(() => {
        onQueryChange(query)
    }, [query])

    useEffect(() => {
        setQuery({ ...query, Sites: filterSite ? [filterSite] : sites })
    }, [filterSite])

    useEffect(() => {
        setQuery({ ...query, Tags: filterTags.tags })
    }, [filterTags])

    useEffect(() => {
        setQuery({ ...query, page: 1 })
    }, [query.Search, query.ContentTypes, query.Sites, query.Tags])

    const columns: GridColDef[] = [
        {
            field: 'Title',
            headerName: 'Title',
            flex: 2,
            filterable: false,
            sortable: true,
            renderCell: (params) => (
                <TwoLinesCell
                    l1={`${params.row.Title}${
                        params.row.Data?.contact?.to ? ` (${params.row.Data?.contact?.to})` : ''
                    }`}
                    l2={params.row.ID}
                    path={getContentEditorPath(params.row) || undefined}
                    target='_blank'
                />
            )
        },
        {
            field: 'Type',
            headerName: 'Content Type',
            width: 150,
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                <CellWrapper>
                    <CellLine>{params.row.Type}</CellLine>
                </CellWrapper>
            )
        },
        {
            field: 'PublishPeriod',
            headerName: 'Status',
            width: 250,
            sortable: false,
            filterable: false,
            renderCell: (params) => (
                <PublishPeriodCell publishAt={params.row.PublishAt} expireAt={params.row.ExpireAt} />
            )
        },
        {
            field: 'publish_at',
            headerName: 'Publish At',
            flex: 1,
            sortable: true,
            filterable: false,
            renderCell: (params) => <NullableDateCell row={params.row} fieldName={'PublishAt'} />
        },
        {
            field: 'expire_at',
            headerName: 'Expire At',
            flex: 1,
            sortable: true,
            filterable: false,
            renderCell: (params) => <NullableDateCell row={params.row} fieldName={'ExpireAt'} />
        }
    ]

    if (onSelect) {
        columns.push({
            field: 'select',
            headerName: '...',
            width: 150,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                const isSelected = selected.includes(params.row.ID)
                return (
                    <CellWrapper>
                        <Button onClick={() => onSelect(params.row.ID, params.row)} disabled={isSelected}>
                            {isSelected ? 'Selected' : 'Select'}
                        </Button>
                    </CellWrapper>
                )
            }
        })
    }

    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ flex: '1 1 30%' }}>
                    <TextField
                        style={{ flex: '1' }}
                        onChange={(e) => setQuery({ ...query, Search: e.target.value })}
                        value={query.Search || ''}
                        label={'Search'}
                        placeholder='Search'
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position={'start'}>
                                    <SearchIcon></SearchIcon>
                                </InputAdornment>
                            )
                        }}
                        variant={'outlined'}
                    />
                </Box>
                <Box sx={{ flex: '1 1 150px', ml: 1 }}>
                    <TagsSelector
                        value={filterTags?.tags || []}
                        onChange={(tags) => setFilterTags((p) => ({ ...p, tags }))}
                        tagType={TagType.Content}
                    />
                </Box>
                {sitesForSelector.length > 1 && (
                    <Box sx={{ flex: '1 1 150px', ml: 1 }}>
                        <FormControl fullWidth>
                            <InputLabel id='content-site'>Site</InputLabel>
                            <Select
                                labelId='content-site'
                                value={filterSite}
                                label='Site'
                                renderValue={(v) => tenantSites.find((s) => s.ID === v)?.Name || v}
                                onChange={(e) => setFilterSite(e.target.value as string)}
                            >
                                <MenuItem value={''}>All</MenuItem>
                                {sitesForSelector.map((ct) => (
                                    <MenuItem key={ct.ID} value={ct.ID}>
                                        {ct.Name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                )}
                {contentTypes.length > 1 && (
                    <Box sx={{ flex: '1 1 100px', ml: 1 }}>
                        <FormControl fullWidth>
                            <InputLabel id='content-type'>Type</InputLabel>
                            <Select
                                labelId='content-type'
                                value={filterType}
                                label='Type'
                                onChange={(e) => setFilterType(e.target.value as ContentType)}
                            >
                                <MenuItem value={''}>All</MenuItem>
                                {contentTypes.map((ct) => (
                                    <MenuItem key={ct} value={ct}>
                                        {ct}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                )}
                <Box sx={{ flex: '0 1 140px', ml: 1 }}>
                    <FormControlLabel
                        sx={{ width: '100%' }}
                        value='start'
                        control={
                            <Switch
                                checked={query.SiteOnly || false}
                                onChange={(e) =>
                                    setQuery({
                                        ...query,
                                        SiteOnly: e.target.checked,
                                        Status: '',
                                        page: 1
                                    })
                                }
                                color='secondary'
                            />
                        }
                        label='Site Only'
                        labelPlacement='start'
                    />
                </Box>
            </Box>

            <Box sx={{ display: 'flex', px: 1, alignItems: 'top', justifyContent: 'space-between', marginBottom: 3 }}>
                <Box></Box>
                <Box sx={{ flex: '0 0 auto', ml: 1 }}>
                    <Button
                        onClick={() => {
                            setQuery(defaultQuery)
                            setFilterType('')
                            setFilterSite(defaultSite)
                            setFilterTags({ tags: [], page: 0 })
                        }}
                    >
                        Reset Filters
                    </Button>
                </Box>
            </Box>

            {results.isLoading && <div>Loading...</div>}
            {results.error && <div>Error: {guessErrorMessage(results.error)}</div>}
            {results.data && (
                <DataGridBase
                    // rowHeight={100}
                    columns={columns}
                    state={results.data}
                    setQuery={setQuery}
                />
            )}
        </div>
    )
}
