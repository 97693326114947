import React, { useEffect, useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { SetState } from '../../../pkgs/departments/department.service'
import { is } from '../../../helpers'
import { Site } from '../../../pkgs/auth/types'
import { useTenantSites } from '../../../pkgs/auth/atoms'
import { SiteType } from '../../constants'

export const DISTRICT_WIDE_VALUE = 'ALL'

export interface SingleSiteSelectProps {
    initialValue: string
    onChange: SetState
    // Passed into a filter function
    // Returning TRUE means it is included in the Select options
    // Returning FALSE rejects the possible option.
    // filterOptions?: (v: any[]) => boolean,
    siteType: 'all' | 'department' | 'site'
    allSitesOption?: boolean // siteID value returned is "ALL"
    variant?: 'standard' | 'outlined' | 'filled'
    required?: boolean
    disabled?: boolean
}

export function SingleSiteSelect({
    initialValue,
    onChange,
    siteType = 'all',
    allSitesOption,
    variant = 'outlined',
    required,
    disabled
}: SingleSiteSelectProps) {
    const [value, setValue] = useState<string>(initialValue || '')
    const tenantSites = useTenantSites()
    const validSiteID = tenantSites.some((site) => site.ID === initialValue)

    const [sites, setSites] = useState<Site[]>([])
    // const [sites, setSites] = useState<Record<string, any>[]>([])
    // 1) Title as a reference
    const [ref, setRef] = useState<Record<string, string>>({})
    // 2) Object as a reference
    // const [ref, setRef] = useState<Record<string, Record<any,any>>>({})
    const placeholderLabel = siteType === 'department' ? 'Department' : 'Site'

    useEffect(() => {
        // console.log({
        //     sites, initialValue, value,
        // })
        if (!tenantSites?.length) {
            return
        }
        switch (siteType) {
            case 'all':
                setSites(tenantSites)
                break
            case 'department':
                setSites(tenantSites.filter((site) => site.Type === SiteType.Department))
                break
            case 'site':
                setSites(tenantSites.filter((site) => site.Type !== SiteType.Department))
                break
        }
        if (typeof initialValue !== 'string') {
            if (value !== '') {
                setValue('')
            }
            return
        }
        if (initialValue === '') {
            setValue(initialValue)
        } else if (initialValue) {
            if (validSiteID) {
                setValue(initialValue)
            } else {
                console.log(`SiteID no longer exists: ${initialValue}`)
            }
        }
    }, [initialValue, tenantSites])

    useEffect(() => {
        let empty: Record<string, string> = { '': '--- Select a Site ---' }

        if (allSitesOption) {
            empty = { ...empty, [DISTRICT_WIDE_VALUE]: 'District-Wide' }
        }

        setRef(
            sites?.reduce((accumulator, iter) => {
                // 1) Name as a reference
                accumulator[iter?.ID] = iter?.Name || ''
                // 2) Object as a reference
                // accumulator[iter?.id] = iter
                return accumulator
            }, empty) || empty
        )
    }, [sites])

    return (
        <FormControl variant={variant} required={required} sx={{ width: '100%' }} disabled={Boolean(disabled)}>
            <InputLabel>{placeholderLabel}</InputLabel>
            <Select
                data-testid='single-site-select'
                name='single-site-select'
                disabled={Boolean(disabled)}
                placeholder={placeholderLabel}
                label={placeholderLabel}
                value={value}
                renderValue={(selected: string) => {
                    // console.log({ ref, selected, at: ref?.[selected] })
                    // 1) Title as a reference
                    return ref?.[selected] || ''
                    // 2) Object as a reference
                    // return ref?.[selected]?.name || ""
                }}
                onChange={(evt) => {
                    const value = evt?.target?.value || ''
                    if (is.func(onChange)) {
                        onChange(value)
                    }
                    setValue(value)
                }}
            >
                {ref &&
                    Object.keys(ref).map((key) => {
                        const name = ref?.[key] || ''
                        return (
                            <MenuItem
                                key={key + name}
                                data-testid={`single-site-select-option-${key}`}
                                value={key}
                                sx={{ fontWeight: key == DISTRICT_WIDE_VALUE ? '500' : undefined }}
                            >
                                {name}
                            </MenuItem>
                        )
                    })}
            </Select>
        </FormControl>
    )
}
