import { useState } from 'react'
import { DialogFormAction } from '../../../common/components'
import ColorPicker, { ColorPickerProps } from './ColorPicker'

interface ColorPickerDialogProps extends ColorPickerProps {
    isOpen: boolean
    onClose: () => void
}

function ColorPickerDialog({ color, onChange, isOpen, onClose }: ColorPickerDialogProps) {
    const [newColor, setNewColor] = useState(color)

    return (
        <DialogFormAction
            title={'Set background colour'}
            buttonAgreeLabel={'Confirm'}
            buttonDisagreeLabel={'Close'}
            open={isOpen}
            handleDisagree={onClose}
            handleClose={onClose}
            handleAgree={() => {
                onChange?.(newColor)
                onClose()
            }}
            item={
                <>
                    <ColorPicker color={color} onChange={(color) => setNewColor(color)} />
                </>
            }
            text={undefined}
            alternate={undefined}
            alternateAction={undefined}
            fullWidth={undefined}
            headerComponent={undefined}
        />
    )
}

export default ColorPickerDialog
