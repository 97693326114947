import { useStateWithStorage } from '../../../common/storage.service'
import React from 'react'
import { useDebounce } from '../../../common/useDebounce'
import { Button, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, Switch } from '@mui/material'
import { SingleSiteSelect } from '../../../common/components/selectors/SingleSiteSelect'
import { GridColDef } from '@mui/x-data-grid'
import { CellLine, CellWrapper, IDToNameCell, MenuLightCell, TwoLinesCell } from '../../grid/cells/GridCells'
import { guessErrorMessage } from '../../../helpers/guessErrorMessage'
import { AddButton } from '../../../common/components'
import CreateIcon from '@mui/icons-material/Create'
import { CustomMenuItem } from '../../../common/components/custom-context-menu/CustomMenu'
import axios from 'axios'
import { notify } from '../../../helpers'
import SearchBar from '../../../common/components/SearchBar'
import PageContainerWithHeader from '../../../common/components/PageContainerWithHeader'
import { useAppNavigation } from '../../../app/useAppNavigation'
import { Group } from '../types'
import { defaultPageQuery } from '../../../common/react-query'
import { GroupsQuery, useGroups } from '../queries'
import { GroupAPI } from '../../../common/constants'
import RoleSelect from '../roles/RoleSelect'
import { DataGridBase } from '../../grid/DataGridBase'

const defaultQuery: GroupsQuery = {
    ...defaultPageQuery,
    Active: true,
    Search: '',
    QSiteID: null,
    RoleID: null,
    Type: ''
}

export const SecurityGroupsGrid = () => {
    const [query, setQuery] = useStateWithStorage<GroupsQuery>('security-groups-grid-query', defaultQuery)
    const debouncedQuery = useDebounce(query, 300)
    const { navigateTo } = useAppNavigation()
    const results = useGroups(debouncedQuery)

    const handleActiveChange = (accountID: string, currentState: boolean) => {
        if (currentState) {
            axios
                .delete(`${GroupAPI}/${accountID}`)
                .then(() => results.refetch())
                .catch((e) => notify(guessErrorMessage(e)))
        } else {
            axios
                .patch(`${GroupAPI}/${accountID}/restore`)
                .then(() => results.refetch())
                .catch((e) => notify(guessErrorMessage(e)))
        }
    }

    const menuItems = (group: Group) => {
        return (onClose: () => void) => {
            return [
                <CustomMenuItem
                    key={'0'}
                    text={'Edit'}
                    onClick={() => {
                        navigateTo(`/user-management/security-groups/edit/${group?.ID}`)
                    }}
                >
                    <CreateIcon />
                </CustomMenuItem>,

                <MenuItem
                    key={'3'}
                    onClick={() => {
                        onClose()
                        if (
                            !window.confirm(
                                `Are you sure you want to delete the Group: ${group.Name} (id: ${group.ID})?`
                            )
                        )
                            return

                        handleActiveChange(group.ID, group.Active)
                    }}
                >
                    <ListItemIcon>
                        <Switch checked={group.Active} />
                    </ListItemIcon>
                    <ListItemText>{group.Active ? 'Deactivate' : 'Activate'}</ListItemText>
                </MenuItem>
            ]
        }
    }

    const columns: GridColDef[] = [
        {
            field: 'Name',
            headerName: 'Name',
            flex: 2,
            filterable: false,
            sortable: true,
            renderCell: (params) => <TwoLinesCell l1={`${params.row.Name}`} l2={params.row.ID} />
        },
        {
            field: 'SiteID',
            headerName: 'Site',
            width: 260,
            filterable: false,
            sortable: false,
            renderCell: (params) => <IDToNameCell tableName={'site'} ID={params.row.SiteID} />
        },
        {
            field: 'RoleID',
            headerName: 'Role',
            width: 260,
            filterable: false,
            sortable: false,
            renderCell: (params) => <IDToNameCell tableName={'role'} ID={params.row.RoleID} />
        },
        {
            field: 'Type',
            headerName: 'Type',
            width: 100,
            filterable: false,
            sortable: true,
            renderCell: (params) => (
                <CellWrapper>
                    <CellLine>{params.row.Type}</CellLine>
                </CellWrapper>
            )
        },
        {
            field: 'Description',
            headerName: 'Description',
            flex: 2,
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                <CellWrapper>
                    <CellLine>{params.row.Description}</CellLine>
                </CellWrapper>
            )
        },
        {
            field: 'Menu',
            headerName: '...',
            width: 80,
            sortable: false,
            renderCell: (params) => <MenuLightCell itemsFactory={menuItems(params.row)} />
        }
    ]

    return (
        <PageContainerWithHeader
            title='Security Groups'
            topRightElement={
                <AddButton title={`add group`} func={() => navigateTo(`/user-management/security-groups/create`)} />
            }
        >
            <div className={'row'}>
                <div className={'col-xs-3'}>
                    <SearchBar
                        value={query.Search || ''}
                        onChange={(val) => setQuery((prev) => ({ ...prev, Search: val || '' }))}
                    />
                </div>
                <div className={'col-xs-3'}>
                    <SingleSiteSelect
                        initialValue={query.QSiteID || ''}
                        onChange={(site) => setQuery((prev) => ({ ...prev, QSiteID: site || null }))}
                        siteType={'all'}
                    />
                </div>
                <div className={'col-xs-2'}>
                    <RoleSelect
                        initialValue={query.RoleID || ''}
                        onChange={(value: string) => setQuery((prev) => ({ ...prev, RoleID: value || null }))}
                        variant={'outlined'}
                    />
                </div>
                <div className={'col-md-2'}>
                    <FormControl variant='outlined' sx={{ width: '100%' }}>
                        <InputLabel>State</InputLabel>
                        <Select
                            label={'State'}
                            id='demo-simple-select'
                            value={query.Active === true ? 'Active' : query.Active === false ? 'Deactivated' : 'Any'}
                            onChange={(e) =>
                                setQuery({
                                    ...query,
                                    Active:
                                        e.target.value === 'Active'
                                            ? true
                                            : e.target.value === 'Deactivated'
                                              ? false
                                              : null
                                })
                            }
                        >
                            <MenuItem value={'Any'}>Any</MenuItem>
                            <MenuItem value={'Active'}>Active</MenuItem>
                            <MenuItem value={'Deactivated'}>Deactivated</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className={'col-md-2'}>
                    <FormControl variant='outlined' sx={{ width: '100%' }}>
                        <InputLabel>Type</InputLabel>
                        <Select
                            label={'Type'}
                            id='demo-simple-select'
                            defaultValue={'Any'}
                            value={!query.Type ? 'Any' : query.Type === 'external' ? 'External' : 'Manual'}
                            onChange={(e) =>
                                setQuery({
                                    ...query,
                                    Type:
                                        e.target.value === 'Any'
                                            ? ''
                                            : e.target.value === 'External'
                                              ? 'external'
                                              : 'manual'
                                })
                            }
                        >
                            <MenuItem value={'Any'}>Any</MenuItem>
                            <MenuItem value={'External'}>External</MenuItem>
                            <MenuItem value={'Manual'}>Manual</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className={'col-xs-2'} style={{ alignSelf: 'center' }}>
                    <Button style={{ marginTop: '0.25rem' }} onClick={() => setQuery(defaultQuery)} color={'primary'}>
                        Reset Filters
                    </Button>
                </div>
            </div>

            {results.isLoading && <div>Loading...</div>}
            {Boolean(results.error) && <div>Error:{guessErrorMessage(results.error)}</div>}
            {results.data && (
                <DataGridBase
                    // rowHeight={100}
                    columns={columns}
                    state={results.data}
                    setQuery={setQuery}
                    getRowClassName={(params) => (params.row.Active ? 'active' : 'inactive')}
                />
            )}
        </PageContainerWithHeader>
    )
}
