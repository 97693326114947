import { Checkbox, Divider, FormControlLabel, Grid, Typography } from '@mui/material'

import { patternMatch } from '../../auth/permissions/patternMatch'
import { addScope, defaultScopes, removeScope, Scope } from '../../auth/scopes'
import { ROLE_TYPE } from '../types'
import { useMemo } from 'react'
import { isTenantWideEntityScope } from '../../auth/entityScope'

interface ScopeRowProps {
    scope: Scope
    scopes: string[]
    scopeOnChangeHandler: (newScope: string) => void
}

function ScopeRow({ scope, scopes, scopeOnChangeHandler }: ScopeRowProps) {
    function renderScopeSection(scope: Scope) {
        const matches = scopes?.filter((s) => patternMatch(s, scope.pattern))
        const disabled = matches.length > 0 && matches.some((s) => s !== scope.pattern)

        return (
            <div key={scope.pattern} className='scope'>
                <div className='scope__header'>
                    <FormControlLabel
                        label={
                            <Typography
                                sx={{ fontWeight: scope.children || /^[A-Z]/.test(scope.label) ? 'bold' : 'normal' }}
                            >
                                {scope.label.replace(' Editor', '')}
                            </Typography>
                        }
                        control={
                            <Checkbox
                                data-testid={`scope-${scope.pattern.replace(/([.*/])/g, '_')}`}
                                size={scope.children ? 'medium' : 'small'}
                                checked={matches.length > 0}
                                disabled={disabled}
                                onChange={() => scopeOnChangeHandler(scope.pattern)}
                            />
                        }
                    />
                </div>
                <div style={{ display: 'flex', marginLeft: '30px', overflow: 'auto' }}>
                    {scope.children?.map((child) => renderScopeSection(child))}
                </div>
            </div>
        )
    }

    return renderScopeSection(scope)
}

interface ScopesEditorProps {
    scopes: string[]
    onChange: (scopes: string[]) => void
    roleType?: ROLE_TYPE
}

function ScopesEditor({ scopes, onChange, roleType }: ScopesEditorProps) {
    function handleScopeOnChange(targetScope: string) {
        const updatedScopes = scopes.includes(targetScope)
            ? removeScope(scopes, targetScope)
            : addScope(scopes, targetScope)

        onChange(updatedScopes)
    }

    const filteredScopes = useMemo(() => {
        if (roleType == ROLE_TYPE.SITE) {
            return defaultScopes.filter((scope) => !isTenantWideEntityScope(scope.pattern))
        }

        return defaultScopes
    }, [roleType])

    return (
        <Grid container paddingRight={'30px'}>
            {filteredScopes.map((scope) => (
                <Grid key={scope.label} item xs={6} paddingLeft={'30px'}>
                    <ScopeRow scope={scope} scopes={scopes} scopeOnChangeHandler={handleScopeOnChange} />
                    <Divider sx={{ paddingBottom: '20px', marginBottom: '20px' }} />
                </Grid>
            ))}
        </Grid>
    )
}

export default ScopesEditor
