import { DiffEditor } from '@monaco-editor/react'

type DiffViewerProps = {
    original: any
    modified: any
}
export const DiffViewer = ({ original, modified }: DiffViewerProps) => {
    const originalString = typeof original === 'string' ? original : JSON.stringify(original, null, 4)
    const modifiedString = typeof modified === 'string' ? modified : JSON.stringify(modified, null, 4)
    const language = getLanguage(original) || getLanguage(modified) || 'plaintext'

    return (
        <DiffEditor
            language={language}
            original={originalString}
            modified={modifiedString}
            options={{
                automaticLayout: true,
                readOnly: true
            }}
            height={'500px'}
            width={'calc(100% - 30px)'}
        />
    )
}

function getLanguage(value: any): string | undefined {
    if (typeof value === 'string') {
        if (value.includes('</')) return 'html'
        return 'plaintext'
    }

    return !!value ? 'json' : undefined
}
