import axios from 'axios'
import { DefaultMax, DefaultPage, DepartmentAPI, SiteAPI } from '../../../common/constants'
import { guessErrorMessage } from '../../../helpers/guessErrorMessage'

/**
 * @param {string}              selectedSite        - UUID of the SelectedSite, can be obtained via selectedSiteContext
 * @param {string | number}     page                - page
 * @param {string | number}     limit               - limit
 * @param {boolean}             includePermissions  - include Permissions of the user for that site
 * @param {string}              type  - site.Type
 * @return {Promise<any>}
 * */
const get = async (selectedSite, page, limit, includePermissions, type) => {
    const config = {
        params: {
            siteId: selectedSite,
            page: page ? page : DefaultPage,
            limit: limit ? limit : DefaultMax,
            includePermissions: Boolean(includePermissions)
        }
    }
    if (typeof type === 'string' && type.length > 0) {
        config.params['type'] = type
    }
    const response = await axios.get(SiteAPI, config)
    return response.data as any
}
/**
 * @param {boolean}        includePermissions  - include Permissions of the user for that site
 * @return {Promise<any>}
 */
const getAll = async (includePermissions, abortController) => {
    const config = {
        params: {
            page: DefaultPage,
            limit: DefaultMax,
            includePermissions: Boolean(includePermissions)
        }
    }
    if (abortController?.signal != null) {
        config['signal'] = abortController.signal
    }
    const response = await axios.get(SiteAPI, config)
    return response.data as any
}
/**
 * @param {string}        id  - uuid of the requested site
 * @return {Promise<any>}
 */
const getCurrentSite = async () => {
    try {
        return (await axios.get(`${SiteAPI}/current`)).data as any
    } catch (e) {
        return Promise.reject({ message: guessErrorMessage(e) })
    }
}
/**
 * @param {string}        id  - uuid of the requested site
 * @return {Promise<any>}
 */
const getById = async (id) => {
    try {
        return (await axios.get(`${SiteAPI}/${id}`)).data as any
    } catch (e) {
        return Promise.reject({ message: guessErrorMessage(e) })
    }
}
/**
 * @param {Object}        siteModel  - Site Object or Class
 * @return {Promise<any>}
 */
const upsertSite = async (siteModel) => {
    try {
        return (await axios.put(DepartmentAPI, siteModel)).data as any
    } catch (e) {
        return Promise.reject(e)
    }
}

const getTimezone = async () => {
    try {
        const { data } = await axios.get(`${SiteAPI}/timezone`)
        return (data as any).timezone
    } catch (e) {
        console.error(e)
        return Promise.reject(e)
    }
}

export const siteService = {
    get,
    getAll,
    getCurrentSite,
    getById,
    upsert: upsertSite,
    getTimezone
}
