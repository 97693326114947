import React, { useEffect, useState } from 'react'
import { Topic, TopicForEditor, topics, Topics, TopicsQuery, TopicType } from './types'
import axios from 'axios'
import { GridColDef } from '@mui/x-data-grid'
import { ActiveCell } from './ActiveCell'
import { httpGet, httpPatch, httpPost } from '../../../common/client'
import { notify } from '../../../helpers'
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField
} from '@mui/material'
import { RelaySelector } from '../relays/RelaySelector'
import { SiteSelectorForContent } from '../../../common/components/selectors/SiteSelectorForContent'
import EditIcon from '@mui/icons-material/Edit'
import { z } from 'zod'
import { guessErrorMessage } from '../../../helpers/guessErrorMessage'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import { useStateWithStorage } from '../../../common/storage.service'
import { EntityScopeEnum } from '../../auth/entityScope'
import { useCurrentSiteID } from '../../auth/atoms'
import PageContainerWithHeader from '../../../common/components/PageContainerWithHeader'
import SearchBar from '../../../common/components/SearchBar'
import { AdminCell, IDToNameCell, TwoLinesCell } from '../../grid/cells/GridCells'
import { TopicsAPI } from '../../../common/constants'
import { AddButton } from '../../../common/components'
import { DataGridBase } from '../../grid/DataGridBase'
import { BusRouteSelector } from '../../transportation/BusRouteSelector'
import { defaultPageQuery } from '../../../common/react-query'
import { WhatsNew } from '../../../common/components/WhatsNew'

export const TopicsGrid = () => {
    const [query, setQuery] = useStateWithStorage<TopicsQuery>('topics-grid-query', { ...defaultPageQuery })
    const [gridState, setGridState] = useState<Topics>({
        TotalRecords: 0,
        TotalPages: 0,
        Offset: 0,
        PageSize: 10,
        Page: 1,
        Rows: []
    })
    const [open, setOpen] = useState(false)
    const [currentTopic, setCurrentTopic] = useState<TopicForEditor | undefined>(undefined)

    const local = (id: string, active: boolean) => {
        let updated: Topic = {} as Topic
        const newRows = gridState.Rows.map((row) => {
            if (row.ID === id) {
                row.Active = active
                updated = row
            }
            return { ...row }
        })
        setGridState((prevState) => ({ ...prevState, Rows: [...newRows] }))
        return updated
    }
    const setActive = async (id: string, active: boolean) => {
        let updated = local(id, active)
        try {
            await axios.patch(`${TopicsAPI}/${id}`, { ...updated, Active: active })
        } catch (e) {
            local(id, !active)
        }
    }

    const getTitle = (topic: TopicForEditor) => {
        if (topic.TopicType === 'bus_alert') {
            return `Bus Alert`
        } else if (topic.TopicType === 'alert') {
            return `Alert`
        } else {
            return topic.Title || 'Newsletter'
        }
    }

    const columns: GridColDef[] = [
        {
            field: 'TopicType',
            headerName: 'Type/ID',
            flex: 1,
            sortable: false,
            renderCell: (params) => <TwoLinesCell l1={params.row.TopicType} l2={params.row.ID} />
        },
        {
            field: 'Title',
            headerName: 'Title',
            flex: 4,
            sortable: false,
            renderCell: (params) => <TwoLinesCell l1={getTitle(params.row)} l2={params.row.Description} />
        },
        {
            field: 'SiteID',
            headerName: 'SiteID',
            flex: 1,
            sortable: false,
            renderCell: (params) => <IDToNameCell tableName={'site'} ID={params.row.SiteID} />
        },
        {
            field: 'BusRouteID',
            headerName: 'BusRouteID',
            flex: 1,
            sortable: false,
            renderCell: (params) => <IDToNameCell tableName={'bus_route'} ID={params.row.BusRouteID} />
        },
        {
            field: 'Active',
            headerName: 'Active',
            flex: 1,
            sortable: false,
            renderCell: (params) => <ActiveCell setActive={setActive} {...params.row} />
        },
        {
            field: 'Edit',
            headerName: 'Edit',
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <Button
                    onClick={() => {
                        console.log({ ...params.row })
                        setCurrentTopic({ ...params.row })
                        setOpen(true)
                    }}
                >
                    <EditIcon />
                </Button>
            )
        },
        {
            field: 'RelayID',
            headerName: 'RelayID',
            flex: 1,
            sortable: false,
            filterable: false,
            renderCell: (params) => <IDToNameCell tableName={'relay'} ID={params.row.RelayID} />
        },
        {
            field: 'ScheduleID',
            headerName: 'ScheduleID',
            flex: 1,
            sortable: false,
            filterable: false,
            renderCell: (params) => <IDToNameCell tableName={'schedule'} ID={params.row.ScheduleID} />
        },

        ...trackCells
    ]

    const load = () => {
        httpGet(`${TopicsAPI}`, query, topics)
            .then((res) => {
                setGridState(res)
            })
            .catch((err) => {
                console.log(err)
                notify(guessErrorMessage(err))
            })
    }

    const generateTopics = () => {
        axios
            .put(`${TopicsAPI}`)
            .then((res) => {
                notify(`Success. ${res.data}`, 'info')
                load()
            })
            .catch((err) => {
                console.log(err)
                notify(guessErrorMessage(err))
            })
    }

    useEffect(() => {
        load()
    }, [query])

    return (
        <PageContainerWithHeader
            title='Topics'
            titleSlot={<WhatsNew
                link={'https://cdn.cmdesign.imagineeverything.com/notifications-1718912661937?hash=doL25vdGlmaWNhdGlvbnMtMTcxODkxMjY2MTkzNw=='} />}
            topRightElement={
                <div>
                    <Button
                        variant={'contained'}
                        onClick={generateTopics}
                        size={'medium'}
                        startIcon={<AutoFixHighIcon />}
                        sx={{ mr: 2 }}
                    >
                        Generate Topics
                    </Button>

                    {false && (
                        <AddButton
                            title={'Add Topic'}
                            func={() => {
                                setCurrentTopic(undefined)
                                setOpen(true)
                            }}
                        />
                    )}
                </div>
            }
        >
            <div className={'row'} style={{ marginBottom: '1rem' }}>
                <div className={'col-md-6'}>
                    <SearchBar
                        value={query.Search || ''}
                        onChange={(val) => setQuery((prev) => ({ ...prev, Search: val }))}
                    />
                </div>
                <div className={'col-md-3'}>
                    <FormControl fullWidth>
                        <InputLabel id='demo-simple-select-label'>Topic Type</InputLabel>
                        <Select
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            value={query.TopicType || ''}
                            label='Topic Type'
                            onChange={(v) => setQuery({ ...query, TopicType: v.target.value as string })}
                        >
                            <MenuItem value={''}>All</MenuItem>
                            <MenuItem value={'alert'}>Alert</MenuItem>
                            <MenuItem value={'bus_alert'}>Bus Alert</MenuItem>
                            <MenuItem value={'custom'}>Custom</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className={'col-md-3'}>
                    <FormControl fullWidth>
                        <InputLabel>State</InputLabel>
                        <Select
                            id='demo-simple-select'
                            value={query.Active === true ? 'Active' : query.Active === false ? 'Disabled' : ''}
                            label='State'
                            onChange={(v) =>
                                setQuery({
                                    ...query,
                                    Active:
                                        v.target.value === 'Active'
                                            ? true
                                            : v.target.value === 'Disabled'
                                                ? false
                                                : undefined
                                })
                            }
                        >
                            <MenuItem selected={query.Active == undefined} value={''}>
                                All
                            </MenuItem>
                            <MenuItem selected={query.Active === true} value={'Active'}>
                                Active
                            </MenuItem>
                            <MenuItem selected={query.Active === false} value={'Disabled'}>
                                Disabled
                            </MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
            <DataGridBase
                columns={[...columns]}
                state={gridState}
                setQuery={setQuery}
                getRowClassName={(params) => (params.row.Active ? 'active' : 'inactive')}
            />

            {open && (
                <TopicEditor
                    open={open}
                    topic={currentTopic}
                    onCancel={() => {
                        setOpen(false)
                        setCurrentTopic(undefined)
                    }}
                    onChange={() => {
                        setOpen(false)
                        setCurrentTopic(undefined)
                        load()
                    }}
                />
            )}
        </PageContainerWithHeader>
    )
}

export const trackCells: GridColDef[] = [
    { field: 'CreatedAt', headerName: 'CreatedAt', width: 200, sortable: true },
    {
        field: 'CreatedBy',
        headerName: 'CreatedBy',
        width: 200,
        sortable: false,
        renderCell: (params) => <AdminCell ID={params.row.CreatedBy} />
    },
    { field: 'UpdatedAt', headerName: 'UpdatedAt', width: 200, sortable: true },
    {
        field: 'UpdatedBy',
        headerName: 'UpdatedBy',
        width: 200,
        sortable: false,
        renderCell: (params) => <AdminCell ID={params.row.UpdatedBy} />
    }
]

export const TopicEditor = ({
                                open,
                                topic,
                                onChange = () => {
                                },
                                onCancel = () => {
                                }
                            }: {
    open: boolean
    topic?: TopicForEditor
    onChange?: (topic: TopicForEditor) => void
    onCancel?: () => void
}) => {
    const currentSiteID = useCurrentSiteID()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const defaultTopic: TopicForEditor = {
        ID: null,
        TopicType: 'custom',
        SiteID: currentSiteID,
        Active: true,
        DeliveryType: 'email'
    } as TopicForEditor

    const [state, setState] = useState(topic || defaultTopic)
    useEffect(() => {
        setState(topic || defaultTopic)
    }, [topic])
    useEffect(() => {
        setError('')
    }, [open])

    const handleSave = () => {
        setError('')
        setLoading(true)
        if (state.ID) {
            httpPatch(`${TopicsAPI}/${state.ID}`, state)
                .then((res) => {
                    onChange(state)
                    notify('Topic Updated', 'info')
                    setLoading(false)
                })
                .catch((err) => {
                    setLoading(false)
                    console.log(err)
                    setError(err['ErrorMessage'] || 'Can\'t update Topic.')
                })
        } else {
            httpPost(`${TopicsAPI}`, state, z.string())
                .then((res) => {
                    state.ID = res
                    setState((prev) => ({ ...state }))
                    onChange(state)
                    setLoading(false)
                    notify('Topic Created', 'info')
                })
                .catch((err) => {
                    setLoading(false)
                    console.log('ERROR: ', JSON.stringify(err))
                    setError(err['ErrorMessage'] || 'Cant update Topic.')
                })
        }
    }

    return (
        <Dialog open={open} onClose={onCancel} fullWidth={true} maxWidth={'md'}>
            <DialogTitle>{state.ID ? 'Edit Topic' : 'Add Topic'}</DialogTitle>
            <DialogContent>
                <Box
                    noValidate
                    component='form'
                    style={{ gap: '1.5rem' }}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        m: 0,
                        p: 0,
                        width: 'auto'
                    }}
                >
                    {error && (
                        <Alert style={{ marginTop: '0.5rem' }} severity='error'>
                            {error}
                        </Alert>
                    )}

                    <FormControl fullWidth style={{ marginTop: '0.5rem' }}>
                        <InputLabel id='demo-simple-select-label'>Topic Type</InputLabel>
                        <Select
                            disabled={!!state.ID}
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            value={state.TopicType || ''}
                            label='Topic Type'
                            onChange={(v) => {
                                const newType = v.target.value as TopicType
                                const siteID = newType === 'bus_alert' ? null : state.SiteID || currentSiteID
                                const busRouteID = newType === 'bus_alert' ? state.BusRouteID : null
                                const title = newType === 'custom' ? state.Title : null
                                const description = newType === 'custom' ? state.Description : null
                                setState({
                                    ...state,
                                    TopicType: newType,
                                    SiteID: siteID,
                                    BusRouteID: busRouteID,
                                    Title: title,
                                    Description: description
                                })
                            }}
                        >
                            <MenuItem value={'custom'}>custom</MenuItem>
                            <MenuItem value={'alert'}>alert</MenuItem>
                            <MenuItem value={'bus_alert'}>bus_alert</MenuItem>
                        </Select>
                    </FormControl>
                    {state.TopicType !== 'bus_alert' && state.SiteID && (
                        <SiteSelectorForContent
                            Selected={[state.SiteID]}
                            ContentType={EntityScopeEnum.NotificationTopic}
                            Label={'Site'}
                            Multi={false}
                            OnChange={(v) => {
                                // TODO: create SiteSelectorForTopic
                                if (Array.isArray(v) && v.length === 1 && v[0] !== state.SiteID) {
                                    setState((prev) => ({ ...prev, SiteID: v[0] }))
                                } else if (typeof v === 'string' && v !== state.SiteID) {
                                    setState((prev) => ({ ...prev, SiteID: v }))
                                }
                            }}
                        />
                    )}
                    {state.TopicType === 'bus_alert' && (
                        <BusRouteSelector
                            selected={state.BusRouteID}
                            onChange={(v) => {
                                setState((prev) => ({ ...prev, BusRouteID: v }))
                            }}
                        />
                    )}

                    {state.TopicType === 'custom' && (
                        <FormControl fullWidth>
                            <TextField
                                label={'Title'}
                                inputProps={{ maxLength: 160 }}
                                value={state.Title || ''}
                                onChange={(v) =>
                                    setState((prev) => ({
                                        ...prev,
                                        Title: v.target.value.trim().length === 0 ? null : v.target.value
                                    }))
                                }
                            />
                        </FormControl>
                    )}

                    {state.TopicType === 'custom' && (
                        <FormControl fullWidth>
                            <TextField
                                label={'Description'}
                                inputProps={{ maxLength: 160 }}
                                value={state.Description || ''}
                                onChange={(v) =>
                                    setState((prev) => ({
                                        ...prev,
                                        Description: v.target.value.trim().length === 0 ? null : v.target.value
                                    }))
                                }
                            />
                        </FormControl>
                    )}

                    <RelaySelector
                        selected={state.RelayID}
                        onChange={(v) => setState((prev) => ({ ...prev, RelayID: v }))}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={state.Active}
                                onChange={(e) => {
                                    setState((prev) => ({ ...prev, Active: e.target.checked }))
                                }}
                            />
                        }
                        label='Active'
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        setState(defaultTopic)
                        onCancel()
                    }}
                >
                    Cancel
                </Button>
                <Button disabled={loading} onClick={() => handleSave()} variant='contained'>
                    {state?.ID ? 'Save' : 'Create'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
