import LocationOffIcon from '@mui/icons-material/LocationOff'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import React from 'react'
import { contentService } from '../../../pkgs/content/content.service'
import { BaseContextMenuItemProps } from './types'
import { is, notify } from '../../../helpers'
import { CustomMenuItem } from './CustomMenu'
import { guessErrorMessage } from '../../../helpers/guessErrorMessage'

export const PinnedToggleCustomMenuItem = ({
    value,
    onChange,
    disabled
}: BaseContextMenuItemProps & {
    value: Record<string, any>
}) => {
    // TODO => Permissions => Patch requires Shareable interface

    const handlePriorityToggle = async () => {
        try {
            let newPriority: number | null
            if (is.number(value?.settings?.priority)) {
                newPriority = null
            } else {
                const { results = [] } = (await contentService.getPinnedNews()) as any
                newPriority = results.pop()?.settings?.priority + 1 || 999
            }
            const next = { ...value, settings: { ...value?.settings, priority: newPriority } }
            const response = await contentService.patchContent('', next)
            notify(`Success, ${value?.title} has been ${newPriority === null ? 'un-pinned' : 'pinned'}`, 'info')
            onChange?.(response)
        } catch (err) {
            notify(`Error pinning content: ${guessErrorMessage(err)}`, 'error')
            console.error(err)
        }
    }
    if (!value) {
        return null
    }
    return (
        <CustomMenuItem
            text={is.number(value?.settings?.priority) ? 'Un-pin' : 'Pin'}
            onClick={handlePriorityToggle}
            disabled={Boolean(disabled)}
        >
            {is.number(value?.settings?.priority) ? <LocationOffIcon /> : <LocationOnIcon />}
        </CustomMenuItem>
    )
}
