import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { httpGet } from '../../../common/client'
import { z } from 'zod'
import { guessErrorMessage } from '../../../helpers/guessErrorMessage'
import { useStateWithStorage } from '../../../common/storage.service'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { notify } from '../../../helpers'
import { ManageAccountsAPI, SiteAPI } from '../../../common/constants'

type CreateAccountDTO = {
    username?: string
    domain?: string
    firstName?: string
    lastName?: string
    password?: string
    passwordRepeat?: string
}

export const CreateAccount = ({ onClose }: { onClose: () => void }) => {
    const [state, setState] = useState<CreateAccountDTO>({})
    const [errors, setErrors] = useState<CreateAccountDTO>({})

    const [domain, setDomain] = useStateWithStorage('create-account-domain', '')
    const [email, setEmail] = useState('')
    const domains = useQuery({
        queryKey: ['domains'],
        queryFn: async () => httpGet(`${SiteAPI}/domains`, {}, z.array(z.string()))
    })
    useEffect(() => {
        setState({ ...state, domain })
    }, [domain])
    useEffect(() => {
        setEmail(`${state.username || ''}@${state.domain || ''}`)
    }, [state])

    const handleCreate = () => {
        let e: CreateAccountDTO = {}
        if (!state.username || state.username.includes('@')) {
            e.username = "Username is required and can't contain @"
        }
        if (!state.password || state.password.length < 8) {
            e.password = 'Password is required and must be at least 8 characters'
        }
        if (!state.passwordRepeat || state.passwordRepeat !== state.password) {
            e.passwordRepeat = 'Passwords must match'
        }
        if (!domain) {
            e.domain = 'Domain is required'
        }
        setErrors(e)
        if (Object.keys(e).length) {
            return
        }

        axios
            .post(`${ManageAccountsAPI}`, {
                Email: email,
                FirstName: state.firstName,
                LastName: state.lastName,
                Password: state.password,
                PasswordConfirm: state.passwordRepeat
            })
            .then(() => {
                notify('Account created successfully', 'info')
                onClose()
            })
            .catch((e) => {
                notify(guessErrorMessage(e))
            })
    }

    return (
        <Dialog open={true} onClose={onClose}>
            <DialogTitle>Create an Account</DialogTitle>
            <DialogContent>
                {domains.isError && <Alert severity='error'>{guessErrorMessage(domains.error)}</Alert>}
                {domains.isLoading && <Alert severity='info'>Loading...</Alert>}
                {domains.data && (
                    <>
                        <InputLabel>Email: {email}</InputLabel>
                        <Stack direction='row' spacing={1} sx={{ mt: 2 }}>
                            <FormControl fullWidth>
                                <TextField
                                    label='Username'
                                    data-testid='username'
                                    error={!!errors.username}
                                    helperText={errors.username}
                                    onChange={(e) => setState((p) => ({ ...p, username: e.target.value }))}
                                />
                            </FormControl>
                            <Typography variant='h4'>@</Typography>
                            <FormControl fullWidth sx={{ minWidth: 300 }}>
                                <InputLabel id='domain-select-label'>Domain</InputLabel>
                                <Select
                                    label={'Domain'}
                                    labelId='domain-select-label'
                                    id='domain-select'
                                    value={domain}
                                    onChange={(e) => setDomain(e.target.value as string)}
                                >
                                    {domains.data.map((d) => (
                                        <MenuItem key={d} value={d}>
                                            {d}
                                        </MenuItem>
                                    ))}
                                    error={!!errors.domain} helperText={errors.domain}
                                </Select>
                            </FormControl>
                        </Stack>
                        <Stack direction='row' spacing={2} sx={{ my: 2 }}>
                            <FormControl fullWidth>
                                <TextField
                                    label='First Name'
                                    data-testid='first-name'
                                    onChange={(e) => setState((p) => ({ ...p, firstName: e.target.value }))}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <TextField
                                    data-testid='last-name'
                                    label='Last Name'
                                    onChange={(e) => setState((p) => ({ ...p, lastName: e.target.value }))}
                                />
                            </FormControl>
                        </Stack>
                        <Stack direction='row' spacing={2}>
                            <FormControl fullWidth>
                                <TextField
                                    label='Password'
                                    data-testid='password'
                                    error={!!errors.password}
                                    helperText={errors.password}
                                    onChange={(e) => setState((p) => ({ ...p, password: e.target.value }))}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <TextField
                                    data-testid='password-repeat'
                                    label='Repeat password'
                                    error={!!errors.passwordRepeat}
                                    helperText={errors.passwordRepeat}
                                    onChange={(e) => setState((p) => ({ ...p, passwordRepeat: e.target.value }))}
                                />
                            </FormControl>
                        </Stack>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleCreate}>Create</Button>
            </DialogActions>
        </Dialog>
    )
}
