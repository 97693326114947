export const colours = {
    white: '#fff',
    off_white: '#F0F0F0',
    off_white_but_darker: 'rgb(216, 222, 228)',
    weird_blue: '#325673',
    light_blue: '#2196f3',
    orange: '#f15d43',
    expired: '#b20000',
    warning: '#dba400',
    light_warning: '#fff4d3',
    light_red: '#ffd6d6',
    red: 'red',
    base_blue: '#4c84ff',
    base_blue_hover: '#205ee5',
    background_color: '#f0f0f0', // from App.css
    published: '#0b6615',
    disabled: 'rgb(84, 84, 84)',
    disabled2: 'rgb(179, 179, 179)' // native disabled colour from MUI. Use for textfields
} as const

export type Colour = keyof typeof colours
export type ColourHexValue = (typeof colours)[Colour]

export function colourWithOpacity(colour: ColourHexValue, opacity: number) {
    return `${colour}${Math.floor(opacity * 255)
        .toString(16)
        .padStart(2, '0')}`
}
