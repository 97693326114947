import React from 'react'
import { useAppContext } from '../atoms'
import { RequiresAuth } from './RequiresAuth'

export function RequiresAdmin({ children }: { children: React.ReactElement }) {
    const evaluators = useAppContext()
    return (
        <RequiresAuth>
            {evaluators.isAdmin() ? (
                children
            ) : (
                <div>This view requires administrative privileges. You are not admin</div>
            )}
        </RequiresAuth>
    )
}
