import { useCallback, useEffect, useRef, useState } from 'react'
import { useCompileFragmentById } from '../../content/fragments/queries'
import {
    $createParagraphNode,
    $getNodeByKey,
    $getSelection,
    $isParagraphNode,
    CLICK_COMMAND,
    COMMAND_PRIORITY_LOW,
    KEY_ENTER_COMMAND,
    NodeKey
} from 'lexical'
import { useLexicalNodeSelection } from '@lexical/react/useLexicalNodeSelection'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { mergeRegister } from '@lexical/utils'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { $isContentFragmentNode } from './ContentFragmentNode'
import { $insertParagraphAfterNode, composedPathHasId } from '../helpers/helpers'
import { useContentDetailsQuery } from '../../content/queries'
import { FragmentEditor } from '../../content/fragments/FragmentEditor'
import { colours } from '../../../common/colours'

interface ContentFragmentHtmlProps {
    id: string
    nodeKey?: NodeKey
}

function getContainerId(id, nodeKey) {
    return 'container-id-' + id + '-' + nodeKey
}

function ContentFragmentHtml({ id, nodeKey }: ContentFragmentHtmlProps) {
    const [isSelected, setSelected, clearSelection] = useLexicalNodeSelection(nodeKey || '')
    const [editor] = useLexicalComposerContext()
    const fragmentRef = useRef<null | HTMLDivElement>(null)
    const { data, isLoading } = useCompileFragmentById(id)
    const { data: fragment } = useContentDetailsQuery(id)
    const [fragmentEditorIsOpen, setFragmentEditorIsOpen] = useState(false)

    const onClick = useCallback(
        (event: MouseEvent) => {
            if (nodeKey && id && composedPathHasId(event.composedPath(), getContainerId(id, nodeKey))) {
                if (event.shiftKey) {
                    setSelected(!isSelected)
                } else {
                    clearSelection()
                    setSelected(true)
                }
                return true
            }
            return false
        },
        [isSelected, setSelected, clearSelection]
    )

    const onEnter = useCallback(
        (event: KeyboardEvent) => {
            if (isSelected) {
                event.preventDefault()
                const contentFragmentNode = nodeKey ? $getNodeByKey(nodeKey) : $getSelection()?.getNodes()?.[0]
                const parentNode = contentFragmentNode?.getParent()
                if (contentFragmentNode && $isContentFragmentNode(contentFragmentNode)) {
                    if (parentNode && $isParagraphNode(parentNode)) {
                        $insertParagraphAfterNode(parentNode)
                    } else {
                        $insertParagraphAfterNode(contentFragmentNode)
                    }
                    return true
                }
                return false
            }
            return false
        },
        [isSelected, setSelected, clearSelection]
    )

    useEffect(() => {
        const unregister = mergeRegister(
            editor.registerCommand<MouseEvent>(CLICK_COMMAND, onClick, COMMAND_PRIORITY_LOW),
            editor.registerCommand<KeyboardEvent>(KEY_ENTER_COMMAND, onEnter, COMMAND_PRIORITY_LOW)
        )
        return () => {
            unregister()
        }
    }, [editor, isSelected, setSelected, clearSelection, nodeKey, onClick])

    if (!id || isLoading) {
        return (
            <Box width='100%' justifyContent='left' textAlign='left'>
                <CircularProgress size={24} />
                Loading Content Fragment
            </Box>
        )
    }

    return (
        <Box
            id={getContainerId(id, nodeKey)}
            sx={{
                backgroundColor: colours.off_white_but_darker,
                boxShadow: `0 0 ${isSelected ? 10 : 3}px ${colours.light_blue}`,
                padding: '0px 5px'
            }}
        >
            <Button
                color='info'
                title={(fragment?.Title || '') + `\n ID: ${id}`}
                size='small'
                onClick={() => setFragmentEditorIsOpen(true)}
                sx={{
                    width: '100%',
                    borderBottom: 'solid 1px'
                }}
            >
                <Typography onClick={() => {}} textTransform='none' fontWeight='500' color='black'>
                    {fragment?.Title}
                </Typography>
            </Button>
            {fragmentEditorIsOpen && (
                <FragmentEditor
                    dialogMaxWidth='md'
                    id={id}
                    open={fragmentEditorIsOpen}
                    onClose={() => {
                        setFragmentEditorIsOpen(false)
                    }}
                    warning={true}
                />
            )}
            <div ref={fragmentRef} dangerouslySetInnerHTML={{ __html: data || '' }} />
        </Box>
    )
}

export default ContentFragmentHtml
