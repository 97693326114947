import { Alert } from '@mui/material'
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined'
import { useMemo } from 'react'

export function tryParseURL(link: string, base?: string): URL | null {
    try {
        return new URL(link, base)
    } catch (err) {
        // console.warn(`Error on tryParseURL(${link}) - [${err}]`)
        return null
    }
}

export function WhatsNew({ link, description }: { link: string; description?: string }) {
    const linkTo = useMemo(() => parseLinkTo(), [link])

    function parseLinkTo(): string | null {
        const url = tryParseURL(
            link,
            /*  If it's a relative link, a base can be appended
                If the link is non-relative, the base should be ignored, as it can provide confusing results.
                (e.g) When missing protocol:
                1. new URL(cdn.imagineeverything.com/path/to, https://cdn.imagineeverything.com)
                2. link =  https://cdn.imagineeverything.com/cdn.imagineeverything.com/path/to
            */
            link.startsWith('/') ? 'https://cdn.imagineeverything.com' : undefined
        )
        if (url == null) {
            logErr('Invalid URL: Potentially missing protocol on non-relative link')
            return null
        }
        if (url.pathname.length <= 1) {
            logErr('link missing required /path')
            return null
        }
        return url.toString()
    }

    function logErr(details: string) {
        let err = `Error: WhatsNew failed to parse link: [${link}]`
        if (details) {
            err += ` with error: [${details}]`
        }
        return console.error(err)
    }

    function openLink() {
        if (linkTo === null) {
            return
        }
        window.open(linkTo, '_blank')
    }

    return (
        <>
            {linkTo != null && (
                <Alert
                    sx={{
                        alignItems: 'center',
                        width: 'fit-content',
                        borderRadius: '4rem',
                        margin: '0.25rem 0',
                        padding: '0.15rem 1rem',
                        cursor: 'pointer',
                        '& .MuiAlert-message': {
                            padding: '8px 0 4px 0px'
                        },
                        '&:hover': {
                            backgroundColor: '#dbeef6'
                        }
                    }}
                    onClick={openLink}
                    severity={'info'}
                    icon={<CampaignOutlinedIcon />}
                >
                    {description || 'This area has been recently updated - Click here to see what has changed.'}
                </Alert>
            )}
        </>
    )
}
