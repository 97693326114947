import {styled} from "@mui/system";
import {Tooltip, tooltipClasses, TooltipProps} from "@mui/material";
import React from "react";

export const LightTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip
        placement={'top'}
        slotProps={{
            popper: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -14]
                        }
                    }
                ]
            }
        }}
        {...props}
        classes={{popper: className}}
    />
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        fontSize: 16
    }
}))