// @ts-nocheck
import React, { useContext, useEffect } from 'react'
import { DragSource } from 'react-dnd'
import { Get } from '../../../helpers'
import { emittedEventContext } from '../useEmitter'
import { generateClassNames } from './TreeBase'

export const ExternalNodeType = 'orphan'

// This needs to return an object with a property `node` in it.
// Object rest spread is recommended to avoid side effects of
// referencing the same object in different trees.
const externalNodeSpec = {
    beginDrag: (componentProps) => ({ node: { ...componentProps.node } })
}

// Add props via react-dnd APIs to enable more visual
// customization of your component
// Monitor Documentation: https://react-dnd.github.io/react-dnd/docs/api/drop-target-monitor
const externalNodeCollect = (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    didDrop: monitor.didDrop(),
    getDropResult: monitor.getDropResult(),
    getItem: monitor.getItem(),
    isDragging: monitor.isDragging(),
    monitor,
    getItemType: monitor.getItemType()
})

const ExternalNodeBaseClasses = ' flex-row ml5 mr5 '

export function ExternalNodeElement({ Buttons, node, title, disabled, className, searchState }) {
    let classname = (className || generateClassNames(node, disabled)) + ExternalNodeBaseClasses
    if (disabled) classname += ' Navigation-Disabled '
    if (Get.isPrimary(node)) classname += ' Navigation-Primary '

    if (node.existsInTree) {
        classname += ' Navigation-ExistsInTree '
    } else {
        classname += ' Navigation-MissingFromTree '
    }
    // if (typeof searchState === 'boolean') {
    //     classname += ` Navigation-Searched Navigation-${searchState === true ? "ExistsInTree" : "MissingFromTree"}`
    // }
    return (
        <div className={classname}>
            <div className='rst__moveHandle' style={{ height: '32px' }} draggable={false} />
            <div className='rst__rowContents rst__external-node'>
                <div className='rst__rowLabel'>
                    <span className='rst__rowTitle'>{node?.content?.title || node?.title || title}</span>
                </div>
                {!disabled && <div className='rst__rowToolbar'>{Buttons && (Buttons?.() || <Buttons />)}</div>}
            </div>
        </div>
    )
}

const ExternalNodeDragBase = ({
    connectDragSource,
    didDrop,
    getDropResult,
    getItemType,
    node,
    Buttons,
    className,
    disabled
}) => {
    const [, emitEvent] = useContext(emittedEventContext)

    useEffect(() => {
        if (didDrop && getItemType === ExternalNodeType && getDropResult?.dropEffect === 'move') {
            console.log(getDropResult.node)

            //@ts-ignore
            emitEvent({ message: 'TreeChange', id: getDropResult?.node?.id })
        }
    }, [didDrop])

    return connectDragSource(
        // ExternalNodeElement must be wrapped in div
        //  "Only native element nodes can now be passed to React DnD connectors."
        //  "You can either wrap the component into a <div>, or turn it into a drag source or a drop target itself."
        <div>
            <ExternalNodeElement {...{ node, Buttons, disabled, className }} />
        </div>,
        { dropEffect: 'move', draggable: false }
    )
}

export const ExternalNodeV2 = DragSource(ExternalNodeType, externalNodeSpec, externalNodeCollect)(ExternalNodeDragBase)
