import React, { useContext, useEffect, useState } from 'react'
import { disabledContext } from '../../../common/DisabledContext'
import PageManager from './PageManager'
import { AddButton } from '../../../common/components'
import { useLocation } from 'react-router-dom'
import { useAppContext } from '../../auth/atoms'
import { EntityScopeEnum } from '../../auth/entityScope'
import PageContainerWithHeader from '../../../common/components/PageContainerWithHeader'
import { CreateStructuredContent } from '../editor/CreateStructuredContent'
import './Pages.css'
import { ContentType } from '../types'

const Pages = () => {
    const location = useLocation()
    const evaluators = useAppContext()
    const [, setDisabled] = useContext(disabledContext)
    const [open, setOpen] = useState(false)
    const [locationState, setLocationState] = useState(location.state)
    const [newPage, setNewPage] = useState(false)

    // Enable Site Select
    useEffect(() => {
        //@ts-ignore
        setDisabled(false)
    }, [])

    useEffect(() => {
        if (location.state) {
            setLocationState(location.state)
        }
    }, [location.state])

    const handleClose = () => {
        setOpen(false)
    }
    const handleOpen = () => {
        setOpen(true)
    }

    return (
        <PageContainerWithHeader
            title='Pages'
            topRightElement={
                evaluators.actionForEntityScope(EntityScopeEnum.Page, 'create') ? (
                    <AddButton title='ADD PAGE' func={() => setNewPage(true)} style={{ marginRight: 10 }} />
                ) : null
            }
        >
            <PageManager open={open} locationState={locationState} handleOpen={handleOpen} handleClose={handleClose} />
            {newPage && (
                <CreateStructuredContent
                    availableTypes={[ContentType.Page]}
                    open={newPage}
                    onClose={() => setNewPage(false)}
                />
            )}
        </PageContainerWithHeader>
    )
}

export default Pages
