import { useEffect, useState } from 'react'
import { ContentQueryParams, getDeleteContentMutation, useContentQuery } from '../queries'
import { GridColDef } from '@mui/x-data-grid'
import CodeIcon from '@mui/icons-material/Code'
import { useNavigate } from 'react-router-dom'
import { AddButton, ConfirmAction } from '../../../common/components'
import { Alert, Grid, IconButton } from '@mui/material'
import SearchBar from '../../../common/components/SearchBar'
import { guessErrorMessage } from '../../../helpers/guessErrorMessage'
import { DataGridBase } from '../../grid/DataGridBase'
import { CellLine, CellWrapper, TwoLinesCell } from '../../grid/cells/GridCells'
import { MoreHoriz } from '@mui/icons-material'
import { CustomMenu, CustomMenuItem } from '../../../common/components/custom-context-menu/CustomMenu'
import CreateIcon from '@mui/icons-material/Create'
import DeleteIcon from '@mui/icons-material/Delete'
import { useAppContext, useCurrentSiteID } from '../../auth/atoms'
import { v4 as uuidv4 } from 'uuid'
import GroupIcon from '@mui/icons-material/Group'
import moment from 'moment'
import PageContainerWithHeader from '../../../common/components/PageContainerWithHeader'
import { useStateWithStorage } from '../../../common/storage.service'
import { useAppNavigation } from '../../../app/useAppNavigation'
import { CreateTemplate } from '../templates/CreateTemplate'
import { defaultPageQuery } from '../../../common/react-query'
import { ContentType } from '../types'

export function TemplatesGrid() {
    const currentSiteID = useCurrentSiteID()
    const evaluators = useAppContext()
    const { getPath, navigateTo } = useAppNavigation()

    const [queryParams, setQueryParams] = useStateWithStorage<ContentQueryParams>('templates-grid', {
        ...defaultPageQuery,
        Search: '',
        ContentTypes: [ContentType.Template],
        Sites: [currentSiteID || '']
    })

    const deleteContentMutation = getDeleteContentMutation()

    const { data, isLoading, error, refetch } = useContentQuery(queryParams)

    const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false)
    const [newTemplate, setNewTemplate] = useState<any>({
        id: uuidv4(),
        type: '',
        title: '',
        data: null,
        route: '',
        path: '',
        structure: ''
    })

    useEffect(() => {
        setQueryParams((p) => ({
            ...p,
            Sites: [currentSiteID || '']
        }))
    }, [currentSiteID])

    function handleCreate() {
        navigateTo(`/template/${newTemplate?.id}`, { state: { ...newTemplate, mode: '' } })
    }

    const columns: GridColDef[] = [
        {
            field: 'Title',
            headerName: 'Title',
            flex: 2,
            sortable: true,
            disableColumnMenu: true,
            renderCell: (params) => <TwoLinesCell l1={`${params.row.Title}`} l2={params.row.ID} />
        },
        {
            field: 'Shared',
            headerName: 'Shared',
            // width: 100,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <div>{params?.row?.Sites?.length > 1 ? <GroupIcon className='disabled-icon icon' /> : '-'}</div>
            )
        },
        {
            field: 'Last Modified',
            headerName: 'Last Modified',
            // width: 250,
            sortable: true,
            filterable: true,
            renderCell: (params) => (
                <CellWrapper>
                    <CellLine>{moment(params.row.Updated).format('YYYY-MM-DD HH:mm:ss') || '-'}</CellLine>
                </CellWrapper>
            )
        },
        {
            field: 'Code',
            headerName: 'Code',
            width: 100,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <div>
                    <a href={getPath(`/template-editor/${params?.row.ID}`).toString()} target='_blank' rel='noreferrer'>
                        <CodeIcon className='create-icon icon' />
                    </a>
                </div>
            )
        },
        {
            field: 'Menu',
            headerName: 'Actions',
            headerAlign: 'right',
            align: 'right',
            width: 80,
            disableColumnMenu: true,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                const [actionMenuAnchor, setActionMenuAnchor] = useState<HTMLElement | null>(null)
                const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false)

                return (
                    <CellWrapper style={{ textAlign: 'right' }}>
                        <IconButton
                            size={'small'}
                            aria-haspopup={'true'}
                            onClick={(e) => {
                                setActionMenuAnchor(e.currentTarget)
                            }}
                        >
                            <MoreHoriz />
                        </IconButton>
                        <CustomMenu
                            key={params.row.ID}
                            anchorElement={actionMenuAnchor}
                            onClose={() => setActionMenuAnchor(null)}
                        >
                            <CustomMenuItem
                                text={'Edit'}
                                disabled={!evaluators.action(params?.row, 'update')}
                                onClick={() => navigateTo(`/template-editor/${params?.row.ID}`)}
                            >
                                <CreateIcon />
                            </CustomMenuItem>
                            <CustomMenuItem
                                disabled={!evaluators.action(params?.row, 'delete')}
                                text={'Delete'}
                                onClick={() => {
                                    setConfirmDeleteDialogOpen(true)
                                    setActionMenuAnchor(null)
                                }}
                            >
                                <DeleteIcon />
                            </CustomMenuItem>
                        </CustomMenu>
                        <ConfirmAction
                            open={confirmDeleteDialogOpen}
                            title={`Delete "${params?.row.Title}"`}
                            text={'Are you sure you want to delete this template?'}
                            handleClose={() => setConfirmDeleteDialogOpen(false)}
                            handleDisagree={() => setConfirmDeleteDialogOpen(false)}
                            handleAgree={() =>
                                deleteContentMutation.mutate({
                                    content: params?.row,
                                    _then: () => {
                                        refetch()
                                        setConfirmDeleteDialogOpen(false)
                                    }
                                })
                            }
                        />
                    </CellWrapper>
                )
            }
        }
    ]

    return (
        <PageContainerWithHeader
            title='Templates'
            topRightElement={<AddButton title={`add template`} func={() => setCreateDialogIsOpen(true)} />}
        >
            <Grid container spacing={2}>
                <Grid item md={6}>
                    <SearchBar
                        value={queryParams.Search || ''}
                        onChange={(val) => setQueryParams((p) => ({ ...p, Search: val }))}
                    />
                </Grid>
                <Grid item xs={12}>
                    {error && (
                        <Alert variant={'filled'} severity={'error'}>
                            Error: {guessErrorMessage(error)}
                        </Alert>
                    )}
                    {isLoading && <div>Loading...</div>}
                    {data && (
                        <DataGridBase
                            disableColumnFilter
                            disableDensitySelector
                            disableColumnSelector
                            columns={columns}
                            state={data}
                            setQuery={setQueryParams}
                        />
                    )}
                </Grid>
            </Grid>

            {createDialogIsOpen && (
                <CreateTemplate open={createDialogIsOpen} onClose={() => setCreateDialogIsOpen(false)} />
            )}
        </PageContainerWithHeader>
    )
}
