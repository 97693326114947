import { z } from 'zod'
import { PagingQuery, SortingQuery, dateTime, paged, trackable } from '../../../common/react-query'

export type SubscribersQuery = {
    search?: string
} & PagingQuery &
    SortingQuery

const subscriber = trackable.extend({
    FirstName: z.string().optional(),
    LastName: z.string().optional(),
    ManageCode: z.string().optional().nullable(),
    Email: z.string().email(),
    EmailConfirmedAt: dateTime.optional().nullable(),
    EmailConfirmationSentAt: dateTime.optional().nullable()
})
export const subscribers = paged.extend({
    Rows: z.array(subscriber)
})
export type Subscriber = z.infer<typeof subscriber>
export type Subscribers = z.infer<typeof subscribers>
