import React, { useEffect, useState } from 'react'
import Navigation, { getPrimacyValue } from './Navigation'
import { notify, sanitizePathString, validateUUID } from '../../helpers'
import { contentService } from '../content/content.service'
import { navigationService } from './navigation.service'
import { navigationHelpers } from './navigation.helpers'
import { CircularProgress } from '@mui/material'
import { uuidNil } from '../../common/constants'
import { Navigation as NavigationModel } from './types'
import { EmittedEventContextProvider } from './useEmitter'
import { guessErrorMessage } from '../../helpers/guessErrorMessage'
import { useCurrentSite, useCurrentSiteID, useTenantSites } from '../auth/atoms'
import { AsShareable } from '../auth/permissions/evaluate'

const NavigationLanding = () => {
    // Context
    const tenantSites = useTenantSites()
    const currentSite = useCurrentSite()
    const currentSiteID = useCurrentSiteID()

    // State
    const [sortableTreeData, setSortableTreeData] = useState([])
    const [navigationToBeDeleted, setNavigationToBeDeleted] = useState([])
    const [orphanedNavigation, setOrphanedNavigation] = useState([])
    const [unassignedExternalNavigation, setUnassignedExternalNavigation] = useState([])

    const [departmentNavigation, setDepartmentNavigation] = useState([])

    // Flat Navigation Reference
    const [navigationReferenceMap, setNavigationReferenceMap] = useState({})

    // Enabled / Disabled
    const [isEnabled, setIsEnabled] = useState(false)

    const parseNavigationRecords = (navigationRecords) => {
        try {
            if (!navigationRecords?.length) {
                return { nested: [], orphaned: [], unassigned: [], references: {} }
            }
            const treeModels = navigationHelpers.toTreeModels(navigationRecords)
            const tree = navigationHelpers.Nest(treeModels)
            const references = {}
            for (const item of navigationRecords) {
                const { path, ContentId, treeIndex, inlineChildren, DepartmentId } = item
                references[ContentId] = { existsInTree: true, visible: true, path, treeIndex, inlineChildren }
                if (validateUUID(DepartmentId)) {
                    references[DepartmentId] = { existsInTree: true, visible: true, path, treeIndex, inlineChildren }
                }
            }
            for (const navigation of tree.orphaned.concat(tree.unassigned)) {
                const { ContentId } = navigation
                if (references[ContentId]) {
                    references[ContentId].existsInTree = false
                }
            }
            // ...tree =>
            //   - Nested     = The compatible nested nodes ready for DND
            //   - Orphaned   = Nodes that have lost their parent connection and require reconnection
            //   - Unassigned = External_link items which dont have a valid parent connection
            //  Flat       = A reference object used in the "Add Page" operation to manage the "Checked" status
            return { ...tree, references }
        } catch (e) {
            console.log(e)
            return []
        }
    }

    const findDifference = () => {
        const difference = {}
        // TODO => Department nodes come in as uuidNil
        //  References is an object, multiple uuidNil's get combined to one key
        //  We iterate through references, so we're missing multiple department nodes
        //  We should create these nodes first, then push them to navigation controller
        //  1) uuidNil, create the content (external_link) record
        //  2) After created, add to references
        //  3) iterate through references and patch like doing now.
        const { tree, references, createContent, updateContent } = navigationHelpers.BuildTreePaths(sortableTreeData)
        for (const key in references) {
            if (
                !navigationReferenceMap[key] ||
                navigationReferenceMap[key]?.path !== references[key]?.path ||
                navigationReferenceMap[key]?.treeIndex !== references[key]?.treeIndex ||
                navigationReferenceMap[key]?.inlineChildren !== references[key]?.inlineChildren
            ) {
                difference[key] = references[key]
            }
        }
        return {
            difference: Object.values(difference),
            tree,
            createContent,
            updateContent
        }
    }

    const loadNavigation = async () => {
        const navigation = await navigationService.getAll()
        const { nested, orphaned, unassigned, references = {} } = parseNavigationRecords(navigation)
        setSortableTreeData(nested)
        setUnassignedExternalNavigation(unassigned)
        setOrphanedNavigation(orphaned)
        setNavigationReferenceMap(references)

        const nd = tenantSites.reduce((acc, iter) => {
            if (iter.Type === 'department' && iter.Hosts.includes(currentSite?.ID)) {
                acc.push(iter)
            }
            return acc
        }, [])
        setDepartmentNavigation(nd)
        setIsEnabled(true)
    }

    const saveNavigationTree = async () => {
        try {
            const { difference, tree, createContent, updateContent } = findDifference()
            const results = await Promise.all([
                ...createContent.map(async (record) => {
                    const content = await contentService.createExternalLinkContent(
                        currentSiteID,
                        record.title,
                        record?.content?.route,
                        record?.content?.sites,
                        record?.content?.departmentId,
                        { isPrimary: getPrimacyValue(record) }
                    )
                    record.content = content
                    record.ContentId = content.id
                    record.path = record.path.replace(sanitizePathString(uuidNil), sanitizePathString(content.id))
                    const x = new NavigationModel(record).DBModel()
                    console.log({
                        original: record,
                        content,
                        dbModel: x
                    })
                    return x
                }),
                ...updateContent.map(async (record) => {
                    const { data } = await contentService.patchContent('', {
                        ...AsShareable(record?.content),
                        settings: record.content.settings
                    })
                    record.content = data
                    return record
                })
            ])

            const setInactive = navigationToBeDeleted.map((record) => ({ ...record, active: false }))
            difference.push(...results, ...setInactive)
            if (!difference.length) {
                notify('There is nothing to save', 'info')
                return
            }
            // TODO => Departments: Response currently doesn't contain anything useful.
            //  We use the tree generated prior to saving, we should be querying
            //  for fresh tree data here or returning it in the patch.
            await navigationService.patchNavigation(difference, currentSiteID)
            setSortableTreeData(tree)
            await loadNavigation()
            notify('Success!', 'info')
            setNavigationToBeDeleted([])
        } catch (e) {
            const message = guessErrorMessage(e)
            notify(`Whoops! Something went wrong while attempting to save your changes. Error: ${message}`, 'error')
        }
    }

    useEffect(() => {
        if (!currentSite?.ID) {
            return
        }
        loadNavigation()
            .then()
            .catch((e) => {
                console.error(e)
                notify(
                    `Whoops! Something went wrong while attempting to load your navigation. Please, refresh the browser window. Error: ${guessErrorMessage(
                        e
                    )}`,
                    'error'
                )
            })

        return () => {
            if (isEnabled) {
                setIsEnabled(false)
                setOrphanedNavigation([])
                setUnassignedExternalNavigation([])
                setNavigationReferenceMap([])
                setSortableTreeData([])
                setNavigationToBeDeleted([])
            }
        }
    }, [currentSiteID])

    return (
        <>
            <EmittedEventContextProvider>
                {!isEnabled ? (
                    <CircularProgress
                        style={{ width: '5rem', height: '5rem', position: 'absolute', right: '50%', top: '50%' }}
                    />
                ) : (
                    <Navigation
                        navigationToBeDeleted={navigationToBeDeleted}
                        setNavigationToBeDeleted={setNavigationToBeDeleted}
                        departmentNavigation={departmentNavigation}
                        onSubmit={saveNavigationTree}
                        sortableTreeData={sortableTreeData}
                        setSortableTreeData={setSortableTreeData}
                        orphanedNavigation={orphanedNavigation}
                        setOrphanedNavigation={setOrphanedNavigation}
                        flat={navigationReferenceMap}
                        setFlatNavigation={setNavigationReferenceMap}
                        unassignedExternalNavigation={unassignedExternalNavigation}
                        setUnassignedExternalNavigation={setUnassignedExternalNavigation}
                    />
                )}
            </EmittedEventContextProvider>
        </>
    )
}
export default NavigationLanding
