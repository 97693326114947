import React, { Fragment, useState } from 'react'
import { Button } from '@mui/material'
import '../image/Media.css'
import DocumentManager from './DocumentManager'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { SelectedDocumentContextProvider } from './context'
import { useAppContext } from '../../auth/atoms'
import { EntityScopeEnum } from '../../auth/entityScope'
import PageContainer from '../../../common/components/PageContainer'
import { WhatsNew } from '../../../common/components/WhatsNew'

export const Documents = () => {
    const evaluators = useAppContext()
    const [view, setView] = useState('output')
    const [isFolderDialogOpen, setIsFolderDialogOpen] = useState(false)

    const handleView = (view) => {
        setView(view)
    }

    return (
        <PageContainer>
            <div className='row' style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                <div className={'flex-row-align-center'}>
                    <h1 style={{ marginRight: '0.25rem' }} className='pages-header'>
                        Documents
                    </h1>
                    <WhatsNew link={'/vanity-url?hash=doL3Zhbml0eS11cmw='} />
                </div>
                {evaluators.actionForEntityScope(EntityScopeEnum.Document, 'create') && (
                    <Fragment>
                        {view === 'output' ? (
                            <span>
                                <Button
                                    startIcon={<AddCircleOutlineIcon />}
                                    style={{ color: '#4285f4' }}
                                    onClick={() => setIsFolderDialogOpen(true)}
                                >
                                    Create Folder
                                </Button>
                                <Button
                                    startIcon={<AddCircleOutlineIcon />}
                                    style={{ color: '#4285f4' }}
                                    onClick={() => handleView('input')}
                                >
                                    Add Documents
                                </Button>
                            </span>
                        ) : (
                            <Button size={'small'} style={{ color: '#4285f4' }} onClick={() => handleView('output')}>
                                View Documents and Folders
                            </Button>
                        )}
                    </Fragment>
                )}
            </div>
            <div className='row'>
                <SelectedDocumentContextProvider>
                    <DocumentManager
                        handleView={handleView}
                        view={view}
                        isFolderDialogOpen={isFolderDialogOpen}
                        setIsFolderDialogOpen={setIsFolderDialogOpen}
                    />
                </SelectedDocumentContextProvider>
            </div>
        </PageContainer>
    )
}

export default Documents
