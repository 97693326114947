import { LexicalEditor } from 'lexical'
import { ImageGallery } from '../../media/image/ImageGallery'
import { useEffect, useRef } from 'react'
import { Image } from '../../media/image/MediaExplorer'
import { INSERT_IMAGE_COMMAND, InsertImagePayload } from './ImagesPlugin'

interface InsertImageDialogProps {
    activeEditor: LexicalEditor
    isOpen: boolean
    onClose: () => void
}

export function InsertImageDialog({ activeEditor, isOpen, onClose }: InsertImageDialogProps): JSX.Element {
    const hasModifier = useRef(false)
    useEffect(() => {
        hasModifier.current = false
        const handler = (e: KeyboardEvent) => {
            hasModifier.current = e.altKey
        }
        document.addEventListener('keydown', handler)
        return () => {
            document.removeEventListener('keydown', handler)
        }
    }, [activeEditor])

    const onClick = (image: Image) => {
        const payload: InsertImagePayload = {
            altText: image?.alt || '',
            src: `/images/${image.id}`,
            captionsEnabled: true
        }
        activeEditor.dispatchCommand(INSERT_IMAGE_COMMAND, payload)
        onClose()
    }

    return <>{isOpen && <ImageGallery open={isOpen} close={onClose} onChange={onClick} />}</>
}
