import axios from 'axios'

const handleChangeRowsPerPage = (event, setState, urlRoute, limit, selectedSite, state) => {
    const firstPage = 0
    setState((prev) => ({
        ...prev,
        currentPage: firstPage,
        perPage: parseInt(event.target.value, 10)
    }))
    axios
        .get(urlRoute, {
            params: {
                limit: parseInt(event.target.value, 10),
                page: firstPage,
                siteId: selectedSite
            }
        })
        .then((content) => {
            setState((prev) => ({
                ...prev,
                data: content.data.results,
                pageCount: Math.ceil(state.total / limit) // Total count x limit
            }))
        })
}

const handleChangeRowsPerPageWithContentType = (event, setState, urlRoute, limit, selectedSite, state, contentType) => {
    const firstPage = 0
    setState((prev) => ({
        ...prev,
        currentPage: firstPage,
        perPage: parseInt(event.target.value, 10)
    }))
    axios
        .get(urlRoute, {
            params: {
                limit: parseInt(event.target.value, 10),
                page: firstPage,
                siteId: selectedSite,
                contentType: contentType
            }
        })
        .then((content) => {
            setState((prev) => ({
                ...prev,
                data: content.data.results,
                pageCount: Math.ceil(state.total / limit) // Total count x limit
            }))
        })
}

const loadContentFromServerWithContentType = (urlRoute, limit, page, selectedSite, setState, state, contentType) => {
    const data = axios
        .get(urlRoute, {
            params: {
                limit: limit,
                page: page,
                siteId: selectedSite,
                contentType: contentType
            }
        })
        .then((content) => content.data)

    Promise.all([data]).then((values) => {
        setState((prev) => ({
            ...prev,
            total: values[0].resultset.total_records,
            data: values[0].results,
            pageCount: Math.ceil(state.total / limit) // Total count x limit
        }))
    })
}

const loadContentFromServer = (urlRoute, limit, page, selectedSite, setState, state) => {
    const data = axios
        .get(urlRoute, {
            params: {
                limit: limit,
                page: page,
                siteId: selectedSite
                // contentType: "page"
            }
        })
        .then((content) => content.data)

    Promise.all([data]).then((values) => {
        setState((prev) => ({
            ...prev,
            total: values[0].resultset.total_records,
            data: values[0].results,
            pageCount: Math.ceil(state.total / limit) // Total count x limit
        }))
    })
}

const handleChangePage = (event, newPage, setState, state, selectedSite, urlRoute) => {
    let offset = Math.ceil(newPage * state.perPage)
    setState((prev) => ({
        ...prev,
        currentPage: newPage,
        offset: offset
    }))

    loadContentFromServer(urlRoute, state.perPage, newPage, selectedSite, setState, state)
}

const handleChangePageWithContentType = (event, newPage, setState, state, selectedSite, urlRoute, contentType) => {
    let offset = Math.ceil(newPage * state.perPage)
    setState((prev) => ({
        ...prev,
        currentPage: newPage,
        offset: offset
    }))

    loadContentFromServerWithContentType(urlRoute, state.perPage, newPage, selectedSite, setState, state, contentType)
}

export const pagination = {
    handleChangePage,
    loadContentFromServer,
    handleChangeRowsPerPage,
    handleChangePageWithContentType,
    loadContentFromServerWithContentType,
    handleChangeRowsPerPageWithContentType
}
