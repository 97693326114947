import { useContentDetailsQuery } from '../content/queries'
import { Alert, Card, CardActionArea, CardContent, Typography } from '@mui/material'
import { guessErrorMessage } from '../../helpers/guessErrorMessage'
import CenteredSpinner from '../../common/components/CenteredSpinner'

interface FormFromFragmentPreviewProps {
    id: string
    onClick: () => void
}
export const FormFromFragmentPreview = ({ id, onClick }: FormFromFragmentPreviewProps) => {
    const result = useContentDetailsQuery(id)

    if (result.isError) {
        return <Alert severity='error'>Error loading form fragment: {guessErrorMessage(result.error)}</Alert>
    }

    if (result.isLoading || !result.data) {
        return <CenteredSpinner />
    }

    const title = result.data.Title
    const fragmentID = result.data.ID
    const email = result.data.Data?.contact?.to

    return (
        <Card sx={{ mt: 1 }}>
            <CardActionArea onClick={onClick} title={'Click to edit'}>
                <CardContent>
                    <Typography variant='h5'>{title}</Typography>
                    <Typography variant='body1'>{email}</Typography>
                    <Typography variant='body2'>{fragmentID}</Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}
