import React, { useState } from 'react'
import { Switch } from '@mui/material'

export const ActiveCell = ({
    ID,
    Active,
    setActive
}: {
    ID: string
    Active: boolean
    setActive: (id: string, active: boolean) => Promise<void>
}) => {
    const [loading, setLoading] = useState<boolean>(false)
    return (
        <>
            {loading ? (
                <div>loading...</div>
            ) : (
                <Switch
                    checked={Active}
                    onChange={async (e) => {
                        setLoading(true)
                        await setActive(ID, e.target.checked)
                        setLoading(false)
                    }}
                />
            )}
        </>
    )
}
