import { useStructureDetailsQuery } from './queries'
import { guessErrorMessage } from '../../helpers/guessErrorMessage'
import { Checkbox, FormControlLabel, FormGroup, FormLabel } from '@mui/material'

const contentLikeSharedOverrides = ['ContentTitle', 'ContentExpireAt']
const contentLikeOverrides = [...contentLikeSharedOverrides, 'ContentMedia']

export const SchemaSectionsSelector = ({
    id,
    isContentLike,
    selected,
    onSelect,
    disabled
}: {
    id: string
    isContentLike: boolean
    selected: string[]
    disabled: boolean
    onSelect: (selected: string[]) => void
}) => {
    const contentLikeSections = isContentLike ? contentLikeOverrides : contentLikeSharedOverrides

    const result = useStructureDetailsQuery(id)

    const structureSections = (result.data?.FormStructure || []).map((s) => s.name)
    const sections = [...contentLikeSections, ...structureSections]

    if (isContentLike) {
        // ContentLike doesn't have a Structure associated with a list, so users have to explicitly supply the data for these sections
        if (structureSections.some((s) => !selected.includes(s))) {
            setTimeout(() => onSelect(Array.from(new Set([...selected, ...structureSections]))), 100)
        }
    }

    return (
        <>
            {result.isLoading && <div>Loading...</div>}
            {result.isError && <div>Error: {guessErrorMessage(result.error)}</div>}
            {result.data && (
                <>
                    <FormGroup row>
                        {sections.map((section) => {
                            return (
                                <FormControlLabel
                                    key={section}
                                    control={
                                        <Checkbox
                                            disabled={
                                                disabled || (isContentLike && !contentLikeOverrides.includes(section))
                                            }
                                            checked={selected.includes(section)}
                                            onChange={(e) => {
                                                const checked = e.target.checked
                                                const newContentTypes = checked
                                                    ? [...selected, section]
                                                    : selected.filter((ct) => ct !== section)
                                                onSelect(newContentTypes)
                                            }}
                                        />
                                    }
                                    label={section}
                                />
                            )
                        })}
                    </FormGroup>
                </>
            )}
        </>
    )
}
