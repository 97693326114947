import React, { useState } from 'react'
import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import ConfirmAction from '../../../common/components/ConfirmAction'
import CreateIcon from '@mui/icons-material/Create'
import DeleteIcon from '@mui/icons-material/Delete'
import axios from 'axios'
import { MoreHoriz } from '@mui/icons-material'
import { GridColDef } from '@mui/x-data-grid'
import { useMutation } from '@tanstack/react-query'
import { DialogFormAction, AddButton } from '../../../common/components'
import PageContainerWithHeader from '../../../common/components/PageContainerWithHeader'
import SplitButton from '../../../common/components/SplitButton'
import { WhatsNew } from '../../../common/components/WhatsNew'
import { CustomMenu, CustomMenuItem } from '../../../common/components/custom-context-menu/CustomMenu'
import { defaultPageQuery } from '../../../common/react-query'
import { notify } from '../../../helpers'
import { guessErrorMessage } from '../../../helpers/guessErrorMessage'
import { validateRequiredValues } from '../../../helpers/requiresValues'
import { useCurrentSite, useAppContext, useCurrentSiteID } from '../../auth/atoms'
import { DataGridBase } from '../../grid/DataGridBase'
import { TwoLinesCell, CellWrapper, AdminCell } from '../../grid/cells/GridCells'
import { copyToClipboard } from '../../media/copyToClipboard'
import { TagsQueryParamsV2, createTagQuery, deleteTagQuery, updateTagQuery, useTagsQueryV2 } from './tag.service'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import SearchBar from '@/common/components/SearchBar'
import TagsFilter from './TagsFilter'
import { TagType } from './types'
import { useStateWithStorage } from '@/common/storage.service'
import Button from '@mui/material/Button'

function getLatestDocumentWithTagURL(tagId: string, primaryDomain: string, fullUrl = false) {
    if (!tagId || !primaryDomain) return null
    const link = `/sys/v1/tagged/${tagId}/latest`

    if (fullUrl) {
        return new URL(link, 'https://' + primaryDomain)
    }

    return link
}

export function Tags() {
    const defaultQuery = {
        ...defaultPageQuery,
        Search: '',
        Type: []
    }
    const cacheKey = 'v3-' + window.location.pathname
    const currentSite = useCurrentSite()
    const evaluators = useAppContext()
    const currentSiteID = useCurrentSiteID()

    const [queryParams, setQueryParams] = useStateWithStorage<TagsQueryParamsV2>('tags-query-params', defaultQuery)

    const { data, refetch, isLoading } = useTagsQueryV2(queryParams)

    const createTagMutation = useMutation({
        mutationFn: createTagQuery,
        onSuccess: (data) => {
            notify('Success! Tag has been created', 'info')
            refetch()
        },
        onError: (err: any) => {
            notify('Oops! Tag could not be created', 'error')
            const errorMessage = guessErrorMessage(err)
            console.log('errorMsg', errorMessage)
        }
    })

    const editTagMutation = useMutation({
        mutationFn: updateTagQuery,
        onSuccess: (data) => {
            notify('Success! Tag has been updated', 'info')
            refetch()
        },
        onError: (err: any) => {
            notify('Oops! Tag could not be updated', 'error')
            const errorMessage = guessErrorMessage(err)
            console.log('errorMsg', errorMessage)
        }
    })

    const deleteTagMutation = useMutation({
        mutationFn: deleteTagQuery,
        onSuccess: (data) => {
            notify('Success! Tag has been deleted', 'info')
            refetch()
        },
        onError: (err: any) => {
            notify('Oops! Tag could not be deleted', 'error')
            const errorMessage = guessErrorMessage(err)
            console.log('errorMsg', errorMessage)
        }
    })

    const [localCreateState, setLocalCreateState] = useState({
        Name: '',
        Type: ''
    })
    const [errors, setErrors] = useState({
        Name: false,
        Type: false
    })

    const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false)
    const handleClose = () => setCreateDialogIsOpen(false)

    const handleCloseAdapter = () => {
        handleClose()
        setLocalCreateState({ Name: '', Type: '' })
        setErrors({ Name: false, Type: false })
    }

    const handleCreateChange = (event) => {
        setLocalCreateState((prev) => ({ ...prev, Name: event.target.value.toLowerCase() }))
        setErrors((prev) => ({ ...prev, Name: false }))
    }

    const handleCreate = () => {
        createTagMutation.mutate(localCreateState)
        // axios.post(TagAPI, { ...localCreateState }, { params: { siteId: currentSiteID } }).then(() => refetch())
        setLocalCreateState({ Name: '', Type: '' })
        handleClose()
    }

    const columns: GridColDef[] = [
        {
            field: 'Name',
            headerName: 'Name',
            flex: 2,
            disableColumnMenu: true,
            sortable: true,
            renderCell: (params) => <TwoLinesCell l1={`${params.row.Name}`} l2={params.row.ID} />
        },
        {
            field: 'Type',
            headerName: 'Type',
            width: 100,
            align: 'left',

            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => <TwoLinesCell l1={`${params.row.Type}`} l2={''} />
        },
        {
            field: 'link',
            headerName: 'Link',
            width: 180,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderHeader: () => undefined,
            renderCell: (params) =>
                params.row.Type == 'media' ? (
                    <SplitButton
                        sx={{
                            color: '#757575'
                        }}
                        button={{
                            label: 'Latest',
                            buttonProps: {
                                onClick: (e) => {
                                    const url = getLatestDocumentWithTagURL(
                                        params.row.ID,
                                        currentSite?.PrimaryDomain || ''
                                    )
                                    if (url) {
                                        copyToClipboard(
                                            url.toString(),
                                            `Copied link to latest document with tag: "${
                                                params.row.Name
                                            }". ${url.toString()}`
                                        )
                                    }
                                },
                                startIcon: <FileCopyIcon />
                            }
                        }}
                        options={[
                            {
                                label: 'Share Latest',
                                menuItemProps: {
                                    onClick: () => {
                                        const url = getLatestDocumentWithTagURL(
                                            params.row.ID,
                                            currentSite?.PrimaryDomain || '',
                                            true
                                        )

                                        if (url) {
                                            copyToClipboard(
                                                url.toString(),
                                                `Copied link to latest document with tag: "${
                                                    params.row.Name
                                                }". ${url.toString()}`
                                            )
                                        }
                                    }
                                }
                            }
                        ]}
                    />
                ) : undefined
        },
        { field: 'UpdatedAt', headerName: 'Updated', width: 200, sortable: true },

        {
            field: 'Menu',
            headerName: 'Actions',
            headerAlign: 'left',
            filterable: false,
            hideable: false,
            width: 80,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                const [actionMenuAnchor, setActionMenuAnchor] = useState<HTMLElement | null>(null)
                const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false)

                const [dialogOpenEdit, setDialogOpenEdit] = useState(false)
                const [editTagForm, setEditTagForm] = useState(params.row)
                const [editErrors, setEditErrors] = useState({
                    // currently can only edit name of tag
                    Name: '',
                    Type: ''
                })

                const handleEditChange = (event) => {
                    setEditTagForm({
                        ...editTagForm,
                        Name: event.currentTarget.value.toLowerCase()
                    })
                    setEditErrors((prev) => ({ ...prev, Name: '' }))
                }

                const handleDialogDelete = (tag) => {
                    deleteTagMutation.mutate(tag.ID)
                    setConfirmDeleteDialogOpen(false)
                }

                const handleEdit = () => {
                    editTagMutation.mutate({
                        id: editTagForm.ID,
                        tag: editTagForm
                    })
                    // tagService.update(currentSiteID, editTagForm).then(() => refetch())
                    setDialogOpenEdit(false)
                }

                return (
                    <CellWrapper style={{ textAlign: 'left' }}>
                        <IconButton
                            size={'small'}
                            aria-haspopup={'true'}
                            onClick={(e) => {
                                setActionMenuAnchor(e.currentTarget)
                            }}
                        >
                            <MoreHoriz />
                        </IconButton>
                        <CustomMenu
                            key={params.row.id}
                            anchorElement={actionMenuAnchor}
                            onClose={() => setActionMenuAnchor(null)}
                        >
                            <CustomMenuItem
                                text={'Edit'}
                                disabled={!evaluators.action(params?.row, 'update')}
                                onClick={() => {
                                    setActionMenuAnchor(null)
                                    setDialogOpenEdit(true)
                                }}
                            >
                                <CreateIcon />
                            </CustomMenuItem>
                            <CustomMenuItem
                                disabled={!evaluators.action(params?.row, 'delete')}
                                text={'Delete'}
                                onClick={() => {
                                    setConfirmDeleteDialogOpen(true)
                                    setActionMenuAnchor(null)
                                }}
                            >
                                <DeleteIcon />
                            </CustomMenuItem>
                        </CustomMenu>

                        {/*Edit*/}
                        <DialogFormAction
                            item={
                                <TextField
                                    variant='standard'
                                    autoFocus
                                    onChange={handleEditChange}
                                    value={editTagForm.Name}
                                    error={!!editErrors.Name}
                                    margin='dense'
                                    id='name'
                                    name='name'
                                    label='Tag'
                                    fullWidth
                                />
                            }
                            title='Edit a tag'
                            text='Would you like to rename this tag?'
                            open={dialogOpenEdit}
                            handleDisagree={() => setDialogOpenEdit(false)}
                            handleAgree={() => {
                                const { error, errors } = validateRequiredValues(editTagForm)
                                if (error) {
                                    return setEditErrors((p) => ({ ...p, ...errors }))
                                }
                                return handleEdit()
                            }}
                            handleClose={() => setDialogOpenEdit(false)}
                            buttonDisagreeLabel={undefined}
                            buttonAgreeLabel={undefined}
                            alternate={undefined}
                            alternateAction={undefined}
                            fullWidth={undefined}
                            headerComponent={undefined}
                        />

                        {/* Delete */}
                        <ConfirmAction
                            open={confirmDeleteDialogOpen}
                            title={`Delete "${params?.row.name}"`}
                            text={'Are you sure you want to delete this tag?'}
                            handleClose={() => setConfirmDeleteDialogOpen(false)}
                            handleDisagree={() => setConfirmDeleteDialogOpen(false)}
                            handleAgree={() => handleDialogDelete(params.row)}
                        />
                    </CellWrapper>
                )
            }
        }
    ]

    return (
        <PageContainerWithHeader
            title='Tags'
            titleSlot={<WhatsNew link={'/vanity-url?hash=doL3Zhbml0eS11cmw='} />}
            topRightElement={
                evaluators.actionForEntityScope('cm.tag', 'create') ? (
                    <AddButton title={'ADD TAG'} func={() => setCreateDialogIsOpen(true)} />
                ) : null
            }
        >
            <Grid container spacing={2}>
                <Grid item md={6}>
                    <SearchBar
                        value={queryParams.Search || ''}
                        onChange={(val) => setQueryParams((p) => ({ ...p, Search: val, page: 1 }))}
                    />
                </Grid>
                <Grid item md={3}>
                    <TagsFilter
                        value={queryParams.Type || []}
                        onChange={(v) => setQueryParams((p) => ({ ...p, Type: v, page: 1 }))}
                    />
                </Grid>
                <Grid item md={3}>
                    <Button
                        sx={{ marginTop: 'auto', marginLeft: 'auto' }}
                        onClick={() => {
                            setQueryParams(defaultQuery)
                        }}
                    >
                        Reset Filters
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    {data && (
                        <DataGridBase
                            loading={isLoading}
                            disableColumnFilter
                            disableDensitySelector
                            disableColumnSelector
                            columns={columns}
                            state={data}
                            setQuery={setQueryParams}
                        />
                    )}
                </Grid>
            </Grid>

            {/*Create*/}
            <DialogFormAction
                item={
                    <div>
                        <TextField
                            variant='standard'
                            error={errors.Name}
                            value={localCreateState.Name}
                            onChange={handleCreateChange}
                            helperText={errors.Name ? 'Entry is too short' : ''}
                            name='name'
                            label='Tag'
                            margin='dense'
                            id='name'
                            autoFocus
                            fullWidth
                        />
                        <FormControl
                            variant='standard'
                            sx={{
                                marginTop: '1vh',
                                height: '5%',
                                width: '100%'
                            }}
                        >
                            <InputLabel error={errors.Type}>type</InputLabel>
                            <Select
                                variant='standard'
                                sx={{
                                    width: '100%',
                                    marginTop: '1vh',
                                    marginBottom: '1vh'
                                }}
                                value={localCreateState.Type || ''}
                                error={errors.Type}
                                onChange={(event) => {
                                    setLocalCreateState((prev) => ({ ...prev, Type: event.target.value }))
                                    setErrors((prev) => ({ ...prev, Type: false }))
                                }}
                            >
                                <MenuItem value='media'>Media</MenuItem>
                                <MenuItem value='site'>Site</MenuItem>
                                <MenuItem value='content'>Content</MenuItem>
                                {/*<MenuItem value='event' ></MenuItem>*/}
                                {/*<MenuItem value='news' ></MenuItem>*/}
                                {/*<MenuItem value='account' ></MenuItem>*/}
                            </Select>
                        </FormControl>
                    </div>
                }
                title='Create a tag'
                text='What would you like to name this new tag?'
                open={createDialogIsOpen}
                handleDisagree={handleCloseAdapter}
                handleAgree={() => {
                    const { error, errors } = validateRequiredValues(localCreateState)
                    if (error) {
                        return setErrors((p) => ({ ...p, ...errors }))
                    }
                    return handleCreate()
                }}
                handleClose={handleCloseAdapter}
                buttonAgreeLabel={'save'}
                buttonDisagreeLabel={'cancel'}
                alternate={undefined}
                alternateAction={undefined}
                fullWidth={undefined}
                headerComponent={undefined}
            />
        </PageContainerWithHeader>
    )
}
