import React, { useEffect } from 'react'
import { LexicalEditor } from './RichText'
import { SerializedEditorState, SerializedLexicalNode } from 'lexical'
import { InitialEditorStateType } from '@lexical/react/LexicalComposer'
import Editor from '@monaco-editor/react'
import { LexicalEditor as LexicalEditorT } from 'lexical'
import TreeViewer from '@/pkgs/lexical-editor/TreeViewer'
import { Button, Dialog, DialogContent, FormControlLabel, Switch } from '@mui/material'

export const LexicalPlayground = () => {
    const [editInput, setEditInput] = React.useState<boolean>(false)
    const [asJson, setAsJson] = React.useState<boolean>(false)
    const [input, setInput] = React.useState<string>('')

    const [lexicalHTML, setLexicalHTML] = React.useState<string>()
    const [lexicalJSON, setLexicalJSON] = React.useState<
        string | null | SerializedEditorState<SerializedLexicalNode> | InitialEditorStateType
    >(null)
    const [output, setOutput] = React.useState<string>('')

    const [lexicalEditor, setLexicalEditor] = React.useState<LexicalEditorT | undefined>(undefined)

    useEffect(() => {
        if (editInput) {
            setLexicalEditor(undefined)
        }
    }, [editInput])
    return (
        <div style={{ margin: 'auto', maxWidth: '90%', display: 'flex' }}>
            <div style={{ width: '50%', padding: '10px' }}>
                <FormControlLabel
                    control={<Switch />}
                    label={'Edit input'}
                    labelPlacement={'end'}
                    onChange={() => setEditInput(!editInput)}
                    checked={editInput}
                />
                <FormControlLabel
                    control={<Switch />}
                    label={'As JSON'}
                    labelPlacement={'end'}
                    onChange={() => setAsJson(!asJson)}
                    checked={asJson}
                    disabled={!editInput}
                />
                <Editor
                    value={input}
                    language={asJson ? 'json' : 'html'}
                    width={'100%'}
                    height={'calc((100vh / 3) - 50px)'}
                    onChange={(value) => {
                        setInput(value || '')
                    }}
                    options={{
                        readOnly: !editInput,
                        wordWrap: 'on',
                        minimap: { enabled: false },
                        automaticLayout: true
                    }}
                ></Editor>

                {!editInput && <TreeViewer editor={lexicalEditor} />}
            </div>
            <div style={{ width: '50%', padding: '10px' }}>
                <Button
                    onClick={() => {
                        const o = {
                            lexical: {
                                html: lexicalHTML,
                                json: lexicalJSON,
                                engine: 'lexical'
                            }
                        }
                        setOutput(JSON.stringify(o, null, 2))
                    }}
                >
                    Show lexical output
                </Button>
                {!editInput && (
                    <LexicalEditor
                        value={!asJson && input ? input : ''}
                        onChange={(value: string, editorState) => {
                            setLexicalHTML(value)
                            setLexicalJSON(editorState)
                        }}
                        onLoad={setLexicalEditor}
                        initialEditorState={asJson && input ? input : undefined}
                    />
                )}
            </div>
            <Dialog open={!!output} onClose={() => setOutput('')} fullWidth={true} maxWidth={'md'}>
                <DialogContent>
                    <pre>{output}</pre>
                </DialogContent>
            </Dialog>
        </div>
    )
}

function formatHTML(html: string) {
    const tab = '  '
    let result = ''
    let indent = 0

    html.split(/>\s*</).forEach((element) => {
        if (element.match(/^\/\w/)) {
            indent--
        }

        result += tab.repeat(indent) + '<' + element + '>\n'

        if (element.match(/^<?\w[^>]*[^\/]$/) && !element.startsWith('input')) {
            indent++
        }
    })

    return result.substring(1, result.length - 2)
}
