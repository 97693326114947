import React, { Fragment, useContext, useMemo, useState } from 'react'
import Button from '@mui/material/Button'
import { Checkbox } from '@mui/material'
import { documentContext } from '../context'
import CustomSelect from '../../../../common/components/MultipleSelect'
import { selectedDocumentContext } from '../context/SelectedDocumentContext'
import { documentService } from '../document.service'
import { notify, renderSelectedValue } from '../../../../helpers'
import ConfirmAction from '../../../../common/components/ConfirmAction'
import '../../../../app/App.css'
import '../../image/Media.css'
import { useAppContext } from '../../../auth/atoms'
import { EntityScopeEnum } from '../../../auth/entityScope'

const getPaginationState = (pagination, resultsLen) => {
    const total = pagination.total - resultsLen
    const offset = pagination.currentPage ? pagination.offset : 0
    return { total, offset, limit: pagination.perPage }
}

export default function DocumentBulkActions({
    allowedFolders,
    bulkActionEnabled,
    setBulkActionEnabled,
    chosenFolder,
    setChosenFolder,
    pagination,
    setPagination,
    loadPaginatedDocuments
}) {
    const [selectedDocuments, setSelectedDocuments] = useContext(selectedDocumentContext)
    const evaluators = useAppContext()
    const [documents] = useContext(documentContext)
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
    const [isMoveDialogOpen, setIsMoveDialogOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [canUpdate, canDelete] = useMemo(
        () => [
            evaluators.actionForEntityScope(EntityScopeEnum.Document, 'update'),
            evaluators.actionForEntityScope(EntityScopeEnum.Document, 'delete')
        ],
        [evaluators]
    )

    const popElementFromMultiple = (change) => {
        if (change && change.length > 1) {
            change.shift()
        }
    }
    const startMoveDialog = () => {
        setIsMoveDialogOpen(true)
    }
    const endMoveDialog = () => {
        setIsLoading(false)
        setIsMoveDialogOpen(false)
        setChosenFolder([])
        setBulkActionEnabled(false)
    }
    const startDeleteDialog = () => {
        setIsDeleteDialogOpen(true)
    }
    const endDeleteDialog = () => {
        setIsDeleteDialogOpen(false)
        setIsLoading(false)
        setBulkActionEnabled(false)
    }

    const setAllCheckboxes = (event) => {
        setBulkActionEnabled(!bulkActionEnabled)
        if (event.target.checked) {
            let obj = {}
            for (const item of documents) {
                // TODO => Permissions - Test
                console.log(item)
                if (evaluators.action(item, 'update') || evaluators.action(item, 'delete')) {
                    obj[item.id] = true
                }
            }
            setSelectedDocuments(obj)
        } else {
            setSelectedDocuments({})
        }
    }

    const handleMoveTo = async () => {
        setIsLoading(true)
        let ref = []
        for (const item in selectedDocuments) {
            if (selectedDocuments[item]) ref.push(item)
        }
        let folderId = chosenFolder && chosenFolder.length ? chosenFolder[0].id : ''
        const result = await documentService.editDocumentsFolder(evaluators.currentSiteID, ref, folderId)
        if (result) {
            const len = result.length
            const { total, offset, limit } = getPaginationState(pagination, len)
            setPagination((prev) => ({ ...prev, total, offset }))
            loadPaginatedDocuments(limit, offset)
            endMoveDialog()
            notify('Success!', 'info')
        } else {
            notify('Whoops! Invalid folder selected', 'error')
        }
        setSelectedDocuments({})
    }

    const deleteDocuments = async () => {
        setIsLoading(true)
        let ref = []
        for (const item in selectedDocuments) {
            if (selectedDocuments[item]) ref.push(item)
        }

        try {
            const result = await documentService.deleteDocuments(evaluators.currentSiteID, ref)
            const len = ref.length
            const { total, offset, limit } = getPaginationState(pagination, len)
            setPagination((prev) => ({ ...prev, total, offset }))
            loadPaginatedDocuments(limit, offset)
            setSelectedDocuments({})
        } catch (err) {
            notify("Whoops! Documents couldn't be deleted", 'error')
            console.error(err)
        }
        endDeleteDialog()
    }

    return (
        <div className='row' style={{ paddingLeft: '0.55em' }}>
            {(canUpdate || canDelete) && (
                <Fragment>
                    <span className='col-xs-1 row align-center'>
                        <Checkbox checked={bulkActionEnabled} onChange={setAllCheckboxes} />
                    </span>

                    <span className='col-xs-6 col-lg-5 row align-center'>
                        <CustomSelect
                            label={'Move files to...'}
                            multiple
                            renderValue={renderSelectedValue}
                            customChange={popElementFromMultiple}
                            value={chosenFolder}
                            setValue={setChosenFolder}
                            options={[{ filename: 'Documents', path: '', id: '' }, ...allowedFolders]}
                            nameKey={'filename'}
                            customStyle={{ width: '80%', marginBottom: '25px' }}
                        />
                        <Button
                            color={'primary'}
                            size={'small'}
                            disabled={!canUpdate || !chosenFolder.length > 0}
                            onClick={startMoveDialog}
                        >
                            APPLY
                        </Button>
                    </span>
                    <span className='col-xs-2 row align-center'>
                        <Button
                            size={'small'}
                            style={{ color: !canDelete ? 'rgba(0, 0, 0, 0.26)' : '#e66262' }}
                            onClick={startDeleteDialog}
                            disabled={!canDelete}
                        >
                            Delete Selected
                        </Button>
                    </span>

                    {isMoveDialogOpen && (
                        <ConfirmAction
                            open={isMoveDialogOpen}
                            title='Are you sure?'
                            text={`Please confirm if you'd like to move these documents ${
                                chosenFolder && chosenFolder.length ? 'to ' + chosenFolder[0].filename : ''
                            }`}
                            handleDisagree={() => setIsMoveDialogOpen(false)}
                            handleAgree={handleMoveTo}
                            isLoading={isLoading}
                        />
                    )}

                    {isDeleteDialogOpen && (
                        <ConfirmAction
                            open={isDeleteDialogOpen}
                            title='Are you sure?'
                            text={`Please confirm if you'd like to delete these documents`}
                            handleDisagree={endDeleteDialog}
                            handleAgree={deleteDocuments}
                            isLoading={isLoading}
                        />
                    )}
                </Fragment>
            )}
        </div>
    )
}
