import React, { useEffect, useState } from 'react'
import { Box, Checkbox, FormControlLabel, TextField } from '@mui/material'
import { BoxForm } from '../../../common/components/BoxForm'
import { SingleSiteSelect } from '../../../common/components/selectors/SingleSiteSelect'
import Papa, { NODE_STREAM_INPUT } from 'papaparse'
import { CodeEditor } from '../../monaco/code-editor'
import { Settings, SettingsType } from './types'
import SettingsTypeSelector from './SettingsTypeSelector'
import { SiteSelectorForContent } from '../../../common/components/selectors/SiteSelectorForContent'
import { EntityScopeEnum } from '../../auth/entityScope'
import {mustString, tryParseJson} from "@/helpers/parseJSON";

export type ISettingsFormData = Omit<Settings, 'ID'>

interface SettingsFormProps {
    formData: ISettingsFormData
    setFormData: React.Dispatch<React.SetStateAction<ISettingsFormData>>
    settingsFormJson: string | null
    setSettingsFormJson: React.Dispatch<React.SetStateAction<string | null>>
    formDataErrors?: Partial<ISettingsFormData>
    setFormDataErrors?: React.Dispatch<React.SetStateAction<Partial<ISettingsFormData> | undefined>>
    formControlSection: JSX.Element
    onSubmit: () => void
    onReset: () => void
    // user should not be able to edit settings type
    disableSettingsTypeSelector?: boolean
}

function SettingsForm({
    formData,
    formDataErrors,
    setFormDataErrors,
    setFormData,
    formControlSection,
    settingsFormJson,
    setSettingsFormJson,
    onSubmit,
    onReset,
    disableSettingsTypeSelector
}: SettingsFormProps) {
    const [importedFile, setImportedFile] = useState<null | File>(null)

    useEffect(() => {
        if (importedFile) {
            importedFile.text().then((res) => {
                Papa.parse(res as typeof NODE_STREAM_INPUT, {
                    header: true,
                    complete: (results) => {
                        setSettingsFormJson(JSON.stringify(results?.data, undefined, 4))
                    }
                })
            })
        }
    }, [importedFile])

    return (
        <BoxForm
            onSubmit={onSubmit}
            onReset={onReset}
            sx={{
                maxWidth: undefined
            }}
        >
            <TextField
                required
                aria-required
                inputProps={
                    {
                        // maxLength: 500
                    }
                }
                variant={'standard'}
                label={'Name'}
                name={'name'}
                value={formData?.Name || ''}
                error={!!formDataErrors?.Name}
                helperText={formDataErrors?.Name}
                onChange={(ev) => {
                    setFormData((p) => ({ ...p, Name: ev.target.value }))
                    setFormDataErrors?.((p) => ({ ...p, Name: undefined }))
                }}
            />
            <SettingsTypeSelector
                disabled={disableSettingsTypeSelector}
                required
                variant='standard'
                initialValue={formData.Type}
                onChange={(type) => {
                    setFormData((p) => ({ ...p, Type: type }))
                    setFormDataErrors?.((p) => ({ ...p, Type: undefined }))
                    // Optionally, check if the editor is empty - it might be convenient to update even if it isn't.
                    setSettingsFormJson(mustString(getDefaultJsonByType(type)))
                }}
            />

            <TextField
                inputProps={{
                    maxLength: 500
                }}
                variant={'standard'}
                label={'Description'}
                name={'description'}
                value={formData?.Description || ''}
                onChange={(ev) => setFormData((p) => ({ ...p, Description: ev.target.value }))}
            />
            <SiteSelectorForContent
                ShowDistrictWideSwitch
                Variant='standard'
                Selected={formData.Sites || []}
                ContentType={EntityScopeEnum.Settings}
                Label={'Sites'}
                OnChange={(v) => {
                    setFormData((p) => ({
                        ...p,
                        Sites: v?.length ? v : null
                    }))
                }}
            />
            <FormControlLabel
                label={'Public'}
                control={
                    <Checkbox
                        checked={formData?.Public || false}
                        onChange={(e) => setFormData((p) => ({ ...p, Public: e.target.checked ? true : false }))}
                    />
                }
            />
            <Box>
                <input
                    style={{ width: '100%' }}
                    type='file'
                    onChange={(ev) => setImportedFile(ev.target?.files?.item(0) || null)}
                />
            </Box>
            <CodeEditor
                value={settingsFormJson === null ? '' : settingsFormJson}
                language={'json'}
                onChange={(v) => setSettingsFormJson(v || '')}
            />
            {formControlSection}
        </BoxForm>
    )
}

export default SettingsForm


function getDefaultJsonByType(type: string): Record<string,any> {
    switch (type) {
        case "outlook":
        case "edsby":
            return getEmptyStandardizedIntegration()
        case "outlook-secrets":
            return { tenantId: "string", timezone: "" }
        case "edsby-secrets":
            return edsbySecretsJson
        case 'google-calendar':
            return { GoogleCalendarID: "" }
        case 'google-calendar-secrets':
            return { adminUser: "" }
        case 'facebook':
            return { FacebookPageId: "" }
        case 'facebook-secrets':
            return { user_access_token: "" }
    }
    return {}
}

type ConfigurableField = {
    insertOnly: boolean
    default: any
    useDefault: boolean
}
interface ContentConfig {
    templateId: string
    fields: {
        tags: ConfigurableField
        site: ConfigurableField
        privacyLevel: ConfigurableField
        published: ConfigurableField
    }
}
const getEmptyContentConfig = () => ({
    templateId: "",
    fields: {
        tags: {
            insertOnly: false,
            default: "",
            useDefault: false,
        },
        site: {
            insertOnly: false,
            default: "",
            useDefault: false,
        },
        published: {
            insertOnly: false,
            default: "",
            useDefault: false,
        },
        privacyLevel: {
            insertOnly: false,
            default: "",
            useDefault: false,
        },
    }
})
interface StandardizedIntegrationConfig {
    id      : string // Should refactor this from id to something like externalId | integrationId
    news    : ContentConfig
    events  : ContentConfig
}
const getEmptyStandardizedIntegration = (): StandardizedIntegrationConfig => ({
    id      : "",
    news    : getEmptyContentConfig(),
    events  : getEmptyContentConfig(),
})
const edsbySecretsJson = {
    ClientID     : "",
    ClientSecret : "",
    ClientURL    : "",
    Timezone     : "",
}