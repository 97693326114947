import React, { useEffect, useMemo, useRef, useState } from 'react'
import { CKEditor as CKEditor5 } from '@ckeditor/ckeditor5-react'
import ClassicEditor from 'ie-ckeditor5'
import WysiwygActions from '../../common/editor/WysiwygActions'
import ImageGallery from '../media/image/ImageGallery'
import DocumentGallery from '../media/document/DocumentGallery'
import { insertMediaIntoEditor } from '../../helpers'
import './CKEditor.css'

export const DefaultEditorConfig = {
    heading: {
        options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
            { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
            { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
            { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
        ]
    },
    link: {
        decorators: {
            openInNewTab: {
                mode: 'manual',
                //mode:"automatic",
                //callback can be used to check (e.g) isExternal link
                //callback
                label: 'Open in a new tab',
                attributes: {
                    target: '_blank',
                    rel: 'noopener noreferrer'
                }
            }
        }
    },
    toolbar: {
        // items: [ 'heading', '|', 'bold', 'italic', 'link', '|', 'bulletedList', 'numberedList', 'blockQuote','horizontalLine','insertTable','|','style','|','mediaEmbed','|', 'collapsible','sourceEditing']
        items: [
            'heading',
            '|',
            'bold',
            'italic',
            'link',
            '|',
            'bulletedList',
            'numberedList',
            'blockQuote',
            'horizontalLine',
            'insertTable',
            '|',
            'htmlEmbed',
            'mediaEmbed',
            '|',
            'collapsible'
        ]
    }
    // style: {
    //     definitions: [
    //         {
    //             name: '(h3) Article category',
    //             element: 'h3',
    //             classes: [ 'ck-category' ]
    //         },
    //         {
    //             name: '(p) Info box',
    //             element: 'p',
    //             classes: [ 'ck-info-box' ]
    //         },
    //         {
    //             name: '(h1) Styled Title',
    //             element: 'h1',
    //             classes: [ 'ck-header-title' ]
    //         },
    //     ]
    // },
}

interface CKEditorProps {
    onChange: (value: string) => void
    value: string
    onReady?: (any) => void
    disabled?: boolean
}

export const CKEditor = React.memo(function CKEditor({ disabled, value, onChange, onReady }: CKEditorProps) {
    const [isImageGalleryOpen, setIsImageGalleryOpen] = useState(false)
    const [isDocumentGalleryOpen, setIsDocumentGalleryOpen] = useState(false)
    const initialValue = useMemo(() => value, [])
    const editorRef = useRef(null)
    const timeoutRef = useRef<NodeJS.Timeout>()
    const delay = 2000

    function setChangeTimeout() {
        if (timeoutRef?.current) {
            clearTimeout(timeoutRef?.current)
        }
        timeoutRef.current = setTimeout(handleOnChange, delay)
    }

    function handleOnChange() {
        if (editorRef.current != null) {
            //@ts-ignore
            onChange?.(editorRef.current?.getData() || '')
        }
    }

    function clearTimer() {
        if (timeoutRef?.current) clearTimeout(timeoutRef?.current)
    }

    useEffect(() => {
        return () => {
            clearTimer()
        }
    }, [timeoutRef?.current])

    return (
        <>
            <WysiwygActions
                disabled={disabled}
                openMediaGallery={() => {
                    setIsImageGalleryOpen(true)
                }}
                openDocumentGallery={() => setIsDocumentGalleryOpen(true)}
                vertical={undefined}
            />
            <CKEditor5
                editor={ClassicEditor}
                data={initialValue}
                onBlur={handleOnChange}
                onReady={(editor) => {
                    editorRef.current = editor
                    handleOnChange()
                }}
                onChange={setChangeTimeout}
                disabled={Boolean(disabled)}
                className={Boolean(disabled) ? 'disabled-editor' : ''}
                config={DefaultEditorConfig}
            />
            <ImageGallery
                open={isImageGalleryOpen}
                // setGalleryOpen={openImageGallery}
                close={() => setIsImageGalleryOpen(false)}
                // isForEditor={true}
                onChange={(img) => {
                    insertMediaIntoEditor(img, editorRef?.current)
                    handleOnChange()
                }}
            />
            <DocumentGallery
                isGalleryOpen={isDocumentGalleryOpen}
                setGalleryClose={() => setIsDocumentGalleryOpen(false)}
                isForDct={undefined}
                saveForDct={undefined}
            />
        </>
    )
})
export default CKEditor
