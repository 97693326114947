import { InitialEditorStateType, LexicalComposer } from '@lexical/react/LexicalComposer'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin'
import { HorizontalRulePlugin } from '@lexical/react/LexicalHorizontalRulePlugin'
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode'
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary'
import { HeadingNode, QuoteNode } from '@lexical/rich-text'
import { ListItemNode, ListNode } from '@lexical/list'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin'
import { Toolbar } from './toolbar/Toolbar'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { CodeHighlightNode, CodeNode } from '@lexical/code'
import React, { useCallback, useEffect, useState } from 'react'
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html'
import {
    $createParagraphNode,
    $getRoot,
    $isDecoratorNode,
    $isElementNode,
    LexicalEditor as LexicalEditorT,
    ParagraphNode,
    SerializedEditorState,
    SerializedLexicalNode
} from 'lexical'
import './lexical-theme.css'
import TableCellResizerPlugin from './Table/TableCellResizer'
import TableActionMenuPlugin from './Table/TableActionMenuPlugin'
import ImagesPlugin from './Image/ImagesPlugin'
import { ImageNode } from './Image/ImageNode'
import DraggableBlockPlugin from './DraggableBlock/DraggableBlockPlugin'
import { TableNode } from './Table/TablePlugin/TableNode'
import { TableCellNode } from './Table/TablePlugin/TableCellNode'
import { TablePlugin } from './Table/TablePlugin/TablePlugin'
import { TableRowNode } from './Table/TablePlugin/TableRowNode'
import VideoPlugin from './Embed/VideoPlugin'
import { VideoNode } from './Embed/VideoNode'
import AutoEmbedPlugin from './Embed/AutoEmbedPlugin'
import ContentFragmentPlugin from './ContentFragment/ContentFragmentPlugin'
import { ContentFragmentNode } from './ContentFragment/ContentFragmentNode'
import CollapsiblePlugin from './Collapsible/CollapsiblePlugin'
import { CollapsibleContainerNode } from './Collapsible/CollapsibleContainerNode'
import { CollapsibleContentNode } from './Collapsible/CollapsibleContentNode'
import { CollapsibleTitleNode } from './Collapsible/CollapsibleTitleNode'
import { AutoLinkNode, LinkNode } from './Link/LinkNode'
import { LinkPlugin } from './Link/LinkPlugin'
import MonacoCodeEmbedPlugin from './MonacoCodeEmbed/MonacoCodeEmbedPlugin'
import { MonacoCodeEmbedNode } from './MonacoCodeEmbed/MonacoCodeEmbedNode'
import { LexicalTheme } from './lexical-theme'
import _ from 'lodash'
import VideoNodeMenuPlugin from './Embed/VideoNodeMenuPlugin'
import CodeHighlightPlugin from './Code/CodeHighlightPlugin'
import { WhatsNew } from '@/common/components/WhatsNew'
import { YouTubeNode } from './Embed/YoutubeNode'
import { InsertListListenerPlugin } from '@/pkgs/lexical-editor/EventListeners/InsertListListenerPlugin'
import { OnLoadPlugin } from '@/pkgs/lexical-editor/OnLoadPlugin'
import { sanitizeHTML } from '@/pkgs/lexical-editor/helpers/sanitizeHTML'

// Lexical Playground for examples on how to use & build the editor.
// https://playground.lexical.dev/
// https://github.com/facebook/lexical/tree/main/packages/lexical-playground

const editorConfig = {
    theme: LexicalTheme,
    onError(error) {
        console.error(error)
    },
    // Any custom nodes go here
    nodes: [
        HeadingNode,
        ListNode,
        ListItemNode,
        QuoteNode,
        TableNode,
        TableCellNode,
        TableRowNode,
        AutoLinkNode,
        LinkNode,
        ImageNode,
        HorizontalRuleNode,
        VideoNode,
        ContentFragmentNode,
        CollapsibleContainerNode,
        CollapsibleContentNode,
        CollapsibleTitleNode,
        MonacoCodeEmbedNode,
        CodeNode,
        CodeHighlightNode,
        YouTubeNode
    ]
}

interface LexicalEditorProps {
    onChange: (value: string, editorState: SerializedEditorState<SerializedLexicalNode> | null) => void
    onLoad?: (editor: LexicalEditorT) => void
    value: string
    initialEditorState?: InitialEditorStateType
    disabled?: boolean
    config?: { toolbar?: string[] }
}

// Table and Images: https://github.com/Imagine-Everything/contentmanager/pull/509#issue-2017113206

export function LexicalEditor({
    onChange,
    value,
    disabled,
    initialEditorState,
    onLoad,
    config: { toolbar = [] } = {}
}: LexicalEditorProps) {
    const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null)
    const onChangeThrottled = _.throttle(onChange, 200, { leading: false, trailing: true })
    const onRef = (_floatingAnchorElem: HTMLDivElement) => {
        if (_floatingAnchorElem !== null) {
            setFloatingAnchorElem(_floatingAnchorElem)
        }
    }

    const iEditor = useCallback((editor: LexicalEditorT) => {
        if (value) {
            // Browser -> Use native DOMParser
            // SSR -> Use JSDom parser
            const parser = new DOMParser()
            const dom = parser.parseFromString(sanitizeHTML(`<div>${value}</div>`), 'text/html')
            const nodes = $generateNodesFromDOM(editor, dom)
            // Migration creates HTML which is sometimes leading to errors when appending to root.
            // Certain HTML tags are removed and have their inner HTML exposed (e.g <div>test</div> becomes "test")
            // If nodes[i] is a TextNode, wrap it in a paragraph so that it doesn't throw an error, and, hopefully the issue
            // is then resolved on save, as the TextNode is now wrapped in a supported element.
            for (let i = 0; i < nodes.length; i++) {
                if (!$isDecoratorNode(nodes[i]) && !$isElementNode(nodes[i])) {
                    nodes[i] = $createParagraphNode().append(nodes[i])
                }
            }
            $getRoot().append(...nodes)
        }
    }, [])

    return (
        <div>
            <WhatsNew link={'/using-contact-forms?hash=doL3VzaW5nLWNvbnRhY3QtZm9ybXM='} />
            {/*@ts-ignore*/}
            <LexicalComposer
                initialConfig={{
                    ...editorConfig,
                    editorState: initialEditorState || iEditor,
                    editable: !disabled,
                    namespace: 'root'
                }}
            >
                <div className='editor-container'>
                    <Toolbar toolbarOptions={toolbar} disabled={Boolean(disabled)} />
                    <div className='editor-inner'>
                        <RichTextPlugin
                            contentEditable={
                                <div ref={onRef} style={{ overflow: 'auto' }}>
                                    <ContentEditable className='editor-input' />
                                </div>
                            }
                            placeholder={<div className='editor-placeholder'>Enter some rich text...</div>}
                            ErrorBoundary={LexicalErrorBoundary}
                        />
                        <OnChangeHTML onChange={onChangeThrottled} />
                        <HistoryPlugin />
                        <AutoFocusPlugin />
                        <ListPlugin />
                        <OnLoadPlugin onLoad={onLoad} />
                        <LinkPlugin />
                        <TablePlugin hasCellMerge />
                        <ImagesPlugin />
                        <TableCellResizerPlugin />
                        {floatingAnchorElem && (
                            <>
                                <DraggableBlockPlugin anchorElem={floatingAnchorElem} />
                                <TableActionMenuPlugin anchorElem={floatingAnchorElem} cellMerge={true} />
                                {/* <CodeActionMenuPlugin anchorElem={floatingAnchorElem} /> */}
                                <VideoNodeMenuPlugin anchorElem={floatingAnchorElem} />
                            </>
                        )}
                        <HorizontalRulePlugin />
                        <VideoPlugin />
                        <ContentFragmentPlugin />
                        <AutoEmbedPlugin />
                        <InsertListListenerPlugin />
                        <CollapsiblePlugin />
                        <MonacoCodeEmbedPlugin />
                        <TabIndentationPlugin />
                        <CodeHighlightPlugin />
                    </div>
                </div>
            </LexicalComposer>
        </div>
    )
}

interface OnChangeHTMLProps {
    onChange: (value: string, editorState: SerializedEditorState<SerializedLexicalNode> | null) => void
}

const OnChangeHTML = ({ onChange }: OnChangeHTMLProps) => {
    // Lexical requires a active editor instance to serialize Editor(JSON) to HTML
    // https://github.com/facebook/lexical/issues/4003
    // There are ways to be able to generate an HTML string from the stored JSON format that Lexical
    // provides, but based on issue#4003 above, it is left to user implementation.
    // e.g: https://github.com/AlessioGr/payload-plugin-lexical/blob/master/serialize-example/NewRichTextParser.ts
    // There is also a 'headless' version that is recommended in cases where the content is meant to be rendered on a webpage.
    // this should be investigated.
    const [editor] = useLexicalComposerContext()

    useEffect(() => {
        if (!onChange) {
            return
        }

        return editor.registerUpdateListener((e) => {
            // lexical will for some unknown reason set last paragraph node direction even if it is unset
            // causing unexpected changes on load and impacting the unsaved changes indicator in content-editor
            const nodes = e.editorState._nodeMap
            editor.update(() => {
                const editorStateJson = e.editorState.isEmpty() ? null : e.editorState.toJSON()
                onChange($generateHtmlFromNodes(editor, null), editorStateJson)
            })
        })
    }, [editor, onChange])

    return null
}
