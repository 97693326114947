import { z } from 'zod'
import { paged, trackable } from '../../common/react-query'

const component = z.object({
    name: z.string(),
    type: z.string(),
    label: z.string(),
    key: z.boolean().nullish(),
    required: z.boolean().nullish(),
    validate: z.any().nullish(),
    maximumLength: z.any().nullish(),
    options: z.any().nullish()
    // config: z.object(
    //     {
    //         toolbar: z.array(z.string()),
    //         engine: z.string()
    //     }
    // ).nullish()
})

const formStructure = z.object({
    name: z.string(),
    title: z.string(),
    description: z.string().nullish(),
    allowMultiple: z.boolean().nullish(),
    components: z.array(component),
    maxCollectionCount: z.union([z.string(), z.number()]).nullish()
})

export const formStructures = z.array(formStructure)

export const structure = trackable.extend({
    ID: z.string(),
    Name: z.string(),
    Template: z.string(),
    Active: z.boolean(),
    FormStructure: z.array(formStructure)
})

export const structures = paged.extend({
    Rows: z.array(structure)
})

export type Structure = z.infer<typeof structure>
export type FormStructure = z.infer<typeof formStructure>
export type FormStructures = z.infer<typeof formStructures>

export function parseFormStructure(jsonString: string): { data?: FormStructures; error?: z.ZodError } {
    try {
        const jsonData = JSON.parse(jsonString) // Attempt to parse the JSON string
        const parsedData = formStructures.parse(jsonData) // Validate parsed JSON data with Zod schema
        return { data: parsedData } // Return the validated data if successful
    } catch (error) {
        if (error instanceof z.ZodError) {
            return { error } // Return ZodError if validation fails
        } else {
            return {
                error: new z.ZodError([{ message: 'Invalid JSON string', fatal: true, code: 'custom', path: [''] }])
            }
        }
    }
}
