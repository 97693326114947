import {
    Autocomplete,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    InputLabel,
    ListItemText,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField
} from '@mui/material'
import { DISTRICT_WIDE_VALUE } from '../../../common/components/selectors/SingleSiteSelect'
import { useState } from 'react'
import { Box } from '@mui/system'
import { BoxForm } from '../../../common/components/BoxForm'
import { ROLE_TYPE } from '../types'
import SingleSiteSelectWithSwitch from '../../../common/components/selectors/SingleSiteSelectWithSwitch'
import useGroupQueryMutation, { useRoleQueryMutation } from '../queries'
import RoleSelect from '../roles/RoleSelect'

const azureGroupFieldHelperText = 'Input an Azure Group ID and press Enter'

export interface ISecurityGroupFormData {
    Type: string
    Name: string
    Description: string
    RoleID: string
    SiteID: string | null
    ExternalIdList: string[]
    type?: string // "external" | "manual"
    AvailableGroups: string[]
    Audience: string[]
    DependsOn: string[]
}

export type SecurityFormDataError<ISecurityGroupFormData> = {
    [alias in keyof ISecurityGroupFormData]: string
}

interface SecurityGroupFormProps {
    formType: 'create' | 'edit'
    headerText: string
    formData: ISecurityGroupFormData
    setFormData: React.Dispatch<React.SetStateAction<ISecurityGroupFormData>>
    formControlSection: JSX.Element
    onSubmit: () => void
    onReset: () => void
}

// Supports creating and editing a security group
// TBD: Validation
function SecurityGroupForm({
    formType = 'edit',
    headerText,
    formData,
    setFormData,
    formControlSection,
    onSubmit,
    onReset
}: SecurityGroupFormProps) {
    const { getRoleIdToRole } = useRoleQueryMutation()
    const roleIdToRole = getRoleIdToRole()

    const [externalIdInput, setExternalIdInput] = useState('')
    const [formError, setFormError] = useState({
        externalIdInput: '',
        audienceInput: '',
        dependsOnInput: ''
    })

    const [audienceInput, setAudienceInput] = useState('')

    const [dependsOnInput, setDependsOnInput] = useState('')

    const { data: groups, getGroupIdToGroup } = useGroupQueryMutation({
        TotalRecords: 0,
        TotalPages: 0,
        Offset: 0,
        PageSize: 1000, // get all groups, should never be that large since groups are manual
        Page: 1,
        Type: 'manual'
    })

    const groupIdToGroup = getGroupIdToGroup()

    function onChangeHandler(field: string, newValue: string | string[] | null) {
        // Validation TBD (refer to EditSettingsGeneral for code style)
        if (Array.isArray(formData[field]) && Array.isArray(newValue)) {
            setFormData((prev) => ({
                ...prev,
                [field]: newValue
            }))
        } else if (!Array.isArray(newValue)) {
            setFormData((prev) => ({
                ...prev,
                [field]: newValue
            }))
        }
    }

    function _onSubmit() {
        formData.SiteID = formData.SiteID || null

        const formErrors = {
            externalIdInput: formData.Type == 'external' && !formData.ExternalIdList.length ? 'Required' : '',
            dependsOnInput: formData.Type == 'manual' && !formData.DependsOn.length ? 'Required' : '',
            audienceInput:
                formData.AvailableGroups.length && !formData.Audience.length
                    ? 'Required if Available Groups is not empty'
                    : ''
        }
        setFormError(formErrors)
        if (!Object.values(formErrors).filter((v) => !!v).length) {
            onSubmit()
        }
    }

    function _onReset() {
        setFormError(
            Object.keys(formError).reduce(
                (a, key) => ({
                    ...a,
                    [key]: ''
                }),
                {} as any
            )
        )
        onReset()
    }

    return (
        <BoxForm sx={{ maxWidth: undefined }} onSubmit={_onSubmit} onReset={_onReset}>
            <h3>{headerText}</h3>
            <Box display='flex' flexDirection='row' alignItems='center' gap='8px'>
                <FormLabel id='security-group-type' required>
                    Type:
                </FormLabel>
                <RadioGroup
                    aria-disabled={formType == 'edit'}
                    aria-required
                    row
                    aria-labelledby='security-group-type'
                    value={formData.Type}
                    onChange={(ev, value) => onChangeHandler('Type', value)}
                >
                    <FormControlLabel
                        aria-required
                        value='external'
                        label='External'
                        control={<Radio required />}
                        disabled={formType == 'edit'}
                    />
                    <FormControlLabel
                        aria-required
                        value='manual'
                        label='Manual'
                        control={<Radio required />}
                        disabled={formType == 'edit'}
                    />
                </RadioGroup>
                {formType == 'create' && <FormHelperText>Group type cannot be changed</FormHelperText>}
            </Box>
            <TextField
                required
                aria-required
                data-testid='group-name'
                inputProps={{
                    maxLength: 500
                }}
                variant={'standard'}
                label={'Name'}
                name={'name'}
                value={formData?.Name || ''}
                onChange={(ev) => onChangeHandler('Name', ev.target.value)}
            />
            <TextField
                required
                aria-required
                data-testid='group-description'
                variant={'standard'}
                label={'Description'}
                name={'description'}
                value={formData?.Description || ''}
                onChange={(ev) => onChangeHandler('Description', ev.target.value)}
            />

            <SingleSiteSelectWithSwitch
                required
                disabled={formType === 'edit'}
                switchDefaultEnabled={formType === 'edit' && !!!formData?.SiteID}
                initialValue={formData?.SiteID || ''}
                onChange={(value: string) => {
                    onChangeHandler('SiteID', value || null)
                    if (
                        value &&
                        value != DISTRICT_WIDE_VALUE &&
                        formData?.RoleID &&
                        roleIdToRole?.[formData?.RoleID].type != ROLE_TYPE.SITE
                    ) {
                        onChangeHandler('RoleID', '')
                    }
                }}
                siteType={'all'}
                variant={'standard'}
            />

            <RoleSelect
                initialValue={formData?.RoleID || ''}
                onChange={(value: string) => onChangeHandler('RoleID', value)}
                variant={'standard'}
                roleType={
                    !formData?.SiteID || formData?.SiteID === DISTRICT_WIDE_VALUE ? ROLE_TYPE.TENANT : ROLE_TYPE.SITE
                }
                required
            />
            {formData.Type == 'manual' && (
                <Autocomplete
                    aria-required
                    multiple
                    freeSolo
                    options={[]}
                    autoSelect={true}
                    value={formData?.DependsOn || []}
                    onChange={(event, newValue) => {
                        let newArray: any[] = []
                        newValue.forEach((each) => {
                            if (each !== undefined && !newArray.includes(each)) {
                                newArray.push(each)
                            }
                        })
                        onChangeHandler('DependsOn', newArray)
                        setFormError((prev) => ({ ...prev, dependsOnInput: '' }))
                    }}
                    inputValue={dependsOnInput}
                    onInputChange={(event, newInputValue) => setDependsOnInput(newInputValue)}
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                variant='standard'
                                label='Depends On Group IDs *' // required
                                error={!!formError.dependsOnInput}
                                helperText={formError.dependsOnInput || azureGroupFieldHelperText}
                            />
                        )
                    }}
                />
            )}
            {formData.Type == 'external' && (
                <Autocomplete
                    aria-required
                    multiple
                    freeSolo
                    options={[]}
                    autoSelect={true}
                    value={formData?.ExternalIdList || []}
                    onChange={(event, newValue) => {
                        let newArray: any[] = []
                        newValue.forEach((each) => {
                            if (each !== undefined && !newArray.includes(each.trim())) {
                                newArray.push(each.trim())
                            }
                        })
                        onChangeHandler('ExternalIdList', newArray)
                        setFormError((prev) => ({ ...prev, externalIdInput: '' }))
                    }}
                    inputValue={externalIdInput}
                    onInputChange={(event, newInputValue) => setExternalIdInput(newInputValue)}
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                variant='standard'
                                label='External Group IDs *' // required
                                error={!!formError.externalIdInput}
                                helperText={formError.externalIdInput || 'Input external id and press Enter'}
                            />
                        )
                    }}
                />
            )}
            <Autocomplete
                aria-required
                multiple
                freeSolo
                options={[]}
                autoSelect={true}
                value={formData?.Audience || []}
                onChange={(event, newValue) => {
                    let newArray: any[] = []
                    newValue.forEach((each) => {
                        if (each !== undefined && !newArray.includes(each)) {
                            newArray.push(each)
                        }
                    })
                    onChangeHandler('Audience', newArray)
                    setFormError((prev) => ({ ...prev, audienceInput: '' }))
                }}
                inputValue={audienceInput}
                onInputChange={(event, newInputValue) => setAudienceInput(newInputValue)}
                renderInput={(params) => {
                    const value = params?.inputProps?.value
                    const required = formData.AvailableGroups.length
                    return (
                        <TextField
                            {...params}
                            variant='standard'
                            label={`Audience ${required ? '*' : ''}`}
                            error={!!formError.audienceInput}
                            helperText={formError.audienceInput || azureGroupFieldHelperText}
                        />
                    )
                }}
            />
            <FormControl variant='standard' disabled={false}>
                {groups?.Rows.length ? (
                    <>
                        <InputLabel id='available-groups-select'>Available Groups</InputLabel>
                        <Select
                            id='available-groups-select'
                            label='Available Groups'
                            multiple
                            value={formData.AvailableGroups}
                            renderValue={(groupIds) => {
                                return groupIds.map((id) => groupIdToGroup?.[id].Name || '').join(', ')
                            }}
                            onChange={(e) => {
                                onChangeHandler('AvailableGroups', e.target.value)
                                setFormError((prev) => ({ ...prev, audienceInput: '' }))
                            }}
                        >
                            {groups?.Rows.map((group, idx) => (
                                <MenuItem key={group.ID} value={group.ID}>
                                    <Checkbox checked={formData.AvailableGroups.includes(group.ID)} />
                                    <ListItemText primary={group.Name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </>
                ) : (
                    <TextField
                        disabled
                        label='Available Groups'
                        value='No manual groups available for current site'
                        variant='standard'
                    />
                )}
            </FormControl>
            {formControlSection}
        </BoxForm>
    )
}

export default SecurityGroupForm
