import { Box, Button, DialogContentText, TextField, Tooltip } from '@mui/material'
import { BoxForm } from './BoxForm'
import ForwardIcon from '@mui/icons-material/Forward'
import DeleteIcon from '@mui/icons-material/Delete'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { useState } from 'react'

interface CustomFieldsFormProps {
    title: string
    isSaveDisabled?: boolean
    value: Record<string, any>
    valueOnChangeHandler: (key: string, value: string) => void
    valueOnAddHandler: (key: string, value: string, successCallback: () => void) => void
    valueOnRemoveHandler: (key: string) => void
    valueErrors?: Record<string, any>
    customFieldKeyErrorMsg?: string
    customFieldValueErrorMsg?: string
    onSubmit?: () => void
    onReset?: () => void
}

// key value pair
export function CustomFieldsForm({
    title,
    isSaveDisabled = false,
    value,
    valueOnChangeHandler,
    valueOnAddHandler,
    valueOnRemoveHandler,
    valueErrors,
    customFieldKeyErrorMsg,
    customFieldValueErrorMsg,
    onSubmit,
    onReset
}: CustomFieldsFormProps) {
    const [newCustomFieldKey, setNewCustomFieldKey] = useState('')
    const [newCustomFieldValue, setNewCustomFieldValue] = useState('')

    return (
        <BoxForm sx={{ gap: '1vh', maxWidth: '100%' }} onReset={onReset} onSubmit={onSubmit}>
            <DialogContentText>{title}</DialogContentText>
            <div
                key={'newCustomField'}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}
            >
                <TextField
                    error={!!customFieldKeyErrorMsg}
                    helperText={customFieldKeyErrorMsg}
                    value={newCustomFieldKey}
                    name={newCustomFieldKey}
                    size='small'
                    label='key'
                    variant='outlined'
                    onChange={(e) => setNewCustomFieldKey(e.target.value)}
                />
                <ForwardIcon style={{ alignSelf: 'center' }} />
                <TextField
                    error={!!customFieldValueErrorMsg}
                    helperText={customFieldValueErrorMsg}
                    value={newCustomFieldValue}
                    name={newCustomFieldValue}
                    size='small'
                    label='value'
                    variant='outlined'
                    onChange={(e) => setNewCustomFieldValue(e.target.value)}
                />
                <AddCircleIcon
                    className='create-icon'
                    style={{ alignSelf: 'center' }}
                    onClick={() => {
                        valueOnAddHandler(newCustomFieldKey, newCustomFieldValue, () => {
                            setNewCustomFieldKey('')
                            setNewCustomFieldValue('')
                        })
                    }}
                />
            </div>
            <div className='drawer-seperator' style={{ marginTop: '2vh', marginBottom: '2vh' }}></div>
            {value &&
                Object.keys(value).map((customFieldKey) => (
                    <div
                        key={customFieldKey}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}
                    >
                        <TextField
                            value={customFieldKey}
                            name={customFieldKey}
                            size='small'
                            label='key'
                            variant='outlined'
                            inputProps={{
                                readOnly: Boolean(true),
                                disabled: Boolean(true)
                            }}
                        />
                        <ForwardIcon style={{ alignSelf: 'center' }} />
                        <Tooltip title={value[customFieldKey].length > 40 ? value[customFieldKey] : undefined}>
                            <TextField
                                value={value[customFieldKey]}
                                error={!!valueErrors?.[customFieldKey]}
                                helperText={valueErrors?.[customFieldKey]}
                                name='value'
                                size='small'
                                label='value'
                                variant='outlined'
                                onChange={(e) => {
                                    valueOnChangeHandler(customFieldKey, e.target.value)
                                }}
                            />
                        </Tooltip>
                        <DeleteIcon
                            className='create-icon'
                            style={{ alignSelf: 'center' }}
                            onClick={() => valueOnRemoveHandler(customFieldKey)}
                        />
                    </div>
                ))}
            <Box className='box-row'>
                {onReset && (
                    <Button variant='text' color='primary' type='reset'>
                        Reset Changes
                    </Button>
                )}
                {onSubmit && (
                    <Button disabled={isSaveDisabled} variant='contained' color='primary' type='submit'>
                        Save
                    </Button>
                )}
            </Box>
        </BoxForm>
    )
}

export default CustomFieldsForm
