import GroupIcon from '@mui/icons-material/Group'
import React, { useEffect, useState } from 'react'
import { useStateWithStorage } from '../../../common/storage.service'
import { guessErrorMessage } from '../../../helpers/guessErrorMessage'
import { GridColDef } from '@mui/x-data-grid'
import {
    Button,
    FormControl,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    Switch,
    TextField
} from '@mui/material'
import { CellLine, CellWrapper, TrackableCell, TwoLinesCell } from '../../grid/cells/GridCells'
import { ManagePATokens } from './ManagePATokens'
import { AdminPanelSettings } from '@mui/icons-material'
import axios from 'axios'
import { notify } from '../../../helpers'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import { useNavigate } from 'react-router-dom'
import { CreateAccount } from './CreateAccount'
import { AddButton } from '../../../common/components'
import { useIdentity } from '../../auth/atoms'
import PageContainerWithHeader from '../../../common/components/PageContainerWithHeader'
import { MenuLightCell } from '../../grid/cells/MenuLightCell'
import { AccountsQuery, useAccounts } from '../queries'
import { Account } from '../types'
import { defaultPageQuery } from '../../../common/react-query'
import { ManageAccountsAPI } from '../../../common/constants'
import { DataGridBase } from '../../grid/DataGridBase'

const defaultQuery = { ...defaultPageQuery, Active: null, Search: '' }

export const AccountsGrid = () => {
    const identity = useIdentity()
    const [query, setQuery] = useStateWithStorage<AccountsQuery>('accounts-grid-query-v6', defaultQuery)
    const [selected, setSelected] = useState<Account | undefined>(undefined)
    const [pat, setPat] = useState(false)
    const [create, setCreate] = useState(false)
    const navigate = useNavigate()

    const q = useAccounts(query)
    useEffect(() => {
        // @ts-ignore
        q.refetch({ options: query })
    }, [query])

    const handleActiveChange = (accountID: string, currentState: boolean) => {
        if (currentState) {
            axios
                .delete(`${ManageAccountsAPI}/${accountID}`)
                .then(() => q.refetch())
                .catch((e) => notify(guessErrorMessage(e)))
        } else {
            axios
                .patch(`${ManageAccountsAPI}/${accountID}/restore`)
                .then(() => q.refetch())
                .catch((e) => notify(guessErrorMessage(e)))
        }
    }

    const menuItems = (account: Account) => {
        return (onClose: () => void) => {
            return [
                account.Active && (
                    <MenuItem
                        key={'1'}
                        onClick={() =>
                            navigate(
                                `/user-management/accounts/${account.ID}?${new URLSearchParams(
                                    window.location.search
                                ).toString()}`
                            )
                        }
                    >
                        <ListItemIcon>
                            <GroupIcon />
                        </ListItemIcon>
                        <ListItemText>Groups</ListItemText>
                    </MenuItem>
                ),

                account.Active && (identity.IsAdmin || identity.Email === account.Email) && (
                    <MenuItem
                        key={'2'}
                        onClick={() => {
                            setPat(true)
                            setSelected(account)
                            onClose()
                        }}
                    >
                        <ListItemIcon>
                            <AdminPanelSettings />
                        </ListItemIcon>
                        <ListItemText>Manage Tokens</ListItemText>
                    </MenuItem>
                ),

                <MenuItem
                    key={'3'}
                    onClick={() => {
                        handleActiveChange(account.ID, account.Active)
                        onClose()
                    }}
                >
                    <ListItemIcon>
                        <Switch checked={account.Active} />
                    </ListItemIcon>
                    <ListItemText>{account.Active ? 'Deactivate' : 'Activate'}</ListItemText>
                </MenuItem>
            ]
        }
    }

    const columns: GridColDef[] = [
        {
            field: 'Email',
            headerName: 'Email/ID',
            flex: 2,
            sortable: true,
            renderCell: (params) => <TwoLinesCell l1={`${params.row.Email}`} l2={params.row.ID} />
        },
        {
            field: 'UpdatedAt',
            headerName: 'Updated/by',
            width: 300,
            sortable: true,
            renderCell: (params) => <TrackableCell trackable={params.row} ownerID={params.row.ID} />
        },
        {
            field: 'ExternalGroups',
            headerName: 'External Groups',
            flex: 3,
            sortable: false,
            renderCell: (params) => (
                <CellWrapper>
                    <CellLine>{params.row.ExternalGroups?.join(', ')}</CellLine>
                </CellWrapper>
            )
        },
        {
            field: 'Menu',
            headerName: '...',
            headerAlign: 'center',
            filterable: false,
            hideable: false,
            width: 80,
            sortable: false,
            renderCell: (params) => <MenuLightCell itemsFactory={menuItems(params.row)} />
        }
    ]

    return (
        <PageContainerWithHeader
            title='Accounts'
            topRightElement={
                identity.IsAdmin ? <AddButton title='ADD ACCOUNT' func={() => setCreate(true)} /> : undefined
            }
        >
            <div className={'row'}>
                <div className={'col-md-8'}>
                    <TextField
                        style={{ flex: '1' }}
                        onChange={(e) => setQuery({ ...query, Search: e.target.value })}
                        value={query.Search}
                        label={'Search'}
                        placeholder='Search'
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position={'start'}>
                                    <SearchIcon></SearchIcon>
                                </InputAdornment>
                            )
                        }}
                        variant={'outlined'}
                    />
                </div>
                <div className={'col-md-4'}>
                    <FormControl sx={{ width: '100%' }}>
                        <InputLabel>Active</InputLabel>
                        <Select
                            label={'State'}
                            id='demo-simple-select'
                            value={query.Active === true ? 'Active' : query.Active === false ? 'Deactivated' : 'Any'}
                            onChange={(e) =>
                                setQuery({
                                    ...query,
                                    Active:
                                        e.target.value === 'Active'
                                            ? true
                                            : e.target.value === 'Deactivated'
                                              ? false
                                              : null
                                })
                            }
                        >
                            <MenuItem value={'Any'}>Any</MenuItem>
                            <MenuItem value={'Active'}>Active</MenuItem>
                            <MenuItem value={'Deactivated'}>Deactivated</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
            <Button onClick={() => q.refetch()}>Refresh</Button>
            <Button onClick={() => setQuery(defaultQuery)}>Reset Filters</Button>

            {q.isLoading && <div>Loading...</div>}
            {q.isError && <div>Error loading accounts: {guessErrorMessage(q.error)}</div>}

            {q.isSuccess && (
                <DataGridBase
                    // rowHeight={100}
                    columns={columns}
                    state={q.data}
                    setQuery={setQuery}
                    getRowClassName={(params) => (params.row.Active ? 'active' : 'inactive')}
                />
            )}

            {pat && selected && (
                <ManagePATokens
                    account={selected}
                    open={pat}
                    onClose={() => {
                        setSelected(undefined)
                        setPat(false)
                    }}
                />
            )}
            {create && (
                <CreateAccount
                    onClose={() => {
                        setCreate(false)
                        q.refetch()
                    }}
                />
            )}
        </PageContainerWithHeader>
    )
}
