import React from 'react'
import { makeStyles } from '@mui/styles'
import DocumentUpload from '../../upload/DocumentUpload'
import { Format, is, notify, sanitizePathString, validateUUID } from '../../../../helpers'
import { documentService } from '../document.service'
import { DocumentRow } from './DocumentRow'
import { guessErrorMessage } from '../../../../helpers/guessErrorMessage'
import { useCurrentSite } from '../../../auth/atoms'

export default function DocumentUploader({
    documentsToUpload,
    setDocumentsToUpload,
    chosenBulkTags,
    errors,
    chosenSites,
    department_id,
    setDocuments,
    chosenFolder,
    privacyLevel
}) {
    const classes = useStyles()
    const currentSite = useCurrentSite()

    const removeDocument = (document) => {
        const documentId = is.object(document) ? document?.id : document
        if (validateUUID(documentId)) {
            setDocumentsToUpload((prev) => prev.filter((doc) => doc.id !== documentId))
        }
    }

    const setUploadStatus = (document, status) => {
        setDocumentsToUpload((prev) => {
            const ref = [...prev]
            const index = ref.findIndex((x) => x.id === (document?.id || document))
            if (index > -1) {
                const found = ref?.[index] || {}
                ref.splice(index, 1, { ...found, uploadState: status })
            }
            return ref
        })
    }
    const saveDocument = async (document) => {
        try {
            if (errors.sites) {
                notify('Whoops! Please select the sites this document will be shared with', 'error')
            }
            document.path =
                (chosenFolder.length && chosenFolder[0].path ? chosenFolder[0].path + '.' : '') +
                sanitizePathString(document.id)
            document.sites = Format.objectsToIds(chosenSites)
            document.department_id = currentSite.Type === 'department' ? currentSite.ID : null
            document.type = 'document'
            document.tags = Format.objectsToIds(chosenBulkTags)
            document.privacyLevel = privacyLevel
            document.parentId = chosenFolder.length && chosenFolder[0].id ? chosenFolder[0].id : ''
            setUploadStatus(document, { failed: false, loading: true, success: false, message: '' })
            const response = await documentService.createDocument(currentSite.ID, document)
            if (setDocuments && is.document(response)) {
                setDocuments((prev) => [{ ...response, highlight: true }, ...prev])
                setUploadStatus(document, {
                    failed: false,
                    loading: false,
                    success: true,
                    message: 'Success!',
                    failCount: 0
                })
            }
            return response
        } catch (e) {
            let message = `Document Upload Failed - ${guessErrorMessage(e)}`
            setUploadStatus(document, {
                failed: true,
                loading: false,
                success: false,
                message,
                failCount: document?.uploadState?.failCount ? document?.uploadState?.failCount + 1 : 1
            })
            return Promise.reject({ message })
        }
    }

    return (
        <div className='mb10'>
            <div className={classes.bottomcard}>
                <h1>Upload Documents</h1>
            </div>
            <div className='flex-column'>
                {documentsToUpload && documentsToUpload.length > 0 && (
                    <div className='document-gallery'>
                        {documentsToUpload.map((document) => (
                            <DocumentRow
                                key={document.id + document.filename}
                                document={document}
                                handleSave={saveDocument}
                                handleRemove={removeDocument}
                                error={errors.sites || (errors.privacyLevel && chosenFolder && !chosenFolder.length)}
                            />
                        ))}
                    </div>
                )}
                <DocumentUpload setDocuments={setDocumentsToUpload} />
            </div>
        </div>
    )
}

const useStyles = makeStyles({
    textfield: {
        width: '98%',
        margin: '1vh'
    },
    options: {
        marginTop: '2vh'
    },
    dialogStyles: {
        minWidth: '25vw'
    },
    text: {
        maxWidth: '25vw'
    },
    bottomcard: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row'
    },
    auto: {
        width: '40%',
        marginTop: '1vh',
        marginBottom: '1vh'
    },
    siteselect: {
        width: '100%'
    }
})
