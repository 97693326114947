import { z } from 'zod'
import { paged, trackable, uuidSchema } from '../../../common/react-query'

// export type Json = { [key: string]: Json } | Json[]
// export const jsonSchema: z.ZodType<Json> = z.lazy(() => z.union([z.array(jsonSchema), z.record(jsonSchema)]))

export enum SettingsType {
    SiteSettingsCustom = 'site-settings-custom',
    Edsby = 'edsby',
    EdsbySecrets = 'edsby-secrets',
    Facebook = 'facebook',
    FacebookSecrets = 'facebook-secrets',
    Outlook = 'outlook',
    OutlookSecrets = 'outlook-secrets',
    GoogleCalendar = 'google-calendar',
    GoogleCalendarSecrets = 'google-calendar-secrets',
    Instagram = 'instagram'
}

export function getAllIntegrationTypes() {
    return [SettingsType.Edsby, SettingsType.Facebook, SettingsType.Outlook, SettingsType.GoogleCalendar]
}

function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

export function getPrettySettingsType(type: SettingsType) {
    return type.split('-').map(capitalizeFirstLetter).join(' ')
}

const settingsDto = z.object({
    Name: z.string(),
    Description: z.string(),
    Type: z.nativeEnum(SettingsType),
    Sites: z.array(z.string()).nullish(),
    Data: z.any(),
    Public: z.boolean().nullish(),
    Active: z.boolean().nullish()
})

export const settings = trackable.extend(settingsDto.shape)

export type Settings = z.infer<typeof settings>
export type SettingsDTO = z.infer<typeof settingsDto>

export const settingsQuerySchema = paged.extend({
    Rows: z.array(settings)
})
