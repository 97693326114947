import _ from 'lodash'

export const mergeExistingObjectFields = (source, mergeTo) => {
    const dereferenced = _.cloneDeep(mergeTo)
    for (const key in source) {
        if (key in dereferenced) {
            dereferenced[key] = source[key]
        }
    }
    return dereferenced
}
