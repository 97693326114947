import { ContentExplorer } from './ContentExplorer'
import { ContentType } from '@/pkgs/content/types'
import { useAppContext, useSitesForAccount } from '@/pkgs/auth/atoms'
import PageContainerWithHeader from '@/common/components/PageContainerWithHeader'
import { Button } from '@mui/material'
import React, { useState } from 'react'
import { initiateFileDownload } from '@/common/initiateFileDownload'
import { BASE } from '@/common/constants'
import { ImportForm } from '@/pkgs/content/explorer/ImportForm'

export function ContentExplorerPage() {
    const sitesForAccount = useSitesForAccount()
    const appContext = useAppContext()
    const [query, setQuery] = useState({})
    const [paused, setPaused] = useState(false)

    const [importOpen, setImportOpen] = useState(false)

    const exportResults = (pageSize?: number) => {
        setPaused(true)
        setTimeout(() => setPaused(false), 3000)

        const queryParams = pageSize ? { ...query, pageSize, page: 1 } : query
        initiateFileDownload(`${BASE}/api/v2/content/export/xlsx`, {
            ...queryParams,
            siteId: appContext.currentSiteID
        })
    }

    return (
        <PageContainerWithHeader
            title={'Content'}
            topRightElement={
                <>
                    <Button
                        disabled={paused}
                        sx={{ marginRight: 2 }}
                        variant='contained'
                        color='success'
                        onClick={() => {
                            setImportOpen(true)
                        }}
                    >
                        Import
                    </Button>
                    <Button
                        disabled={paused}
                        variant='contained'
                        color='primary'
                        onClick={() => {
                            exportResults()
                        }}
                    >
                        Export Current Page
                    </Button>
                </>
            }
        >
            <ContentExplorer
                contentTypes={[
                    ContentType.Page,
                    ContentType.News,
                    ContentType.Event,
                    ContentType.Alert,
                    ContentType.Fragment
                ]}
                structureID={null}
                sites={sitesForAccount.map((site) => site.ID)}
                onQueryChange={setQuery}
            />

            {importOpen && <ImportForm open={importOpen} onClose={() => setImportOpen(false)} />}
        </PageContainerWithHeader>
    )
}
