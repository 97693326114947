import React from 'react'
import { ExternalNodeType } from './ExternalNode_v2'
import { DndContext } from 'react-dnd'
import { Get, is, validateUUID } from '../../../helpers'
// TODO => Departments: fix ts
import SortableTree from 'ndanvers-react-sortable-tree'
import { getPrimacyValue } from '../Navigation'
import { NavigationType } from '../types'
import { ContentType } from '../../content/types'

export const generateClassNames = (node: Record<string, any>, isDisabled?: boolean, canManagePrimary?: boolean) => {
    try {
        let className = 'node-option-container'
        if (getPrimacyValue(node)) {
            className += ' Navigation-Primary'
            if (!canManagePrimary) {
                className += ' Navigation-Disabled Navigation-DisabledTree'
            }
        }
        // Implemented in Context Menu instead of Tree Node
        // if (node?.content?.sites?.length > 1) {
        //     className += ' Navigation-SharedContent'
        // }
        if (validateUUID(node?.DepartmentId)) {
            className += ' Navigation-TypeDepartment'
        } else if (node?.type === NavigationType.External) {
            className += ' Navigation-TypeExternal'
        } else {
            className += ' Navigation-TypePage'
        }
        if (isDisabled) {
            className += ' Navigation-Disabled Navigation-DisabledTree'
        }
        // NST-543: Navigation-DraftContent icon is overtaking the MissingSiteOverlap icon
        // This change is due to the Missing Overlap warning being more important than Draft, and icon space being limited.
        // Future us: We should display this differently than an icon.
        if (!node?.HasSiteIdOverlap) {
            className += ' Navigation-MissingSiteOverlap'
        } else if (
            !Boolean(node?.published) &&
            !Get.isPublished(node?.content) &&
            node.type !== ContentType.ExternalLink
        ) {
            className += ' Navigation-DraftContent'
        }
        return className
    } catch (e) {
        console.error(node)
        return ''
    }
}
export const generateNodeProps = (
    nodeWrapper: { node: any },
    buttons?: (v: { node: any }) => any[],
    isDisabled?: boolean,
    canManagePrimary?: boolean
) => {
    const nodeProps = {
        className: generateClassNames(nodeWrapper.node, isDisabled, canManagePrimary)
    }
    if (buttons && is.func(buttons)) {
        nodeProps['buttons'] = buttons(nodeWrapper)
    }
    return nodeProps
}

// TreeBase contains the generateNodeProps function
//  - Most changes that should be represented on the tree node itself (i.e, styling, iconography, buttons, etc) should be done within the
//    generateNodeProps function
//  - dndType may sometimes be required depending on the need for unique trees.
//   (e.g) Tree 1 and 2 are on the same screen, but need to have some unique behaviour between their nodes.
//  - Unspecified props given to TreeBase will be passed to the SortableTree component (...rest)
interface TreeBaseProps {
    dndType?: string
    treeData: any[]
    setTreeData: (v: any[]) => void
    disabled?: boolean
    canManagePrimary?: boolean
    buttons?: (any) => React.ReactNode[]
}

export const TreeBase = ({
    treeData,
    setTreeData,
    canManagePrimary,
    disabled,
    buttons,
    dndType = ExternalNodeType,
    ...rest
}: TreeBaseProps) => {
    const generateProps = (nodeWrapper) => {
        return generateNodeProps(nodeWrapper, buttons, disabled, canManagePrimary)
    }

    const canDragNode = (baseNode) => {
        // Doesn't need to reference __setPrimacy
        // canDragNode is used as a permission check, if a content isPrimary, the source of truth for that is the content model
        const isDepartmentOrPrimaryNode = Get.isPrimary(baseNode?.node?.content) || baseNode?.node?.DepartmentId
        if (isDepartmentOrPrimaryNode && !canManagePrimary) {
            return false
        }
        return true
    }

    const canNodeHaveChildren = (node) => {
        return node?.DepartmentId == null
    }

    return (
        <DndContext.Consumer>
            {({ dragDropManager }) => (
                <SortableTree
                    {...rest}
                    dragDropManager={dragDropManager}
                    treeData={treeData}
                    onChange={setTreeData}
                    canNodeHaveChildren={canNodeHaveChildren}
                    canDrag={canDragNode}
                    // onMoveNode={(n) => console.log(n)}
                    generateNodeProps={generateProps}
                    dndType={dndType}
                    shouldCopyOnOutsideDrop={false}
                />
            )}
        </DndContext.Consumer>
    )
}

const sx = {
    '& rst__rowContents': {
        borderRadius: '0px 5px 5px 0px',
        '-webkit-box-shadow': '0',
        boxShadow: '0'
    },
    '& rst__moveHandle': {
        borderRadius: '5px 0px 0px 5px',
        '-webkit-box-shadow': '0',
        boxShadow: '0'
    }
}
