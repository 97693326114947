import { Button } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { getPinnedNewsColumns } from '../../grid/cells/columnsFactory'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import usePinnedNewsQueryMutation from '../../../common/query-hooks/usePinnedNewsQueryMutation'
import { useAppContext, useCurrentSite } from '../../auth/atoms'
import { EntityScopeEnum } from '../../auth/entityScope'

// TODO => Pinned news needs to utilize floats as previous version did to allow for:
//  -> Site permission users to be able to change permissions properly - currently it attempts to save all, which means
//     that if I have site permissions and there are shared news, it attempts to, 401's and fails to updates, which doubles up prioritization
//     (e.g, shared news at priority 8 was attempted to be updated to 7, as a site news took its place at 8 - it fails, now we have two priority 8.)
//  -> performance cost reduction
export const PinnedNews = ({ setMenuElement }) => {
    const currentSite = useCurrentSite()
    const evaluators = useAppContext()

    const basicDisable = useCallback((row) => {
        return !evaluators.action(row, 'update')
    }, [])

    const applyBasicDisable = useCallback(
        (news) => {
            if (!news || !news.length) return []
            return news.map((item) => ({ ...item, disabled: basicDisable(item) })) || []
        },
        [basicDisable]
    )

    const {
        pinnedNewsData: pinnedNewsDataRaw,
        isLoading,
        handleSavePinnedOrder
    } = usePinnedNewsQueryMutation(currentSite?.ID)
    const [pinnedNews, setPinnedNews] = useState<any[]>(applyBasicDisable(pinnedNewsDataRaw))
    const [reorderedPins, setReorderedPins] = useState([])

    useEffect(() => {
        setPinnedNews(applyBasicDisable(pinnedNewsDataRaw))
    }, [applyBasicDisable, basicDisable, pinnedNewsDataRaw])

    // TODO =>
    //  If user lacks shared permission, they should only be able to swap items
    //  e.g if I have 2 & 5, I can swap 2 -> 5, and 5 -> 2
    //  If there is nothing below me that I can edit, I could go to 'last'
    //  if there is nothing above me that I can edit, I could go to 'first'

    // TODO =>
    //  Move up twice reverts, seems like I'm looking at wrong source array and position is unchanging
    //  Should check how much work a "if I have no editable above me, can I become -1"
    //  See if I can pre-compute the indexes so that its not quite as sluggish on move.
    const handleReorder = useCallback((newPinnedNews) => {
        setPinnedNews(newPinnedNews)
        setReorderedPins(newPinnedNews)
    }, [])

    const _handleSavePinnedOrder = () => {
        handleSavePinnedOrder(reorderedPins, () => setReorderedPins([]))
    }

    const rowHeight = 78
    const [gridHeight, setGridHeight] = useState(600)
    useEffect(() => {
        if (pinnedNews && Array.isArray(pinnedNews)) {
            setGridHeight(rowHeight * pinnedNews.length + 57)
        }
    }, [pinnedNews])

    return (
        <>
            {pinnedNews.length > 0 ? (
                <Fragment>
                    <div className='flex-row'>
                        <Button
                            disabled={!evaluators.entityScopeAny(EntityScopeEnum.News)}
                            variant='outlined'
                            color='primary'
                            style={{ margin: '0 0 20px auto' }}
                            onClick={_handleSavePinnedOrder}
                        >
                            Save Order
                        </Button>
                    </div>

                    <div style={{ height: gridHeight, width: '100%' }}>
                        {/* TODO => Disable if missing permission */}
                        <DataGrid
                            columns={getPinnedNewsColumns({
                                pinnedNews,
                                setMenuElement,
                                handleReorder
                            })}
                            // columnTypes={getColumnsTypes() as any}
                            rows={pinnedNews}
                            disableRowSelectionOnClick
                            rowHeight={rowHeight}
                            hideFooter={true}
                            loading={isLoading}
                        />
                    </div>
                </Fragment>
            ) : (
                <p>There is no pinned news for this site</p>
            )}
        </>
    )
}
