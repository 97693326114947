import React from 'react'
import { Grid } from '@mui/material'
import TextField from '@mui/material/TextField'

interface MetaEditorProps {
    value: Record<string, string>
    onChange: (value: Record<string, string>) => void
    disabled?: boolean
}
export const MetaEditor = ({ value, onChange, disabled = false }: MetaEditorProps) => {
    value = addEmpty(value)

    const handleMetaChange = (prevKey: string, key: string, newValue: string) => {
        const newMeta = {}
        if (key === '' && newValue === '') {
            delete value[prevKey]
            onChange(value)
            return
        }
        for (const k in value) {
            if (k === prevKey) {
                newMeta[key] = newValue
            } else {
                newMeta[k] = value[k]
            }
        }
        if (Object.keys(value).every((s) => s !== '')) {
            value[''] = ''
        }

        onChange(removeEmpty(newMeta))
    }

    return (
        <>
            {Object.keys(value).map((key, index) => {
                const existingKeys = Object.keys(value)
                existingKeys.splice(index, 1)
                return (
                    <KeyValue
                        keyName={key}
                        value={value[key]}
                        onChange={handleMetaChange}
                        disabled={disabled}
                        key={key}
                        existingKeys={existingKeys}
                    />
                )
            })}
        </>
    )
}

const KeyValue = ({
    keyName,
    value,
    onChange,
    disabled,
    existingKeys = []
}: {
    keyName: string
    value: string
    onChange: (prevKey: string, key: string, value: string) => void
    disabled?: boolean
    existingKeys?: string[]
}) => {
    const [currentKey, setCurrentKey] = React.useState(keyName)
    const [currentValue, setCurrentValue] = React.useState(value)
    const [error, setError] = React.useState(false)

    React.useEffect(() => {
        setError(existingKeys.includes(currentKey))
    }, [currentKey, existingKeys])

    const newKeyValuePairIsValid = () => {
        if (keyName === '' && currentKey && currentValue && !error) {
            setTimeout(() => {
                setCurrentKey('')
                setCurrentValue('')
                setError(false)
            }, 100)
            return true
        } else if (keyName === '') {
            return false
        }

        return (
            (currentKey === '' && currentValue === '') ||
            (currentKey !== '' && (keyName !== currentKey || value !== currentValue) && !error)
        )
    }

    return (
        <Grid
            container
            rowSpacing={0}
            columnSpacing={2}
            sx={{
                my: 1
            }}
        >
            <Grid item xs={6}>
                <TextField
                    size='small'
                    label={'Key'}
                    value={currentKey}
                    onChange={(e) => setCurrentKey(e.target.value)}
                    onBlur={() => {
                        setError(existingKeys.includes(currentKey) || (currentKey === '' && keyName !== ''))
                        newKeyValuePairIsValid() && onChange(keyName, currentKey, currentValue)
                    }}
                    disabled={disabled}
                    fullWidth
                    error={error}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    size='small'
                    label={'Value'}
                    value={currentValue}
                    onChange={(e) => setCurrentValue(e.target.value)}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            event.preventDefault()
                            setError(currentKey === '' && keyName === '' && currentValue !== '')
                            newKeyValuePairIsValid() && onChange(keyName, currentKey, currentValue)
                        }
                    }}
                    onBlur={() => {
                        setError(currentKey === '' && keyName === '' && currentValue !== '')
                        newKeyValuePairIsValid() && onChange(keyName, currentKey, currentValue)
                    }}
                    disabled={disabled}
                    fullWidth
                />
            </Grid>
        </Grid>
    )
}

function removeEmpty(obj: Record<string, string>) {
    return Object.fromEntries(Object.entries(obj).filter(([k, v]) => k !== '' && v !== ''))
}

function addEmpty(obj: Record<string, string>) {
    return { ...obj, '': '' }
}
