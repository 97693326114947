// @ts-nocheck
import { walk } from 'ndanvers-react-sortable-tree'
import { is, sanitizePathString, validateUUID } from '../../helpers'
import { Navigation } from './types'
import { uuidNil } from '../../common/constants'

// TODO => Remove functionality that has been deprecated and/or is not being used any longer
//  There are legacy pieces of code here such as references to radioValue === "custom" (doesPatchedNodesContainSelf) which no longer have purpose
//  and are holdovers from the original navigation system.

export function toContentModel(obj) {
    let { id, ContentId, ...rest } = obj || {}
    if ((!id || !validateUUID(id)) && validateUUID(ContentId)) {
        id = ContentId
    }
    const content = { id, ...rest }
    delete content.updated
    delete content.created
    delete content.deleted
    return content
}

export function toNavigationModel(obj) {
    const { id, ContentId, SiteId, DepartmentId, path, type, visible, active, treeIndex, content, ...rest } = obj || {}
    return {
        id,
        ContentId,
        SiteId,
        DepartmentId,
        path,
        type,
        visible,
        active,
        treeIndex,
        content: toContentModel({ ...content, ...rest, ContentId })
    }
}

const getNodeKey = ({ treeIndex }) => treeIndex

function toTreeModels(records) {
    try {
        for (let i = 0; i < records.length; i++) {
            records[i] = toTreeModel(records[i])
        }
        return records
    } catch (e) {
        return []
    }
}

function toTreeModel(record) {
    try {
        record.title = record?.content?.title
    } catch (e) {
        console.log('catch', { e, record })
        record = {}
    }
    return record
}

export function BuildTreePaths(tree) {
    const paths = {
        references: {},
        createContent: [],
        updateContent: []
    }
    const processed = recursivelySetPath({ ContentId: '', children: tree }, undefined, (iter) => {
        if (iter?.ContentId === uuidNil) {
            paths.createContent.push(iter)
        } else if (iter?.__setPrimacy != null && iter.__setPrimacy !== iter?.content?.settings?.isPrimary) {
            if (!iter?.content?.settings) {
                iter.content.settings = {}
            }
            iter.content.settings['isPrimary'] = iter.__setPrimacy
            paths.updateContent.push(iter)
        } else if (iter?.ContentId) {
            paths.references[iter.ContentId] = iter
        }
    })
    return {
        tree: is.objectArray(processed?.children) ? processed?.children : [],
        ...paths
    }
}

export function recursivelySetPath(iter, previousIter, optionalFunc) {
    iter.path = (previousIter?.path ? previousIter.path + '.' : '') + sanitizePathString(iter.ContentId)
    if (is.func(optionalFunc)) {
        optionalFunc(iter, previousIter)
    }
    if (!iter?.children?.length) {
        return iter
    }
    for (let i = 0; i < iter?.children.length; i++) {
        iter.children[i] = recursivelySetPath({ ...iter?.children[i], treeIndex: i }, iter, optionalFunc)
    }
    return iter
}

interface TreeNode {
    title: string
    path: string
    parent_id: string
    children?: Navigation[]
    expanded?: boolean
}

interface NavigationTypesForUI {
    orphaned: any[]
    unassigned: any[]
    nested: any
}

const createBaseNavigationTypes = (): NavigationTypesForUI => {
    return { nested: [], orphaned: [], unassigned: [] }
}
const getKeyDefault = (node) => node.path
const getParentKeyDefault = (node) => node.ParentId

interface NestTreeDataParams {
    flatData: TreeNode[]
    getKey?: (TreeNode) => string
    getParentKey?: (TreeNode) => string
    rootKey?: string
}

// TODO => General optimization
function createTreeFromFlatData({ flatData, getKey, getParentKey, rootKey = '0' }: NestTreeDataParams) {
    getKey = getKey || getKeyDefault
    getParentKey = getParentKey || getParentKeyDefault
    if (!flatData) {
        return createBaseNavigationTypes()
    }

    const childrenToParents = {}
    const orphanedTree = []
    const unassigned = []

    for (const child of flatData) {
        const parentKey = getParentKey(child) //ParentId
        // TODO => Departments / Post Departments: With changes to navigation that allow inactive nodes on the tree,
        // this band-aid should be removed
        if (child.path.substring(0, 2) === '0.' && child.type === 'external_link') {
            unassigned.push(child)
            continue
        }

        if (parentKey in childrenToParents) {
            childrenToParents[parentKey].push(child)
        } else {
            childrenToParents[parentKey] = [child]
        }
    }

    if (!(rootKey in childrenToParents)) {
        return createBaseNavigationTypes()
    }

    const traverse = (parent) => {
        const parentKey = getKey?.(parent) //node.path
        if (parentKey in childrenToParents) {
            return {
                ...parent,
                children: childrenToParents[parentKey]
                    .map((child) => traverse(child))
                    .sort((a, b) => {
                        if (a.treeIndex === b.treeIndex) {
                            return a.title > b.title ? 1 : -1
                        } else {
                            return a.treeIndex - b.treeIndex
                        }
                    })
            }
        }
        return { ...parent }
    }
    const nestedTree = childrenToParents[rootKey]
        .map((child) => traverse(child))
        .sort((a, b) => a.treeIndex - b.treeIndex)

    const tree = {}
    walk({
        treeData: nestedTree,
        getNodeKey,
        ignoreCollapsed: false,
        callback: (nodeInfo) => {
            tree[nodeInfo.node.id] = true
        }
    })
    // TODO => Departments - review
    for (const data of flatData) {
        if (!(data.id in tree)) {
            if (data.type === 'external_link' && unassigned.indexOf(data) < 0) {
                unassigned.push(data)
            } else if (data.type !== 'external_link') {
                orphanedTree.push(data)
            }
        }
    }
    return { nested: nestedTree, orphaned: orphanedTree, unassigned }
}

function Nest(records: TreeNode[]): NavigationTypesForUI {
    if (!is.objectArray(records)) {
        return createBaseNavigationTypes()
    }
    return createTreeFromFlatData({
        flatData: records,
        getKey: (node) => node.path,
        getParentKey: (node) => node.ParentId,
        rootKey: ''
    })
}

function expandAll(records: TreeNode[]) {
    for (let i = 0; i < records.length; i++) {
        if (!records?.[i]) continue
        records[i]['expanded'] = true
        if (is.objectArray(records[i]?.children)) {
            expandAll(records[i].children)
        }
    }
    return records
}

export const navigationHelpers = {
    createTreeFromFlatData,
    Nest,
    // processTreeData,
    toTreeModel,
    toTreeModels,
    BuildTreePaths,
    toNavigationModel
}

// PREVIOUS VERSIONS
/**
 * @name             processTreeData
 * @param {Object}   navigationRecord
 * @param {Object[]} sortableTreeData
 * @param {Object[]} treeDataFromFlatData
 * @param {function} setThisNavigation
 * @param {function} setSelectedNode
 * @param {function} setSortableTreeData
 * @param {function} setOriginalSelectedNode
 * @returns {Object[]}
 */
// TODO => Departments: check / remove
// function processTreeData(navigationRecord, setThisNavigation, sortableTreeData, setSortableTreeData, setSelectedNode, setOriginalSelectedNode, treeDataFromFlatData) {
//     return new Promise((resolve, reject) => {
//         if (navigationRecord.active) {
//             setThisNavigation(navigationRecord);
//
//             // Establish the parents path as a searchQuery
//             let parentPath = navigationRecord.path.split(".");
//             parentPath.pop();
//             parentPath = parentPath.join(".");
//             // Declare Callback & potential selected value
//             let selected ;
//             let callback = ( nodeInfo ) => {
//                 if(nodeInfo.node.path === parentPath) {
//                     selected = nodeInfo
//                 }
//             }
//             // Try to find the node using the above callback & path
//             try {
//                 if (parentPath.length > 1) {
//                     walk({
//                         treeData:treeDataFromFlatData,
//                         getNodeKey,
//                         ignoreCollapsed:false,
//                         callback
//                     });
//                 } else {
//                     setSortableTreeData(treeDataFromFlatData)
//                 }
//                 if (selected) {
//                     setSelectedNode(selected);
//                     setOriginalSelectedNode(selected);
//                     // Create a clone that will be mutated to leave only parents treePath
//                     let treePath = selected.path;
//
//                     // Find the specified node using the above treePath and expand it
//                     let newTreeData = changeNodeAtPath({
//                         treeData:treeDataFromFlatData,
//                         path: [treePath[0]],
//                         getNodeKey,
//                         ignoreCollapsed: false,
//                         newNode: ({node}) => {
//                             return {...node, expanded : true}
//                         }
//                     })
//
//                     // SetState on TreeData to have the expanded & selected node
//                     setSortableTreeData(newTreeData)
//                     resolve(selected);
//                 } else {
//                     setSortableTreeData(treeDataFromFlatData)
//                     resolve();
//                 }
//
//             } catch (err) {
//                 reject(err);
//                 notify("Whoops! Error retrieving the associated navigation details", "error")
//             }
//
//         } else {
//             setSortableTreeData(treeDataFromFlatData);
//             resolve();
//         }
//     })
// }
