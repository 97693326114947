import React, { useState } from 'react'
import { Card, CardContent, CircularProgress, TextField } from '@mui/material'
import { ToastContainer } from 'react-toastify'
import TableHeader from '../../common/components/TableHeader'
import { notify } from '../../helpers'
import DialogFormAction from '../../common/components/DialogFormAction'
import CreateIcon from '@mui/icons-material/Create'
import DeleteIcon from '@mui/icons-material/Delete'
import ConfirmAction from '../../common/components/ConfirmAction'
import { useCurrentSiteID } from '../auth/atoms'
import { useBusAreaQueryMutation } from './queries'

export const BusAreas = (props) => {
    const { open, classes, handleClose, handleTextField } = props
    const currentSiteID = useCurrentSiteID()
    const {
        busAreaData: areas,
        error,
        refetch,
        createMutation,
        editMutation,
        deleteMutation,
        isLoading
    } = useBusAreaQueryMutation(currentSiteID, () => {
        notify('Success! Bus area has been created', 'info')
        handleClose()
        refetch()
    })

    console.log('busArea', areas)

    // Dialog States
    const [editDialogOpen, setEditDialogOpen] = useState(false)
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

    // Create
    const [createErrors, setCreateErrors] = useState({
        name: false
    })
    const [createState, setCreateState] = useState({
        name: ''
    })

    // Edit
    const [editState, setEditState] = useState({
        name: ''
    })

    // CRUD
    const handleCreate = () => {
        if (createState.name.length > 0) {
            createMutation.mutate(createState)
        } else {
            setCreateErrors((prev) => ({
                ...prev,
                name: true
            }))
        }
    }

    const handleEdit = () => {
        if (editState.name.length > 0) {
            editMutation.mutate(editState)
            handleDialogueDisagree()
            setEditState({
                name: ''
            })
        } else {
            setCreateErrors((prev) => ({
                ...prev,
                name: true
            }))
        }
    }

    const handleDelete = () => {
        deleteMutation.mutate(editState)
        handleDialogueDisagree()
        setEditState({
            name: ''
        })
    }

    // Generics
    const handleDialogueDisagree = () => {
        setEditDialogOpen(false)
        setDeleteDialogOpen(false)
        if (createErrors.name) {
            setCreateErrors((prev) => ({
                ...prev,
                name: false
            }))
        }
    }

    // Icons found on rows
    const Icons = (state) => {
        const handleEditOpen = () => {
            setEditDialogOpen(true)
            setEditState(state)
        }

        const handleDeleteOpen = () => {
            setEditState(state)
            setDeleteDialogOpen(true)
        }

        return (
            <div>
                <CreateIcon onClick={handleEditOpen} className='create-icon' />
                <DeleteIcon onClick={handleDeleteOpen} className='create-icon' />
            </div>
        )
    }

    if (isLoading) {
        return (
            <CircularProgress
                style={{ width: '5rem', height: '5rem', position: 'absolute', right: '50%', top: '50%' }}
            />
        )
    }
    return (
        <div>
            <Card className='sub-container'>
                <CardContent>
                    <ToastContainer />

                    {areas.length < 1 ? (
                        <p className='empty-section'>There are no areas associated with this tenant</p>
                    ) : (
                        <div className='table-container'>
                            <TableHeader first='Area Name' second='Options' alternative={true} />
                            {areas.map((state) => (
                                <div key={state?.name + state?.id} className='table-item'>
                                    <p className='site-name'>{state.name}</p>
                                    <div className='icon site-options'>{Icons(state)}</div>
                                </div>
                            ))}
                        </div>
                    )}
                </CardContent>
            </Card>

            {/*Create*/}
            <DialogFormAction
                item={
                    <div>
                        <TextField
                            variant='standard'
                            error={createErrors.name}
                            value={createState.name}
                            name='name'
                            onChange={(event) => handleTextField(event, setCreateState, setCreateErrors)}
                            className={classes.textfield}
                            id='standard-basic'
                            label='Name'
                        />
                    </div>
                }
                title='Create a bus area'
                // text="What would you like to create?"
                buttonAgreeLabel='Create'
                buttonDisagreeLabel='Cancel'
                open={open}
                handleDisagree={handleClose}
                handleAgree={handleCreate}
                handleClose={handleClose}
                fullWidth={true}
                text={undefined}
                alternate={undefined}
                alternateAction={undefined}
                headerComponent={undefined}
            />

            {/*Edit*/}
            <DialogFormAction
                item={
                    <div>
                        <TextField
                            variant='standard'
                            error={createErrors.name}
                            value={editState.name}
                            name='name'
                            onChange={(event) => handleTextField(event, setEditState, setCreateErrors)}
                            className={classes.textfield}
                            id='standard-basic'
                            label='Name'
                        />
                    </div>
                }
                title='Edit a bus area'
                // text="What would you like to create?"
                buttonAgreeLabel='Save'
                buttonDisagreeLabel='Cancel'
                open={editDialogOpen}
                handleDisagree={handleDialogueDisagree}
                handleAgree={handleEdit}
                handleClose={handleDialogueDisagree}
                fullWidth={true}
                text={undefined}
                alternate={undefined}
                alternateAction={undefined}
                headerComponent={undefined}
            />

            {/*Delete*/}
            <ConfirmAction
                open={deleteDialogOpen}
                title='Are you sure?'
                text="Please confirm if you'd like to delete this area"
                handleDisagree={handleDialogueDisagree}
                handleAgree={handleDelete}
                handleClose={handleDialogueDisagree}
            />
        </div>
    )
}
export default BusAreas
