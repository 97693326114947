import { Box, Button, InputAdornment, TextField } from '@mui/material'
import { useMemo, useState } from 'react'
import { isEqual } from 'lodash'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import TwitterIcon from '@mui/icons-material/Twitter'
import { YouTube } from '@mui/icons-material'
import useSiteForAccountsQueryMutation from '../../../../common/query-hooks/useSiteForAccountsQueryMutation'
import { BoxForm } from '../../../../common/components/BoxForm'
import { useParams } from 'react-router-dom'

interface IEditSettingsSocialMediaFormData {
    facebook: string
    instagram: string
    twitter: string
    youtube: string
}

interface IEditSettingsSocialMediaValidators {
    facebook: ((v: string) => string) | null
    instagram: ((v: string) => string) | null
    twitter: ((v: string) => string) | null
    youtube: ((v: string) => string) | null
}

const validators: IEditSettingsSocialMediaValidators = {
    facebook: null,
    instagram: null,
    twitter: null,
    youtube: null
}

// also used to remove fields from settings in CustomField form
export const socialMediaTypes = ['facebook', 'instagram', 'twitter', 'youtube']

function EditSettingsSocialMedia() {
    const params = useParams()
    const { siteData, mutation } = useSiteForAccountsQueryMutation(params.id)

    const defaultFormDataValues = useMemo(() => {
        return socialMediaTypes.reduce(
            (a, v) => ({ ...a, [v]: siteData?.settings?.[v] || '' }),
            {} as IEditSettingsSocialMediaFormData
        )
    }, [siteData])

    const [formData, setFormData] = useState(defaultFormDataValues)
    const [formDataError, setFormDataError] = useState<Record<string, string> | null>(null)

    const formDataHasChanges = useMemo(() => {
        return !isEqual(defaultFormDataValues, formData)
    }, [defaultFormDataValues, formData])

    const disableSaveButton = useMemo(
        () => !formDataHasChanges || !!(formDataError && Object.values(formDataError).find((v) => !!v)),
        [formDataError, formDataHasChanges]
    )

    function onChangeHandler(fieldName: string, value: string) {
        setFormData((prev) => ({
            ...prev,
            [fieldName]: value
        }))

        if (validators[fieldName]) {
            const validationError = validators[fieldName]?.(value)
            setFormDataError((prev) => ({
                ...prev,
                [fieldName]: !!validationError ? validationError : null
            }))
        }
    }

    function handleSubmit() {
        // Ensure not to remove fields within settings object that are not used in this component
        const updateSettingsJsonObject = {
            ...siteData.settings,
            ...formData
        }

        mutation.mutate({
            id: siteData.id,
            settings: updateSettingsJsonObject
        })
    }

    if (!siteData) return null

    return (
        <BoxForm>
            <TextField
                variant='standard'
                label='Facebook'
                InputProps={{
                    startAdornment: (
                        <InputAdornment position='start'>
                            <FacebookIcon />
                        </InputAdornment>
                    )
                }}
                value={formData.facebook}
                onChange={(e) => onChangeHandler('facebook', e.target.value)}
            />
            <TextField
                variant='standard'
                label='Instagram'
                InputProps={{
                    startAdornment: (
                        <InputAdornment position='start'>
                            <InstagramIcon />
                        </InputAdornment>
                    )
                }}
                value={formData.instagram}
                onChange={(e) => onChangeHandler('instagram', e.target.value)}
            />
            <TextField
                variant='standard'
                label='Twitter'
                InputProps={{
                    startAdornment: (
                        <InputAdornment position='start'>
                            <TwitterIcon />
                        </InputAdornment>
                    )
                }}
                value={formData.twitter}
                onChange={(e) => onChangeHandler('twitter', e.target.value)}
            />
            <TextField
                variant='standard'
                label='Youtube'
                InputProps={{
                    startAdornment: (
                        <InputAdornment position='start'>
                            <YouTube />
                        </InputAdornment>
                    )
                }}
                value={formData.youtube}
                onChange={(e) => onChangeHandler('youtube', e.target.value)}
            />
            <Box className='box-row'>
                <Button variant='text' color='primary' type='reset' onClick={() => setFormData(defaultFormDataValues)}>
                    Reset Changes
                </Button>
                <Button
                    disabled={disableSaveButton}
                    variant='contained'
                    color='primary'
                    type='submit'
                    onClick={handleSubmit}
                >
                    Save
                </Button>
            </Box>
        </BoxForm>
    )
}

export default EditSettingsSocialMedia
