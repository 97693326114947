import { useContext, useEffect, useMemo, useState } from 'react'
import { Checkbox, FormControl, Input, InputLabel, ListItemText, MenuItem, Select } from '@mui/material'
import { fullAccessFoldersContext } from './context'
import { makeStyles } from '@mui/styles'
import Alert from '@mui/material/Alert'
import { PrivacyLevelCheckbox } from '../../../common/components/PrivacyLevelCheckbox'
import { isEqual } from 'lodash'
import { TagsSelectorForContent } from '../../../common/components/selectors/TagSelectorForContent'
import { SiteSelectorForContent } from '../../../common/components/selectors/SiteSelectorForContent'
import { useCurrentSite, useDefaultSitesForSelector } from '../../auth/atoms'
import { TagType } from '../../system/tags/types'

export const DocumentOptionsSelect = ({
    errors,
    setErrors,
    folderModel,
    setFolderModel,
    chosenFolder,
    setChosenFolder,
    isDocumentUpload,
    isEditFolder,
    openedFolder
}) => {
    // const [allFolders] = useContext(allFolderContext)
    const currentSite = useCurrentSite()
    const defaultSites = useDefaultSitesForSelector()
    const classes = useStyles()
    const [foldersWithPermission] = useContext(fullAccessFoldersContext)
    const [folderParent, setFolderParent] = useState(null)

    const folderOptions = useMemo(
        () =>
            isEditFolder
                ? foldersWithPermission.filter((folder) => !folder?.path?.includes(folderModel.path))
                : foldersWithPermission,
        [isEditFolder, foldersWithPermission]
    )

    useEffect(() => {
        if (!folderModel['sites'] || (Array.isArray(folderModel['sites']) && folderModel['sites'].length === 0)) {
            setFolderModel((prev) => ({
                ...prev,
                department_id: currentSite.Type === 'department' ? currentSite.ID : null,
                sites: defaultSites
            }))
        }
    }, [currentSite, defaultSites])

    // ChosenFolder is set elsewhere
    // OpenedFolder = Document view "which folder is currently open"
    // When user does not have access to ChosenFolder, we shouldn't allow it to be the default option:
    // HOWEVER, we can't just change it here, as that wouldnt make sense from UX - e.g I go to Folder 1, I go to Upload, I'm now no longer in Folder 1
    useEffect(() => {
        if (chosenFolder && chosenFolder.length > 0 && chosenFolder[0].privacyLevel === 2) {
            setFolderModel((prev) => ({ ...prev, privacyLevel: 2 }))
        }
    }, [chosenFolder])

    // folderModel is the model to be edited, no need to preselect folder, so we don't assign
    // chosenFolder if !folderModel['id']
    useEffect(() => {
        if (!folderModel?.id && openedFolder?.id) {
            if (!isEqual([openedFolder], chosenFolder)) {
                if (foldersWithPermission.find((folder) => folder.id === openedFolder?.id)) {
                    setChosenFolder([openedFolder])
                }
            }
        }
    }, [folderModel?.id, openedFolder])

    let customLabel
    if (isDocumentUpload) {
        customLabel = 'Document Visibility'
    } else {
        if (isEditFolder) {
            customLabel = `${folderModel.filename} Visibility`
        } else {
            customLabel = `${folderModel.filename ? `${folderModel.filename} Visibility` : 'New Folder Visibility'}`
        }
    }

    const setPrivacyLevel = (privacyLevel) => {
        setFolderModel((prev) => ({ ...prev, privacyLevel }))
    }

    return (
        <div>
            {chosenFolder && chosenFolder.length > 0 && chosenFolder[0].privacyLevel === 2 && (
                <Alert severity='info'>
                    {folderModel.filename ? folderModel.filename : isDocumentUpload ? 'Document' : 'Folder'} Visibility
                    will be determined by the location you have selected - Further changes to the{' '}
                    {isDocumentUpload ? 'document' : 'folder'} visibility can be made from within the selected location
                </Alert>
            )}
            <div className='row mt10'>
                <div className='col-xs-6'>
                    <FormControl variant='standard' className={classes.select}>
                        <InputLabel>
                            {folderModel.filename
                                ? folderModel.filename
                                : isDocumentUpload
                                  ? 'Document Location'
                                  : 'New Folder Location'}
                        </InputLabel>
                        <Select
                            variant='standard'
                            value={chosenFolder}
                            multiple
                            input={<Input />}
                            onChange={(event) => {
                                let change = event.target.value
                                if (change && change.length > 1) {
                                    change.shift()
                                }
                                console.log(change)
                                setChosenFolder(change)
                            }}
                            renderValue={(selected) => {
                                let newArr = []
                                for (const item of selected) {
                                    if (item !== undefined && !newArr.includes(item.filename)) {
                                        newArr.push(item.filename)
                                    }
                                }
                                return newArr.join(', ')
                            }}
                        >
                            {folderOptions.map((folder) => (
                                <MenuItem key={folder.id} value={folder}>
                                    <Checkbox checked={chosenFolder.findIndex((x) => x.id === folder.id) > -1} />
                                    <ListItemText primary={folder.filename} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className='col-xs-6'>
                    <PrivacyLevelCheckbox
                        customLabel={customLabel}
                        horizontal
                        isDisabled={chosenFolder && chosenFolder.length > 0 && chosenFolder[0].privacyLevel === 2}
                        errors={errors}
                        setErrors={setErrors}
                        privacyLevel={folderModel.privacyLevel}
                        setPrivacyLevel={setPrivacyLevel}
                    />
                </div>
            </div>
            <Alert severity='info'>
                Choose which sites to share the {isDocumentUpload ? 'document' : 'folder'} with and optionally tag the
                document before saving.
            </Alert>

            <div className='row mt10'>
                <div className={'col-xs-6'}>
                    <SiteSelectorForContent
                        Selected={folderModel.sites}
                        ContentType={isDocumentUpload ? 'document' : 'folder'}
                        OnChange={(sites) => setFolderModel((prev) => ({ ...prev, sites: sites }))}
                        HasError={!!errors.sites}
                    />
                </div>
                <div className={'col-xs-6'}>
                    <FormControl variant='standard' className={classes.select}>
                        <TagsSelectorForContent
                            selected={folderModel.tags}
                            tagType={TagType.Media}
                            onChange={(tags) => setFolderModel((prev) => ({ ...prev, tags: tags }))}
                        />
                    </FormControl>
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles({
    select: {
        width: '100%'
        // margin:'10px'
    },
    m5: {
        margin: '5px'
    }
})
