import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import DeleteIcon from '@mui/icons-material/Delete'
import { $insertParagraphAfterNode, _createPortal } from '../helpers/helpers'
import { Box, IconButton } from '@mui/material'
import { useCallback, useEffect, useRef, useState } from 'react'
import { getBlockElement } from '../DraggableBlock/DraggableBlockPlugin'
import { $getNearestNodeFromDOMNode, LexicalEditor, isHTMLElement } from 'lexical'
import { $isVideoNode, videoNodeIframeAttributeName } from './VideoNode'
import { positionFloatingMenu } from '../toolbar/toolbar.helpers'
import { colours } from '../../../common/colours'
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn'
import { $findMatchingParent } from '@lexical/utils'
import { $isDecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode'
import { $isCollapsibleContainerNode } from '../Collapsible/CollapsibleContainerNode'
import { $getChildrenRecursively } from '../Table/TablePlugin/TableSelection'
import { $getNodeFromDOM } from '../helpers/LexicalUtils'

function useVideoNodeMenu(editor: LexicalEditor, anchorElem: HTMLElement, isEditable: boolean) {
    const menuRef = useRef(null)

    const findDecoratorBlockNodeNearMouse = useCallback(
        (mouseEvent: MouseEvent) => {
            const targetBlockElem = getBlockElement(anchorElem, editor, mouseEvent, true)
            if (!targetBlockElem) {
                return null
            }

            if ($isCollapsibleContainerNode($getNodeFromDOM(targetBlockElem, editor))) {
                // TODO: if there are two videos stacked on top of each other inside an accordion this will
                // return the top most video node instead of the expected video node
                const children = $getChildrenRecursively($getNodeFromDOM(targetBlockElem, editor)!)
                for (const child of children) {
                    if ($isVideoNode(child)) {
                        return $findMatchingParent(child, (node) => $isDecoratorBlockNode(node))
                    }
                }
            }

            const targetNode = $getNearestNodeFromDOMNode(targetBlockElem)
            if (!targetNode) {
                return null
            }

            const blockNode = $findMatchingParent(targetNode, (node) => $isDecoratorBlockNode(node))

            return blockNode
        },
        [editor, anchorElem]
    )

    const deleteVideoAtSelection = useCallback(
        (event) => {
            editor.update(() => {
                findDecoratorBlockNodeNearMouse(event)?.remove()
            })
        },
        [editor]
    )

    const insertNewLineOnClickHandler = useCallback(
        (event) => {
            editor.update(() => {
                const nearestDecoratorBlockNode = findDecoratorBlockNodeNearMouse(event)
                if (nearestDecoratorBlockNode) {
                    $insertParagraphAfterNode(nearestDecoratorBlockNode)
                    return true
                }

                return false
            })
        },
        [editor]
    )

    useEffect(() => {
        // position floating menu
        function onMouseMove(event: MouseEvent) {
            const target = event.target
            if (!target) return
            if (!isHTMLElement(target)) {
                return
            }

            const isMouseOnMenu = !!target.closest('.video-node-menu')
            if (isMouseOnMenu) return

            const videoDOMNode = target.closest<HTMLElement>(`.${videoNodeIframeAttributeName}`)
            if (videoDOMNode == null) {
                positionFloatingMenu(menuRef?.current, null, 'left')
                return
            }

            let isVideoNode = false
            editor.update(() => {
                const targetNode = $getNearestNodeFromDOMNode(videoDOMNode)
                if (!targetNode) {
                    return false
                }

                isVideoNode = $isVideoNode(targetNode)
            })

            if (isVideoNode) {
                // buttons should be on top of the video node to help findDecoratorBlockNodeNearMouse
                positionFloatingMenu(menuRef?.current, videoDOMNode, 'bottom', { y: -35, x: 0 })
            } else {
                positionFloatingMenu(menuRef?.current, null)
            }
        }

        function onMouseLeave() {
            positionFloatingMenu(menuRef?.current, null)
        }

        document.addEventListener('mousemove', onMouseMove)
        document?.addEventListener('mouseleave', onMouseLeave)

        return () => {
            document.removeEventListener('mousemove', onMouseMove)
            document?.removeEventListener('mouseleave', onMouseLeave)
        }
    }, [])

    return _createPortal(
        <Box
            ref={menuRef}
            className='video-node-menu'
            sx={{
                position: 'absolute',
                top: '-10000px',
                left: '-10000px',
                display: 'flex',
                flexDirection: 'row',
                boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.3)',
                backgroundColor: colours.white,
                borderRadius: '8px',
                transition: 'opacity 0.5s',
                alignItems: 'center',
                gap: '32px'
            }}
        >
            <IconButton
                sx={{
                    fontSize: '25px'
                }}
                onClick={(e) => insertNewLineOnClickHandler(e)}
            >
                <KeyboardReturnIcon />
            </IconButton>
            <IconButton
                sx={{
                    fontSize: '25px',
                    color: 'red'
                }}
                onClick={(e) => {
                    deleteVideoAtSelection(e)
                    positionFloatingMenu(menuRef?.current, null)
                }}
            >
                <DeleteIcon />
            </IconButton>
        </Box>,
        anchorElem
    )
}

export default function VideoNodeMenuPlugin({ anchorElem = document.body }: { anchorElem?: HTMLElement }): JSX.Element {
    const [editor] = useLexicalComposerContext()
    return useVideoNodeMenu(editor, anchorElem, editor._editable)
}
