import { Box, TextField } from '@mui/material'
import { DialogFormAction } from '../../../common/components'
import { LexicalEditor } from 'lexical'
import { useEffect, useState } from 'react'
import { INSERT_TABLE_COMMAND } from './TablePlugin/TableCommands'

export function InsertTableDialog({
    activeEditor,
    isOpen,
    onClose
}: {
    activeEditor: LexicalEditor
    isOpen: boolean
    onClose: () => void
}): JSX.Element {
    const [rows, setRows] = useState('5')
    const [columns, setColumns] = useState('5')
    const [isDisabled, setIsDisabled] = useState(true)

    useEffect(() => {
        const row = Number(rows)
        const column = Number(columns)
        if (row && row > 0 && row <= 500 && column && column > 0 && column <= 50) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }
    }, [rows, columns])

    const handleConfirm = () => {
        activeEditor.dispatchCommand(INSERT_TABLE_COMMAND, { columns, rows })
        onClose()
    }

    return (
        <DialogFormAction
            title={'Insert Table'}
            buttonAgreeLabel={'Confirm'}
            isHandleAgreeDisabled={isDisabled}
            buttonDisagreeLabel={'Close'}
            open={isOpen}
            handleDisagree={onClose}
            handleClose={onClose}
            handleAgree={handleConfirm}
            item={
                <Box padding='8px' display='flex' flexDirection='column' gap='12px'>
                    <TextField
                        helperText='# of rows (1-500)'
                        label='Rows'
                        onChange={(ev) => setRows(ev.target.value)}
                        value={rows}
                        data-test-id='table-modal-rows'
                        type='number'
                    />
                    <TextField
                        helperText='# of columns (1-50)'
                        label='Columns'
                        onChange={(ev) => setColumns(ev.target.value)}
                        value={columns}
                        data-test-id='table-modal-columns'
                        type='number'
                    />
                </Box>
            }
            text={'The number of rows and columns may be changed later'}
            alternate={undefined}
            alternateAction={undefined}
            fullWidth={undefined}
            headerComponent={undefined}
        />
    )
}

export default InsertTableDialog
