// Constants AND TYPES

export const AlertType = {
    Banner: 'banner',
    Popup: 'popup'
}

export enum SiteType {
    Elementary = 'elementary',
    ElementaryJuniorHigh = 'elementary - junior high',
    JuniorHighHighSchool = 'junior high - high school',
    HighSchool = 'high school',
    District = 'district',
    K12School = 'k-12 school',
    CentralOffice = 'central office',
    MiddleSchool = 'middle school',
    Department = 'department',
    Other = 'other'
}

export const HandlebarsErrors = {
    line: 'Parse error on line',
    position: 'Pos:'
}

// Google OAUTH2 Client_ID
export const GOOGLE_CLIENT_ID = '281757022600-vb7n2t80ib020r6v4v2o398lt1kkr6l6.apps.googleusercontent.com'

// API
export const BASE = window.location.origin === 'http://localhost:3000' ? 'http://localhost:10000' : ''
export const MEDIA_BASE = window.location.origin === 'http://localhost:3000' ? 'http://localhost:10000' : ''

export const MEDIA_THUMBNAILS = `${MEDIA_BASE}/thumbnails`
export const MEDIA_IMAGES = `${MEDIA_BASE}/images`
export const NotificationsAPIBase = BASE + '/api/v1/notifications'
export const PATokensAPI = BASE + '/api/v1/pat'
export const ManageAccountsAPI = BASE + '/api/v1/user_management/accounts'
export const InstagramErrorsAPI = BASE + '/api/v1/site/instagram-errors'
export const TopicsAPI = NotificationsAPIBase + '/topics'
export const SubscribersAPI = NotificationsAPIBase + '/subscribers'
export const IssuesAPI = NotificationsAPIBase + '/issues'
export const RelaysAPI = NotificationsAPIBase + '/relays'
export const AuditRecordsAPI = NotificationsAPIBase + '/audit-records'
export const MapIDsAPI = NotificationsAPIBase + '/map-ids'

export const NavigationAPI = BASE + '/api/v1/navigation'
export const SecurityGroupAPI = BASE + '/api/v1/securitygroup'
export const GroupAPI = BASE + '/api/v1/user_management/groups'
export const SiteAPI = BASE + '/api/v1/site'
export const SiteRelatedAPI = `${SiteAPI}/related`
export const AccountsAPI = BASE + '/api/v1/account'
export const ContentAPI = BASE + '/api/v1/content'
export const ContentAPIV2 = BASE + '/api/v2/content'
export const TemplateAPI = BASE + '/api/v1/template'
export const SchoolAPI = BASE + '/api/v1/school'
export const EditorsAPI = BASE + '/api/v1/content/editors'
export const ContentByIdAPI = BASE + '/api/v1/content/'
export const LegacyURLsAPI = BASE + '/api/v1/legacy-urls/'
export const MediaAPI = BASE + '/api/v1/media'
export const ImagesAPI = BASE + '/api/v1/media-v2'
export const SitesAPI = BASE + '/api/v1/sites-for-content'
export const WhoAmIAPI = BASE + '/api/v1/whoami'
export const MeAPI = BASE + '/api/v1/me'
export const LoginAPI = BASE + '/api/v1/login'
export const RoleAPI = BASE + '/api/v1/role'
export const GoogleAuthAPI = BASE + '/api/v1/googleAuth'
export const GCVerifyAPI = BASE + '/api/v1/auth/gcverify'
export const FbAuthAPI = BASE + '/api/v1/auth/fb'
export const FbAuthVerifyAPI = BASE + '/api/v1/auth/fbverify'
export const LogoutAPI = BASE + '/api/v1/logout'
export const PreviewAPI = BASE + '/api/v1/preview'
export const TagAPI = BASE + '/api/v1/tag'
export const TagAPIV2 = BASE + '/api/v2/tag'
export const BusAreaAPI = BASE + '/api/v1/busarea'
export const BusRouteAPI = BASE + '/api/v1/busroute'
export const BusStatusAPI = BASE + '/api/v1/busstatus'
export const DocumentsAPI = BASE + '/api/v1/document'
export const DepartmentAPI = BASE + '/api/v1/department'
export const S3API = BASE + '/api/v1/s3'
export const EdsbyAuthVerifyAPI = BASE + '/api/v1/auth/verify/edsby'
export const OutlookAuthVerifyAPI = BASE + '/api/v1/auth/verify/outlook'
export const SettingsAPI = BASE + '/api/v1/settings'
export const StructureAPI = BASE + '/api/v1/structure'
export const ImportAuthVerifyAPI = (importName) => `${BASE}/api/v1/auth/verify/${importName}`

// react-query query keys
export const busAreaQueryKey = 'bus-area'
export const busRouteQueryKey = 'bus-route'
export const busStatusQueryKey = 'bus-status'
export const siteForAccountsQueryKey = 'site-for-accounts'
export const siteTagsQueryKey = 'site-tags'
export const pinnedNewsQueryKey = 'pinned-news'
export const securityGroupQueryKey = 'security-group'
export const roleQueryKey = 'role'
export const groupQueryKey = 'group'
export const contentQueryKey = 'content'
export const templateQueryKey = 'template'
export const settingsQueryKey = 'settings'

// Colors
export const PrimaryColor = '#2196f3'
export const PrimaryOutlinedColor = '#3f51b5'
export const PrimaryIconColor = '#4285f4'
//..//
export const NullDateTime = '0001-01-01T00:00:00Z'
export const uuidNil = '00000000-0000-0000-0000-000000000000'
export const DocumentsRouteLength = 11
export const DefaultLimit = 10
export const DefaultMax = 99999
export const DefaultPage = 0
export const DelayedSearchTimeout = 750

export const emailRegex =
    /^(?:(?:(?:(?:[a-zA-Z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(?:\.([a-zA-Z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|(?:(?:\x22)(?:(?:(?:(?:\x20|\x09)*(?:\x0d\x0a))?(?:\x20|\x09)+)?(?:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(?:(?:[\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(?:(?:(?:\x20|\x09)*(?:\x0d\x0a))?(\x20|\x09)+)?(?:\x22))))@(?:(?:(?:[a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(?:(?:[a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])(?:[a-zA-Z]|\d|-|\.|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*(?:[a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(?:(?:[a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(?:(?:[a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])(?:[a-zA-Z]|\d|-|\.|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*(?:[a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/
