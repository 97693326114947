import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { documentService } from '../media/document/document.service'
import DocumentFolder from '../media/document/folders/DocumentFolder'
import FolderIcon from '@mui/icons-material/Folder'
import WarningIcon from '@mui/icons-material/Warning'
import clsx from 'clsx'
import { DocumentRow } from '../media/document/documents/DocumentRow'
import { useCurrentSiteID } from '../auth/atoms'
import { colours } from '../../common/colours'
import { DocumentType } from '../media/document/types'
import { validateUUID } from '@/helpers'

export const OpenDocumentGallery = ({
    title,
    handleOpen,
    value,
    error,
    component,
    setCurrentComponent,
    removeSelectedDocument,
    setErrors
}) => {
    const classes = useStyles()
    const currentSiteID = useCurrentSiteID()
    const [document, setDocument] = useState({})
    const [isFolder, setIsFolder] = useState(false)
    const [hasDeletedDocument, setHasDeletedDocument] = useState(false)

    useEffect(() => {
        if (!value || typeof value === 'object') return
        const split = value.split('/')
        const id = split.find((potentialId) => validateUUID(potentialId))
        documentService.getDocumentById(currentSiteID || '', id).then(
            (r) => {
                setDocument(r)
                setIsFolder(r.type === DocumentType.Folder)
                setHasDeletedDocument(false)
                setErrors((prev) => ({ ...prev, [component.name]: false }))
            },
            (e) => {
                setHasDeletedDocument(true)
                if (component.required) {
                    setErrors((prev) => ({ ...prev, [component.name]: true }))
                }
                removeSelectedDocument()
            }
        )
    }, [value])

    const handleCurrentComponent = () => {
        setCurrentComponent(component)
        handleOpen()
    }

    return (
        <div>
            {!hasDeletedDocument && value ? (
                <div style={{ width: '97%' }}>
                    <p>{component.label}</p>
                    <div
                        style={{ backgroundColor: colours.off_white, padding: '10px' }}
                        className='max-width document-gallery'
                    >
                        {isFolder ? (
                            //@ts-ignore
                            <DocumentFolder folder={document} handleRemove={removeSelectedDocument} />
                        ) : (
                            //@ts-ignore
                            <DocumentRow document={document} handleRemove={removeSelectedDocument} />
                        )}
                    </div>
                </div>
            ) : (
                <div>
                    {error ? (
                        <div className={'flex-row'}>
                            <div className='open-gallery-error flex-row max-width' onClick={handleCurrentComponent}>
                                <p className={clsx(classes.mlr, 'error-red')}>{title}</p>
                                <FolderIcon className='error-red' />
                            </div>
                            {hasDeletedDocument && (
                                <div
                                    className='tooltip'
                                    style={{ display: 'flex', alignItems: 'center', margin: '15px' }}
                                >
                                    <WarningIcon style={{ color: '#f44336' }} />
                                    <span className='tooltiptext tooltip-notch-none' style={{ width: '175px' }}>
                                        A document or folder was deleted and is no longer available - Please make a new
                                        selection
                                    </span>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className='flex-row-align-center'>
                            <div className='open-gallery flex-row max-width' onClick={handleCurrentComponent}>
                                <p className={clsx('material-blue', classes.mlr)}>Link to a folder or document</p>
                                <FolderIcon className={'material-blue'} />
                            </div>
                            {hasDeletedDocument && (
                                <div
                                    className='tooltip'
                                    style={{ display: 'flex', alignItems: 'center', margin: '15px' }}
                                >
                                    <WarningIcon style={{ color: '#f44336' }} />
                                    <span className='tooltiptext tooltip-notch-none' style={{ width: '175px' }}>
                                        A document or folder was deleted and is no longer available - Please make a new
                                        selection
                                    </span>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default OpenDocumentGallery
const useStyles = makeStyles((theme) => ({
    mlr: {
        marginLeft: '0.5em',
        marginRight: '0.5em'
    }
}))
