import React, { useEffect, useMemo, useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { is, notify, sanitizePathString } from '../../../helpers'
import { ToastContainer } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'
import { contentService } from '../content.service'
import { DialogFormAction } from '../../../common/components'
import CreateIcon from '@mui/icons-material/Create'
import moment from 'moment'
import { ManagerWithFilters } from '../../grid/ManagerWithFilters'
import './SubPages.css'
import { fromOptional } from '../../../common/components/selectors/SiteSelectorForAccount'
import { ContentRestoreMenuItem } from '../../../common/components/custom-context-menu/ContentRestoreMenuItem'
import { CustomMenu, CustomMenuItem } from '../../../common/components/custom-context-menu/CustomMenu'
import { PreviewMenuItem } from '../../../common/components/selectors/SiteSelectorForContent'
import { useStateWithStorage } from '../../../common/storage.service'
import { asSecured } from '../../auth/permissions/securityMapping'
import { useAppContext, useCurrentSiteID } from '../../auth/atoms'
import ReadMoreIcon from '@mui/icons-material/ReadMore'
import { useAppNavigation } from '../../../app/useAppNavigation'
import { DeleteCustomMenuItem } from '../../../common/components/custom-context-menu/ContentDeleteMenuItem'
import { CloneCustomMenuItem } from '../../../common/components/custom-context-menu/ContentCloneMenuItem'
import { TransferDepartmentMenuItem } from '../../../common/components/custom-context-menu/ContentTransferDepartmentMenuItem'
import { makeStyles } from '@mui/styles'
import { validateRequiredValues } from '../../../helpers/requiresValues'
import { ContentPublishedTypes, ContentType } from '../types'

export const PageManager = (props) => {
    const classes = useStyles()
    const evaluators = useAppContext()
    const { navigateTo } = useAppNavigation()
    const { open, handleClose, locationState } = props
    const currentSiteID = useCurrentSiteID()
    const [parent, setParent] = useState('')
    const [parentContent, setParentContent] = useState()
    const [errors, setErrors] = useState({
        title: false,
        pagelayout: false,
        parent: false
    })
    const [searchLoading, setSearchLoading] = useState(false)
    const [templates, setTemplates] = useState([])
    const [pageTemplates, setPageTemplates] = useState([])
    const [localCreateState, setLocalCreateState] = useState({
        id: uuidv4(),
        title: '',
        siteId: currentSiteID,
        pagelayout: '',
        active: undefined
    })
    const defaultState = {
        data: [],
        contentType: ContentType.Page,
        offset: 0,
        perPage: 10,
        page: 0,
        total: 0,
        searchTerm: null,
        publishedState: ContentPublishedTypes.all,
        tags: [],
        editors: [],
        template: null,
        filters: { items: [] },
        sortings: [],
        editable: false,
        deleted: null,
        departments: [],
        withPermissions: true,
        status: '',
        siteOnly: false
    }
    const [paginationState, setPaginationState] = useStateWithStorage('page-manager-query', defaultState)
    const [paginationLoading, setPaginationLoading] = useState(true)
    const [controller, setController] = useState(new AbortController())

    const loadDataFromServer = () => {
        setSearchLoading(true)
        const newController = new AbortController()
        controller.abort()
        setController(newController)
        contentService
            .getAllContents(newController, {
                selectedSite: currentSiteID,
                ...paginationState,
                status: paginationState.status,
                page: paginationState.page,
                limit: paginationState.perPage,
                isPublished: paginationState.publishedState === ContentPublishedTypes.published,
                ignorePublished: paginationState.publishedState === ContentPublishedTypes.all,
                deleted:
                    paginationState.publishedState === ContentPublishedTypes.deleted
                        ? moment().subtract(3, 'months').format('YYYY-MM-DD')
                        : null
            })
            .then(
                ({ results, resultset }) => {
                    setPaginationState((prev) => ({
                        ...prev,
                        data: results,
                        total: resultset.total_records,
                        pageCount: Math.ceil(resultset.total_records / resultset.limit) // Total count x limit
                    }))
                    setSearchLoading(false)
                },
                (e) => {
                    setSearchLoading(false)
                }
            )
    }

    useEffect(() => {
        if (paginationLoading) return
        loadDataFromServer()
        return () => {
            controller.abort()
        }
    }, [
        paginationState.page,
        paginationState.perPage,
        paginationState.publishedState,
        paginationState.searchTerm,
        paginationState.tags,
        paginationState.editors,
        paginationState.template,
        paginationState.sortings,
        paginationState.filters,
        paginationState.editable,
        paginationState.departments,
        paginationState.status,
        paginationState.inactive,
        paginationState.siteOnly,
        currentSiteID,
        paginationLoading
    ])

    useEffect(() => {
        if (locationState && Object.keys(locationState).length > 0) {
            // Alter Request and Pagination Options based on previous
            setPaginationState((prev) => ({
                ...prev,
                ...locationState
            }))
            navigateTo(`/pages`, { replace: true, state: {} })
        }
        setPaginationLoading(false)

        if (evaluators.isCurrentSiteDepartment() && paginationState.siteOnly === true) {
            setPaginationState((prev) => ({
                ...prev,
                siteOnly: false
            }))
        }

        if (!evaluators.isCurrentSiteDepartment()) {
            setPaginationState((prev) => ({
                ...prev,
                departments: null
            }))
        }

        contentService.getTemplates().then(
            (templates) => {
                setTemplates(templates)
                setPageTemplates(
                    templates.filter((tmpl) => {
                        const cl = tmpl?.settings?.classification
                        return Array.isArray(cl) && cl.includes(ContentType.Page)
                    })
                )
            },
            (e) => console.log(e)
        )
    }, [currentSiteID])

    const handleCloseAdapter = () => {
        handleClose()
        Object.keys(errors).forEach((error) => {
            setErrors((prev) => ({
                ...prev,
                [error]: false
            }))
        })
    }

    const handleSave = () => {
        // TODO => refactor, should get values within editor and not rely on history state
        if (localCreateState.pagelayout === 'WYSIWYG') {
            navigateTo(`/page/${localCreateState?.id}`, {
                state: {
                    ...localCreateState,
                    currentElement: parentContent,
                    paginationState
                }
            })
        } else {
            navigateTo(`/dct/${localCreateState?.id}`, {
                state: {
                    ...localCreateState,
                    parent: parentContent,
                    currentElement: parentContent,
                    paginationState
                }
            })
        }
        handleClose()
    }

    const handleChange = (event) => {
        setLocalCreateState({
            ...localCreateState,
            [event.currentTarget.name]: event.currentTarget.value
        })
        setErrors((prev) => ({ ...prev, [event.target.name]: false }))
    }

    // On creation of a new page:
    // If: the Parent-template doesn't have structure set the pagelayout as WYSIWYG
    // TODO => refactor
    useEffect(() => {
        if (parent !== '') {
            const elementsIndex = templates.findIndex((element) => element.id === parent)
            const isDct = isFormTemplate(templates?.[elementsIndex]?.structure)
            setLocalCreateState((prev) => ({ ...prev, pagelayout: isDct ? 'DCT' : 'WYSIWYG' }))
        }
    }, [parent])

    const isFormTemplate = (struct) => {
        if (is.object(struct) || is.array(struct)) {
            try {
                JSON.stringify(struct)
                return true
            } catch (e) {
                return false
            }
        }
        if (typeof struct === 'string') {
            try {
                JSON.parse(struct)
                return true
            } catch (e) {
                return false
            }
        }
        return false
    }

    function resetForm() {
        setPaginationState(defaultState)
    }

    const handleOpen = (state) => {
        console.log(state)
        if (state?.structureId) {
            navigateTo(`/content-editor/${state.id}`)
            return
        }

        state.pagelayout === 'DCT' ? handleDctOpen(state) : handleWysiwygOpen(state)
    }

    const handleDctOpen = (state) => {
        let currentElement
        let pathArray = state.path.split('.')
        if (pathArray.length > 1) {
            let sanitizedPath = pathArray[pathArray.length - 2]
            for (const item of templates) {
                if (sanitizedPath === sanitizePathString(item.id)) {
                    if (item.structure) {
                        currentElement = item
                        navigateTo(`/dct/${state?.id}`, {
                            state: {
                                ...state,
                                currentElement,
                                selectedSite: currentSiteID,
                                paginationState
                            }
                        })
                        return
                    } else {
                        notify("Uh-oh! This pages parent doesn't have any structure!", 'error')
                    }
                }
            }
            notify('Uh-oh! This pages parent is no longer available!', 'error')
        } else {
            notify("Uh-oh! This page doesn't have a parent!", 'error')
        }
    }
    const handleWysiwygOpen = (state) => navigateTo(`/page/${state.id}`, { state: { ...state, paginationState } })

    const [anchorElement, setAnchorElement] = useState(null)
    const [actionItem, setActionItem] = useState(null)
    const setMenuElement = async (event, state) => {
        setActionItem(state)
        setAnchorElement(event.currentTarget)
    }
    const closeMenu = () => setAnchorElement(null)
    const handleDelete = () => {
        if (paginationState.data.length === 1 && paginationState.currentPage) {
            setPaginationState((prev) => ({ ...prev, currentPage: prev.currentPage - 1 }))
        } else {
            loadDataFromServer()
        }
    }
    const handleClone = (response) => {
        if (response.success) {
            notify('Successfully cloned page', 'info')
            handleOpen(response?.data || {})
        } else {
            notify("Whoops! Couldn't clone page with error: " + response?.error?.message, 'error')
        }
        closeMenu()
    }
    const handleRefresh = () => {
        closeMenu()
        loadDataFromServer()
    }

    const secured = asSecured(actionItem)
    const ctxItemPermission = useMemo(
        () => ({
            update:
                actionItem == null
                    ? false
                    : evaluators.action(actionItem, 'update') || actionItem?.settings?.isDistrictPage,
            create: actionItem == null ? false : evaluators.action(actionItem, 'create'),
            delete: actionItem == null ? false : evaluators.action(actionItem, 'delete')
        }),
        [actionItem]
    )

    return (
        <>
            <CustomMenu anchorElement={anchorElement} onClose={closeMenu}>
                {Boolean(actionItem?.active) ? (
                    <>
                        <PreviewMenuItem content={actionItem} onClick={() => setAnchorElement(null)} />
                        <CustomMenuItem
                            text={ctxItemPermission.update ? 'Edit' : 'View'}
                            onClick={() => handleOpen(actionItem)}
                        >
                            {ctxItemPermission.update ? <CreateIcon /> : <ReadMoreIcon />}
                        </CustomMenuItem>
                        <CloneCustomMenuItem
                            id={actionItem?.id}
                            onChange={(r) => handleClone(r)}
                            disabled={!ctxItemPermission.create}
                        />
                        <DeleteCustomMenuItem
                            contentModel={actionItem}
                            onChange={handleDelete}
                            closeMenu={closeMenu}
                            disabled={!ctxItemPermission.delete}
                        />
                        {evaluators.isAdmin() && (
                            <TransferDepartmentMenuItem
                                contentModel={{
                                    id: actionItem?.id,
                                    departmentId: fromOptional(actionItem?.departmentId),
                                    sites: actionItem?.sites || [],
                                    contentType: secured.EntityScope,
                                    settings: actionItem?.settings
                                }}
                                onChange={handleRefresh}
                                closeMenu={closeMenu}
                            />
                        )}
                    </>
                ) : (
                    <ContentRestoreMenuItem
                        id={actionItem?.id}
                        onChange={handleRefresh}
                        disabled={!ctxItemPermission.delete}
                    />
                )}
            </CustomMenu>

            <ToastContainer />

            <ManagerWithFilters
                contentType={ContentType.Page}
                setPaginationState={setPaginationState}
                paginationState={paginationState}
                searchLoading={searchLoading}
                resetFormCallback={resetForm}
                setMenuElement={setMenuElement}
            />

            <DialogFormAction
                item={
                    <div>
                        <TextField
                            variant='standard'
                            error={errors.title}
                            value={localCreateState.title}
                            name='title'
                            onChange={handleChange}
                            className={classes.textfield}
                            id='standard-basic'
                            label='content name..'
                        />
                        <FormControl variant='standard' className={classes.textfield}>
                            <InputLabel error={errors.parent}>Template</InputLabel>
                            <Select
                                variant='standard'
                                className={classes.select}
                                value={parent}
                                error={errors.parent}
                                onChange={(event) => {
                                    setParent(event.target.value)
                                    const elementsIndex = templates.findIndex(
                                        (element) => element.id === event.target.value
                                    )
                                    if (templates[elementsIndex]) {
                                        setParentContent(templates[elementsIndex])
                                    }
                                    setErrors((prev) => ({ ...prev, parent: false }))
                                }}
                                renderValue={(id) => {
                                    return <div>{pageTemplates.find((i) => i.id === id).title}</div>
                                }}
                            >
                                {pageTemplates.map((item) => (
                                    <MenuItem key={item.id + 'key'} value={item.id}>
                                        {item.title}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant='standard'>{/*<StructureSelector onChange={} />*/}</FormControl>
                    </div>
                }
                title='Create a page'
                text='Which kind of page would you like to create?'
                open={open}
                buttonDisagreeLabel='Cancel'
                buttonAgreeLabel='Continue'
                handleDisagree={handleCloseAdapter}
                handleAgree={() => {
                    const toCheck = { ...localCreateState, parent }
                    delete toCheck.active
                    const { error, errors } = validateRequiredValues(toCheck)
                    if (error) {
                        console.warn(error)
                        return setErrors((p) => ({ ...p, ...errors }))
                    }
                    return handleSave()
                }}
                handleClose={handleCloseAdapter}
                fullWidth={true}
            />
        </>
    )
}

export default PageManager
export const useStyles = makeStyles((theme) => ({
    select: {
        width: '100%',
        margin: 'auto',
        marginTop: '1.5vh',
        marginBottom: '1.5vh'
    },
    textfield: {
        width: '100%'
    }
}))
