import React, { useState } from 'react'
import { Checkbox, FormControlLabel, FormGroup, Switch } from '@mui/material'
import { SiteForSelector } from './SiteSelectorForContent'

type TagsSelectorForSitesProps = {
    sites: SiteForSelector[]
    selectedSiteIDs: string[]
    serverTags: { id: string; name: string }[]
    onChange?: (command: 'select' | 'unselect', siteIDs: string[]) => void
    disabled?: boolean
}

// should be in settings/sites
export const TagsSelectorForSites = ({
    onChange,
    selectedSiteIDs,
    sites,
    serverTags,
    disabled
}: TagsSelectorForSitesProps) => {
    const [show, setShow] = useState(false)

    const allSiteIDs = sites.map((s) => s.ID)
    const tagIDs = sites.map((s) => s.Tags).flat()
    const nonSelectedSites = sites.filter((s) => !selectedSiteIDs.includes(s.ID))
    const nonSelectedTagIDs = new Set(nonSelectedSites.map((s) => s.Tags).flat())
    const availableTags = serverTags.filter((t) => tagIDs.includes(t.id)).sort((a, b) => a.name.localeCompare(b.name))
    const tagsIdsToSitesMap = new Map<string, string[]>()
    sites.forEach((s) => {
        s.Tags.forEach((t) => {
            if (!tagsIdsToSitesMap.has(t)) {
                tagsIdsToSitesMap.set(t, [s.ID])
            } else {
                tagsIdsToSitesMap.get(t)?.push(s.ID)
            }
        })
    })

    return (
        <FormGroup>
            <FormControlLabel
                label={show ? 'Hide tags' : 'Show tags'}
                control={
                    <Switch disabled={Boolean(disabled)} checked={show} onChange={(e) => setShow(e.target.checked)} />
                }
            />

            {show && (
                <FormControlLabel
                    label={'All sites'}
                    control={
                        <Checkbox
                            disabled={Boolean(disabled)}
                            checked={selectedSiteIDs.length === sites.length}
                            onChange={(e) => onChange && onChange(e.target.checked ? 'select' : 'unselect', allSiteIDs)}
                        />
                    }
                />
            )}

            {show &&
                availableTags.map((t) => (
                    <FormControlLabel
                        key={t.id}
                        label={t.name}
                        control={
                            <Checkbox
                                disabled={Boolean(disabled)}
                                key={t.id}
                                checked={!nonSelectedTagIDs.has(t.id)}
                                onChange={(e) =>
                                    onChange &&
                                    onChange(
                                        e.target.checked ? 'select' : 'unselect',
                                        tagsIdsToSitesMap.get(t.id) || []
                                    )
                                }
                            />
                        }
                    />
                ))}
        </FormGroup>
    )
}
