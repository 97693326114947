import { Box, IconButton } from '@mui/material'
import { MoreHoriz } from '@mui/icons-material'
import React, { BaseSyntheticEvent, useState } from 'react'
import { CustomMenu, CustomMenuItem } from '../../common/components/custom-context-menu/CustomMenu'
import CreateIcon from '@mui/icons-material/Create'
import { siteService } from '../system/site/site.service'
import { is } from '../../helpers'
import Collapse from '@mui/material/Collapse'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { SiteZ } from './department.service'

interface DepartmentRowProps {
    site: SiteZ
    index: number
    onClickActions: (event: BaseSyntheticEvent, id: string) => void
}

export function DepartmentRow({ site, index, onClickActions }: DepartmentRowProps) {
    const [open, setOpen] = useState(false)
    const baseStyle = { width: '100%', margin: '0.25rem', alignItems: 'center' }
    if (index > 0) baseStyle['borderTop'] = '0.5px solid lightgrey'

    const test = site.hosts
    const hosts = site.hosts

    return (
        <div className={'row departmentManager-row'} style={baseStyle}>
            <div className={'row max-width'}>
                <div
                    className='col-xs-3'
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}
                >
                    {open ? (
                        <ExpandMoreIcon style={{ color: '#4E01D5' }} onClick={() => setOpen(false)} />
                    ) : (
                        <ChevronRightIcon style={{ color: '#4E01D5' }} onClick={() => setOpen(true)} />
                    )}

                    <h4>{site.name}</h4>
                </div>
                <div className='col-xs-6'>
                    <p>{site.description}</p>
                </div>
                <div className='col-xs-2'></div>
                <div className='col-xs-1'>
                    <IconButton
                        aria-haspopup={'true'}
                        onClick={(e) => {
                            if (onClickActions) {
                                onClickActions(e, site?.id || '')
                            }
                        }}
                    >
                        <MoreHoriz />
                    </IconButton>
                </div>
            </div>
            <Collapse in={open}>
                <div className={'max-width'} style={{ marginLeft: '1.15rem' }}>
                    {site?.hosts?.length > 0 ? (
                        <p style={tmpStyles}>Host Sites: {site?.hosts.map((name: SiteZ) => name.name).join(', ')}</p>
                    ) : (
                        <p style={tmpStyles}>No active host sites</p>
                    )}
                </div>
            </Collapse>
        </div>
    )
}

const tmpStyles = {
    marginTop: '8px',
    marginBottom: '8px',
    textOverflow: 'ellipsis',
    overflowX: 'clip' as const,
    whiteSpace: 'nowrap' as const,
    maxWidth: '70rem'
}

interface RowContainerProps {
    rows: SiteZ[]
    handleOpen: (context?: any) => void
}

export function DepartmentRowContainer({ rows = [], handleOpen }: RowContainerProps) {
    const [anchor, setAnchor] = useState<HTMLElement | null>(null)
    const [context, setContext] = useState<Record<any, any> | null>(null)
    const setContextById = async (event: BaseSyntheticEvent, id: string) => {
        try {
            const target = event.currentTarget
            if (!event || !id) {
                console.error({ message: `Cannot retrieve context with invalid event or id`, event, id })
                return
            }
            const site = await siteService.getById(id)
            console.log({ site, target, id })
            setAnchor(target)
            setContext(site)
        } catch (e) {
            console.log(e)
        }
    }
    const onOpen = () => {
        if (is.func(handleOpen)) {
            handleOpen(context)
        }
        setAnchor(null)
        setContext(null)
    }
    return (
        <Box>
            {rows.map((row: SiteZ, index: number) => (
                <DepartmentRow
                    key={`departmentManager-Site-${row?.id + row?.name}`}
                    site={row}
                    index={index}
                    onClickActions={setContextById}
                />
            ))}
            <CustomMenu anchorElement={anchor} onClose={() => setAnchor(null)}>
                <CustomMenuItem text={'Edit'} onClick={onOpen}>
                    <CreateIcon />
                </CustomMenuItem>
            </CustomMenu>
        </Box>
    )
}
