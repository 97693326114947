import _ from 'lodash'
import axios from 'axios'
import { ImportAuthVerifyAPI, SiteAPI } from '../../../../common/constants'

export const Edsby = 'edsby'
export const Outlook = 'outlook'
export const ImportInfo = {
    [Edsby]: {
        name: Edsby,
        pretty: 'Edsby',
        idName: 'School Id',
        createDialogText: 'This site can be integrated with an Edsby school by adding an Edsby School ID or Nid',
        handlesNews: true,
        handlesEvents: true
    },
    [Outlook]: {
        name: Outlook,
        pretty: 'Outlook',
        idName: 'Mailbox User ID',
        createDialogText: 'This site can be integrated an Outlook/Exchange calendar by entering a Mailbox User ID',
        handlesNews: false,
        handlesEvents: true
    }
}

/**
 * Defaults (insertOnly, useDefault, default) will be applied for RCSD requirements
 * When user interface for configuring field/default combinations is completed
 * this should be reverted to all falsy values.
 * */
const Field = (useDefault, defaultValue) => ({
    insertOnly: true,
    default: defaultValue ? defaultValue : '',
    useDefault: Boolean(useDefault)
})
const ContentType = () => ({
    templateId: '',
    fields: {
        tags: Field(true, []),
        site: Field(false),
        privacyLevel: Field(true, '2'),
        published: Field(true, 'true')
    }
})
const ContentTypeUpdates = () => ({
    templateId: false,
    tags: false,
    published: false,
    privacyLevel: false,
    sites: false
})

function Config(edsbyConfig) {
    this.schoolId = ''
    this.id = ''
    this.news = ContentType()
    this.events = ContentType()

    this.isValid = function () {
        return (
            (this.schoolId.length || this.id.length) && Object.keys(this.news).length && Object.keys(this.events).length
        )
    }
    this.unmarshal = function (config) {
        if (!config) return this
        const { news, events, schoolId, id } = config
        this.news = _.merge(this.news, news)
        this.events = _.merge(this.events, events)
        this.schoolId = schoolId || this.schoolId
        this.id = id || this.id

        //tmp
        this.schoolId = this.schoolId || this.id
        this.id = this.id || this.schoolId

        return this
    }
    this.ids = function () {
        return {
            id: this.schoolId || '',
            schoolId: this.schoolId || '',
            news: this.news?.templateId || '',
            events: this.events?.templateId || ''
        }
    }

    if (edsbyConfig instanceof Config && edsbyConfig.isValid()) {
        return edsbyConfig
    } else if (typeof edsbyConfig === 'object') {
        this.unmarshal(edsbyConfig)
    }
}

function StandardParams(ctx, site) {
    this.news = ContentTypeUpdates()
    this.events = ContentTypeUpdates()
    this.integration = ctx.name || ''
    this.siteSource = site || {}
    this.configSource = {}

    this.withConfigSource = function (source) {
        const config = new Config(source)
        if (config.isValid()) this.configSource = config
        return this
    }
}

const unmarshal = (site) => {
    if (site?.settings?.[Edsby]) {
        site.settings[Edsby] = unmarshalArray(site.settings[Edsby])
    }
    if (site?.settings?.[Outlook]) {
        site.settings[Outlook] = unmarshalArray(site.settings[Outlook])
    }
    return site
}
const unmarshalArray = (arr) => {
    const configurations = arr || []
    for (const ec of configurations) {
        const i = configurations.findIndex((c) => c.id === ec.id)
        if (i === -1) continue
        const config = new Config(ec)
        if (config.isValid()) configurations[i] = config
    }
    return configurations
}
const patch = async (standardParams) => {
    try {
        const data = (await axios.patch(SiteAPI, standardParams)).data
        return {
            sites: data,
            source: data?.find((x) => x.id === standardParams.siteSource.id || {})
        }
    } catch (e) {
        return Promise.reject({ message: `failed to update ${standardParams.integration} config` })
    }
}
const isTenantConfigured = async (ctx) => {
    try {
        return Boolean(await axios.get(ImportAuthVerifyAPI(ctx.name)))
    } catch (e) {
        return false
    }
}

export const integrationService = {
    Config,
    StandardParams,
    ContentType,
    ContentTypeUpdates,
    Field,

    unmarshal,
    unmarshalArray,
    patch,
    isTenantConfigured,

    Edsby,
    Outlook,
    ImportInfo
}
