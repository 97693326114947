import { Box, SxProps, Theme } from '@mui/material'
import { ReactNode } from 'react'

interface BoxFormProps {
    sx?: SxProps<Theme>
    fullWidth?: boolean
    children: ReactNode
    onSubmit?: () => void
    onReset?: () => void // clear, reset changes
}

export function BoxForm({ children, sx, fullWidth, onSubmit, onReset }: BoxFormProps) {
    return (
        <Box
            component='form'
            onSubmit={(ev) => {
                ev.preventDefault()
                onSubmit?.()
            }}
            onReset={(ev) => onReset?.()}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '32px',
                maxWidth: fullWidth ? undefined : '700px',
                '& .box-row': { display: 'flex', flexDirection: 'row', gap: '25px' },
                ...sx
            }}
        >
            {children}
        </Box>
    )
}
