import { DefaultMax } from '../common/constants'
import { ContentPublishedTypes } from '../pkgs/content/types'

export default function NewUrlParam(
    siteId,
    limit,
    page,
    searchTerm,
    multipleParamKey,
    multipleParamValue,
    sameSiteContent,
    pageContentType
) {
    const params = new URLSearchParams()
    if (siteId) {
        params.append('siteId', siteId)
    }
    if (limit && limit >= 0) {
        params.append('limit', limit)
    } else {
        params.append('limit', String(DefaultMax))
    }
    if (page >= 0 && page != undefined && page != null) {
        params.append('page', page)
    } else {
        params.append('page', String(0))
    }
    if (searchTerm && searchTerm.length > 0) {
        params.append('searchTerm', searchTerm)
    }
    if (multipleParamKey && multipleParamValue) {
        if (multipleParamValue && multipleParamValue.length > 0) {
            multipleParamValue.forEach((x) => {
                if (typeof x === 'object') {
                    let id = x.id || x.ID || x.Id
                    params.append(multipleParamKey, id)
                }
                if (typeof x === 'string') {
                    params.append(multipleParamKey, x)
                }
            })
        }
    }

    if (typeof sameSiteContent === 'boolean') {
        params.append('sameSiteContent', String(sameSiteContent))
    }

    if (typeof pageContentType === 'string') {
        params.append('isPublished', String(pageContentType === ContentPublishedTypes.published))
        params.append('ignorePublished', String(pageContentType === ContentPublishedTypes.all))
    }

    if (siteId || limit || page || searchTerm || multipleParamKey || multipleParamValue) {
        return params
    } else {
        return null
    }
}
