import axios from 'axios'
import { validateUUID } from '../../../helpers'
import newUrlParam from '../../../helpers/newUrlParam'
import { DefaultMax, DocumentsAPI, S3API } from '../../../common/constants'

/**
 * @param {string}            selectedSite - UUID of the SelectedSite, can be obtained via selectedSiteContext
 * @param {string}            documentType - folder or document
 * @return {Promise<any>}
 * */
const getAll = async (selectedSite, documentType) => {
    if (!selectedSite || !validateUUID(selectedSite)) return Promise.reject('Invalid Site Selected')
    const params = {
        siteId: selectedSite,
        documentType,
        ignoreParameters: true,
        limit: DefaultMax,
        offset: 0
    }
    const response = await axios.get(DocumentsAPI, {
        params
    })
    return response.data
}
/**
 * @param {string}            selectedSite - UUID of the SelectedSite, can be obtained via selectedSiteContext
 * @param {string}            documentType - folder or document
 * @param {number}            limit        - limit
 * @param {number}            offset       - offset
 * @param {string}            folderId     - get descendants of folderId
 * @return {Promise<any>}
 * */
const getAllByFolderAndType = async (selectedSite, documentType, folderId, limit, offset) => {
    if (!selectedSite || !validateUUID(selectedSite)) return Promise.reject('Invalid Site Selected')
    let params = {
        siteId: selectedSite,
        documentType,
        folderId,
        limit: typeof limit === 'number' ? limit : 0,
        offset: typeof offset === 'number' ? offset : 0,
        ignoreParameters: false
    }
    const response = await axios.get(DocumentsAPI, {
        params
    })
    return response.data
}
/**
 * @param {string}            selectedSite - UUID of the SelectedSite, can be obtained via selectedSiteContext
 * @param {string}            filename     - string filename
 * @param {string}            folderId     - id of the current folder
 * @param {string}            documentType - The type of document (folder or document)
 * @param {number}            limit        - limit
 * @param {number}            offset       - offset
 * @param {Array<string>}     tags         - []string of tag ID's
 * @return {Promise<any>}
 * */
const getByParameter = async (selectedSite, tags, filename, folderId, documentType, limit, offset) => {
    if (!selectedSite || !validateUUID(selectedSite)) return Promise.reject('Invalid Site Selected')
    let params = {
        siteId: selectedSite,
        filename,
        tags: tags,
        folderId,
        documentType,
        ignoreParameters: false,
        limit: typeof limit === 'number' ? limit : 0,
        offset: typeof offset === 'number' ? offset : 0
    }
    const response = await axios.get(DocumentsAPI, {
        params
    })
    return { results: response.data.results, resultset: response.data.resultset }
}
/**
 * @param {string}            selectedSite - UUID of the SelectedSite, can be obtained via selectedSiteContext
 * @param {string}            documentId   - The documentId to be downloaded
 * @return {Promise<any>}
 * */
const getDocumentById = async (selectedSite, documentId) => {
    if (!selectedSite || !validateUUID(selectedSite)) return Promise.reject('Invalid Site Selected')
    if (!validateUUID(documentId)) return Promise.reject('Invalid Document ID')
    let params = {
        siteId: selectedSite
    }
    const response = await axios.get(`${DocumentsAPI}/${documentId}`, {
        params
    })
    return response.data
}
/**
 * @param {string}            selectedSite - UUID of the SelectedSite, can be obtained via selectedSiteContext
 * @param {string}            documentId   - The documentId to be downloaded
 * @return {Promise<any>}
 * */
const getDocumentAncestorsById = async (selectedSite, documentId) => {
    if (!selectedSite || !validateUUID(selectedSite)) return Promise.reject('Invalid Site Selected')
    if (!validateUUID(documentId)) return Promise.reject('Invalid Document ID')
    let params = {
        siteId: selectedSite
    }
    const response = await axios.get(`${DocumentsAPI}/${documentId}/ancestors`, {
        params
    })
    return response.data
}
/**
 * @param {string}            selectedSite - UUID of the SelectedSite, can be obtained via selectedSiteContext
 * @param {string}            documentId   - The document to be downloaded
 * @return {Promise<any>}
 * */
const getPDF = async (selectedSite, documentId) => {
    if (!selectedSite || !validateUUID(selectedSite)) return Promise.reject('Invalid Site Selected')
    if (!validateUUID(documentId)) return Promise.reject('Invalid Document ID')

    let params = {
        siteId: selectedSite,
        size: 'document',
        id: documentId
    }
    const response = await axios.get(S3API, {
        params
    })
    return response.data
}
/**
 * @param {string}            selectedSite - UUID of the SelectedSite, can be obtained via selectedSiteContext
 * @param {Object}            data - The file object to be created
 * @return {Promise<any>}
 * */
const createDocument = async (selectedSite, data) => {
    if (!selectedSite || !validateUUID(selectedSite)) return Promise.reject('Invalid Site Selected')
    let params = {
        siteId: selectedSite
    }
    const response = await axios.post(DocumentsAPI, data, {
        params
    })
    return response.data
}
/**
 * @param {string}            selectedSite - UUID of the SelectedSite, can be obtained via selectedSiteContext
 * @param {Object}            data - The document data which will be updated
 * @return {Promise<any>}
 * */
const editDocument = async (selectedSite, data) => {
    if (!selectedSite || !validateUUID(selectedSite)) return Promise.reject('Invalid Site Selected')
    let params = {
        siteId: selectedSite
    }
    const response = await axios.patch(`${DocumentsAPI}/${data.id}`, data, {
        params
    })
    return response.data
}
/**
 * @param {string}            selectedSite - UUID of the SelectedSite, can be obtained via selectedSiteContext
 * @param {Object}            data - The document data which will be updated
 * @return {Promise<any>}
 * */
const editDocumentsFolder = async (selectedSite, idArray, folderId) => {
    if (!selectedSite || !validateUUID(selectedSite)) return Promise.reject('Invalid Site Selected')
    let params = {
        siteId: selectedSite
    }
    const response = await axios.patch(
        `${DocumentsAPI}`,
        { ids: idArray, folderId: folderId },
        {
            params
        }
    )
    return response.data
}
/**
 * @param {string}            selectedSite - UUID of the SelectedSite, can be obtained via selectedSiteContext
 * @param {string}            documentId   - The document to replace
 * @param {string}            base64       - base64 encoded PDF string
 * @return {Promise<any>}
 * */
const replaceDocument = async (selectedSite, documentId, base64) => {
    if (!selectedSite || !validateUUID(selectedSite)) return Promise.reject('Invalid Site Selected')
    if (!base64 || !isBase64(base64)) return Promise.reject('Invalid Base64 binary')
    const params = {
        siteId: selectedSite
    }
    const data = {
        data: base64,
        id: documentId
    }
    const response = await axios.put(`${DocumentsAPI}/${documentId}`, data, {
        params
    })
    return response.data
}
/**
 * @param {string}              selectedSite - UUID of the SelectedSite, can be obtained via selectedSiteContext
 * @param {string[]}            documentIds  - the documentId of the document requested to be deleted
 * @return {Promise<any>}
 * */
const deleteDocuments = async (selectedSite, documentIds) => {
    if (!selectedSite || !validateUUID(selectedSite)) return Promise.reject('Invalid Site Selected')
    const params = newUrlParam(selectedSite, null, null, null, 'ids', documentIds)
    const response = await axios.delete(`${DocumentsAPI}`, {
        params
    })
    return response.data
}

/**
 *   Helpers
 **/
const isBase64 = (s) => s.includes(';base64')

export const documentService = {
    getAll,
    getAllByFolderAndType,
    getByParameter,
    getPDF,
    replaceDocument,
    getDocumentById,
    createDocument,
    editDocument,
    editDocumentsFolder,
    deleteDocuments,
    getDocumentAncestorsById
}
