import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $insertNodeToNearestRoot } from '@lexical/utils'
import { COMMAND_PRIORITY_EDITOR, createCommand, LexicalCommand, LexicalEditor } from 'lexical'
import { useEffect } from 'react'
import { $createContentFragmentNode, ContentFragmentNode } from './ContentFragmentNode'
import { Content } from '../../content/types'

export const INSERT_CONTENT_FRAGMENT_COMMAND: LexicalCommand<Content> = createCommand('INSERT_CONTENT_FRAGMENT_COMMAND')

export function insertContentFragment(activeEditor: LexicalEditor, content: Content) {
    activeEditor.dispatchCommand(INSERT_CONTENT_FRAGMENT_COMMAND, content)
}

export default function ContentFragmentPlugin(): JSX.Element | null {
    const [editor] = useLexicalComposerContext()

    useEffect(() => {
        if (!editor.hasNodes([ContentFragmentNode])) {
            throw new Error('ContentFragmentPlugin: ContentFragmentNode not registered on editor')
        }

        return editor.registerCommand<Content>(
            INSERT_CONTENT_FRAGMENT_COMMAND,
            (payload) => {
                const contentFragmentNode = $createContentFragmentNode(payload)
                $insertNodeToNearestRoot(contentFragmentNode)

                return true
            },
            COMMAND_PRIORITY_EDITOR
        )
    }, [editor])

    return null
}
