import { IconButton, Menu } from '@mui/material'
import { useState } from 'react'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

export const MenuLightCell = ({ itemsFactory }: { itemsFactory: (onClose: () => void) => any[] }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <div className={'site-options icon'}>
            <IconButton aria-controls={'pageMenu'} aria-haspopup='true' onClick={handleClick} size='large'>
                <MoreHorizIcon />
            </IconButton>
            <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
                {itemsFactory(handleClose)}
            </Menu>
        </div>
    )
}
