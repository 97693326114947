import { forwardRef, useCallback, useImperativeHandle, useState } from 'react'
import { FormStructure } from '../structure/types'
// import { DctCollection, DctSection } from '../_components'
import _ from 'lodash'
import { DctSection } from './DctSection'
import { DctCollection } from './DctCollection'
import { Box, BoxProps } from '@mui/material'
import { emailRegex } from '../../common/constants'

export function getAccordionSectionId(section, suffix) {
    return section.name
    // return section.title + section.name + suffix
}

// arrayOfSections = structure
export function getAccordionInitialState(
    arrayOfSections: Record<string, any>[],
    defaultIsOpen = true
): Record<string, boolean> {
    const initialValue = typeof defaultIsOpen === 'boolean' ? defaultIsOpen : true
    const isSectionExpandedMap =
        arrayOfSections?.reduce(
            (a, section, index) => ({
                ...a,
                [getAccordionSectionId(section, index)]: initialValue
            }),
            {}
        ) || {}

    return isSectionExpandedMap
}

interface FormRendererProps {
    value: any
    onChange: (value: any) => void
    formStructure: FormStructure[] | undefined

    accordionExpandedOnChange?: (id: string, expanded: boolean) => void
    disabled?: boolean
    boxProps?: BoxProps
}

const FormRenderer = forwardRef<any, FormRendererProps>(
    ({ value, onChange, formStructure, accordionExpandedOnChange, disabled, boxProps }, ref) => {
        const _getAccordionInitialState = useCallback(
            (expanded?: boolean) => {
                return getAccordionInitialState(formStructure || [], expanded)
            },
            [formStructure]
        )

        const [formStructureDataErrors, setFormStructureDataErrors] = useState({})
        const [accordion, setAccordion] = useState<Record<string, boolean>>({
            ..._getAccordionInitialState()
        })

        const validateValue = () => {
            if (!formStructure) {
                return [null, null]
            }
            const invalidDctComponentNames: string[] = []
            const errorsObj = _.cloneDeep(value)
            for (const section of formStructure) {
                for (const component of section.components as any) {
                    if (!errorsObj[section.name]) {
                        errorsObj[section.name] = {}
                    }
                    const sectionValues = value?.[section.name]
                    if (Array.isArray(sectionValues)) {
                        // allowMultiple: true
                        // TBD
                    } else {
                        const sectionComponentValue = value?.[section.name]?.[component.name]

                        if (component?.type === 'email') {
                            // email
                            if (
                                (component?.required || !!sectionComponentValue) &&
                                !emailRegex.test(sectionComponentValue)
                            ) {
                                errorsObj[section.name][component.name] = true
                                invalidDctComponentNames.push(component.name)
                            } else {
                                errorsObj[section.name][component.name] = false
                            }
                        } else if (typeof sectionComponentValue === 'object') {
                            // spotlight images
                            if (component?.required && sectionComponentValue.src && !sectionComponentValue.src.length) {
                                errorsObj[section.name][component.name] = true
                                invalidDctComponentNames.push(component.name)
                            } else {
                                errorsObj[section.name][component.name] = false
                            }
                        } else if (
                            (component?.required && !sectionComponentValue) ||
                            (component?.maximumLength && sectionComponentValue?.length > component?.maximumLength)
                        ) {
                            errorsObj[section.name][component.name] = true
                            invalidDctComponentNames.push(component.name)
                        } else {
                            errorsObj[section.name][component.name] = false
                        }
                    }
                }
            }
            setFormStructureDataErrors(errorsObj)
            const invalidSections: string[] = []
            Object.keys(errorsObj).forEach((section) => {
                if (errorsObj[section] && Object.values(errorsObj[section]).some((v) => v === true)) {
                    invalidSections.push(section)
                }
            })
            if (invalidSections.length) {
                const shouldExpand = {}
                invalidSections.forEach((section: string) => {
                    shouldExpand[section] = true
                })
                setAccordion({ ...accordion, ...shouldExpand })
            }
            return [invalidDctComponentNames, errorsObj]
        }

        useImperativeHandle(ref, () => {
            return {
                validate() {
                    const [invalidDctComponentNames, errorsObj] = validateValue()
                    return invalidDctComponentNames
                },
                validateAndReturnErrors() {
                    const [invalidDctComponentNames, errorsObj] = validateValue()
                    return [invalidDctComponentNames, errorsObj]
                },
                setAccordion(id: string, expanded: boolean) {
                    setAccordion({ ...accordion, [id]: expanded })
                },
                setAllAccordions(expanded: boolean) {
                    setAccordion(_getAccordionInitialState(expanded))
                }
            }
        })

        if (!formStructure) {
            return <></>
        }

        return (
            <Box {...boxProps}>
                {formStructure.map((section, index) => {
                    const accordionSectionId = getAccordionSectionId(section, index)
                    return section.allowMultiple ? (
                        // error handling within DctCollection
                        // error only shown for current item being edited on add/update since it is a list of items
                        // will prevent adding to list if error
                        <DctCollection
                            key={section.name + section.title + JSON.stringify(section)}
                            section={section}
                            contentModelData={value}
                            contentModelDataOnChange={(updatedValue) => {
                                onChange({
                                    ...value,
                                    [section.name]: updatedValue
                                })
                            }}
                            expanded={accordion[accordionSectionId]}
                            expandedOnChangeHandler={(expanded) => {
                                setAccordion({ ...accordion, [accordionSectionId]: expanded })
                                accordionExpandedOnChange?.(accordionSectionId, expanded)
                            }}
                            disabled={disabled || false}
                        />
                    ) : (
                        <DctSection
                            key={section.name + section.title + JSON.stringify(section)}
                            section={section}
                            // contentModelData={value?.[section.name]}
                            contentModelData={value}
                            contentModelDataOnChange={(updatedValue) => {
                                onChange({
                                    ...value,
                                    [section.name]: updatedValue
                                })
                            }}
                            contentModelDataErrors={formStructureDataErrors}
                            setContentModelDataErrors={setFormStructureDataErrors}
                            expanded={accordion[accordionSectionId]}
                            expandedOnChangeHandler={(expanded) => {
                                setAccordion({ ...accordion, [accordionSectionId]: expanded })
                                accordionExpandedOnChange?.(accordionSectionId, expanded)
                            }}
                            disabled={disabled || false}
                        />
                    )
                })}
            </Box>
        )
    }
)

export default FormRenderer
