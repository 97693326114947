import { useEffect, useState } from 'react'
import AppAccordion from '../../common/components/AppAccordion'
import { ReleaseNoteItem } from './ReleaseNotes'
import { Button, Typography } from '@mui/material'

function OldReleaseNote({ fileName }: { fileName: string }) {
    const [notes, setNotes] = useState<{ name: string; text: string } | null>(null)

    useEffect(() => {
        async function loadNotes() {
            try {
                const response = await fetch(`/releaseNotes/releasenotes/${fileName}`)
                if (response.ok) {
                    const text = await response.text()
                    setNotes({ name: fileName, text })
                } else {
                    console.warn(`Failed to load file: ${fileName}`)
                }
            } catch (err) {
                console.warn(err)
            }
        }

        loadNotes()
    }, [fileName])

    return (
        <AppAccordion
            defaultExpanded={false}
            key={notes?.name || ''}
            summary={<Typography variant='h6'>{notes?.name.replace('.md', '')}</Typography>}
            details={<ReleaseNoteItem notes={notes?.text || ''} />}
        />
    )
}

const files = [
    'Aug. 22 2024.md',
    'June 20 2024.md',
    'Ma. 22 2024.md',
    'Apr. 17 2024.md',
    'Mar. 14 2024.md',
    'Feb. 26 2024.md',
    'Jan. 19 2024.md',
    'Nov. 23 2023.md',
    'Nov. 1 2023.md',
    'Oct. 16 2023.md'
]

function OldReleaseNotes() {
    const [viewMore, setViewMore] = useState(false)

    if (viewMore) {
        return (
            <>
                {files.map((fileName) => (
                    <OldReleaseNote key={fileName} fileName={fileName} />
                ))}
            </>
        )
    }

    return (
        <Button size={'large'} variant={'outlined'} onClick={() => setViewMore(true)}>
            View More
        </Button>
    )
}

export default OldReleaseNotes
