import React, { Fragment, useEffect, useState } from 'react'
import { Format, notify, validateUUID } from '../../helpers'
import { contentService } from '../content/content.service'
import { navigationService } from '../navigation/navigation.service'
import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, FormLabel } from '@mui/material'
import { DepartmentSelector } from './DepartmentSelector'
import { SiteSelectorForContent } from '../../common/components/selectors/SiteSelectorForContent'
import { contentTypes } from '../../common/components/selectors/SiteSelectorForAccount'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import UndoIcon from '@mui/icons-material/Undo'
import { asSecured } from '../auth/permissions/securityMapping'
import { useTenantSites } from '../auth/atoms'

interface DepartmentTransferDialogProps {
    contentModel?: {
        id: string
        departmentId: string | null
        contentType: contentTypes
        sites: string[]
        settings: any
    } | null
    OnTransfer: (response: Record<string, any>) => any
    IsOpen: boolean
    CloseMenu: () => void
}

// TODO => remove bloat, split up from menu, with sites and reverting from department it isn't as lean as it should be
export const DepartmentTransferDialog = ({
    contentModel,
    OnTransfer,
    IsOpen,
    CloseMenu
}: DepartmentTransferDialogProps) => {
    const tenantSites = useTenantSites()
    // Get department
    // 1 - query
    // 2 - siteContext
    // 3 - parentProp
    const [original, setOriginal] = useState<string | undefined>()
    const [idRef, setIdRef] = useState('')
    const [isRemovingFromDepartment, setIsRemovingFromDepartment] = useState(false)
    const [nextSites, setNextSites] = useState<string[]>([])

    useEffect(() => {
        // TODO => nulluuid
        const id = Format.fromNullUUID(contentModel?.departmentId || '')
        if (contentModel?.departmentId !== idRef && validateUUID(id)) {
            setIdRef(id)
            setOriginal(id)
        }
    }, [contentModel?.departmentId])

    useEffect(() => {
        if (idRef) {
            const targetDepartment = tenantSites.find((site) => site.ID === idRef)
            if (targetDepartment) {
                setNextSites(targetDepartment.Hosts)
            }
        } else {
            console.log('--- idRef is empty -----')
            console.log({ contentModel, nextSites })
        }
    }, [idRef])

    const transfer = () => {
        handleChange().finally(closeDialog)
    }
    const closeDialog = () => CloseMenu?.()
    const handleChange = async () => {
        try {
            const contentId = contentModel?.id || ''
            if (!validateUUID(contentId) || !validateUUID(idRef)) {
                console.log('DepartmentTransferDialog#1', { idRef, id: contentId })
                return Promise.reject({ message: 'invalid id' })
            }
            if (nextSites.length === 0) {
                notify('No sites selected', 'error')
                return Promise.reject({ message: 'no sites selected' })
            }
            // TODO => departments temp work
            const { data } = await contentService.patchContent('', {
                id: contentId,
                departmentId: isRemovingFromDepartment && nextSites.length > 0 ? null : idRef,
                sites: nextSites,
                settings: { ...contentModel?.settings, isPrimary: false },
                // TODO => This makes sense if we're automatically assigning & requiring a minimum of 1 site.
                //  This could be an option in the form for the user to choose.
                published: false
            })
            await navigationService.deleteByContentId(contentId)
            OnTransfer?.(data)

            // TODO => nulluuid
            setIdRef(Format.fromNullUUID(data?.departmentId || null))
            setIsRemovingFromDepartment(false)
            console.log('DepartmentTransferDialog#2', { data })
        } catch (err: any) {
            const errMessage = err?.response?.data || err?.message || 'Undefined error'
            notify(`Can't transfer a content with error: ${errMessage}`, 'error')
            console.log({ ...err })
        }
    }
    const startConvertToNonDepartment = () => {
        setIsRemovingFromDepartment(true)
    }
    const backToDep = () => {
        setIsRemovingFromDepartment(false)
        setNextSites([])
    }
    // TODO => nulluuid
    const fmtId = Format.fromNullUUID(idRef)
    const secured = asSecured(contentModel)
    return (
        <Fragment>
            <Dialog open={IsOpen} fullWidth>
                <DialogTitle>Transfer</DialogTitle>
                <DialogContent style={{ paddingTop: '8px' }}>
                    {contentModel?.contentType && isRemovingFromDepartment ? (
                        <SiteSelectorForContent
                            IgnoreSiteID={true}
                            ContentType={secured.EntityScope}
                            Selected={nextSites}
                            HasError={Boolean(nextSites?.length === 0)}
                            OnChange={setNextSites}
                        />
                    ) : (
                        <DepartmentSelector departmentId={fmtId} onChange={setIdRef} />
                    )}
                </DialogContent>
                <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        {validateUUID(original) && !isRemovingFromDepartment && contentModel?.contentType && (
                            <Button
                                onClick={startConvertToNonDepartment}
                                variant={'outlined'}
                                color={'primary'}
                                sx={{
                                    '& .MuiButton-startIcon': {
                                        marginBottom: '3px'
                                    }
                                }}
                                startIcon={
                                    <>
                                        <UndoIcon style={{ marginTop: '0.15rem' }} />
                                        <CorporateFareIcon />
                                    </>
                                }
                            >
                                Revert
                            </Button>
                        )}
                    </div>
                    <div>
                        {isRemovingFromDepartment ? (
                            <Button variant={'outlined'} color={'primary'} onClick={backToDep}>
                                Back
                            </Button>
                        ) : (
                            <Button variant={'outlined'} color={'primary'} onClick={closeDialog}>
                                Close
                            </Button>
                        )}
                        <Button
                            variant={'outlined'}
                            style={{ marginLeft: '0.15rem' }}
                            color={'warning'}
                            disabled={!validateUUID(fmtId)}
                            onClick={transfer}
                        >
                            Transfer
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

interface DepartmentWidgetProps {
    department: { name: string } | null
    cardProps?: any
}

// "ck-editor-options"
export const DepartmentWidgetForEditor = ({ department, cardProps }: DepartmentWidgetProps) => {
    if (!department?.name) {
        return ''
    }
    return (
        <Card {...cardProps}>
            <FormLabel>Departments</FormLabel>
            <div>
                <Button
                    disabled
                    startIcon={<CorporateFareIcon />}
                    style={{
                        backgroundColor: '#4E01D5',
                        color: 'white',
                        width: '100%',
                        fontSize: '0.85rem'
                    }}
                >
                    {department.name}
                </Button>
            </div>
        </Card>
    )
}
