import { useQuery } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import { notify } from '../../helpers'
import { ContentAPI, pinnedNewsQueryKey } from '../constants'
import { ContentType } from '../../pkgs/content/types'

export default function usePinnedNewsQueryMutation(
    siteId: string | undefined,
    onSuccess?: (data) => void,
    onError?: (e) => void,
    enabled = true // set to false to disable fetch on mount
) {
    const {
        data: pinnedNewsData,
        isLoading,
        error,
        refetch
    } = useQuery({
        enabled: enabled,
        queryKey: [pinnedNewsQueryKey, siteId],
        queryFn: () =>
            axios
                .get(ContentAPI, {
                    params: {
                        siteId: siteId,
                        contentType: ContentType.News,
                        page: 0,
                        limit: 999,
                        usePriority: true
                    }
                })
                .then((res) => (res.data as any).results) // returns array of news
    })

    // Re-ordered pins is passed to NewsTableRow => Priority # is calculated ON MOVE
    // On up/down, re-ordered pins is populated with the pins which have been moved
    // On Save => Take calculated value and PUT  => Get new resources on last PUT
    const handleSavePinnedOrder = (reorderedPins: any[], onSuccess: () => void) => {
        const promises: Promise<AxiosResponse<any>>[] = []
        for (const newsItem of reorderedPins) {
            if (newsItem.disabled) {
                continue
            }
            const obj = { ...newsItem, startdate: null, enddate: null }
            promises.push(axios.put(ContentAPI, obj, { params: { siteId: siteId } }))
        }
        Promise.all(promises).then(
            () => {
                refetch()
                // setReorderedPins([]);
                notify('Success! Pinned items have been re-ordered', 'info')
                onSuccess()
            },
            (e) => {
                console.log(e)
                notify('Whoops! Error saving priority', 'error')
            }
        )
    }

    return { pinnedNewsData, isLoading, error, refetch, handleSavePinnedOrder }
}
