import { useEffect, useRef } from 'react'
import { atom, useAtom } from 'jotai'
import { z } from 'zod'
import { httpGet } from '../../common/client'
import { sitesEventChanel } from '../../common/components/selectors/SiteSelectorForAccount'
import { useNavigate } from 'react-router-dom'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import { Button } from '@mui/material'
import { InstagramErrorsAPI } from '../../common/constants'

const ids = z.array(z.string())

export const instagramErrorsAtom = atom<string[]>([])
export const bannerHeightAtom = atom<number>(0)

export const InstagramErrorsBanner = () => {
    const [errors, setErrors] = useAtom(instagramErrorsAtom)
    const [, setHeight] = useAtom(bannerHeightAtom)
    const navigate = useNavigate()

    const loadErrors = () => {
        httpGet(InstagramErrorsAPI, null, ids)
            .then((res) => {
                setErrors(res)
            })
            .catch((err) => {
                console.error(err)
            })
    }

    const bannerEel = useRef<HTMLDivElement>(null)

    useEffect(() => {
        loadErrors()
        const handler = sitesEventChanel.on('onSiteSecureSettingsUpdated', loadErrors)
        const timer = setInterval(
            () => {
                loadErrors()
            },
            10 * 60 * 1000
        )
        return () => {
            clearInterval(timer)
            sitesEventChanel.off('onSiteSecureSettingsUpdated', handler)
        }
    }, [])

    useEffect(() => {
        if (!bannerEel.current) {
            return
        }
        const resizeObserver = new ResizeObserver(() => {
            setHeight(bannerEel.current?.clientHeight || 0)
        })
        resizeObserver.observe(bannerEel.current)
        return function cleanup() {
            resizeObserver.disconnect()
        }
    }, [bannerEel.current])

    return (
        <div
            style={{
                backgroundColor: 'red',
                color: 'white',
                zIndex: 1,
                padding: 10,
                textAlign: 'center',
                position: 'fixed',
                left: 0,
                right: 0,
                top: 0,
                display: errors.length === 0 ? 'none' : 'block'
            }}
            ref={bannerEel}
        >
            <ReportProblemIcon style={{ verticalAlign: 'middle', marginRight: 10 }} />
            <strong>ALERT</strong> You have{' '}
            <strong>
                {errors.length} issue{errors.length > 1 ? 's' : ''}
            </strong>{' '}
            with Instagram tokens.&nbsp;
            <Button
                style={{ color: 'white' }}
                variant={'text'}
                onClick={() => {
                    const next = errors.length === 1 ? `/edit/${errors[0]}` : ''
                    navigate(`/system/sites${next}?${new URLSearchParams(window.location.search).toString()}`)
                }}
            >
                Update now
            </Button>
        </div>
    )
}
