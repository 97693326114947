import { useContext, useEffect } from 'react'
import { DepartmentList } from './DepartmentList'
import { disabledContext } from '../../common/DisabledContext'
import SidebarContainer from '../../common/components/SidebarContainer'

export default function DepartmentManager() {
    const [, setDisabled] = useContext(disabledContext)

    useEffect(() => {
        // @ts-ignore
        setDisabled(false)
    }, [])

    return <SidebarContainer title='Departments' tabs={[{ name: 'departments', content: <DepartmentList /> }]} />
}
