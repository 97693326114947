import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import imageCompression from 'browser-image-compression'
import { notify } from '../../../helpers'
import { z } from 'zod'
import { Typography } from '@mui/material'

const getColor = (props) => {
    if (props.isDragAccept) {
        return '#00e676'
    }
    if (props.isDragReject) {
        return '#ff1744'
    }
    if (props.isDragActive) {
        return '#2196f3'
    }
    return '#eeeeee'
}

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: ${(props) => getColor(props)};
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
`

export const mediaObject = z.object({
    data: z.string(),
    thumbnail: z.string(),
    filename: z.string()
})
export type MediaObject = z.infer<typeof mediaObject>
export const ImageLoader = ({
    onChange = () => {},
    maxFiles,
    preview,
    additionalText
}: {
    onChange?: (m: MediaObject) => void
    maxFiles?: number
    preview?: string
    additionalText?: string
}) => {
    const maxImageWidth = 2000
    const maxImageHeight = 2000

    // New Feature for Browser-Image-Compression - initialQuality field for options.
    // TODO: Investigate need for further image compression
    let fullSizeOptions = {
        maxSizeMB: 2,
        maxWidthOrHeight: 2048
        // onProgress: Function,       // optional, a function takes one progress argument (percentage from 0 to 100)
    }
    let thumbOptions = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 520
    }
    let fullResOptions = {
        maxWidthOrHeight: 2048
    }

    const compressImageByOption = async (file: File, mediaObject, options) => {
        await imageCompression(file, options)
            .then((result) => {
                let compressed = new FileReader()
                compressed.readAsDataURL(result)
                compressed.onloadend = async () => {
                    mediaObject['data'] = compressed.result
                    await imageCompression(result, thumbOptions)
                        .then((result) => {
                            let compressed = new FileReader()
                            compressed.readAsDataURL(result)
                            compressed.onloadend = () => {
                                mediaObject['thumbnail'] = compressed.result
                                mediaObject['filename'] = file.name
                                // Insert the item into the media array
                                onChange(mediaObject)
                            }
                        })
                        .catch((err) => console.log(err))
                }
            })
            .catch((err) => {
                notify('Whoops! Invalid file extension - Please try again with a valid file extension', 'error', {
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    autoClose: 3500
                })
                console.log(err)
            })
    }

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            let sampleMediaObject: MediaObject = {
                filename: file.name,
                data: '',
                thumbnail: ''
            }
            const reader = new FileReader()
            const image = new Image()
            image.src = URL.createObjectURL(file)
            // FileReader Events
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = async () => {
                // If SVG or GIF
                if (file.type === 'image/svg+xml' || (file.name || '').toLowerCase().endsWith('.gif')) {
                    const svgFile = new FileReader()
                    svgFile.readAsDataURL(file)
                    return (svgFile.onloadend = async () => {
                        if (svgFile.result === null || typeof svgFile.result !== 'string') return
                        const sampleMediaObject: MediaObject = {
                            filename: file.name,
                            data: svgFile.result,
                            thumbnail: svgFile.result
                        }
                        onChange(sampleMediaObject)
                    })
                }

                // Else If bitmap
                else if (file.type === 'image/bmp') {
                    let bmpOptions = {
                        fileType: 'image/jpeg'
                    }
                    if (file.size > 2500000) {
                        bmpOptions['maxSizeMB'] = 2
                    }
                    if (image.width > maxImageWidth || image.height > maxImageHeight) {
                        bmpOptions['maxWidthOrHeight'] = 2048
                    }

                    return await compressImageByOption(file, sampleMediaObject, bmpOptions)
                }

                // If file > 2.5MB
                if (file.size > 2500000) {
                    // Greater than 2000x2000 ?
                    if (image.width > maxImageWidth || image.height > maxImageHeight) {
                        await compressImageByOption(file, sampleMediaObject, fullSizeOptions)
                    } else {
                        await compressImageByOption(file, sampleMediaObject, fullSizeOptions)
                    }
                } else {
                    await compressImageByOption(file, sampleMediaObject, fullResOptions)
                }
            }

            // reader.readAsArrayBuffer(file)
            reader.readAsDataURL(new Blob([file]))
        })
    }, [])
    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        maxFiles: maxFiles,
        onDrop,
        accept: '.jpeg, .png, .svg, .bmp, .jfif, .jpg, .gif'
        // acceptedMimeTypes:".jpeg, .png, .svg, .bmp, .jfif, .jpg",
        // createImageThumbnails:true,
        // thumbnailHeight:'520px',
        // thumbnailWidth:'520px',
    })

    return (
        <div className='row'>
            <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
                <input {...getInputProps({ multiple: maxFiles !== 1 })} />
                {preview ? (
                    <div
                        style={{
                            height: 250,
                            cursor: 'pointer',
                            width: '100%',
                            backgroundImage: `${preview}`,
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundColor: '#f5f5f5'
                        }}
                    ></div>
                ) : (
                    <p>Drag 'n' drop some files here, or click to select files</p>
                )}
                {additionalText && (
                    <Typography style={{ color: 'black', marginTop: '0.75em' }}>{additionalText}</Typography>
                )}
            </Container>
        </div>
    )
}
