import { useEffect } from 'react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
    $getSelection,
    $isParagraphNode,
    $isRangeSelection,
    $isRootNode,
    $isTextNode,
    $setSelection,
    COMMAND_PRIORITY_LOW,
    KEY_DOWN_COMMAND,
    LexicalCommand,
    TextNode
} from 'lexical'
import {INSERT_UNORDERED_LIST_COMMAND, INSERT_ORDERED_LIST_COMMAND} from "@lexical/list"
import { $isCollapsibleTitleNode } from '@/pkgs/lexical-editor/Collapsible/CollapsibleTitleNode'


export function InsertListListenerPlugin() {
    const [editor] = useLexicalComposerContext()

    function onPressSpaceWithinRootParagraph(event: KeyboardEvent) {
        if (event.key !== ' ' || event.code !== 'Space') {
            return false
        }
        const $selection = $getSelection()
        if (!$isRangeSelection($selection)) {
            return false
        }
        const textNode = $selection?.getNodes()?.[0]
        if (textNode == null || !$isTextNode(textNode)) {
            return false
        }
        const textContent = textNode.getTextContent()
        if (!textContent.trim().startsWith('-') && !textContent.trim().startsWith('1.')) {
            return false
        }
        const keyAndCommand = getKey(textContent, $selection.anchor.offset)
        if (keyAndCommand == null) {
            return false
        }
        // Any paragraph node that is not within a Collapsible Title is valid.
        const parentNodes = textNode.getParents()
        if (!$isParagraphNode(parentNodes?.[0]) || parentNodes.some(node => $isCollapsibleTitleNode(node))) {
            return false
        }

        const [key, command] = keyAndCommand
        removeKeyFromTextNodeContent(textNode, key)
        resetCursorPosition()
        editor.dispatchCommand(command, undefined)
        return true
    }

    useEffect(() => {
        editor.registerCommand<KeyboardEvent>(KEY_DOWN_COMMAND, onPressSpaceWithinRootParagraph, COMMAND_PRIORITY_LOW)
    }, [])

    function isTextPrecedingIndex(source: string, text: string, index: number) {
        const target = source.substring(index - text.length, index)
        return target === text
    }

    function getKey(source: string, index: number): [string, LexicalCommand<void>] | null {
        if (isTextPrecedingIndex(source, '-', index)) {
            return ['-', INSERT_UNORDERED_LIST_COMMAND]
        }
        if (isTextPrecedingIndex(source, '1.', index)) {
            return ['1.', INSERT_ORDERED_LIST_COMMAND]
        }
        return null
    }

    function removeKeyFromTextNodeContent(textNode: TextNode, key: string) {
        const textContent = textNode.getTextContent().trimStart()
        const replaced = textContent.replace(key, '')
        textNode.setTextContent(replaced.trimStart())
    }

    function resetCursorPosition() {
        const $selection = $getSelection()
        if ($isRangeSelection($selection)) {
            $selection.anchor.offset = 0
            $selection.focus.offset = 0
            $setSelection($selection)
        }
    }
    return null
}