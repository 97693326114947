import React, { Fragment, useCallback, useEffect, useState } from 'react'
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress,
    TextField,
    Typography
} from '@mui/material'
import clsx from 'clsx'
import ImageIcon from '@mui/icons-material/Image'
import { ImageGallery } from '../../pkgs/media/image/ImageGallery'
import { Format, is, validateUUID } from '../../helpers'
import { useCurrentSiteID } from '../../pkgs/auth/atoms'
import _ from 'lodash'
import './SeoOptions.css'
import { MEDIA_THUMBNAILS } from '../constants'

export function getContentSEO(obj) {
    return {
        mediaId: obj?.mediaId || '',
        seoTitle: obj?.settings?.seoTitle || '',
        seoDescription: obj?.settings?.seoDescription || ''
    }
}

export function SeoOptions({ value, onChange, disabled, noWrapper }) {
    const currentSiteID = useCurrentSiteID()
    const [asset, setAsset] = useState<string | null>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isGalleryOpen, setIsGalleyOpen] = useState(false)
    const [isConfirmOpen, setIsConfirmOpen] = useState(false)
    // Todo => generalize this, and remove its understanding of content model
    const [fields, setFields] = useState({
        mediaId: null,
        seoDescription: '',
        seoTitle: ''
    })

    useEffect(() => {
        const mediaId = Format.fromNullUUID(value?.mediaId)
        if (validateUUID(mediaId)) {
            setAsset(`${MEDIA_THUMBNAILS}/${mediaId}`)
        } else {
            setAsset(null)
        }
    }, [value?.mediaId, currentSiteID])

    useEffect(() => {
        if (is.object(value)) {
            setFields(value)
        }
    }, [value?.mediaId, value?.seoDescription, value?.seoTitle, currentSiteID])

    const handleImage = (v) => {
        const { id = '' } = v || {}
        const fc = _.cloneDeep(fields)
        fc.mediaId = id || null
        if (is.func(onChange)) {
            onChange(fc)
        }
        setFields(fc)
    }
    const handleText = (evt) => {
        const { name, value } = evt?.target || {}
        const fc = _.cloneDeep(fields)
        if (typeof fc?.[name] !== 'string') {
            return
        }
        fc[name] = value
        if (is.func(onChange)) {
            onChange(fc)
        }
        setFields(fc)
    }
    const handleRemove = () => {
        setIsConfirmOpen(false)
        handleImage({ id: null })
    }
    const handleReplace = () => {
        setIsGalleyOpen(true)
        setIsConfirmOpen(false)
    }
    const openConfirm = () => {
        if (disabled) {
            return
        }
        setIsConfirmOpen(true)
    }
    const openGallery = () => {
        if (disabled) {
            return
        }
        setIsConfirmOpen(false)
        setIsGalleyOpen(true)
    }

    const Wrapper = useCallback(
        ({ children }) =>
            noWrapper ? (
                <>{children}</>
            ) : (
                <Card className='m10'>
                    <CardContent>{children}</CardContent>
                </Card>
            ),
        [noWrapper]
    )
    return (
        <Fragment>
            <Wrapper>
                {isLoading && (
                    <span className='flex-row-align-center' style={{ justifyContent: 'center' }}>
                        <LinearProgress style={{ width: '100%' }} />
                    </span>
                )}
                {asset && asset?.length > 0 && (
                    <div
                        onClick={openConfirm}
                        className={`preview ${disabled ? '' : 'cursor-pointer'}`}
                        style={{
                            backgroundImage: `url(${asset})`,
                            width: '100%',
                            height: '100%'
                        }}
                    />
                )}
                {is.func(onChange) && (
                    <Fragment>
                        {!asset && !isLoading && !disabled && (
                            <Fragment>
                                <Typography component={'p'} variant='h6' gutterBottom>
                                    Preview Image
                                </Typography>
                                <div className='open-gallery flex-row' onClick={openGallery}>
                                    <p
                                        className={clsx('material-blue')}
                                        style={{ marginLeft: '5px', marginRight: '5px' }}
                                    >
                                        Add an image
                                    </p>
                                    <ImageIcon className='material-blue' />
                                </div>
                            </Fragment>
                        )}
                        <p className='MuiFormHelperText-root'>
                            This preview image will accompany the SEO title and description below when a link to this
                            content is shared on social media.
                        </p>
                    </Fragment>
                )}
                <Typography style={{ marginTop: '1rem' }} component={'p'} variant='h6' gutterBottom>
                    Search Engine Optimization
                </Typography>
                <TextField
                    variant='standard'
                    label={'Title'}
                    name={'seoTitle'}
                    helperText={'A title should be about 50 - 70 characters in length'}
                    value={fields.seoTitle || ''}
                    disabled={disabled}
                    onChange={handleText}
                />
                <TextField
                    multiline={true}
                    variant='standard'
                    label={'Description'}
                    name={'seoDescription'}
                    helperText={
                        'A description should be about 100 - 150 characters in length and contain descriptive words that prospective parents, students and staff may be searching for'
                    }
                    value={fields.seoDescription || ''}
                    disabled={disabled}
                    onChange={handleText}
                />
            </Wrapper>

            <Dialog open={isConfirmOpen} keepMounted onClose={() => setIsConfirmOpen(false)}>
                <DialogTitle>Cover Image</DialogTitle>
                <DialogContent>
                    <DialogContentText>Would you like to replace or remove this cover image?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRemove} color='primary'>
                        Remove
                    </Button>
                    <Button onClick={handleReplace} size='small' color='primary'>
                        {isLoading ? <CircularProgress style={{ height: 30, width: 30 }} /> : 'Replace'}
                    </Button>
                </DialogActions>
            </Dialog>

            <ImageGallery
                open={isGalleryOpen}
                // setGalleryOpen={openGallery}
                close={() => setIsGalleyOpen(false)}
                // isForEditor={true}
                onChange={handleImage}
            />
        </Fragment>
    )
}

export default SeoOptions
