import axios, { AxiosError } from 'axios'

export const guessErrorMessage = (err: any): string => {
    if (axios.isAxiosError(err)) {
        const axiosError = err as AxiosError
        const error = axiosError.response?.data || axiosError.message || 'Unknown error'
        if (typeof error === 'string') {
            return error
        } else if (typeof error['ErrorMessage'] === 'string') {
            return error['ErrorMessage']
        } else if (typeof error['message'] === 'string') {
            return error['message']
        } else {
            return JSON.stringify(error)
        }
    } else {
        if (err?.response?.statusText) return err.response.statusText
        return JSON.stringify(err, null, 2)
    }
}
