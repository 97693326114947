import React, { FC } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import CircularProgress from '@mui/material/CircularProgress'

interface ConfirmActionProps {
    open: boolean
    handleAgree: () => void
    handleClose: () => void
    handleDisagree: () => void
    text: string
    additionalText?: string
    title: string
    isLoading?: boolean
}

const ConfirmAction: FC<ConfirmActionProps> = ({
    open,
    handleAgree,
    handleClose,
    handleDisagree,
    text,
    additionalText,
    title,
    isLoading
}) => {
    return (
        <div>
            {open && (
                <Dialog
                    open={open}
                    keepMounted
                    onClose={handleClose}
                    aria-labelledby='alert-dialog-slide-title'
                    aria-describedby='alert-dialog-slide-description'
                >
                    <DialogTitle>{title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{text}</DialogContentText>
                        {additionalText && <DialogContentText>{additionalText}</DialogContentText>}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDisagree} color='primary'>
                            Disagree
                        </Button>
                        <Button color='primary' onClick={handleAgree}>
                            {isLoading ? <CircularProgress style={{ height: 30, width: 30 }} /> : 'Agree'}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    )
}

export default ConfirmAction
