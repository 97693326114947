import Alert from '@mui/material/Alert'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Collapse from '@mui/material/Collapse'
import React, { useState } from 'react'
import { AlertColor } from '@mui/material/Alert/Alert'
import { Box, Button } from '@mui/material'

interface CollapsibleAlertProps {
    initialState?: boolean
    severity: AlertColor
    style?: Record<string, number | string>
    children?: React.ReactNode
}

export function CollapsibleAlert({ initialState = false, severity, style, children }: CollapsibleAlertProps) {
    const [open, setOpen] = useState<boolean>(initialState ?? false)

    return (
        <Box style={style}>
            <Button style={{ width: '100%', padding: 0 }} onClick={() => setOpen(!open)} fullWidth>
                <Alert
                    severity={severity}
                    action={
                        <IconButton aria-label='close' color='inherit' size='small' onClick={() => setOpen(!open)}>
                            {open ? <CloseIcon fontSize='inherit' /> : <KeyboardArrowDownIcon fontSize='inherit' />}
                        </IconButton>
                    }
                    sx={{ width: '100%', textTransform: 'none' }}
                >
                    {open ? 'Minimize assistance by clicking the close icon' : 'Click for assistance'}
                </Alert>
            </Button>
            <Collapse in={open}>
                <Alert severity={severity} icon={false}>
                    {children}
                </Alert>
            </Collapse>
        </Box>
    )
}

export default CollapsibleAlert
