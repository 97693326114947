import { ReactNode, useCallback, useEffect, useRef } from 'react'
import { $createParagraphNode, $getSelection, $isRangeSelection } from 'lexical'
import { $setBlocksType, $wrapNodes } from '@lexical/selection'
import { $createHeadingNode, $createQuoteNode } from '@lexical/rich-text'
import { INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, REMOVE_LIST_COMMAND } from '@lexical/list'
import { $createCodeNode, CodeNode } from '@lexical/code'
import { BlockType, blockTypeToBlockName } from './Toolbar'
import { IconButton, SvgIconTypeMap, Typography } from '@mui/material'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import FormatQuoteIcon from '@mui/icons-material/FormatQuote'
import HtmlIcon from '@mui/icons-material/Html'
import AbcIcon from '@mui/icons-material/Abc'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { colours } from '../../../common/colours'

// src: https://codesandbox.io/s/lexical-rich-text-example-5tncvy?from-embed=&file=/src/Editor.js
const RICH_TEXT_HEADING_TYPES = ['h2', 'h3', 'h4', 'h5', 'h6'] as const

const richTextHeadingIconButtonConfig = RICH_TEXT_HEADING_TYPES.reduce(
    (a, headingType) => ({
        ...a,
        [headingType]: {
            icon: headingType.toUpperCase(),
            label: `Heading ${headingType[1].toUpperCase()}`
        }
    }),
    {}
)

interface BlockTypeIconButtonConfig {
    icon: OverridableComponent<SvgIconTypeMap> | string
    label?: ReactNode
}

export const blockTypeIconButtonConfig: Record<string, BlockTypeIconButtonConfig> = {
    paragraph: {
        icon: AbcIcon
    },
    ...richTextHeadingIconButtonConfig,
    ul: {
        icon: FormatListBulletedIcon
    },
    ol: {
        icon: FormatListNumberedIcon
    },
    quote: {
        icon: FormatQuoteIcon
    },
    [CodeNode.getType()]: {
        icon: HtmlIcon,
        label: 'Formatted Code'
    }
}

export function getBlockTypeIcon(blockType) {
    const Icon = blockTypeIconButtonConfig[blockType].icon

    if (typeof Icon == 'string') {
        return (
            <span
                style={{
                    fontSize: '21px',
                    paddingRight: 5
                }}
            >
                {Icon}
            </span>
        )
    }

    return <Icon style={{ fontSize: '21px', paddingRight: 5 }} />
}

export function getBlockTypeLabel(blockType) {
    return blockTypeIconButtonConfig[blockType]?.label || blockTypeToBlockName[blockType]
}

type RichTextHeading = (typeof RICH_TEXT_HEADING_TYPES)[number]

export function BlockOptionsDropdownList({ editor, blockType, buttonRef, setShowBlockOptionsDropDown }) {
    const dropDownRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        const button: HTMLElement = buttonRef.current
        const dropDown = dropDownRef.current

        if (button !== null && dropDown !== null) {
            dropDown.style.top = `${button.offsetTop + 40}px`
            dropDown.style.left = `${button.offsetLeft}px`
        }
    }, [dropDownRef, buttonRef])

    useEffect(() => {
        const dropDown = dropDownRef.current
        const button = buttonRef.current

        if (dropDown !== null && button !== null) {
            const handle = (event) => {
                const target = event.target

                if (!dropDown.contains(target) && !button.contains(target)) {
                    setShowBlockOptionsDropDown(false)
                }
            }
            document.addEventListener('click', handle)

            return () => {
                document.removeEventListener('click', handle)
            }
        }
    }, [dropDownRef, setShowBlockOptionsDropDown, buttonRef])

    const formatParagraph = () => {
        if (blockType !== 'paragraph') {
            editor.update(() => {
                const selection = $getSelection()

                if ($isRangeSelection(selection)) {
                    $wrapNodes(selection, () => $createParagraphNode())
                }
            })
        }
        setShowBlockOptionsDropDown(false)
    }

    const formatHeading = (h: RichTextHeading) => {
        if (blockType !== h) {
            editor.update(() => {
                const selection = $getSelection()
                if ($isRangeSelection(selection)) {
                    $wrapNodes(selection, () => $createHeadingNode(h))
                }
            })
        }
        setShowBlockOptionsDropDown(false)
    }

    const formatBulletList = () => {
        if (blockType !== 'ul') {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND)
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND)
        }
        setShowBlockOptionsDropDown(false)
    }

    const formatNumberedList = () => {
        if (blockType !== 'ol') {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND)
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND)
        }
        setShowBlockOptionsDropDown(false)
    }

    const formatQuote = () => {
        if (blockType !== 'quote') {
            editor.update(() => {
                const selection = $getSelection()

                if ($isRangeSelection(selection)) {
                    $wrapNodes(selection, () => $createQuoteNode())
                }
            })
        }
        setShowBlockOptionsDropDown(false)
    }

    const formatCode = () => {
        if (blockType !== 'code') {
            editor.update(() => {
                let selection = $getSelection()

                if (selection !== null) {
                    // @ts-ignore
                    if (selection.isCollapsed()) {
                        // @ts-ignore
                        $setBlocksType(selection, () => $createCodeNode())
                    } else {
                        const textContent = selection.getTextContent()
                        const codeNode = $createCodeNode()
                        selection.insertNodes([codeNode])
                        selection = $getSelection()
                        if ($isRangeSelection(selection)) {
                            selection.insertRawText(textContent)
                        }
                    }
                }
            })
        }

        setShowBlockOptionsDropDown(false)
    }

    const getBlockTypeFormatHandler = useCallback(
        (blockType: BlockType) => {
            if (RICH_TEXT_HEADING_TYPES.find((t) => t == blockType)) {
                return () => formatHeading(blockType as RichTextHeading)
            } else if (blockType == 'paragraph') {
                return formatParagraph
            } else if (blockType == 'ul') {
                return formatBulletList
            } else if (blockType == 'ol') {
                return formatNumberedList
            } else if (blockType == 'quote') {
                return formatQuote
            } else if (blockType == 'code') {
                return formatCode
            }

            return null
        },
        [formatHeading, formatParagraph, formatBulletList, formatNumberedList, formatQuote, formatCode]
    )

    // TODO => Add H2 -> H6
    //  Rename from Large/Med to H<number>
    //  fix blockType == .. checks looking for h1 to place active class, H1 not an option ATM and it's duplicated here.
    //  look to use something like formatHeading above since we're adding a number of Heading tags.
    return (
        <div className='dropdown' ref={dropDownRef}>
            {Object.keys(blockTypeIconButtonConfig).map((_blockType) => {
                const isActive = _blockType == blockType

                return (
                    <IconButton
                        key={_blockType}
                        className='item'
                        onClick={getBlockTypeFormatHandler(_blockType) || undefined}
                        style={
                            isActive
                                ? {
                                      backgroundColor: colours.off_white
                                  }
                                : undefined
                        }
                    >
                        {getBlockTypeIcon(_blockType)}
                        <Typography>
                            {blockTypeIconButtonConfig[_blockType]?.label || blockTypeToBlockName[_blockType]}
                        </Typography>
                    </IconButton>
                )
            })}
        </div>
    )
}
