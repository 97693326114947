import { TreeView } from '@lexical/react/LexicalTreeView'
import { LexicalEditor } from 'lexical'

export default function TreeViewer({ editor }: { editor: LexicalEditor | undefined }) {
    if (!editor) {
        return null
    }
    return (
        <TreeView
            viewClassName='tree-view-output'
            treeTypeButtonClassName='debug-treetype-button'
            timeTravelPanelClassName='debug-timetravel-panel'
            timeTravelButtonClassName='debug-timetravel-button'
            timeTravelPanelSliderClassName='debug-timetravel-panel-slider'
            timeTravelPanelButtonClassName='debug-timetravel-panel-button'
            editor={editor}
        />
    )
}
