import { Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'

export function WysiwygActions({ openMediaGallery, openDocumentGallery, vertical, disabled }) {
    const classes = useButtonStyles()
    return (
        <div style={vertical ? { display: 'flex', flexDirection: 'column' } : {}}>
            <Button
                disabled={disabled}
                onClick={openDocumentGallery}
                color='primary'
                size={'small'}
                variant='outlined'
                className={classes.button}
            >
                Add Document or Folder Link
            </Button>
            <Button
                disabled={disabled}
                onClick={openMediaGallery}
                color='primary'
                size={'small'}
                variant='outlined'
                className={classes.button}
            >
                Add Image
            </Button>
        </div>
    )
}

export default WysiwygActions
const useButtonStyles = makeStyles({
    button: {
        marginRight: '5px',
        marginBottom: '10px'
    }
})
