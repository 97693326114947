import React, { useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import { DctCollectionActionBar } from './DctCollectionActionBar'
import { DctCollectionRow } from './DctCollectionRow'
import { ToastContainer } from 'react-toastify'
import { ImageGallery } from '../media/image/ImageGallery'
import DocumentGallery from '../media/document/DocumentGallery'
import getJsonDctSchema from '../../helpers/getJsonDctSchema'
import DctComponentList from './DctComponentList'
import AppAccordion from '../../common/components/AppAccordion'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'
import { useCurrentSiteID } from '../auth/atoms'
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault'
import { colours } from '../../common/colours'
import 'react-toastify/dist/ReactToastify.css'
import { useGetLinkToDocument } from '../media/copyToClipboard'

export type DctCollectionView = 'catalogue' | 'add' | 'edit'
type MapAny = Record<string, any>

interface DctCollectionProps {
    expanded?: boolean
    expandedOnChangeHandler?: (expanded: boolean) => void
    disabled: boolean
    section: any
    contentModelData: any
    contentModelDataOnChange: (value: any) => void
}

export function DctCollection({
    expanded = false,
    expandedOnChangeHandler,
    disabled,
    section,
    contentModelData,
    contentModelDataOnChange
}: DctCollectionProps) {
    const currentSiteID = useCurrentSiteID()
    const getLinkToDocument = useGetLinkToDocument()

    const [view, setView] = useState<DctCollectionView>('catalogue')
    const sectionComponents = getJsonDctSchema(section.components)
    const sectionData = (
        Array.isArray(contentModelData?.[section.name]) ? contentModelData?.[section.name] : []
    ) as MapAny[]

    const currentItemFormDefaultValues = sectionComponents.reduce(
        (a, component) => ({
            ...a,
            [component.name]: ''
        }),
        {} as any
    )

    const [currentItemForm, setCurrentItemForm] = useState(currentItemFormDefaultValues)

    const [currentItemFormErrors, setCurrentItemFormErrors] = useState(currentItemFormDefaultValues)

    function currentItemFormHasErrors() {
        let hasErrors = false
        const errorsObj = _.cloneDeep(currentItemFormErrors)
        for (const component of sectionComponents) {
            const currentFormValue = currentItemForm[component.name]
            if (
                (component.required && !!!currentFormValue) ||
                (component?.maximumLength && currentFormValue?.length > component?.maximumLength)
            ) {
                errorsObj[component.name] = true
                hasErrors = true
            } else {
                errorsObj[component.name] = false
            }
        }
        setCurrentItemFormErrors(errorsObj)
        return hasErrors
    }

    const [isImageGalleryOpen, setIsImageGalleryOpen] = useState(false)
    const [isDocumentGalleryOpen, setIsDocumentGalleryOpen] = useState(false)
    const [currentComponent, setCurrentComponent] = useState<any | null>(null)

    // NOTES: Using Programs as an example
    // cleanState: The collection of "localState" items (i.e) ["Basketball", "Badminton", "Soccer"]
    // localState: The individual item that is currently "in use" (i.e) "Basketball"
    // section: The JSON data of the "multi" component, includes fields such as "required, validate, maximumChars"
    // nestedComponents: The JSON data of the "components" array which is within the jsonComponentContainer

    useEffect(() => {
        contentModelDataOnChange(sectionData)
    }, [section.name, sectionData])

    function newButtonOnClickHandler() {
        setView('add')
        refreshCardState()
    }

    function addButtonOnClickHandler() {
        if (!currentItemFormHasErrors()) {
            const newList = [...sectionData, { ...currentItemForm, id: uuidv4() }]
            contentModelDataOnChange(newList)
            refreshCardState()
        }
    }

    // updating an existing item in sectionData
    function updateButtonOnClickHandler() {
        if (!currentItemFormHasErrors()) {
            const elementsIndex = sectionData.findIndex((element) => element.id === currentItemForm.id)
            if (elementsIndex > -1) {
                let newArray = [...sectionData]
                newArray[elementsIndex] = currentItemForm
                contentModelDataOnChange(newArray)
                setView('catalogue')
                refreshCardState()
            }
        }
    }

    function deleteButtonOnClickHandler(sectionCollectionItem) {
        // filter based on UUID
        let filtered = sectionData.filter((item) => item.id !== sectionCollectionItem.id)
        contentModelDataOnChange(filtered)
    }

    const insertIntoCard = (img) => {
        if (currentComponent) {
            const { alt, id } = img
            setCurrentItemForm((p) => ({
                ...p,
                [currentComponent.name]: {
                    src: `/images/${id}`,
                    alt
                }
            }))
            setCurrentItemFormErrors((p) => ({ ...p, [currentComponent.name]: false }))
        }
    }

    const insertDocumentIntoComponentState = (document, isFolder) => {
        if (document && document.id && currentComponent) {
            setCurrentItemForm((p) => ({
                ...p,
                [currentComponent.name]: getLinkToDocument({ doc: document, fullUrl: false, by: 'id' })
            }))
            setCurrentItemFormErrors((p) => ({ ...p, [currentComponent.name]: false }))
            handleDocumentGalleryClose()
        }
    }

    const handleDocumentGalleryClose = () => {
        setIsDocumentGalleryOpen(false)
    }

    const refreshCardState = () => {
        setCurrentItemForm(currentItemFormDefaultValues)
    }

    const moveUp = (index) => {
        let newOptions = sectionData
        let start = newOptions.splice(index, 1)
        if (index - 1 < 0) {
            newOptions.splice(newOptions.length, 0, start[0])
        } else {
            newOptions.splice(index - 1, 0, start[0])
        }
        contentModelDataOnChange(newOptions)
    }

    const moveDown = (index) => {
        let newOptions = sectionData
        let start = newOptions.splice(index, 1)
        if (index + 1 > newOptions.length) {
            newOptions.splice(0, 0, start[0])
        } else {
            newOptions.splice(index + 1, 0, start[0])
        }
        contentModelDataOnChange(newOptions)
    }

    // const getHelperText = (component) => {
    //     if (!component.maximumLength) return null;
    //     let currentCharacterCount = 0
    //     if (localState[component.name]) {
    //         currentCharacterCount = localState[component.name].length
    //     }
    //     return `${currentCharacterCount}/${component.maximumLength}`
    // }

    return (
        <>
            <ToastContainer />
            <AppAccordion
                expanded={expanded || false}
                onChangeHandler={expandedOnChangeHandler}
                summary={
                    <Typography component='p' variant='h5' sx={disabled ? { color: colours.disabled } : undefined}>
                        {section.title + ` ${sectionData?.length ? ` (${sectionData.length})` : ''}`}{' '}
                        {disabled ? (
                            <DisabledByDefaultIcon sx={{ fontSize: '16px' }} titleAccess='Disabled' />
                        ) : undefined}
                    </Typography>
                }
                details={
                    <>
                        {section.description && <p className='no-margin mb10'>{section.description}</p>}
                        {view !== 'catalogue' && (
                            <DctComponentList
                                sectionComponents={sectionComponents}
                                disabled={disabled}
                                localState={currentItemForm}
                                setLocalState={(field: string, value: any) =>
                                    setCurrentItemForm((p) => ({ ...p, [field]: value }))
                                }
                                errorState={currentItemFormErrors}
                                setErrorState={(field: string, value: boolean) =>
                                    setCurrentItemFormErrors((p) => ({ ...p, [field]: value }))
                                }
                                imageGalleryOpenHandler={setIsImageGalleryOpen}
                                currentComponentOnChangeHandler={setCurrentComponent}
                                documentGalleryOpenHandler={setIsDocumentGalleryOpen}
                            />
                        )}
                        <div className='catalogue-container'>
                            {view === 'catalogue' &&
                                sectionData?.map((sectionCollectionItem, index) => (
                                    <DctCollectionRow
                                        key={sectionCollectionItem.id!}
                                        view={view}
                                        index={index}
                                        moveUp={moveUp}
                                        setView={setView}
                                        disabled={disabled}
                                        moveDown={moveDown}
                                        localState={currentItemForm}
                                        editButtonOnClick={() => {
                                            setView('edit')
                                            setCurrentItemForm(sectionCollectionItem)
                                        }}
                                        sectionData={sectionData}
                                        selectedSite={currentSiteID}
                                        deleteButtonOnClick={() => deleteButtonOnClickHandler(sectionCollectionItem)}
                                        sectionComponents={sectionComponents}
                                        sectionCollectionItem={sectionCollectionItem}
                                    />
                                ))}
                        </div>
                        <DctCollectionActionBar
                            view={view}
                            section={section}
                            setView={setView}
                            disabled={disabled}
                            sectionData={sectionData}
                            newButtonOnClickHandler={newButtonOnClickHandler}
                            addButtonOnClickHandler={addButtonOnClickHandler}
                            updateButtonOnClickHandler={updateButtonOnClickHandler}
                        />
                    </>
                }
            />
            {isImageGalleryOpen && !disabled && (
                <ImageGallery
                    open={isImageGalleryOpen}
                    close={() => setIsImageGalleryOpen(false)}
                    onChange={insertIntoCard}
                />
            )}

            {isDocumentGalleryOpen && !disabled && (
                <DocumentGallery
                    isForDct
                    saveForDct={insertDocumentIntoComponentState}
                    isGalleryOpen={isDocumentGalleryOpen}
                    setGalleryClose={handleDocumentGalleryClose}
                />
            )}
        </>
    )
}

export default DctCollection
