import React, { useEffect, useState } from 'react'
import { Card, Icon } from '@mui/material'
import FolderIcon from '@mui/icons-material/Folder'
import CreateIcon from '@mui/icons-material/Create'
import { makeStyles } from '@mui/styles'
import FileCopy from '@mui/icons-material/FileCopy'
import SecureFolder from '../../../../assets/Secure-folder.svg'
import DeleteIcon from '@mui/icons-material/Delete'
import { useAppContext } from '../../../auth/atoms'
import { colours } from '../../../../common/colours'
import { copyToClipboard, useGetLinkToDocument } from '../../copyToClipboard'

/**
 * CopytoClipboard is two possible functions -
 *      - If (isForDct)  = we pass the selection function down from the parent documentGallery
 *      - if (!isForDct) = we pass the parents copyToClipboard function
 * handleRemove is only provided as a prop in DCT component view, since this is the case, we use the same truthy check to determine
 * whether or not we should show the copy to clipboard functions.
 * */

const DocumentFolder = ({
    folder,
    selectFolder,
    prepareEditFolder,
    isForDct,
    saveForDct,
    isFolderDialogOpen,
    handleRemove
}) => {
    const getLinkToDocument = useGetLinkToDocument()
    const classes = useStyles()
    const evaluators = useAppContext()
    const [isDctComponent, setIsDctComponent] = useState(Boolean(handleRemove))
    const [isOptionsDisabled, setIsOptionsDisabled] = useState(true)

    useEffect(() => {
        const canUpdate = evaluators.action(folder, 'update')
        setIsOptionsDisabled(!canUpdate)
        // if (folder.sites.length > 1 && evaluators.entityScopeAny(EntityScopeEnum.Document)) {
        //     setIsOptionsDisabled(false)
        // } else if (folder.sites.length === 1 && evaluators.entityScopeAny(EntityScopeEnum.Document)) {
        //     setIsOptionsDisabled(false)
        // }
    }, [evaluators])

    const handleClick = () => {
        if (isForDct) {
            // save for dct
            saveForDct(folder, true)
        } else {
            // actually copy to clipboard
            copyToClipboard(
                getLinkToDocument({
                    doc: folder
                })
            )
        }
    }

    const editFolder = () => {
        if (isOptionsDisabled) return
        prepareEditFolder(folder)
    }

    const deleteFolder = () => {
        handleRemove(folder.id)
    }

    return (
        <Card
            variant='outlined'
            className={classes.folder}
            style={isDctComponent ? { display: 'flex', justifyContent: 'space-between' } : {}}
        >
            <div className='flex-row-align-center' onClick={() => selectFolder(folder)}>
                {folder.privacyLevel > 0 ? (
                    <Icon>
                        <img src={SecureFolder} alt={'Icon of a folder'} />
                    </Icon>
                ) : (
                    <FolderIcon className={classes.publicFolder} />
                )}
                {folder.filename && <p style={{ margin: '5px' }}>{folder.filename}</p>}
            </div>
            {isFolderDialogOpen !== undefined && (
                <CreateIcon
                    className={isOptionsDisabled ? classes.disabledIconColor : classes.iconColor}
                    classes={isOptionsDisabled ? {} : { root: classes.root }}
                    onClick={editFolder}
                />
            )}
            {isDctComponent ? (
                <DeleteIcon
                    className={classes.deleteIcon}
                    classes={{ root: classes.deleteHover }}
                    onClick={deleteFolder}
                />
            ) : (
                <FileCopy className={classes.iconColor} classes={{ root: classes.root }} onClick={handleClick} />
            )}
        </Card>
    )
}
const useStyles = makeStyles({
    folder: {
        flex: '0 0 auto',
        flexDirection: 'row',
        display: 'flex',
        padding: '10px',
        cursor: 'pointer',
        margin: '5px',
        alignItems: 'center'
    },
    iconColor: {
        color: '#757575',
        width: '0.75em',
        height: '0.75em',
        margin: '0px 5px'
    },
    publicFolder: {
        color: '#FCD699',
        margin: '5px',
        width: '1.25em',
        height: '1.25em'
    },
    root: {
        '&:hover': {
            filter: 'brightness(60%)'
        }
    },
    deleteHover: {
        '&:hover': {
            filter: 'brightness(125%)'
        }
    },
    disabledIconColor: {
        color: colours.off_white
    },
    deleteIcon: {
        color: '#e66262',
        cursor: 'pointer',
        transition: 'all .2s ease-in-out'
    }
})
export default DocumentFolder
