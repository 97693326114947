import React, { useEffect, useState } from 'react'
import { Autocomplete, FormControl, TextField } from '@mui/material'
import { SettingsType, getPrettySettingsType } from './types'

interface SettingsTypeSelectorProps {
    initialValue?: SettingsType
    onChange: (v: SettingsType) => void
    label?: string
    variant?: 'standard' | 'outlined' | 'filled'
    required?: boolean
    disabled?: boolean
}

export function SettingsTypeSelector({
    initialValue,
    onChange,
    label = 'Type',
    variant,
    required,
    disabled
}: SettingsTypeSelectorProps) {
    const [value, setValue] = useState<SettingsType | null>(initialValue || null)

    useEffect(() => {
        setValue(initialValue || null)
    }, [initialValue])

    return (
        <FormControl variant={variant || 'outlined'} required={required} sx={{ width: '100%' }} disabled={disabled}>
            <Autocomplete
                aria-required={required}
                disablePortal
                options={Object.values(SettingsType)}
                value={value}
                getOptionLabel={(option) => {
                    return getPrettySettingsType(option)
                }}
                onChange={(ev, newValue) => {
                    const value: SettingsType | null = (newValue as SettingsType) || null
                    setValue(value)
                    onChange(value)
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label || 'Type'}
                        variant={variant || 'outlined'}
                        required={required}
                    />
                )}
            />
        </FormControl>
    )
}

export default SettingsTypeSelector
