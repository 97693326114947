import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField
} from '@mui/material'
import { z } from 'zod'
import { useMutation, useQuery } from '@tanstack/react-query'
import { httpGet, httpPost } from '../../../common/client'
import DeleteIcon from '@mui/icons-material/Delete'
import { ContentCopy } from '@mui/icons-material'
import axios from 'axios'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { useState } from 'react'
import moment from 'moment'
import AddIcon from '@mui/icons-material/Add'
import { guessErrorMessage } from '../../../helpers/guessErrorMessage'
import { PATokensAPI } from '../../../common/constants'

interface ManagePATokensProps {
    account: {
        ID: string
        Email: string
        Firstname: string
        Lastname: string
    }
    open: boolean
    onClose: () => void
}

export const ManagePATokens = ({ account, open, onClose }: ManagePATokensProps) => {
    const defaultDate = moment().add(1, 'month')
    const { query, create, remove } = usePATokensQuery(account.ID)
    const [dto, setDTO] = useState({
        AccountID: account.ID,
        Name: '',
        ExpiresAt: defaultDate
    })

    const addToken = async () => {
        try {
            await create.mutateAsync({
                AccountID: account.ID,
                Name: dto.Name,
                ExpiresAt: dto.ExpiresAt.toDate()
            })
            setDTO({
                AccountID: account.ID,
                Name: '',
                ExpiresAt: defaultDate
            })
        } catch (e) {}
    }

    if (!account || !open) return null

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Manage Personal Access Tokens</DialogTitle>
            <DialogContent>
                <p>
                    Selected user: {account.Firstname} {account.Lastname} <strong>{account.Email}</strong>
                </p>

                <h3>Create new token</h3>

                <Box>
                    <TextField
                        value={dto.Name}
                        onChange={(e) => setDTO((p) => ({ ...p, Name: e.target.value }))}
                        label='Token name'
                    />
                </Box>
                <Box sx={{ my: 2 }}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DateTimePicker
                            label='Expiration date'
                            disablePast={true}
                            value={dto.ExpiresAt}
                            onChange={(v) => setDTO((p) => ({ ...p, ExpiresAt: v || defaultDate }))}
                        />
                    </LocalizationProvider>
                </Box>
                {create.isError && (
                    <Alert sx={{ mb: 2 }} severity='error'>
                        Error creating token: {guessErrorMessage(create.error)}
                    </Alert>
                )}
                {create.isSuccess && (
                    <Alert sx={{ mb: 2 }} severity='success'>
                        Token created successfully
                    </Alert>
                )}
                <Box>
                    <Button startIcon={<AddIcon />} variant='contained' color='success' onClick={addToken}>
                        Add Token
                    </Button>
                </Box>

                <h3>Active tokens</h3>
                {query.isLoading && <p>Loading...</p>}
                {query.isError && <p>Error loading tokens: {guessErrorMessage(query.error)}</p>}
                {query.data &&
                    Array.isArray(query.data) &&
                    query.data.map((token: TokenType) => (
                        <div key={token.ID}>
                            <strong>{token.Name}</strong> (expires at: {token.ExpiresAt.toISOString()})
                            <IconButton
                                aria-label='delete'
                                onClick={() => {
                                    copyTextToClipboard(token.Token)
                                }}
                            >
                                <ContentCopy />
                            </IconButton>
                            <IconButton
                                color={'error'}
                                aria-label='delete'
                                onClick={() => {
                                    if (window.confirm(`Are you sure you want to delete the token "${token.Name}"?`)) {
                                        remove.mutate(token.ID)
                                    }
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    ))}
                {query.data && Array.isArray(query.data) && query.data.length === 0 && (
                    <p>No active tokens available.</p>
                )}
            </DialogContent>
            <DialogActions></DialogActions>
        </Dialog>
    )
}

const tokenType = z.object({
    ID: z.string(),
    AccountID: z.string(),
    Name: z.string(),
    Token: z.string(),
    ExpiresAt: z.coerce.date()
})
const tokenTypeArray = z.array(tokenType)

const tokenDTOType = z.object({
    AccountID: z.string(),
    Name: z.string(),
    ExpiresAt: z.coerce.date()
})

type TokenType = z.infer<typeof tokenType>
type TokenDTOType = z.infer<typeof tokenDTOType>

const usePATokensQuery = (accountID: string) => {
    const key = `personal-access-tokens-${accountID}`

    const query = useQuery({
        queryKey: [key],
        queryFn: async () => httpGet(`${PATokensAPI}/${accountID}`, {}, tokenTypeArray)
    })

    const create = useMutation({
        mutationKey: [key],
        mutationFn: async (token: TokenDTOType) => httpPost(`${PATokensAPI}`, token, tokenType),
        onSuccess: () => query.refetch()
    })

    const remove = useMutation({
        mutationKey: [key],
        mutationFn: async (tokenID: string) => axios.delete(`${PATokensAPI}/${tokenID}`),
        onSuccess: () => query.refetch()
    })

    return { query, create, remove }
}

export async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text)
    } else {
        return document.execCommand('copy', true, text)
    }
}
