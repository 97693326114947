import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

const DialogFormAction = ({
    open,
    handleClose,
    item,
    title,
    text,
    handleDisagree,
    handleAgree,
    isHandleAgreeDisabled = false,
    buttonDisagreeLabel,
    buttonAgreeLabel,
    alternate,
    alternateAction,
    fullWidth,
    headerComponent
}) => {
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby='form-dialog-title'
                maxWidth='sm'
                fullWidth={fullWidth}
            >
                <DialogTitle id='form-dialog-title'>{title}</DialogTitle>
                <DialogContent>
                    {text && <DialogContentText>{text}</DialogContentText>}
                    {headerComponent && headerComponent}
                    {item}
                </DialogContent>
                <DialogActions>
                    {alternate && (
                        <Button onClick={alternateAction} color='primary'>
                            {alternate}
                        </Button>
                    )}
                    {handleDisagree && (
                        <Button onClick={handleDisagree} color='primary'>
                            {buttonDisagreeLabel ? buttonDisagreeLabel : 'Disagree'}
                        </Button>
                    )}
                    {handleAgree && (
                        <Button
                            data-testid={`dialog-agree`}
                            onClick={handleAgree}
                            color='primary'
                            disabled={isHandleAgreeDisabled}
                        >
                            {buttonAgreeLabel ? buttonAgreeLabel : 'Agree'}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default DialogFormAction
