import { is } from './is'

export default function renderSelectedValue(selected) {
    if (is.array(selected)) {
        const result = []
        for (const item of selected) {
            const key = item?.name || item?.title || item?.label || item?.filename || item
            if (key && !result.includes(key)) {
                result.push(key)
            }
        }
        return result.join(', ')
    }

    return selected
}
