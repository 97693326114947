import TextField, { TextFieldProps } from '@mui/material/TextField'
import lodash from 'lodash'
import { forwardRef, MutableRefObject, useCallback, useEffect, useImperativeHandle, useState } from 'react'

type TextFieldDebouncedProps = {
    value?: string
    onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    wait?: number // default 1000
    inputRef?: MutableRefObject<HTMLDivElement | null>
} & TextFieldProps

export interface TextFieldDebouncedHandlers {
    setValue: (e) => void
    getValue: () => string
}

export const TextFieldDebounced = forwardRef<TextFieldDebouncedHandlers, TextFieldDebouncedProps>(
    ({ value: defaultValue, onChange, wait, inputRef, ...rest }, ref) => {
        const [value, setValue] = useState<string>(defaultValue || '')

        useImperativeHandle(ref, () => {
            return {
                setValue: (v) => setValue(v),
                getValue: () => value
            }
        })

        const onChangeDebounced = useCallback(lodash.debounce(onChange, wait || 1000), [onChange, wait])

        function handleOnChange(e) {
            setValue(e.target.value)
            onChangeDebounced(e)
        }

        useEffect(() => {
            if (defaultValue !== undefined && value != defaultValue) {
                setValue(defaultValue!)
            }
        }, [defaultValue])

        return <TextField inputRef={inputRef} value={value} onChange={handleOnChange} {...rest} />
    }
)

export default TextFieldDebounced
