import { z } from 'zod'
import axios from 'axios'
import { Subject } from 'rxjs'
import { MapIDsAPI } from './constants'

// const environment = "production"
// const environment = "development"
export const httpGet = async <TQuery, TData>(
    url: string,
    query: TQuery,
    schema: z.Schema<TData>,
    controller?: AbortController
): Promise<TData> => {
    const config = {
        params: query
    }

    if (controller?.signal) {
        config['signal'] = controller.signal
    }
    try {
        let response = await axios.get(url, config)
        // if (environment !== 'production') {
        //     return schema.parse(response.data)
        // } else {
        //     const v = schema.safeParse(response.data)
        //     if (v.success) {
        //         return v.data
        //     } else {
        //         return Promise.reject(v.error)
        //     }
        // }
        return schema.parse(response.data)
    } catch (error) {
        console.error('catch', error)
        return Promise.reject(error)
    }
}
export const httpPost = async <TBody, TData>(
    url: string,
    body: TBody,
    schema?: z.Schema<TData>,
    options?: { headers?: Record<string, string> }
): Promise<TData | undefined> => {
    try {
        console.log('httpPost')
        let response = await axios.post(url, body, options)
        console.log('response', response)
        return schema ? schema.parse(response.data) : (response.data as never as TData)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const httpPut = async <TBody, TData>(
    url: string,
    body: TBody,
    schema?: z.Schema<TData>
): Promise<TData | undefined> => {
    try {
        let response = await axios.put(url, body)
        console.log('response', response)
        return schema && schema.parse(response.data)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const httpPatch = <TBody, TData>(
    url: string,
    body: TBody,
    schema?: z.Schema<TData>
): Promise<TData | undefined> => {
    return axios
        .patch(url, body)
        .then((response) => schema && schema.parse(response.data))
        .catch((error) => {
            return Promise.reject(error)
        })
}

export const httpDelete = <TData>(url: string, schema?: z.Schema<TData>): Promise<TData | undefined> => {
    return axios
        .delete(url)
        .then((response) => schema && schema.parse(response.data))
        .catch((error) => {
            return Promise.reject(error)
        })
}

export const clientStorage = {
    setItem<T>(key: string, value: T) {
        const v = JSON.stringify(value)
        sessionStorage.setItem(key, v)
        localStorage.setItem(key, v)
    },
    getItem<T>(key: string): T | null {
        return JSON.parse(sessionStorage.getItem(key) || localStorage.getItem(key) || 'null')
    },
    clearLocalStoragePrefix(prefix: string) {
        Object.keys(localStorage)
            .filter((x) => x.startsWith(prefix))
            .forEach((x) => localStorage.removeItem(x))

        Object.keys(sessionStorage)
            .filter((x) => x.startsWith(prefix))
            .forEach((x) => localStorage.removeItem(x))
    }
}

export const pagesRowsPerPageKey = 'pages-rows-per-page'
export const newsRowsPerPageKey = 'news-rows-per-page'
export const eventsRowsPerPageKey = 'events-rows-per-page'
export const imagesRowsPerPageKey = 'images-rows-per-page'
export const documentsRowsPerPageKey = 'documents-rows-per-page'

export type IDName = { ID: string; Name: string }
const nameZSubject = new Subject<IDName>()

export const nameZ$ = (tableName: string, id: string) => {
    const key = `nameZ__${id}`
    const name = clientStorage.getItem<string>(key)
    if (name) {
        setTimeout(() => nameZSubject.next({ ID: id, Name: name }), 0)
    } else {
        requestNameZ(tableName, id)
    }
    return nameZSubject.asObservable()
}

const idMapResponse = z.record(z.record(z.string()).optional())

let storage: Record<string, Set<string>> = {}
let timeout = setTimeout(() => {}, 0)
const requestNameZ = (tableName: string, id: string) => {
    if (!storage[tableName]) {
        storage[tableName] = new Set()
    }
    storage[tableName].add(id)

    clearTimeout(timeout)
    timeout = setTimeout(() => {
        const body: Record<string, string[]> = {}
        Object.entries(storage).forEach(([table, ids]) => {
            body[table] = Array.from(ids)
        })
        storage = {}

        httpPost(MapIDsAPI, body, idMapResponse)
            .then((idMap) => {
                Object.entries(idMap || {}).forEach(([table, names]) => {
                    if (names) {
                        Object.entries(names).forEach(([id, name]) => {
                            clientStorage.setItem(`nameZ__${id}`, name)
                            nameZSubject.next({ ID: id, Name: name })
                        })
                    }
                })
            })
            .catch((error) => {
                console.error(error)
            })
    }, 1000)
}

// setInterval(() => {
//     clientStorage.clearLocalStoragePrefix('nameZ__')
// }, 1000 * 60 * 5)
