import { z } from 'zod'
import { dateTime, paged } from '../../common/react-query'

// Content Pagelayout
export const PageLayout = {
    DCT: 'DCT',
    WYSIWYG: 'WYSIWYG',
    HTML: 'HTML'
}

export enum ContentType {
    Event = 'event',
    Page = 'page',
    News = 'news',
    Template = 'template',
    Navigation = 'navigation',
    ExternalLink = 'external_link',
    Alert = 'alert',
    JS = 'js',
    CSS = 'css',
    Fragment = 'fragment'
}

export function isContentType(contentType: string | ContentType | ContentLike) {
    return Object.values(ContentType).includes(contentType as ContentType)
}

export type ContentLike = Extract<
    ContentType,
    ContentType.Page | ContentType.News | ContentType.Event | ContentType.Alert
>

export const ContentLike = {
    [ContentType[ContentType.Page]]: ContentType.Page,
    [ContentType[ContentType.News]]: ContentType.News,
    [ContentType[ContentType.Event]]: ContentType.Event,
    [ContentType[ContentType.Alert]]: ContentType.Alert
}

export function getAllContentLikeContent(): ContentLike[] {
    return [ContentType.Page, ContentType.News, ContentType.Event, ContentType.Alert]
}

export function isContentLikeContent(contentType: string | ContentType | ContentLike) {
    return Object.values<string>(ContentLike).includes(contentType)
}

export const ContentPublishedTypes = {
    all: 'all',
    drafts: 'draft',
    published: 'published',
    deleted: 'deleted'
}

export const ContentExpirationsState = {
    all: 'all',
    expired: 'expired',
    active: 'active'
}

export const sharable = z.object({
    Sites: z.array(z.string()),
    DepartmentID: z.string().nullish()
})
export const publishPeriod = z.object({
    PublishAt: dateTime.optional().nullable(),
    ExpireAt: dateTime.optional().nullable()
})

export const base = z
    .object({
        PrivacyLevel: z.number()
    })
    .merge(sharable)
    .merge(publishPeriod)

export const content = base.extend({
    ID: z.string(),
    Active: z.boolean(),
    Owner: z.string(),
    Publisher: z.string(),
    Created: dateTime,
    Updated: dateTime,
    Deleted: dateTime.optional().nullable(),
    MediaID: z.string().nullish(),
    StructureID: z.string().nullish(),
    Title: z.string(),
    Type: z.string(),
    Route: z.string(),
    Path: z.string(),
    PageLayout: z.string(),
    Data: z.any().nullish(),
    Settings: z.any().nullish(),
    Tags: z.array(z.string()).nullish(),
    Meta: z.record(z.string(), z.string()).nullish(),
    Structures: z.array(z.string()).nullish(),
    Content: z.string().nullish(),
    Structure: z.any().nullish()
})

export const fragmentDTO = base.extend({
    StructureID: z.string().nullish(),
    Title: z.string(),
    Type: z.string(),
    Tags: z.array(z.string()).nullish(),
    Data: z.any().nullish()
})

export const contentResult = paged.extend({
    Rows: z.array(content)
})

export type PublishPeriod = z.infer<typeof publishPeriod>
export type Sharable = z.infer<typeof sharable>
export type Base = z.infer<typeof base>
export type Content = z.infer<typeof content>
export type FragmentDTO = z.infer<typeof fragmentDTO>
export type ContentEssentials = Base & Pick<Content, 'StructureID' | 'Type' | 'Path' | 'Tags'>
