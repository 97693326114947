import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import OpenDocumentGallery from './OpenDocumentGallery'
import { OpenGallery } from '../media/image/OpenGallery'
import { SingleDateTimePicker } from '../../common/editor/EventDateTimePicker'
import moment from 'moment'
import React, { Dispatch } from 'react'
import { StructuredRichTextEditor } from '../lexical-editor/RichTextEditor'
import { FormLink } from '../forms/FormLink'

interface DctComponentListProps {
    sectionComponents: any
    disabled: boolean
    localState: any
    setLocalState: (field: string, value: any) => void
    errorState: any
    setErrorState: (field: string, value: boolean) => void
    imageGalleryOpenHandler: (isOpen: boolean) => void
    currentComponentOnChangeHandler: Dispatch<any>
    documentGalleryOpenHandler: (isOpen: boolean) => void
}

// list of components for a dct section
// form validation is done on save/publish in DctSection component
function DctComponentList({
    sectionComponents,
    disabled,
    localState,
    setLocalState,
    errorState,
    setErrorState,
    imageGalleryOpenHandler,
    currentComponentOnChangeHandler,
    documentGalleryOpenHandler
}: DctComponentListProps) {
    function getHelperText(component) {
        if (!component.maximumLength) return null
        let currentCharacterCount = 0
        if (localState[component.name]) {
            currentCharacterCount = localState[component.name].length
        }
        return `${currentCharacterCount}/${component.maximumLength}`
    }

    return sectionComponents?.map((component, idx) => {
        const _setContentModelData = (newValue: any) => {
            setLocalState(component.name, newValue)
        }
        const _setContentModelDataErrors = (newValue: boolean) => setErrorState(component.name, newValue)
        switch (component.type) {
            case 'checkbox':
                return (
                    <FormControl variant='standard' key={component.label + component.name} disabled={disabled}>
                        <FormControlLabel
                            sx={{
                                marginTop: '0.5em'
                            }}
                            control={
                                <Checkbox
                                    disabled={disabled}
                                    required={component.required}
                                    checked={localState[component.name]}
                                    onChange={(event) => {
                                        _setContentModelData(event.target.checked)
                                    }}
                                    aria-label={component.label}
                                />
                            }
                            label={component.label}
                        />
                    </FormControl>
                )
            case 'document':
                return (
                    <div key={component.label + component.name} style={{ width: '100%' }}>
                        {!disabled && (
                            <OpenDocumentGallery
                                component={component}
                                setErrors={setErrorState}
                                title={'Choose a document'}
                                error={!!errorState?.[component.name]}
                                value={localState[component.name]}
                                handleOpen={() => documentGalleryOpenHandler(true)}
                                setCurrentComponent={currentComponentOnChangeHandler}
                                removeSelectedDocument={() => {
                                    _setContentModelData('')
                                    documentGalleryOpenHandler(false)
                                }}
                            />
                        )}
                    </div>
                )
            case 'image':
                return (
                    <div
                        className='flex-row'
                        style={{ justifyContent: 'flex-start', alignItems: 'center', marginTop: '1vh' }}
                        key={component.label + component.name}
                    >
                        <OpenGallery
                            title='Add Image'
                            error={!!errorState?.[component.name]}
                            localState={localState}
                            component={component}
                            handleOpen={() => imageGalleryOpenHandler(true)}
                            setCurrentComponent={currentComponentOnChangeHandler}
                            removeSelectedMedia={() => {
                                _setContentModelData('')
                            }}
                            value={localState[component.name]}
                            disabled={disabled}
                        />
                    </div>
                )
            case 'select':
                return (
                    <FormControl
                        sx={{
                            marginTop: '1vh',
                            height: '5%',
                            width: '50%'
                        }}
                        variant='standard'
                        key={component.label + component.name}
                        disabled={disabled}
                    >
                        <InputLabel error={!!errorState?.[component.name]}>{component.label}</InputLabel>
                        <Select
                            variant='standard'
                            disabled={disabled}
                            error={!!errorState?.[component.name]}
                            value={localState[component.name] || ''}
                            onChange={(event) => {
                                _setContentModelData(event.target.value)
                                _setContentModelDataErrors(false)
                            }}
                        >
                            {component.options.map((x) => (
                                <MenuItem key={x.value + 'key'} value={x.value}>
                                    {x.text}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )
            case 'textarea':
                return (
                    <FormControl
                        sx={{
                            width: '100%',
                            marginTop: '1vh'
                        }}
                        variant='standard'
                        disabled={disabled}
                        key={component.label + component.name}
                    >
                        <TextField
                            variant='standard'
                            multiline
                            disabled={disabled}
                            maxRows={7}
                            helperText={getHelperText(component)}
                            label={component.label}
                            required={component.required}
                            error={!!errorState?.[component.name]}
                            value={localState[component.name] || ''}
                            onChange={(event) => {
                                _setContentModelData(event.target.value)
                                _setContentModelDataErrors(false)
                            }}
                        />
                    </FormControl>
                )
            case 'text':
            case 'email':
                return (
                    <FormControl
                        sx={{
                            width: '100%',
                            marginTop: '1vh'
                        }}
                        variant='standard'
                        disabled={disabled}
                        key={component.label + component.name}
                    >
                        <TextField
                            variant='standard'
                            label={component.label}
                            disabled={disabled}
                            required={component.required}
                            helperText={getHelperText(component)}
                            error={!!errorState?.[component.name]}
                            value={localState[component.name] || ''}
                            onChange={(event) => {
                                _setContentModelData(event.target.value)
                                _setContentModelDataErrors(false)
                            }}
                        />
                    </FormControl>
                )
            case 'date':
                return (
                    <SingleDateTimePicker
                        sx={{
                            marginTop: '1vh'
                        }}
                        key={component.label + component.name}
                        format={'MMM DD, YYYY'}
                        date={localState[component.name]}
                        dateOnChange={(s) => {
                            const savedFormat = 'YYYY-MM-DD'
                            const formattedDate = moment(s).format(savedFormat)
                            _setContentModelData(formattedDate)
                        }}
                        handleOnError={(error) => {
                            _setContentModelDataErrors(false)
                        }}
                        disableTime
                        disabled={disabled}
                        label={component.label}
                        required={component.required}
                        error={errorState?.[component.name]}
                    />
                )
            // case 'rich-text':
            //     return (
            //         // If the section state isn't initialized yet, block render
            //         // otherwise, initialValue will load as empty, and not updatable
            //         // 2024-03-23: Testing the removal of render blocker
            //         // localState[component.name] != null && (
            //         <LexicalEditor
            //             onChange={(value, editorStateJson) => {
            //                 _setContentModelData({
            //                     html: value,
            //                     json: editorStateJson
            //                 })
            //             }}
            //             value={localState[component.name]?.html}
            //             disabled={disabled}
            //             config={{
            //                 toolbar: component?.options?.toolbar
            //             }}
            //             key={component.name + component.label + (localState?.id ? localState.id : 'section')}
            //         />
            //         // )
            //     )
            case 'rich-text':
                return (
                    <StructuredRichTextEditor
                        key={component.name}
                        value={localState[component.name]}
                        onChange={_setContentModelData}
                        disabled={disabled}
                        options={component?.options}
                        editorKey={component.name + component.label + (localState?.id ? localState.id : 'section')}
                    />
                )
            case 'contact-form-link':
                return (
                    <FormLink
                        key={component.name}
                        value={localState[component.name]}
                        required={component.required}
                        onChange={(v) => {
                            _setContentModelData(v)
                            _setContentModelDataErrors(false)
                        }}
                        disabled={disabled}
                        allowedStructures={component?.options?.allowedStructures}
                        types={component?.options?.types}
                        error={errorState?.[component.name] && 'Invalid link'}
                    />
                )
            case 'error':
            default:
                return <div key={idx}>There aren't any DCT forms associated with this template</div>
        }
    })
}

export default DctComponentList
