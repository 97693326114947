import { Box } from '@mui/system'
import { Button, Card, CardContent, CircularProgress, FormLabel, Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { isEqual } from 'lodash'
import { Role, ROLE_TYPE } from '../types'
import ScopesEditor from './ScopesEditor'
import { BoxForm } from '../../../common/components/BoxForm'
import { useParams } from 'react-router-dom'
import { BackButton } from '../../../common/components'
import { useRoleQueryMutation } from '../queries'

type PermissionName = 'core' | 'pages' | 'news' | 'media' | 'events' | 'transportation' | 'alerts' | 'instagram'

// property key used in role object model in backend
function getPermissionColumnName(permissionName: PermissionName) {
    return 'mod_' + permissionName
}

function getPermissionValue(permissionStateObject) {
    const permissions = Object.keys(permissionStateObject)
    let pl = 0
    for (let i = 0; i < permissions.length; i++) {
        const isChecked = permissionStateObject[permissions[i]]
        pl += isChecked ? PERMISSION_INDEX_TO_VALUE[i] : 0
    }

    return pl
}

export const PERMISSION_INDEX_TO_VALUE = [1, 2, 4, 8, 16]
export const ROLES_PERMISSIONS: Record<PermissionName, string[]> = {
    core: ['account management', 'publish site resource', 'publish shared resource', 'site settings', 'tags'],
    pages: ['publish shared content', 'publish site content'],
    news: ['publish shared content', 'publish site content'],
    media: ['publish shared content', 'publish site content'],
    events: ['publish shared content', 'publish site content'],
    transportation: ['create areas and routes', 'assign states to routes'],
    alerts: ['create shared alerts', 'create site alerts'],
    instagram: ['manage instagram token']
}

function permissionListToChecklist(permissionsName: PermissionName, initialPermissions: Role): Record<string, boolean> {
    const permissionsList = ROLES_PERMISSIONS[permissionsName]
    return (
        permissionsList.reduce(
            (a, perm, index) => ({
                ...a,
                [perm]:
                    (PERMISSION_INDEX_TO_VALUE[index] &
                        Number(initialPermissions[getPermissionColumnName(permissionsName)])) ===
                    PERMISSION_INDEX_TO_VALUE[index]
            }),
            {} as Record<string, boolean>
        ) || null
    )
}

// editor/manager
function RolesPermissions() {
    const { data, editMutation } = useRoleQueryMutation()
    const params = useParams()
    const selectedRole = useMemo(() => data?.find((role) => role.id == params.id), [data, params.id])
    const [selectedScopes, setSelectedScopes] = useState<string[]>(selectedRole?.Scopes || [])
    const [roleType, setRoleType] = useState<ROLE_TYPE>(selectedRole?.type || ROLE_TYPE.TENANT)

    const defaultFormValues: Record<PermissionName, Record<string, boolean>> = useMemo(
        () =>
            Object.keys(ROLES_PERMISSIONS).reduce(
                (previousValue, currentValue) => ({
                    ...previousValue,
                    [currentValue]: selectedRole
                        ? permissionListToChecklist(currentValue as PermissionName, selectedRole)
                        : false
                }),
                {} as Record<PermissionName, Record<string, boolean>>
            ),
        [selectedRole]
    )

    const [checkedItems, setCheckedItems] = useState(defaultFormValues)
    const permissionNames = Object.keys(checkedItems) as PermissionName[]

    useEffect(() => {
        setCheckedItems(defaultFormValues)
        setSelectedScopes(selectedRole?.Scopes || [])
        setRoleType(selectedRole?.type || ROLE_TYPE.TENANT)
    }, [defaultFormValues])

    function roleOnSave() {
        const updatedPermissions = permissionNames.reduce(
            (a, perm) => ({
                ...a,
                [getPermissionColumnName(perm)]: getPermissionValue(checkedItems[perm])
            }),
            {}
        )

        editMutation.mutate({
            ...selectedRole,
            ...updatedPermissions,
            Scopes: selectedScopes,
            type: roleType
        })
    }

    const formHasChanges =
        selectedRole &&
        (!isEqual(defaultFormValues, checkedItems) ||
            !isEqual(selectedScopes, selectedRole.Scopes) ||
            selectedRole?.type != roleType)

    if (!selectedRole) {
        return (
            <div className='container'>
                <div className='flex-row settings-header' style={{ justifyContent: 'flex-end' }}>
                    <BackButton route='/user-management/roles' />
                </div>
                <CircularProgress
                    style={{ width: '5rem', height: '5rem', position: 'absolute', right: '50%', top: '50%' }}
                />
            </div>
        )
    }

    return (
        <div className='container'>
            <div className='flex-row settings-header' style={{ justifyContent: 'space-between' }}>
                <Box display='flex' gap='8px'>
                    <Typography fontWeight='normal' variant='h6'>{`Edit Role Permissions:`}</Typography>
                    <Typography variant='h6'>{selectedRole?.name}</Typography>
                </Box>
                <BackButton route='/user-management/roles' />
            </div>
            <Card>
                <CardContent>
                    <BoxForm
                        fullWidth
                        onReset={() => {
                            setCheckedItems(defaultFormValues)
                            setSelectedScopes(selectedRole?.Scopes || [])
                        }}
                        onSubmit={roleOnSave}
                    >
                        <Box display='flex' flexDirection='row' alignItems='center' gap='8px' paddingLeft='30px'>
                            <FormLabel sx={{ fontSize: '24px' }}>
                                Role Type: <strong>{roleType}</strong>
                            </FormLabel>
                        </Box>
                        <ScopesEditor
                            roleType={roleType}
                            scopes={selectedScopes}
                            onChange={(scopes) => setSelectedScopes(scopes)}
                        />

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '25px' }}>
                            <Button variant='text' color='primary' type='reset'>
                                Reset Changes
                            </Button>
                            <Button disabled={!formHasChanges} variant='contained' color='primary' type='submit'>
                                Save
                            </Button>
                        </Box>
                    </BoxForm>
                </CardContent>
            </Card>
        </div>
    )
}

export default RolesPermissions
