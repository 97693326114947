import { Autocomplete, Checkbox, FormControl, TextField } from '@mui/material'
import { SettingsType, getPrettySettingsType } from './types'

interface SettingsTypeFilterProps {
    value: SettingsType[]
    onChange: (v: SettingsType[]) => void
    label?: string
    variant?: 'standard' | 'outlined' | 'filled'
    required?: boolean
    disabled?: boolean
}

export function SettingsTypeFilter({
    value,
    onChange,
    label = 'Type',
    variant,
    required,
    disabled
}: SettingsTypeFilterProps) {
    return (
        <FormControl variant={variant || 'outlined'} required={required} sx={{ width: '100%' }} disabled={disabled}>
            <Autocomplete
                aria-required={required}
                multiple
                disableCloseOnSelect
                disablePortal
                options={Object.values(SettingsType)}
                value={value}
                onChange={(ev, newValue) => {
                    onChange(newValue)
                }}
                getOptionLabel={(option) => {
                    return getPrettySettingsType(option)
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label || 'Type'}
                        variant={variant || 'outlined'}
                        required={required}
                    />
                )}
                renderOption={(props, option, { selected }) => {
                    const { ...optionProps } = props
                    return (
                        <li key={option} {...optionProps}>
                            <Checkbox style={{ marginRight: 8 }} checked={selected} size='small' />
                            {getPrettySettingsType(option)}
                        </li>
                    )
                }}
            />
        </FormControl>
    )
}

export default SettingsTypeFilter
