import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import { AppBar, Dialog, Toolbar, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { documentService } from './document.service'
import { allFolderContext, documentContext, folderContext, fullAccessFoldersContext } from './context'
import { tagService } from '../../system/tags/tag.service'
import DocumentOutput from './documents/DocumentOutput'
import DocumentUploader from './documents/DocumentUploader'
import CircularProgress from '@mui/material/CircularProgress'
import { DefaultLimit, DefaultMax } from '../../../common/constants'
import DocumentFolderOutput from './folders/DocumentFolderOutput'
import CollapsibleAlert from '../../../common/components/CollapsibleAlert'
import helpImg from '../../../assets/document-gallery-alert-help.png'
import TablePagination from '@mui/material/TablePagination'
import { DocumentOptionsSelect } from './DocumentOptionsSelect'
import { useAppContext, useCurrentSite, useCurrentSiteID, useDefaultSitesForSelector } from '../../auth/atoms'
import { EntityScopeEnum } from '../../auth/entityScope'
import { DocumentType } from './types'

export const DocumentGallery = ({ isGalleryOpen, setGalleryClose, isForDct, saveForDct }) => {
    const classes = useStyles()
    const evaluators = useAppContext()

    const currentSite = useCurrentSite()

    const defaultSites = useDefaultSitesForSelector()
    const currentSiteID = useCurrentSiteID()

    const [isEnabled, setIsEnabled] = useState(false)
    const [mediaTags, setMediaTags] = useState([])
    const [collapseAlert, setCollapseAlert] = useState(false)

    // Create
    const [chosenFolder, setChosenFolder] = useState([])
    const [documentsToUpload, setDocumentsToUpload] = useState([])
    const [createModel, setCreateModel] = useState({
        sites: defaultSites,
        department_id: null,
        tags: [],
        privacyLevel: 0
    })
    const [errors, setErrors] = useState({
        sites: false,
        privacyLevel: false
    })

    // Folders
    const [documents, setDocuments] = useContext(documentContext)
    const [, setFolders] = useContext(folderContext)
    const [, setAllFolders] = useContext(allFolderContext)
    const [, setFullAccessFolders] = useContext(fullAccessFoldersContext)
    const [openedFolder, setOpenedFolder] = useState([])
    const [breadCrumbs, setBreadCrumbs] = useState([])

    // Pagination
    const [pagination, setPagination] = useState({
        data: [],
        offset: DefaultLimit,
        perPage: DefaultLimit,
        currentPage: 0,
        total: 0
    })
    const [searchValue, setSearchValue] = useState([])
    const [searchText, setSearchText] = useState('')

    useEffect(() => {
        if (currentSite && currentSite.Type === 'department') {
            setCreateModel((prev) => ({ ...prev, department_id: currentSite.ID }))
        }
    }, [currentSite])

    useEffect(() => {
        if (createModel.sites.length === 0) {
            setErrors((prev) => ({ ...prev, sites: true }))
        } else {
            setErrors((prev) => ({ ...prev, sites: false }))
        }
    }, [createModel.sites])

    useEffect(() => {
        if (isGalleryOpen) {
            Promise.all([
                documentService
                    .getAllByFolderAndType(currentSiteID, DocumentType.Document, '', DefaultLimit, 0)
                    .then((r) => r.results),

                documentService.getAll(currentSiteID, DocumentType.Folder).then((r) => r.results),
                tagService.getAll(currentSiteID, 'media').then((r) => r.results)
            ]).then(([docs, folders, tags]) => {
                setDocuments(docs)

                setFolders(folders)
                setAllFolders(folders)
                const foldersWithAccess = folders.filter((folder) => evaluators.action(folder, 'update'))
                setFullAccessFolders(foldersWithAccess)

                setMediaTags(tags)
                setIsEnabled(true)
            })
        }
    }, [isGalleryOpen])

    const selectFolder = (folder) => {
        if (folder && Object.keys(folder).length > 0) {
            setOpenedFolder(folder)

            if (folder.id && folder.id.length) {
                documentService.getDocumentAncestorsById(currentSiteID, folder.id).then((x) => setBreadCrumbs(x))
            } else {
                setBreadCrumbs([])
            }

            documentService
                .getAllByFolderAndType(currentSiteID, DocumentType.Document, folder.id, pagination.perPage, 0)
                .then(
                    ({ results, resultset }) => {
                        setDocuments(results)
                        setPagination((prev) => ({
                            ...prev,
                            total: resultset.total_records,
                            currentPage: 0,
                            offset: 0
                        }))
                    },
                    (e) => console.log(e)
                )
            documentService.getAllByFolderAndType(currentSiteID, DocumentType.Folder, folder.id, DefaultMax, 0).then(
                ({ results }) => {
                    setFolders(results)
                },
                (e) => console.log(e)
            )
        }
    }

    const loadPaginatedDocuments = async (limit, offset) => {
        offset = offset * limit
        const response = await documentService.getByParameter(
            currentSiteID,
            searchValue.map((x) => x.id),
            searchText,
            openedFolder.id,
            DocumentType.Document,
            limit,
            offset
        )
        if (response) {
            const { results } = response
            setDocuments(results)
            setPagination((prev) => ({ ...prev, pageCount: Math.ceil(prev.total / limit) }))
            return results
        }
    }

    const handleChangeRowsPerPage = async (event) => {
        // Reset to the first page and adjust the per-page limit
        const perPage = parseInt(event.target.value, 10)
        setPagination((prev) => ({ ...prev, currentPage: 0, perPage: perPage }))
        await loadPaginatedDocuments(perPage, 0)
    }

    const handleChangePage = async (event, newPage) => {
        let offset = Math.ceil(newPage * pagination.perPage)
        setPagination((prev) => ({ ...prev, currentPage: newPage, offset: offset }))
        await loadPaginatedDocuments(pagination.perPage, newPage)
    }

    const appbarRef = React.useRef()

    const canUpload = useMemo(
        () => evaluators.actionForEntityScope(EntityScopeEnum.Document, 'create'),
        [evaluators, currentSiteID]
    )
    return (
        <Fragment>
            <Dialog fullScreen open={isGalleryOpen} onClose={setGalleryClose}>
                <AppBar ref={appbarRef}>
                    <Toolbar>
                        <IconButton
                            edge='start'
                            color='inherit'
                            onClick={setGalleryClose}
                            aria-label='close'
                            size='large'
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant='h6' className={classes.title}>
                            Document Gallery
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div
                    style={{
                        minHeight: appbarRef?.current?.clientHeight || appbarRef?.current?.height || '4rem',
                        width: '100%'
                    }}
                />
                {isEnabled ? (
                    <div className='container-fluid border-box full-width'>
                        <div className='row' style={canUpload ? {} : { margin: '2rem' }}>
                            <div className={`col-xs-12 col-md-12 ${canUpload ? 'col-lg-6' : 'col-lg-12'}  first-lg`}>
                                <h1>Choose a Document</h1>
                                <CollapsibleAlert
                                    severity={'info'}
                                    collapseAlert={collapseAlert}
                                    setCollapseAlert={setCollapseAlert}
                                    style={{ marginTop: '5px', marginBottom: '20px' }}
                                >
                                    {alertHelpComponent()}
                                </CollapsibleAlert>

                                <DocumentFolderOutput
                                    mediaTags={mediaTags}
                                    selectFolder={selectFolder}
                                    openedFolder={openedFolder}
                                    setOpenedFolder={setOpenedFolder}
                                    breadCrumbs={breadCrumbs}
                                    setBreadCrumbs={setBreadCrumbs}
                                    saveForDct={saveForDct}
                                    isForDct={isForDct}
                                    isForGallery
                                    pagination={pagination}
                                    setPagination={setPagination}
                                    searchText={searchText}
                                    setSearchText={setSearchText}
                                    searchValue={searchValue}
                                    setSearchValue={setSearchValue}
                                />

                                <DocumentOutput
                                    handleSave={saveForDct}
                                    isForDct={isForDct}
                                    tags={mediaTags}
                                    isEnabled={true}
                                />
                                <TablePagination
                                    component='div'
                                    style={{ display: 'flex', justifyContent: 'center' }}
                                    count={pagination.total}
                                    page={pagination.currentPage}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={pagination.perPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </div>
                            {canUpload && (
                                <div className='col-xs-12 col-md-12 col-lg-6  first-md first-xs'>
                                    <DocumentUploader
                                        errors={errors}
                                        setDocuments={setDocuments}
                                        chosenFolder={chosenFolder}
                                        chosenSites={createModel.sites}
                                        department_id={createModel.department_id}
                                        chosenBulkTags={createModel.tags}
                                        privacyLevel={createModel.privacyLevel}
                                        documentsToUpload={documentsToUpload}
                                        setDocumentsToUpload={setDocumentsToUpload}
                                    />
                                    <DocumentOptionsSelect
                                        isDocumentUpload
                                        errors={errors}
                                        setErrors={setErrors}
                                        folderModel={createModel}
                                        setFolderModel={setCreateModel}
                                        chosenFolder={chosenFolder}
                                        setChosenFolder={setChosenFolder}
                                        openedFolder={openedFolder}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <CircularProgress style={{ margin: 'auto' }} />
                )}
            </Dialog>
        </Fragment>
    )
}

export default DocumentGallery

const alertHelpComponent = () => {
    return (
        <div className={'flex-row'}>
            <img
                style={{ width: '40%' }}
                src={helpImg}
                alt={
                    'Click on your chosen document below to copy the file’s URL. Create a hyperlink to your file in the text editor by highlighting text, choosing the link icon in the toolbar, and pasting the URL.'
                }
            />
            <span style={{ width: '60%' }} className='ml5p'>
                Click on your chosen document below to copy the file’s URL. Create a hyperlink to your file in the text
                editor by highlighting text, choosing the link icon in the toolbar, and pasting the URL.
            </span>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    auto: {
        width: '90%',
        marginTop: '1vh',
        marginBottom: '1vh'
    },
    appBar: {
        position: 'relative',
        backgroundColor: '#2196f3'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1
    },
    back: {
        gridColumnStart: 3
    },
    flexEndDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    spaceBetweenDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    select: {
        width: '90%'
    },
    button: {
        alignSelf: 'flex-end',
        marginTop: '2vh',
        marginBottom: '2vh'
    },
    paper: {
        outline: 0, // Disable browser on-focus borders
        boxShadow: theme.shadows[5],
        backgroundColor: 'white',
        width: '35vw',
        marginTop: '20vh',
        marginRight: 'auto',
        marginLeft: 'auto',
        display: 'flex',
        flexDirection: 'column'
    }
}))
