export function validateRequiredValues(object: Record<string, any>) {
    const missingValues: Record<string, boolean> = {}
    let hasMissingValues = false
    for (const key in object) {
        let val = object[key]
        if (typeof val === 'object') {
            if (!Array.isArray(val)) {
                val = Object.keys(val)
            }
            if (!val?.length) {
                missingValues[key] = true
                hasMissingValues = true
            }
            continue
        }
        if (typeof val === 'boolean') {
            continue
        }
        if (!object[key]) {
            missingValues[key] = true
            hasMissingValues = true
        }
    }
    return { error: hasMissingValues, errors: missingValues }
}
