import React, { useState } from 'react'

const folderContext = React.createContext([{}, (r) => {}])

const FolderContextProvider = (props) => {
    const [folder, setFolder] = useState([])
    return <folderContext.Provider value={[folder, setFolder]}>{props.children}</folderContext.Provider>
}

export { folderContext, FolderContextProvider }
