import moment from 'moment'

export function formatRegularTimestamp(timestamp: string, format?: string) {
    const momentDate = moment(timestamp)
    if (!momentDate.isValid()) {
        return ''
    }
    return momentDate.format(format || 'YYYY-MM-DD, h:mm:ss a')
}

export function formatAllDayEndDateTimestamp(endDate: string, format?: string) {
    const momentDate = moment(endDate)
    if (!momentDate.isValid()) {
        return ''
    }
    return momentDate
        .utc()
        .subtract(1, 'days')
        .format(format || 'YYYY-MM-DD')
}

export function formatAllDayStartDateTimestamp(startDate: string, format?: string) {
    const momentDate = moment(startDate)
    if (!momentDate.isValid()) {
        return ''
    }
    return momentDate.utc().format(format || 'YYYY-MM-DD')
}

export interface EventSchedule {
    isAllDay: boolean
    startdate: string
    enddate: string
}

export function formatEventSchedule(schedule: EventSchedule) {
    const { isAllDay, startdate = '', enddate = '' } = schedule || {}
    const fmtStartDate = isAllDay
        ? formatAllDayStartDateTimestamp(startdate)
        : moment(startdate).format('YYYY-MM-DD, h:mm:ss a')
    const fmtEndDate = isAllDay
        ? formatAllDayEndDateTimestamp(enddate)
        : moment(enddate).format('YYYY-MM-DD, h:mm:ss a')
    return [fmtStartDate, fmtEndDate]
}
