import React, { useState } from 'react'

const selectedDocumentContext = React.createContext([{}, (r) => {}])

const SelectedDocumentContextProvider = (props) => {
    const [selectedDocument, setSelectedDocument] = useState({})
    return (
        <selectedDocumentContext.Provider value={[selectedDocument, setSelectedDocument]}>
            {props.children}
        </selectedDocumentContext.Provider>
    )
}

export { selectedDocumentContext, SelectedDocumentContextProvider }
