import React, { useEffect, useState } from 'react'
import {
    Card,
    CardContent,
    CircularProgress,
    FormControl,
    FormLabel,
    Input,
    InputLabel,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Select,
    TextField
} from '@mui/material'
import { notify } from '../../../helpers'
import LockIcon from '@mui/icons-material/Lock'

import { makeStyles } from '@mui/styles'
import axios from 'axios'
import CreateIcon from '@mui/icons-material/Create'
import DeleteIcon from '@mui/icons-material/Delete'
import ConfirmAction from '../../../common/components/ConfirmAction'
import { v4 as uuidv4 } from 'uuid'
import DialogFormAction from '../../../common/components/DialogFormAction'
import { TemplateRow } from '../../../common/components/TemplateRow'
import { TemplateTableHeader } from '../../../common/components/TemplateTableHeader'

import TablePagination from '@mui/material/TablePagination'
import newUrlParam from '../../../helpers/newUrlParam'
import { ContentAPI, DelayedSearchTimeout, DefaultLimit, DefaultPage } from '../../../common/constants'
import InputAdornment from '@mui/material/InputAdornment'
import lodash from 'lodash'
import { contentService } from '../content.service'
import { useNavigate } from 'react-router-dom'
import { useAppContext, useCurrentSite, useCurrentSiteID } from '../../auth/atoms'
import PageContainerWithHeader from '../../../common/components/PageContainerWithHeader'
import { AddButton } from '../../../common/components'
import { ContentPublishedTypes, ContentType } from '../types'
import { RouteEditor } from '../../../pkgs/content/editor/components/RouteEditor'

export function Resources() {
    const currentSiteID = useCurrentSiteID()
    const currentSite = useCurrentSite()
    const evaluators = useAppContext()

    const [dialogueOpen, setDialogueOpen] = useState(false)
    const [localDialogueState, setLocalDialogueState] = useState([])
    const navigate = useNavigate()
    const classes = useStyles()
    const [errorState, setErrorState] = useState({
        name: false,
        type: false
    })
    const [state, setState] = useState({
        id: uuidv4(),
        title: '',
        type: 'js',
        path: '',
        content: '',
        owner: '',
        publisher: ''
    })

    const [searchLoading, setSearchLoading] = useState(false)

    const limit = 10
    const [contentSearchTerm, setContentSearchTerm] = useState('')
    const [paginationState, setPaginationState] = useState({
        data: [],
        offset: limit,
        perPage: limit,
        currentPage: 0,
        total: 0
    })
    const [open, setOpen] = useState(false)
    const handleClose = () => setOpen(false)

    useEffect(() => {
        let page = DefaultPage
        let limit = DefaultLimit
        // if (locationState?.title === 'resources') {
        //     const { currentPage, perPage, offset } = locationState
        //     page = currentPage
        //     limit = perPage
        //     setPaginationState((prev) => ({ ...prev, perPage, currentPage, offset }))
        //     navigate(`/settings?siteId=${currentSiteID}`, { replace: true, state: {} })
        //     // history.replace(`/settings?siteId=${selectedSite}`, {})
        // }

        loadContentFromServer(limit, page)
    }, [currentSiteID])

    useEffect(() => {
        if (!currentSiteID) return
        setSearchLoading(true)
        const timeout = setTimeout(searchForContentBySearchTerm, DelayedSearchTimeout)

        return () => {
            clearTimeout(timeout)
        }
    }, [contentSearchTerm])

    const handleDeleteDialogAction = async () => {
        try {
            const body = lodash.cloneDeep(localDialogueState)
            const response = await contentService.deleteContent(currentSiteID, body)
            if (response) {
                let currentPage = paginationState.currentPage
                if (paginationState.data.length === 1 && paginationState.currentPage) {
                    currentPage -= 1
                    setPaginationState((prev) => ({ ...prev, currentPage }))
                }
                loadContentFromServer(paginationState.perPage, currentPage)
                setDialogueOpen(false)
            } else await Promise.reject({ message: 'bad request' })
        } catch (e) {
            setDialogueOpen(false)
            notify("Whoops! Couldn't delete that page - Please try again later", 'error')
        }
    }

    const handleDialogueDisagree = () => {
        setDialogueOpen(false)
    }

    const [anchorElement, setAnchorElement] = useState(null)
    const [actionItem, setActionItem] = useState(null)

    const setMenuElement = async (event, state) => {
        const anchorEl = event.currentTarget
        try {
            const response = await contentService.getContentById(currentSiteID, state.id)
            if (response) {
                setActionItem(response)
                setAnchorElement(anchorEl)
            } else await Promise.reject({ message: 'unauthorized' })
        } catch {
            setActionItem(null)
            setAnchorElement(null)
        }
    }

    const ContextMenu = () => {
        const handleDialogueOpen = () => {
            setLocalDialogueState(actionItem)
            setDialogueOpen(true)
            setAnchorElement(null)
        }

        const navigateToEditor = () => {
            // TODO => RF
            navigate(`/resource/${actionItem?.id}?siteId=${currentSiteID}`, {
                state: {
                    ...actionItem,
                    mode: actionItem.type,
                    selectedSite: currentSiteID,
                    paginationState: {
                        title: 'resources',
                        offset: paginationState.offset,
                        perPage: paginationState.perPage,
                        currentPage: paginationState.currentPage
                    }
                }
            })
        }
        const hasPermission = evaluators.action(actionItem, 'update')
        return (
            <Menu
                anchorEl={anchorElement}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                keepMounted
                transformOrigin={{ vertical: 10, horizontal: 80 }}
                open={Boolean(anchorElement)}
                onClose={() => setAnchorElement(null)}
            >
                <div style={{ minWidth: '10rem' }}>
                    <MenuItem onClick={navigateToEditor}>
                        <ListItemIcon className={classes.iconSize}>
                            {hasPermission ? <CreateIcon /> : <LockIcon />}
                        </ListItemIcon>
                        <ListItemText>{hasPermission ? 'Edit' : 'View'}</ListItemText>
                    </MenuItem>

                    <MenuItem onClick={handleDialogueOpen} disabled={!hasPermission}>
                        <ListItemIcon className={classes.iconSize}>
                            <DeleteIcon />
                        </ListItemIcon>
                        <ListItemText>Delete</ListItemText>
                    </MenuItem>
                </div>
            </Menu>
        )
    }

    const navigateToEditor = () => {
        // TODO => RF
        navigate(`/resource/${state?.id}?siteId=${currentSiteID}`, {
            state: {
                ...state,
                mode: state.type,
                paginationState: {
                    title: 'resources',
                    offset: paginationState.offset,
                    perPage: paginationState.perPage,
                    currentPage: paginationState.currentPage
                }
            }
        })
    }

    // Pagination //
    const handleChangeRowsPerPage = (event) => {
        // Query params with multiple identical keys
        let params = newUrlParam(
            currentSiteID,
            parseInt(event.target.value, 10).toString(),
            0,
            contentSearchTerm,
            'contentType',
            ['js', 'css']
        )

        // Reset to the first page and adjust the per-page limit
        const firstPage = 0
        setPaginationState((prev) => ({
            ...prev,
            currentPage: firstPage,
            perPage: parseInt(event.target.value, 10)
        }))

        // Get the content with the above params
        axios.get(ContentAPI, { params }).then((content) => {
            setPaginationState((prev) => ({
                ...prev,
                data: content.data.results,
                pageCount: Math.ceil(paginationState.total / content.data.resultset.limit) // Total count x limit
            }))
        })
    }

    const loadContentFromServer = (limit, page) => {
        contentService
            .getAll(
                currentSiteID,
                [ContentType.JS, ContentType.CSS],
                page,
                limit,
                contentSearchTerm,
                ContentPublishedTypes.all
            )
            .then(({ results, resultset: { total_records, limit } }) =>
                setPaginationState((prev) => ({
                    ...prev,
                    data: results,
                    total: total_records,
                    pageCount: Math.ceil(total_records / limit) // Total count x limit
                }))
            )
    }

    const handleChangePage = (event, newPage) => {
        let offset = Math.ceil(newPage * paginationState.perPage)
        setPaginationState((prev) => ({
            ...prev,
            currentPage: newPage,
            offset: offset
        }))

        loadContentFromServer(paginationState.perPage, newPage)
    }

    const searchForContentBySearchTerm = () => {
        setSearchLoading(true)
        contentService
            .getAll(
                currentSiteID,
                [ContentType.JS, ContentType.CSS],
                DefaultPage,
                paginationState.perPage,
                contentSearchTerm,
                ContentPublishedTypes.all
            )
            .then(
                ({ results, resultset: { total_records, limit } }) => {
                    setSearchLoading(false)
                    setPaginationState((prev) => ({
                        ...prev,
                        currentPage: 0,
                        data: results,
                        total: total_records,
                        pageCount: Math.ceil(total_records / limit) // Total count x limit
                    }))
                },
                () => {
                    setSearchLoading(false)
                }
            )
    }

    if (currentSite?.Type === 'department') {
        return <div>This feature is not available for departments</div>
    }

    return (
        <PageContainerWithHeader
            title='Resources'
            topRightElement={<AddButton func={() => setOpen(true)} title={'ADD RESOURCE'} />}
        >
            <Card className='sub-container'>
                <CardContent>
                    <FormControl variant='standard' style={{ width: '50%', margin: '20px' }}>
                        <InputLabel htmlFor='input-with-icon-adornment'>Search</InputLabel>
                        <Input
                            id='input-with-icon-adornment'
                            onChange={(event) => setContentSearchTerm(event.target.value)}
                            value={contentSearchTerm}
                            endAdornment={
                                <div>
                                    <InputAdornment position='end' style={{ width: '20px' }}>
                                        {searchLoading && (
                                            <CircularProgress style={{ height: '20px', width: '100%' }} />
                                        )}
                                    </InputAdornment>
                                </div>
                            }
                        />
                    </FormControl>

                    <div className='scaffolding'>
                        {paginationState.data.length > 0 ? (
                            <div className='table-container'>
                                <TemplateTableHeader first='Type' second='Title' third='Shared' fourth='Options' />
                                {paginationState.data.map((x) => (
                                    <TemplateRow key={x.title + x.id} state={x} setMenu={setMenuElement} />
                                ))}
                                {ContextMenu()}
                            </div>
                        ) : (
                            <div className='table-container'>
                                <p>There are no resources available</p>
                            </div>
                        )}
                        <TablePagination
                            component='div'
                            count={paginationState.total}
                            page={paginationState.currentPage}
                            onPageChange={handleChangePage}
                            rowsPerPage={paginationState.perPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>

                    {/*Create*/}
                    {open && (
                        <DialogFormAction
                            item={
                                <div>
                                    <FormControl fullWidth sx={{ my: 2 }}>
                                        <InputLabel id={'demo-simple-select-label'}>Type</InputLabel>
                                        <Select
                                            variant={'outlined'}
                                            labelId='demo-simple-select-label'
                                            label={'Type'}
                                            value={state.type || 'js'}
                                            error={errorState.type}
                                            onChange={(event) => {
                                                setState((prev) => ({ ...prev, type: event.target.value }))
                                                setErrorState((prev) => ({ ...prev, type: false }))
                                            }}
                                        >
                                            <MenuItem value='js'>Javascript</MenuItem>
                                            <MenuItem value='css'>CSS</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <RouteEditor
                                        value={state.title}
                                        onChange={(v) => setState({ ...state, title: v })}
                                        contentType={state.type === 'js' ? ContentType.JS : ContentType.CSS}
                                    />
                                </div>
                            }
                            title='Create a resource'
                            text='What would you like to create?'
                            open={open}
                            handleDisagree={handleClose}
                            handleAgree={navigateToEditor}
                            handleClose={handleClose}
                            fullWidth={true}
                        />
                    )}

                    <ConfirmAction
                        open={dialogueOpen}
                        title='Are you sure?'
                        text="Please confirm if you'd like to delete this resource"
                        handleDisagree={handleDialogueDisagree}
                        handleAgree={handleDeleteDialogAction}
                    />
                </CardContent>
            </Card>
        </PageContainerWithHeader>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            outline: 0,
            marginTop: '0.5vh',
            marginBottom: '1vh',
            margin: 'auto',
            display: 'flex',
            justifyContent: 'column'
        }
    },
    select: {
        width: '100%',
        marginTop: '1vh',
        marginBottom: '1vh'
    },
    form: {
        marginTop: '1vh',
        height: '5%',
        width: '100%'
    },
    button: {
        marginTop: '2vh',
        marginBottom: '2vh'
    },
    iconSize: {
        minWidth: '35px'
    }
}))
