import SidebarContainer from '../../../../common/components/SidebarContainer'

import EditSettingsDefaultSeo from './EditSettingsGeneralSeo'
import EditSettingsIntegrations from './EditSettingsIntegrations'
import EditSettingsCustomFields from './EditSettingsCustomFields'
import EditSettingsSocialMedia from './EditSettingsSocialMedia'
import EditSettingsGeneral from './EditSettingsGeneral'
import useSiteForAccountsQueryMutation from '../../../../common/query-hooks/useSiteForAccountsQueryMutation'
import { contentTypes } from '../../../../common/components/selectors/SiteSelectorForAccount'
import { useAppContext } from '../../../auth/atoms'
import { EntityScopeEnum } from '../../../auth/entityScope'
import { useParams } from 'react-router-dom'
import { BackButton } from '../../../../common/components'

export const editSettingsTabs = [
    {
        name: 'General',
        component: <EditSettingsGeneral />,
        contentType: 'site-settings'
    },
    {
        name: 'Social Media',
        component: <EditSettingsSocialMedia />,
        contentType: 'site-settings'
    },
    {
        name: 'Default SEO',
        component: <EditSettingsDefaultSeo />,
        contentType: 'site-settings'
    },
    {
        name: 'Integrations > Instagram',
        component: <EditSettingsIntegrations />,
        contentType: 'instagram',
        entityScope: EntityScopeEnum.InstagramSettings
    },
    {
        name: 'Custom Fields',
        component: <EditSettingsCustomFields />,
        contentType: 'site-settings'
    }
]

function EditSiteSettings() {
    const params = useParams()
    const evaluators = useAppContext()
    const { siteData, error } = useSiteForAccountsQueryMutation(params.id)
    return (
        <SidebarContainer
            title='Settings'
            subtitle={(siteData as any)?.name || undefined}
            tabs={
                error || !siteData?.id
                    ? []
                    : editSettingsTabs
                          .filter((tab) =>
                              evaluators.action(
                                  {
                                      sites: [siteData.id],
                                      departmentId: null,
                                      type: tab.contentType as contentTypes,
                                      EntityScope: tab?.entityScope || EntityScopeEnum.Settings
                                  },
                                  'update'
                              )
                          )
                          .map((tab) => ({ name: tab.name, content: siteData ? tab.component : null }))
            }
            topRightElement={<BackButton route='/system/sites' />}
        />
    )
}

export default EditSiteSettings
