import { $isAtNodeEnd } from '@lexical/selection'
import { computePosition, Placement } from '@floating-ui/react-dom'
import { MutableRefObject } from 'react'

export const LowPriority = 1

export function getSelectedNode(selection) {
    const anchor = selection.anchor
    const focus = selection.focus
    const anchorNode = selection.anchor.getNode()
    const focusNode = selection.focus.getNode()
    if (anchorNode === focusNode) {
        return anchorNode
    }
    const isBackward = selection.isBackward()
    if (isBackward) {
        return $isAtNodeEnd(focus) ? anchorNode : focusNode
    } else {
        return $isAtNodeEnd(anchor) ? focusNode : anchorNode
    }
}

export async function positionFloatingMenu(
    $elementToPosition: HTMLElement | null,
    $anchorElement: HTMLElement | null,
    placement?: Placement,
    add?: {
        y: number
        x: number
    }
) {
    try {
        if ($elementToPosition === null) {
            return
        }
        if ($anchorElement === null) {
            $elementToPosition.style.opacity = '0'
            $elementToPosition.style.top = '-10000px'
            $elementToPosition.style.left = '-10000px'
        } else {
            const position = await computePosition($anchorElement, $elementToPosition, { placement })
            $elementToPosition.style.top = position.y + (add?.y || 0) + 'px'
            $elementToPosition.style.left = position.x + (add?.x || 0) + 'px'
            $elementToPosition.style.position = position.strategy
            $elementToPosition.style.opacity = '1'
        }
    } catch (e) {
        console.error(e)
    }
}

export async function $positionFloatingMenu(
    $elementToPosition: MutableRefObject<null>,
    $anchorElement: MutableRefObject<null>,
    placement?: Placement,
    add?: {
        y: number
        x: number
    }
) {
    return positionFloatingMenu($elementToPosition.current, $anchorElement.current, placement, add)
}
