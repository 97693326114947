import React, { useState } from 'react'

const allFolderContext = React.createContext([{}, (r) => {}])

const AllFolderContextProvider = (props) => {
    const [allFolders, setAllFolders] = useState([])
    return <allFolderContext.Provider value={[allFolders, setAllFolders]}>{props.children}</allFolderContext.Provider>
}

export { allFolderContext, AllFolderContextProvider }
