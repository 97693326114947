import React, { useState } from 'react'

const disabledContext = React.createContext<[boolean, React.Dispatch<React.SetStateAction<boolean>>]>([false, (b) => b])

const DisabledContextProvider = (props) => {
    const [disabled, setDisabled] = useState<boolean>(false)
    return <disabledContext.Provider value={[disabled, setDisabled]}>{props.children}</disabledContext.Provider>
}

export { disabledContext, DisabledContextProvider }
