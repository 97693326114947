import { validateUUID } from './index'

const isObject = (potential) => {
    return potential != null && typeof potential === 'object' && !Array.isArray(potential)
}
const isArray = (potential) => {
    return potential != null && typeof potential === 'object' && Array.isArray(potential)
}
const isFunc = (potential) => {
    return potential != null && typeof potential === 'function'
}
const objectArray = (potential) => {
    if (!isArray(potential) || !potential.length) return false
    return potential.length === potential.filter((x) => isObject(x)).length
}
const number = (potential) => {
    return potential != null && typeof potential === 'number' && !isNaN(potential)
}
const stringArray = (potential) => {
    if (!potential?.length || !isArray(potential)) return false
    return potential.every((x) => typeof x === 'string')
}
const nullableUUID = (potential) => {
    return isObject(potential) && potential?.UUID != null && potential?.Valid != null
}
const isUUID = (potential) => {
    return potential && typeof potential == 'string' && validateUUID(potential)
}
const document = (potential) => {
    const { filename, active, id, path, type, privacy_level, privacyLevel = privacy_level, created } = potential || {}
    return Boolean(
        typeof filename === 'string' &&
            typeof active === 'boolean' &&
            typeof id === 'string' &&
            typeof path === 'string' &&
            typeof type === 'string' &&
            typeof privacyLevel === 'number' &&
            typeof created === 'string'
    )
}
export const is = {
    number,
    func: isFunc,
    object: isObject,
    array: isArray,
    objectArray,
    stringArray,
    nullUUID: nullableUUID,
    uuid: isUUID,
    document
}
