import React, { useRef, useState } from 'react'
import { AppBar, Dialog, IconButton, Toolbar, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import { Image, MediaExplorer } from './MediaExplorer'
import { MediaUploader } from './MediaUploader'
import { ScrollToTopIconButton } from '../../../app/ApplicationWrapper'

interface ImageGalleryProps {
    open: boolean
    close: () => void
    onChange: (image: Image) => void
}

export const ImageGallery = ({ open, close, onChange }: ImageGalleryProps) => {
    const [trigger, setTrigger] = useState(false)
    const classes = useStyles()

    const appbarRef = useRef<HTMLDivElement | null>(null)

    const imageGalleryRef = useRef<HTMLDivElement | null>(null)
    const [visible, setVisible] = useState(false)

    const toggleVisible = (e) => {
        if (e) {
            const scrolled = e.target.scrollTop
            if (scrolled > 300) {
                setVisible(true)
            } else if (scrolled <= 300) {
                setVisible(false)
            }
        }
    }

    const scrollToTop = () => {
        imageGalleryRef?.current?.scrollIntoView({
            block: 'start',
            behavior: 'smooth'
        })
    }

    return (
        <Dialog
            data-testid='image-gallery-dialog'
            fullScreen
            open={open}
            PaperProps={{
                onScroll: toggleVisible
            }}
        >
            {/* must wrap dialog content in div to execute scroll function */}
            <div ref={imageGalleryRef}>
                <AppBar ref={appbarRef}>
                    <Toolbar>
                        <IconButton edge='start' color='inherit' onClick={close} aria-label='close' size='large'>
                            <CloseIcon />
                        </IconButton>
                        <Typography variant='h6' className={classes.title}>
                            Image Gallery
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div style={{ minHeight: appbarRef?.current?.clientHeight || '4rem', width: '100%' }} />

                <div className='container-fluid border-box full-width'>
                    <div className='row'>
                        <div className='col-xs-12 col-md-12 col-lg-7  first-lg'>
                            <MediaExplorer
                                mode={'select'}
                                onSelect={(image) => {
                                    onChange(image)
                                    close()
                                }}
                                trigger={trigger}
                            />
                        </div>

                        <div className='col-xs-12 col-md-12 col-lg-5 first-md first-xs'>
                            <MediaUploader
                                onUpload={() => {
                                    setTrigger(!trigger)
                                }}
                            />
                        </div>
                    </div>
                    {visible && <ScrollToTopIconButton onClick={scrollToTop} iconButtonSxProps={{ bottom: '28px' }} />}
                </div>
            </div>
        </Dialog>
    )
}

export default ImageGallery

const useStyles = makeStyles((theme) => ({
    auto: {
        width: '90%',
        marginTop: '1vh',
        marginBottom: '1vh'
    },
    appBar: {
        position: 'relative',
        backgroundColor: '#2196f3'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1
    },
    back: {
        gridColumnStart: 3
    },
    flexEndDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    spaceBetweenDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    select: {
        width: '90%'
    },
    button: {
        alignSelf: 'flex-end',
        marginTop: '2vh',
        marginBottom: '2vh'
    },
    paper: {
        outline: 0, // Disable browser on-focus borders
        boxShadow: theme.shadows[5],
        backgroundColor: 'white',
        width: '35vw',
        marginTop: '20vh',
        marginRight: 'auto',
        marginLeft: 'auto',
        display: 'flex',
        flexDirection: 'column'
    },
    textfield: {
        width: '100%'
    }
}))
