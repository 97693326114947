import { IconButton, InputAdornment } from '@mui/material'
import TextFieldDebounced, { TextFieldDebouncedHandlers } from './TextFieldDebounced'
import SearchIcon from '@mui/icons-material/Search'
import CancelIcon from '@mui/icons-material/Cancel'
import { useRef } from 'react'

interface SearchBarProps {
    value: string
    onChange: (val: string) => void
    helperText?: string
}

function SearchBar({ value, onChange, helperText }: SearchBarProps) {
    const searchTextFieldRef = useRef<TextFieldDebouncedHandlers>(null)

    return (
        <TextFieldDebounced
            ref={searchTextFieldRef}
            placeholder={'Type here'}
            label={'Search'}
            value={value}
            helperText={helperText}
            onChange={(ev) => {
                const search = ev?.target?.value || ''
                onChange?.(search)
            }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position={'start'}>
                        <SearchIcon />
                    </InputAdornment>
                ),
                endAdornment: value?.length ? (
                    <InputAdornment position='end'>
                        <IconButton
                            onClick={() => {
                                onChange?.('')
                                searchTextFieldRef?.current?.setValue('')
                            }}
                            title='Clear'
                        >
                            <CancelIcon />
                        </IconButton>
                    </InputAdornment>
                ) : undefined
            }}
        />
    )
}

export default SearchBar
