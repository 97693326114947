import { BoxForm } from '../../common/components/BoxForm'
import { TextField } from '@mui/material'
import { CodeEditor } from '../monaco/code-editor'
import { editor } from 'monaco-editor'
import HelpTooltip from '../../common/components/HelpTooltip'
import IMarker = editor.IMarker

// copied from TemplateEditor.js
const renderError = (data) => {
    if (data.includes('invalid memory address')) {
        data += '\n This is most likely cause by an invalid memory access - e.g - Array[0] instead of Array.[0] \n'
    }
    if (data.includes('Expecting OpenEndBlock')) {
        data +=
            '\n This is most likely caused by a helper being opened but not having a corresponding closing tag - e.g - {{#if}} ... \n'
    }
    if (data.includes('Expecting ID')) {
        data +=
            '\n This is most likely caused by a helper being opened but not having a helper name after the # or / - e.g - {{# }} or {{/ }}  \n'
    }
    if (data.includes("doesn't match")) {
        data +=
            '\n This is most likely caused by a helper being opened but not having a matching closing tag - e.g - {{#if }}{{/notIf}} \n'
    }
    if (data.includes('Evaluation error: Helper')) {
        data +=
            '\n This is most likely caused by a helper being opened but not having the right number of arguments - e.g - {{#if }} instead of {{#if true}} \n'
    }
    return data
}

export type StructureDTO = {
    Name: string
    Template: string
    FormStructure: string
}

interface StructureFormProps {
    onSubmit: () => void
    onReset: () => void
    value: StructureDTO
    onChange: (value: StructureDTO) => void
    formControlSection: JSX.Element
}

function StructureForm({ value, onChange, onSubmit, onReset, formControlSection }: StructureFormProps) {
    return (
        <BoxForm
            fullWidth
            onSubmit={onSubmit}
            onReset={onReset}
            sx={{
                overflow: 'visible'
            }}
        >
            <TextField
                required
                variant='standard'
                label='Structure Name'
                value={value.Name}
                onChange={(ev) => onChange({ ...value, Name: ev.target.value })}
            />
            <div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <h2 className='editor-title'>Template</h2>
                    <div style={{ marginLeft: '5px', marginTop: '0.3rem' }}>
                        <HelpTooltip
                            tooltipTextStyles={{
                                width: '650px',
                                textAlign: 'left',
                                left: '100px',
                                bottom: '0%'
                            }}
                            contents={[
                                'ContentForHandlebars : The Current Content / Fragment',
                                'Timezone : Local Timezone of the School District',
                                'Account : The user (Authenticated or Unauthenticated) that is viewing this content when it renders.',
                                'Site : The Site that this fragment is being rendered on.',
                                "Dct : The data that is created through the form in JSON Binary format, requires json functions ({{#jsonRender Dct 'section.component'}})",
                                'DctMap : The data that is created through the form in JSON format, can be used without additional functions ({{DctMap.section.component}}'
                            ]}
                        />
                    </div>
                </div>
                <CodeEditor
                    value={value.Template}
                    language={'html'}
                    onChange={(str) => onChange({ ...value, Template: str || '' })}
                    height={'60vh'}
                />
            </div>
            <div>
                <h2 className='editor-title'>Form Structure</h2>
                <CodeEditor
                    value={value.FormStructure}
                    language={'json'}
                    onChange={(str) => onChange({ ...value, FormStructure: str || '' })}
                    onValidate={(markers: IMarker[]) => console.log(markers)}
                    height={'60vh'}
                />
            </div>
            {formControlSection}
        </BoxForm>
    )
}

export default StructureForm
