import { z } from 'zod'
import { PagingQuery, SortingQuery, paged, trackable } from '../../../common/react-query'

export type TopicsQuery = {
    Search?: string
    TopicType?: string
    AdminId?: string
    QSiteId?: string
    BusRouterId?: string
    Active?: boolean
} & PagingQuery &
    SortingQuery

const topicType = z.enum(['alert', 'bus_alert', 'news', 'events', 'whats_new', 'board_agenda', 'custom'])
const deliveryType = z.enum(['email', 'sms'])
const topicEditor = z.object({
    ID: z.string().nullable().optional(),
    DeliveryType: deliveryType,
    TopicType: topicType,
    Title: z.string().nullable().optional(),
    Description: z.string().nullable().optional(),
    ScheduleID: z.string().nullable().optional(),
    RelayID: z.string().nullable().optional(),
    SiteID: z.string().nullable().optional(),
    BusRouteID: z.string().nullable().optional(),
    Active: z.boolean()
})
const topic = trackable.extend(topicEditor.shape)
export const topics = paged.extend({
    Rows: z.array(topic)
})
export type Topics = z.infer<typeof topics>
export type Topic = z.infer<typeof topic>
export type TopicType = z.infer<typeof topicType>
export type TopicForEditor = z.infer<typeof topicEditor>
