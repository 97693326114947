import React from 'react'

export const AppVersion = () => {
    const [uiVersion, setUIVersion] = React.useState<string | null>(null)
    const [apiVersion, setAPIVersion] = React.useState<string | null>(null)

    const parseVersion = (v: string) => {
        if (!v || v.length < 5) return 'dev'
        return v.substring(0, 5)
    }

    React.useEffect(() => {
        fetch('/api/v2/version')
            .then((r) => r.text())
            .then((v) => {
                setAPIVersion(parseVersion(v))
            })
            .catch(console.error)

        fetch('/image_tags.txt')
            .then((r) => {
                if (r.ok && r.headers.get('Content-type')?.includes('plain')) return r.text()
                setUIVersion('dev')
                return null
            })
            .then((v) => {
                if (!v) return
                setUIVersion(parseVersion(v))
            })
            .catch(console.error)
    }, [])

    if (!apiVersion || !uiVersion) return null

    return (
        <div
            style={{
                position: 'fixed',
                width: '225px',
                bottom: '0',
                left: '0',
                padding: '5px 0 16px 16px',
                backgroundColor: '#f0f0f0',
                fontSize: '13px'
            }}
        >
            API: {apiVersion}&nbsp; UI: {uiVersion}
        </div>
    )
}
