import { Box, CircularProgress } from '@mui/material'

function CenteredSpinner() {
    return (
        <Box width='100%' justifyContent='center' textAlign='center'>
            <CircularProgress size={36} />
        </Box>
    )
}

export default CenteredSpinner
