import React, { Dispatch, Fragment, useEffect, useState } from 'react'
import { Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import '../../common/editor/DctEditor.css'
import { DctCollectionView } from './DctCollection'

const useStyles = makeStyles({
    save: {
        marginRight: '1vw',
        alignSelf: 'flex-end'
    },
    manipulate: {
        marginTop: '1vh'
    }
})

interface DctCollectionActionBarProps {
    newButtonOnClickHandler: () => void
    addButtonOnClickHandler: () => void
    updateButtonOnClickHandler: () => void
    disabled: boolean
    view: string
    setView: Dispatch<DctCollectionView>
    sectionData: any[]
    section: any
}

export const DctCollectionActionBar = ({
    newButtonOnClickHandler,
    addButtonOnClickHandler,
    updateButtonOnClickHandler,
    disabled,
    view,
    setView,
    sectionData,
    section
}: DctCollectionActionBarProps) => {
    const classes = useStyles()
    const [hasReachedMaximum, setHasReachedMaximum] = useState(false)

    useEffect(() => {
        setHasReachedMaximum(!canExpandCollection())
    }, [sectionData])

    const canExpandCollection = () => {
        if (!section.hasOwnProperty('maxCollectionCount')) return true
        return sectionData && sectionData.length < section.maxCollectionCount
    }

    return (
        <Fragment>
            <div className='multi-action'>
                {view !== 'catalogue' && (
                    <div>
                        <Button className={classes.manipulate} onClick={() => setView('catalogue')} color='primary'>
                            VIEW ALL
                        </Button>
                        {view === 'add' ? (
                            <Button
                                className={classes.manipulate}
                                title={'Add'}
                                aria-label={'Add'}
                                onClick={addButtonOnClickHandler}
                                disabled={disabled || hasReachedMaximum}
                                color='primary'
                            >
                                ADD
                            </Button>
                        ) : (
                            <Button
                                disabled={disabled}
                                title={'Update'}
                                aria-label={'Update'}
                                className={classes.manipulate}
                                onClick={updateButtonOnClickHandler}
                                color='primary'
                            >
                                UPDATE
                            </Button>
                        )}
                    </div>
                )}

                {view === 'catalogue' && (
                    <div>
                        <Button
                            aria-label={'New'}
                            onClick={newButtonOnClickHandler}
                            disabled={disabled || hasReachedMaximum}
                            color='primary'
                        >
                            NEW
                        </Button>
                    </div>
                )}
            </div>
            {hasReachedMaximum && (
                <em style={{ opacity: '0.6', marginLeft: '5px' }}>
                    Maximum number of items for this collection has been reached
                </em>
            )}
        </Fragment>
    )
}

// export default DctCollectionActionBar
