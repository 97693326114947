import React from 'react'
import { Button } from '@mui/material'

interface GoToNavigationProps {
    id: string
    active: boolean | undefined
}

export const GoToNavigation = ({ id, active }: GoToNavigationProps) => {
    if (!active) {
        return <div>Save your changes to access Navigation</div>
    }
    return (
        <Button
            variant='outlined'
            color='primary'
            style={{ display: 'block', width: '100%' }}
            onClick={() => {
                const currentParams = new URLSearchParams(window.location.search)
                currentParams.set('searchQuery', id)
                const newUrl = `/navigation?${currentParams.toString()}`
                window.open(newUrl)
            }}
        >
            Manage Navigation
        </Button>
    )
}
