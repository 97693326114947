import React, { useState } from 'react'
import { Avatar, Button, Card, Collapse, ListItem, ListItemAvatar } from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { PrimaryColor } from '../../common/constants'
import { daysBetweenDates } from '../../helpers/date'
import NewReleasesIcon from '@mui/icons-material/NewReleases'

/**
 * =========================================
 *  This method of release note is deprecated
 *  =========================================
 *
 *  ReleaseNotes.tsx is the appropriate method of rendering release notes, using .md files.
 * */
export function ListItemIcon({ onClick, styles, Icon, avatarStyles }) {
    const props = {}
    if (styles) props['style'] = styles
    if (onClick) props['onClick'] = onClick
    avatarStyles = avatarStyles || { backgroundColor: '#2196f3' }
    return (
        <ListItemAvatar {...props}>
            <Avatar style={avatarStyles}>{Icon}</Avatar>
        </ListItemAvatar>
    )
}

export const ReleaseNoteMemo = React.memo(ReleaseNote)

export function ReleaseNote({ title, description, routeTo, releaseDate, Icon, features, bugFixes }) {
    const [active, setActive] = useState(false)
    const hasMore = features || bugFixes
    const showMore = () => setActive(!active)
    const goTo = () => {
        if (!routeTo) return undefined
        window.open(routeTo, '_blank')
    }
    const style = {
        marginBottom: '1rem',
        padding: '15px 10px 20px 5px',
        cursor: routeTo ? 'pointer' : 'default'
    }
    if (daysBetweenDates(new Date(releaseDate), new Date()) < 7) {
        style['boxShadow'] = `0px 0px 10px -1px ${PrimaryColor}`
    }
    return (
        <Card key={description} style={style} onClick={goTo}>
            <ListItem className={'flex-column'}>
                <div className='flex-row-align-center max-width mb10'>
                    {Icon && !hasMore && <ListItemIcon Icon={Icon} />}
                    {hasMore && !Icon && (
                        <ListItemIcon
                            onClick={showMore}
                            styles={{ cursor: hasMore ? 'pointer' : 'default' }}
                            Icon={active ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        />
                    )}
                    <div>
                        <h3 className='typography no-margin'>{title}</h3>
                        <span className='typography subtitle'>{description}</span>
                    </div>
                </div>
                {hasMore && (
                    <Collapse in={active} style={{ width: '100%' }}>
                        {releaseDate && <span className='typography subtitle'>Released on {releaseDate} </span>}
                        {features && (
                            <div>
                                <span className='typography subtitle'>
                                    This release includes the following new features:
                                </span>
                                <ul>
                                    {features.map((feature) => (
                                        <li className='typography subtitle' key={feature}>
                                            {feature}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        {bugFixes && (
                            <div>
                                <span className='typography subtitle'>
                                    This release includes the following fixes for:
                                </span>
                                <ul>
                                    {bugFixes.map((bug) => (
                                        <li className='typography subtitle' key={bug}>
                                            {bug}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </Collapse>
                )}
            </ListItem>
        </Card>
    )
}

export default ReleaseNoteMemo
/**
 * =================================================================================
 * Note: Deprecated as per above
 * New Release Notes should be created as .md files and provided to ReleaseNotes.tsx
 * =================================================================================
 * Release Notes should be added to releaseNotes with every release
 * New Release Notes should be added to the top of the array as it isn't sorted
 * - ExpandMore or ExpandLess icon with action is automatically added if features or bugFixes contains content
 * - A custom icon can be provided and will be rendered if there isn't any features/bugFixes to render
 * - A routeTo URL can be passed and will be tied to the onClick event of the card
 **/
const releaseNotes = [
    {
        title: 'Page Validation & Instagram Integration Improvements',
        description: 'Enhancements to page route validation and more!',
        releaseDate: '2023.08.17',
        features: [
            'Unique Route Validation: ',
            ' - Editing a drafted page will no longer trigger route validation',
            ' - External Links and Drafts with the same route will no longer trigger validation',
            'Instagram Integration: ',
            ' - Automated renewal of Instagram Access Tokens',
            ' - Application-wide alert banner for Admin level users informing them of token errors',
            " - Automatic validation on save of new Instagram Access Tokens - If it saves, it's valid!",
            'Outlook Calendar - Improved the conversion of Outlook content'
        ]
    },
    {
        title: 'Improvements to Search',
        description: 'New Capabilities for Search on Public Sites ',
        releaseDate: '2023.06.26',
        features: [
            'Ability to search by type(s) (News, Event, Page, Document) ',
            'Ability to search with filters such as Exact match, and Excluding terms'
        ]
    },
    {
        title: 'Updated Navigation Drawer & Editor Capabilities',
        description: 'Improvements to the navigation drawer & editor experience',
        releaseDate: '2023.05.02',
        features: [
            'Ability to remove Cover Image (SEO Image)',
            'Improvements to the Expiry Date picker in News & Events',
            'Updated Navigation Drawer & Appbar: ',
            ' - Navigation to Settings moved to the navigation drawer',
            ' - Navigation to Settings Views (Accounts, Tags, etc) moved to the navigation drawer',
            ' - Ability to have the drawer navigation persistently open or closed'
        ]
    },
    {
        title: 'Page Editor Augmentation & General Update',
        description: 'Wide ranging updates to the application, ensuring a smoother and faster experience',
        releaseDate: '2023.01.16',
        features: [
            'Updated image editor interface',
            'WYSIWYG editor augmentations:',
            ' - Image Linking',
            ' - Image Sizing',
            ' - Image Positioning',
            ' - HTML Embed',
            ' - Horizontal Line',
            'Implement user feedback on creating roles'
        ],
        bugFixes: [
            'Search by multiple tags in Images and Documents',
            'Rename options for users lacking permissions on some Content (View instead of Edit)'
        ]
    },
    {
        title: 'Outlook Integration & Edsby augmentations',
        description: 'New Integration available for Outlook Mailbox & Exchange calendars',
        releaseDate: '2022.12.22',
        features: ['Outlook Integration', 'Ability to add pre-set tags for Edsby integrations']
    },
    {
        title: 'Sorting Improvements in News & Events',
        description: 'New sorting capabilities have been added to the news & events managers',
        releaseDate: '2022.11.16',
        features: [
            'Ability to sort News by Release Date ',
            'Ability to sort Events by Start Date',
            'Ability to sort News and Events by Expiration Date'
        ]
    },
    {
        title: 'New Feature: News and Events Improvements',
        description: 'The News & Events Manager has been augmented with new capabilities',
        releaseDate: '2022.11.03',
        features: [
            'News Manager Improvements',
            'Event Manager Improvements',
            'Privacy Level Filters in Page, Event, and News Managers'
        ]
    },
    {
        title: 'New Feature: Page Manager Improvements',
        description: 'The Page Manager has been augmented with new capabilities',
        releaseDate: '2022.10.17',
        features: [
            'Page Manager Improvements',
            'Restoring archived/delete pages',
            "Uploading documents or creating folders takes into account the folder you're currently in",
            'Improve Importing from CSV capabilities'
        ],
        bugFixes: [
            'Previews of published or drafted content sending users to the wrong domain',
            'Invalid published timestamps on content imported from Edsby'
        ]
    },
    {
        title: 'New Integration: Edsby Learning Management System',
        description: 'News & Events seamlessly integrate from Edsby into Content Manager',
        releaseDate: '2022.10.03',
        features: ['Edsby Integration'],
        bugFixes: [
            'Failing Calendar subscriptions for MacOS and iOS',
            'Large documents failing to load and presenting a white screen',
            'Primary Navigation not properly rendering on certain pages on Public Websites'
        ]
    },
    {
        title: 'New Feature - Content Redirection',
        description: 'URL redirects creatable from the Page Editor.',
        releaseDate: '2022.09.08',
        features: [
            'Url Redirects',
            'Token Matching Redirects',
            'Azure Active Directory Crawler for Dynamic Staff Directories'
        ],
        bugFixes: [
            'Search performance improvements on public sites',
            'Non-PDF documents not being replaceable',
            'Un-sharing a page from a site not removing the navigation on said site',
            'Sorting order in the Events Manager'
        ]
    },
    {
        title: 'New Service: Custom Integrations',
        description: 'Improvements to custom and third party integrations',
        releaseDate: '2022.08.22',
        features: [
            'Improved Integration Service',
            "Custom Integration Service for API's and services hosted by school districts",
            'Add support for indirect-membership in Azure AD groups for Authentication'
        ],
        bugFixes: [
            'Invalid timestamps on content imported from Facebook due to Facebook API update',
            'Staff-only content not appearing in Page, Events, or News Managers'
        ]
    },
    {
        title: 'New Feature - Drafting',
        description: 'Page editor improvements introducing Drafts & Previews to Pages.',
        routeTo: 'https://www.imagineeverything.com/drafting-documentation',
        Icon: <NewReleasesIcon />
    },
    {
        title: 'New Feature - Search Engine Optimization',
        description: 'Improvements for sharing content on Social Media and Search Engines.',
        routeTo: 'https://www.imagineeverything.com/seo-documentation',
        Icon: <NewReleasesIcon />
    }
]

export function DashboardHistoricReleaseNotes({ renderLimit, onClick }) {
    return (
        <>
            {releaseNotes.slice(0, renderLimit).map((note) => (
                <ReleaseNoteMemo key={note.title} {...note} />
            ))}
            <Button
                disabled={renderLimit >= releaseNotes.length}
                size={'large'}
                variant={'outlined'}
                onClick={() => onClick?.()}
            >
                View More
            </Button>
        </>
    )
}
