export const success = (data) => ({
    success: true,
    failure: false,
    data: data,
    error: null
})

export const failure = (error) => ({
    success: false,
    failure: true,
    data: null,
    error: error
})
