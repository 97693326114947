import React, { useEffect, useState } from 'react'
import { tagService } from '../../../pkgs/system/tags/tag.service'
import { Autocomplete, TextField } from '@mui/material'
import { TagType } from '../../../pkgs/system/tags/types'

type TagsSelectorForContentProps = {
    tagType: TagType
    selected: string[]
    onChange?: (selected: string[]) => void
    disabled?: boolean
    hasError?: boolean
}

// should be in content
export const TagsSelectorForContent = ({
    tagType,
    onChange,
    selected,
    disabled,
    hasError
}: TagsSelectorForContentProps) => {
    const [serverTags, setServerTags] = useState<{ id: string; name: string }[]>([])
    const [value, setValue] = useState<{ id: string; name: string }[]>([])
    const [tagsAbort, setTagsAbort] = useState(new AbortController())

    useEffect(() => {
        tagsAbort.abort()
        const newController = new AbortController()
        setTagsAbort(newController)

        const run = async () => {
            const res = await tagService.getAllAlt(newController.signal, { tagType: tagType })
            if (res.failure) {
                return
            }

            const options = res.data.results.map((o) => ({ name: o.name, id: o.id }))
            setServerTags(options)
            setValue(options.filter((t) => selected.includes(t.id)))
        }
        run().catch(console.error)
    }, [])

    function handleChange(e, selected) {
        setValue(selected)
        onChange && onChange(selected.map((s) => s.id))
    }

    return (
        <Autocomplete
            style={{ flex: '1' }}
            onChange={handleChange}
            multiple
            disabled={disabled || false}
            filterSelectedOptions
            options={serverTags}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(o, v) => o.id === v.id}
            value={value}
            renderInput={(params) => (
                <TextField
                    error={hasError}
                    {...params}
                    variant='outlined'
                    label='Tags'
                    placeholder='Tags'
                    helperText='Start typing or select tags from drop down'
                />
            )}
        />
    )
}
