import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { changeNodeAtPath } from 'ndanvers-react-sortable-tree'
import 'ndanvers-react-sortable-tree/style.css'
import './Navigation.css'
import '../../styles/tooltip.css'
import { Get, notify, validateUUID } from '../../helpers'
import {
    Alert,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    FormLabel,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Switch,
    TextField
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import lodash from 'lodash'
import { contentService } from '../content/content.service'
import { navigationHelpers } from './navigation.helpers'
import { Navigation as NavigationModel, NavigationType } from './types'
import { TreeBase } from './sortable-tree/TreeBase'
import { DepartmentNavigationControl, DisconnectedNavigationControl, NavigationSearchControl } from './NavigationNodes'
import { useSearchParams } from 'react-router-dom'
import ExternalLinkNavigationControl from './ExternalLinkControl'
import { useAppContext, useCurrentSite, useCurrentSiteID } from '../auth/atoms'
import { EntityScopeEnum } from '../auth/entityScope'
import { NotesCell } from '../grid/cells/GridCells'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import ClearIcon from '@mui/icons-material/Clear'
import CreateIcon from '@mui/icons-material/Create'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import GroupIcon from '@mui/icons-material/Group'
import { ContentType } from '../content/types'
import { BoxForm } from '@/common/components/BoxForm'
import { MetaEditor } from '../content/editor/components/MetaEditor'
import CollapsibleAlert from "@/common/components/CollapsibleAlert";

const contextMenuID = 'navigationMenu'
export const hasAssignPrimaryPermission = (evaluators, content) => {
    if (content == null) {
        return false
    }
    const canAssignPrimary = evaluators.actionForEntityScope(EntityScopeEnum.Navigation, 'assign_primary')
    const canUpdateContent = evaluators.action(content, 'update')
    return canAssignPrimary && canUpdateContent
}
export const getPrimacyValue = (node) => {
    if (node?.__setPrimacy != null) {
        return node.__setPrimacy
    }
    return Boolean(node?.content?.settings?.isPrimary)
}

export function Navigation({
    onSubmit,
    setNavigationToBeDeleted,
    flat,
    sortableTreeData,
    setSortableTreeData,
    orphanedNavigation,
    setOrphanedNavigation,
    departmentNavigation
}) {
    const [queryParameters] = useSearchParams()
    const classes = useStyles()
    const currentSiteID = useCurrentSiteID()
    const currentSite = useCurrentSite()
    const evaluators = useAppContext()
    const [searchState, setSearchState] = useState({
        query: '',
        index: 0,
        count: null
    })

    // (Navigation V2)
    const [searchQuery, setSearchQuery] = useState(queryParameters.get('searchQuery') || '')
    const [maxDepth, setMaxDepth] = useState(99999)
    const [canSave, setCanSave] = useState(true)

    const [selectedExternalNode, setSelectedExternalNode] = useState(null)
    const [deleteContext, setDeleteContext] = useState(null /* navigation node */)
    const [anchorElement, setAnchorElement] = useState(null)
    const [menuContext, setMenuContext] = useState(null)

    const [hasAnyNavigationPermission, canManagePrimary] = useMemo(
        () => [
            evaluators.entityScopeAny(EntityScopeEnum.Navigation),
            evaluators.actionForEntityScope(EntityScopeEnum.Navigation, 'assign_primary')
        ],
        [evaluators]
    )

    const submit = async () => {
        if (!onSubmit) return

        if (onSubmit.constructor.name === 'AsyncFunction') {
            try {
                setCanSave(false)
                await onSubmit()
            } finally {
                setTimeout(() => {
                    setCanSave(true)
                }, 2000)
            }
        } else {
            onSubmit()
        }
    }

    const removeTreeNode = (path) => {
        return changeNodeAtPath({
            treeData: sortableTreeData,
            path: path ? path : '',
            newNode: null,
            getNodeKey,
            ignoreCollapse: false
        })
    }

    const moveNavigationNodeToRecycling = async (navigationNodeWrapper) => {
        if (!navigationNodeWrapper.node || !navigationNodeWrapper?.path) return
        const { path, node } = navigationNodeWrapper
        const updated = removeTreeNode(path)
        const model = navigationHelpers.toNavigationModel(node)
        setNavigationToBeDeleted((prev) => [...prev, model])
        // endDialog should be called prior to setting SortableTree state - updating treeData before setting
        // anchorElement to null will throw an error, as currentTarget used by renderMenu must appear in the DOM
        endDeleteDialog()
        setSortableTreeData(updated)
    }

    const editExternalLink = async (externalNode) => {
        try {
            if (!externalNode) {
                return notify('Whoops! - An error has occured, please try again', 'error')
            }
            const { ContentId, title, treePath, content, meta } = externalNode
            const { route, sites, settings } = content
            const { data } = await contentService.patchContent(currentSiteID, {
                id: ContentId,
                route,
                title,
                sites,
                settings,
                meta,
                type: ContentType.ExternalLink
            })
            if (!data) {
                return Promise.reject({ message: 'expectation failed - no data returned from ' })
            }
            const updatedNode = { ...externalNode }
            updatedNode.content.route = data.route
            updatedNode.content.title = data.title
            updatedNode.content.settings = data.settings

            setSortableTreeData(
                changeNodeAtPath({
                    treeData: sortableTreeData,
                    path: treePath,
                    newNode: updatedNode,
                    getNodeKey,
                    ignoreCollapse: false
                })
            )
            closeExternalLinkEditDialog()
        } catch (err) {
            console.warn(err)
            const { message = '' } = err || {}
            return Promise.reject({ message })
        }
    }
    // SetPrimacy replaces EditContentPrimacy
    // NavigationLanding takes care of actually sending the request to the API
    // to have the navigation/content updated.
    const setPrimacy = (nodeWrapper) => {
        const { path, node, newNode = node } = nodeWrapper
        newNode['__setPrimacy'] = !getPrimacyValue(newNode)
        const replaced = changeNodeAtPath({
            treeData: sortableTreeData,
            path,
            newNode,
            getNodeKey,
            ignoreCollapse: false
        })
        setSortableTreeData(replaced)
        closeContextMenu()
        notify(
            `Success - ${node.title} will be ${newNode?.__setPrimacy ? 'pinned' : 'unpinned'} when you Save Navigation`,
            'info'
        )
    }
    // const editContentPrimacy = async (confirmState) => {
    //     try {
    //         const { path, node } = confirmState
    //         if (node?.path && node.path.split('.').length > 1) return
    //         const { id, sites, settings } = node?.content || {}
    //         settings['isPrimary'] = !Boolean(settings.isPrimary)
    //         const content = await contentService
    //             .patchContent(currentSiteID, { id, settings, sites, type: ContentType.ExternalLink })
    //             .then((x) => x.data)
    //         if (content) {
    //             const { title, sites, settings } = content
    //             node.content = { ...node.content, title, sites, settings }
    //             const replaced = changeNodeAtPath({
    //                 treeData: sortableTreeData,
    //                 path: path,
    //                 newNode: node,
    //                 getNodeKey,
    //                 ignoreCollapse: false
    //             })
    //             setSortableTreeData(replaced)
    //         }
    //         closeContextMenu()
    //         // closeDeleteNavigationDialog()
    //     } catch (e) {}
    // }

    const editInlineChildren = async (confirmState) => {
        try {
            const { path, node } = confirmState
            const replaced = changeNodeAtPath({
                treeData: sortableTreeData,
                path: path,
                newNode: node,
                getNodeKey,
                ignoreCollapse: false
            })
            setSortableTreeData(replaced)
        } catch (e) {}
    }

    const openExternalLinkEditDialog = (node) => {
        setSelectedExternalNode({ ...node.node, treePath: node.path })
    }

    const closeExternalLinkEditDialog = () => {
        setSelectedExternalNode(null)
        closeContextMenu()
    }

    const endDeleteDialog = () => {
        setDeleteContext(null)
        closeContextMenu()
    }

    const closeContextMenu = () => {
        setAnchorElement(null)
    }

    const navigateToContentFromNode = (baseNode) => {
        if (!baseNode?.node) return
        const { ContentId } = baseNode.node
        window.open(`/content-editor/${ContentId}?siteId=${currentSiteID}`, '_blank')
    }

    const setMenuElement = (event, state) => {
        setMenuContext(lodash.cloneDeep(state))
        setAnchorElement(event.currentTarget)
    }

    const caseInsensitiveSearch = ({ node, searchQuery }) => {
        return (
            searchQuery &&
            (node?.content?.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
                node?.ContentId === searchQuery)
        )
    }

    const getNodeKey = ({ treeIndex }) => treeIndex

    const getMaxDepth = (node) => {
        const isCurrentlyPrimary = Get.isPrimary(node?.draggedNode?.content)
        if (
            (node.draggedNode?.__setPrimacy == null && isCurrentlyPrimary) ||
            (node.draggedNode?.__setPrimacy === true && maxDepth > 1)
        ) {
            setMaxDepth(1)
        } else if (maxDepth === 1) {
            setMaxDepth(9999)
        }
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <div className='container-fluid'>
                <div className='row' style={{ marginTop: '3rem' }}>
                    <div className='col-xs-3'>
                        <NavigationSearchControl
                            className={classes.optionCard}
                            currentSite={currentSite}
                            searchQuery={searchQuery}
                            setSearchQuery={setSearchQuery}
                            searchState={searchState}
                            setSearchState={setSearchState}
                            Reference={flat}
                        />
                        <ExternalLinkNavigationControl className={classes.optionCard} />
                        {departmentNavigation.length > 0 && canManagePrimary && (
                            <DepartmentNavigationControl
                                Departments={departmentNavigation}
                                Reference={flat}
                                className={classes.optionCard}
                            />
                        )}
                        {orphanedNavigation.length > 0 && (
                            <DisconnectedNavigationControl
                                className={classes.optionCard}
                                currentSite={currentSite}
                                nodes={orphanedNavigation}
                                setDeleted={setNavigationToBeDeleted}
                            />
                        )}
                    </div>

                    <div className='col-xs-6'>
                        {!canManagePrimary && (
                            <Alert style={{ width: '90%' }} severity={'info'}>
                                <div className='flex-row' style={{ alignItems: 'flex-start' }}>
                                    <div className='Navigation-PrimaryIconSmall' />
                                    <span>Primary Navigation cannot be re-organized without elevated permissions</span>
                                </div>
                            </Alert>
                        )}

                        <TreeBase
                            treeData={sortableTreeData}
                            setTreeData={setSortableTreeData}
                            disabled={!hasAnyNavigationPermission}
                            canManagePrimary={canManagePrimary}
                            maxDepth={maxDepth}
                            onDragStateChanged={getMaxDepth}
                            searchMethod={caseInsensitiveSearch}
                            searchQuery={searchState.query}
                            searchFocusOffset={searchState.index}
                            searchFinishCallback={(matches) =>
                                setSearchState({
                                    count: matches.length,
                                    index: matches.length > 0 ? searchState.index % matches.length : 0,
                                    query: searchState.query
                                })
                            }
                            buttons={(nodeWrapper) => [
                                <IconButton
                                    aria-controls={contextMenuID}
                                    size='small'
                                    onClick={(e) => setMenuElement(e, nodeWrapper)}
                                >
                                    <MoreHorizIcon />
                                </IconButton>
                            ]}
                        />
                    </div>
                    <div className='col-xs-3'>
                        <div>
                            <div className={classes.helpRow}>
                                <div
                                    className='Navigation-PrimaryIcon'
                                    style={{
                                        height: '35px',
                                        width: '35px',
                                        margin: '2px',
                                        marginRight: '10px',
                                        color: '#f6c94c'
                                    }}
                                />
                                <p>
                                    Navigation with this label will appear on the Primary Navigation bar of your public
                                    websites.
                                </p>
                            </div>
                            <div className={classes.helpRow}>
                                <div
                                    className='Navigation-MissingSiteOverlapIcon'
                                    style={{
                                        height: '35px',
                                        width: '35px',
                                        margin: '2px',
                                        marginRight: '6px',
                                        marginLeft: '4px'
                                    }}
                                />
                                <p>
                                    Navigation with this label is tied to a page that is not shared with{' '}
                                    {currentSite?.Name ? currentSite.Name : 'current site'} - It will only appear in
                                    this interface. It can be explicitly removed if you're sure that the page won't be
                                    shared here once more
                                </p>
                            </div>
                            <div className={classes.helpRow}>
                                <div
                                    className='Navigation-DraftContentIcon'
                                    style={{
                                        height: '35px',
                                        width: '35px',
                                        margin: '2px',
                                        marginRight: '6px',
                                        marginLeft: '4px'
                                    }}
                                />
                                <p>
                                    Navigation with this label is tied to a page that is a draft - It will need to be
                                    published to be seen on Public Website navigation.
                                </p>
                            </div>
                        </div>

                        <div className='m5p'>
                            <Button
                                onClick={submit}
                                disabled={!canSave}
                                color='success'
                                size='medium'
                                variant='contained'
                            >
                                Save Navigation
                            </Button>
                        </div>
                    </div>
                </div>

                <Fragment>
                    <NavigationContextMenu
                        anchorElement={anchorElement}
                        menuContext={menuContext}
                        evaluators={evaluators}
                        onUpdate={
                            menuContext?.node?.type === NavigationType.External
                                ? openExternalLinkEditDialog
                                : navigateToContentFromNode
                        }
                        onAssignPrimary={/*editContentPrimacy*/ setPrimacy}
                        onDelete={setDeleteContext}
                        onClose={() => setAnchorElement(null)}
                        editInlineChildren={editInlineChildren}
                    />
                    <EditActionDialog
                        value={selectedExternalNode}
                        onChange={editExternalLink}
                        onClose={closeExternalLinkEditDialog}
                        evaluators={evaluators}
                        disableAssignPrimary={currentSite?.Type === 'department'}
                    />
                    <DeleteConfirmationDialog
                        value={deleteContext}
                        onChange={moveNavigationNodeToRecycling}
                        close={() => setDeleteContext(null)}
                    />
                </Fragment>
            </div>
        </DndProvider>
    )
}

const EditActionDialog = ({ value, onChange, onClose, evaluators, disableAssignPrimary }) => {
    const [externalNode, setExternalNode] = useState(null)
    const canAssignPrimary = hasAssignPrimaryPermission(evaluators, externalNode)
    const canBeMadePrimary = useMemo(
        () => Get.paths(value).length === 1 && canAssignPrimary && !disableAssignPrimary,
        [externalNode, canAssignPrimary, disableAssignPrimary]
    )

    useEffect(() => setExternalNode(value), [value])
    const handleSave = () => {
        onChange?.(externalNode)
    }
    const handleClose = () => {
        onClose?.()
        setExternalNode(null)
    }

    if (externalNode == null) {
        return null
    }
    const open = Boolean(externalNode)
    const isPrimary = Boolean(externalNode?.content?.settings?.isPrimary)
    const isNavigationGroup = Boolean(externalNode?.content?.settings?.isNavigationGroup)
    return (
        <Dialog maxWidth='sm' open={open} onClose={handleClose}>
            <DialogTitle>Edit Custom Navigation Link</DialogTitle>
            <DialogContent>
                <BoxForm>
                    <TextField
                        label='URL'
                        name='route'
                        fullWidth
                        variant='standard'
                        value={externalNode?.content?.route}
                        disabled={isNavigationGroup}
                        onChange={(e) =>
                            setExternalNode((p) => ({
                                ...p,
                                content: { ...p.content, route: e?.target?.value || '' }
                            }))
                        }
                    />
                    <TextField
                        label='Label'
                        name='title'
                        fullWidth
                        variant='standard'
                        value={externalNode.title}
                        onChange={(e) =>
                            setExternalNode((p) => ({
                                ...p,
                                title: e?.target?.value || ''
                            }))
                        }
                    />
                    <Box>
                        <FormLabel>Meta</FormLabel>
                        <MetaEditor
                            value={externalNode.meta || value?.content?.meta || {}}
                            onChange={(v) => {
                                setExternalNode((p) => ({ ...p, meta: v }))
                            }}
                        />
                    </Box>
                    {canBeMadePrimary && (
                        <Fragment>
                            <DialogContentText style={{ marginBottom: 0, marginTop: '12px' }}>
                                Should this appear on your Primary Navigation bar?
                            </DialogContentText>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isPrimary}
                                        onChange={(e) =>
                                            setExternalNode((p) => {
                                                const copy = { ...p }
                                                if (!copy.content?.settings) {
                                                    copy.content.settings = {}
                                                }
                                                copy.content.settings.isPrimary = !Boolean(
                                                    copy.content.settings.isPrimary
                                                )
                                                return copy
                                            })
                                        }
                                        name='first'
                                    />
                                }
                                label='Primary Navigation'
                            />
                        </Fragment>
                    )}
                    <div>
                        <DialogContentText style={{ marginBottom: 0, marginTop: '12px' }}>
                            Do you want to use this as a Navigation Group?
                        </DialogContentText>
                        <CollapsibleAlert severity={"info"} initialState={false}>
                            <span>
                                A Navigation Group can be used to organize different nodes under a common sub-section.
                                Navigation Groups don't have a Link and won't be appear as a Page that can be navigated to.
                            </span>
                        </CollapsibleAlert>
                        <FormControlLabel control={
                            <Checkbox
                                label='Use as a Navigation Group'
                                checked={isNavigationGroup}
                                onChange={() => setExternalNode((p) => {
                                    const copy = { ...p }
                                    if (!copy.content?.settings) {
                                        copy.content.settings = {}
                                    }
                                    const next = !Boolean(
                                        copy.content.settings.isNavigationGroup
                                    )
                                    copy.content.settings.isNavigationGroup = next
                                    if (next === true) {
                                        copy.content.route = "#"
                                    }
                                    return copy
                                })}
                            />
                        } label={"Use as a Navigation Group"} />

                    </div>
                </BoxForm>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>CANCEL</Button>
                <Button onClick={handleSave}>SAVE</Button>
            </DialogActions>
        </Dialog>
    )
}
const DeleteConfirmationDialog = ({ value, close, onChange }) => {
    if (value == null) {
        return
    }
    const open = Boolean(value)
    const title = value?.node?.title || value?.title || ''
    return (
        <Dialog open={open} keepMounted onClose={close} fullWidth>
            <DialogTitle>Are you sure you want to delete {title}'s navigation?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Changes made to navigation will only take effect after clicking the <b>Save Navigation</b> button in
                    the bottom right-hand corner
                </DialogContentText>
            </DialogContent>
            <DialogActions className='flex-row-between'>
                <Button size='small' onClick={close} color='primary'>
                    Cancel
                </Button>
                <Button size='small' onClick={() => onChange?.(value)} color='primary'>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const NavigationContextMenu = ({
    menuContext,
    anchorElement,
    onClose,
    onUpdate,
    onDelete,
    onAssignPrimary,
    editInlineChildren,
    evaluators
}) => {
    if (!menuContext) {
        return null
    }
    const menuContextNode = menuContext?.node || null
    console.log(menuContext)
    // TODO => Permissions => Need to make it so that isPrimary is applied on save, not on button press, As-is, there is a problem with blocking user from invalid action
    //   1) On Save at top level, my node is navigation.path = 1
    //   if I drag the node to level 2, my navigation.path is still 1, as I haven't saved yet.
    //   using navigation.path, I'd need to hit save and have the nodes be refreshed before I can do my action
    //   2) using treeNode.path, I can have it be saved as n.path = 2, move it to level 1, set it to primary, and not save my navigation
    //   both of these paths leave open the possibility of setting navigation where nlevel != 1 as primary.
    //
    const nlevel = menuContext?.path?.length || 0
    const nModel = new NavigationModel(menuContextNode || {})
    const shareable = {
        sites: nModel.sites,
        departmentId: nModel.contentDepartmentId,
        type: EntityScopeEnum.Navigation
    }
    const [hasAssignPrimary, canUpdateContent, canDeleteContent, canUpdateNavigation] = useMemo(
        () => [
            menuContextNode &&
                evaluators.currentSite().Type !== 'department' &&
                evaluators.actionForEntityScope(EntityScopeEnum.Navigation, 'assign_primary'),
            evaluators.action(shareable, 'update') /* Access to the Content entity itself */,
            evaluators.action(shareable, 'delete') /* Access to the Content entity itself */,
            evaluators.actionForEntityScope(EntityScopeEnum.Navigation, 'update') /* Only */
        ],
        [evaluators, menuContext]
    )

    // canManagePrimary: Since we're adding isPrimary to the content itself, user requires both
    // the ability to Manage Primary, as well as the ability to update navigation.Content
    const canManagePrimary = hasAssignPrimary && canUpdateContent
    const handleDelete = () => {
        onDelete?.(menuContext)
    }
    const handleAssignPrimary = () => {
        onAssignPrimary?.(menuContext)
    }
    const handleUpdate = () => {
        onUpdate?.(menuContext)
    }
    // console.log("NavigationContextMenu", {
    //     shareable,
    //     hasAssignPrimary,
    //     canUpdate: canUpdateNavContent,
    //     canDelete: canDeleteNavigation,
    //     nModel
    // })
    const isCurrentlyPrimary = getPrimacyValue(menuContextNode)
    const isDepartmentNode = validateUUID(menuContextNode?.DepartmentId) && menuContextNode?.type === 'external_link'
    return (
        <Menu
            id={contextMenuID}
            keepMounted
            open={Boolean(anchorElement)}
            onClose={onClose}
            anchorEl={anchorElement}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 10, horizontal: 80 }}
            sx={{
                '& .MuiPaper-root': {
                    overflow: 'visible'
                }
            }}
        >
            {nModel?.content != null && nModel?.content?.sites?.length > 1 && (
                <NotesCell
                    row={nModel?.content}
                    style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '0.75rem' }}
                />
            )}
            <div style={{ minWidth: '10rem' }}>
                {!isDepartmentNode && (
                    <MenuItem onClick={handleUpdate} disabled={!canUpdateContent}>
                        <ListItemIcon style={{ minWidth: '35px' }}>
                            {canUpdateContent ? <CreateIcon fontSize='small' /> : <GroupIcon />}
                        </ListItemIcon>
                        <ListItemText>
                            Edit {menuContext?.node?.type === NavigationType.External ? 'Link' : 'Page'}
                        </ListItemText>
                    </MenuItem>
                )}
                {canManagePrimary && (
                    <MenuItem onClick={handleAssignPrimary} disabled={nlevel !== 1}>
                        <ListItemIcon style={{ minWidth: '35px' }}>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                enableBackground='new 0 0 20 20'
                                height='20px'
                                viewBox='3 0 20 20'
                                width='20px'
                                fill='#0000008a'
                            >
                                <g>
                                    <rect fill='none' height='24' width='24' />
                                </g>
                                <g>
                                    <path d='M14,4v5c0,1.12,0.37,2.16,1,3H9c0.65-0.86,1-1.9,1-3V4H14 M17,2H7C6.45,2,6,2.45,6,3c0,0.55,0.45,1,1,1c0,0,0,0,0,0l1,0v5 c0,1.66-1.34,3-3,3v2h5.97v7l1,1l1-1v-7H19v-2c0,0,0,0,0,0c-1.66,0-3-1.34-3-3V4l1,0c0,0,0,0,0,0c0.55,0,1-0.45,1-1 C18,2.45,17.55,2,17,2L17,2z' />
                                </g>
                            </svg>
                        </ListItemIcon>
                        <ListItemText>
                            {nModel.isPrimary ? 'Detach from Primary Navigation' : 'Attach to Primary Navigation'}
                        </ListItemText>
                    </MenuItem>
                )}
                <MenuItem
                    onClick={handleDelete}
                    disabled={!canDeleteContent || (isCurrentlyPrimary ? !canManagePrimary : !canUpdateNavigation)}
                >
                    <ListItemIcon style={{ minWidth: '35px' }}>
                        <ClearIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Remove from Navigation</ListItemText>
                </MenuItem>
                {isDepartmentNode && (
                    <MenuItem>
                        <ListItemIcon style={{ minWidth: '35px' }}>
                            <Switch
                                checked={!!menuContext?.node['inlineChildren']}
                                onChange={(e, v) => {
                                    menuContext.node['inlineChildren'] = v
                                    editInlineChildren(menuContext)
                                }}
                            />
                        </ListItemIcon>
                        <ListItemText>Only show children</ListItemText>
                    </MenuItem>
                )}
            </div>
        </Menu>
    )
}

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        backgroundColor: '#2196f3'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1
    },
    select: {
        borderColor: 'white',
        color: 'white',
        '&$focus': {
            color: 'white'
        }
    },
    input: {
        color: 'white',
        '&.Mui-focused': {
            color: 'white'
        }
    },
    searchButtonWidth: {
        minWidth: '0px',
        width: '0px'
    },
    underline: {
        color: 'white',
        '&::after': {
            border: '1px solid white'
        },
        '&::before': {
            borderBottom: '1px solid white'
        },
        '&&&&:hover:before': {
            borderBottom: '1.5px solid #ffffffd1'
        }
    },
    container: {
        display: 'grid',
        gridTemplateColumns: '20% 50% 30%',
        gridTemplateRows: '8% 42% 42% 8%',
        minHeight: '80vh'
    },
    nonDialogContainer: {
        height: '4.5vh',
        display: 'grid',
        gridTemplateColumns: '30% 55% 15%',
        gridTemplateRows: '8% 42% 42% 8%',
        minHeight: '90vh'
    },
    explorer: {
        paddingTop: '5%',
        gridColumnStart: 2,
        gridRowStart: 1,
        gridRowEnd: 'span 3'
    },
    thirdColumn: {
        gridColumnStart: 3,
        gridRowStart: 4
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    flexRowEnd: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end'
    },
    flexRowBetween: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    auto: {
        width: '85%'
    },
    optionsCard: {
        gridColumnStart: 1,
        gridRowStart: 2
    },
    optionCard: {
        overflow: 'visible',
        margin: '5%',
        border: '1px solid #707070',
        borderRadius: '5px',
        padding: '0.5rem',
        // padding:"4%",
        marginBottom: '0'
    },
    m5p: {
        margin: '5%'
    },
    helpRow: {
        alignItems: 'center',
        opacity: '0.5',
        display: 'flex',
        flexDirection: 'row'
    }
}))

export default Navigation
