import { httpGet } from '../../../common/client'
import axios from 'axios'
import { useMutation, useQuery } from '@tanstack/react-query'
import { is, notify, validateUUID } from '../../../helpers'
import { sitesEventChanel } from '../../../common/components/selectors/SiteSelectorForAccount'
import { BASE } from '../../../common/constants'
import { SettingsDTO, SettingsType, settingsQuerySchema } from './types'

interface getQuery {
    search: string
    type: string
    adminId?: string
    qSiteId?: string
    active?: boolean
    public?: boolean
}

export const get = async (query: getQuery) => {
    try {
        return await httpGet(`${BASE}/api/v1/settings`, query, settingsQuerySchema)
    } catch (e) {
        console.log(e)
        return Promise.reject()
    }
}
export const post = async (body: SettingsDTO) => {
    try {
        return await axios.post(`${BASE}/api/v1/settings`, body)
    } catch (e) {
        console.error(e)
        return Promise.reject(e)
    }
}
export const put = async (id: string, body: SettingsDTO, params?: any) => {
    try {
        return await axios.put(`${BASE}/api/v1/settings/${id}`, body, params || {})
    } catch (e) {
        console.log(e)
        return Promise.reject(e)
    }
}

export const settingsService = {
    get,
    put,
    post
}

const SETTINGS_PREFIX = ''

const settingsTypes = {
    general: SETTINGS_PREFIX + 'general',
    instagram: SETTINGS_PREFIX + 'instagram',
    outlook: SETTINGS_PREFIX + 'outlook',
    edsby: SETTINGS_PREFIX + 'edsby',
    googleCalendar: SETTINGS_PREFIX + 'googleCalendar',
    facebook: SETTINGS_PREFIX + 'facebook'
}
const instagramBaseSearchQuery = (siteId: string) => ({
    search: `instagram-${siteId}`,
    type: settingsTypes.instagram,
    qSiteId: siteId,
    active: true
})

const instagramBaseDTO = (siteId: string, accessToken: string): SettingsDTO => ({
    Name: `instagram-${siteId}`,
    Data: { instagramAccessToken: accessToken },
    Sites: [siteId],
    Type: settingsTypes.instagram as SettingsType,
    Description: 'instagram',
    Public: false
})

export function useInstagramSettingQueryMutation(siteId: string, onSuccess?: (data) => void, onError?: (e) => void) {
    const {
        data: instagramSetting,
        error,
        refetch,
        isLoading
    } = useQuery({
        queryKey: [settingsTypes.instagram + '-' + siteId],
        queryFn: () =>
            settingsService.get(instagramBaseSearchQuery(siteId)).then(({ Rows }) => (Rows?.length ? Rows[0] : null))
    })
    const mutation = useMutation({
        mutationFn: (accessToken: string) => {
            if (!validateUUID(instagramSetting?.ID)) {
                return settingsService.post(instagramBaseDTO(siteId, accessToken))
            }
            const data = instagramSetting?.Data || {}
            // @ts-ignore
            const updatedSettings: SettingsDTO = {
                ...instagramSetting,
                Data: { ...data, instagramAccessToken: accessToken }
            }
            // @ts-ignore
            return settingsService.put(instagramSetting.ID, updatedSettings)
        },
        onSuccess: (data) => {
            console.log(data)
            if (onSuccess) {
                onSuccess(data)
            } else {
                notify('Your Instagram Token has been renewed and successfully updated!', 'info')
                refetch()
            }
            sitesEventChanel.emit('onSiteSecureSettingsUpdated')
        },
        onError: (err) => {
            if (onError) {
                onError(err)
            } else {
                notify('Whoops! Error updating site settings, please try again later')
            }
        }
    })
    return { instagramSetting, isLoading, error, refetch, mutation }
}
