import { useEffect, useState } from 'react'
import { DISTRICT_WIDE_VALUE, SingleSiteSelect, SingleSiteSelectProps } from './SingleSiteSelect'
import { Box, FormControlLabel, Switch } from '@mui/material'

interface SingleSiteSelectWithSwitchProps extends SingleSiteSelectProps {
    switchDefaultEnabled?: boolean
}

// Switch for "District Wide"
function SingleSiteSelectWithSwitch({
    switchDefaultEnabled = false,
    ...singleSiteSelectProps
}: SingleSiteSelectWithSwitchProps) {
    const [switchIsEnabled, setSwitchIsEnabled] = useState(switchDefaultEnabled)

    useEffect(() => {
        setSwitchIsEnabled(switchDefaultEnabled)
    }, [switchDefaultEnabled])

    return (
        <Box>
            <SingleSiteSelect
                {...{
                    ...singleSiteSelectProps,
                    disabled:
                        singleSiteSelectProps.disabled !== undefined && singleSiteSelectProps.disabled
                            ? true
                            : switchIsEnabled
                }}
            />
            <FormControlLabel
                disabled={singleSiteSelectProps.disabled}
                label={'District Wide'}
                control={
                    <Switch
                        disabled={singleSiteSelectProps.disabled}
                        checked={switchIsEnabled}
                        onChange={(e) => {
                            setSwitchIsEnabled(e.target.checked)
                            if (e.target.checked) {
                                singleSiteSelectProps?.onChange(DISTRICT_WIDE_VALUE)
                            } else {
                                singleSiteSelectProps?.onChange('')
                            }
                        }}
                    />
                }
            />
        </Box>
    )
}

export default SingleSiteSelectWithSwitch
