import { Deferred, deferred } from './deferred'
/**
 * Batcher.
 * A batch manager that will batch requests for a certain data type within a given window.
 */
export type Batcher<T, Q, R = T> = {
    /**
     * Schedule a get request for a query.
     */
    fetch: (query: Q) => Promise<R>
}

/**
 * Config needed to create a Batcher
 */
export type BatcherConfig<T, Q, R> = {
    /**
     * The function that makes the batched request for the current batch queries
     *
     * @param queries Q[]
     * @returns Promise<T
     */
    fetcher: (queries: Q[]) => Promise<T>
    /**
     * The scheduling function.
     */
    scheduler?: BatcherScheduler
    /**
     * Correlate an item by its query. Used to extract the correct value from the batch of items
     * to the correct query used to fetch it.
     */
    resolver: (items: T, query: Q) => R
    /**
     * Display name of the batcher. Used for debugging and devtools.
     */
    name?: string
}

/**
 * A function to schedule batch execution timing
 */
export type BatcherScheduler = {
    /**
     * A scheduler function.
     */
    (start: number, latest: number, batchSize: number): Schedule
}

/**
 * A schedule for when to execute a batched fetch call.
 */
export type Schedule = number | 'immediate' | 'never'

export type BatcherMemory<T, Q> = {
    seq: number
    batch: Set<Q>
    currentRequest: Deferred<T>
    timer?: NodeJS.Timeout | undefined
    start?: number | null
    latest?: number | null
}

/**
 * Create a batch manager for a given collection of a data type.
 * Will batch all .get calls given inside a scheduled time window into a singel request.
 */
export const createBatcher = <T, Q, R = T>(
    config: BatcherConfig<T, Q, R>,
    memory?: BatcherMemory<T, Q>
): Batcher<T, Q, ReturnType<(typeof config)['resolver']>> => {
    const name = config.name ?? `batcher:${Math.random().toString(16).slice(2)})`

    const scheduler: BatcherScheduler = config.scheduler ?? windowScheduler(10)

    let mem: BatcherMemory<T, Q> = memory ?? {
        seq: 0,
        batch: new Set<Q>(),
        currentRequest: deferred<T>(),
        timer: undefined,
        start: null,
        latest: null
    }

    const nextBatch = () => {
        mem.batch = new Set()
        mem.currentRequest = deferred<T>()
        mem.timer = undefined
        mem.start = null
        mem.latest = null
    }

    const fetch = async (query: Q): Promise<R> => {
        if (!mem.start) mem.start = Date.now()
        mem.latest = Date.now()

        mem.batch.add(query)
        clearTimeout(mem.timer)

        const scheduled = scheduler(mem.start, mem.latest, mem.batch.size)

        const fetchBatch = () => {
            const req = config.fetcher([...Array.from(mem.batch)])
            const currentRequest = mem.currentRequest

            nextBatch()

            req.then((data) => {
                currentRequest.resolve(data)
            }).catch((error) => {
                currentRequest.reject(error)
            })

            mem.seq++

            return req
        }

        if (scheduled === 'immediate') {
            const req = mem.currentRequest
            await fetchBatch()
            let items = await req.value
            return config.resolver(items, query)
        } else if (scheduled === 'never') {
            let items1 = await mem.currentRequest.value
            return config.resolver(items1, query)
        } else {
            mem.timer = setTimeout(fetchBatch, scheduled)
            let items2 = await mem.currentRequest.value
            return config.resolver(items2, query)
        }
    }

    return { fetch }
}

/**
 * Resolve by item field of items when response is an array.
 * Create a euquality check to check if the query matches a given key on the item data.
 */
export const keyResolver =
    <T extends Array<any>, Q, R = T extends Array<infer A> ? A : never>(
        key: T extends Array<infer A> ? keyof A : never
    ) =>
    (items: T, query: Q): R =>
        items.find((item) => item[key] == query)

/**
 * Resolve by record index when response is an object.
 * Create a euquality check to check if the query matches a given key on the item data.
 */
export const indexedResolver =
    <T extends Record<any, any>, Q>() =>
    (itemsIndex: T, query: Q) =>
        itemsIndex[query]

/**
 * Give a window in ms where all queued fetched made within the window will be batched into
 * one singler batch fetch call.
 */
export const windowScheduler: (ms: number) => BatcherScheduler = (ms) => (start, latest) => {
    const spent = latest - start
    return ms - spent
}

/**
 * Give a buffer time in ms. Will give another buffer window when queueing a fetch.
 */
export const bufferScheduler: (ms: number) => BatcherScheduler = (ms) => () => {
    return ms
}

/**
 * Same as windowScheduler, will batch calls made within a window of time OR when the max batch size is reached.
 *
 * @param config: {windowMs: number; maxBatchSize: number;}
 * @returns BatcherScheduler
 */
export const windowedFiniteBatchScheduler: (config: { windowMs: number; maxBatchSize: number }) => BatcherScheduler =
    ({ windowMs, maxBatchSize }) =>
    (start, latest, batchSize) => {
        if (batchSize >= maxBatchSize) return 'immediate'
        const spent = latest - start
        return windowMs - spent
    }

/**
 * Will batch calls when the max batch size is reached.
 */
export const maxBatchSizeScheduler: (config: { maxBatchSize: number }) => BatcherScheduler =
    ({ maxBatchSize }) =>
    (_start, _latest, batchSize) => {
        if (batchSize >= maxBatchSize) return 'immediate'
        return 'never'
    }
