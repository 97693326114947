import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from '@mui/material'
import Alert from '@mui/material/Alert'
import React from 'react'
import {LightTooltip} from "@/common/components/LightTooltip";

type PrivacyLevelProps = {
    value: number
    onChange: (v: number) => void
    isDisabled: boolean
}

export function PrivacyLevel({ value, onChange, isDisabled }: PrivacyLevelProps) {
    return (
        <FormControl sx={{ width: '100%', flexDirection: 'row', alignItems: 'center', gap: '8px', flexWrap: 'wrap' }}>
            <FormLabel id='privacy-level'>Privacy Level: </FormLabel>
            <RadioGroup
                row={true}
                aria-labelledby='privacy-level'
                name='privacy-level'
                value={value || 0}
                onChange={(e) => onChange(Number(e.target.value) as 0 | 2)}
            >
                <LightTooltip title={'The content will be available for all site visitors.'}>
                    <FormControlLabel value={0} control={<Radio />} label='Public' disabled={isDisabled} />
                </LightTooltip>

                <LightTooltip title={'The content will be hidden and available only for signed-in staff members.'}>
                    <FormControlLabel value={2} control={<Radio />} label='Staff' disabled={isDisabled} />
                </LightTooltip>
            </RadioGroup>
            {value === 2 && (
                <Alert sx={{ flexBasis: '100%' }} severity={'warning'}>
                    This content will be hidden and available only for signed-in staff members.
                </Alert>
            )}
        </FormControl>
    )
}

