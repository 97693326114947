import React, { useContext, useEffect, useMemo, useState } from 'react'
import './Dashboard.css'
import { disabledContext } from '../../common/DisabledContext'
import { Avatar, Box, Card, ListItem, ListItemAvatar, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { DashboardAnnouncement } from './Announcement'
import ReleaseNotes, { useReleaseNotesIsRead } from './ReleaseNotes'
import OldReleaseNotes from './OldReleaseNotes'
import { colours } from '../../common/colours'
import { DashboardHistoricReleaseNotes } from './ReleaseNote'

export function Dashboard() {
    const classes = useStyles()
    const [, setDisabled] = useContext(disabledContext)
    const [notesLimit, setNotesLimit] = useState(0)
    const { isRead } = useReleaseNotesIsRead()
    const loadedAsUnread = useMemo(() => isRead, [])

    useEffect(() => {
        setDisabled(false)
    }, [])

    return (
        <div className='container-fluid dashboard-wrapper'>
            <div className='row'>
                <div className='col-xs-12 col-md-12 col-lg-5 first-lg'>
                    <Card
                        className={classes.root}
                        style={loadedAsUnread ? {} : { boxShadow: '0px 0px 1.5px 1.5px #ffae00c7' }}
                    >
                        <Box sx={{ paddingX: '14px' }}>
                            <ReleaseNotes />
                        </Box>
                    </Card>
                    {/*Old markdown release notes*/}
                    <OldReleaseNotes />
                    {/*Deprecated / Historic JSON release notes format */}
                    {/* <DashboardHistoricReleaseNotes
                        renderLimit={notesLimit}
                        onClick={() => setNotesLimit(notesLimit + 5)}
                    /> */}
                </div>

                {/*TODO => Refactor this temporary addition if its going to be long term*/}
                {/* identity is required here */}

                <DashboardAnnouncement
                    additionalContent={
                        <Card className={classes.root} sx={{ backgroundColor: colours.weird_blue, color: 'white' }}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar style={{ backgroundColor: colours.light_blue }}>
                                        <HelpOutlineIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <div>
                                    <Typography variant='h6'>Questions?</Typography>
                                    <Typography>
                                        <a
                                            target={'_blank'}
                                            href={'https://cdn.imagineeverything.com/content-manager-documentation'}
                                            style={{ color: colours.orange, textDecoration: 'none' }}
                                        >
                                            {' '}
                                            Explore our documentation{' '}
                                        </a>
                                        or
                                        <a
                                            target={'_blank'}
                                            href={'mailto:support@imagineeverything.com'}
                                            style={{ color: colours.orange, textDecoration: 'none' }}
                                        >
                                            {' '}
                                            reach out to us if you have any further questions{' '}
                                        </a>
                                    </Typography>
                                </div>
                            </ListItem>
                        </Card>
                    }
                />
            </div>
        </div>
    )
}

export default Dashboard
const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: '1rem',
        padding: '15px 10px 20px 5px'
    }
}))
