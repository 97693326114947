import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { z } from 'zod'
import { httpPost, httpPut, httpDelete, httpGet } from '../../../common/client'
import { TagAPIV2, TagAPI } from '../../../common/constants'
import { trackable, paged, PagingQuery, SortingQuery, baseQueryConfig } from '../../../common/react-query'
import { success, failure } from '../../../helpers/result'
import { useCurrentSiteID } from '../../auth/atoms'
import { TagType } from './types'

export const tagDto = z.object({
    Name: z.string(),
    Type: z.string()
})

export const tag = trackable.extend({
    ID: z.string(),
    Name: z.string(),
    Type: z.string(),
    Active: z.boolean()
})

export const tags = paged.extend({
    Rows: z.array(tag)
})

export type Structure = z.infer<typeof tag>
export type TagDTO = z.infer<typeof tagDto>

export function createTagQuery(newTag: Partial<TagDTO>) {
    return httpPost(TagAPIV2, newTag, z.string())
}

export function updateTagQuery({ id, tag }: { id: string; tag: Partial<TagDTO> }) {
    return httpPut(`${TagAPIV2}/${id}`, tag, z.string())
}

export function deleteTagQuery(id: string) {
    return httpDelete(`${TagAPIV2}/${id}`)
}

export interface TagsQueryParamsV2 extends PagingQuery, SortingQuery {
    Search: string
    Type?: TagType[]
}

export function useTagsQueryV2(q: TagsQueryParamsV2) {
    return useQuery({
        ...baseQueryConfig,
        queryKey: ['tags-query-v2', q],
        queryFn: async () => httpGet(TagAPIV2, q, z.any())
    })
}

export interface TagsQueryParams extends PagingQuery, SortingQuery {
    type?: string[]
    limit: number
}

export function useTagsQuery(q: TagsQueryParams) {
    const currentSite = useCurrentSiteID()
    q['siteId'] = currentSite || null

    const query = useQuery({
        ...baseQueryConfig,
        queryKey: ['tags-query', q],
        queryFn: async () => httpGet(TagAPI, q, z.any())
    })

    return query
}

/**
 * @param {string | undefined}            selectedSite - UUID of the SelectedSite, can be obtained via selectedSiteContext
 * @param {string[]}            type   - the types of tag
 * @param {string}            page
 * @param {string}            limit
 * @param {Object}            params - A params object which will overwrite page / limit
 * @return {Promise<any>}
 * */
const getAll = async (selectedSite, type, page, limit, params) => {
    let p = {
        tagType: type ? type : [],
        page: page ? page : '0',
        limit: limit ? limit : '9999'
    }
    if (selectedSite?.length) {
        p['siteId'] = selectedSite
    }
    if (!params || (params && !Object.keys(params).length)) {
        params = p
    }
    const response = await axios.get(TagAPI, {
        params
    })
    return response.data
}

const getAllAlt = async (signal, { limit = 9999, page = 0, tagType }) => {
    try {
        const res = await axios.get(TagAPI, {
            signal: signal,
            params: {
                limit,
                page,
                tagType
            }
        })
        return success(res.data)
    } catch (e) {
        return failure(e)
    }
}

const update = async (selectedSite, model) => {
    if (!selectedSite || !Object.keys(model).length) return Promise.reject('bad request')
    return await axios.put(TagAPI, model, { params: { siteId: selectedSite } })
}

const create = async (selectedSite, model) => {
    if (!selectedSite || !Object.keys(model).length) return Promise.reject('bad request')
    return await axios.post(TagAPI, model, { params: { siteId: selectedSite } })
}

const deleteModel = async (selectedSite, model) => {
    if (!selectedSite || !Object.keys(model).length) return Promise.reject('bad request')
    return await axios.delete(TagAPI, { data: model })
}

export const tagService = {
    getAll,
    update,
    create,
    getAllAlt,
    delete: deleteModel
}
