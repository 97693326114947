import React, { useState } from 'react'

const documentContext = React.createContext([{}, (r) => {}])

const DocumentContextProvider = (props) => {
    const [documents, setDocuments] = useState([])
    return <documentContext.Provider value={[documents, setDocuments]}>{props.children}</documentContext.Provider>
}

export { documentContext, DocumentContextProvider }
