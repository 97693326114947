import React, { useEffect, useState } from 'react'
import { GridColDef } from '@mui/x-data-grid'
import { httpGet } from '../../../common/client'
import { notify } from '../../../helpers'
import { auditRecords, AuditRecords, AuditRecordsQuery } from './types'
import {
    Checkbox,
    FormControl,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent
} from '@mui/material'
import { useStateWithStorage } from '../../../common/storage.service'
import PageContainerWithHeader from '../../../common/components/PageContainerWithHeader'
import SearchBar from '../../../common/components/SearchBar'
import { IDToNameCell } from '../../grid/cells/GridCells'
import { AuditRecordsAPI } from '../../../common/constants'
import { DataGridBase } from '../../grid/DataGridBase'
import { defaultPageQuery } from '../../../common/react-query'
import { WhatsNew } from '../../../common/components/WhatsNew'

export const AuditRecordsGrid = () => {
    const [query, setQuery] = useStateWithStorage<AuditRecordsQuery>('audit-recordsGrid-query', { ...defaultPageQuery })
    const [gridState, setGridState] = useState<AuditRecords>({
        TotalRecords: 0,
        TotalPages: 0,
        Offset: 0,
        PageSize: 10,
        Page: 1,
        Rows: []
    })

    const columns: GridColDef[] = [
        { field: 'CreatedAt', headerName: 'CreatedAt', width: 200, sortable: true },
        { field: 'Event', headerName: 'Event', width: 200, sortable: false },
        { field: 'Message', headerName: 'Message', flex: 3, sortable: false },
        {
            field: 'IssueID',
            headerName: 'IssueID',
            width: 250,
            sortable: false,
            renderCell: (params) => <IDToNameCell tableName={'issue'} ID={params.row.IssueID} />
        },
        {
            field: 'TopicID',
            headerName: 'TopicID',
            width: 140,
            sortable: false,
            renderCell: (params) => <IDToNameCell tableName={'topic'} ID={params.row.TopicID} />
        },
        {
            field: 'SubscriberID',
            headerName: 'SubscriberID',
            width: 140,
            sortable: false,
            renderCell: (params) => <IDToNameCell tableName={'subscriber'} ID={params.row.SubscriberID} />
        }
        // {field: 'ID', headerName: 'ID', width: 200, sortable: true},
    ]
    useEffect(() => {
        httpGet(`${AuditRecordsAPI}`, query, auditRecords)
            .then((res) => {
                setGridState(res)
            })
            .catch((err) => {
                console.error(err)
                notify(err)
            })
    }, [query])

    const [selected, setSelected] = useState<string[]>(query.Events || [])
    useEffect(() => {
        setQuery({ ...query, Events: selected })
    }, [selected])


    return (
        <PageContainerWithHeader title='Audit Records' titleSlot={<WhatsNew
            link={"https://cdn.cmdesign.imagineeverything.com/notifications-1718912661937?hash=doL25vdGlmaWNhdGlvbnMtMTcxODkxMjY2MTkzNw=="} />}>
            <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                    <SearchBar
                        value={query.Search || ''}
                        onChange={(val) => setQuery((prev) => ({ ...prev, Search: val }))}
                    />
                </Grid>
                <Grid item md={3} xs={12}>
                    <MultipleSelectCheckmarks
                        label={'Events'}
                        options={[
                            'cant_send_issue_to_subscriber',
                            'send_issue_started',
                            'send_issue_stopped',
                            'send_issue_stopped_error'
                        ]}
                        selected={selected}
                        setSelected={setSelected}
                    />
                </Grid>
                {/*@ts-ignore*/}
                <Grid item md={12}>
                    <DataGridBase
                        columns={columns}
                        state={gridState}
                        setQuery={setQuery}
                        getRowClassName={(params) => params.row.Event}
                    />
                </Grid>
            </Grid>
        </PageContainerWithHeader>
    )
}

export const MultipleSelectCheckmarks = ({
                                             label,
                                             options,
                                             selected,
                                             setSelected
                                         }: {
    label: string
    options: string[]
    selected: string[]
    setSelected: React.Dispatch<React.SetStateAction<string[]>>
}) => {
    const handleChange = (event: SelectChangeEvent<typeof selected>) => {
        const {
            target: { value }
        } = event
        setSelected(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        )
    }

    return (
        <FormControl sx={{ width: '100%' }}>
            <InputLabel id='demo-multiple-checkbox-label'>{label}</InputLabel>
            <Select
                labelId='demo-multiple-checkbox-label'
                id='demo-multiple-checkbox'
                multiple
                value={selected}
                onChange={handleChange}
                input={<OutlinedInput label={label} />}
                renderValue={(selected) => selected.join(',')}
            >
                {options.map((name) => (
                    <MenuItem key={name} value={name}>
                        <Checkbox checked={selected.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
