import { notify, validateUUID } from './index'
import { edsbyService } from '../pkgs/system/site/integration/edsby.service'
import { integrationService, Outlook } from '../pkgs/system/site/integration/integration.service'

const isPrimary = (content) => Boolean(content?.settings?.isPrimary)
const isPublished = (content) => Boolean(content?.publish_at)
const isDistrict = (content) => Boolean(content?.settings?.isDistrictPage)
const isDepartment = (content) =>
    Boolean(
        validateUUID(content?.departmentId) ||
            validateUUID(content?.departmentId?.UUID) ||
            validateUUID(content?.department?.id)
    )
const isElapsed = (content, field) => {
    if (!content?.settings?.[field]) return false
    const dt = Date.now()
    return dt > Date.parse(content.settings[field])
}
const allowsNavigation = (content) => Boolean(content?.settings?.navigation)
const startDate = (content) => content?.settings?.startdate || ''
const endDate = (content) => content?.settings?.enddate || ''
const priority = (content) => content?.settings?.priority
const paths = (content) => {
    if (typeof content?.path === 'string') return content.path.split('.')
    else return []
}
const parentPath = (content) => {
    if (!content?.path) return ''
    const s = paths(content)
    return s.slice(0, s.length - 1).join('.')
}
const childPermissions = (content) => {
    if (!content?.children || !content.children.length) return false
    if (content.children.some((x) => x.hasPermission)) {
        return true
    }
    return false
}
const contentId = (location) => {
    let id = ''
    if (location?.state?.id) {
        id = location.state.id
    } else if (location?.search) {
        const stringSearch = 'contentId='
        let index = location.search.indexOf(stringSearch)
        if (index > -1) {
            index = index + stringSearch.length
            const ss = location.search.substring(index, index + 36)
            if (validateUUID(ss)) {
                id = ss
            }
        }
    }
    return id
}
const queryParam = (name) => {
    return new URLSearchParams(window?.location?.search).get(name) || ''
}
// Returns all records from Left that are not in right(notInArray)
const leftDifference = (left, notInArray, objectProperty) => {
    if (objectProperty) {
        return leftDifferenceObjectArray(left, notInArray, objectProperty)
        // revisit
        // return left.filter(x => !notInArray.some(y => y[objectProperty] !== x[objectProperty]))
    }
    return left.filter((x) => !notInArray.includes(x))
}
// directly references objects for possible use in MUI selects etc
const leftDifferenceObjectArray = (left, right, key) => {
    const leftDiff = []
    const rightValues = right.reduce((accumulator, value) => {
        accumulator[value?.[key] || ''] = true
        return accumulator
    }, {})
    for (let i = 0; i < left.length; i++) {
        const kv = left?.[i]?.[key]
        if (!kv) continue
        if (!Boolean(rightValues[kv])) {
            leftDiff.push(left?.[i])
        }
    }
    return leftDiff
}
const intersection = (array1, array2, objectProperty) => {
    if (objectProperty) {
        return array1.filter((x) => array2.some((y) => y[objectProperty] === x[objectProperty]))
    }
    return array1.filter((x) => array2.includes(x))
}
const difference = (array1, array2, optionalProperty) => {
    const notFoundArray1Values = leftDifference(array1, array2, optionalProperty)
    const notFoundArray2Values = leftDifference(array2, array1, optionalProperty)
    return notFoundArray1Values.concat(notFoundArray2Values)
}
// const uniqueMapByKey    = (array, key) => Array.from(new Set(array.map( x => x[key] )))
const uniqueMapByKey = (array, key) => Array.from(new Set(array.map((x) => (key ? x[key] : x))))

const importInfo = (content, site, sites) => {
    const source = content?.settings?.importInfo?.source || ''

    switch (source) {
        case 'edsby':
            return edsbyInfo(content, site, sites)
        case 'outlook':
            return outlookInfo(content, site, sites)
        case 'googleCalendar':
        case 'facebook':
        default:
            return {}
    }
}

function migrationInfo(content) {
    return content?.settings?.migrationInfo || content?.Settings?.migrationInfo
}

const outlookInfo = (content, site, sites) => {
    try {
        const { calendar: calendarId, source } = content?.settings?.importInfo || {}
        const configurations = integrationService.unmarshalArray(site?.settings?.[Outlook])
        let foundConfig = configurations.find((c) => c.id === calendarId)
        if (!foundConfig) {
            findingConfig: for (const { settings: { outlook = [] } = {} } of sites) {
                for (const config of outlook) {
                    if (calendarId === config.id) {
                        foundConfig = config
                        break findingConfig
                    }
                }
            }
        }

        const config = new integrationService.Config(foundConfig)
        const fields = content?.type === 'news' ? config.news.fields : config.events.fields
        const importInfo = {
            imported: Boolean(content?.settings?.imported),
            source: String(source),
            allowedFields: {}
        }
        for (const [k, v] of Object.entries(fields)) {
            const ok = Boolean(v?.insertOnly)
            if (ok) importInfo.allowedFields[k] = ok
        }
        // Temporary: Refactoring back-end / site settings import configs to utilize publish_at / expire_at is outside the scope of this task.
        // this addition is to aid in future proofing the client side logic, contentEditor.tsx should use the new keywords so it won't have to be refactored in the future.
        if (importInfo.allowedFields.published === true) {
            importInfo.allowedFields['publish_at'] = true
            importInfo.allowedFields['publishAt'] = true
            importInfo.allowedFields['expire_at'] = true
            importInfo.allowedFields['expireAt'] = true
        }
        return importInfo
    } catch (e) {
        return {}
    }
}
const edsbyInfo = (content, site, sites) => {
    try {
        const schoolIds = content?.settings?.importInfo.schoolIds
        const configurations = edsbyService.unmarshal(site)
        // TODO => support of schoolId and id is temporary and should be dealt with at the DB level.
        //  New/saved configurations will be set as "id", whereas old, historic / un-updated configs will have schoolId
        // 2024-05-07: ids added to go import code
        let foundConfig = configurations.find((c) => schoolIds?.includes(c.schoolId) || schoolIds?.includes(c.id))
        if (!foundConfig) {
            findingConfig: for (const { settings: { edsby = [] } = {} } of sites) {
                for (const config of edsby) {
                    if (schoolIds.includes(config?.schoolId)) {
                        foundConfig = config
                        break findingConfig
                    }
                }
            }
        }
        const config = new edsbyService.Config(foundConfig)
        const fields = content?.type === 'news' ? config.news.fields : config.events.fields
        const importInfo = {
            imported: Boolean(content?.settings?.imported),
            source: 'edsby',
            allowedFields: {}
        }
        for (const [k, v] of Object.entries(fields)) {
            const ok = Boolean(v?.insertOnly)
            if (ok) importInfo.allowedFields[k] = ok
        }
        // Temporary: Refactoring back-end / site settings import configs to utilize publish_at / expire_at is outside the scope of this task.
        // this addition is to aid in future proofing the client side logic, contentEditor.tsx should use the new keywords so it won't have to be refactored in the future.
        if (importInfo.allowedFields?.published === true) {
            importInfo.allowedFields['publish_at'] = true
            importInfo.allowedFields['publishAt'] = true
            importInfo.allowedFields['expire_at'] = true
            importInfo.allowedFields['expireAt'] = true
        }
        return importInfo
    } catch (e) {
        return {}
    }
}

export const Get = {
    isPrimary,
    isPublished,
    isDistrict,
    isDepartment,
    isElapsed,
    allowsNavigation,
    startDate,
    endDate,
    priority,
    paths,
    parentPath,
    childPermissions,
    contentId,
    difference,
    intersection,
    leftDifference,
    uniqueMapByKey,
    importInfo,
    migrationInfo,
    queryParam
}
