import { useContentQueries } from '../queries'
import { useParams } from 'react-router-dom'
import React, { useState } from 'react'
import PageContainerWithHeader from '../../../common/components/PageContainerWithHeader'
import { BaseTemplate, TemplateSelector } from './components/TemplateSelector'
import FormRenderer from '../../form-renderer/FormRenderer'
import { StructureSelector } from '../../structure/StructureSelector'
import { FormStructure } from '../../structure/types'
import { asSecured } from '../../auth/permissions/securityMapping'
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    Typography
} from '@mui/material'
import TextField from '@mui/material/TextField'
import { RouteEditor } from './components/RouteEditor'
import { BackButton, HelpTooltip } from '../../../common/components'
import { SeoOptions } from '../../../common/components/SeoOptions'
import AppAccordion from '../../../common/components/AppAccordion'
import { GoToNavigation } from '../../navigation/GoToNavigation'
import { useAppContext, useCurrentSite } from '../../auth/atoms'
import { LegacyUrls } from './components/LegacyUrls'
import { DistributedPageEditor, DistributedPageEditorRef } from '../distributed-page/DistributedPageEditor'
import { TagsSelectorForContent } from '../../../common/components/selectors/TagSelectorForContent'
import { EntityScopeEnum } from '../../auth/entityScope'
import { EventDateTimePickerV2 } from '../../../common/editor/EventDateTimePickerV2'
import { MetaEditor } from './components/MetaEditor'
import { ContentEditorSaveCard } from './ContentEditorSaveCard'
import { useStructuredContentValidators } from './useStructuredContentValidators'
import { Content, ContentType, PublishPeriod } from '../types'
import { useDisable } from '../../../common/useDisable'
import { guessErrorMessage } from '../../../helpers/guessErrorMessage'
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import { useInputDisabler } from './imported-content/useInputDisabler'
import { EditableFieldsList } from './imported-content/EditableFieldsList'
import { BaseForm } from '../BaseForm'
import { TagType } from '../../system/tags/types'
import { AlertNotification } from './components/AlertNotification'
import { PinNews } from '@/pkgs/content/editor/components/PinNews'
import { RevisionHistoryList } from '@/pkgs/content/RevisionHistoryList'

function getPathToContentType(content: Content | any) {
    const contentType = content?.type || content?.Type

    if (contentType == ContentType.Page) {
        return '/pages'
    } else if (contentType == ContentType.News) {
        return '/news'
    } else if (contentType == ContentType.Event) {
        return '/events'
    }

    return ''
}

export const defaultSEOAccordionId = 'defaultSEOAccordion'
export const defaultMetaAccordionId = 'defaultMetaAccordion'

function getDefaultAccordionState(expanded = true) {
    return {
        [defaultSEOAccordionId]: expanded,
        [defaultMetaAccordionId]: expanded === undefined ? false : expanded
    }
}

export const ContentEditor = () => {
    const { id } = useParams()
    const currentSite = useCurrentSite()
    const [structure, setStructure] = useState<FormStructure[] | undefined>(undefined)
    const [selectedTemplate, setSelectedTemplate] = useState<BaseTemplate | undefined>(undefined)
    const [distributedPageHasChanges, setDistributedPageHasChanges] = useState(false)

    const formRendererRef = React.useRef<any>()
    const [accordionExpanded, setAccordionExpanded] = useState(getDefaultAccordionState(true))

    function setAllAccordions(expanded) {
        formRendererRef?.current?.setAllAccordions(expanded)
        setAccordionExpanded(getDefaultAccordionState(expanded))
    }

    const dpRef = React.useRef<DistributedPageEditorRef>(null)

    const [state, setState] = React.useState<Content | undefined>(undefined)

    const { validateAll, errors, setErrors } = useStructuredContentValidators(
        ['Sites', 'Route', 'Title', 'Path', 'StructureID', 'Settings', 'PublishAt', 'ExpireAt'],
        state,
        formRendererRef
    )

    const [notificationErrors, setNotificationErrors] = useState<Partial<Record<string, string>>>({})
    const validateNotification = () => {
        const validationErrors: Partial<Record<string, string>> = {}
        if (state?.Settings?.HasEmailNotification) {
            if (!state?.Settings?.EmailSubject) {
                validationErrors.EmailSubject = 'Email Subject is required'
            }
            if (!state?.Settings?.EmailBody) {
                validationErrors.EmailBody = 'Email Body is required'
            }
        }
        setNotificationErrors(validationErrors)
        return Object.keys(validationErrors).length === 0
    }

    const { fetcher, updater } = useContentQueries(id || '')

    const classifications = React.useMemo(
        () => (fetcher.data?.Type === 'page' ? ['page', 'template'] : [fetcher.data?.Type || 'page']),
        [fetcher.data]
    )

    useDisable()

    const evaluators = useAppContext()
    const hasPermission = React.useMemo(() => evaluators.action(state, 'update'), [state])
    const hasDistributedPagePermission = React.useMemo(
        () =>
            evaluators.action(
                { EntityScope: EntityScopeEnum.Page, Sites: [currentSite?.ID], DepartmentID: null },
                'update'
            ),
        [currentSite]
    )

    const { isInputDisabled, isImported, importInfo } = useInputDisabler({ content: state, hasPermission })

    React.useEffect(() => {
        if (updater.error) {
            const errorMessage = guessErrorMessage(updater.error)
            if (errorMessage.includes('Route')) {
                setErrors((prev) => ({ ...prev, Route: errorMessage }))
            }
        } else {
            setErrors((prev) => ({ ...prev, Route: '' }))
        }
    }, [updater.error])

    React.useEffect(() => {
        if (!fetcher.data || fetcher.isLoading) return
        setState(fetcher.data)
    }, [fetcher.data])

    React.useEffect(() => {
        if (!state) return
        document.title = state.Title
    }, [state?.Title])

    return (
        <PageContainerWithHeader
            title={state ? `[${state.Type}] ${state.Title}` : 'Edit'}
            topRightElement={
                <Box display='flex'>
                    <Button
                        onClick={() => {
                            setAllAccordions(true)
                        }}
                    >
                        <UnfoldMoreIcon />
                        Expand All
                    </Button>
                    <Button
                        onClick={() => {
                            setAllAccordions(false)
                        }}
                    >
                        <UnfoldLessIcon />
                        Collapse All
                    </Button>
                    <BackButton route={getPathToContentType(state)} />
                </Box>
            }
        >
            {fetcher.isLoading && <div>Loading...</div>}
            {fetcher.isError && <div>Error: {fetcher.error.message}</div>}

            {state && (
                <Grid container spacing={2}>
                    <Grid container item xs={4}>
                        <Grid item xs={12}>
                            {isImported && <EditableFieldsList hasPermission={hasPermission} importInfo={importInfo} />}
                            <Card sx={{ my: 1, p: 2 }}>
                                {!hasPermission && (
                                    <Alert severity={'info'} sx={{ marginBottom: '0.5rem' }}>
                                        {hasDistributedPagePermission && state?.Settings?.isDistrictPage
                                            ? `You are using a Distributed Page. A portion of this page is locked. Please scroll down to add or edit your content.`
                                            : 'You dont have access to this page - this is a read-only view.'}
                                    </Alert>
                                )}

                                <FormControl fullWidth sx={{ my: 1 }}>
                                    <TextField
                                        label='Title'
                                        required
                                        style={{ width: '100%' }}
                                        value={state.Title || ''}
                                        onChange={(v) => {
                                            state && setState({ ...state, Title: v.target.value })
                                        }}
                                        // disabled={!hasPermission}
                                        disabled={isInputDisabled('title')}
                                        error={!!errors.Title}
                                    />
                                </FormControl>
                                {state.Type !== 'alert' && (
                                    <FormControl fullWidth sx={{ my: 1 }}>
                                        <RouteEditor
                                            value={state.Route}
                                            onChange={(v) => {
                                                state && setState({ ...state, Route: v })
                                            }}
                                            contentType={state.Type as ContentType}
                                            // disabled={!hasPermission}
                                            disabled={isInputDisabled('route')}
                                            error={errors.Route}
                                        />
                                    </FormControl>
                                )}

                                {state.Type === 'news' && (
                                    <PinNews
                                        value={
                                            Number.isFinite(state.Settings?.priority) ? state.Settings.priority : null
                                        }
                                        onChange={(v) =>
                                            setState({
                                                ...state,
                                                Settings: { ...state.Settings, priority: v }
                                            })
                                        }
                                        disabled={!hasPermission}
                                    />
                                )}
                            </Card>
                            <Card sx={{ my: 1, p: 2 }}>
                                <FormControl fullWidth sx={{ my: 1 }}>
                                    <TemplateSelector
                                        ownerID={state.ID}
                                        path={state.Path}
                                        onChange={(path, tpl) => {
                                            setState({ ...state, Path: path })
                                            setSelectedTemplate(tpl)
                                        }}
                                        onLoaded={(tpl) => setSelectedTemplate(tpl)}
                                        templateType={'all'}
                                        classifications={classifications}
                                        error={errors.Path}
                                        // disabled={!hasPermission}
                                        disabled={isInputDisabled('path')}
                                    />
                                </FormControl>
                                <FormControl fullWidth sx={{ my: 1 }}>
                                    <StructureSelector
                                        required
                                        value={state.StructureID}
                                        onChange={(v, s) => {
                                            setState({ ...state, StructureID: v || null })
                                            setStructure(s?.FormStructure)
                                        }}
                                        selectedStructure={(s) => !structure && setStructure(s.FormStructure)}
                                        // disabled={!hasPermission}
                                        disabled={isInputDisabled('structure')}
                                        error={errors.StructureID}
                                        allowedStructures={selectedTemplate?.Structures || []}
                                    />
                                </FormControl>
                            </Card>

                            {state.Type === 'event' && (
                                <Card sx={{ my: 1, p: 2 }}>
                                    <EventDateTimePickerV2
                                        value={state.Settings}
                                        onChange={(v) => {
                                            setState({
                                                ...state,
                                                Settings: {
                                                    ...(state?.Settings || {}),
                                                    startdate: v.startdate,
                                                    enddate: v.enddate,
                                                    isAllDay: v.isAllDay
                                                }
                                            })
                                        }}
                                        // disabled={!hasPermission}
                                        disabled={Boolean(!hasPermission || isImported)}
                                        error={errors.Settings} // TODO: add error message for event date
                                    />
                                </Card>
                            )}

                            <Card sx={{ my: 1, p: 2 }}>
                                <BaseForm
                                    value={{
                                        PublishAt: state.PublishAt,
                                        ExpireAt: state.ExpireAt,
                                        PrivacyLevel: state.PrivacyLevel,
                                        Sites: state.Sites,
                                        DepartmentID: state.DepartmentID
                                    }}
                                    onChange={(b) => {
                                        setState({
                                            ...state,
                                            PublishAt: b.PublishAt,
                                            ExpireAt: b.ExpireAt,
                                            PrivacyLevel: b.PrivacyLevel,
                                            Sites: b.Sites,
                                            DepartmentID: b.DepartmentID
                                        })
                                    }}
                                    contentType={asSecured(state).EntityScope}
                                    // disabledFields={!hasPermission}
                                    disabledFields={{
                                        Sites: isInputDisabled('site'),
                                        DepartmentID: isInputDisabled('departmentId'),
                                        PrivacyLevel: isInputDisabled('privacyLevel'),
                                        PublishAt: isInputDisabled('publishAt') ? isInputDisabled('published') : false,
                                        ExpireAt: isInputDisabled('expireAt') ? isInputDisabled('published') : false
                                    }}
                                    errors={errors}
                                />
                            </Card>

                            <Card sx={{ my: 1, p: 2 }}>
                                <FormControl fullWidth sx={{ my: 1 }}>
                                    <TagsSelectorForContent
                                        selected={state.Tags || []}
                                        // disabled={!hasPermission}
                                        disabled={isInputDisabled('tags')}
                                        tagType={TagType.Content}
                                        onChange={(tags) => {
                                            tags && setState({ ...state, Tags: tags })
                                        }}
                                    />
                                </FormControl>
                            </Card>

                            {state.Type !== 'alert' && (!!state.Settings?.isDistrictPage || state.Sites.length > 1) && (
                                <Card sx={{ my: 1, p: 2 }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={(e) => {
                                                    state &&
                                                        setState({
                                                            ...state,
                                                            Settings: {
                                                                ...(state.Settings || {}),
                                                                isDistrictPage: e.target.checked
                                                            }
                                                        })
                                                }}
                                                checked={!!state.Settings?.isDistrictPage}
                                                name='first'
                                            />
                                        }
                                        label='Allow additional site specific content on shared sites'
                                        // disabled={!hasPermission}
                                        disabled={Boolean(!hasPermission || isImported)}
                                    />
                                </Card>
                            )}

                            {state.Type === 'page' && (
                                <Card sx={{ my: 1 }}>
                                    <CardContent sx={{ padding: '8px', '&:last-child': { paddingBottom: '8px' } }}>
                                        <Alert severity={'info'} style={{ marginBottom: '0.5rem' }}>
                                            Navigation changes for {currentSite?.Name} can be done in the Navigation
                                            Editor
                                        </Alert>
                                        <GoToNavigation id={state.ID} active={state.Active} />
                                    </CardContent>
                                </Card>
                            )}

                            {state.Type !== 'alert' && state && (
                                <Card>
                                    <CardContent>
                                        <LegacyUrls contentId={state.ID} hasPermission={hasPermission} />
                                    </CardContent>
                                </Card>
                            )}

                            {state.Type === 'alert' && (
                                <AlertNotification
                                    state={state}
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            PrivacyLevel: e.target.checked ? 0 : state.PrivacyLevel,
                                            Settings: {
                                                ...(state.Settings || {}),
                                                HasEmailNotification: e.target.checked
                                            }
                                        })
                                    }}
                                    hasPermission={hasPermission}
                                    notificationErrors={notificationErrors}
                                    onChange1={(e) => {
                                        setState({
                                            ...state,
                                            Settings: {
                                                ...(state.Settings || {}),
                                                EmailSubject: e.target.value
                                            }
                                        })
                                    }}
                                    onChange2={(e) => {
                                        setState({
                                            ...state,
                                            Settings: {
                                                ...(state.Settings || {}),
                                                EmailBody: e.target.value
                                            }
                                        })
                                    }}
                                />
                            )}

                            <AppAccordion
                                unmountOnExit
                                defaultExpanded={false}
                                withoutPadding
                                summary={'Revision History'}
                                details={<RevisionHistoryList content={state} />}
                            />
                        </Grid>
                    </Grid>

                    <Grid container item xs={8} sx={{ mb: 30 }}>
                        <Grid item xs={12}>
                            {structure && (
                                <FormRenderer
                                    ref={formRendererRef}
                                    value={state.Data}
                                    onChange={(d) => {
                                        setState({ ...state, Data: d })
                                    }}
                                    formStructure={structure}
                                    // disabled={!hasPermission}
                                    disabled={isInputDisabled('data')}
                                />
                            )}

                            {currentSite?.ID && !!state.Settings?.isDistrictPage && (
                                <DistributedPageEditor
                                    parentID={state.ID}
                                    siteID={currentSite?.ID}
                                    onChanges={(hasChanges) => {
                                        setDistributedPageHasChanges(hasChanges)
                                    }}
                                    ref={dpRef}
                                    label={`${state.Title} for ${currentSite?.Name}`}
                                    disabled={!hasDistributedPagePermission || isImported}
                                />
                            )}
                            <AppAccordion
                                expanded={accordionExpanded[defaultSEOAccordionId]}
                                onChangeHandler={(expanded) =>
                                    setAccordionExpanded({
                                        ...accordionExpanded,
                                        [defaultSEOAccordionId]: expanded
                                    })
                                }
                                summary={
                                    <Typography component={'p'} variant='h5'>
                                        SEO
                                    </Typography>
                                }
                                details={
                                    <SeoOptions
                                        noWrapper
                                        value={{
                                            mediaId: state.MediaID,
                                            seoTitle: state.Settings?.seoTitle || '',
                                            seoDescription: state.Settings?.seoDescription || ''
                                        }}
                                        onChange={({ mediaId, seoTitle, seoDescription }) => {
                                            setState(
                                                (prev) =>
                                                    prev && {
                                                        ...prev,
                                                        MediaID: mediaId,
                                                        Settings: {
                                                            ...prev?.Settings,
                                                            seoTitle,
                                                            seoDescription
                                                        }
                                                    }
                                            )
                                        }}
                                        disabled={!hasPermission}
                                    />
                                }
                            />
                            <AppAccordion
                                expanded={accordionExpanded[defaultMetaAccordionId]}
                                onChangeHandler={(expanded) =>
                                    setAccordionExpanded({
                                        ...accordionExpanded,
                                        [defaultMetaAccordionId]: expanded
                                    })
                                }
                                summary={
                                    <Typography component={'div'} variant='h5'>
                                        Meta
                                    </Typography>
                                }
                                details={
                                    <MetaEditor
                                        value={state.Meta || {}}
                                        onChange={(v) => {
                                            setState({ ...state, Meta: v })
                                        }}
                                        disabled={!hasPermission}
                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}

            {fetcher.data && state && (
                <ContentEditorSaveCard
                    serverValue={fetcher.data}
                    value={state}
                    onAction={() => {
                        if (!validateAll() || !validateNotification()) {
                            return
                        }

                        hasDistributedPagePermission && dpRef.current?.save()
                        hasPermission && updater.mutate(state)
                    }}
                    hasExternalChanges={distributedPageHasChanges}
                    disabled={!hasPermission && !hasDistributedPagePermission}
                    onChange={(publishPeriod: PublishPeriod) => {
                        setState({ ...state, PublishAt: publishPeriod.PublishAt, ExpireAt: publishPeriod.ExpireAt })
                    }}
                />
            )}
        </PageContainerWithHeader>
    )
}
