import React, { useState } from 'react'
import { CustomMenuItem } from './CustomMenu'
import { CircularProgress } from '@mui/material'
import { FileCopyOutlined } from '@mui/icons-material'
import { BaseContextMenuItemProps } from './types'
import { contentService } from '../../../pkgs/content/content.service'

export const CloneCustomMenuItem = ({
    id,
    onChange,
    disabled
}: BaseContextMenuItemProps & {
    id: string
}) => {
    const [isCloneLoading, setIsCloneLoading] = useState<boolean>(false)
    const handleClone = () => {
        setIsCloneLoading(true)
        contentService
            .clone(id)
            .then((response) => onChange?.(response))
            .catch((e) => console.error(e))
            .finally(() => setIsCloneLoading(false))
    }
    return (
        <CustomMenuItem text={'Clone'} onClick={handleClone} disabled={Boolean(disabled)}>
            {isCloneLoading ? <CircularProgress style={{ height: '24px', width: '24px' }} /> : <FileCopyOutlined />}
        </CustomMenuItem>
    )
}
