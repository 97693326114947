import { useListDetailsQuery } from './queries'
import { guessErrorMessage } from '../../helpers/guessErrorMessage'
import React from 'react'
import { Alert, Box, Button, Checkbox, FormControlLabel } from '@mui/material'
import { AddButton, BackButton } from '../../common/components'
import { Item, List } from './types'
import { httpPut } from '../../common/client'
import { notify } from '../../helpers'
import lodash from 'lodash'
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { SortableItem } from './SortableItem'
import { ItemView } from './Item'
import { ItemEditor } from './ItemEditor'
import { FragmentEditor } from '../content/fragments/FragmentEditor'
import { ContentExplorerDialog } from '@/pkgs/content/explorer/ContentExplorerDialog'
import { Preview } from '@mui/icons-material'
import PageContainerWithHeader from '../../common/components/PageContainerWithHeader'
import { useStateWithStorage } from '../../common/storage.service'
import { useParams } from 'react-router-dom'
import { ContentType } from '../content/types'
import { BASE } from '../../common/constants'

export const ItemsEditor = () => {
    const params = useParams()
    const results = useListDetailsQuery(params.id || '')
    const list = results.data

    // POC: the `fragment` type only locks the structure and allows any type of content
    // TODO: update the UI to reflect the changes or reconsider the approach
    // @ts-ignore
    const contentTypes: ContentType[] =
        list?.ContentTypes[0] === ContentType.Fragment
            ? [ContentType.Fragment, ContentType.Page, ContentType.News, ContentType.Event]
            : list?.ContentTypes || []

    const [items, setItems] = React.useState<Item[]>(list?.Items || [])
    const [selected, setSelected] = React.useState<string | undefined>()
    const [contentExplorerIsOpen, setContentExplorerIsOpen] = React.useState(false)
    const [fragmentEditorIsOpen, setFragmentEditorIsOpen] = React.useState(false)

    const [itemsEditorOptions, setItemsEditorOptions] = useStateWithStorage('items-editor-options', {
        addToList: true
    })

    const [initialised, setInitialised] = React.useState(false)
    const [saving, setSaving] = React.useState(false)

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    )

    React.useEffect(() => {
        setItems(list?.Items || [])
    }, [list?.Items])

    React.useEffect(() => {
        if (!initialised && list) {
            setInitialised(true)
            return
        }
        saveItemsThrottled()
    }, [items])

    const saveItems = () => {
        if (!list) return
        setSaving(true)
        httpPut(`${BASE}/api/v1/lists/${list.ID}/items`, items)
            .then(() => {
                notify('Items saved', 'info')
                setSaving(false)
            })
            .catch((e) => {
                notify(`Error saving items: ${guessErrorMessage(e)}`, 'error')
                setSaving(false)
            })
    }
    const saveItemsThrottled = lodash.throttle(saveItems, 1000)

    const onAction = (id: string, action: 'delete' | 'edit') => {
        switch (action) {
            case 'delete':
                if (!confirm(`Are you sure you want to delete the item with id: ${id}?`)) return
                setItems(items.filter((i) => i.ContentID !== id))
                break
            case 'edit':
                setSelected(id)
                break
        }
    }

    function handleDragEnd(event: { active: any; over: any }) {
        const { active, over } = event

        if (active.id !== over.id) {
            setItems((items) => {
                const oldIndex = items.findIndex((i) => i.ContentID === active.id)
                const newIndex = items.findIndex((i) => i.ContentID === over.id)

                return arrayMove(items, oldIndex, newIndex)
            })
        }
    }

    return (
        <PageContainerWithHeader
            title={`Items Editor: ${list?.Name}`}
            topRightElement={<BackButton route={'/ordered-lists'} />}
        >
            {items.length && items.length > 50 ? (
                <Alert severity='warning'>You have more than 50 items in this list. feature.</Alert>
            ) : (
                <Box display='flex' justifyContent='space-between'>
                    <Box>
                        {list?.ContentTypes.includes('fragment') && (
                            <AddButton
                                title={'Create fragment'}
                                func={() => {
                                    setSelected(undefined)
                                    setFragmentEditorIsOpen(true)
                                }}
                                style={{ marginRight: 10 }}
                            />
                        )}

                        <AddButton
                            title={'Select content'}
                            func={() => {
                                setSelected(undefined)
                                setContentExplorerIsOpen(true)
                            }}
                        />
                    </Box>
                    {list && items.length > 0 && (
                        <Button
                            variant={'contained'}
                            size={'medium'}
                            startIcon={<Preview />}
                            onClick={() => window.open(`${BASE}/api/v1/lists/${list.ID}/compile`, '_blank')}
                            style={{ marginRight: 10 }}
                        >
                            Preview
                        </Button>
                    )}
                </Box>
            )}
            {results.isLoading && <div>Loading...</div>}
            {results.error && <div>Error: {guessErrorMessage(results.error)}</div>}
            {results.data && (
                <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                    <SortableContext items={items.map((i) => i.ContentID)} strategy={verticalListSortingStrategy}>
                        {items.map((i) => (
                            <SortableItem
                                key={i.ContentID}
                                id={i.ContentID}
                                children={<ItemView item={i} onAction={onAction} />}
                            />
                        ))}
                    </SortableContext>
                </DndContext>
            )}
            {/*{items.map((i) => (*/}
            {/*    <div key={i.ContentID}>{i.ContentID}</div>*/}
            {/*))}*/}
            {list && contentExplorerIsOpen && (
                <ContentExplorerDialog
                    isOpen={!selected}
                    onClose={() => setContentExplorerIsOpen(false)}
                    contentTypes={contentTypes}
                    sites={list.Sites}
                    onSelect={(id) => setSelected(id)}
                    selected={items.map((i) => i.ContentID)}
                    structureID={list.ContentTypes.includes('fragment') ? list.StructureID : null}
                />
            )}
            {list && fragmentEditorIsOpen && (
                <FragmentEditor
                    id={undefined} // create fragment
                    open={fragmentEditorIsOpen}
                    onClose={() => setFragmentEditorIsOpen(false)}
                    onSuccessfulSave={(id) => {
                        if (itemsEditorOptions.addToList) {
                            setItems([...items, { ContentID: id, Overrides: {} }])
                        }
                    }}
                    fixedStructureID={list.StructureID}
                    moreDialogActions={
                        <FormControlLabel
                            sx={{ marginRight: '0px' }}
                            control={
                                <Checkbox
                                    onChange={() =>
                                        setItemsEditorOptions((p) => ({
                                            ...p,
                                            addToList: !itemsEditorOptions.addToList
                                        }))
                                    }
                                    checked={itemsEditorOptions.addToList}
                                    name='Add to List'
                                />
                            }
                            label='Add to List'
                        />
                    }
                />
            )}
            {selected && list?.Structure && (
                <ItemEditor
                    list={list as List}
                    item={items.find((i) => i.ContentID === selected) || { ContentID: selected }}
                    onSave={(item) => {
                        console.log(item)
                        if (items.find((i) => i.ContentID === selected)) {
                            setItems(items.map((i) => (i.ContentID === selected ? item : i)))
                        } else {
                            // item was just created via FragmentEditor and not yet saved so we add it to list
                            setItems([...items, item])
                        }
                        setSelected(undefined)
                        setContentExplorerIsOpen(false)
                    }}
                    onCancel={() => {
                        setSelected(undefined)
                        setContentExplorerIsOpen(false)
                    }}
                    overridables={list?.OverrideSections || []}
                />
            )}
            {/*<div style={{ position: 'absolute', bottom: 0, right: 0, padding: 50 }}>*/}
            {/*    <Button variant={'contained'} size={'large'} onClick={saveItems} disabled={saving}>*/}
            {/*        Save*/}
            {/*    </Button>*/}
            {/*</div>*/}
        </PageContainerWithHeader>
    )
}
