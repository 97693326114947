// css style string (e.g.: from style.cssText) to object
export const parseInlineStyle = (styleString: string | undefined) => {
    if (!styleString) {
        return {}
    }

    return styleString.split(';').reduce((acc, style) => {
        const [key, value] = style.split(':')
        if (key && value) {
            // Convert kebab-case to camelCase
            const camelKey = key.trim().replace(/-([a-z])/g, (g) => g[1].toUpperCase())
            acc[camelKey] = value.trim()
        }
        return acc
    }, {})
}
