import { Checkbox, FormControl, FormHelperText, InputLabel, ListItemText, MenuItem, Select } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ErrorIcon from '@mui/icons-material/Error'
import { is, renderSelectedValue } from '../../helpers'

export function CustomSelect({
    label,
    value,
    setValue,
    options,
    multiple,
    nameKey,
    customChange,
    customStyle,
    error,
    disabled,
    disableMenuItemCallback,
    helperText,
    variant,
    renderValue,
    size,
    useStyledRender,
    ...rest
}) {
    // If the value is an object it must have reference equality with the option in order to be selected (https://mui.com/material-ui/api/select/)
    value = options?.filter((option) => value?.findIndex((x) => x?.id === option?.id) > -1)
    const [disabledItems, setDisabledItems] = useState()

    useEffect(() => {
        if (!disableMenuItemCallback || !is.objectArray(options)) return
        const disabledState = options.reduce((acc, option) => {
            acc[option?.id] = disableMenuItemCallback(option)
            return acc
        }, {})
        setDisabledItems(disabledState)
    }, [disableMenuItemCallback, options])

    const disabledStatus = (option) => {
        const key = typeof option === 'string' ? option : option?.id
        const { permission = false, sharing = false } = disabledItems?.[key] || {}
        return { permission, sharing, disabled: Boolean(permission || sharing) }
    }

    function funcConnector(selected) {
        const ref = []
        for (let i = 0; i < selected.length; i++) {
            if (!selected?.[i]) continue
            selected[i].disabled = disabledStatus(selected?.[i]).disabled
            if (selected[i].disabled) {
                ref.push(selected?.[i])
            } else {
                ref.unshift(selected?.[i])
            }
        }

        let renderFunc = renderSelectedValue
        if (is.func(renderValue)) renderFunc = renderValue
        else if (useStyledRender) renderFunc = renderStyledValue

        return renderFunc(ref)
    }

    function renderStyledValue(ref) {
        const result = []
        const added = {}
        for (let i = 0; i < ref.length; i++) {
            const item = ref[i] || {}
            if (!item.id || added[item.id]) continue
            result.push(
                <span key={item.id} style={Boolean(item.disabled) ? { opacity: 0.6 } : {}}>
                    {i !== 0 && ', '}
                    {item.title || item.name || item.label || item.description || ''}
                </span>
            )
            added[item.id] = true
        }
        return result
    }

    return (
        <FormControl
            variant={variant || 'standard'}
            style={customStyle ? customStyle : { width: '100%', margin: '10px' }}
            size={size || 'medium'}
        >
            <InputLabel error={error}>{label}</InputLabel>
            <Select
                {...rest}
                label={label}
                size={size || 'medium'}
                variant={variant || 'standard'}
                value={value}
                multiple={multiple}
                disabled={disabled}
                error={error}
                onChange={(event) => {
                    // Multiple returns the entire object which saves us operations
                    // We only want to allow for one selection
                    if (is.func(customChange)) {
                        customChange(event.target.value)
                    }
                    setValue(event.target.value)
                }}
                renderValue={funcConnector}
            >
                {options.map((option) => {
                    const { sharing, disabled } = disabledStatus(option)
                    return (
                        <MenuItem key={option.id} value={option} disabled={disabled}>
                            <Checkbox checked={value.findIndex((x) => x.id === option.id) > -1} />
                            <ListItemText primary={option[nameKey]} />
                            {sharing && <ErrorIcon />}
                        </MenuItem>
                    )
                })}
            </Select>
            {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
        </FormControl>
    )
}

export default CustomSelect
