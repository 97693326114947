import React, { useEffect } from 'react'
import { sitesEventChanel } from '../../common/components/selectors/SiteSelectorForAccount'
import { Content, ContentType, getAllContentLikeContent } from '../content/types'
import { guessErrorMessage } from '../../helpers/guessErrorMessage'
import { notify } from '../../helpers'
import { CustomMenuItem } from '../../common/components/custom-context-menu/CustomMenu'
import { Preview } from '@mui/icons-material'
import CreateIcon from '@mui/icons-material/Create'
import { GridColDef } from '@mui/x-data-grid'
import { httpPatch, httpPost } from '../../common/client'
import {
    CellLine,
    CellWrapper,
    IDToNameCell,
    MenuLightCell,
    NullableDateCell,
    PublishPeriodCell,
    TwoLinesCell
} from '../grid/cells/GridCells'
import PageContainerWithHeader from '../../common/components/PageContainerWithHeader'
import { FormControlLabel, Grid, Switch } from '@mui/material'
import SearchBar from '../../common/components/SearchBar'
import { StructureSelector } from './StructureSelector'
import { TagsSelector } from '../grid/selectors/TagsSelector'
import { StatusSelector } from '../content/BaseForm'
import Button from '@mui/material/Button'
import { FragmentEditor } from '../content/fragments/FragmentEditor'
import moment from 'moment/moment'
import { ContentQueryParams, useContentQuery } from '../content/queries'
import { useParams } from 'react-router-dom'
import { Structure } from './types'
import { useNavigate } from 'react-router'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import { z } from 'zod'
import { useAppNavigation } from '../../app/useAppNavigation'
import { defaultPageQuery } from '../../common/react-query'
import { BASE, StructureAPI } from '../../common/constants'
import { DataGridBase } from '../grid/DataGridBase'
import { TagType } from '../system/tags/types'

export const RelatedContentGrid: React.FC = () => {
    const navigate = useNavigate()
    const { navigateTo } = useAppNavigation()
    const { structureID } = useParams()
    const [currentStructure, setCurrentStructure] = React.useState<Structure | undefined>(undefined)
    const contentTypes = getAllContentLikeContent()
    const defaultQuery: ContentQueryParams = {
        ...defaultPageQuery,
        ContentTypes: contentTypes as ContentType[],
        Sites: [],
        StructureID: structureID
    }
    const [query, setQuery] = React.useState<ContentQueryParams>(defaultQuery)
    const results = useContentQuery(query)

    const [selectedContent, setSelectedContent] = React.useState<string | undefined>(undefined)
    const [openEditor, setOpenEditor] = React.useState(false)
    const [filterTags, setFilterTags] = React.useState<{ tags: string[]; page: number }>({ tags: [], page: 0 })

    useEffect(() => {
        setQuery({ ...query, Tags: filterTags.tags })
    }, [filterTags])

    useEffect(() => {
        const subs = sitesEventChanel.on('onSiteUpdated', (siteID) => {
            setQuery({ ...query, Sites: [siteID || ''] })
        })
        return () => subs()
    }, [])

    useEffect(() => {
        setQuery({ ...query, page: 1 })
    }, [query.Search, query.ContentTypes, query.Sites, query.Tags])

    const restoreContent = (id: string) => {
        httpPatch(`${BASE}/api/v2/content/${id}/restore`, null)
            .then(() => {
                results.refetch()
            })
            .catch((err) => {
                notify(guessErrorMessage(err), 'error')
            })
    }

    const menuItems = (content: Content) => {
        return (onClose: () => void) => {
            return [
                <CustomMenuItem
                    key={'Preview'}
                    onClick={() => {
                        window.open(`${BASE}/api/v1/fragments/${content.ID}/compile`, '_blank')
                        onClose()
                    }}
                    text={'Preview'}
                >
                    <Preview />
                </CustomMenuItem>,
                <CustomMenuItem
                    key={'edit'}
                    onClick={() => {
                        navigateTo(`/content-editor/${content.ID}`)
                        // setSelectedContent(content.ID)
                        // setOpenEditor(true)
                        onClose()
                    }}
                    text={'Edit'}
                >
                    <CreateIcon />
                </CustomMenuItem>
            ]
        }
    }

    const columns: GridColDef[] = [
        {
            field: 'Title',
            headerName: 'Title',
            width: 300,
            filterable: false,
            sortable: true,
            renderCell: (params) => <TwoLinesCell l1={`${params.row.Title}`} l2={params.row.ID} />
        },
        {
            field: 'Updated',
            headerName: 'Updated',
            width: 100,
            filterable: false,
            sortable: true,
            renderCell: (params) => {
                const date = moment(params.row.Updated || params.row.Created)
                    .format('YYYY-MM-DD, h:mm:ss a')
                    ?.split(',')
                return <TwoLinesCell l1={date[0]} l2={date[1]} />
            }
        },
        {
            field: 'StructureID',
            headerName: 'Structure',
            width: 300,
            filterable: false,
            sortable: false,
            renderCell: (params) => <IDToNameCell tableName={'structure'} ID={params.row.StructureID} />
        },
        {
            field: 'Type',
            headerName: 'Type',
            width: 100,
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                <CellWrapper>
                    <CellLine>{params.row.Type}</CellLine>
                </CellWrapper>
            )
        },
        {
            field: 'Layout',
            headerName: 'Layout',
            width: 100,
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                <CellWrapper>
                    <CellLine>{params.row.PageLayout}</CellLine>
                </CellWrapper>
            )
        },
        {
            field: 'PublishPeriod',
            headerName: 'Status',
            width: 250,
            sortable: false,
            filterable: false,
            renderCell: (params) => (
                <PublishPeriodCell publishAt={params.row.PublishAt} expireAt={params.row.ExpireAt} />
            )
        },
        {
            field: 'publish_at',
            headerName: 'Publish At',
            flex: 1,
            sortable: true,
            filterable: false,
            renderCell: (params) => <NullableDateCell row={params.row} fieldName={'PublishAt'} />
        },
        {
            field: 'expire_at',
            headerName: 'Expire At',
            flex: 1,
            sortable: true,
            filterable: false,
            renderCell: (params) => <NullableDateCell row={params.row} fieldName={'ExpireAt'} />
        },
        {
            field: 'Menu',
            headerName: '...',
            width: 80,
            sortable: false,
            filterable: false,
            renderCell: (params) => <MenuLightCell itemsFactory={menuItems(params.row)} />
        }
    ]

    return (
        <PageContainerWithHeader
            title={!currentStructure ? `Related Content: ${structureID}` : `Related Content: ${currentStructure.Name}`}
            topRightElement={
                <Button
                    variant={'contained'}
                    size={'medium'}
                    startIcon={<AutoFixHighIcon />}
                    onClick={() => {
                        httpPost(`${StructureAPI}/migrate-dct-to-structure/${structureID}`, {}, z.array(z.string()))
                            .then((msg) => {
                                notify(msg || `Success. Migrated all structures.`, 'info')
                                results.refetch()
                            })
                            .catch((e) => {
                                notify(`Error. Failed to migrate structures. ` + guessErrorMessage(e), 'error')
                            })
                    }}
                >
                    Migrate from DCT
                </Button>
            }
        >
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <SearchBar value={query.Search || ''} onChange={(val) => setQuery({ ...query, Search: val })} />
                </Grid>
                <Grid item xs={4}>
                    <StructureSelector
                        disabled={true}
                        value={query.StructureID}
                        onChange={(id, structure) => {
                            setCurrentStructure(structure)
                            setQuery({ ...query, StructureID: id })
                        }}
                        selectedStructure={(s) => !currentStructure && setCurrentStructure(s)}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TagsSelector
                        value={filterTags?.tags || []}
                        onChange={(tags) => setFilterTags((p) => ({ ...p, tags }))}
                        tagType={TagType.Content}
                    />
                </Grid>
                <Grid item xs={3}>
                    <StatusSelector value={query.Status} onChange={(v) => setQuery({ ...query, Status: v, page: 1 })} />
                </Grid>
                <Grid item xs={2}>
                    <FormControlLabel
                        value='start'
                        control={
                            <Switch
                                checked={query.Inactive || false}
                                onChange={(e) =>
                                    setQuery({ ...query, Inactive: e.target.checked, Status: '', page: 1 })
                                }
                                color='secondary'
                            />
                        }
                        label='Deleted'
                        labelPlacement='start'
                    />
                </Grid>
                <Grid item xs={7}>
                    <Button
                        style={{ marginTop: '0.25rem', float: 'right' }}
                        onClick={() => {
                            setQuery(defaultQuery)
                            setFilterTags({ tags: [], page: 0 })
                        }}
                    >
                        Reset Filters
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    {results.isLoading && <div>Loading...</div>}
                    {results.error && <div>Error: {guessErrorMessage(results.error)}</div>}
                    {results.data && (
                        <DataGridBase
                            // rowHeight={100}
                            columns={columns}
                            state={results.data}
                            setQuery={setQuery}
                        />
                    )}
                </Grid>
            </Grid>
            {openEditor && (
                <FragmentEditor
                    id={selectedContent}
                    open={openEditor}
                    onClose={() => {
                        setOpenEditor(false)
                        results.refetch()
                    }}
                    onSuccessfulSave={() => results.refetch()}
                />
            )}
        </PageContainerWithHeader>
    )
}
