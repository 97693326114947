import axios from 'axios'
import { NavigationAPI } from '../../common/constants'

export async function deleteByContentId(id) {
    try {
        if (!id?.length) {
            return Promise.reject(`invalid contentId: [${id}]`)
        }
        return await axios.delete(`${NavigationAPI}/${id}`)
    } catch (e) {
        console.error(e)
        return Promise.reject(e)
    }
}

export async function getBySiteId(siteId) {
    try {
        return (await axios.get(NavigationAPI, { params: { siteId } })).data
    } catch (e) {
        return Promise.reject(e)
    }
}

export async function getDepartmentNavigation(departmentId) {
    try {
        return (await axios.get('/api/v1/department/navigation', { params: { departmentId } })).data
    } catch (e) {
        return []
    }
}

export async function getAll() {
    try {
        return (await axios.get(NavigationAPI)).data
    } catch (e) {
        return []
    }
}

export async function patchNavigation(navigationArray, selectedSite) {
    console.log('patchNavigation', navigationArray)
    return axios.patch(NavigationAPI, { records: navigationArray }, { params: { siteId: selectedSite } }).then((x) => x)
}

export async function deleteNavigation(nodes, selectedSite) {
    if (!nodes || !nodes.length) return
    for (const node of nodes) {
        const index = nodes.indexOf(node)
        nodes[index].active = false
        nodes[index].sites = [selectedSite]
    }
    const collection = {
        records: nodes
    }
    return patchNavigation(collection, selectedSite)
}

export const navigationService = {
    deleteByContentId,
    getAll,
    patchNavigation
}
