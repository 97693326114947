import { useParams } from 'react-router-dom'
import React, { useState } from 'react'
import { Content, ContentType } from '../types'
import { useStructuredContentValidators } from '../editor/useStructuredContentValidators'
import { useContentQueries } from '../queries'
import { useDisable } from '../../../common/useDisable'
import { useAppContext } from '../../auth/atoms'
import {
    Alert,
    Card,
    CardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    MenuItem,
    Select
} from '@mui/material'
import { CodeEditor } from '../../monaco/code-editor'
import TextField from '@mui/material/TextField'
import { TemplateSelector } from '../editor/components/TemplateSelector'
import { SiteSelectorForContent } from '../../../common/components/selectors/SiteSelectorForContent'
import { EntityScopeEnum } from '../../auth/entityScope'
import { StructuresForTemplateSelector } from '../../structure/StructuresForTemplateSelector'
import Button from '@mui/material/Button'
import { notify } from '../../../helpers'
import { mustString } from '../../../helpers/parseJSON'

export const TemplateEditorV2 = () => {
    useDisable()
    const { id } = useParams()
    const [state, setState] = React.useState<Content | undefined>(undefined)

    const [jsonErrors, setJsonErrors] = useState<any>([])
    const { validateAll, errors, setErrors } = useStructuredContentValidators(
        ['Sites', 'Title', 'Path', 'Settings'],
        state
    )
    const { fetcher, updater } = useContentQueries(id || '')

    const evaluators = useAppContext()
    const hasPermission = React.useMemo(() => evaluators.action(state, 'update'), [state])

    React.useEffect(() => {
        if (!fetcher.data) return
        if (state) return

        setState(fetcher.data)
    }, [fetcher.data])

    React.useEffect(() => {
        if (state?.Title) {
            document.title = `Edit Template: ${state.Title}`
        }
    }, [state?.Title])

    return (
        <div className='container-fluid'>
            {fetcher.isLoading && <div>Loading...</div>}
            {fetcher.isError && <Alert severity={'error'}>Error: {fetcher.error.message}</Alert>}
            {state && (
                <div className='row'>
                    <div className='col-xs-12 col-lg-10 col-md-9 first-lg'>
                        <Card sx={{ my: 1, p: 2 }}>
                            <CardContent className='no-padding'>
                                <h2 className='editor-title'>{state.Title}</h2>
                                <h3>Template HTML</h3>
                                <CodeEditor
                                    value={state?.Content || ''}
                                    language={'html'}
                                    onChange={(v) => state && setState({ ...state, Content: v })}
                                    disabled={!hasPermission}
                                    height={'60vh'}
                                />
                            </CardContent>
                        </Card>
                        {state?.Type === ContentType.Template && (
                            <Card className='editor'>
                                <CardContent className='no-padding'>
                                    <h3>Page Components (legacy)</h3>
                                    <Alert style={{ whiteSpace: 'break-spaces' }} className='m15' severity={'warning'}>
                                        The following JSON is not used anymore. It is kept for legacy reasons.
                                    </Alert>
                                    {!!jsonErrors?.length && (
                                        <Alert
                                            style={{ whiteSpace: 'break-spaces' }}
                                            className='m15'
                                            severity={'error'}
                                        >
                                            {jsonErrors.map((e: any) => {
                                                const msg = `Error at line ${e.startLineNumber} column ${e.startColumn}: ${e.message}`
                                                return <div key={msg}>{msg}</div>
                                            })}
                                        </Alert>
                                    )}
                                    <CodeEditor
                                        value={mustString(state.Structure)}
                                        language={'json'}
                                        onChange={(v) => {
                                            setState({ ...state, Structure: v ? v : undefined })
                                        }}
                                        onValidate={(v) => {
                                            setJsonErrors(v)
                                        }}
                                        disabled={!hasPermission}
                                        height={'60vh'}
                                    />
                                </CardContent>
                            </Card>
                        )}
                    </div>
                    <div className='col-xs-12 col-lg-2 col-md-3'>
                        <Card sx={{ my: 1, p: 2 }}>
                            <FormControl fullWidth sx={{ my: 1 }}>
                                <TextField
                                    label='Title'
                                    required
                                    style={{ width: '100%' }}
                                    value={state.Title || ''}
                                    onChange={(v) => state && setState({ ...state, Title: v.target.value })}
                                    disabled={!hasPermission}
                                    error={!!errors.Title}
                                />
                            </FormControl>

                            <div style={{ height: '20px' }} />

                            <TemplateSelector
                                ownerID={id || ''}
                                path={state.Path || ''}
                                onChange={(path) => state && setState({ ...state, Path: path })}
                                templateType={'all'}
                                classifications={['template']}
                                error={errors.Path}
                                disabled={!hasPermission}
                                label='Parent Template'
                            />

                            <div style={{ height: '20px' }} />

                            <SiteSelectorForContent
                                Selected={state.Sites}
                                OnChange={(sites, departmentID) =>
                                    setState({ ...state, Sites: sites, DepartmentID: departmentID })
                                }
                                ContentType={EntityScopeEnum.Resource}
                                Disabled={!hasPermission}
                                HasError={!!errors.Sites}
                            />
                        </Card>

                        <Card sx={{ my: 1, p: 2 }}>
                            <FormControl variant='standard' style={{ paddingLeft: '5px' }} component='fieldset'>
                                <FormLabel error={false} component='legend'>
                                    Enable Navigation
                                </FormLabel>
                                <FormHelperText>Does this template Render Navigation?</FormHelperText>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled={!hasPermission}
                                                onChange={(e) =>
                                                    setState({
                                                        ...state,
                                                        Settings: {
                                                            ...(state.Settings || {}),
                                                            navigation: e.target.checked
                                                        }
                                                    })
                                                }
                                                checked={state.Settings?.navigation || false}
                                            />
                                        }
                                        label='Enable'
                                    />
                                </FormGroup>
                            </FormControl>

                            <FormControl variant='standard' style={{ marginTop: 30 }} fullWidth>
                                <FormLabel>Classification</FormLabel>
                                <FormHelperText style={{ paddingLeft: '5px' }}>
                                    What would you like this template to be used for?
                                </FormHelperText>

                                <Select
                                    fullWidth
                                    label={'Classification'}
                                    variant='standard'
                                    disabled={!hasPermission}
                                    multiple={true}
                                    value={state.Settings?.classification || []}
                                    onChange={(event) =>
                                        setState({
                                            ...state,
                                            Settings: {
                                                ...(state?.Settings || {}),
                                                classification: event.target.value || []
                                            }
                                        })
                                    }
                                >
                                    <MenuItem value='page'>Pages</MenuItem>
                                    <MenuItem value='news'>News</MenuItem>
                                    <MenuItem value='event'>Events</MenuItem>
                                    <MenuItem value='alert'>Alerts</MenuItem>
                                    <MenuItem value='template'>Master</MenuItem>
                                </Select>
                                {!state.Settings?.classification?.length && (
                                    <Alert severity='warning'>
                                        If no Classification selected the template won't be available for users to
                                        select.{' '}
                                    </Alert>
                                )}
                            </FormControl>
                        </Card>

                        {state.Settings?.classification &&
                            !!state.Settings?.classification.length &&
                            !!state.Settings?.classification.filter((c) => c !== 'template').length && (
                                <Card sx={{ my: 1, p: 2 }}>
                                    <FormControl variant='standard' style={{ paddingLeft: '5px' }} component='fieldset'>
                                        <StructuresForTemplateSelector
                                            value={state.Structures || []}
                                            onChange={(v) => setState({ ...state, Structures: v })}
                                            disabled={!hasPermission}
                                        />
                                    </FormControl>
                                </Card>
                            )}

                        <Button
                            sx={{ my: 2 }}
                            fullWidth
                            variant='contained'
                            color='primary'
                            disabled={!hasPermission}
                            onClick={() => {
                                if (!validateAll() || jsonErrors?.length) {
                                    notify('Please fix the errors before saving', 'error')
                                    return
                                }
                                if (state?.Structure) {
                                    try {
                                        if (typeof state.Structure === 'string')
                                            state.Structure = JSON.parse(state.Structure)
                                    } catch (e) {
                                        notify('Please fix the JSON errors before saving', 'error')
                                        return
                                    }
                                }
                                updater.mutate(state)
                            }}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            )}
        </div>
    )
}
