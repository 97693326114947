import moment from 'moment'
import { NullDateTime } from '../common/constants'

export const renderLastModified = (created, updated) => {
    if (updated && updated !== NullDateTime) {
        return moment(updated).format('ll')
    }
    if (created) {
        return moment(created).format('ll')
    }
    return ''
}
