import { contentTypes } from '../selectors/SiteSelectorForAccount'
import React, { useState } from 'react'
import { CustomMenuItem } from './CustomMenu'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import { DepartmentTransferDialog } from '../../../pkgs/departments/DepartmentWidgetForEditors'
import { BaseContextMenuItemProps } from './types'

interface TransferDepartmentMenuItemProps {
    closeMenu: () => void
    endpoint?: 'content' | 'media' | 'document'
    contentModel?: {
        id: string
        departmentId: string | null
        contentType: contentTypes
        sites: string[]
        settings: any
    }
}

export const TransferDepartmentMenuItem = ({
    contentModel,
    onChange,
    disabled,
    closeMenu,
    endpoint = 'content'
}: TransferDepartmentMenuItemProps & BaseContextMenuItemProps) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const handleOpen = () => {
        setIsDialogOpen(true)
        closeMenu?.()
    }
    return (
        <>
            <CustomMenuItem text={'Department'} onClick={handleOpen} disabled={Boolean(disabled)}>
                <CorporateFareIcon style={{ color: '#4E01D5' }} />
            </CustomMenuItem>
            {isDialogOpen && (
                <DepartmentTransferDialog
                    contentModel={contentModel || null}
                    IsOpen={isDialogOpen}
                    OnTransfer={onChange}
                    CloseMenu={() => setIsDialogOpen(false)}
                />
            )}
        </>
    )
}
