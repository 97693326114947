import { ManagerFilters } from './ManagerFilters'
import { Manager } from './Manager'
import React, { useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Fallback } from '@/pkgs/errors/Fallback'

export const ManagerWithFilters = ({
    paginationState,
    setPaginationState,
    contentType,
    searchLoading,
    resetFormCallback,
    setMenuElement
}) => {
    const [resetFormSignal, setResetFormSignal] = useState(false)

    return (
        <>
            <ErrorBoundary FallbackComponent={Fallback}>
                <ManagerFilters
                    setPaginationState={setPaginationState}
                    paginationState={paginationState}
                    resetFormCallback={() => {
                        setResetFormSignal((resetFormSignal) => !resetFormSignal)
                        resetFormCallback()
                    }}
                    contentType={contentType}
                />
            </ErrorBoundary>

            <ErrorBoundary FallbackComponent={Fallback}>
                <Manager
                    paginationState={paginationState}
                    setPaginationState={setPaginationState}
                    resetSignal={resetFormSignal}
                    searchLoading={searchLoading}
                    // persistColumnVisibility={persistColumnVisibility}
                    contentType={contentType}
                    setMenuElement={setMenuElement}
                />
            </ErrorBoundary>
        </>
    )
}
