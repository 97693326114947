import moment from 'moment'
import { Content, ContentType } from './types'
import { Moment } from 'moment-timezone'

export type ContentErrors = Partial<Record<keyof Content, string>>

const skip = (state: Content) => ({})
const publishPeriod = (state: Content) => {
    const errors: ContentErrors = { PublishAt: '', ExpireAt: '' }

    if (!state.PublishAt) return errors

    if (state.Type === 'alert' && !state.ExpireAt) {
        errors.ExpireAt = 'Please select an expiration date'
    }

    return errors
}

export const contentValidators: Record<keyof Content, (state: Content) => ContentErrors> = {
    Sites: (state: Content) => {
        const errors: ContentErrors = { Sites: '' }

        if (!state.Sites || !state.Sites.length) {
            errors.Sites = 'Please select at least one site'
        }
        return errors
    },
    StructureID: (state: Content) => {
        const errors: ContentErrors = { StructureID: '' }
        if (state.Type === 'template') return errors

        if (!state.StructureID || !state.StructureID.length) {
            errors.StructureID = 'Please select at least one structure'
        }
        return errors
    },
    Title: (state: Content) => {
        const errors: ContentErrors = { Title: '' }

        if (!state.Title || state.Title.length < 3) {
            errors.Title = 'Please enter a title'
        }
        return errors
    },
    Path: (state: Content) => {
        const errors: ContentErrors = { Path: '' }

        const pathNotRequiredContentTypes = [ContentType.Fragment, ContentType.Template]
        if (pathNotRequiredContentTypes.some((r) => r === state.Type)) return errors

        if (!state.Path || state.Path.length < 32) {
            errors.Path = 'Please select at least one structure'
        }
        return errors
    },
    Route: (state) => {
        const errors: ContentErrors = { Route: '' }

        const noRoute = ['fragment', 'template', 'distributed_page', 'alert']
        if (noRoute.some((r) => r === state.Type)) return errors

        if (
            !state.Route ||
            !state.Route.length ||
            !(
                state.Route.startsWith('/') ||
                state.Route.toLowerCase().startsWith('https://') ||
                state.Route.toLowerCase().startsWith('http://')
            ) ||
            state.Route.toLowerCase().startsWith('/api/') ||
            state.Route.toLowerCase().startsWith('/sys/')
        ) {
            errors.Route = 'Please enter a valid route'
        }

        if (state.Route.toLowerCase().startsWith('https://') || state.Route.toLowerCase().startsWith('http://')) {
            try {
                new URL(state.Route)
            } catch (e) {
                errors.Route = 'Please enter a valid route, including the protocol'
            }
        }
        return errors
    },
    ExpireAt: publishPeriod,
    PublishAt: publishPeriod,
    Settings: (state) => {
        const errors: ContentErrors = { Settings: '' }

        const required = ['event', 'template']
        if (!required.some((r) => r === state.Type)) return errors

        if (state.Type === 'event') {
            if (!state.Settings || !state.Settings.enddate || !state.Settings.startdate) {
                errors.Settings = 'Please select a start and end date'
            } else {
                const adjustedEndDate = (
                    state.Settings?.isAllDay
                        ? moment(state.Settings?.enddate).subtract({ days: 1 })
                        : moment(state.Settings?.enddate)
                );
                if (adjustedEndDate.isBefore(moment(state.Settings?.startdate))) {
                    errors.Settings = 'End date must be after start date.'
                }
                // const endDatePlusOneDay = moment(state.Settings?.enddate).subtract({ days: 1 })
                // if (endDatePlusOneDay.isBefore(moment(state.Settings?.startdate))) {
                //     errors.Settings = 'End date must be after start date.'
                // }
            }
        } else if (state.Type === 'template') {
            // TODO: decide if we need any validation here
            // if (
            //     !state.Settings ||
            //     !Array.isArray(state.Settings.classification) ||
            //     !state.Settings.classification.length
            // ) {
            //     errors.Settings = 'Please select at least one classification for the template'
            // }
        }

        return errors
    },

    Meta: skip,

    Type: skip,
    Data: skip,
    DepartmentID: skip,
    Tags: skip,
    MediaID: skip,
    Owner: skip,
    PageLayout: skip,
    PrivacyLevel: skip,

    ID: skip,
    Active: skip,
    Created: skip,
    Deleted: skip,
    Updated: skip,
    Publisher: skip,
    Structures: skip,
    Content: skip,
    Structure: (state) => {
        if (state.Type !== 'template') return skip(state)

        const errors: ContentErrors = { Structure: '' }
        try {
            if (state.Structure && typeof state.Structure === 'string') {
                JSON.parse(state.Structure)
            }
        } catch (e) {
            if (e instanceof SyntaxError) {
                errors.Structure = `${e.name}: ${e.message}`
            } else {
                errors.Structure = `Can't parse structure`
            }
        }
        return errors
    }
}
