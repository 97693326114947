import { ElementFormatType, NodeKey } from 'lexical'
import { Video } from './VideoNode'
import { useLexicalNodeSelection } from '@lexical/react/useLexicalNodeSelection'
import { BlockWithAlignableContents } from '@lexical/react/LexicalBlockWithAlignableContents'
import { Box } from '@mui/material'
import { colours } from '@/common/colours'

type VideoComponentProps = Readonly<{
    className: Readonly<{
        base: string
        focus: string
    }>
    format: ElementFormatType | null
    nodeKey: NodeKey
    video: Video
}>

function VideoComponent({ className, format, nodeKey, video }: VideoComponentProps) {
    const [isSelected, setSelected, clearSelection] = useLexicalNodeSelection(nodeKey || '')

    return (
        <BlockWithAlignableContents className={className} format={format} nodeKey={nodeKey}>
            <Box
                sx={{
                    boxShadow: isSelected ? `0 0 10px ${colours.light_blue}` : undefined
                }}
            >
                {video.renderIframe()}
            </Box>
        </BlockWithAlignableContents>
    )
}

export default VideoComponent
