import React, { useEffect, useState } from 'react'
import { isEqual } from 'lodash'
import { Autocomplete, TextField } from '@mui/material'
import { SiteType } from '../../../common/constants'
import { tagService } from '../../system/tags/tag.service'
import { storage } from '../../../common/storage.service'
import { ContentType } from '../../content/types'
import { TagType } from '../../system/tags/types'
import { useAppContext } from '@/pkgs/auth/atoms'

interface TagsSelectorProps {
    value: string[]
    onChange: (v: string[]) => void
    tagType: TagType
    disabled?: boolean
}

export const TagsSelector = ({ value: tags, onChange, tagType, disabled }: TagsSelectorProps) => {
    const appContext = useAppContext()
    const [serverTags, setServerTags] = useState([])
    const [value, setValue] = useState<any[]>([])
    const [tagsAbort, setTagsAbort] = useState(new AbortController())
    const [restored, setRestored] = useState(false)

    const key = `${appContext.tenantID}-${tagType}-selected-tags`

    useEffect(() => {
        if (!restored) return
        if (Array.isArray(tags) && tags.length === 0 && value.length > 0) {
            handleOnChange(null, [])
        }
    }, [tags])

    useEffect(() => {
        tagsAbort.abort()
        const newController = new AbortController()
        setTagsAbort(newController)

        const run = async () => {
            const res = await tagService.getAllAlt(newController.signal, { tagType: tagType })
            if (res.failure) {
                return
            }

            const options = res.data.results.map((o) => ({ label: o.name, id: o.id }))
            setServerTags(options)
            setRestored(true)

            const selected = (storage.getItem(key) || []) as any[]
            setValue(selected)

            if (isEqual(tags, selected)) {
                return
            }
            onChange?.(selected?.map(({ id }) => id))
        }
        run().catch(console.error)
    }, [])

    function handleOnChange(e, selected) {
        storage.setItem(key, selected)
        setValue(selected)
        onChange?.(selected?.map(({ id }) => id))
    }

    return (
        <Autocomplete
            style={{ flex: '1' }}
            onChange={handleOnChange}
            multiple
            disabled={disabled || false}
            filterSelectedOptions
            options={serverTags}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(o, v) => o.id === v.id}
            value={value}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant='outlined'
                    label='Tags'
                    placeholder='Tags'
                    helperText='Start typing or select tags from drop down'
                />
            )}
        />
    )
}
