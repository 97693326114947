import React, { Fragment } from 'react'
import { IconButton, MenuItem } from '@mui/material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

export const AppbarHelp = ({ mobile }) => {
    const sendToDocumentation = () => {
        const currentRoute = window.location.pathname?.split('/')?.filter(Boolean)?.shift()
        let helpRoute = 'https://cdn.imagineeverything.com'
        switch (currentRoute) {
            case 'pages':
            case 'dct':
            case 'ckeditor':
            case 'page':
                helpRoute += '/pages'
                break
            case 'news':
            case 'newseditor':
            case 'article':
                helpRoute += '/news'
                break
            case 'events':
            case 'events-editor':
            case 'event':
                helpRoute += '/events'
                break
            case 'media':
                helpRoute += /* currentSite.hasSharedMedia ? '/images-admin' : */ '/images-site'
                break
            case 'transportation':
                helpRoute += '/transportation'
                break
            case 'navigation':
                helpRoute += '/navigation'
                break
            case 'settings':
                helpRoute += '/settings'
                break
            case 'documents':
                helpRoute += /* currentSite.hasSharedMedia ? '/documents-admin' : */ '/documents-site'
                break
            case 'alerts':
                helpRoute += '/alerts'
                break
            case 'ordered-lists':
                helpRoute += '/ordered-lists'
                break
            case '/fragments':
                helpRoute += '/fragments'
                break
            default:
                helpRoute += '/content-manager-documentation'
                break
        }
        window.open(helpRoute, '_blank')
    }
    if (mobile) {
        return (
            <MenuItem>
                <IconButton
                    onClick={sendToDocumentation}
                    aria-label={'Get documentation help'}
                    color='inherit'
                    size='large'
                >
                    <HelpOutlineIcon />
                </IconButton>
                <p>Help</p>
            </MenuItem>
        )
    } else {
        return (
            <Fragment>
                <IconButton
                    onClick={sendToDocumentation}
                    aria-label={'Get documentation help'}
                    color={'inherit'}
                    size='large'
                >
                    <HelpOutlineIcon />
                </IconButton>
            </Fragment>
        )
    }
}
