import React from 'react'
import { FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import { ContentLike, getAllContentLikeContent } from './types'

interface ContentTypeSelectorProps {
    value: ContentLike
    onChange: (contentType: ContentLike) => void
    availableTypes?: ContentLike[]
}

export const ContentTypeSelector = ({ value, onChange, availableTypes }: ContentTypeSelectorProps) => {
    const options = availableTypes || getAllContentLikeContent()
    if (!value) {
        value = options[0]
        onChange(value)
    }

    return (
        <>
            <FormLabel>Content Type</FormLabel>
            <RadioGroup row>
                {options.map((type) => (
                    <FormControlLabel
                        sx={{ textTransform: 'capitalize' }}
                        key={type}
                        value={type}
                        control={<Radio />}
                        label={type}
                        checked={value === type}
                        onChange={() => onChange(type)}
                    />
                ))}
            </RadioGroup>
        </>
    )
}
