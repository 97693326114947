import { useAppNavigation } from './useAppNavigation'
import { useEffect } from 'react'
import { getMatchingSubstrings } from '../helpers/getMatchingSubstrings'
import { useRouterState } from '../common/useParams'

export function Redirect({ to }: { to: string }) {
    const { navigateTo } = useAppNavigation()
    const params = useRouterState()
    useEffect(() => {
        navigateTo(getRedirectLinkTo(to, params), { replace: true })
    }, [])
    return null
}

export function getRedirectLinkTo(to: string, params: Record<string, any>, fallback?: string) {
    if (!to.includes('/:')) {
        return to
    }
    const matches = getMatchingSubstrings(to, /:+[^:\/]*(?!\/)./g)
    for (const match in matches) {
        const paramKey = match.replace(':', '')
        const param = params[paramKey]
        if (!param) {
            if (fallback) {
                return getRedirectLinkTo(fallback, params)
            } else {
                return '/'
            }
        }
        to = to.replace(match, param)
    }
    return to
}
