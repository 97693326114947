import { useContext, useEffect } from 'react'
import { disabledContext } from './DisabledContext'

export function useDisable() {
    const [, setDisabled] = useContext(disabledContext)
    useEffect(() => {
        if (typeof setDisabled === 'function') {
            setDisabled(true)
        }
        return () => {
            if (typeof setDisabled === 'function') {
                setDisabled(false)
            }
        }
    }, [setDisabled])
}
