import React from 'react'

export function useDebounce<T>(value: T, delay: number) {
    const [debouncedValue, setDebouncedValue] = React.useState(value)
    const timeoutRef = React.useRef<number | undefined>(undefined)

    React.useEffect(() => {
        if (timeoutRef.current !== undefined) {
            clearTimeout(timeoutRef.current)
        }
        // @ts-ignore
        timeoutRef.current = setTimeout(() => {
            setDebouncedValue(value)
        }, delay)

        return () => {
            clearTimeout(timeoutRef.current)
        }
    }, [value, delay])

    return debouncedValue
}
