import { Button, TextField, Typography } from '@mui/material'
import { HelpTooltip } from '../../common/components'
import { SiteSelectorForContent } from '../../common/components/selectors/SiteSelectorForContent'
import React, { useEffect, useState } from 'react'
import { Format } from '../../helpers'
import { makeBaseExternalLink } from './NavigationNodes'
import ClearIcon from '@mui/icons-material/Clear'
import { ExternalNodeV2 } from './sortable-tree/ExternalNode_v2'
import { useEmitter } from './useEmitter'
import { useAppContext, useCurrentSite } from '../auth/atoms'
import { EntityScopeEnum } from '../auth/entityScope'

const baseExternalNode = () => ({
    label: '',
    url: '',
    isPrimary: false,
    sites: []
})

export default function ExternalLinkControl({ className }: { className?: any }) {
    const evaluators = useAppContext()
    const currentSite = useCurrentSite()
    const [next, setNext] = useState<Record<string, any>>(baseExternalNode())
    const [errors, setErrors] = useState<Record<string, boolean>>({
        sites: false,
        url: false,
        label: false
    })
    const [list, setList] = useState<Array<Object>>([])
    const emitter = useEmitter(['TreeChange'])

    useEffect(() => {
        if (emitter.test(list) > -1) {
            setList((p) => p.filter((x) => x['id'] !== emitter?.event?.id))
            emitter.done()
        }
    }, [emitter.event])

    useEffect(() => {
        setNext({ ...baseExternalNode(), sites: [defaultSiteId()] })
    }, [currentSite?.ID])

    // const baseExternalNode = () => ({
    //     label: "",
    //     url: "",
    //     isPrimary: false,
    //     sites: [defaultSiteId()],
    // })

    const setTextValue = (event) => {
        const { value = '', name } = event?.target || {}
        if (!name) {
            return console.error('missing name prop on TextField')
        }
        setNext((prev) => ({ ...prev, [name]: value }))
    }

    const createExternal = () => {
        setErrors({
            label: !Boolean(next.label.length),
            url: !Boolean(next.url.length),
            sites: !Boolean(next.sites.length)
        })
        if (!next.label.length || !next.url.length || !next.sites.length) {
            return
        }

        const departmentId = currentSite?.Type === 'department' ? currentSite.ID : null
        const externalBase = makeBaseExternalLink(currentSite?.ID || '', departmentId, next.label, next.url)
        externalBase.settings = {
            ...(externalBase.settings || {}),
            isPrimary: next.isPrimary
        }
        externalBase.content.sites = Format.asStringArray(next.sites)
        setList((prev) => [externalBase, ...prev])
        setNext(baseExternalNode())
    }

    const deleteExternalNode = async (node) => {
        if (node?.id) {
            setList((prev) => prev.filter((x) => x['id'] !== node.id))
        }
    }

    const defaultSiteId = (): string =>
        (currentSite?.Type === 'department' ? currentSite?.Hosts?.[0] : currentSite?.ID) || ''

    return (
        <div className={className ? className : undefined}>
            {/*{Alert && (*/}
            {/*    <Alert severity={'warning'}>*/}
            {/*        <p>{Alert}</p>*/}
            {/*    </Alert>*/}
            {/*)}*/}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                {/*<h3 className="no-margin ml5" >Custom or External Links</h3>*/}
                <Typography variant='h6' component='div' fontWeight={300}>
                    Custom or External Links
                </Typography>
                <HelpTooltip
                    contents={[
                        "External Links are not created until they've been placed in the Tree and saved",
                        'External Links can be used to link to documents or websites other than your own (Include the full link https://..)',
                        'Custom Links can be used to link to pages that already have Navigation',
                        'External Links cannot have navigation attached to them'
                    ]}
                    /* @ts-ignore */
                    position={{ bottom: '25px', left: '60px', width: '450px', textAlign: 'left' }}
                />
            </div>
            <TextField
                label={'URL'}
                name={'url'}
                className='max-width'
                variant='standard'
                value={next?.url || ''}
                error={errors.url}
                onChange={setTextValue}
            />
            <TextField
                name={'label'}
                label={'Link Label'}
                className='max-width'
                variant='standard'
                value={next?.label || ''}
                error={errors.label}
                onChange={setTextValue}
            />

            <div className='flex-row' style={{ marginTop: '1rem' }}>
                <SiteSelectorForContent
                    Selected={next.sites}
                    OnChange={(v) => {
                        // TODO =>
                        //  If non-department, we must enforce that v includes currentSite - or else it could never get placed on the tree & created.
                        //  If department, we must only enforce that v is not empty OR we can just handle validation and allow empty with error for user.
                        //
                        console.log('SiteSelectorForContent::OnChange::', {
                            value: v,
                            next,
                            currentSite
                        })
                        if (currentSite?.Type === 'department') {
                            // if (!v.length) {
                            // v.push(...next.sites)
                            // }
                        } else {
                            const siteId = defaultSiteId()
                            if (!v.includes(siteId)) {
                                v.push(siteId)
                            }
                        }
                        setNext((p) => ({ ...p, sites: v }))
                    }}
                    HasError={Boolean(errors?.sites)}
                    Disabled={!evaluators.entityScopeAny(EntityScopeEnum.Page)}
                    ContentType={EntityScopeEnum.Page}
                />
            </div>
            <Button
                color='primary'
                style={{ width: '50%', margin: '0.35rem', marginLeft: 0 }}
                size='small'
                variant='outlined'
                onClick={createExternal}
            >
                Next
            </Button>
            {list.map((item) => (
                <ExternalNodeV2
                    key={item['id']}
                    node={item}
                    /* @ts-ignore */
                    customClass={'Navigation-TypeDepartment'}
                    Buttons={(p) => <ClearIcon onClick={() => deleteExternalNode(item)} />}
                />
            ))}
        </div>
    )
}
