import { isEqual, omit } from 'lodash'

export default function _isEqual(value: any, other: any, ignoreFields?: string[]) {
    if (ignoreFields?.length) {
        try {
            return isEqual(omit(value, ignoreFields), omit(other, ignoreFields))
        } catch (err) {
            console.error('_isEqual error', err)
        }
    }

    return isEqual(value, other)
}
