import { DepartmentQuery, SetState } from './department.service'
import { Button, InputAdornment, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { SiteSelectorForContent } from '../../common/components/selectors/SiteSelectorForContent'
import { EntityScopeEnum } from '../auth/entityScope'
import { defaultPageQuery } from '../../common/react-query'

interface DepartmentFiltersProps {
    state: DepartmentQuery
    setState: SetState
}

export function DepartmentFilters({ state, setState }: DepartmentFiltersProps) {
    return (
        <div className={'row'}>
            <div className={'col-xs-4'}>
                <TextField
                    placeholder={'Search'}
                    label={'Search'}
                    value={state?.search || ''}
                    onChange={(v) => {
                        const search = v?.target?.value || ''
                        setState?.((prev: any) => ({ ...prev, search }))
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position={'start'}>
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />
            </div>
            {/*<div className={'col-xs-3'}>*/}
            {/*    <TagsSelector contentType={'Site'} state={state} setState={setState} disabled />*/}
            {/*</div>*/}
            <div className={'col-xs-4'}>
                <SiteSelectorForContent
                    Selected={state?.sites || []}
                    ContentType={EntityScopeEnum.Department}
                    OnChange={(sites) => {
                        setState((prev) => ({ ...prev, sites }))
                    }}
                />
            </div>
            <div className={'col-xs-2'}>
                <Button
                    style={{ marginTop: '0.65rem' }}
                    onClick={() => setState({ ...defaultPageQuery, pageSize: 99 })}
                    color={'primary'}
                >
                    Reset Filters
                </Button>
            </div>
        </div>
    )
}
