import { useNavigate } from 'react-router-dom'
import { useStateWithStorage } from '../../common/storage.service'
import { deleteStructureQuery, StructureQuery, useStructuresQuery } from './queries'
import React, { useState } from 'react'
import { GridColDef } from '@mui/x-data-grid'
import { CellLine, CellWrapper, TwoLinesCell } from '../grid/cells/GridCells'
import { Alert, Grid, IconButton } from '@mui/material'
import { CustomMenu, CustomMenuItem } from '../../common/components/custom-context-menu/CustomMenu'
import CreateIcon from '@mui/icons-material/Create'
import DeleteIcon from '@mui/icons-material/Delete'
import { MoreHoriz } from '@mui/icons-material'
import { AddButton, ConfirmAction } from '../../common/components'
import SearchBar from '../../common/components/SearchBar'
import moment from 'moment'
import { useMutation } from '@tanstack/react-query'
import { notify } from '../../helpers'
import { guessErrorMessage } from '../../helpers/guessErrorMessage'
import PageContainerWithHeader from '../../common/components/PageContainerWithHeader'
import Button from '@mui/material/Button'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import { httpPost } from '../../common/client'
import { z } from 'zod'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import { useAppNavigation } from '../../app/useAppNavigation'
import { DataGridBase } from '../grid/DataGridBase'
import { StructureAPI } from '../../common/constants'
import { defaultPageQuery } from '../../common/react-query'

function StructureTable() {
    const [query, setQuery] = useStateWithStorage<StructureQuery>('structure-query', {
        ...defaultPageQuery,
        Search: '',
        Active: true
    })

    const { data, isLoading, error, refetch } = useStructuresQuery(query)

    const deleteMutation = useMutation({
        mutationFn: (id: string) => deleteStructureQuery(id)
    })

    const [isMigrating, setIsMigrating] = useState(false)

    const { navigateTo } = useAppNavigation()

    const columns: GridColDef[] = [
        {
            field: 'Name',
            headerName: 'Name',
            flex: 1,
            sortable: true,
            disableColumnMenu: true,
            renderCell: (params) => <TwoLinesCell l1={`${params.row.Name}`} l2={params.row.ID} />
        },
        {
            field: 'Authors',
            headerName: 'Authors',
            width: 300,
            sortable: true,
            disableColumnMenu: true,
            renderCell: (params) => <TwoLinesCell l1={`${params.row.CreatedBy}`} l2={params.row.UpdatedBy} />
        },
        {
            field: 'UpdatedAt',
            headerName: 'Last Modified',
            width: 200,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <CellWrapper>
                    <CellLine>{moment(params.row.UpdatedAt).format('YYYY-MM-DD HH:mm:ss') || '-'}</CellLine>
                </CellWrapper>
            )
        },
        {
            field: 'Menu',
            headerName: 'Actions',
            headerAlign: 'right',
            align: 'right',
            disableColumnMenu: true,
            filterable: false,
            width: 80,
            sortable: false,
            renderCell: (params) => {
                const [actionMenuAnchor, setActionMenuAnchor] = useState<HTMLElement | null>(null)
                const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false)

                function handleDeleteSetting() {
                    deleteMutation.mutate(params.row.ID, {
                        onSuccess: () => {
                            notify(`Success. Structure ${params.row.Name} was deleted.`, 'info')
                            refetch()
                            setConfirmDeleteDialogOpen(false)
                            setActionMenuAnchor(null)
                        },
                        onError: () => {
                            notify(`Error. Structure ${params.row.Name} was not deleted.`, 'error')
                        }
                    })
                }

                return (
                    <CellWrapper style={{ textAlign: 'right' }}>
                        <IconButton
                            size={'small'}
                            aria-haspopup={'true'}
                            onClick={(e) => {
                                setActionMenuAnchor(e.currentTarget)
                            }}
                        >
                            <MoreHoriz />
                        </IconButton>
                        <CustomMenu
                            key={params.row.ID}
                            anchorElement={actionMenuAnchor}
                            onClose={() => setActionMenuAnchor(null)}
                        >
                            <CustomMenuItem
                                key={params.row.ID}
                                text={'Edit'}
                                onClick={() => {
                                    navigateTo(`/structure/${params?.row.ID}`)
                                }}
                            >
                                <CreateIcon />
                            </CustomMenuItem>
                            <CustomMenuItem
                                key={`related-content-${params.row.ID}`}
                                text={'Related Content'}
                                onClick={() => {
                                    navigateTo(`/structure/${params?.row.ID}/related-content`)
                                }}
                            >
                                <FormatListNumberedIcon />
                            </CustomMenuItem>
                            <CustomMenuItem text={'Delete'} onClick={() => setConfirmDeleteDialogOpen(true)}>
                                <DeleteIcon />
                            </CustomMenuItem>
                        </CustomMenu>
                        <ConfirmAction
                            open={confirmDeleteDialogOpen}
                            title={`Delete "${params?.row.Name}"`}
                            text={'Are you sure you want to delete this structure?'}
                            handleClose={() => setConfirmDeleteDialogOpen(false)}
                            handleDisagree={() => setConfirmDeleteDialogOpen(false)}
                            handleAgree={() => handleDeleteSetting()}
                        />
                    </CellWrapper>
                )
            }
        }
    ]

    return (
        <PageContainerWithHeader
            title={'Structure'}
            topRightElement={
                <>
                    <Button
                        variant={'contained'}
                        disabled={isMigrating}
                        size={'medium'}
                        startIcon={<AutoFixHighIcon />}
                        sx={{ mr: 2 }}
                        onClick={() => {
                            setIsMigrating(true)
                            httpPost(`${StructureAPI}/convert-from-dct`, {}, z.string())
                                .then((msg) => {
                                    setIsMigrating(false)
                                    notify(msg || `Success. Migrated all structures.`, 'info')
                                    refetch()
                                })
                                .catch((e) => {
                                    setIsMigrating(false)
                                    notify(`Error. Failed to migrate structures. ` + guessErrorMessage(e), 'error')
                                })
                        }}
                    >
                        Migrate from DCT
                    </Button>
                    <Button
                        disabled={isMigrating}
                        variant={'contained'}
                        size={'medium'}
                        startIcon={<AutoFixHighIcon />}
                        color={'warning'}
                        sx={{ mr: 2 }}
                        onClick={() => {
                            setIsMigrating(true)
                            httpPost(`${StructureAPI}/migrate-all-to-structure`, {}, z.string())
                                .then((msg) => {
                                    setIsMigrating(false)
                                    notify(msg || `Success. Migrated all content.`, 'info')
                                    refetch()
                                })
                                .catch((e) => {
                                    setIsMigrating(false)
                                    notify(`Error. Failed to migrate all content. ` + guessErrorMessage(e), 'error')
                                })
                        }}
                    >
                        Migrate All Content
                    </Button>
                    <AddButton title={`add structure`} func={() => navigateTo(`/structure/create`)} />
                </>
            }
        >
            <Grid container spacing={2}>
                <Grid item md={6}>
                    <SearchBar
                        value={query.Search || ''}
                        onChange={(val) => setQuery((p) => ({ ...p, Search: val }))}
                    />
                </Grid>
                <Grid item md={12}>
                    {error && (
                        <Alert variant={'filled'} severity={'error'}>
                            Error: {guessErrorMessage(error)}
                        </Alert>
                    )}
                    {isLoading && <div>Loading...</div>}
                    {data && (
                        <DataGridBase
                            disableColumnFilter
                            disableDensitySelector
                            disableColumnSelector
                            columns={columns}
                            state={data}
                            setQuery={setQuery}
                        />
                    )}
                </Grid>
            </Grid>
        </PageContainerWithHeader>
    )
}

export default StructureTable
