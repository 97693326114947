import { useParams } from 'react-router-dom'
import { Alert, Button, Paper, Table, TableBody, TableCell, TableRow } from '@mui/material'
import React from 'react'
import { AccountGroups } from './AccountGroups'
import { Refresh } from '@mui/icons-material'
import { Account } from '../types'
import { BackButton } from '../../../common/components'
import { useAccountDetails } from '../queries'
import { guessErrorMessage } from '../../../helpers/guessErrorMessage'

export const ManageAccount = () => {
    const { accountID } = useParams()

    const q = useAccountDetails(accountID || '')

    return (
        <>
            <div className='flex-row-between' style={{ margin: 'auto auto 30px auto' }}>
                <h1 className='pages-header'>Manage Account</h1>
                <BackButton route={`/user-management/accounts`} label={'Manage Accounts'} />
            </div>

            {q.isLoading && <p>Loading...</p>}
            {q.isError && <Alert severity={'error'}>{guessErrorMessage(q.error)}</Alert>}
            {q.data && (
                <>
                    <Paper>
                        <Button startIcon={<Refresh />} onClick={() => q.refetch()}>
                            Refresh
                        </Button>

                        <Table>
                            <TableBody>
                                <TableRow hover>
                                    <TableCell variant={'head'}>ID</TableCell>
                                    <TableCell>{q.data.ID}</TableCell>
                                </TableRow>
                                <TableRow hover>
                                    <TableCell variant={'head'}>Email</TableCell>
                                    <TableCell>{q.data.Email}</TableCell>
                                </TableRow>
                                <TableRow hover>
                                    <TableCell variant={'head'}>Name</TableCell>
                                    <TableCell>
                                        {q.data.Firstname} {q.data.Lastname}
                                    </TableCell>
                                </TableRow>
                                <TableRow hover>
                                    <TableCell variant={'head'}>IsAdmin</TableCell>
                                    <TableCell>{q.data.IsAdmin ? 'Yes' : 'No'}</TableCell>
                                </TableRow>
                                <TableRow hover>
                                    <TableCell variant={'head'}>Status</TableCell>
                                    <TableCell>{q.data.Active ? 'Active' : 'Deactivated'}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Paper>

                    <AccountGroups
                        account={q.data as Account /* TODO: what is wrong here? Why account schema doesn't work? */}
                        onChange={q.refetch}
                    />
                </>
            )}
        </>
    )
}
