import React, { Fragment, useEffect, useMemo, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { documentService } from '../media/document/document.service'
import CircularProgress from '@mui/material/CircularProgress'
import WarningIcon from '@mui/icons-material/Warning'
import { IconButton, Typography } from '@mui/material'
import { guessErrorMessage } from '@/helpers/guessErrorMessage'

export const DctCollectionRow = (props) => {
    const {
        selectedSite,
        disabled,
        sectionCollectionItem,
        sectionComponents,
        editButtonOnClick,
        deleteButtonOnClick,
        moveUp,
        moveDown,
        index
    } = props
    const TITLE = 'title'
    const DESCRIPTION = 'description'
    const [isLoading, setIsLoading] = useState(true)
    const [hasError, setHasError] = useState(false)
    const [documentName, setDocumentName] = useState('')
    const fieldNames = Object.keys(sectionCollectionItem).filter((x) => x !== 'id')

    function getLabelFromName(name: string) {
        return sectionComponents?.find((sc) => sc.name == name)?.label
    }

    const { title, titleKey } = useMemo(() => {
        let result = { title: sectionCollectionItem?.title, titleKey: TITLE }

        if (!result?.title) {
            result = { title: sectionCollectionItem?.name, titleKey: 'name' }
        }

        if (!result?.title) {
            result = { title: sectionCollectionItem?.label, titleKey: 'label' }
        }

        if (!result?.title) {
            for (const key of fieldNames) {
                if (
                    key.toLowerCase().indexOf(TITLE) > -1 &&
                    sectionCollectionItem[key] &&
                    sectionCollectionItem[key].length > 0
                ) {
                    result = { title: sectionCollectionItem[key], titleKey: key }
                }
            }
        }

        // intentionally utilize the first property
        const firstFieldName = fieldNames[0]
        if (!result?.title) {
            if (typeof sectionCollectionItem[firstFieldName] === 'object') {
                // rich text or html
                if ('html' in sectionCollectionItem[firstFieldName]) {
                    result = { title: '</>', titleKey: 'Rich Text' }
                } else if ('alt' in sectionCollectionItem[firstFieldName]) {
                    result = { title: sectionCollectionItem[firstFieldName].alt, titleKey: firstFieldName }
                } else {
                    // unknown?
                    const unknownFirstProperty = Object.keys(sectionCollectionItem[firstFieldName])?.[0]
                    if (!unknownFirstProperty) {
                        result = { title: '', titleKey: firstFieldName }
                    } else {
                        result = {
                            title: sectionCollectionItem[firstFieldName].unknownFirstProperty,
                            titleKey: firstFieldName
                        }
                    }
                }
            } else {
                result = { title: sectionCollectionItem[firstFieldName], titleKey: firstFieldName }
            }
        }

        return result
    }, [documentName, sectionCollectionItem])

    const { description, descriptionKey } = useMemo(() => {
        let result = {
            description: sectionCollectionItem?.description,
            descriptionKey: 'description'
        }

        if (!result?.description) {
            result = { description: sectionCollectionItem?.text, descriptionKey: 'text' }
        }

        if (!result?.description) {
            for (const key of fieldNames) {
                if (key.toLowerCase().includes(DESCRIPTION) && sectionCollectionItem[key]?.length > 0) {
                    result = { description: sectionCollectionItem[key], descriptionKey: key }
                }
            }
        }

        if (!result?.description) {
            for (const key of fieldNames) {
                if (key == titleKey) continue

                if (typeof sectionCollectionItem[key] === 'object') {
                    // rich text or html
                    if ('html' in sectionCollectionItem[key]) {
                        result = { description: '</>', descriptionKey: 'Rich Text' }
                    } else if ('alt' in sectionCollectionItem[key]) {
                        result = { description: sectionCollectionItem[key].alt, descriptionKey: key }
                    } else {
                        // unknown?
                        const unknownFirstProperty = Object.keys(sectionCollectionItem[key])?.[0]
                        if (!unknownFirstProperty) {
                            result = { description: '', descriptionKey: key }
                        } else {
                            result = {
                                description: sectionCollectionItem[key].unknownFirstProperty,
                                descriptionKey: key
                            }
                        }
                    }
                } else if (sectionCollectionItem[key]?.length > 0) {
                    result = { description: sectionCollectionItem[key], descriptionKey: key }
                }
            }
        }

        return result
    }, [documentName, sectionCollectionItem])

    // TODO => (Tech Debt) => this needs to be refactored such that we have prioritized fields that are
    //  Selected should they have value - the current system is sluggish and silly but not scoped in this task

    // notes
    // sectionCollectionItem !== localState => localState updated to nil values after clicking ADD or UPDATE
    useEffect(() => {
        assignCatalogueValues()
    }, [sectionCollectionItem])

    const assignCatalogueValues = async () => {
        const documents = sectionComponents.filter((component) => component.type === 'document')
        for (const document of documents) {
            let state = sectionCollectionItem[document.name]
            if (state) {
                try {
                    const split = state.split('/')
                    const id = split[2]
                    const result = await documentService.getDocumentById(selectedSite, id)
                    if (result) {
                        setDocumentName(result.filename)
                    }
                } catch (err) {
                    console.error(err)
                    setHasError(true)
                    setDocumentName(`Error: ${guessErrorMessage(err)}`)
                }
            }
        }

        setIsLoading(false)
    }

    return (
        <div className='catalogue-line'>
            {isLoading ? (
                <CircularProgress
                    style={{
                        gridColumnStart: '4',
                        gridColumnEnd: 'span 2',
                        justifySelf: 'center'
                    }}
                />
            ) : (
                <Fragment>
                    <div className='catalogue-first flex-column'>
                        {!disabled && (
                            <Fragment>
                                <ArrowDropUpIcon className='cursor-pointer' onClick={() => moveUp(index)} />
                                <ArrowDropDownIcon className='cursor-pointer' onClick={() => moveDown(index)} />
                            </Fragment>
                        )}
                    </div>
                    <div className='catalogue-second'>
                        <Typography fontWeight='300'>{getLabelFromName(titleKey) || titleKey}:</Typography>
                        <Typography fontWeight='bold'>{title}</Typography>
                    </div>
                    <div className='catalogue-third'>
                        <Typography fontWeight='300' noWrap>
                            {!!documentName ? 'Document Name' : getLabelFromName(descriptionKey) || descriptionKey}
                        </Typography>
                        <Typography fontWeight='bold'>
                            {documentName || description?.slice(0, 26) + (description?.length > 26 ? '...' : '') || ''}
                        </Typography>
                    </div>
                    <div className='catalogue-fourth' style={{ display: 'flex' }}>
                        <IconButton title='Edit' onClick={() => editButtonOnClick?.()}>
                            <EditIcon className='cursor-pointer create-icon' />
                        </IconButton>
                        {!disabled && (
                            <IconButton title='Delete' onClick={() => deleteButtonOnClick?.()}>
                                <DeleteIcon className='cursor-pointer create-icon' />
                            </IconButton>
                        )}
                    </div>
                    {hasError && (
                        <div className='catalogue-fifth'>
                            <div className='tooltip'>
                                <WarningIcon style={{ color: '#f44336' }} />
                                <span className='tooltiptext tooltip-notch-none' style={{ width: '175px' }}>
                                    A document or folder was deleted and is no longer available - Please make a new
                                    selection
                                </span>
                            </div>
                        </div>
                    )}
                </Fragment>
            )}
        </div>
    )
}

// export default DctCollectionRow
