// TODO => This doesn't belong in this directory
//  Used in: DCT (Solo, DctCollection) & Sites
//  It should be moved to a more relevant place (likely components)

import React, { useEffect, useState } from 'react'
import './OpenGallery.css'
import ImageIcon from '@mui/icons-material/Image'
import { Button, Card, CardActionArea, CardActions, CardMedia, FormHelperText } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { MEDIA_BASE } from '../../../common/constants'

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
        minWidth: 172.5,
        margin: '0.65vh'
    },
    media: {
        height: 140
    },
    button: {
        margin: 'auto'
    },
    mlr: {
        marginLeft: '0.5em',
        marginRight: '0.5em'
    }
}))

export const OpenGallery = (props) => {
    const classes = useStyles()
    const {
        title,
        error,
        component,
        setCurrentComponent,
        disabled,
        value,
        removeSelectedMedia,
        rootStyles,
        helperText
    } = props
    const [image, setImage] = useState('')
    useEffect(() => {
        if (value && typeof value === 'string') {
            setImage(`${MEDIA_BASE}${value}`)
        } else if (value && value.src) {
            setImage(`${MEDIA_BASE}${value.src}`)
        }
    }, [value])

    const handleCurrentComponent = () => {
        if (disabled) return
        setCurrentComponent(component)
        props.handleOpen()
    }

    return (
        <div>
            {value ? (
                <>
                    <Card style={rootStyles ? rootStyles : {}} className={classes.root}>
                        <CardActionArea disabled={disabled} onClick={handleCurrentComponent}>
                            <CardMedia className={classes.media} component={'img'} image={image} title={image} />
                        </CardActionArea>
                        <CardActions>
                            <Button
                                disabled={disabled}
                                size='small'
                                color='primary'
                                className={classes.button}
                                onClick={removeSelectedMedia}
                            >
                                Delete
                            </Button>
                        </CardActions>
                    </Card>
                    {props.helperText && <FormHelperText>{helperText}</FormHelperText>}
                </>
            ) : (
                <div>
                    {error ? (
                        <div
                            className='open-gallery-error flex-row'
                            style={disabled ? { border: '0.5px solid grey' } : {}}
                            onClick={handleCurrentComponent}
                        >
                            <p className={clsx('error-red', classes.mlr)}>{title}</p>
                            <ImageIcon className='error-red' />
                            {props.helperText && <FormHelperText>{helperText}</FormHelperText>}
                        </div>
                    ) : (
                        <>
                            <div
                                className='open-gallery flex-row'
                                style={disabled ? { border: '0.5px solid grey' } : {}}
                                onClick={handleCurrentComponent}
                            >
                                <p className={clsx('material-blue', classes.mlr)}>Add an image</p>
                                <ImageIcon className='material-blue' />
                            </div>
                            {props.helperText && <FormHelperText>{helperText}</FormHelperText>}
                        </>
                    )}
                </div>
            )}
        </div>
    )
}
