import React, { useEffect, useState } from 'react'
import { useAppContext } from '@/pkgs/auth/atoms'

export const storage = {
    setItem<T>(key: string, value: T) {
        const v = JSON.stringify(value)
        sessionStorage.setItem(key, v)
        localStorage.setItem(key, v)
    },
    getItem<T>(key: string): T | null {
        return JSON.parse(sessionStorage.getItem(key) || localStorage.getItem(key) || 'null') as T
    }
}

export const useStateWithStorage = <T>(key: string, defaultValue: T): [T, React.Dispatch<React.SetStateAction<T>>] => {
    const appContext = useAppContext()
    const tenantKey = `${appContext.tenantID}-${key}`

    // only if both default value and storage value are objects { ...defaultValue, ...storageValue }
    const storedValue = storage.getItem<T>(tenantKey)
    let initialValue = defaultValue

    if (typeof defaultValue === 'object' && typeof storedValue === 'object') {
        initialValue = { ...initialValue, ...storedValue }
    }

    const [value, setValue] = useState<T>(initialValue)

    useEffect(() => {
        // storing input name
        storage.setItem(tenantKey, value)
    }, [key, value])

    return [value, setValue]
}
