import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import React, { useEffect, useState } from 'react'
import moment, { Moment } from 'moment-timezone'
import { EventTimePicker } from './EventDateTimePicker'
import { siteService } from '../../pkgs/system/site/site.service'

export const useTimezone = () => {
    const [timezone, setTimezone] = useState('')

    useEffect(() => {
        siteService.getTimezone().then((r) => {
            console.log(r)
            setTimezone(r)
        })
    }, [])

    return timezone
}

interface EventSchedule {
    startdate: string | null
    enddate: string | null
    isAllDay: boolean
}

interface EventDateTimePickerV2Props {
    disabled?: boolean
    value: EventSchedule
    onChange: (e: EventSchedule) => void
    timeStep?: number
    error?: string
}

export function EventDateTimePickerV2({ disabled, value, onChange, timeStep = 15, error }: EventDateTimePickerV2Props) {
    const timezone = useTimezone()

    const fromString = (str: string) => withAppropriateOffset(str, value.isAllDay, timezone)
    const minusOneDay = (date: Moment) => date.clone().subtract({ days: 1 })

    return (
        <Box display='flex' flexDirection='column' gap='12px'>
            <FormLabel error={!!error}>Start Date:</FormLabel>
            <Box display='flex' gap='12px'>
                <DatePicker
                    disabled={disabled}
                    value={(value.startdate && fromString(value.startdate)) || null}
                    slotProps={{
                        textField: { size: 'small', variant: 'standard' }
                    }}
                    onChange={(val: moment.Moment | null) => {
                        onChange(
                            serializeEventSchedule({
                                ...value,
                                startdate: val?.isValid()
                                    ? (value.startdate && fromString(value.startdate)
                                          ? setMomentDateDay(fromString(value.startdate), val)
                                          : val
                                      )?.toISOString()
                                    : null
                            })
                        )
                    }}
                />
                {!value.isAllDay && !disabled && (
                    <EventTimePicker
                        id={'event-startDate'}
                        timeStep={timeStep}
                        startDate={(value.startdate && fromString(value.startdate)) || null}
                        timeFieldValue={(value.startdate && fromString(value.startdate)) || null}
                        onChange={(val) => {
                            onChange(
                                serializeEventSchedule({
                                    ...value,
                                    startdate:
                                        (value.startdate && fromString(value.startdate)
                                            ? setMomentDateTime(fromString(value.startdate), val)
                                            : val
                                        )?.toISOString() || null
                                })
                            )
                        }}
                    />
                )}
            </Box>
            <FormLabel error={!!error}>End Date:</FormLabel>
            <Box display='flex' gap='12px'>
                <DatePicker
                    disabled={disabled}
                    value={
                        (value.enddate &&
                            (value.isAllDay ? minusOneDay(fromString(value.enddate)) : fromString(value.enddate))) ||
                        null
                    }
                    slotProps={{
                        textField: { size: 'small', variant: 'standard' }
                    }}
                    onChange={(val: moment.Moment | null) => {
                        onChange(
                            serializeEventSchedule({
                                ...value,
                                enddate: val?.isValid()
                                    ? (value.enddate && fromString(value.enddate)
                                          ? setMomentDateDay(fromString(value.enddate), val)
                                          : val
                                      )?.toISOString()
                                    : null
                            })
                        )
                    }}
                />
                {!value.isAllDay && !disabled && (
                    <EventTimePicker
                        id={'event-endDate'}
                        timeStep={timeStep}
                        startDate={(value.startdate && fromString(value.startdate)) || undefined}
                        endDate={(value.enddate && fromString(value.enddate)) || undefined}
                        timeFieldValue={(value.enddate && fromString(value.enddate)) || null}
                        disablePast={
                            !!(
                                value.enddate &&
                                value.startdate &&
                                fromString(value.startdate)?.isSame(fromString(value.enddate), 'day')
                            )
                        }
                        showHelperText
                        onChange={(e) => {
                            if (value.enddate && fromString(value.enddate)) {
                                const updated = setMomentDateTime(fromString(value.enddate), e)
                                onChange(
                                    serializeEventSchedule({
                                        ...value,
                                        enddate: (updated ? updated : e)?.toISOString() || null
                                    })
                                )
                            } else {
                                if (
                                    value.enddate &&
                                    value.startdate &&
                                    fromString(value.startdate) &&
                                    setMomentDateTime(fromString(value.enddate), e).isBefore(
                                        value.startdate && fromString(value.startdate)
                                    )
                                ) {
                                    onChange(
                                        serializeEventSchedule({
                                            ...value,
                                            enddate:
                                                value.startdate &&
                                                fromString(value.startdate).add({ day: 1 }).toISOString()
                                        })
                                    )
                                } else if (value.startdate && fromString(value.startdate)) {
                                    onChange(
                                        serializeEventSchedule({
                                            ...value,
                                            enddate: setMomentDateTime(fromString(value.startdate), e).toISOString()
                                        })
                                    )
                                } else {
                                    onChange(
                                        serializeEventSchedule({
                                            ...value,
                                            enddate: null
                                        })
                                    )
                                }
                            }
                        }}
                    />
                )}
            </Box>
            {!!error && <FormHelperText error>{error}</FormHelperText>}

            <FormControl variant='standard' component='fieldset' disabled={disabled}>
                <FormLabel component='legend'>Your timezone: {timezone}</FormLabel>

                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={disabled}
                                checked={value.isAllDay || false}
                                onChange={(e) =>
                                    onChange(serializeEventSchedule({ ...value, isAllDay: e.target.checked }))
                                }
                                name='first'
                            />
                        }
                        label='All Day Event'
                    />
                </FormGroup>
            </FormControl>
        </Box>
    )
}

function setMomentDateTime(date: Moment, dateWithNewTime: Date | Moment | string) {
    const momentDateWithNewTime = moment(dateWithNewTime)
    const res = date.clone().set({
        hour: momentDateWithNewTime.hour(),
        minute: momentDateWithNewTime.minute()
    })
    return res
}

function setMomentDateDay(date: Moment, dateWithNewDay: Date | Moment | string) {
    const momentDateWithNewDay = moment(dateWithNewDay)
    return date.clone().set({
        date: momentDateWithNewDay.date(),
        month: momentDateWithNewDay.month(),
        year: momentDateWithNewDay.year()
    })
}

function withAppropriateOffset(date, isAllDay, timezone) {
    return moment?.(date).tz(isAllDay ? 'UTC' : timezone)
}

function convertToUTCAtZeroHours(date) {
    if (!(date instanceof Date)) {
        date = new Date(date)
    }
    const fullYear = date.getUTCFullYear()
    const month = date.getUTCMonth()
    const utcDate = date.getUTCDate()
    const asUTC = Date.UTC(fullYear, month, utcDate)
    return new Date(asUTC)
}

function serializeEventSchedule({ isAllDay = false, startdate, enddate }) {
    startdate = startdate || moment().toISOString()
    enddate = enddate || (isAllDay ? moment().add({ hour: 1 }).toISOString() : moment().add({ day: 1 }).toISOString())

    if (isAllDay) {
        startdate = convertToUTCAtZeroHours(startdate)
        const jsEndDate = convertToUTCAtZeroHours(enddate) || convertToUTCAtZeroHours(startdate)
        enddate = new Date(jsEndDate.setDate(jsEndDate.getDate() + 1))
    }
    return {
        isAllDay,
        startdate: moment(startdate).toISOString(),
        enddate: moment(enddate).toISOString()
    }
}
