import { entityScope, EntityScopeEnum } from '../pkgs/auth/entityScope'
import { Navigate } from 'react-router-dom'
import { FragmentsGrid } from '../pkgs/content/fragments/FragmentsGrid'
import DepartmentManager from '../pkgs/departments/DepartmentsManager'
import ResourceEditor from '../common/editor/ResourceEditor'
import { Documents } from '../pkgs/media/document/Documents'
import { MediaExplorer } from '../pkgs/media/image/MediaExplorer'
import { MediaUploader } from '../pkgs/media/image/MediaUploader'
import NavigationLanding from '../pkgs/navigation/NavigationLanding'
import { CombinedNewsEvents } from '../pkgs/content/grids/CombinedNewsEvents'
import { AuditRecordsGrid, IssuesGrid, RelaysGrid, SubscribersGrid, TopicsGrid } from '../pkgs/notifications'
import { ItemsEditor } from '../pkgs/ordered-lists/ItemsEditor'
import { ListsGrid } from '../pkgs/ordered-lists/ListsGrid'
import CreateSettings from '../pkgs/system/settings/CreateSettings'
import EditSettings from '../pkgs/system/settings/EditSettings'
import EditSiteSettings from '../pkgs/system/site/site-settings/EditSiteSettings'
import SettingsTable from '../pkgs/system/settings/SettingsTable'
import CreateStructure from '../pkgs/structure/CreateStructure'
import EditStructure from '../pkgs/structure/EditStructure'
import StructureTable from '../pkgs/structure/StructureTable'
import Transportation from '../pkgs/transportation/Transportation'
import { AccountsGrid } from '../pkgs/user-management/accounts/AccountsGrid'
import { ManageAccount } from '../pkgs/user-management/accounts/ManageAccount'
import { CreateSecurityGroup } from '../pkgs/user-management/groups/CreateSecurityGroup'
import { EditSecurityGroup } from '../pkgs/user-management/groups/EditSecurityGroup'
import { SecurityGroupsGrid } from '../pkgs/user-management/groups/SecurityGroupsGrid'
import Roles from '../pkgs/user-management/roles/Roles'
import RolesPermissions from '../pkgs/user-management/roles/RolesPermissions'
import Alerts from '../pkgs/content/grids/Alerts'
import { Dashboard } from '../pkgs/dashboard/Dashboard'
import Pages from '../pkgs/content/grids/Pages'
import React, { ReactNode, useEffect } from 'react'
import { useAppContext } from '../pkgs/auth/atoms'
import HomeIcon from '@mui/icons-material/Home'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import RssFeedIcon from '@mui/icons-material/RssFeed'
import MailIcon from '@mui/icons-material/Mail'
import DashboardIcon from '@mui/icons-material/Dashboard'
import ViewListIcon from '@mui/icons-material/ViewList'
import NavigationIcon from '@mui/icons-material/Navigation'
import PermMediaIcon from '@mui/icons-material/PermMedia'
import AssignmentIcon from '@mui/icons-material/Assignment'
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus'
import WarningIcon from '@mui/icons-material/Warning'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import SettingsIcon from '@mui/icons-material/Settings'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import { isTestDomain } from './AppLeftDrawer'
import { TemplateEditorV2 } from '../pkgs/content/templates/TemplateEditor'
import { PageContainer } from '../common/components/PageContainer'
import { TemplatesGrid } from '../pkgs/content/grids/TemplatesGrid'
import { Sites } from '../pkgs/system/site/Sites'
import { Tags } from '../pkgs/system/tags/Tags'
import { Resources } from '../pkgs/content/grids/Resources'
import { BackButton } from '../common/components'
import { useReleaseNotesIsRead } from '../pkgs/dashboard/ReleaseNotes'
import usePath from './usePath'
import { Redirect } from './Redirect'
import { ContentType } from '../pkgs/content/types'
import { LexicalPlayground } from '@/pkgs/lexical-editor/LexicalPlayground'
import { ContentExplorerPage } from '@/pkgs/content/explorer/ContentExplorerPage'
import { ContentEditor } from '@/pkgs/content/editor/ContentEditor'
import { RelatedContentGrid } from '@/pkgs/structure/RelatedContentGrid'

// pagesIndex helper at the bottom

export interface PageRoute {
    path: string
    title: string
    authorization: {
        requires: 'auth' | 'admin' | null
        entityScope?: entityScope[] | entityScope | null
    }
    strict?: boolean // StrictMode
    element: () => JSX.Element
}

export const routes: PageRoute[] = [
    {
        path: '*',
        title: 'Unknown page',
        authorization: {
            requires: null
        },
        element: () => <Navigate to='/' replace />
    },
    {
        path: '/',
        title: 'Content Manager',
        authorization: {
            requires: 'auth'
        },
        strict: true,
        element: () => <Dashboard />
    },
    {
        path: '/system/sites',
        title: 'Sites',
        authorization: {
            requires: 'auth',
            entityScope: [EntityScopeEnum.Site, EntityScopeEnum.InstagramSettings]
        },
        element: () => <Sites />
    },
    {
        path: '/structure/:structureID/related-content',
        title: 'Edit Structure',
        authorization: {
            requires: 'auth',
            entityScope: [EntityScopeEnum.News, EntityScopeEnum.Page, EntityScopeEnum.Event, EntityScopeEnum.Alert]
        },
        element: () => <RelatedContentGrid />
    },
    {
        path: '/content-editor/:id',
        title: 'Edit Content',
        authorization: {
            requires: 'auth'
        },
        element: () => <ContentEditor />
    },
    {
        path: '/system/sites/edit/:id',
        title: 'Edit Sites',
        authorization: {
            requires: 'auth',
            entityScope: EntityScopeEnum.InstagramSettings
        },
        strict: true,
        element: () => <EditSiteSettings />
    },
    {
        path: '/system/tags',
        title: 'Tags',
        authorization: {
            requires: 'auth',
            entityScope: EntityScopeEnum.Tag
        },
        element: () => <Tags />
    },
    {
        path: '/system/templates',
        title: 'Templates',
        authorization: {
            requires: 'auth',
            entityScope: EntityScopeEnum.Resource
        },
        element: () => (
            <PageContainer>
                <TemplatesGrid />
            </PageContainer>
        )
    },
    {
        path: '/system/resources',
        title: 'Resources',
        authorization: {
            requires: 'auth',
            entityScope: EntityScopeEnum.Resource
        },
        element: () => <Resources />
    },
    {
        path: '/system/departments',
        title: 'Departments',
        authorization: {
            requires: 'auth',
            entityScope: EntityScopeEnum.Site
        },
        strict: true,
        element: () => <DepartmentManager />
    },
    {
        path: '/system/settings',
        title: 'Settings',
        authorization: {
            requires: 'auth',
            entityScope: EntityScopeEnum.InstagramSettings
        },
        strict: true,
        element: () => <SettingsTable />
    },
    {
        path: '/system/settings/create',
        title: 'Create Settings',
        authorization: {
            requires: 'auth',
            entityScope: EntityScopeEnum.InstagramSettings
        },
        strict: true,
        element: () => <CreateSettings />
    },
    {
        path: '/system/settings/edit/:id',
        title: 'Edit Settings',
        authorization: {
            requires: 'auth',
            entityScope: EntityScopeEnum.InstagramSettings
        },
        strict: true,
        element: () => <EditSettings />
    },
    {
        path: '/pages',
        title: 'Pages',
        authorization: {
            requires: 'auth',
            entityScope: EntityScopeEnum.Page
        },
        strict: true,
        element: () => <Pages />
    },
    {
        path: '/news',
        title: 'News',
        authorization: {
            requires: 'auth',
            entityScope: EntityScopeEnum.News
        },
        strict: true,

        element: () => <CombinedNewsEvents contentType={ContentType.News} />
    },
    {
        path: '/events',
        title: 'Events',
        authorization: {
            requires: 'auth',
            entityScope: EntityScopeEnum.Event
        },
        strict: true,
        element: () => <CombinedNewsEvents contentType={ContentType.Event} />
    },
    // images
    {
        path: '/media-explorer',
        title: 'Images',
        authorization: {
            requires: 'auth',
            entityScope: EntityScopeEnum.Image
        },
        strict: true,
        element: () => <MediaExplorer />
    },
    {
        path: '/media-uploader',
        title: 'Media Uploader',
        authorization: {
            requires: 'auth',
            entityScope: EntityScopeEnum.Image
        },
        strict: true,
        element: () => <MediaUploader HeaderSlot={<BackButton route={`/media-explorer`} label={'View Images'} />} />
    },
    {
        path: '/transportation',
        title: 'Transportation',
        authorization: {
            requires: 'auth',
            entityScope: EntityScopeEnum.BusStatus
        },
        strict: true,
        element: () => <Transportation />
    },
    {
        path: '/alerts',
        title: 'Alerts',
        authorization: {
            requires: 'auth',
            entityScope: EntityScopeEnum.Alert
        },
        strict: true,
        element: () => <Alerts />
    },
    {
        path: '/navigation',
        title: 'Navigation',
        authorization: {
            requires: 'auth',
            entityScope: EntityScopeEnum.Navigation
        },
        strict: true,
        element: () => <NavigationLanding />
    },
    {
        path: '/documents',
        title: 'Documents',
        authorization: {
            requires: 'auth',
            entityScope: EntityScopeEnum.Document
        },
        strict: true,
        element: () => <Documents />
    },
    {
        path: '/ordered-lists',
        title: 'Ordered Lists',
        authorization: {
            requires: 'auth',
            entityScope: EntityScopeEnum.List
        },
        strict: true,
        element: () => <ListsGrid />
    },
    {
        path: '/fragments',
        title: 'Fragments',
        authorization: {
            requires: 'auth',
            entityScope: EntityScopeEnum.Fragment
        },
        strict: true,
        element: () => <FragmentsGrid />
    },
    {
        path: '/ordered-lists/:id/items',
        title: 'Ordered Lists',
        authorization: {
            requires: 'auth',
            entityScope: EntityScopeEnum.List
        },
        strict: true,
        element: () => <ItemsEditor />
    },
    {
        path: '/template-editor/:id',
        title: 'Template Editor',
        authorization: {
            requires: 'auth',
            entityScope: EntityScopeEnum.Resource
        },
        strict: true,
        element: () => <TemplateEditorV2 />
    },
    {
        path: '/resource/:id',
        title: 'Resource Editor',
        authorization: {
            requires: 'auth',
            entityScope: EntityScopeEnum.Resource
        },
        strict: true,
        element: () => <ResourceEditor />
    },
    {
        path: '/user-management/accounts/:accountID',
        title: 'Manage Account',
        authorization: {
            requires: 'auth'
        },
        strict: true,
        element: () => <ManageAccount />
    },
    {
        path: '/user-management/accounts',
        title: 'Accounts',
        authorization: {
            requires: 'auth'
        },
        strict: true,
        element: () => <AccountsGrid />
    },
    {
        path: '/user-management/security-groups',
        title: 'Security Groups',
        authorization: {
            requires: 'admin'
        },
        strict: true,
        element: () => <SecurityGroupsGrid />
    },
    {
        path: '/user-management/security-groups/create',
        title: 'Create Security Group',
        authorization: {
            requires: 'admin'
        },
        strict: true,
        element: () => <CreateSecurityGroup />
    },
    {
        path: '/user-management/security-groups/edit/:id',
        title: 'Edit Security Group',
        authorization: {
            requires: 'admin'
        },
        strict: true,
        element: () => <EditSecurityGroup />
    },
    {
        path: '/user-management/roles',
        title: 'Roles',
        authorization: {
            requires: 'admin'
        },
        strict: true,
        element: () => <Roles />
    },
    {
        path: '/user-management/roles/:id',
        title: 'Edit Role',
        authorization: {
            requires: 'admin'
        },
        strict: true,
        element: () => <RolesPermissions />
    },
    {
        path: '/issues',
        title: 'Issues',
        authorization: {
            requires: 'admin',
            entityScope: EntityScopeEnum.Notification
        },
        strict: true,
        element: () => <IssuesGrid />
    },
    {
        path: '/audit-records',
        title: 'Audit Records',
        authorization: {
            requires: 'admin',
            entityScope: EntityScopeEnum.Notification
        },
        strict: true,
        element: () => <AuditRecordsGrid />
    },
    {
        path: '/subscribers',
        title: 'Subscribers',
        authorization: {
            requires: 'admin',
            entityScope: EntityScopeEnum.Notification
        },
        strict: true,
        element: () => <SubscribersGrid />
    },
    {
        path: '/relays',
        title: 'Relays',
        authorization: {
            requires: 'admin',
            entityScope: EntityScopeEnum.Notification
        },
        strict: true,
        element: () => <RelaysGrid />
    },
    {
        path: '/topics',
        title: 'Topics',
        authorization: {
            requires: 'admin',
            entityScope: EntityScopeEnum.Notification
        },
        strict: true,
        element: () => <TopicsGrid />
    },
    {
        path: '/structure',
        title: 'Structure',
        authorization: {
            requires: 'admin'
        },
        strict: true,
        element: () => <StructureTable />
    },
    {
        path: '/structure/:id',
        title: 'Edit Structure',
        authorization: {
            requires: 'admin'
        },
        strict: true,
        element: () => <EditStructure />
    },
    {
        path: '/structure/create',
        title: 'Create Structure',
        authorization: {
            requires: 'admin'
        },
        strict: true,
        element: () => <CreateStructure />
    },
    /**
     * Deprecated Editor Paths
     * - Old Page, News, Event, Template, and Resource editors below
     * */
    {
        path: '/resource-editor',
        title: 'Resource Editor',
        authorization: {
            requires: 'auth',
            entityScope: EntityScopeEnum.Resource
        },
        strict: true,
        element: () => <Redirect to={'/resource/:id'} />
        // element: () => <ResourceEditor />
    },
    {
        path: '/template/:id',
        title: 'Template Editor',
        authorization: {
            requires: 'auth',
            entityScope: EntityScopeEnum.Resource
        },
        strict: true,
        element: () => <Redirect to={'/template-editor/:id'} />
        // element: () => <TemplateEditorV2 />
    },
    {
        path: '/editor',
        title: 'Template Editor',
        authorization: {
            requires: 'auth',
            entityScope: EntityScopeEnum.Resource
        },
        strict: true,
        element: () => <Redirect to={'/template-editor/:id'} />
        // element: () => <TemplateEditorV2 />
    },
    {
        path: '/newseditor',
        title: 'News Editor',
        authorization: {
            requires: 'auth',
            entityScope: EntityScopeEnum.News
        },
        strict: true,
        element: () => <Redirect to={'/content-editor/:id'} />
        // element: () => <NewsEditor />
    },
    {
        path: '/article/:id',
        title: 'News Editor',
        authorization: {
            requires: 'auth',
            entityScope: EntityScopeEnum.News
        },
        strict: true,
        element: () => <Redirect to={'/content-editor/:id'} />
    },
    {
        path: '/events-editor',
        title: 'Event Editor',
        authorization: {
            requires: 'auth',
            entityScope: EntityScopeEnum.Event
        },
        strict: true,
        // element: () => <EventsEditor />
        element: () => <Redirect to={'/content-editor/:id'} />
    },
    {
        path: '/event/:id',
        title: 'Event Editor',
        authorization: {
            requires: 'auth',
            entityScope: EntityScopeEnum.Event
        },
        strict: true,
        element: () => <Redirect to={'/content-editor/:id'} />
    },
    {
        path: '/page/:id',
        title: 'Page editor',
        authorization: {
            requires: 'auth'
        },
        strict: true,
        // element: () => <PageEditor />
        element: () => <Redirect to={'/content-editor/:id'} />
    },
    {
        path: '/ckeditor',
        title: 'Page Editor',
        authorization: {
            requires: 'auth'
        },
        strict: true,
        // element: () => <PageEditor />
        element: () => <Redirect to={'/content-editor/:id'} />
    },
    {
        path: '/dct/:id',
        title: 'Dct Editor',
        authorization: {
            requires: 'auth'
        },
        strict: true,
        element: () => <Redirect to={'/content-editor/:id'} />
    },
    {
        path: '/dct',
        title: 'Dct Editor',
        authorization: {
            requires: 'auth'
        },
        strict: true,
        element: () => <Redirect to={'/content-editor/:id'} />
    },
    {
        path: `/system/export-content`,
        title: 'Export Content',
        authorization: {
            requires: 'auth'
        },
        strict: true,
        element: () => <ContentExplorerPage />
    },
    {
        path: '/lexical-playground',
        title: 'Lexical Playground',
        authorization: {
            requires: 'auth'
        },
        strict: true,
        element: () => <LexicalPlayground />
    }
]

export const pagesIndex: Record<string, PageRoute> = routes.reduce(
    (acc, route) => {
        return {
            ...acc,
            [route.path]: route
        }
    },
    {} as Record<string, any>
)

export interface DrawerItem {
    path: string | null // null = divider
    customLabel?: string // defaults to pagesIndex[path].title

    // set to false to hide
    // entity scope automatically referenced inside drawer renderer
    enabled?: boolean
    icon?: () => ReactNode
    _children?: DrawerItem[]
    isUnread?: boolean
}

export function useDrawerItems() {
    const evaluators = useAppContext()
    const { pageId } = usePath()
    const { isRead: releaseNotesIsRead, read: readReleaseNotes } = useReleaseNotesIsRead()

    useEffect(() => {
        if (pageId == '') {
            readReleaseNotes()
        }
    }, [pageId])

    const drawerItems: (DrawerItem | null)[] = [
        {
            path: '/',
            customLabel: 'Dashboard',
            icon: () => <HomeIcon />,
            isUnread: !releaseNotesIsRead
        },
        {
            path: '/pages',
            icon: () => <FileCopyIcon />
        },
        {
            path: '/news',
            icon: () => <RssFeedIcon />
        },
        {
            path: '/events',
            icon: () => <MailIcon />
        },
        {
            path: '/fragments',
            icon: () => <DashboardIcon />
        },
        {
            path: '/ordered-lists',
            icon: () => <ViewListIcon />
        },
        {
            path: '/navigation',
            icon: () => <NavigationIcon />
        },
        null, // divider
        {
            path: '/media-explorer',
            icon: () => <PermMediaIcon />
        },
        {
            path: '/documents',
            icon: () => <AssignmentIcon />
        },
        {
            path: '/transportation',
            icon: () => <DirectionsBusIcon />
        },
        {
            path: '/alerts',
            icon: () => <WarningIcon />
        },
        {
            path: '/structure',
            enabled: evaluators.isAdmin(),
            icon: () => <AccountTreeIcon />
        },
        {
            path: null,
            enabled: evaluators.isValid(),
            customLabel: 'System',
            icon: () => <SettingsIcon />,
            _children: [
                {
                    path: '/system/sites',
                    enabled:
                        evaluators.entityScopeAny(EntityScopeEnum.Site) ||
                        evaluators.entityScopeAny(EntityScopeEnum.InstagramSettings)
                },
                {
                    path: '/system/tags',
                    enabled: evaluators.entityScopeAny(EntityScopeEnum.Tag)
                },
                {
                    path: '/system/templates',
                    enabled: evaluators.entityScopeAny(EntityScopeEnum.Resource)
                },
                {
                    path: '/system/resources',
                    enabled:
                        evaluators.entityScopeAny(EntityScopeEnum.Resource) && !evaluators.isCurrentSiteDepartment()
                },
                {
                    path: '/system/departments',
                    enabled: evaluators.isAdmin()
                },
                {
                    path: '/system/settings',
                    enabled:
                        evaluators.entityScopeAny(EntityScopeEnum.InstagramSettings) ||
                        evaluators.entityScopeAny(EntityScopeEnum.Settings)
                },
                {
                    path: '/system/export-content',
                    enabled: evaluators.entityScopeAny('cm.content')
                }
            ]
        },
        {
            path: null,
            enabled: isTestDomain() && evaluators.isAdmin(),
            customLabel: 'Notifications',
            icon: () => <MailIcon />,
            _children: [
                {
                    path: '/subscribers'
                },
                {
                    path: '/issues'
                },
                {
                    path: '/topics'
                },
                {
                    path: '/relays'
                },
                {
                    path: '/audit-records'
                }
            ]
        },
        {
            path: null,
            enabled: evaluators.isAccountAdmin(),
            customLabel: 'User Management',
            icon: () => <ManageAccountsIcon />,
            _children: [
                {
                    path: '/user-management/accounts',
                    enabled: evaluators.isAccountAdmin()
                },
                {
                    path: '/user-management/security-groups',
                    enabled: evaluators.isAdmin()
                },
                {
                    path: '/user-management/roles',
                    enabled: evaluators.isAdmin()
                }
            ]
        }
    ]

    return drawerItems
}
