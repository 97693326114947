import { useMutation, useQuery } from '@tanstack/react-query'
import { httpDelete, httpGet, httpPatch, httpPost, httpPut } from '../../common/client'
import { Content, content, contentResult, ContentType } from './types'
import { createBatcher, keyResolver, windowScheduler } from '../../helpers/batcher/batcher'
import { z } from 'zod'
import { notify } from '../../helpers'
import { guessErrorMessage } from '../../helpers/guessErrorMessage'
import { baseQueryConfig, cancelOn401, PagingQuery, SortingQuery } from '../../common/react-query'
import { publishStatus } from './editor/ContentEditorSaveCard'
import { BASE, ContentAPIV2 } from '../../common/constants'

export const useContentDetailsQuery = (id: string) => {
    const query = useQuery({
        ...baseQueryConfig,
        queryKey: ['content-details-query', id],
        queryFn: async () => httpGet(`${BASE}/api/v2/content/${id}`, {}, content)
    })

    return query
}

export const useBatchedContentDetails = (id: string) =>
    useQuery(['content-details-query', id], async () => contentDetailsBatched.fetch(id))

const contentDetailsBatched = createBatcher<Content[], string, Content>({
    fetcher: async (ids: string[]) => {
        const result = await httpGet(ContentAPIV2, { ids: ids, pageSize: 100 }, contentResult)
        return result.Rows
    },
    resolver: keyResolver('ID'),
    scheduler: windowScheduler(100)
})

export interface ContentQueryParams extends PagingQuery, SortingQuery {
    Search?: string
    ContentTypes?: ContentType[]
    Tags?: string[]
    Departments?: string[]
    Sites?: string[]
    StructureID?: string | null
    Status?: publishStatus | ''
    Inactive?: boolean
    SiteOnly?: boolean
}

export function useContentQuery(q: ContentQueryParams) {
    const query = useQuery({
        ...baseQueryConfig,
        queryKey: ['content-query-v2', q],
        queryFn: async () => httpGet(ContentAPIV2, q, contentResult)
    })

    return query
}

export function createContentQuery(content: Content) {
    return httpPost(ContentAPIV2, content, z.string())
}

export function deleteContentQuery(id: string) {
    return httpDelete(`${ContentAPIV2}/${id}`)
}

export function restoreContentQuery(id: string) {
    return httpPatch(`${ContentAPIV2}/${id}/restore`, null)
}

export const useContentQueries = (id: string) => {
    const fetcher = useQuery({
        refetchOnWindowFocus: false,
        keepPreviousData: false,
        retry: cancelOn401,
        cacheTime: 0,
        queryKey: ['content-query', id],
        queryFn: async () => httpGet(`${BASE}/api/v2/content/${id}`, {}, content)
    })

    const updater = useMutation({
        mutationFn: (content: Content) => httpPut(`${ContentAPIV2}/${content.ID}`, content),
        onSuccess: (data, variables, context) => {
            fetcher.refetch()
            notify(`Success! ${variables?.Title} updated`, 'info')
        },
        onError: (err: any, variables) => {
            const errorMessage = guessErrorMessage(err)
            notify(`Oops! ${variables?.Title} could not be updated: ${errorMessage}`, 'error')
        }
    })

    return { fetcher, updater }
}

export function getUpdateContentMutation() {
    return useMutation({
        mutationFn: ({ content, _then }: { content: Content; _then?: () => void }) =>
            httpPut(`${ContentAPIV2}/${content.ID}`, content),
        onSuccess: (data, variables, context) => {
            notify(`Success! ${variables?.content.Title} updated`, 'info')
            variables?._then?.()
        },
        onError: (err: any, variables) => {
            notify(`Oops! ${variables?.content.Title} could not be updated`, 'error')
            const errorMessage = err?.response?.data?.ErrorMessage
            console.log('update content error', errorMessage)
        }
    })
}

export function getDeleteContentMutation() {
    return useMutation({
        mutationFn: ({ content, _then }: { content: Content; _then?: () => void }) => deleteContentQuery(content.ID),
        onSuccess: (data, variables, context) => {
            notify(`Success! ${variables?.content.Title} updated`, 'info')
            variables?._then?.()
        },
        onError: (err: any, variables) => {
            const msg = guessErrorMessage(err)
            notify(`Oops! ${variables?.content.Title} could not be deleted: ${msg}`, 'error')
            const errorMessage = err?.response?.data?.ErrorMessage
            console.log('update content error', errorMessage)
        }
    })
}
