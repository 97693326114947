import React, { useEffect, useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import { SetState } from '../../../pkgs/departments/department.service'
import { is } from '../../../helpers'
import { ROLE_TYPE } from '../../../pkgs/user-management/types'
import { useRoleQueryMutation } from '../../../pkgs/user-management/queries'

interface RoleSelectProps {
    initialValue: string // role id
    onChange: SetState
    variant?: 'standard' | 'outlined' | 'filled'
    required?: boolean
    roleType?: ROLE_TYPE
}

export function RoleSelect({ initialValue, onChange, variant, required, roleType }: RoleSelectProps) {
    // role id
    const [value, setValue] = useState<string>('')

    const { data: roles, getRoleIdToRole } = useRoleQueryMutation()

    const roleIdToRole = { ...getRoleIdToRole(roleType), ['']: { name: '--- Select a Role ---' } }
    const sortedRoleIds = roleIdToRole ? [''].concat(Object.keys(roleIdToRole).filter((role) => role !== '')) : []

    useEffect(() => {
        if (!roles?.length) {
            return
        }

        if (typeof initialValue !== 'string') {
            if (value !== '') {
                setValue('')
            }
            return
        }

        if (initialValue === '' || roles?.some((role) => role.id === initialValue)) {
            setValue(initialValue)
        }
    }, [initialValue, roles])

    return (
        <FormControl variant={variant || 'outlined'} required={required} sx={{ width: '100%' }}>
            <InputLabel>Role</InputLabel>
            <Select
                data-testid={'role-select'}
                placeholder={'Role'}
                label={'Role'}
                value={value}
                renderValue={(selectedRoleId: string) => {
                    return roleIdToRole?.[selectedRoleId]?.name || ''
                }}
                onChange={(evt) => {
                    const value = evt?.target?.value || ''

                    if (is.func(onChange)) {
                        onChange(value)
                    }

                    setValue(value)
                }}
                disabled={!roleIdToRole}
            >
                {roleIdToRole &&
                    sortedRoleIds.map((roleId) => {
                        const name = roleIdToRole?.[roleId]?.name || ''
                        const type = roleIdToRole?.[roleId]?.type || ''
                        return (
                            <MenuItem key={roleId + name} value={roleId}>
                                <Typography>{name}</Typography>
                                <Typography style={{ paddingLeft: '8px', textTransform: 'capitalize' }}>
                                    {`${type != '' ? `(${type})` : ''}`}
                                </Typography>
                            </MenuItem>
                        )
                    })}
            </Select>
        </FormControl>
    )
}

export default RoleSelect
