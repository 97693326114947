export default function getJsonDctSchema(componentContainer) {
    let requiredValues = ['name', 'label', 'type', 'required']
    let futureStateArray = []
    let errorComponent = {
        key: true,
        label: 'Invalid JSON data, A text field has been rendered in its place',
        maximumLength: '',
        name: 'error',
        required: true,
        type: 'text',
        validate: ''
    }

    componentContainer.forEach((component) => {
        let nestedComponentValues = Object.keys(component)
        if (requiredValues.every((x) => nestedComponentValues.includes(x))) {
            futureStateArray.push(component)
        } else {
            futureStateArray.push(errorComponent)
        }
    })
    return futureStateArray
}
