import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

export const ObjectAsJson = ({ obj, title, onClose = () => {} }: { obj: any; title: string; onClose?: () => void }) => {
    const [open, setOpen] = React.useState(obj !== undefined)
    let jsonString = "can't stringify obj"
    try {
        jsonString = JSON.stringify(obj, null, 4)
    } catch (e) {
        console.error('cant stringify obj')
        console.log(obj)
        console.log(e)
    }

    return (
        <Dialog open={Boolean(obj)} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <pre>{jsonString}</pre>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        setOpen(false)
                        onClose()
                    }}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}
