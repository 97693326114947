// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { v4 } from 'uuid'
import { uuidNil } from '../../common/constants'
import { is, notify, sanitizePathString, validateUUID } from '../../helpers'
import { Button, TextField, Typography } from '@mui/material'
import { ExternalNodeElement, ExternalNodeV2 } from './sortable-tree/ExternalNode_v2'
import { useEmitter } from './useEmitter'
import { ConfirmAction, HelpTooltip } from '../../common/components'
import ClearIcon from '@mui/icons-material/Clear'
import { generateClassNames } from './sortable-tree/TreeBase'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { contentService } from '../content/content.service'
import { useCurrentSiteID } from '../auth/atoms'
import { Site } from '../auth/types'
import { ContentType } from '../content/types'
import { NavigationType } from './types'

/** Tree Nodes */
interface DepartmentWrapperProps {
    Departments: Site[]
    Reference: Record<string, any>
    className?: any
}

export const DepartmentNavigationControl = ({ Departments, Reference, className }: DepartmentWrapperProps) => {
    const currentSiteID = useCurrentSiteID()
    const [list, setList] = useState([])
    const emitter = useEmitter(['TreeChange'])

    // TODO => SetDepartments or change up the logic
    //  right now, dragging departmentNode into tree doesn't refresh
    //  it in this list as 'checked'
    useEffect(() => {
        if (Departments && Reference && currentSiteID) {
            const next = []
            for (let i = 0; i < Departments.length; i++) {
                const ref = Departments[i]
                if (validateUUID(ref.ID)) {
                    const n = makeBaseExternalLink(currentSiteID, ref.ID, ref.Name)
                    n.existsInTree = Boolean(Reference[ref.ID]?.existsInTree)
                    next.push(n)
                }
            }
            setList(next)
        }
    }, [Departments, Reference, currentSiteID])

    // List / Next is an array of sites
    // They do not use the navigation model at this point
    useEffect(() => {
        const next = [...list]
        const i = emitter.test(next, 'id')
        console.log('event', {
            i,
            next
        })
        if (i > -1) {
            next[i].existsInTree = true
            setList(next)
            emitter.done()
        }
    }, [emitter.event])

    if (!currentSiteID) {
        return ''
    }
    return (
        <div className={className ? className : undefined}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography variant='h6' component='div' fontWeight={300}>
                    Department Links
                </Typography>
                <HelpTooltip
                    contents={[
                        'These are departments which are hosted by your current site',
                        'Departments can have a single entry point in the navigation tree of your site'
                    ]}
                    position={{ bottom: '-50px', left: '85px', width: '450px', textAlign: 'left' }}
                />
            </div>
            {list.map(
                (props) =>
                    props.existsInTree ? (
                        <ExternalNodeElement key={'NavigationDepNode-' + props?.id} node={props} disabled={true} />
                    ) : (
                        <DepartmentNode node={props} Reference={Reference} key={'NavigationDepNode-' + props?.id} />
                    )
                // : <DepartmentNode SiteId={selectedSite || ""} DepartmentId={props.id} Name={props.name} Reference={Reference} key={"NavigationDepNode-" + props?.id}/>
            )}
        </div>
    )
}

interface DepartmentNodeProps {
    Name: string
    SiteId: string
    Reference: Record<string, any>
    rest?: any
}

export const DepartmentNode = ({ node, /*Name, SiteId, DepartmentId,*/ Reference, ...rest }: DepartmentNodeProps) => {
    // const findInTree = node => { // }
    if (!node) {
        return ''
    }
    return (
        <ExternalNodeV2
            node={node}
            customClass={'Navigation-TypeDepartment'}
            key={`NavigationDepNode-${node?.ContentId}`}
            // Buttons={p => (<ClearIcon onClick={findInTree}/>)}
        />
    )
}

export const DisconnectedNavigationControl = ({ className, currentSite, nodes, setDeleted }) => {
    const [list, setList] = useState([])
    const [actionItem, setActionItem] = useState<Record<any, any> | null>(null)
    const emitter = useEmitter(['TreeChange'])

    useEffect(() => {
        if (emitter.test(list) > -1) {
            setList((p) => p.filter((x) => x.id !== emitter.event.id))
            emitter.done()
        }
    }, [emitter.event])

    useEffect(() => {
        if (!list?.length) {
            if (is.objectArray(nodes)) {
                setList([...nodes])
            }
        }
    }, [])

    const removeFromList = () => {
        setList((list) => list.filter((x) => x.id !== actionItem?.id))
        setDeleted?.((prev) => [actionItem, ...prev])
        setActionItem(null)
    }
    return (
        <div className={className ? className : {}}>
            <div>
                <div className='flex-row' style={{ justifyContent: 'space-between' }}>
                    {/*<h3 className="no-margin">Disconnected Pages</h3>*/}
                    <Typography variant='h6' component='div' fontWeight={300}>
                        Disconnected Pages
                    </Typography>
                    <HelpTooltip
                        contents={[
                            'These Pages have been assigned to the tree in the past but have since been disconnected',
                            'Drag and Drop Pages from here onto the tree to reposition them'
                        ]}
                        position={{ bottom: '-50px', left: '85px', width: '450px', textAlign: 'left' }}
                        // position={{bottom: "25px",left: "-330px", width:"400px", textAlign:"left"}}
                    />
                </div>
                {list.map((item) => (
                    <ExternalNodeV2
                        key={`Navigation-Disconnected-${item?.id}`}
                        node={item}
                        className={generateClassNames(item)}
                        Buttons={(p) => <ClearIcon onClick={() => setActionItem(item)} />}
                    />
                ))}
                <ConfirmAction
                    open={actionItem != null}
                    handleAgree={removeFromList}
                    handleClose={() => setActionItem(null)}
                    handleDisagree={() => setActionItem(null)}
                    text={`Are you sure you want to delete ${actionItem?.title}'s navigation?`}
                    additionalText={`This change will only affect the navigation of ${currentSite?.Name}`}
                    title={'Delete Navigation?'}
                />
            </div>
        </div>
    )
}

const delayedSearchTime = 500
export const NavigationSearchControl = ({
    className,
    currentSite,
    searchState,
    setSearchState,
    searchQuery,
    setSearchQuery,
    Reference
}) => {
    const [searchedContent, setSearchedContent] = useState([])
    const [list, setList] = useState([])
    const emitter = useEmitter(['TreeChange'])

    useEffect(() => {
        setList(
            searchedContent.map((sc) => {
                if (sc.existsInTree) {
                    return sc
                }
                return newNodeFromContent(sc)
            })
        )
    }, [searchedContent, searchedContent?.length])

    useEffect(() => {
        const s = [...list]
        const index = emitter.test(s)
        if (index > -1) {
            s[index].existsInTree = true

            setList(s)
            emitter.done()
        }
    }, [emitter.event])

    let searchTimeout
    useEffect(() => {
        if (searchQuery.length) {
            searchTimeout = setTimeout(() => {
                contentService
                    .search([ContentType.ExternalLink, ContentType.Page], 0, 99999, searchQuery, {
                        hideDepartmentContent: true
                    })
                    .then(
                        ({ results }) => {
                            // Create an object representation of the nodes
                            // that exist within the tree - this is a reference
                            // used in our search functionality
                            for (const result of results) {
                                if (
                                    Reference[result.id] &&
                                    (!Reference[result.id].hasOwnProperty('existsInTree') ||
                                        Reference[result.id].existsInTree)
                                ) {
                                    result.existsInTree = true
                                }
                            }
                            console.log({ results, searchQuery })
                            //@ts-ignore
                            setSearchState((prev) => ({ ...prev, query: searchQuery, count: results.length }))
                            setSearchedContent(results)
                        },
                        (e) => {
                            notify(`Whoops! ${e}`, 'error')
                        }
                    )
            }, delayedSearchTime)
            return () => clearTimeout(searchTimeout)
        } else {
            setSearchState((prev) => ({ ...prev, query: '', count: 0 }))
            setSearchQuery('')
            setSearchedContent([])
            setList([])
        }
    }, [searchQuery])

    const selectPrevMatch = () => {
        setSearchState({
            index:
                searchState.index !== null
                    ? (searchState.count + searchState.index - 1) % searchState.count
                    : searchState.count - 1,
            count: searchState.count,
            query: searchState.query
        })
    }

    const selectNextMatch = () => {
        setSearchState({
            index: searchState.index !== null ? (searchState.index + 1) % searchState.count : 0,
            count: searchState.count,
            query: searchState.query
        })
    }

    const newNodeFromContent = (content) => ({
        ...makeBaseExternalLink(currentSite.ID, null, content.title),
        ContentId: content.id,
        content: { ...content },
        type: content?.type === ContentType.ExternalLink ? NavigationType.External : NavigationType.Page
    })

    return (
        <div className={className ? className : undefined} style={{ marginTop: '0' }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography variant='h6' component='div' fontWeight={300}>
                    All Site Pages
                </Typography>
                <HelpTooltip
                    contents={[
                        'Search for any pages that belong to your School Site',
                        'Pages with navigation will be highlighted in blue in the tree, use the arrows to view results',
                        'Pages without navigation can be Drag and Dropped into position'
                    ]}
                    position={{ bottom: '-50px', left: '85px', width: '450px', textAlign: 'left' }}
                />
                {/*<h3 className="no-margin ml5">All Site Pages</h3>*/}
            </div>
            <div className='flex-column-center'>
                <TextField
                    variant='standard'
                    label={'Search'}
                    className='max-width'
                    value={searchQuery}
                    onChange={(e) => {
                        setSearchQuery(e.target.value)
                    }}
                />

                {searchedContent.length > 0 && (
                    <div className='m10 max-width external-node-list Navigation-PageSearchList'>
                        {list.map((content) => (
                            <div key={`${content.id}--${content.title}--${content.existsInTree}`}>
                                {content.existsInTree ? (
                                    <ExternalNodeElement node={content} /* title={content.title} */ />
                                ) : (
                                    <ExternalNodeV2 node={content} />
                                )}
                            </div>
                        ))}
                    </div>
                )}
                <div className='mt5p full-width'>
                    <div className='m5 full-width'>
                        <span className='flex-row'>
                            <span
                                className='canMoveSVG'
                                style={{ height: '20px', width: '12px', marginRight: '8px', opacity: '0.5' }}
                            />
                            <strong className='fs65'> PAGE IS NOT YET ASSIGNED TO TREE </strong>
                        </span>
                        <span className='fs65'> Drag and drop page to assign page to tree structure</span>
                    </div>
                    <hr style={{ opacity: '0.3' }} />
                    <div className='m5 full-width'>
                        <span className='flex-row'>
                            <span
                                className='cantMoveSVG'
                                style={{ height: '20px', width: '18px', marginRight: '3px', opacity: '0.5' }}
                            />
                            <div className='flex-row full-width' style={{ justifyContent: 'space-between' }}>
                                <strong className='fs65'> PAGE IS ALREADY ASSIGNED TO TREE </strong>
                                <div className='flex-row ' style={{ marginRight: '5%' }}>
                                    <Button
                                        size={'small'}
                                        variant={'text'}
                                        onClick={selectPrevMatch}
                                        disabled={!searchState.count}
                                        style={{
                                            ...(!searchState.count
                                                ? { color: 'rgb(182, 178, 178)' }
                                                : { color: '#4396F3' }),
                                            minWidth: '0px',
                                            width: '0px'
                                        }}
                                    >
                                        <ArrowBackIosIcon />
                                    </Button>
                                    <Button
                                        size={'small'}
                                        variant={'text'}
                                        disabled={true}
                                        color={'primary'}
                                        style={{
                                            ...(!searchState.count
                                                ? { marginLeft: '-6px', width: '40px' }
                                                : {
                                                      marginLeft: '-6px',
                                                      width: 'width: 55px',
                                                      color: 'rgb(182, 178, 178)'
                                                  }),
                                            minWidth: '0.15rem',
                                            whiteSpace: 'nowrap'
                                        }}
                                    >
                                        {searchState.count !== 0 ? searchState.index + 1 : searchState.index} /{' '}
                                        {searchState.count}
                                    </Button>
                                    <Button
                                        size={'small'}
                                        variant={'text'}
                                        onClick={selectNextMatch}
                                        disabled={!searchState.count}
                                        style={{
                                            ...(!searchState.count
                                                ? { color: 'rgb(182, 178, 178)' }
                                                : { color: '#4396F3' }),
                                            minWidth: '0px',
                                            width: '0px'
                                        }}
                                    >
                                        <ArrowForwardIosIcon />
                                    </Button>
                                </div>
                            </div>
                        </span>
                        <span className='fs65'>
                            {' '}
                            Use arrows above to highlight search result(s) already in the tree structure
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}
const classes = {
    searchButtonWidth: {
        minWidth: '0px',
        width: '0px'
    }
}

export const makeBaseExternalLink = (SiteId: string, DepartmentId: string | null, Name: string, Route?: string) => {
    return {
        id: v4(),
        title: Name,
        type: NavigationType.External,
        ContentId: uuidNil,
        active: true,
        visible: true,
        path: sanitizePathString(uuidNil),
        treeIndex: 0,
        SiteId: SiteId,
        DepartmentId: DepartmentId,
        content: {
            id: uuidNil,
            title: Name,
            route: Route || 'javascript:',
            sites: [SiteId],
            departmentId: DepartmentId
        },
        // Tree Specific
        existsInTree: false,
        HasSiteIdOverlap: true,
        settings: {}
    }
}
