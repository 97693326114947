import { Box, IconButton } from '@mui/material'
import { CodeEditor, CodeEditorHandler, CodeEditorProps } from '../../monaco/code-editor'
import { useCallback, useEffect, useRef, useState } from 'react'
import { $insertParagraphAfterNode, composedPathHasId } from '../helpers/helpers'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
    $getNodeByKey,
    $getSelection,
    $isParagraphNode,
    CLICK_COMMAND,
    COMMAND_PRIORITY_LOW,
    COMMAND_PRIORITY_NORMAL,
    COPY_COMMAND,
    KEY_BACKSPACE_COMMAND,
    KEY_DELETE_COMMAND,
    KEY_ENTER_COMMAND
} from 'lexical'
import { $isMonacoCodeEmbedNode, MonacoCodeEmbedNode } from './MonacoCodeEmbedNode'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import CreateIcon from '@mui/icons-material/Create'
import { useLexicalNodeSelection } from '@lexical/react/useLexicalNodeSelection'
import { mergeRegister } from '@lexical/utils'
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn'
import { colours } from '../../../common/colours'
import MonacoCodeEmbedEditorDialog from './MonacoCodeEmbedEditorDialog'

interface MonacoCodeEmbedViewProps extends CodeEditorProps {
    nodeKey: string
}

function getContainerId(nodeKey) {
    return MonacoCodeEmbedNode.getType() + '-' + nodeKey
}

const language = 'html'
const heightWithinEditor = '20vh'
export default function MonacoCodeEmbedView({ nodeKey, ...codeEditorProps }: MonacoCodeEmbedViewProps) {
    const nodeViewBoxRef = useRef<HTMLDivElement | null>(null)
    const [isSelected, setSelected, clearSelection] = useLexicalNodeSelection(nodeKey || '')
    const monacoCodeEditorRef = useRef<CodeEditorHandler | null>(null)
    const [editor] = useLexicalComposerContext()
    const [isEditable, setIsEditable] = useState(false)
    const [activeValue, setActiveValue] = useState(codeEditorProps.value)

    const onDelete = useCallback(
        (payload: KeyboardEvent) => {
            if (isSelected) {
                const event: KeyboardEvent = payload
                event.preventDefault()
                const node = $getNodeByKey(nodeKey)
                if ($isMonacoCodeEmbedNode(node)) {
                    node.remove()
                }
            }
            return false
        },
        [isSelected, nodeKey]
    )

    const onClick = useCallback(
        (event: MouseEvent) => {
            if (nodeKey && composedPathHasId(event.composedPath(), getContainerId(nodeKey))) {
                clearSelection()
                setSelected(true)
                return true
            }
            return false
        },
        [isSelected, setSelected, clearSelection]
    )

    const insertNewLine = useCallback(() => {
        const codeEmbedNode = nodeKey ? $getNodeByKey(nodeKey) : $getSelection()?.getNodes()?.[0]
        const parentNode = codeEmbedNode?.getParent()
        if (codeEmbedNode && $isMonacoCodeEmbedNode(codeEmbedNode)) {
            if (parentNode && $isParagraphNode(parentNode)) {
                $insertParagraphAfterNode(parentNode)
                return true
            } else {
                $insertParagraphAfterNode(codeEmbedNode)
                return true
            }
        }
        return false
    }, [nodeKey])

    const onEnter = useCallback(
        (event: KeyboardEvent) => {
            if (isSelected && !isEditable) {
                event.preventDefault()
                return insertNewLine()
            }
            return false
        },
        [isSelected, isEditable, setSelected, clearSelection]
    )

    const insertNewLineOnClickHandler = useCallback(
        (e) => {
            editor.update(() => {
                return insertNewLine()
            })
        },
        [editor]
    )

    useEffect(() => {
        return mergeRegister(
            editor.registerCommand<MouseEvent>(CLICK_COMMAND, onClick, COMMAND_PRIORITY_LOW),
            editor.registerCommand<KeyboardEvent>(KEY_ENTER_COMMAND, onEnter, COMMAND_PRIORITY_LOW),
            editor.registerCommand(KEY_DELETE_COMMAND, onDelete, COMMAND_PRIORITY_LOW),
            editor.registerCommand(KEY_BACKSPACE_COMMAND, onDelete, COMMAND_PRIORITY_LOW),
            editor.registerCommand(
                COPY_COMMAND,
                (payload, editor) => {
                    monacoCodeEditorRef?.current
                        ?.getEditor()
                        ?.trigger('source', 'editor.action.clipboardCopyAction', null)
                    return true
                },
                COMMAND_PRIORITY_NORMAL
            )
        )
    }, [editor, isSelected, setSelected, clearSelection, nodeKey, onClick])

    useEffect(() => {
        setActiveValue(codeEditorProps?.value || '')
    }, [codeEditorProps?.value])

    useEffect(() => {
        if (!isSelected && isEditable) {
            setIsEditable(false)
        }
    }, [isSelected])

    const updateCode = useCallback(
        (code: string) => {
            editor.update(() => {
                const node = $getNodeByKey(nodeKey)
                if ($isMonacoCodeEmbedNode(node)) {
                    node.setCode(code)
                }
            })
        },
        [editor, nodeKey]
    )

    const codeEditorOnSaveHandler = useCallback((v: string) => {
        setActiveValue(v)
        updateCode(v)
        // setIsEditable(false)
    }, [])

    const codeEditorOnCloseHandler = useCallback(() => {
        setIsEditable(false)
        // setActiveValue(codeEditorProps.value)
    }, [])

    return (
        <>
            <Box
                ref={nodeViewBoxRef}
                id={getContainerId(nodeKey)}
                onClick={() => setSelected(true)}
                sx={{
                    backgroundColor: colours.off_white_but_darker,
                    display: 'flex',
                    width: '100%',
                    boxShadow: isSelected ? `0 0 10px ${colours.light_blue}` : undefined
                }}
            >
                <Box
                    width='100%'
                    minHeight={-20 + (nodeViewBoxRef?.current?.offsetHeight || 50)}
                    sx={{
                        cursor: isEditable ? undefined : 'pointer',
                        opacity: isEditable ? undefined : '0.65',
                        pointerEvents: isEditable ? undefined : 'none'
                    }}
                >
                    <CodeEditor
                        ref={monacoCodeEditorRef}
                        value={activeValue}
                        language={language}
                        onChange={(v) => setActiveValue(v || '')}
                        disabled={!isEditable}
                        height={heightWithinEditor}
                    />
                </Box>
                <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='space-between'
                    minHeight={-20 + (nodeViewBoxRef?.current?.offsetHeight || 50)}
                    sx={{
                        position: 'relative'
                    }}
                >
                    <IconButton
                        tabIndex={0}
                        onMouseDown={(event) => event.preventDefault()}
                        onClick={() => {
                            setIsEditable(true)
                            monacoCodeEditorRef?.current?.getEditor()?.focus()
                        }}
                        sx={{
                            color: colours.disabled
                        }}
                        title='Edit source'
                    >
                        <CreateIcon />
                    </IconButton>
                    <IconButton
                        sx={{
                            position: 'absolute',
                            bottom: '5%'
                        }}
                        tabIndex={2}
                        onMouseDown={(event) => event.preventDefault()}
                        onClick={(e) => {
                            e.stopPropagation()
                            insertNewLineOnClickHandler(e)
                        }}
                        title='Add a new line below'
                    >
                        <KeyboardReturnIcon />
                    </IconButton>
                </Box>
            </Box>
            <MonacoCodeEmbedEditorDialog
                isOpen={isEditable}
                value={activeValue}
                language={language}
                onSaveHandler={(v) => {
                    codeEditorOnSaveHandler(v)
                }}
                onCloseHandler={codeEditorOnCloseHandler}
            />
        </>
    )
}
