import { Card, CardContent, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup, TextField } from '@mui/material'
import CreateIcon from '@mui/icons-material/Create'
import { useState } from 'react'
import { AddButton, DialogFormAction } from '../../../common/components'
import { useNavigate } from 'react-router'
import { notify } from '../../../helpers'
import { ROLE_TYPE } from '../types'
import PageContainerWithHeader from '../../../common/components/PageContainerWithHeader'
import { useAppNavigation } from '../../../app/useAppNavigation'
import { useRoleQueryMutation } from '../queries'

export function Roles() {
    const { navigateTo } = useAppNavigation()
    const { data, createMutation: createRoleMutation, refetch } = useRoleQueryMutation()
    const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false)
    const [createRoleName, setCreateRoleName] = useState('')
    const [createRoleType, setCreateRoleType] = useState('')

    return (
        <PageContainerWithHeader
            title='Roles'
            topRightElement={
                <AddButton
                    title={`add role`}
                    func={() => setCreateDialogIsOpen(true)}
                    style={{ marginTop: '0.75rem', marginBottom: '0.75rem' }}
                />
            }
        >
            <Card className='sub-container'>
                <CardContent>
                    <div className='table-container'>
                        <div className='table-item'>
                            <p className='four-font-weight'>Role Name</p>
                            <p className='four-font-weight site-options'>Edit</p>
                        </div>
                        {data?.map((role) => {
                            return (
                                <div className='table-item' key={role.id}>
                                    <p className='site-name'>
                                        {role.name} <strong>[{role.type}]</strong>
                                    </p>
                                    <div className={'site-options icon'}>
                                        <IconButton
                                            size={'small'}
                                            aria-label={'Edit Role'}
                                            aria-haspopup={'true'}
                                            data-testid={`edit-role-${role.name.replace(/\s/g, '-')}`}
                                            onClick={(e) => {
                                                navigateTo(`/user-management/roles/${role.id}`)
                                            }}
                                        >
                                            <CreateIcon />
                                        </IconButton>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </CardContent>
            </Card>
            {createDialogIsOpen && (
                <DialogFormAction
                    item={
                        <div>
                            <TextField
                                variant='standard'
                                value={createRoleName}
                                data-testid={'create-role-name'}
                                name='name'
                                onChange={(event) => setCreateRoleName(event.target.value)}
                                id='standard-basic'
                                label='Name'
                            />

                            <FormLabel required>Type:</FormLabel>
                            <RadioGroup
                                row
                                value={createRoleType}
                                onChange={(ev, value) => {
                                    setCreateRoleType(value as ROLE_TYPE)
                                }}
                                sx={{
                                    textTransform: 'capitalize'
                                }}
                            >
                                <FormControlLabel
                                    aria-required
                                    value={ROLE_TYPE.TENANT}
                                    label={ROLE_TYPE.TENANT}
                                    control={<Radio data-testid={'tenant-role'} required />}
                                />
                                <FormControlLabel
                                    aria-required
                                    value={ROLE_TYPE.SITE}
                                    label={ROLE_TYPE.SITE}
                                    control={<Radio data-testid={'site-role'} required />}
                                />
                            </RadioGroup>
                        </div>
                    }
                    title='Create a new role'
                    buttonAgreeLabel='Create'
                    buttonDisagreeLabel='Cancel'
                    open={createDialogIsOpen}
                    handleDisagree={() => setCreateDialogIsOpen(false)}
                    handleAgree={() => {
                        if (createRoleName.length && createRoleType.length) {
                            createRoleMutation.mutate(
                                { name: createRoleName, type: createRoleType },
                                {
                                    onSuccess: () => {
                                        refetch()
                                        setCreateDialogIsOpen(false)
                                        notify('Role successfully created', 'info')
                                    },
                                    onError: () => {
                                        notify('Oops! Role could not be created', 'error')
                                    }
                                }
                            )
                        }
                    }}
                    handleClose={() => setCreateDialogIsOpen(false)}
                    fullWidth={true}
                    text={undefined}
                    alternate={undefined}
                    alternateAction={undefined}
                    headerComponent={undefined}
                />
            )}
        </PageContainerWithHeader>
    )
}

export default Roles
