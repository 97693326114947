import React, { useMemo } from 'react'
import { CustomMenuItem } from './CustomMenu'
import TimerOffIcon from '@mui/icons-material/TimerOff'
import TimerIcon from '@mui/icons-material/Timer'
import { contentService } from '../../../pkgs/content/content.service'
import { notify } from '../../../helpers'

export const ExpireToggleCustomMenuItem = ({ value, onChange, disabled }) => {
    const isExpired = useMemo(() => !!value?.expire_at && Date.parse(value?.expire_at) < Date.now(), [value])

    const toggleExpiration = async () => {
        try {
            const patchModel: any = { id: value?.id }
            patchModel.expire_at = isExpired ? null : new Date().toISOString()
            const response = await contentService.patchContent('', patchModel)
            console.log(response)
            onChange?.(response)
        } catch (e) {
            console.error(e)
            notify('Whoops - Something went wrong, find more details in the console.', 'error')
        }
    }

    if (value == null) {
        return null
    }
    return (
        <CustomMenuItem
            text={isExpired ? 'Un-expire' : 'Expire now'}
            onClick={() => toggleExpiration()}
            disabled={disabled}
        >
            {isExpired ? <TimerOffIcon fontSize='small' /> : <TimerIcon />}
        </CustomMenuItem>
    )
}
