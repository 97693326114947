import { useQuery } from '@tanstack/react-query'
import { httpGet } from '../../../common/client'
import { PagingQuery, SortingQuery, baseQueryConfig } from '../../../common/react-query'
import { contentResult } from '../types'
import { BASE } from '../../../common/constants'

export type TemplatesQuery = {
    Search?: string
    Classifications?: string[]
    Sites?: string[]
    IDs?: string[]
    TemplateType?: 'dct' | 'formless' | 'all'
} & PagingQuery &
    SortingQuery

export const useTemplatesQuery = (q: TemplatesQuery) => {
    const query = useQuery({
        ...baseQueryConfig,
        queryKey: ['templates-query', q],
        queryFn: async () => httpGet(`${BASE}/api/v2/templates`, q, contentResult)
    })

    return query
}
