import { List, ListsQuery } from './types'
import { useStateWithStorage } from '../../common/storage.service'
import { useDebounce } from '../../common/useDebounce'
import { useListsQuery } from './queries'
import { GridColDef } from '@mui/x-data-grid'
import {
    CellLine,
    CellWrapper,
    IDToNameCell,
    MenuLightCell,
    NullableDateCell,
    PublishPeriodCell,
    TwoLinesCell
} from '../grid/cells/GridCells'
import { guessErrorMessage } from '../../helpers/guessErrorMessage'
import { CustomMenuItem } from '../../common/components/custom-context-menu/CustomMenu'
import CreateIcon from '@mui/icons-material/Create'
import { Button, FormControlLabel, Grid, Switch } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { StructureSelector } from '../structure/StructureSelector'
import { ListForm } from './ListForm'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import { AddButton } from '../../common/components'
import DeleteIcon from '@mui/icons-material/Delete'
import { httpDelete, httpPatch } from '../../common/client'
import { notify } from '../../helpers'
import { Preview, RestoreFromTrash } from '@mui/icons-material'
import { TagsSelector } from '../grid/selectors/TagsSelector'
import PageContainerWithHeader from '../../common/components/PageContainerWithHeader'
import SearchBar from '../../common/components/SearchBar'
import { StatusSelector } from '../content/BaseForm'
import { sitesEventChanel } from '../../common/components/selectors/SiteSelectorForAccount'
import { useAppNavigation } from '../../app/useAppNavigation'
import { defaultPageQuery } from '../../common/react-query'
import { BASE } from '../../common/constants'
import { DataGridBase } from '../grid/DataGridBase'
import { TagType } from '../system/tags/types'

const defaultQuery: ListsQuery = { ...defaultPageQuery }
export const ListsGrid = () => {
    const { navigateTo } = useAppNavigation()
    const [query, setQuery] = useStateWithStorage('lists-grid', defaultQuery)
    const debouncedQuery = useDebounce(query, 300)
    const results = useListsQuery(debouncedQuery)

    const [selectedList, setSelectedList] = useState<List | undefined>(undefined)
    const [listEditorIsOpen, setListEditorIsOpen] = useState(false)

    useEffect(() => {
        const subs = sitesEventChanel.on('onSiteUpdated', (siteID) => {
            // Test: refetch() here has a race condition: siteID is correct, however, if you go from one site to the next, it refetches
            // with the previous value.
            // we don't have siteID as a param for lists (using r.Request.SiteID), so this is not properly fixed, but it does work as expected.
            //@ts-ignore
            setQuery((p) => ({ ...p, SiteID: siteID }))
        })
        return () => subs()
    }, [])

    useEffect(() => {
        // Because the list editor does not close on save/update we must pass in the list with the updated values into ListForm
        // in order to ensure hasChanges within ListForm is reset to false.
        if (selectedList) {
            const upToDateSelectedList = results.data?.Rows?.find((list) => list.ID == selectedList?.ID)
            if (upToDateSelectedList) {
                setSelectedList(upToDateSelectedList as any)
            }
        }
    }, [results])

    const deleteList = (id: string) => {
        httpDelete(`${BASE}/api/v1/lists/${id}`)
            .then(() => {
                notify('List deleted', 'info')
                results.refetch()
            })
            .catch((err) => {
                notify(guessErrorMessage(err), 'error')
                results.refetch()
            })
    }

    const restoreList = (id: string) => {
        httpPatch(`${BASE}/api/v1/lists/${id}/restore`, null)
            .then(() => {
                notify('List restored', 'info')
                results.refetch()
            })
            .catch((err) => {
                notify(guessErrorMessage(err), 'error')
                results.refetch()
            })
    }

    const menuItems = (list: List) => {
        return (onClose: () => void) => {
            return list.Active
                ? [
                      <CustomMenuItem
                          key={'Preview'}
                          onClick={() => {
                              window.open(`${BASE}/api/v1/lists/${list.ID}/compile`, '_blank')
                              onClose()
                          }}
                          text={'Preview'}
                      >
                          <Preview />
                      </CustomMenuItem>,

                      <CustomMenuItem
                          key={'0'}
                          text={'Edit Items'}
                          onClick={() => {
                              navigateTo(`/ordered-lists/${list?.ID}/items`)
                          }}
                      >
                          <FormatListNumberedIcon />
                      </CustomMenuItem>,

                      <CustomMenuItem
                          key={'1'}
                          text={'Edit List'}
                          onClick={() => {
                              setSelectedList(list)
                              setListEditorIsOpen(true)
                              onClose()
                          }}
                      >
                          <CreateIcon />
                      </CustomMenuItem>,

                      <CustomMenuItem
                          key={'2'}
                          text={'Delete List'}
                          onClick={() => {
                              window.confirm(
                                  `Are you sure you want to delete the list "${list.Name}" with ID="${list.ID}"?`
                              ) && deleteList(list.ID)
                              onClose()
                          }}
                      >
                          <DeleteIcon />
                      </CustomMenuItem>
                  ]
                : [
                      <CustomMenuItem
                          key={'2'}
                          text={'Restore List'}
                          onClick={() => {
                              restoreList(list.ID)
                              onClose()
                          }}
                      >
                          <RestoreFromTrash />
                      </CustomMenuItem>
                  ]
        }
    }

    const columns: GridColDef[] = [
        {
            field: 'Name',
            headerName: 'Name',
            flex: 1.4,
            filterable: false,
            sortable: true,
            renderCell: (params) => <TwoLinesCell l1={`${params.row.Name}`} l2={params.row.ID} />
        },
        {
            field: 'StructureID',
            headerName: 'Structure',
            flex: 1.5,
            filterable: false,
            sortable: false,
            renderCell: (params) => <IDToNameCell tableName={'structure'} ID={params.row.StructureID} />
        },
        {
            field: 'ContentTypes',
            headerName: 'Content Types',
            flex: 1.3,
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                <CellWrapper>
                    <CellLine>{params.row.ContentTypes?.join(', ')}</CellLine>
                </CellWrapper>
            )
        },
        {
            field: 'PublishPeriod',
            headerName: 'Status',
            flex: 1,
            sortable: false,
            filterable: false,
            renderCell: (params) => (
                <PublishPeriodCell publishAt={params.row.PublishAt} expireAt={params.row.ExpireAt} />
            )
        },
        {
            field: 'publish_at',
            headerName: 'Publish At',
            flex: 1,
            sortable: true,
            filterable: false,
            renderCell: (params) => <NullableDateCell row={params.row} fieldName={'PublishAt'} />
        },
        {
            field: 'expire_at',
            headerName: 'Expire At',
            flex: 1,
            sortable: true,
            filterable: false,
            renderCell: (params) => <NullableDateCell row={params.row} fieldName={'ExpireAt'} />
        },
        {
            field: 'Menu',
            headerName: '...',
            width: 80,
            sortable: false,
            filterable: false,
            renderCell: (params) => <MenuLightCell itemsFactory={menuItems(params.row)} />
        }
    ]

    return (
        <PageContainerWithHeader
            title={'Lists'}
            topRightElement={
                <AddButton
                    title={'Add List'}
                    func={() => {
                        setSelectedList(undefined)
                        setListEditorIsOpen(true)
                    }}
                />
            }
        >
            <Grid container spacing={2}>
                {/* FILTERS */}
                <Grid item xs={4}>
                    <SearchBar
                        value={query.Search || ''}
                        onChange={(val) => {
                            setQuery((prev) => ({ ...prev, Search: val || '', page: 1 }))
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <StructureSelector
                        value={query.StructureID}
                        onChange={(v) => setQuery({ ...query, StructureID: v, page: 1 })}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TagsSelector
                        value={query.Tags || []}
                        onChange={(tags) => setQuery((p) => ({ ...query, Tags: tags, page: 1 }))}
                        tagType={TagType.Content}
                    />
                </Grid>
                <Grid item xs={4}>
                    <StatusSelector value={query.Status} onChange={(v) => setQuery({ ...query, Status: v, page: 1 })} />
                </Grid>
                <Grid item xs={2}>
                    <FormControlLabel
                        value='start'
                        control={
                            <Switch
                                checked={query.SiteOnly || false}
                                onChange={(e) =>
                                    setQuery({ ...query, SiteOnly: e.target.checked, Status: '', page: 1 })
                                }
                                color='secondary'
                            />
                        }
                        label='Site Only'
                        labelPlacement='start'
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormControlLabel
                        value='start'
                        control={
                            <Switch
                                checked={query.Inactive || false}
                                onChange={(e) =>
                                    setQuery({ ...query, Inactive: e.target.checked, Status: '', page: 1 })
                                }
                                color='secondary'
                            />
                        }
                        label='Deleted'
                        labelPlacement='start'
                    />
                </Grid>
                <Grid item xs={4}>
                    <Button
                        style={{ marginTop: '0.25rem', float: 'right' }}
                        onClick={() => setQuery(defaultQuery)}
                        color={'primary'}
                    >
                        Reset Filters
                    </Button>
                </Grid>
                {/* GRID */}
                <Grid item xs={12}>
                    {results.isLoading && <div>Loading...</div>}
                    {results.error && <div>Error: {guessErrorMessage(results.error)}</div>}
                    {results.data && (
                        <DataGridBase
                            // rowHeight={100}
                            columns={columns}
                            state={results.data}
                            setQuery={setQuery}
                        />
                    )}
                </Grid>
            </Grid>

            {listEditorIsOpen && (
                <ListForm
                    value={selectedList}
                    open={listEditorIsOpen}
                    onClose={() => {
                        setListEditorIsOpen(false)
                        setSelectedList(undefined)
                    }}
                    onSave={() => {
                        results.refetch()
                    }}
                />
            )}
        </PageContainerWithHeader>
    )
}
