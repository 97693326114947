import React, { useContext, useEffect, useState } from 'react'
import { disabledContext } from '../../../common/DisabledContext'
import {
    Card,
    CardContent,
    FormControl,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Select
} from '@mui/material'
import { AddButton, ConfirmAction, DialogFormAction } from '../../../common/components'
import { contentService } from '../content.service'
import { BASE, DefaultMax } from '../../../common/constants'
import { v4 } from 'uuid'
import { notify, validateUUID } from '../../../helpers'
import moment from 'moment'
import lodash from 'lodash'
import WarningIcon from '@mui/icons-material/Warning'
import CreateIcon from '@mui/icons-material/Create'
import DeleteIcon from '@mui/icons-material/Delete'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import TimerOffIcon from '@mui/icons-material/TimerOff'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { ToastContainer } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import LockIcon from '@mui/icons-material/Lock'
import { useAppContext, useCurrentSiteID } from '../../auth/atoms'
import { EntityScopeEnum } from '../../auth/entityScope'
import PageContainerWithHeader from '../../../common/components/PageContainerWithHeader'
import { CreateStructuredContent } from '../editor/CreateStructuredContent'
import { httpDelete, httpPatch } from '../../../common/client'
import { guessErrorMessage } from '../../../helpers/guessErrorMessage'
import { ContentPublishedTypes, ContentType } from '../types'

const isAlertActive = (state) => {
    if (!state || !state.settings) return false
    const dt = Date.now()
    return Date.parse(state.publish_at) < dt && Date.parse(state.expire_at) > dt
}

const RenderMenu = ({
    anchorElement,
    menuId,
    isMenuOpen,
    setAnchorElement,
    handleEdit,
    setIsExpireActionOpen,
    setIsDeleteActionOpen,
    actionItem
}) => {
    const evaluators = useAppContext()
    const hasPermission = evaluators.action(actionItem, 'update')
    return (
        <Menu
            anchorEl={anchorElement}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 10, horizontal: 80 }}
            open={isMenuOpen}
            onClose={() => setAnchorElement(null)}
        >
            <div style={{ minWidth: '10rem' }}>
                <MenuItem onClick={handleEdit}>
                    <ListItemIcon style={{ minWidth: '35px' }}>
                        {hasPermission ? <CreateIcon fontSize='small' /> : <LockIcon fontSize='small' />}
                    </ListItemIcon>
                    <ListItemText>{hasPermission ? 'Edit' : 'View'}</ListItemText>
                </MenuItem>
                <MenuItem
                    disabled={!isAlertActive(actionItem) || !hasPermission}
                    onClick={() => {
                        setIsExpireActionOpen(true)
                        setAnchorElement(null)
                    }}
                >
                    <ListItemIcon style={{ minWidth: '35px' }}>
                        <TimerOffIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Expire</ListItemText>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setIsDeleteActionOpen(true)
                        setAnchorElement(null)
                    }}
                    disabled={!evaluators.action(actionItem, 'delete')}
                >
                    <ListItemIcon style={{ minWidth: '35px' }}>
                        <DeleteIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                </MenuItem>
            </div>
        </Menu>
    )
}

export default function Alerts() {
    const currentSiteID = useCurrentSiteID()
    const evaluators = useAppContext()

    const navigate = useNavigate()
    const [, setDisabled] = useContext(disabledContext)
    const [newPage, setNewPage] = useState(false)
    const [open, setOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isDeleteActionOpen, setIsDeleteActionOpen] = useState(false)
    const [isExpireActionOpen, setIsExpireActionOpen] = useState(false)

    const [actionItem, setActionItem] = useState(null)

    const [alerts, setAlerts] = useState([])
    const [alertTemplates, setAlertTemplates] = useState([])
    const [parentId, setParentId] = useState('')
    const [createModel] = useState({
        id: v4(),
        title: '',
        type: ContentType.Alert
    })

    const [anchorElement, setAnchorElement] = useState(null)
    const isMenuOpen = Boolean(anchorElement)
    const menuId = 'alertMenu'

    useEffect(() => {
        setDisabled(false)
    }, [])

    const refresh = () => {
        contentService.getAll(currentSiteID, [ContentType.Alert], 0, DefaultMax, '', ContentPublishedTypes.all).then(
            ({ results }) => {
                setAlerts(results)
            },
            (error) => console.log(error)
        )
    }
    useEffect(() => {
        refresh()
        contentService.getAllByClassification(currentSiteID, ContentType.Alert, true).then(
            ({ results }) => {
                setAlertTemplates(results)
            },
            (e) => console.log(e)
        )
    }, [currentSiteID])

    const handleOpen = () => {
        if (alertTemplates.length === 1) {
            setParentId(alertTemplates[0].id)
            handleSendToEditor(alertTemplates[0].id)
        } else {
            setOpen(true)
        }
    }

    const handleClose = () => {
        setOpen(false)
        setParentId('')
    }

    const handleDeclineAction = () => {
        setIsDeleteActionOpen(false)
        setIsExpireActionOpen(false)
        setAnchorElement(null)
    }

    const handleConfirmAction = () => {
        setIsLoading(true)
        if (isExpireActionOpen) {
            handleExpire()
        } else if (isDeleteActionOpen) {
            handleDelete()
        }
        handleDeclineAction()
    }

    const handleSendToEditor = (id) => {
        const p = alertTemplates.find((tmp) => tmp.id === (id && validateUUID(id) ? id : parentId))
        if (!p) return notify('Whoops! Error creating alert, please try again later', 'error')
        const location = `/dct?siteId=${currentSiteID}`
        navigate(location, {
            state: {
                ...createModel,
                parent: p
            }
        })
    }

    const handleEdit = () => {
        if (actionItem.structureId) {
            navigate(`/content-editor/${actionItem?.id}?siteId=${currentSiteID}`)
            return
        }
        const clone = lodash.cloneDeep(actionItem)
        const pathArray = clone.path.split('.')
        if (pathArray && pathArray.length < 2) return notify('Whoops! Parent template is no longer available', 'error')
        const joined = pathArray.slice(0, pathArray.length - 1).join('.')
        const parent = alertTemplates.find((x) => x.path === joined)
        if (!parent || !parent.structure) return notify('Whoops! Parent template is no longer available', 'error')
        navigate(`/dct?siteId=${currentSiteID}`, {
            state: {
                ...actionItem,
                parent
            }
        })
    }

    const handleDelete = async () => {
        if (!actionItem || !actionItem.id) return notify('Whoops! Error deleting alert', 'error')
        httpDelete(`${BASE}/api/v2/content/${actionItem.id}`)
            .then(() => {
                setIsLoading(false)
                refresh()
            })
            .catch((err) => {
                setIsLoading(false)
                notify(guessErrorMessage(err), 'error')
            })
    }

    const handleExpire = async () => {
        if (!actionItem || !actionItem.id) return notify('Whoops! Error expiring alert', 'error')
        httpPatch(`${BASE}/api/v2/content/${actionItem.id}/expire`, null)
            .then(() => {
                setIsLoading(false)
                refresh()
            })
            .catch((err) => {
                setIsLoading(false)
                notify(guessErrorMessage(err), 'error')
            })
    }

    const setMenuElement = async (event, state) => {
        const anchorEl = event.currentTarget
        try {
            const response = await contentService.getContentById(currentSiteID, state.id)
            if (response) {
                setActionItem(response)
                setAnchorElement(anchorEl)
            } else await Promise.reject({ message: 'unauthorized' })
        } catch {
            setActionItem(null)
            setAnchorElement(null)
        }
    }

    return (
        <>
            <PageContainerWithHeader
                title='Alerts'
                topRightElement={
                    evaluators.actionForEntityScope(EntityScopeEnum.Alert, 'create') && (
                        <>
                            <AddButton title='ADD ALERT' func={() => setNewPage(true)} style={{ marginRight: 10 }} />
                        </>
                    )
                }
            >
                <ToastContainer />
                <div className='row'>
                    <div className='col-xs-12 col-md-3'>
                        <List>
                            <ListItem className='pages-list-item'>
                                <WarningIcon className='pages-pages-icon' fontSize='small' />
                                <ListItemText className='pages-list-text' disableTypography={true}>
                                    Alerts
                                </ListItemText>
                            </ListItem>
                        </List>
                    </div>
                    <div className='col-xs-12 col-md-9'>
                        <Card className='sub-container'>
                            <CardContent>
                                <div>
                                    <div className='row' style={{ marginLeft: '0.5vw' }}>
                                        <p className='col-xs-4'>Name</p>
                                        <p className='col-xs-2'>Type</p>
                                        <p className='col-xs-2'>Status</p>
                                        <p className='col-xs-2'>Shared</p>
                                        <p className='col-xs-2'>Options</p>
                                    </div>
                                </div>
                                {alerts.map((state) => (
                                    <div
                                        key={state.id + state.title}
                                        className='row'
                                        style={{ marginLeft: '0.5vw', alignItems: 'center' }}
                                    >
                                        <div className='col-xs-4'>
                                            <p>{state.title}</p>
                                        </div>
                                        <div className='col-xs-2'>
                                            <p>{state.settings.alertType ? state.settings.alertType : state.title}</p>
                                        </div>
                                        <div className='col-xs-2'>
                                            {isAlertActive(state) ? (
                                                <span style={{ color: '#489c48' }}>Active</span>
                                            ) : (
                                                <span style={{ color: 'grey' }}>Inactive</span>
                                            )}
                                        </div>
                                        <div className='col-xs-2'>
                                            {state.sites && state.sites.length > 1 && (
                                                <CheckCircleIcon style={{ color: '#b5a9a9' }} />
                                            )}
                                        </div>
                                        <div className='icon col-xs-2'>
                                            <IconButton
                                                aria-controls={menuId}
                                                aria-haspopup='true'
                                                onClick={(e) => setMenuElement(e, state)}
                                                size='large'
                                            >
                                                <MoreHorizIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                ))}
                            </CardContent>
                        </Card>
                    </div>
                </div>

                <DialogFormAction
                    item={
                        <div>
                            <FormControl variant='standard' className='max-width'>
                                <InputLabel>Alert Template</InputLabel>
                                <Select
                                    variant='standard'
                                    value={parentId}
                                    renderValue={(value) => alertTemplates.find((x) => x.id === value)?.title || ''}
                                    onChange={(event) => {
                                        setParentId(event.target.value)
                                    }}
                                >
                                    {alertTemplates.map((item) => (
                                        <MenuItem key={item.id + 'key'} value={item.id}>
                                            {item.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    }
                    title='Create an alert'
                    text='Which kind of alert would you like to make?'
                    open={open}
                    buttonDisagreeLabel='Cancel'
                    buttonAgreeLabel='Create'
                    handleDisagree={handleClose}
                    handleAgree={handleSendToEditor}
                    handleClose={handleClose}
                    fullWidth={true}
                />

                <ConfirmAction
                    open={isDeleteActionOpen || isExpireActionOpen}
                    handleClose={handleDeclineAction}
                    title='Are you sure?'
                    text={
                        isExpireActionOpen
                            ? `This alert will be expired and its end date will be set to ${moment().format(
                                  'dddd, MMMM Do YYYY, h:mm:ss a'
                              )}`
                            : 'This alert will be deleted and will no longer render on your public website'
                    }
                    handleDisagree={handleDeclineAction}
                    handleAgree={handleConfirmAction}
                    isLoading={isLoading}
                />

                <RenderMenu
                    anchorElement={anchorElement}
                    menuId={menuId}
                    isMenuOpen={isMenuOpen}
                    setAnchorElement={setAnchorElement}
                    handleEdit={handleEdit}
                    setIsExpireActionOpen={setIsExpireActionOpen}
                    setIsDeleteActionOpen={setIsDeleteActionOpen}
                    actionItem={actionItem}
                />
            </PageContainerWithHeader>

            {newPage && (
                <CreateStructuredContent availableTypes={['alert']} open={newPage} onClose={() => setNewPage(false)} />
            )}
        </>
    )
}
