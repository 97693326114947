import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@mui/styles'
import {
    Badge,
    Card,
    CardContent,
    Checkbox,
    FormControl,
    IconButton,
    InputLabel,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Select,
    TextField
} from '@mui/material'
import { NIL as NIL_UUID } from 'uuid'
import { Format, notify, pagination } from '../../../helpers'
import axios from 'axios'
import CreateIcon from '@mui/icons-material/Create'
import DeleteIcon from '@mui/icons-material/Delete'
import DialogContentText from '@mui/material/DialogContentText'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import ForwardIcon from '@mui/icons-material/Forward'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import './Sites.css'
import TablePagination from '@mui/material/TablePagination'
import { OpenGallery } from '../../media/image/OpenGallery'
import { ImageGallery } from '../../media/image/ImageGallery'
import lodash from 'lodash'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { useAtom } from 'jotai'
import { sitesEventChanel } from '../../../common/components/selectors/SiteSelectorForAccount'
import { PhotoCameraOutlined } from '@mui/icons-material'
import { instagramErrorsAtom } from '../../../app/AppTopBar/InstagramErrorsBanner'
import { useAppContext, useCurrentSiteID } from '../../auth/atoms'
import { EntityScopeEnum } from '../../auth/entityScope'
import PageContainerWithHeader from '../../../common/components/PageContainerWithHeader'
import { useAppNavigation } from '../../../app/useAppNavigation'
import { BackButton } from '../../../common/components'
import { SiteAPI, TagAPI } from '../../../common/constants'
import { useStateWithStorage } from '@/common/storage.service'

export const mandatoryFields = ['email', 'phone', 'address', 'city', 'postal', 'province', 'country']

// defaultsite settings keys
// they are ignored because we do not show them in the key/value pair component list
// note: curator is deprecated
export const ignoredKeys = [
    'curator',
    'defaultSeoImage',
    'defaultSeoDescription',
    'defaultSeoTitle',
    'edsby',
    'outlook'
]

export function Sites() {
    const { navigateTo } = useAppNavigation()
    const evaluators = useAppContext()
    const currentSiteID = useCurrentSiteID()
    const [instagramErrors] = useAtom(instagramErrorsAtom)

    // Page States
    const [localState, setLocalState] = useState({})
    // const [localDialogueState, setLocalDialogueState] = useState([]);
    const [errors, setErrors] = useState({
        id: false,
        name: false,
        key: false,
        value: false,
        instagram: ''
    })

    // View States
    // 'setting' | 'secure'
    const [siteView, setSiteView] = useState('settings')
    const [modalOpen, setModalOpen] = useState(false)
    // const [dialogueOpen, setDialogueOpen] = useState(false);
    const [isImageGalleryOpen, setIsImageGalleryOpen] = useState(false)

    // Settings States
    const [settingsState, setSettingsState] = useState({})
    const settingsStateKeys = useMemo(
        () => Object.keys(settingsState).filter((x) => !ignoredKeys.includes(x)),
        [settingsState]
    )

    const [newSettingKey, setNewSettingKey] = useState('')
    const [newSettingValue, setNewSettingValue] = useState('')
    const [siteTags, setSiteTags] = useState([])

    // Secure Settings States
    const [secureSettingsState, setSecureSettingsState] = useState({})

    const limit = 10
    const [paginationState, setPaginationState] = useStateWithStorage('sites-grid', {
        data: [],
        offset: limit,
        perPage: limit,
        currentPage: 0,
        total: 0
    })

    // Tag States
    const [chosenSiteTags, setChosenSiteTags] = useState([])

    useEffect(() => {
        const data = axios
            .get(SiteAPI, {
                params: {
                    siteId: currentSiteID,
                    limit: paginationState.perPage,
                    page: 0
                }
            })
            .then((content) => content.data)
        const tenantSiteTags = axios
            .get(TagAPI, { params: { siteId: currentSiteID, tagType: 'site', limit: 999, page: 0 } })
            .then((tags) => tags.data)

        Promise.all([data, tenantSiteTags]).then((values) => {
            sitesEventChanel.emit('onSiteSecureSettingsUpdated')
            const sites = values[0].results
            for (const site of sites) {
                let tempArray = []
                const element = sites.find((x) => x.id === currentSiteID)
                if (element) {
                    const { settings, secure_settings } = element
                    setSettingsState(settings)
                    setSecureSettingsState(secure_settings)
                }
                for (const tagItem of site.tags) {
                    const elementsIndex = values[1].results.findIndex((element) => element.id === tagItem)
                    tempArray.push(values[1].results[elementsIndex])
                }
                site.tags = tempArray
            }

            setPaginationState((prev) => ({
                ...prev,
                total: values[0].resultset.total_records,
                data: sites,
                pageCount: Math.ceil(values[0].resultset.total_records / limit) // Total count x limit
            }))
            setSiteTags(values[1].results)
        })
    }, [])

    // We're currently not allowing the delete action on Sites.
    // This function kept here for record.

    //"Agree" Action in local dialogue component
    // const handleDialogueAgree = () => {
    //     axios.delete(
    //         SiteAPI,
    //         {data:localDialogueState}
    //         )
    //         .then(() => axios.get(
    //                 SiteAPI,
    //                 {params:{siteId:selectedSite, page: paginationState.currentPage , limit:paginationState.perPage}}
    //                 )
    //             .then((x) => {
    //                 setPaginationState(prev => ({
    //                     ...prev,
    //                     total:sites.data.resultset.total_records,
    //                     data: sites.data.results,
    //                     pageCount: Math.ceil(sites.data.resultset.total_records / limit), // Total count x limit
    //                 }));
    //             }));
    //     setDialogueOpen(false);
    // };

    //"Disagree" Action in local dialogue component
    // const handleDialogueDisagree = () => {
    //     setDialogueOpen(false);
    // };

    const [anchorElement, setAnchorElement] = useState(null)
    const [actionItem, setActionItem] = useState(null)
    const menuId = 'sitesMenu'

    const setMenuElement = (event, state) => {
        setAnchorElement(event.currentTarget)
        setActionItem(lodash.cloneDeep(state))
    }

    const menu = () => {
        if (!actionItem?.id) {
            return ''
        }
        const state = actionItem
        const handleEditSettings = () => {
            setAnchorElement(null)
            setModalOpen(true)
            setSiteView('settings')
            setSettingsState(state['settings'])
            setSecureSettingsState(state['secure_settings'])
            setLocalState(state)
            if (typeof state.tags[0] === 'object') {
                setChosenSiteTags(state['tags'])
            } else if (typeof state.tags[0] === 'string') {
                let tempArray = []
                for (const tagItem of state.tags) {
                    const elementsIndex = siteTags.findIndex((element) => element.id === tagItem)
                    if (elementsIndex >= 0) {
                        tempArray.push(siteTags[elementsIndex])
                    }
                }
                setChosenSiteTags(tempArray)
            } else {
                setChosenSiteTags([])
            }
        }
        const hasSiteSettings = evaluators.entityScopeAny(EntityScopeEnum.Settings, actionItem.id)
        const hasSettingsInstagram = evaluators.entityScopeAny(EntityScopeEnum.InstagramSettings, actionItem.id)
        return (
            <Menu
                anchorEl={anchorElement}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                id={menuId}
                keepMounted
                transformOrigin={{ vertical: 10, horizontal: 80 }}
                // getContentAnchorEl={null}
                open={Boolean(anchorElement)}
                onClose={() => setAnchorElement(null)}
            >
                {/*<div style={{ minWidth: '10rem' }}>*/}
                {/*    <MenuItem onClick={handleEditSettings} disabled={!hasSiteSettings}>*/}
                {/*        <ListItemIcon className={classes.iconSize}>*/}
                {/*            <CreateIcon />*/}
                {/*        </ListItemIcon>*/}
                {/*        <ListItemText>Edit</ListItemText>*/}
                {/*    </MenuItem>*/}
                {/*</div>*/}
                <div style={{ minWidth: '10rem' }}>
                    <MenuItem
                        onClick={() => navigateTo(`/system/sites/edit/${state.id}`)}
                        disabled={!hasSiteSettings && !hasSettingsInstagram}
                    >
                        <ListItemIcon className={classes.iconSize}>
                            <CreateIcon />
                        </ListItemIcon>
                        <ListItemText>Edit</ListItemText>
                    </MenuItem>
                </div>
            </Menu>
        )
    }

    const handleSiteSettingsDialogClose = () => {
        setModalOpen(false)
        setNewSettingKey('')
        setNewSettingValue('')
    }

    const handleChangeSiteView = (value) => {
        setSiteView(value)
    }

    // Settings
    const handleAddSetting = () => {
        if (settingsState[newSettingKey]) {
            notify("Uh-oh! There's already a key with that name!", 'error')
        } else if (newSettingKey.length <= 1 || newSettingValue.length <= 1) {
            newSettingKey.length <= 1 ? setErrors((prev) => ({ ...prev, key: true })) : console.log('')
            newSettingValue.length <= 1 ? setErrors((prev) => ({ ...prev, value: true })) : console.log('')
        } else {
            let tempObject = { ...settingsState, [newSettingKey]: newSettingValue }
            setSettingsState(tempObject)
            setNewSettingValue('')
            setNewSettingKey('')
        }
    }

    const handleRemoveSetting = useCallback(
        (setting) => {
            let temp = { ...settingsState } // deep copy
            delete temp[setting]
            if (mandatoryFields.includes(setting[0])) {
                notify('This is a mandatory field!')
            } else {
                setSettingsState(temp)
            }
        },
        [settingsState]
    )

    const handleChangeNewKey = (event) => {
        setNewSettingKey(event.target.value)
        setErrors((prev) => ({ ...prev, key: false }))
    }

    const handleChangeNewValue = (event) => {
        setNewSettingValue(event.target.value)
        setErrors((prev) => ({ ...prev, value: false }))
    }

    const handleEditValues = (event, setting) => {
        setSettingsState((prev) => ({
            ...prev,
            [setting]: event.target.value
        }))
    }

    const handleSaveError = (e) => {
        notify('Whoops! Error updating site settings, please try again later')
        if (e.response?.status === 400 && e?.response?.data.includes('instagramAccessToken is invalid')) {
            setErrors((prev) => ({ ...prev, instagram: e.response.data }))
        }
    }

    const handleSave = useCallback(() => {
        const handleRefreshSites = (data) => {
            sitesEventChanel.emit('onSiteSecureSettingsUpdated')
            const siteIndex = paginationState.data.findIndex((s) => s.id === data.id)
            if (siteIndex > -1) {
                let clone = lodash.clone(paginationState.data)
                clone.splice(siteIndex, 1, data)
                setPaginationState((prev) => ({ ...prev, data: clone }))
            }
        }

        let updateModel = null
        if (siteView === 'settings') {
            updateModel = {
                id: localState.id,
                settings: settingsState,
                tags: Format.objectsToIds(chosenSiteTags)
            }
        } else if (siteView === 'secure') {
            updateModel = { id: localState.id, secure_settings: secureSettingsState }
        }

        axios
            .put(SiteAPI, updateModel, { params: { siteId: currentSiteID } })
            .then(({ data }) => {
                handleRefreshSites(data)
                setModalOpen(false)
            })
            .catch((e) => handleSaveError(e))
    }, [
        chosenSiteTags,
        localState.id,
        paginationState.data,
        secureSettingsState,
        currentSiteID,
        settingsState,
        siteView
    ])

    const classes = useSiteSettingsStyles()
    return (
        <PageContainerWithHeader title='Sites' topRightElement={<BackButton route={'/'} />}>
            <Card className='sub-container'>
                <CardContent>
                    <div className='scaffolding'>
                        {paginationState.data.length < 1 ? (
                            <p className='empty-section'>There are no sites associated with this administrator</p>
                        ) : (
                            <div className='table-container'>
                                <div className='table-item'>
                                    <p className='four-font-weight'>Name</p>
                                    {evaluators.entityScopeAny(EntityScopeEnum.InstagramSettings) && (
                                        <p className=' four-font-weight site-options'>Options</p>
                                    )}
                                </div>

                                {paginationState.data.map((x) => (
                                    <div className='table-item' key={x.id + x.name}>
                                        <p className='site-name'>{x.name}</p>
                                        <div className={'site-options icon'}>
                                            {
                                                // TODO => NST-590: This technically removes functionality, however, I'm unsure if
                                                //  it's necessary to show an icon if a school has instagram.
                                                instagramErrors.includes(x.id) && (
                                                    <Badge
                                                        color='error'
                                                        badgeContent={'!'}
                                                        invisible={!instagramErrors.includes(x.id)}
                                                        title='Instagram Token Error'
                                                    >
                                                        <PhotoCameraOutlined
                                                            color={
                                                                !instagramErrors.includes(x.id) ? 'disabled' : 'error'
                                                            }
                                                        />
                                                    </Badge>
                                                )
                                            }
                                            <IconButton
                                                aria-controls={'pageMenu'}
                                                aria-haspopup='true'
                                                onClick={(e) => setMenuElement(e, x)}
                                                disabled={!evaluators.entityScopeAny(EntityScopeEnum.InstagramSettings)}
                                                size='large'
                                            >
                                                <MoreHorizIcon />
                                            </IconButton>
                                        </div>
                                        {/*}*/}
                                        {menu(x)}
                                    </div>
                                ))}
                            </div>
                        )}
                        <TablePagination
                            component='div'
                            count={paginationState.total}
                            page={paginationState.currentPage}
                            onPageChange={(event, newPage) =>
                                pagination.handleChangePage(
                                    event,
                                    newPage,
                                    setPaginationState,
                                    paginationState,
                                    currentSiteID,
                                    SiteAPI
                                )
                            }
                            rowsPerPage={paginationState.perPage}
                            onRowsPerPageChange={(event) =>
                                pagination.handleChangeRowsPerPage(
                                    event,
                                    setPaginationState,
                                    SiteAPI,
                                    paginationState.limit,
                                    currentSiteID,
                                    paginationState
                                )
                            }
                        />
                    </div>
                    <div>
                        <Dialog
                            open={modalOpen}
                            onClose={handleSiteSettingsDialogClose}
                            aria-labelledby='form-dialog-title'
                            maxWidth='md'
                            fullWidth={true}
                        >
                            <DialogTitle id='form-dialog-title'>
                                {localState && Object.keys(localState).length > 0
                                    ? localState.name
                                    : 'Update Site Settings'}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText>What settings would you like to add?</DialogContentText>

                                <div>
                                    <div>
                                        <div className={classes.settingsMap}>
                                            <TextField
                                                error={errors.key}
                                                value={newSettingKey}
                                                onChange={handleChangeNewKey}
                                                name='key'
                                                size='small'
                                                className={classes.textfield}
                                                label='key'
                                                variant='outlined'
                                                helperText={
                                                    errors.key ? 'Entry must be at least 2 characters long' : ''
                                                }
                                            />
                                            <ForwardIcon style={{ alignSelf: 'center' }} />
                                            <TextField
                                                error={errors.value}
                                                value={newSettingValue}
                                                onChange={handleChangeNewValue}
                                                name='value'
                                                size='small'
                                                className={classes.textfield}
                                                label='value'
                                                variant='outlined'
                                                helperText={
                                                    errors.value ? 'Entry must be at least 2 characters long' : ''
                                                }
                                            />
                                            <AddCircleIcon
                                                className='create-icon'
                                                style={{ alignSelf: 'center' }}
                                                onClick={handleAddSetting}
                                            />
                                        </div>

                                        <div
                                            className='drawer-seperator'
                                            style={{ marginTop: '2vh', marginBottom: '2vh' }}
                                        ></div>

                                        <DialogContentText>Current settings</DialogContentText>

                                        {settingsStateKeys.map((setting) => (
                                            <div key={setting} className={classes.settingsMap}>
                                                <TextField
                                                    error={false}
                                                    value={setting}
                                                    name={setting}
                                                    size='small'
                                                    className={classes.textfield}
                                                    label='key'
                                                    variant='outlined'
                                                    inputProps={{
                                                        readOnly: Boolean(true),
                                                        disabled: Boolean(true)
                                                    }}
                                                />
                                                <ForwardIcon style={{ alignSelf: 'center' }} />
                                                <TextField
                                                    error={false}
                                                    value={settingsState[setting]}
                                                    name='value'
                                                    size='small'
                                                    onChange={(event) => handleEditValues(event, setting)}
                                                    className={classes.textfield}
                                                    label='value'
                                                    variant='outlined'
                                                />
                                                {mandatoryFields.includes(setting) ? (
                                                    <DeleteIcon
                                                        style={{
                                                            alignSelf: 'center',
                                                            backgroundColor: 'transparent',
                                                            color: 'transparent'
                                                        }}
                                                        onClick={() => handleRemoveSetting(setting)}
                                                    />
                                                ) : (
                                                    <DeleteIcon
                                                        className='create-icon'
                                                        style={{ alignSelf: 'center' }}
                                                        onClick={() => handleRemoveSetting(setting)}
                                                    />
                                                )}
                                            </div>
                                        ))}

                                        <div
                                            className='drawer-seperator'
                                            style={{ marginTop: '2vh', marginBottom: '2vh' }}
                                        ></div>
                                        <div>
                                            <DialogTitle style={{ padding: '0', marginBottom: '10px' }}>
                                                Select Site Tags
                                            </DialogTitle>
                                        </div>
                                        <FormControl variant='outlined' style={{ width: '100%' }}>
                                            <InputLabel>Tags</InputLabel>
                                            <Select
                                                variant='standard'
                                                multiple
                                                label={'label'}
                                                placeholder={'Select tags...'}
                                                value={chosenSiteTags}
                                                onChange={(event) => {
                                                    setChosenSiteTags(event.target.value)
                                                }}
                                                renderValue={(selected) => {
                                                    let newArr = []
                                                    for (const item of selected) {
                                                        if (
                                                            item !== undefined &&
                                                            !newArr.includes(item.name) &&
                                                            item !== NIL_UUID
                                                        ) {
                                                            newArr.push(item.name)
                                                        }
                                                    }
                                                    return newArr.join(', ')
                                                }}
                                            >
                                                {siteTags.map((tag) => (
                                                    <MenuItem key={tag.id} value={tag}>
                                                        <Checkbox
                                                            checked={
                                                                chosenSiteTags.findIndex((x) => x.id === tag.id) > -1
                                                            }
                                                        />
                                                        <ListItemText primary={tag.name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                        <div
                                            className='drawer-seperator'
                                            style={{ marginTop: '2vh', marginBottom: '2vh' }}
                                        ></div>
                                        <div>
                                            <DialogTitle style={{ padding: '0', marginBottom: '10px' }}>
                                                Default Search Engine Optimization
                                            </DialogTitle>
                                        </div>
                                        <TextField
                                            label={'Default SEO Title'}
                                            className='max-width'
                                            style={{ margin: '10px 0 10px 0' }}
                                            variant={'outlined'}
                                            value={settingsState['defaultSeoTitle']}
                                            onChange={(e) => handleEditValues(e, 'defaultSeoTitle')}
                                        />
                                        <TextField
                                            label={'Default SEO Description'}
                                            className='max-width'
                                            style={{ margin: '10px 0 10px 0' }}
                                            variant={'outlined'}
                                            value={settingsState['defaultSeoDescription']}
                                            onChange={(e) => handleEditValues(e, 'defaultSeoDescription')}
                                        />
                                        <OpenGallery
                                            title={'Default SEO Image'}
                                            error={false}
                                            component={false}
                                            setCurrentComponent={() => {}}
                                            value={
                                                settingsState['defaultSeoImage']
                                                    ? `/images/${settingsState['defaultSeoImage']}`
                                                    : ''
                                            }
                                            removeSelectedMedia={() => {
                                                setSettingsState((prev) => ({ ...prev, defaultSeoImage: '' }))
                                            }}
                                            handleOpen={() => setIsImageGalleryOpen(true)}
                                            rootStyles={{ maxWidth: '100%' }}
                                        />

                                        <div
                                            className='drawer-seperator'
                                            style={{ marginTop: '2vh', marginBottom: '2vh' }}
                                        ></div>
                                    </div>

                                    {/*TODO => NST-590 */}
                                    {/*{siteView === "secure"*/}
                                    {/*    && <SecureSettings*/}
                                    {/*        secureSettingsState={secureSettingsState}*/}
                                    {/*        setSecureSettingsState={setSecureSettingsState}*/}
                                    {/*        mandatoryFields={mandatoryFields}*/}
                                    {/*        errors={errors}*/}
                                    {/*        setErrors={setErrors}*/}
                                    {/*    />*/}
                                    {/*}*/}
                                </div>
                            </DialogContent>
                            <div className={classes.buttonActions}>
                                {/*TODO => NST-590 */}
                                {/*<DialogActions >*/}
                                {/*    {siteView === "settings"*/}
                                {/*        ?*/}
                                {/*        <Button onClick={() => handleChangeSiteView('secure')} color="primary" >*/}
                                {/*            SECURE*/}
                                {/*        </Button>*/}
                                {/*        :*/}
                                {/*        <Button onClick={() => handleChangeSiteView('settings')} color="primary" >*/}
                                {/*            SETTINGS*/}
                                {/*        </Button>*/}
                                {/*    }*/}
                                {/*</DialogActions>*/}

                                <DialogActions>
                                    <Button onClick={handleSiteSettingsDialogClose} color='primary'>
                                        Cancel
                                    </Button>
                                    <Button onClick={handleSave} color='primary'>
                                        Save
                                    </Button>
                                </DialogActions>
                            </div>
                        </Dialog>
                    </div>

                    {/*<ConfirmAction*/}
                    {/*    open={dialogueOpen}*/}
                    {/*    title="Are you sure?"*/}
                    {/*    text="Please confirm if you'd like to delete this site"*/}
                    {/*    handleDisagree={handleDialogueDisagree}*/}
                    {/*    handleAgree={handleDialogueAgree}*/}
                    {/*    handleClose={handleDialogueDisagree}*/}
                    {/*/>*/}

                    {isImageGalleryOpen && (
                        <ImageGallery
                            open={isImageGalleryOpen}
                            setGalleryOpen={() => setIsImageGalleryOpen(true)}
                            close={() => setIsImageGalleryOpen(false)}
                            isForEditor={true}
                            onChange={(img) => setSettingsState((prev) => ({ ...prev, defaultSeoImage: `${img.id}` }))}
                        />
                    )}
                </CardContent>
            </Card>
        </PageContainerWithHeader>
    )
}

export const useSiteSettingsStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            outline: 0,
            marginTop: '0.5vh',
            marginBottom: '1vh',
            margin: 'auto',
            display: 'flex',
            justifyContent: 'column'
        }
    },
    button: {
        marginTop: '2vh',
        marginBottom: '2vh'
    },
    select: {
        width: '80%',
        margin: 'auto'
    },
    form: {
        marginTop: '1vh',
        height: '5%',
        width: '100%'
    },
    settingsMap: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    buttonActions: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between'
    },
    textfield: {
        marginTop: '1vh',
        marginBottom: '1vh'
    },
    iconSize: {
        minWidth: '35px'
    }
}))
