import { useCurrentSiteID } from '../pkgs/auth/atoms'
import { useNavigate } from 'react-router'
import type { NavigateOptions } from 'react-router/dist/lib/context'
import { Content, ContentType } from '../pkgs/content/types'

export function getContentEditorPath(content: Content | any) {
    const id = content?.id || content?.ID
    const structureID = content?.structureId || content?.StructureID
    const pageLayout = content?.pagelayout || content?.PageLayout
    const contentType = content?.type || content?.Type

    if (!id) return null

    let path = ''

    if (structureID) {
        path = '/content-editor/'
    } else if (contentType == ContentType.Page) {
        if (pageLayout == 'DCT') {
            path = '/dct/'
        } else {
            path = '/page/'
        }
    } else if (contentType == ContentType.News) {
        path = '/article/'
    } else if (contentType == ContentType.Event) {
        path = '/event/'
    }

    return path ? path + id : null
}

export const useAppNavigation = () => {
    const currentSiteID = useCurrentSiteID()
    const navigate = useNavigate()

    function getPath(path: string): URL {
        const url = new URL(path, window.location.origin)
        if (!url.searchParams.has('siteId')) {
            url.searchParams.set('siteId', currentSiteID || '')
        }

        return url
    }

    function getRelativePath(path: string): string {
        const url = getPath(path)
        return url.pathname + url.search
    }

    // Works with absolute/relative and internal/external links
    function navigateTo(to: string, options?: NavigateOptions, newWindow?: boolean) {
        const url = getPath(to)

        if (newWindow) {
            window.open(url.toString(), '_blank')
            return
        }

        const newPath = url.pathname + url.search
        navigate(newPath, options)
    }

    return {
        getPath,
        getRelativePath,
        navigateTo,
        goBack: () => navigate(-1)
    }
}
