import { AxiosError } from 'axios'
import { GridSortDirection } from '@mui/x-data-grid'
import { z } from 'zod'
// import { PagingQuery, SortingQuery, dateTime, paged, trackable, uuidSchema } from '../../../common/react-query'

// types, helpers, constants related to using react-query client

export const cancelOn401 = (count: number, error: AxiosError) => {
    if (error.message.includes('401') || error.message.includes('403') || error.message.includes('404')) {
        return false
    }

    if (count > 3) return false
    return true
}

export const baseQueryConfig = {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    retry: cancelOn401
}

export type PagingQuery = { page?: number; pageSize?: number }
export const defaultPageQuery: PagingQuery = { page: 1, pageSize: 10 }
export type SortingQuery = { sortingField?: string; sortingDirection?: GridSortDirection }
export const defaultSortingQuery: SortingQuery = { sortingField: undefined, sortingDirection: undefined }
export const dateTime = z.coerce.date()
export const uuidSchema = z.string().uuid()
export const paged = z.object({
    TotalRecords: z.number(),
    TotalPages: z.number(),
    Offset: z.number(),
    PageSize: z.number(),
    Page: z.number()
})
export type Paged<T> = z.infer<typeof paged> & { Rows: T[] }
export const trackable = z.object({
    ID: uuidSchema,
    CreatedAt: dateTime,
    CreatedBy: uuidSchema,
    UpdatedAt: dateTime,
    UpdatedBy: uuidSchema
})

export type Trackable = z.infer<typeof trackable>
