import { z } from 'zod'
import { base, ContentType } from '../content/types'
import { structure } from '../structure/types'
import { publishStatus } from '../content/editor/ContentEditorSaveCard'
import { paged, PagingQuery, SortingQuery, trackable } from '../../common/react-query'

export type ListsQuery = {
    Search?: string
    StructureID?: string
    Tags?: string[]
    Status?: publishStatus | ''
    Inactive?: boolean
    SiteOnly?: boolean
} & PagingQuery &
    SortingQuery

export const item = z.object({
    ContentID: z.string(),
    Overrides: z.any()
})

export const list = base.merge(trackable).extend({
    Active: z.boolean(),
    Name: z.string(),
    Description: z.string(),
    StructureID: z.string(),
    ContentTypes: z.array(z.union([z.literal('page'), z.literal('news'), z.literal('event'), z.literal('fragment')])),
    OverrideSections: z.union([z.array(z.string()), z.null()]).transform((val) => (val === null ? [] : val)),
    Template: z.string(),
    Tags: z.union([z.array(z.string()), z.null()]).transform((val) => (val === null ? [] : val)),

    Items: z.union([z.array(item), z.null()]).transform((val) => (val === null ? [] : val)),
    Structure: structure.nullish()
})

export const lists = paged.extend({
    Rows: z.array(list)
})

export type ContentTypeForList = ContentType.Page | ContentType.News | ContentType.Event | ContentType.Fragment

export type List = z.infer<typeof list>
export type Item = z.infer<typeof item>
