export function getMatchingSubstrings(stringToCheck: string, matcher: RegExp) {
    const accumulator: Record<string, [number, number]> = {}
    try {
        const matches = stringToCheck.matchAll(matcher)
        for (const match of matches) {
            const start = match?.index
            if (start == null || match?.[0] == null) {
                continue
            }
            const end = start + match[0].length
            accumulator[match[0]] = [start, end]
        }
    } catch (err) {
        console.warn(err)
    }
    return accumulator
}
