import { useState } from 'react'
import { CustomMenuItem } from './CustomMenu'
import DeleteIcon from '@mui/icons-material/Delete'
import { BaseContextMenuItemProps } from './types'
import { validateUUID } from '../../../helpers'
import { deleteContent } from '../../../pkgs/content/content.service'
import ConfirmAction from '../ConfirmAction'

export const DeleteCustomMenuItem = ({
    contentModel,
    onChange,
    closeMenu,
    disabled
}: BaseContextMenuItemProps & {
    closeMenu: () => void
    contentModel: Record<string, any>
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const handleDelete = async () => {
        try {
            if (!validateUUID(contentModel?.id)) {
                return Promise.reject({ message: 'invalid id' })
            }
            const response = await deleteContent('', contentModel)
            onChange?.(response)
            setClosed()
        } catch (err) {
            console.error(err)
            return Promise.reject(err)
        }
    }
    const setClosed = () => setIsOpen(false)
    const open = () => {
        setIsOpen(true)
        closeMenu?.()
    }
    return (
        <>
            <CustomMenuItem text={'Delete'} onClick={open} disabled={Boolean(disabled)}>
                <DeleteIcon />
            </CustomMenuItem>
            <ConfirmAction
                open={isOpen}
                title={`Delete ${contentModel?.title}`}
                text={'Are you sure you want to delete this?'}
                handleClose={setClosed}
                handleDisagree={setClosed}
                handleAgree={handleDelete}
            />
        </>
    )
}
