import { Base } from './types'
import {
    SiteSelectorForContent,
    SiteSelectorForContentProps
} from '../../common/components/selectors/SiteSelectorForContent'
import { entityScope } from '../auth/entityScope'
import {
    Box,
    FormControl,
    FormControlLabel,
    FormLabel,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select
} from '@mui/material'
import { useAppContext } from '../auth/atoms'
import { PublishPeriodControl } from './editor/components/PublishPeriodControl'
import React from 'react'
import PublishStatusLabel from './editor/components/PublishStatusLabel'
import { getPublishStatus, publishStatus } from './editor/ContentEditorSaveCard'
import Alert from '@mui/material/Alert'
import { PrivacyLevel } from '@/pkgs/content/PrivacyLevel'

interface BaseFormConfig {
    moreSiteSelectorProps?: Omit<
        SiteSelectorForContentProps,
        'ContentType' | 'Selected' | 'OnChange' | 'HasError' | 'ParentSitesIDs'
    >
}

interface BaseFormProps<T extends Base> {
    value: T | undefined
    onChange: (v: T) => void
    contentType: entityScope
    errors?: Partial<Record<keyof Base, string>>
    disabledFields?: Record<keyof Base, boolean> | boolean
    parentSitesIDs?: string[]
    config?: BaseFormConfig
}

export function BaseForm<T extends Base>({
    value,
    onChange,
    contentType,
    errors,
    parentSitesIDs,
    disabledFields
}: BaseFormProps<T>) {
    const evaluators = useAppContext()
    if (!value) {
        value = {} as T
    }

    React.useEffect(() => {
        if (!value) {
            value = {} as T
        }
        value.Sites = !value.Sites || value.Sites.length === 0 ? evaluators.getDefaultSitesForSelectors() : value.Sites
        value.PrivacyLevel = value.PrivacyLevel === undefined ? 0 : value.PrivacyLevel
        value.DepartmentID = value.DepartmentID || evaluators.getCurrentSiteDepartmentID()
    }, [])

    const isDisabled = (field: keyof Base) => {
        if (typeof disabledFields === 'boolean') return disabledFields
        return disabledFields && disabledFields[field]
    }

    return (
        <Box display='flex' flexDirection='column' gap='16px'>
            <SiteSelectorForContent
                ContentType={contentType}
                Selected={value?.Sites || []}
                OnChange={(sites) => value && onChange({ ...value, Sites: sites })}
                HasError={!!errors?.Sites}
                ParentSitesIDs={parentSitesIDs}
                Disabled={isDisabled('Sites')}
            />

            <PrivacyLevel
                value={value?.PrivacyLevel || 0}
                onChange={(v) => value && onChange({ ...value, PrivacyLevel: v })}
                isDisabled={Boolean(isDisabled('PrivacyLevel'))}
            />

            <FormControl sx={{ width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <FormLabel id='publish-period'>Publish Period: </FormLabel>
                    <FormLabel id='publish-status'>
                        <PublishStatusLabel publishAt={value.PublishAt} expireAt={value.ExpireAt} />
                    </FormLabel>
                </div>
                <PublishPeriodControl
                    value={{ PublishAt: value.PublishAt, ExpireAt: value.ExpireAt }}
                    onChange={(period) => {
                        value && onChange({ ...value, ...period })
                    }}
                    disabled={isDisabled('PublishAt') || isDisabled('ExpireAt')}
                />
            </FormControl>
        </Box>
    )
}

export function StatusSelector({
    value,
    onChange
}: {
    value: publishStatus | '' | undefined | null
    onChange: (v: publishStatus | '') => void
}) {
    return (
        <FormControl variant={'outlined'} sx={{ width: '100%' }}>
            <InputLabel id={'structure-selector'}>Status</InputLabel>
            <Select
                value={value || ''}
                onChange={(e) => {
                    onChange(e.target.value as publishStatus | '')
                }}
                labelId='structure-selector'
                label={'Status'}
            >
                <MenuItem value={''}>Any</MenuItem>
                <MenuItem value={'draft'}>Draft</MenuItem>
                <MenuItem value={'published'}>Published</MenuItem>
                <MenuItem value={'scheduled'}>Scheduled</MenuItem>
                <MenuItem value={'expired'}>Expired</MenuItem>
            </Select>
        </FormControl>
    )
}
