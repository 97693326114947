import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { alpha, styled } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Toolbar,
    Typography
} from '@mui/material'
import AppLeftDrawer from '../AppLeftDrawer'
import { authenticationService } from '../../pkgs/auth/authentication.service'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import MenuIcon from '@mui/icons-material/Menu'
import { AppbarMobileMenu } from './AppbarMobileMenu'
import { AppbarHelp } from './AppbarHelp'
import MuiAppBar from '@mui/material/AppBar'
import { initInterceptor, SiteSelectorForAccount } from '../../common/components/selectors/SiteSelectorForAccount'
import { useAtom } from 'jotai'
import { bannerHeightAtom, InstagramErrorsBanner } from './InstagramErrorsBanner'
import { identityAtom, useAppContext, useCurrentSiteID } from '../../pkgs/auth/atoms'
import BugReportIcon from '@mui/icons-material/BugReport'
import { EntityScopeEnum } from '../../pkgs/auth/entityScope'

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        display: 'none',
        cursor: 'pointer',
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        }
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25)
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto'
        }
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    inputRoot: {
        color: 'inherit'
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch'
        }
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    chooseSite: {
        display: 'flex',
        alignItems: 'center',
        transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderRadius: '14px',
        margin: '2px',
        padding: '6px',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
        },
        '&:focus': {
            borderRadius: 0,
            backgroundColor: '#2196f3'
        }
    },
    select: {
        borderColor: 'white',
        color: 'white',
        '&:focus': {
            background: 'none'
        }
    },
    disabledSelect: {
        color: 'white',
        cursor: 'no-drop !important',
        '-webkit-text-fill-color': '#ffffffbd !important'
    }
}))

export const drawerWidth = 264 // enough so user management is in one line (with scroll bar
export const StyledPrimaryAppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open'
    //    @ts-ignore
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        })
    })
}))

const UserInfoHelp = () => {
    const evaluators = useAppContext()
    const [isOpen, setIsOpen] = useState(false)
    const [tab, setTab] = useState(0)
    const identity = evaluators.identity()
    const currentSiteID = evaluators.currentSiteID
    if (!identity) {
        return null
    }
    return (
        <>
            <IconButton onClick={() => setIsOpen(!isOpen)} size={'large'} color={'inherit'}>
                <BugReportIcon />
            </IconButton>

            <Dialog onClose={() => setIsOpen(false)} open={isOpen}>
                <DialogTitle>{identity.Email || ''}</DialogTitle>
                <DialogContent>
                    {tab === 0 && <pre>{JSON.stringify(identity || {}, null, 4)}</pre>}
                    {tab === 1 && (
                        <pre>
                            {JSON.stringify(
                                identity.Groups.filter((x) => x.SiteID === currentSiteID || x.SiteID === null).map(
                                    (x) => x?.Role || {}
                                ),
                                null,
                                4
                            )}
                        </pre>
                    )}
                    {tab === 2 && (
                        <pre>
                            {Object.entries(EntityScopeEnum).map(([name, scope]) => {
                                return (
                                    <p>
                                        {name}: {String(evaluators.entityScopeAny(scope))}
                                    </p>
                                )
                            })}
                        </pre>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button disabled={tab == 0} variant='text' onClick={() => setTab(0)}>
                        Overview
                    </Button>
                    <Button disabled={tab == 1} variant='text' onClick={() => setTab(1)}>
                        Current Roles
                    </Button>
                    <Button disabled={tab == 2} variant='text' onClick={() => setTab(2)}>
                        Current Scopes
                    </Button>
                    <Button variant='text' onClick={() => setIsOpen(false)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default function AppTopBar({ open, toggleDrawer }) {
    const classes = useStyles()
    const navigate = useNavigate()
    const [bannerHeight] = useAtom(bannerHeightAtom)
    const [identity, setIdentity] = useAtom(identityAtom)
    const currentSiteID = useCurrentSiteID()
    const appContext = useAppContext()

    const endSession = async () => {
        await authenticationService.logout()
        initInterceptor(null)
        setIdentity(undefined)
    }
    if (!identity?.Email) {
        return null
    }

    return (
        <div className={classes.grow}>
            <InstagramErrorsBanner />
            {/*@ts-ignore*/}
            <StyledPrimaryAppBar position={'fixed'} open={open} style={{ marginTop: `${bannerHeight}px` }}>
                <Toolbar>
                    <IconButton
                        edge='start'
                        className={classes.menuButton}
                        color='inherit'
                        aria-label='open drawer'
                        onClick={toggleDrawer}
                        size='large'
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                        data-testid='open-drawer'
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        onClick={() => {
                            navigate(`/?siteId=${currentSiteID}`)
                        }}
                        className={classes.title}
                        variant='h6'
                        noWrap
                    >
                        Content Manager
                    </Typography>
                    <div className={classes.grow} />

                    <div style={{ display: 'flex' }}>
                        <SiteSelectorForAccount />
                    </div>
                    <div className={classes.sectionDesktop}>
                        <AppbarHelp mobile={false} />
                        {<UserInfoHelp />}
                        <IconButton
                            onClick={endSession}
                            aria-label='Logout'
                            color='inherit'
                            size='large'
                            data-testid={'logout'}
                        >
                            <PowerSettingsNewIcon />
                        </IconButton>
                    </div>

                    <div className={classes.sectionMobile}>
                        <AppbarMobileMenu />
                    </div>
                </Toolbar>
            </StyledPrimaryAppBar>
            {appContext.tenantID && <AppLeftDrawer isOpen={!!open} toggleDrawer={toggleDrawer} />}
        </div>
    )
}
