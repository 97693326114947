import React, { FC, useEffect } from 'react'
import { useAppContext } from '../auth/atoms'
import { httpPost } from '../../common/client'
import { z } from 'zod'
import { notify } from '../../helpers'
import { guessErrorMessage } from '../../helpers/guessErrorMessage'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { StructureSelector } from '../structure/StructureSelector'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import { FormFromFragmentPreview } from './FormFromFragmentPreview'
import { ContentType } from '../content/types'
import { BASE, emailRegex } from '../../common/constants'
import { ContentExplorerDialog } from '@/pkgs/content/explorer/ContentExplorerDialog'

interface SelectContactFormDialogProps {
    open: boolean
    value: string
    onChange: (value: string) => void
    onClose: () => void
    types?: Array<'fragment' | 'email'>
    allowedStructures?: string[] | null
}

export const SelectContactFormDialog: FC<SelectContactFormDialogProps> = ({
    open,
    value,
    onChange,
    onClose,
    types = ['fragment', 'email'],
    allowedStructures
}) => {
    const appContext = useAppContext()
    const [loading, setLoading] = React.useState(false)
    const [structureID, setStructureID] = React.useState<string | undefined>(undefined)
    const [fragmentID, setFragmentID] = React.useState<string | undefined>(undefined)
    const [email, setEmail] = React.useState<string | undefined>(undefined)
    const [emailError, setEmailError] = React.useState<string | undefined>(undefined)
    const [mode, setMode] = React.useState<'email' | 'fragment'>(types.includes('email') ? 'email' : 'fragment')
    const [openContentExplorer, setOpenContentExplorer] = React.useState(false)

    useEffect(() => {
        const { structure, fragment, email } = parseFormLink(value)
        setStructureID(structure)
        setFragmentID(fragment)
        if (fragment && types.includes('fragment')) {
            setMode('fragment')
        }
        if (email) {
            ;(async () => {
                setLoading(true)
                const decrypted = await convertEmail('decrypt', email)
                setEmail(decrypted)
                setLoading(false)
            })()
        }
    }, [])

    const setLink = async () => {
        if (!structureID) {
            notify('Please select a structure', 'error')
            return
        }

        if (mode === 'fragment') {
            if (!fragmentID) {
                notify('Please select a fragment', 'error')
                return
            }
            onChange(`/sys/api/forms?fragment_id=${fragmentID}`)
            onClose()
            return
        } else {
            if (!email || !emailRegex.test(email)) {
                setEmailError('Please enter a valid email')
                notify('Please enter a valid email', 'error')
                return
            }
            setLoading(true)
            const encrypted = await convertEmail('encrypt', email)
            setLoading(false)
            if (!encrypted) {
                notify(`can't encrypt email`, 'error')
                return
            }
            onChange(`/sys/api/forms?structure_id=${structureID}&to=${encrypted}`)
            onClose()
        }
    }

    return (
        <div>
            {open && (
                <Dialog open={open} keepMounted onClose={onClose}>
                    <DialogTitle>Link to a Contact Form</DialogTitle>
                    <DialogContent style={{ minWidth: '450px' }}>
                        <FormControl fullWidth sx={{ my: 1 }}>
                            <StructureSelector
                                value={structureID || ''}
                                onChange={(id) => setStructureID(id)}
                                onlyContactForms={true}
                                allowedStructures={allowedStructures}
                                required={true}
                            />
                        </FormControl>

                        {structureID && types.length > 1 && (
                            <FormControl fullWidth sx={{ my: 1 }}>
                                <InputLabel id='content-type'>Mode</InputLabel>
                                <Select
                                    labelId='content-type'
                                    value={mode}
                                    label='Mode'
                                    onChange={(e) => setMode(e.target.value as 'email' | 'fragment')}
                                >
                                    {types.includes('email') && <MenuItem value={'email'}>Email</MenuItem>}
                                    {types.includes('fragment') && <MenuItem value={'fragment'}>Fragment</MenuItem>}
                                </Select>
                            </FormControl>
                        )}

                        {mode === 'email' && structureID && (
                            <FormControl fullWidth sx={{ my: 1 }}>
                                <TextField
                                    fullWidth
                                    label='Email'
                                    value={email || ''}
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                        emailError && emailRegex.test(e.target.value) && setEmailError(undefined)
                                    }}
                                    disabled={loading}
                                    error={!!emailError}
                                    helperText={emailError}
                                />
                            </FormControl>
                        )}

                        {mode === 'fragment' && fragmentID && (
                            <FormControl fullWidth sx={{ my: 1 }}>
                                <FormFromFragmentPreview id={fragmentID} onClick={() => setOpenContentExplorer(true)} />
                            </FormControl>
                        )}
                        {structureID && mode === 'fragment' && !fragmentID && (
                            <FormControl fullWidth sx={{ my: 1 }}>
                                <Button
                                    onClick={() => setOpenContentExplorer(true)}
                                    variant='contained'
                                    color='primary'
                                >
                                    {fragmentID ? 'Change the' : 'Select a'} fragment
                                </Button>
                            </FormControl>
                        )}
                        {structureID && openContentExplorer && (
                            <ContentExplorerDialog
                                isOpen={openContentExplorer}
                                onClose={() => setOpenContentExplorer(false)}
                                selected={fragmentID ? [fragmentID] : undefined}
                                contentTypes={[ContentType.Fragment]}
                                sites={!!appContext.currentSiteID ? [appContext.currentSiteID] : []}
                                onSelect={(id) => {
                                    setFragmentID(id)
                                    setOpenContentExplorer(false)
                                }}
                                structureID={structureID}
                            />
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color='primary'>
                            Cancel
                        </Button>
                        <Button
                            disabled={loading || !structureID}
                            onClick={setLink}
                            variant='contained'
                            color='primary'
                        >
                            Set link
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    )
}

export function parseFormLink(urlPart: string) {
    // Create a URL object using a base since urlPart might be a relative URL
    try {
        const url = new URL(urlPart, 'http://example.com')

        // Extract parameters from the URL search parameters
        const structure = url.searchParams.get('structure_id') || undefined
        const fragment = url.searchParams.get('fragment_id') || undefined
        const email = url.searchParams.get('to') || undefined

        // Return the extracted parameters, will be undefined if not present
        return {
            structure,
            fragment,
            email
        }
    } catch (error) {
        return {
            structure: undefined,
            fragment: undefined,
            email: undefined
        }
    }
}

export const convertEmail = async (action: 'encrypt' | 'decrypt', email: string) => {
    try {
        const res = await httpPost(`${BASE}/api/v2/forms/${action}`, { Email: email }, z.object({ Email: z.string() }))
        return res?.Email
    } catch (error) {
        notify(guessErrorMessage(error), 'error')
    }
}
