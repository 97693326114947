import { z } from 'zod'
import React, { useEffect, useState } from 'react'
import { clientStorage, httpGet, imagesRowsPerPageKey } from '../../../common/client'
import { notify } from '../../../helpers'
import TablePagination from '@mui/material/TablePagination'
import { useNavigate } from 'react-router-dom'
import {
    Box,
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    Dialog,
    IconButton,
    TextField,
    Typography
} from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import { TagsSelector } from '../../grid/selectors/TagsSelector'
import { MediaEditorDialog } from './MediaEditor'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import PhotoSizeSelectLargeOutlinedIcon from '@mui/icons-material/PhotoSizeSelectLargeOutlined'
import { storage, useStateWithStorage } from '../../../common/storage.service'
import { useAppContext, useCurrentSiteID } from '../../auth/atoms'
import { EntityScopeEnum } from '../../auth/entityScope'
import PageContainerWithHeader from '../../../common/components/PageContainerWithHeader'
import { PagingQuery, dateTime, paged } from '../../../common/react-query'
import { ImagesAPI, MEDIA_THUMBNAILS } from '../../../common/constants'
import { TagType } from '../../system/tags/types'

export type MediaQuery = {
    Search?: string
    Tags?: string[]
} & PagingQuery

export const pgUUID = z.object({
    UUID: z.string().nullish(),
    Valid: z.boolean()
})

const image = z.object({
    id: z.string(),
    // active: z.boolean(),
    alt: z.string(),
    content_id: pgUUID,
    created: dateTime,
    deleted: dateTime,
    department_id: z.string().nullish(),
    filename: z.string(),
    // path: z.string().nullish(),
    tags: z.array(z.string()).nullish(),
    sites: z.array(z.string()),
    type: z.string(),
    updated: dateTime
})
const images = paged.extend({
    Rows: z.array(image)
})

export type Image = z.infer<typeof image>
export type Images = z.infer<typeof images>

type MediaExplorerProps =
    | {
          mode: 'select'
          onSelect: (image: Image) => void
          trigger?: boolean
      }
    | {
          mode?: 'edit'
          trigger?: boolean
      }

type MediaExplorerPreviewSize = 'small' | 'large'

const previewSizes: Record<MediaExplorerPreviewSize, Record<string, number>> = {
    large: {
        width: 300,
        height: 250
    },
    small: {
        width: 165,
        height: 125
    }
}

const defaultQuery = { page: 1, pageSize: 20 }

export const MediaExplorer = (props: MediaExplorerProps) => {
    const [previewSize, setPreviewSize] = useState<MediaExplorerPreviewSize>('small')
    const [query, setQuery] = useStateWithStorage<MediaQuery>('media-explorer-query', defaultQuery)
    const [state, setState] = useState<Images | undefined>(undefined)
    const [selected, setSelected] = useState<Image | undefined>(undefined)
    const currentSiteID = useCurrentSiteID()
    const evaluators = useAppContext()
    const navigate = useNavigate()

    useEffect(() => {
        setQuery({ ...query })
    }, [currentSiteID])

    useEffect(() => {
        const previewSize = storage.getItem('mediaPreviewSize') as MediaExplorerPreviewSize
        if (previewSize) setPreviewSize(previewSize)
    }, [])

    useEffect(() => {
        if (!currentSiteID) return

        httpGet(ImagesAPI, query, images)
            .then(setState)
            .catch((err) => {
                console.error(err)
                notify(err)
            })
    }, [query, props.trigger])

    function Paginater() {
        return state ? (
            <TablePagination
                component='div'
                count={state.TotalRecords}
                page={state.Page - 1}
                rowsPerPageOptions={[20, 50, 100]}
                onPageChange={(e, page) => setQuery({ ...query, page: page + 1 })}
                rowsPerPage={state.PageSize}
                onRowsPerPageChange={(e) => {
                    setQuery({ ...query, pageSize: parseInt(e.target.value) })
                    clientStorage.setItem(imagesRowsPerPageKey, parseInt(e.target.value))
                }}
            />
        ) : null
    }

    return (
        <PageContainerWithHeader
            title='Images'
            topRightElement={
                props.mode !== 'select' && evaluators.entityScopeAny(EntityScopeEnum.Image) ? (
                    <Button
                        startIcon={<AddCircleOutlineIcon />}
                        style={{ color: '#4285f4' }}
                        onClick={() => navigate(`/media-uploader?siteId=${currentSiteID}`)}
                    >
                        Upload images
                    </Button>
                ) : undefined
            }
        >
            <div className={'row'}>
                <div className={'col-md-6'}>
                    <TextField
                        style={{ flex: '1' }}
                        onChange={(event) => setQuery((prev) => ({ ...prev, Search: event.target.value }))}
                        value={query.Search || ''}
                        label={'Search'}
                        placeholder='Search'
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position={'start'}>
                                    <SearchIcon></SearchIcon>
                                </InputAdornment>
                            )
                        }}
                        variant={'outlined'}
                    />
                </div>
                <div className={'col-md-6'}>
                    <TagsSelector
                        value={query?.Tags || []}
                        onChange={(tags) => setQuery({ ...query, page: 1, Tags: tags })}
                        tagType={TagType.Media}
                    />
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-md-12'}>
                    <Button
                        style={{ float: 'right' }}
                        color={'primary'}
                        onClick={() => {
                            setQuery(defaultQuery)
                        }}
                    >
                        Reset Filters
                    </Button>
                </div>
            </div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <Box
                    sx={{
                        display: 'flex'
                    }}
                >
                    <p>Resize: </p>
                    <IconButton
                        sx={{
                            '&:hover': {
                                backgroundColor: 'lightgray'
                            },
                            color: previewSize == 'small' ? 'primary.main' : undefined,
                            height: '90%'
                        }}
                        onClick={() => {
                            const newPreviewSize = previewSize == 'small' ? 'large' : 'small'
                            setPreviewSize(newPreviewSize)
                            storage.setItem('mediaPreviewSize', newPreviewSize)
                        }}
                    >
                        <PhotoSizeSelectLargeOutlinedIcon />
                    </IconButton>
                </Box>
                <Paginater />
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', p: 1, m: 1 }}>
                {state?.Rows.map((img) => (
                    <ImageCard
                        key={img.id}
                        image={img}
                        onClick={(img) => {
                            if (props.mode === 'select') props.onSelect(img)
                            else if (evaluators.entityScopeAny(EntityScopeEnum.Image)) setSelected(img)
                            else notify('You do not have permission to edit this image', 'error')
                        }}
                        previewSize={previewSize}
                    />
                ))}
            </Box>
            <Paginater />

            {selected && (
                <MediaEditorDialog
                    image={selected}
                    onClose={() => {
                        setSelected(undefined)
                        setQuery({ ...query })
                    }}
                />
            )}
        </PageContainerWithHeader>
    )
}

type ImageCardProps = {
    image: Image
    onClick?: (image: Image) => void
    previewSize: MediaExplorerPreviewSize
}
const ImageCard = ({ image, onClick, previewSize }: ImageCardProps) => {
    return (
        <Card key={image.id} sx={{ maxWidth: previewSizes[previewSize].width, p: 1, m: 1 }}>
            <CardActionArea onClick={() => onClick && onClick(image)}>
                <div
                    style={{
                        transition: '.25s ease-in-out',
                        height: previewSizes[previewSize].height,
                        width: previewSizes[previewSize].width,
                        backgroundImage: `url(${MEDIA_THUMBNAILS}/${
                            image.id
                        }?updatedAt=${image.updated.toISOString()}})`,
                        // backgroundImage: `url(${MEDIA_IMAGES}/${image.id})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundColor: '#f5f5f5'
                    }}
                ></div>
                {image.sites.length > 1 && (
                    <span
                        className='material-icons-outlined'
                        style={{
                            fontSize: '1.4rem',
                            color: '#6c6c6c',
                            position: 'relative',
                            bottom: '0.2rem',
                            right: '0.1rem'
                        }}
                    >
                        group
                    </span>
                )}
            </CardActionArea>
            <CardContent>
                <Typography variant={'body1'}>{image.alt}</Typography>
                <Typography variant={'body2'}>{image.filename}</Typography>
                {previewSize == 'large' ? <Typography variant={'body2'}>{image.id}</Typography> : null}
            </CardContent>
            <CardActions style={{ justifyContent: 'center' }}></CardActions>
        </Card>
    )
}

type MediaExplorerDialogProps = {
    open: boolean
    onClose: () => void
    onSelect: (image: Image) => void
}

export const MediaExplorerDialog = ({ open, onClose, onSelect }: MediaExplorerDialogProps) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth={'lg'} fullWidth>
            <MediaExplorer mode={'select'} onSelect={onSelect} />
        </Dialog>
    )
}
