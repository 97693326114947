import moment from 'moment'

// const isToTimeStringRegex = /([0-9]{2}):([0-9]{2}):([0-9]{2}) (GMT)(([+-])[0-9]{4}) (\()([a-zA-Z ]{0,})(\))+/g
const timeRegex = /([0-9]{2}:[0-9]{2}:[0-9]{2})+/g
const isToTimeStringRegex = /([0-9]{2}:[0-9]{2}:[0-9]{2} GMT[+-][0-9]{4} \([a-zA-Z ]{0,}\))+/g
export const Thursday = 4

export const millisecondsToDays = (milliseconds) => {
    return milliseconds / (1000 * 60 * 60 * 24)
}
export const daysBetweenDates = (fromDate, toDate) => {
    const diff = new Date(toDate).getTime() - new Date(fromDate).getTime()
    return millisecondsToDays(diff)
}
export const isDateGreater = (date, compareTo) => {
    return Date.parse(new Date(date).toString()) > Date.parse(new Date(compareTo).toString())
}
export function isToday(test) {
    if (!(test instanceof Date)) {
        test = new Date(test)
    }
    const today = new Date()
    return (
        today.getDate() === test.getDate() &&
        today.getMonth() === test.getMonth() &&
        today.getFullYear() === test.getFullYear()
    )
}
export function getDaysForMonth(day, month) {
    const days = []
    const d = new Date()

    if (month) {
        d.setMonth(month, 1)
        if (month === 12) month = 0
    } else {
        month = d.getMonth()
    }
    d.setDate(1)
    while (d.getDay() !== day) {
        d.setDate(d.getDate() + 1)
    }
    while (d.getMonth() === month) {
        days.push(new Date(d.getTime()))
        d.setDate(d.getDate() + 7)
    }
    return days
}
export function getNextDate(dayNumber, interval) {
    let date = dateHelpers.getDaysForMonth(dayNumber)?.[interval]
    if (!date) return ''
    if (dateHelpers.isDateGreater(new Date(), date)) {
        date = dateHelpers.getDaysForMonth(dayNumber, new Date().getMonth() + 1)?.[interval]
    }
    return new Date(date)
}
export function renderNextThirdThursday() {
    const date = dateHelpers.getNextDate(Thursday, 2)
    let fmtDate = moment(date).format('LL')
    if (dateHelpers.isToday(fmtDate)) {
        fmtDate = `Today, ${fmtDate}`
    }
    return fmtDate
}
export function timeStringToUTC(dateLike) {
    try {
        if (!isToTimeStringRegex.test(dateLike))
            throw `invalid date time format (${dateLike}) \n expected new Date().toTimeString() format`
        if (dateLike.includes('GMT+0000')) return dateLike

        const year = new Date().getFullYear()?.toString()
        const segments = new Date().toString().split(year)
        if (!segments.length) throw `Invalid Date (${dateLike}) \n unable to split date string `
        const parseAttempt = new Date(`${segments.shift()} ${year} ${dateLike}`)
        if (parseAttempt.toString() === 'Invalid Date') throw `Invalid Date (${dateLike})`
        const match = timeRegex?.exec(parseAttempt?.toISOString())?.shift()
        if (!match) throw `Invalid Date (${dateLike}) \n unable to match time of date-like string`
        return `${match} GMT+0000 (Coordinated Universal Time)`
    } catch (e) {
        const { message = '' } = e || {}
        return { value: dateLike, error: message }
    }
}
export function isDate(dateLike) {
    try {
        return !isNaN(Date.parse(dateLike || ''))
    } catch {
        return false
    }
}
export function isDateTimeStringFormat(dateLike) {
    return isToTimeStringRegex.test(String(dateLike || ''))
}

export const dateHelpers = {
    isDate,
    isDateTimeStringFormat,
    isToday,
    isDateGreater,
    timeStringToUTC,
    millisecondsToDays,
    daysBetweenDates,
    getDaysForMonth,
    getNextDate
}
