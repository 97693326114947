import { useState, useEffect, RefObject } from 'react'
import { contentValidators } from '../validators'
import { Content } from '../types'
import { notify } from '../../../helpers'
export const useStructuredContentValidators = (
    componentsToValidate: Array<keyof Content>,
    state: Content | undefined,
    formRendererRef?: RefObject<any>
) => {
    const [stateLoaded, setStateLoaded] = useState(false)
    const [errors, setErrors] = useState<Partial<Record<keyof Content, string>>>({})

    useEffect(() => {
        if (!state || stateLoaded) return
        setStateLoaded(true)
    }, [state, stateLoaded])

    for (const cp of componentsToValidate) {
        useEffect(() => {
            if (!state || !stateLoaded) return

            setErrors((p) => ({ ...p, ...contentValidators[cp](state) }))
        }, [state?.[cp]])
    }

    const validateAll = () => {
        if (!state) {
            return false
        }

        const validationErrors: Partial<Record<keyof Content, string>> = {}
        for (const cp of componentsToValidate) {
            Object.assign(validationErrors, contentValidators[cp](state))
        }

        // Perform formRendererRef validation if it is provided and current
        if (formRendererRef?.current && typeof formRendererRef.current.validateAndReturnErrors === 'function') {
            const [components] = formRendererRef.current.validateAndReturnErrors()
            console.log('FormRenderer errors', components)
            if (Array.isArray(components) && components.length) {
                validationErrors.Data = 'Structure Form has errors'
            }
        }

        setErrors(validationErrors)

        if (Object.keys(validationErrors).some((key) => !!validationErrors[key])) {
            notify(
                <>
                    <div>
                        <h4>Errors found</h4>
                        <ul>
                            {Object.keys(validationErrors)
                                .filter((key) => !!validationErrors[key])
                                .map((key) => (
                                    <li key={key}>
                                        <strong>{key}</strong>:&nbsp;
                                        {validationErrors[key]}
                                    </li>
                                ))}
                        </ul>
                    </div>
                </>,
                'error'
            )
            return false
        }

        return true
    }

    return { validateAll, errors, setErrors }
}
