import {
    CellMenu,
    DateCell,
    EditorsCell,
    EventsDatesCell,
    NotesCell,
    NullableDateCell,
    PrivacyLevelCell,
    PublishPeriodCell,
    ReorderMenu,
    TemplateCell,
    TitleCell
} from './GridCells'
import React from 'react'
import { storage } from '../../../common/storage.service'
import { getGridDateOperators, getGridStringOperators } from '@mui/x-data-grid'
import { ContentType } from '../../content/types'

// for News/Events tables
export const getColumns = (contentType, setMenuElement) => {
    const persistColumnVisibility = ({ field, isVisible }) => {
        console.log(field, isVisible)
        storage.setItem(`pages-visibility-${contentType}-${field}`, !isVisible)
    }

    const restoreColumnVisibility = (field, def) => {
        const restored = storage.getItem(`pages-visibility-${contentType}-${field}`)
        return restored === false || restored === true ? restored : def
    }

    const titles = (def) => ({
        headerName: 'Title',
        field: 'title',
        hide: restoreColumnVisibility('title', def),
        filterable: false,
        sortable: true,
        disableColumnMenu: true,
        flex: 3,
        renderCell: (cellParams) => <TitleCell {...cellParams} />
    })
    const editors = (def) => ({
        headerName: 'Author/Editor',
        field: 'editor',
        hide: restoreColumnVisibility('editor', def),
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        renderCell: (cellParams) => <EditorsCell {...cellParams} />
    })

    const startDate = (def) => ({
        headerName: 'Start',
        field: 'startDate',
        hide: restoreColumnVisibility('startDate', def),
        filterable: false,
        sortable: true,
        disableColumnMenu: true,
        flex: 1,
        renderCell: (cellParams) => <EventsDatesCell {...cellParams} settingsName={'startdate'} />
    })

    const status = (def) => ({
        headerName: 'Status',
        field: 'publishPeriod',
        hide: restoreColumnVisibility('publishPeriod', def),
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        flex: 2,
        renderCell: (cellParams) => (
            <PublishPeriodCell publishAt={cellParams.row['publish_at']} expireAt={cellParams.row['expire_at']} />
        )
    })

    const endDate = (def) => ({
        headerName: 'End',
        field: 'endDate',
        hide: restoreColumnVisibility('endDate', def),
        filterable: false,
        sortable: true,
        disableColumnMenu: true,
        flex: 1,
        renderCell: (cellParams) => <EventsDatesCell {...cellParams} settingsName={'enddate'} />
    })

    const updated = (def) => ({
        headerName: 'Date',
        field: 'updated',
        hide: restoreColumnVisibility('updated', def),
        filterable: true,
        sortable: true,
        type: 'dateCustom',
        disableColumnMenu: false,
        flex: 1,
        renderCell: (cellParams) => <DateCell {...cellParams} />
    })

    const publishAt = (def) => ({
        headerName: 'Publish At',
        field: 'publish_at',
        hide: restoreColumnVisibility('publish_at', def),
        filterable: false,
        sortable: true,
        type: 'dateCustom',
        disableColumnMenu: false,
        flex: 1.3,
        renderCell: (cellParams) => <NullableDateCell fieldName={'publish_at'} {...cellParams} />
    })

    const expireAt = (def) => ({
        headerName: 'Expire At',
        field: 'expire_at',
        hide: restoreColumnVisibility('expire_at', def),
        filterable: false,
        sortable: true,
        type: 'dateCustom',
        disableColumnMenu: false,
        flex: 1.3,
        renderCell: (cellParams) => <NullableDateCell fieldName={'expire_at'} {...cellParams} />
    })

    const template = (def) => ({
        headerName: 'Template',
        field: 'template',
        hide: restoreColumnVisibility('template', def),
        filterable: true,
        sortable: false,
        type: 'containsText',
        disableColumnMenu: false,
        flex: 1,
        renderCell: (cellParams) => <TemplateCell {...cellParams} />
    })

    const pageLayout = (def) => ({
        headerName: 'Layout',
        field: 'pagelayout',
        hide: restoreColumnVisibility('pagelayout', def),
        filterable: true,
        sortable: true,
        disableColumnMenu: false,
        flex: 1,
        align: 'center',
        headerClassName: 'MuiHeader-Col-AlignEnd',
        type: 'singleSelect',
        valueOptions: ['WYSIWYG', 'DCT', 'HTML']
    })

    const privacyLevel = (def) => ({
        headerName: 'Privacy Level',
        field: 'privacyLevel',
        hide: restoreColumnVisibility('privacyLevel', def),
        filterable: true,
        sortable: false,
        disableColumnMenu: false,
        flex: 1,
        align: 'center',
        type: 'singleSelect',
        headerAlign: 'center',
        valueOptions: [
            'public',
            'staff'
            // 'undefined',
        ],
        renderCell: (cellParams) => <PrivacyLevelCell {...cellParams} />
    })

    const notes = (def) => ({
        headerName: 'Notes',
        field: 'notes',
        hide: restoreColumnVisibility('notes', def),
        filterable: false,
        sortable: false,
        flex: 1,
        disableColumnMenu: true,
        headerAlign: 'center',
        align: 'center',
        renderCell: (cellParams) => <NotesCell {...cellParams} />
    })

    const actions = (def) => ({
        headerName: 'Actions',
        field: 'menu',
        hide: restoreColumnVisibility('menu', def),
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        renderCell: (cellParams) => <CellMenu {...cellParams} setMenu={setMenuElement} />,
        headerAlign: 'center',
        align: 'center'
    })

    switch (contentType) {
        case ContentType.News:
            return {
                columns: [
                    titles(false),
                    editors(false),
                    updated(false),
                    publishAt(true),
                    expireAt(true),
                    status(true),
                    template(false),
                    pageLayout(true),
                    notes(false),
                    privacyLevel(true),
                    actions(false)
                ],
                persistColumnVisibility
            }
        case ContentType.Event:
            return {
                columns: [
                    titles(false),
                    editors(false),
                    updated(false),
                    publishAt(true),
                    expireAt(true),
                    startDate(false),
                    endDate(false),
                    status(true),
                    template(false),
                    pageLayout(true),
                    notes(false),
                    privacyLevel(true),
                    actions(false)
                ],
                persistColumnVisibility
            }
        default:
            return {
                columns: [
                    titles(false),
                    editors(false),
                    updated(false),
                    publishAt(true),
                    expireAt(true),
                    template(false),
                    pageLayout(true),
                    status(true),
                    notes(false),
                    privacyLevel(true),
                    actions(false)
                ],
                persistColumnVisibility
            }
    }
}

export const getColumnsTypes = () => {
    const dateCustomColumnType = {
        extendType: 'date',
        filterOperators: getGridDateOperators()
            .filter((operator) => operator.value === 'onOrAfter' || operator.value === 'onOrBefore')
            .map((operator) => {
                return {
                    ...operator
                }
            })
    }

    const containsOnlyOperatorForString = {
        extendType: 'string',
        filterOperators: getGridStringOperators()
            .filter((operator) => operator.value === 'contains')
            .map((operator) => {
                return {
                    ...operator
                }
            })
    }

    return { containsText: containsOnlyOperatorForString, dateCustom: dateCustomColumnType }
}

export const getPinnedNewsColumns = ({ pinnedNews, setMenuElement, handleReorder }) => {
    const titles = {
        headerName: 'Title',
        field: 'title',
        hide: false,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        flex: 2,
        renderCell: (cellParams) => <TitleCell {...cellParams} />
    }

    const editors = {
        headerName: 'Author/Editor',
        field: 'editor',
        hide: false,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        renderCell: (cellParams) => <EditorsCell {...cellParams} />
    }

    const updated = {
        headerName: 'Date',
        field: 'updated',
        hide: false,
        filterable: false,
        sortable: false,
        type: 'dateCustom',
        disableColumnMenu: true,
        flex: 1,
        renderCell: (cellParams) => <DateCell {...cellParams} />
    }

    const notes = {
        headerName: 'Notes',
        field: 'notes',
        hide: false,
        filterable: false,
        sortable: false,
        flex: 1,
        disableColumnMenu: true,
        headerAlign: 'center',
        align: 'center',
        renderCell: (cellParams) => <NotesCell {...cellParams} />
    }

    const actions = {
        headerName: 'Actions',
        field: 'menu',
        hide: false,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        renderCell: (cellParams) => <CellMenu {...cellParams} setMenu={setMenuElement} />,
        headerAlign: 'center',
        align: 'center'
    }
    const reorder = {
        headerName: 'Reorder',
        field: 'reorder',
        hide: false,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        renderCell: (cellParams) => (
            <ReorderMenu {...cellParams} pinnedNews={pinnedNews} handleReorder={handleReorder} />
        ),
        headerAlign: 'center',
        align: 'center'
    }

    return [titles, editors, updated, notes, actions, reorder]
}
