import { is } from './is'

export function tryParseJson(jsonString): { data: Record<any, any>; error: boolean } {
    try {
        if (jsonString === '') {
            return {
                data: {},
                error: false
            }
        }
        const v = JSON.parse(jsonString)
        return {
            data: v,
            error: false
        }
    } catch (e) {
        //@ts-ignore
        const { message = '' } = e || {}
        return { error: true, data: { message } }
    }
}

export function mustString(potential): string {
    if (!potential) return ''
    if (typeof potential === 'string') return potential
    if (is.object(potential) || is.array(potential)) return JSON.stringify(potential, null, 2)
    return String(potential || '')
}
