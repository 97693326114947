import { useState } from 'react'
import { Alert, Box, Card, CardContent, Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import CreateIcon from '@mui/icons-material/Create'
import { ToastContainer } from 'react-toastify'
import { notify } from '../../helpers'
import formatStateToArrayOfString from '../../helpers/FormatStateToArrayOfString'
import ConfirmAction from '../../common/components/ConfirmAction'
import ArchiveIcon from '@mui/icons-material/Archive'
import moment from 'moment'
import InfoIcon from '@mui/icons-material/Info'
import 'react-datepicker/dist/react-datepicker.css'
import '../../styles/tooltip.css'
import BusStatusDialogFormAction from './BusStatusDialogFormAction'
import { useAppContext, useCurrentSiteID } from '../auth/atoms'
import { EntityScopeEnum } from '../auth/entityScope'
import { useBusRouteQueryMutation, useBusStatusQueryMutation } from './queries'
import { useTopicsQuery } from '../notifications/topics/queries'
import { guessErrorMessage } from '../../helpers/guessErrorMessage'

export const formatStatusType = (type) => {
    switch (type) {
        case 'delayed':
            return 'Delayed'
        case 'cancelled':
            return 'Cancelled'
        case 'delayed_mechanical':
            return 'Delayed - Mechanical'
        case 'cancelled_mechanical':
            return 'Cancelled - Mechanical'
        default:
            return 'On Time'
    }
}

export const BusStatus = ({ open, classes, handleClose }) => {
    const currentSiteID = useCurrentSiteID()
    const evaluators = useAppContext()

    const topicsQuery = useTopicsQuery({
        Active: true,
        TopicType: 'bus_alert',
        pageSize: 1
    })

    // Check for historic status'
    const [historicChecked, setHistoricChecked] = useState(false)
    // Check for viewing all status' without SiteID
    const [isAllChecked, setIsAllChecked] = useState(false)

    //Edit
    const [editState, setEditState] = useState({})

    // Dialog States
    const [editDialogOpen, setEditDialogOpen] = useState(false)
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

    const { busRouteData, getRouteIdMap } = useBusRouteQueryMutation(currentSiteID, !isAllChecked)

    const busRouteIdToRoute = getRouteIdMap()

    const {
        busStatusData,
        deleteMutation: deleteBusStatusMutation,
        refetch: refetchBusStatus
    } = useBusStatusQueryMutation(currentSiteID, historicChecked, !isAllChecked, undefined, undefined, () => {
        notify('Success! Bus status has been archived', 'info')
        refetchBusStatus()
        handleDialogueDisagree()
    })

    const handleDelete = () => {
        deleteBusStatusMutation.mutate({ ...editState, routes: formatStateToArrayOfString((editState as any).routes) })
    }

    const handleHistoricCheckbox = () => {
        setHistoricChecked(!historicChecked)
    }

    const formatRouteNames = (routes) => {
        let newArr: any[] = []

        // should not happen
        if (!routes) return newArr

        for (const route of routes) {
            newArr.push(busRouteIdToRoute?.[route]?.name || '')
        }

        if (newArr.length > 2) {
            let clone = newArr.slice(0, 2)
            return clone.join(', ') + '....'
        }
        return newArr.join(', ')
    }

    const handleDialogueDisagree = () => {
        setDeleteDialogOpen(false)
        setEditDialogOpen(false)
    }

    // Icons found on rows
    const Icons = (state) => {
        console.log(state)
        const handleDeleteOpen = () => {
            setDeleteDialogOpen(true)
            setEditState(state)
        }

        const handleEditOpen = () => {
            setEditDialogOpen(true)
            setEditState(state)
        }

        return (
            <Box display='flex' justifyContent='flex-end'>
                <div className='tooltip'>
                    <InfoIcon className='create-icon' />
                    <span className='tooltiptext'>{state.description.length > 0 ? state.description : state.type}</span>
                </div>
                <div className='tooltip'>
                    <span className='tooltiptext'>Edit this status</span>
                    {state.active ? (
                        <CreateIcon onClick={handleEditOpen} className='create-icon' />
                    ) : (
                        <CreateIcon className='disabled-icon' />
                    )}
                </div>
                {state.active && evaluators.entityScopeAny(EntityScopeEnum.BusStatus) ? (
                    <div className='tooltip'>
                        <span className='tooltiptext'>Archive this status</span>
                        <ArchiveIcon onClick={handleDeleteOpen} className='create-icon' />
                    </div>
                ) : (
                    <ArchiveIcon className='disabled-icon' />
                )}
            </Box>
        )
    }

    if (topicsQuery.isLoading) return <div>Loading...</div>
    if (topicsQuery.isError)
        return <Alert severity={'error'}>Error loading topics: {guessErrorMessage(topicsQuery.error)}</Alert>

    const showNotificationFeatureFlag = (topicsQuery.data?.Rows?.length || 0) > 0

    return (
        <div>
            <Card className='sub-container'>
                <CardContent>
                    <div className='flex-row' style={{ justifyContent: 'space-between' }}>
                        <div className='flex-row'>
                            {evaluators.entityScopeAny(EntityScopeEnum.BusStatus) && (
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={() => {
                                                    setIsAllChecked(!isAllChecked)
                                                }}
                                                checked={isAllChecked}
                                                name='first'
                                            />
                                        }
                                        label="Show All District Status'"
                                    />
                                </FormGroup>
                            )}
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={handleHistoricCheckbox}
                                            checked={historicChecked}
                                            name='first'
                                        />
                                    }
                                    label="Show Historic Status'"
                                />
                            </FormGroup>
                        </div>
                    </div>

                    <ToastContainer />

                    {!busStatusData || busStatusData.length < 1 ? (
                        <p className='empty-section'>There are no statuses associated with this school</p>
                    ) : (
                        <div className='table-container'>
                            <div className='table-item'>
                                <p className='four-font-weight'>Status</p>
                                <p className=' four-font-weight second-column'>Routes</p>
                                <p className='third-column four-font-weight'>Began</p>
                                <p className='fourth-column four-font-weight'>Ended</p>
                                <p className='fifth-column four-font-weight'>Options</p>
                            </div>
                            {busStatusData.map((state) => (
                                <div key={'bus-status-' + state?.id} className='table-item'>
                                    <p>{formatStatusType(state.type)}</p>
                                    <p className='second-column' style={{ gridColumnEnd: 'span 2' }}>
                                        {formatRouteNames(state.routes)}
                                    </p>
                                    <p className='third-column'>
                                        {moment(state.startdate.Time).format('M[/]D - h:mm a')}
                                    </p>
                                    <p className='fourth-column'>
                                        {state.enddate.Valid
                                            ? moment(state.enddate.Time).format('M[/]D - h:mm a')
                                            : 'Ongoing'}
                                    </p>
                                    <div className='icon fifth-column'>{Icons(state)}</div>
                                </div>
                            ))}
                        </div>
                    )}
                </CardContent>
            </Card>

            {open && (
                <BusStatusDialogFormAction
                    open={open}
                    action='create'
                    classes={classes}
                    isAllChecked={isAllChecked}
                    historicChecked={historicChecked}
                    showNotificationFeatureFlag={showNotificationFeatureFlag}
                    handleDisagree={handleClose}
                    handleClose={handleClose}
                />
            )}

            {editDialogOpen && (
                <BusStatusDialogFormAction
                    open={editDialogOpen}
                    action='edit'
                    classes={classes}
                    isAllChecked={isAllChecked}
                    historicChecked={historicChecked}
                    initialBusStatus={editState}
                    showNotificationFeatureFlag={showNotificationFeatureFlag}
                    handleDisagree={() => {
                        setEditDialogOpen(false)
                    }}
                    handleClose={() => {
                        setEditDialogOpen(false)
                    }}
                />
            )}

            {/*Delete*/}
            <ConfirmAction
                open={deleteDialogOpen}
                title='Are you sure?'
                text="Please confirm if you'd like to delete this area"
                handleDisagree={handleDialogueDisagree}
                handleAgree={handleDelete}
                handleClose={handleDialogueDisagree}
            />
        </div>
    )
}
export default BusStatus
