import React from 'react'
import '../../pkgs/content/grids/PageTableHeader.css'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
    fourthColumn: {
        gridColumnStart: 4,
        fontWeight: 400,
        textAlign: 'center'
    }
})

const TableHeader = (props) => {
    const { alternative, permissions, style, first, second, third } = props
    const classes = useStyles()
    return (
        <div className='table-item' style={style}>
            <p className='four-font-weight'>{first}</p>

            {!alternative && (
                <p className=' page-table-text' className={classes.fourthColumn}>
                    {second}
                </p>
            )}
            {alternative && <p className=' four-font-weight site-options'>{second}</p>}

            {third && <p className='tertiary-column four-font-weight'>{third}</p>}
        </div>
    )
}

export default TableHeader
