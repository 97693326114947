import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { $ElementProps } from '../notifications/TypeHelpers'
import { DataGrid, GridColumnVisibilityModel, GridSortModel } from '@mui/x-data-grid'
import { clientStorage } from '../../common/client'
import { DataGridCustomToolbar } from './Manager'
import { Paged, PagingQuery, SortingQuery, defaultSortingQuery } from '../../common/react-query'

export const DataGridBase = <T extends PagingQuery & SortingQuery>({
    state,
    setQuery,
    getRowId = (row) => row.ID,
    ...params
}: {
    state: Paged<any> | undefined
    setQuery: Dispatch<SetStateAction<T>>
} & Omit<$ElementProps<typeof DataGrid>, 'rows'>) => {
    const cacheKey = 'v3-' + window.location.pathname

    const [visibilityModel, setVisibilityModel] = useState(
        clientStorage.getItem<GridColumnVisibilityModel>(`${cacheKey}.columnVisibilityModel`) || {}
    )
    const [sortModel, setSortModel] = useState(clientStorage.getItem<GridSortModel>(`${cacheKey}.gridSortModel`) || [])

    useEffect(() => {
        if (sortModel.length > 0) {
            setQuery((prev) => ({ ...prev, ...mapSorting(sortModel) }))
        }
    }, [])

    const mapSorting = (sortModel: GridSortModel): SortingQuery => {
        if (sortModel.length > 0) {
            const sorting = sortModel[0]
            return {
                sortingField: sorting.field,
                sortingDirection: sorting.sort
            }
        }
        return defaultSortingQuery
    }

    return (
        <DataGrid
            {...params}
            paginationMode={'server'}
            sortingMode={'server'}
            filterMode={'server'}
            pageSizeOptions={[10, 20, 50, 100]}
            onColumnVisibilityModelChange={(model) => {
                clientStorage.setItem(`${cacheKey}.columnVisibilityModel`, model)
                setVisibilityModel(model)
            }}
            columnVisibilityModel={visibilityModel}
            rows={state?.Rows || []}
            rowCount={state?.TotalRecords}
            paginationModel={state ? { pageSize: state.PageSize, page: state.Page - 1 } : undefined}
            onPaginationModelChange={
                state
                    ? (model) => {
                          if (model.page !== state.Page - 1 || model.pageSize !== state.PageSize) {
                              const page = model.pageSize !== state.PageSize ? 1 : model.page + 1
                              setQuery((prev) => ({ ...prev, page: page, pageSize: model.pageSize }))
                          }
                      }
                    : undefined
            }
            onSortModelChange={(model) => {
                clientStorage.setItem(`${cacheKey}.gridSortModel`, model)
                setSortModel(model)
                setQuery((prev) => ({ ...prev, ...mapSorting(model) }))
            }}
            sortModel={sortModel}
            disableRowSelectionOnClick={true}
            getRowId={getRowId}
            autoHeight={true}
            slots={{
                toolbar: DataGridCustomToolbar
            }}
        />
    )
}
