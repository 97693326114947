import React, { ReactNode, useMemo, useState } from 'react'
import {
    Collapse,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from '@mui/material'
import { Link } from 'react-router-dom'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import './AppLeftDrawer.css'
import { clientStorage } from '../common/client'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import { SetState } from '../pkgs/departments/department.service'
import { useAtom } from 'jotai'
import { bannerHeightAtom } from './AppTopBar/InstagramErrorsBanner'
import { drawerWidth } from './AppTopBar/AppTopBar'
import { useAppContext } from '../pkgs/auth/atoms'
import { entityScope } from '../pkgs/auth/entityScope'
import usePath from './usePath'
import { DrawerItem, pagesIndex, useDrawerItems } from './routes'
import { colours } from '../common/colours'
import { useAppNavigation } from './useAppNavigation'
import GradeIcon from '@mui/icons-material/Grade'
import { AppVersion } from './AppVersion'

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'space-between'
}))

export const isTestDomain = () => {
    // return window?.location?.host.includes('localhost') || window?.location?.host.includes('imagineeverything.ca')
    return true
}

interface PrimaryDrawerProps {
    isOpen: boolean
    toggleDrawer: SetState
}

export default function AppLeftDrawer({ isOpen, toggleDrawer }: PrimaryDrawerProps) {
    const { getRelativePath } = useAppNavigation()
    const [bannerHeight] = useAtom(bannerHeightAtom)
    const { isCurrentPage } = usePath()
    const drawerItems = useDrawerItems()

    return (
        <Drawer
            variant='persistent'
            anchor='left'
            open={isOpen}
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    background: `${colours.off_white} 0% 0% no-repeat padding-box`,
                    opacity: '1',
                    marginTop: `${bannerHeight}px`
                },
                '& .MuiPaper-root': {
                    color: '#666666'
                },
                '& .MuiListItem-root': {
                    color: 'inherit'
                }
            }}
        >
            <DrawerHeader>
                <div className='drawer-logo'></div>
                <IconButton onClick={toggleDrawer}>
                    <ChevronLeftIcon />
                </IconButton>
            </DrawerHeader>
            <Divider />
            <List sx={{ paddingBottom: '58px' }}>
                {drawerItems.map((drawerItem, idx) => {
                    if (drawerItem == null) {
                        return <Divider key={idx} sx={{ mt: '1rem', mb: '1rem' }} />
                    }

                    if (drawerItem.enabled === false) {
                        return undefined
                    }

                    if (drawerItem.path == null) {
                        if (!drawerItem?._children?.length) {
                            throw Error('A drawerItem with path set to null must have at least one child')
                        }

                        if (!drawerItem.customLabel) {
                            throw Error('CustomLabel must be defined for a drawerItem with children')
                        }

                        return (
                            <PrimaryDrawerListItemWithDrawer
                                key={idx}
                                label={drawerItem.customLabel}
                                icon={drawerItem?.icon?.()}
                                items={drawerItem._children}
                            />
                        )
                    }

                    return (
                        <DrawerListItem
                            key={drawerItem.path}
                            text={drawerItem?.customLabel || pagesIndex[drawerItem.path].title}
                            entityScope={pagesIndex[drawerItem.path].authorization?.entityScope || undefined}
                            to={getRelativePath(drawerItem.path)}
                            selected={isCurrentPage(drawerItem.path)}
                            unread={drawerItem.isUnread ? true : false}
                        >
                            {drawerItem.icon ? (drawerItem.icon() as JSX.Element) : undefined}
                        </DrawerListItem>
                    )
                })}
            </List>
            <AppVersion />
        </Drawer>
    )
}

interface DrawerListItemProps {
    to: string
    text: string
    entityScope?: entityScope | entityScope[]
    hideOnDepartment?: boolean
    selected?: boolean
    unread?: boolean
    children?: JSX.Element
}

function DrawerListItem({ to, text, entityScope, hideOnDepartment, selected, unread, children }: DrawerListItemProps) {
    const evaluators = useAppContext()

    if (hideOnDepartment && evaluators.isCurrentSiteDepartment()) return null

    if (entityScope) {
        if (Array.isArray(entityScope)) {
            if (!entityScope.some((scope) => evaluators.entityScopeAny(scope))) {
                return null
            }
        } else if (!evaluators.entityScopeAny(entityScope)) {
            return null
        }
    }

    return (
        <ListItem disablePadding component={Link} to={to}>
            <ListItemButton selected={selected || false}>
                {Boolean(children) && <ListItemIcon>{children}</ListItemIcon>}
                <ListItemText primary={text} />
                {unread ? <GradeIcon sx={{ color: colours.orange }} /> : undefined}
            </ListItemButton>
        </ListItem>
    )
}

interface PrimaryDrawerListItemWithDrawerProps {
    label: string
    icon: ReactNode
    items: DrawerItem[] // paths
}

function PrimaryDrawerListItemWithDrawer({ label, icon, items }: PrimaryDrawerListItemWithDrawerProps) {
    const { getRelativePath } = useAppNavigation()
    const { isCurrentPage } = usePath()
    const [drawerOpen, setDrawerOpen] = useState(
        !!items.find((item) => !!item.path && window?.location?.href?.includes(item.path))
    )

    const enabledItems = useMemo(() => items.filter((item) => item.enabled !== false), [items])

    if (!enabledItems.length) return null

    return (
        <>
            <ListItem disablePadding>
                <ListItemButton
                    onClick={(e) => {
                        e.stopPropagation()
                        setDrawerOpen(!drawerOpen)
                        clientStorage.setItem('primaryDrawerItemIsOpen' + label, !drawerOpen)
                    }}
                >
                    <ListItemIcon>{icon}</ListItemIcon>
                    <span className={'flex-row-between'} style={{ width: '100%' }}>
                        <ListItemText primary={label} />
                        {drawerOpen ? <ExpandLess /> : <ExpandMore />}
                    </span>
                </ListItemButton>
            </ListItem>
            <Collapse in={drawerOpen} timeout='auto' unmountOnExit>
                <List
                    component='div'
                    disablePadding
                    style={{
                        borderLeft: '1px solid grey',
                        marginLeft: '16px'
                    }}
                    sx={{
                        '& .MuiListItem-root': {
                            color: 'inherit'
                        }
                    }}
                >
                    {enabledItems.map((item) => {
                        return (
                            <ListItem
                                key={item.path}
                                disablePadding
                                // @ts-ignore
                                component={Link}
                                to={!!item.path ? getRelativePath(item.path) : item.path}
                                selected={isCurrentPage(item.path!)}
                            >
                                <ListItemButton>
                                    <ListItemText
                                        primary={item.customLabel || pagesIndex[item.path!].title}
                                        sx={{
                                            textTransform: 'capitalize'
                                        }}
                                    />
                                </ListItemButton>
                            </ListItem>
                        )
                    })}
                </List>
            </Collapse>
        </>
    )
}
