import { Container, ContainerProps } from '@mui/material'
import { ReactNode } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Fallback } from '@/pkgs/errors/Fallback'

export interface PageContainerProps extends ContainerProps {
    children: ReactNode
}

export function PageContainer({ children, ...containerProps }: PageContainerProps) {
    return (
        <Container {...containerProps} maxWidth={containerProps?.maxWidth || 'xl'}>
            <ErrorBoundary FallbackComponent={Fallback}>{children}</ErrorBoundary>
        </Container>
    )
}

export default PageContainer
