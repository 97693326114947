import React from 'react'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { Button } from '@mui/material'
import { useAppNavigation } from '../../app/useAppNavigation'

export const BackButton = ({ label = 'Back', route, state }: { label?: string; route?: string; state?: any }) => {
    const { navigateTo, goBack } = useAppNavigation()

    return (
        <Button
            sx={{
                '& .MuiButton-startIcon': {
                    marginBottom: '3px',
                    marginRight: '4px'
                }
            }}
            startIcon={<ChevronLeftIcon />}
            style={{ color: '#4285f4' }}
            onClick={() => (route ? navigateTo(route, { state: state }) : goBack())}
        >
            {label}
        </Button>
    )
}

export default BackButton
