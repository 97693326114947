import { List } from './types'
import { Base, isContentLikeContent } from '../content/types'
import {
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Grid
} from '@mui/material'
import { BaseForm } from '../content/BaseForm'
import { EntityScopeEnum } from '../auth/entityScope'
import TextField from '@mui/material/TextField'
import { StructureSelector } from '../structure/StructureSelector'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Button from '@mui/material/Button'
import { httpPost, httpPut } from '../../common/client'
import { _isEqual, notify } from '../../helpers'
import { guessErrorMessage } from '../../helpers/guessErrorMessage'
import { SchemaSectionsSelector } from '../structure/SchemaSectionsSelector'
import Alert from '@mui/material/Alert'
import { CodeEditor } from '../monaco/code-editor'
import { TagsSelectorForContent } from '../../common/components/selectors/TagSelectorForContent'
import { ConfirmAction } from '../../common/components'
import { BASE } from '../../common/constants'
import { TagType } from '../system/tags/types'

export const availableContentTypes = ['fragment', 'page', 'news', 'event'] as const

export type ListFormData = Base &
    Pick<
        List,
        | 'ID'
        | 'Name'
        | 'Description'
        | 'StructureID'
        | 'Template'
        | 'ContentTypes'
        | 'Structure'
        | 'OverrideSections'
        | 'Tags'
    >
type Payload = Omit<ListFormData, 'ID'>

interface ListFormProps {
    value: ListFormData | undefined
    open: boolean
    // We separate onClose and onSave to prevent users from accidentally closing the dialog and potentially erasing their unsaved changes
    onClose: () => void
    onSave: () => void
}

const defaultValue: ListFormData = {
    Description: '',
    ID: '',
    Name: '',
    Structure: null,
    StructureID: '',
    ContentTypes: [],
    Template: '{{ListItems}}',
    PublishAt: new Date(),
    OverrideSections: [],
    Sites: [],
    Tags: [],
    PrivacyLevel: 0
}

const listFormStateValidators = {
    Sites: (sites: string[]) => (!!sites.length ? null : 'A site must be selected'),
    StructureID: (id: string) => (!!id ? null : 'Structure is required'),
    ContentTypes: (contentTypes: string[]) => (!!contentTypes.length ? null : 'At least one content type is required')
}

function getStateValidation(state: ListFormData): Partial<Record<keyof ListFormData, string>> {
    return Object.keys(listFormStateValidators).reduce(
        (acc, stateFieldName) => ({
            ...acc,
            [stateFieldName]: listFormStateValidators[stateFieldName](state[stateFieldName]) || ''
        }),
        {} as Record<string, any>
    )
}

export const ListForm = ({ value, open, onClose, onSave }: ListFormProps) => {
    const mode = value ? 'edit' : 'create'

    const [state, setState] = useState<ListFormData>(value || defaultValue)
    const [stateErrors, setStateErrors] = useState<Record<string, string>>({})
    const [confirmCloseDialogIsOpen, setConfirmCloseDialogIsOpen] = useState(false)

    const hasChanges = useMemo(
        () => (value == undefined ? !_isEqual(state, defaultValue, ['Sites']) : !_isEqual(state, value, ['Sites'])),
        [value, state]
    )

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (value) {
            setState(value)
        }
    }, [value])

    const updateStateErrors = useCallback(
        (key, value) => {
            setStateErrors((p) => ({ ...p, [key]: value }))
        },
        [setStateErrors]
    )

    function handleDialogOnClose() {
        if (hasChanges) {
            setConfirmCloseDialogIsOpen(true)
        } else {
            onClose()
        }
    }

    const handleSave = () => {
        if (!state) return

        const errors = getStateValidation(state)
        setStateErrors((p) => ({ ...p, ...errors }))
        if (Object.values(errors).find((v) => !!v)) {
            return
        }

        setLoading(true)
        const payload: Payload = {
            Name: state.Name,
            Description: state.Description,
            StructureID: state.StructureID,
            Template: state.Template,
            Tags: state.Tags,
            ContentTypes: state.ContentTypes,
            OverrideSections: state.OverrideSections,
            // Base
            Sites: state.Sites,
            DepartmentID: state.DepartmentID,
            PrivacyLevel: state.PrivacyLevel,
            PublishAt: state.PublishAt,
            ExpireAt: state.ExpireAt
        }
        const promise =
            mode === 'edit'
                ? httpPut(`${BASE}/api/v1/lists/${state.ID}`, payload)
                : httpPost(`${BASE}/api/v1/lists`, payload)

        promise
            .then(() => {
                notify('Saved!', 'info')
                setLoading(false)
                onSave()
                if (mode === 'create') {
                    onClose?.()
                }
            })
            .catch((e) => {
                setLoading(false)
                const msg = guessErrorMessage(e)
                if (msg.includes('Name')) {
                    setStateErrors({ ...stateErrors, Name: 'This name is already in use' })
                }
                notify(msg, 'error')
                if (msg.includes('name') && msg.includes('unique')) {
                    updateStateErrors('Name', 'This name is already in use')
                }
            })
    }

    const disabledContentType = (contentType: (typeof availableContentTypes)[number]) => {
        if (mode === 'edit') return true
        if (contentType === 'fragment' && state && state.ContentTypes.some(isContentLikeContent)) return true
        if (contentType !== 'fragment' && state && state.ContentTypes.includes('fragment')) return true
        return false
    }

    return (
        <Dialog
            open={open}
            onClose={() => {
                handleDialogOnClose()
            }}
            fullWidth
            maxWidth={'xl'}
        >
            <DialogTitle>{`${mode.toUpperCase()} ${state.Name || ''}`}</DialogTitle>
            <DialogContent>
                <Grid
                    container
                    spacing={2}
                    component='form'
                    onSubmit={(ev) => {
                        ev.preventDefault()
                        handleSave()
                    }}
                >
                    <Grid item xs={4} sx={{ marginTop: 3, paddingRight: 1 }}>
                        <BaseForm
                            config={{ moreSiteSelectorProps: { Required: true } }}
                            value={state}
                            onChange={(v) => v && setState(v)}
                            contentType={EntityScopeEnum.List}
                            errors={{
                                Sites: !state.Sites.length ? 'Sites cannot be empty' : ''
                            }}
                        />

                        <FormControl fullWidth sx={{ my: 1 }}>
                            <TagsSelectorForContent
                                selected={state.Tags || []}
                                disabled={false}
                                tagType={TagType.Content}
                                onChange={(tags) => tags && setState((prev) => ({ ...prev, Tags: tags }))}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item container spacing={2} xs={8}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                variant='standard'
                                error={!!stateErrors?.Name}
                                helperText={stateErrors?.Name}
                                label='Name'
                                style={{ width: '100%' }}
                                value={state.Name || ''}
                                onChange={(v) => {
                                    if (state) {
                                        setState({ ...state, Name: v.target.value })
                                    }
                                    updateStateErrors('Name', '')
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                multiline
                                variant='standard'
                                label='Description'
                                value={state.Description || ''}
                                onChange={(v) => state && setState({ ...state, Description: v.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <StructureSelector
                                error={stateErrors?.StructureID}
                                variant='standard'
                                required
                                disabled={mode === 'edit'}
                                onChange={(v) => {
                                    if (!state) return
                                    setState({ ...state, StructureID: v || '', OverrideSections: [] })
                                    updateStateErrors('StructureID', '')
                                }}
                                value={state.StructureID}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormLabel required error={!!stateErrors?.ContentTypes}>
                                Content Types
                            </FormLabel>
                            <FormGroup row>
                                {availableContentTypes.map((contentType) => {
                                    return (
                                        <FormControlLabel
                                            key={contentType}
                                            control={
                                                <Checkbox
                                                    disabled={disabledContentType(contentType)}
                                                    checked={state && state.ContentTypes.includes(contentType)}
                                                    onChange={(e) => {
                                                        if (!state) return

                                                        const checked = e.target.checked
                                                        const newContentTypes = checked
                                                            ? [...state.ContentTypes, contentType]
                                                            : state.ContentTypes.filter((ct) => ct !== contentType)
                                                        setState({
                                                            ...state,
                                                            ContentTypes: newContentTypes,
                                                            OverrideSections: []
                                                        })

                                                        if (stateErrors?.ContentTypes) {
                                                            updateStateErrors('ContentTypes', '')
                                                        }
                                                    }}
                                                />
                                            }
                                            sx={{ textTransform: 'capitalize' }}
                                            label={contentType}
                                        />
                                    )
                                })}
                            </FormGroup>
                            {!!stateErrors?.ContentTypes ? (
                                <FormHelperText error={true}>{stateErrors?.ContentTypes}</FormHelperText>
                            ) : undefined}
                        </Grid>
                        <Grid item xs={12}>
                            <FormLabel>Overridable Sections</FormLabel>
                            {!state.StructureID && <Alert severity='warning'>Select Structure</Alert>}
                            {!state.ContentTypes.length && <Alert severity='warning'>Select Content type</Alert>}
                            {state.StructureID && !!state.ContentTypes.length && (
                                <>
                                    <SchemaSectionsSelector
                                        id={state.StructureID}
                                        isContentLike={state.ContentTypes.some(isContentLikeContent)}
                                        selected={state.OverrideSections}
                                        disabled={mode === 'edit'}
                                        onSelect={(selected) => {
                                            if (!state) return
                                            setState({ ...state, OverrideSections: selected })
                                        }}
                                    />
                                </>
                            )}
                        </Grid>

                        <Grid item xs={12}>
                            <FormLabel>Template</FormLabel>
                            <CodeEditor
                                value={state.Template || ''}
                                language={'html'}
                                onChange={(v) => state && setState({ ...state, Template: v || '' })}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container display='flex' justifyContent='flex-end'>
                        <Button disabled={loading} onClick={onClose} sx={{ marginRight: 2 }}>
                            Cancel
                        </Button>
                        <Button disabled={loading || !hasChanges} type='submit' variant={'contained'}>
                            {mode === 'edit' ? 'Save changes' : 'Create'}
                        </Button>
                    </Grid>
                </Grid>
                <ConfirmAction
                    open={confirmCloseDialogIsOpen}
                    handleAgree={() => {
                        setConfirmCloseDialogIsOpen(false)
                        onClose()
                    }}
                    handleClose={() => {
                        setConfirmCloseDialogIsOpen(false)
                        onClose()
                    }}
                    handleDisagree={() => {
                        setConfirmCloseDialogIsOpen(false)
                    }}
                    text={'Are you sure you want to exit?'}
                    title={`You have unsaved changes!`}
                />
            </DialogContent>
        </Dialog>
    )
}
