import {
    Alert,
    Box,
    Breakpoint,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid
} from '@mui/material'
import React, { ReactNode, forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react'
import Button from '@mui/material/Button'
import { httpPost, httpPut } from '@/common/client'
import { _isEqual, notify } from '../../../helpers'
import { guessErrorMessage } from '@/helpers/guessErrorMessage'
import { BaseForm } from '../BaseForm'
import { EntityScopeEnum } from '@/pkgs/auth/entityScope'
import TextField from '@mui/material/TextField'
import { StructureSelector } from '@/pkgs/structure/StructureSelector'
import { FragmentDTO } from '@/pkgs/content/types'
import FormRenderer from '../../form-renderer/FormRenderer'
import { FormStructure } from '@/pkgs/structure/types'
import { useContentDetailsQuery } from '@/pkgs/content/queries'
import { z } from 'zod'
import { useAppContext } from '@/pkgs/auth/atoms'
import { useQueryClient } from '@tanstack/react-query'
import { TagsSelectorForContent } from '@/common/components/selectors/TagSelectorForContent'
import { BASE } from '@/common/constants'
import { TagType } from '@/pkgs/system/tags/types'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { ConfirmAction } from '@/common/components'

type FragmentFormProps = {
    value: FragmentDTO | undefined
    onChange: (v: FragmentDTO) => void
    fixedStructureID?: string
    formRef?: any
    errors?: Record<keyof FragmentDTO, string>
    disabled?: boolean
}
export const FragmentForm = ({ value, onChange, fixedStructureID, formRef, errors, disabled }: FragmentFormProps) => {
    const [structure, setStructure] = useState<FormStructure[] | undefined>(undefined)

    // Should this ever happen?
    if (!value) return <></>

    return (
        <Grid container spacing={2} alignItems='flex-start' minHeight={'50rem'}>
            <Grid item xs={4} sx={{ my: 4, paddingRight: 1 }}>
                <BaseForm
                    value={value}
                    onChange={(v) => v && onChange(v)}
                    contentType={EntityScopeEnum.Fragment}
                    errors={errors}
                    disabledFields={disabled}
                />

                <FormControl fullWidth sx={{ my: 1 }}>
                    <TagsSelectorForContent
                        selected={value?.Tags || []}
                        disabled={disabled}
                        tagType={TagType.Content}
                        onChange={(tags) => tags && onChange({ ...value, Tags: tags })}
                    />
                </FormControl>
            </Grid>

            <Grid item container spacing={3} xs={8} sx={{ my: 1 }}>
                <Grid item xs={6}>
                    <FormControl variant='outlined' style={{ width: '100%' }}>
                        <TextField
                            label={'Title'}
                            variant='outlined'
                            style={{ width: '100%' }}
                            value={value?.Title || ''}
                            onChange={(v) => value && onChange({ ...value, Title: v.target.value })}
                            error={!!errors?.Title}
                            disabled={disabled}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <StructureSelector
                        required
                        disabled={!!fixedStructureID || disabled}
                        onChange={(v, s) => {
                            onChange({ ...value, StructureID: v || null })
                            setStructure(s?.FormStructure)
                        }}
                        selectedStructure={(s) => {
                            if (!structure) {
                                setStructure(s.FormStructure)
                            }

                            if (!value?.StructureID) {
                                onChange({ ...value, StructureID: s.ID })
                            }
                        }}
                        value={!!fixedStructureID ? fixedStructureID : value?.StructureID}
                        error={errors?.StructureID}
                    />
                </Grid>

                <Grid item xs={12}>
                    {structure && (
                        <FormRenderer
                            disabled={disabled}
                            ref={formRef}
                            value={value?.Data}
                            onChange={(data) => onChange({ ...value, Data: data })}
                            formStructure={structure}
                        />
                    )}
                </Grid>
            </Grid>
        </Grid>
    )
}

interface EditFragmentHandlers {
    _close: () => void
}

interface EditFragmentProps extends FragmentFormProps {
    id: string
    onClose: () => void
}

export const EditFragment = forwardRef<EditFragmentHandlers, EditFragmentProps>(
    ({ id, onChange, formRef, errors, value, disabled, onClose }: EditFragmentProps, ref) => {
        const result = useContentDetailsQuery(id)
        const [confirmDialogIsOpen, setConfirmDialogIsOpen] = useState(false)

        const formDataHasChanges = useMemo(() => {
            if (!result?.data || !value) return false

            return !_isEqual(result?.data, value)
        }, [result?.data, value])

        useImperativeHandle(ref, () => {
            return {
                _close: () => {
                    if (formDataHasChanges && disabled != true) {
                        setConfirmDialogIsOpen(true)
                    } else {
                        onClose()
                    }
                }
            }
        })
        return (
            <>
                {result.isLoading && <div>Loading...</div>}
                {result.isError && <div>{result.error.message}</div>}
                {result.isSuccess && result.data && (
                    <>
                        <FragmentForm
                            disabled={disabled}
                            value={value || result.data}
                            onChange={onChange}
                            formRef={formRef}
                            errors={errors}
                        />
                        <ConfirmAction
                            open={confirmDialogIsOpen}
                            title={`You have unsaved changes`}
                            text={'Are you sure you want to exit?'}
                            handleClose={() => setConfirmDialogIsOpen(false)}
                            handleDisagree={() => setConfirmDialogIsOpen(false)}
                            handleAgree={() => {
                                setConfirmDialogIsOpen(false)
                                onClose()
                            }}
                        />
                    </>
                )}
            </>
        )
    }
)

interface CreateFragmentProps extends FragmentFormProps {}

export const CreateFragment = ({ value, onChange, fixedStructureID, formRef, errors }: CreateFragmentProps) => {
    const evaluators = useAppContext()
    value = value || {
        Title: '',
        PrivacyLevel: 0,
        Type: 'fragment',
        Sites: evaluators.getDefaultSitesForSelectors(),
        Data: {},
        PublishAt: null,
        ExpireAt: null
    }

    return (
        <FragmentForm
            value={value}
            onChange={(data) => {
                onChange(data)
            }}
            fixedStructureID={fixedStructureID}
            formRef={formRef}
            errors={errors}
        />
    )
}

interface FragmentEditorProps {
    id: string | undefined
    open: boolean
    onClose: () => void
    onSuccessfulSave?: (id: string) => void
    fixedStructureID?: string
    moreDialogActions?: ReactNode
    dialogMaxWidth?: Breakpoint
    disabled?: boolean
    warning?: boolean
}

export const FragmentEditor = ({
    id,
    open,
    onClose,
    onSuccessfulSave,
    fixedStructureID,
    moreDialogActions,
    dialogMaxWidth,
    disabled,
    warning
}: FragmentEditorProps) => {
    const appContext = useAppContext()

    const formRendererRef = React.useRef<any>()
    const [validationErrors, setValidationErrors] = useState<Record<string, string>>({})

    const editFragmentRef = useRef<null | EditFragmentHandlers>(null)

    const [state, setState] = useState<FragmentDTO | undefined>()
    const [loading, setLoading] = useState(false)
    const queryClient = useQueryClient()

    const mode = id ? 'update' : 'create'
    const hasPermission = appContext.actionForEntityScope(EntityScopeEnum.Fragment, mode)

    const handleValidation = () => {
        const errors = { ...validateBase(), ...validateStructure() }
        setValidationErrors(errors)
        return errors
    }

    const validateStructure = (): Record<string, string> => {
        const errors: Record<string, string> = {}
        if (formRendererRef.current) {
            const validationResult = formRendererRef.current?.validate()
            if (validationResult?.length) {
                errors.Structure = 'Fill in all required fields'
            }
        }
        return errors
    }

    const validateBase = (): Record<string, string> => {
        const errors: Record<string, string> = {}
        if (!state) return errors
        if (!state.Title) errors.Title = 'Title is required'
        if (!state.StructureID) errors.StructureID = 'Structure is required'
        if (!state.Sites?.length) errors.Sites = 'Sites is required'
        if (state.PrivacyLevel === undefined) errors.PrivacyLevel = 'Privacy Level is required'
        if (Object.hasOwn(state, 'PublishAt') && state.PublishAt === undefined)
            errors.PublishAt = 'Publish At is required'
        if (Object.hasOwn(state, 'ExpireAt') && state.ExpireAt === undefined) errors.ExpireAt = 'Expire At is required'

        return errors
    }

    const handleSave = () => {
        if (!state) return
        const hasErrors = handleValidation()
        if (Object.keys(hasErrors).length) {
            const errorString = Object.entries(hasErrors)
                .map(([key, value]) => `${key}: ${value}`)
                .join('; ')
            notify(`Please fill in all required fields: ${errorString}`, 'error')
            return
        }

        setLoading(true)

        if (id) {
            httpPut(`${BASE}/api/v2/content/${id}`, { ...state, PageLayout: 'HTML', Settings: {} })
                .then(() => {
                    notify('Saved!', 'info')
                    setLoading(false)
                    onSuccessfulSave && onSuccessfulSave(id)
                    queryClient.removeQueries()
                    onClose()
                })
                .catch((e) => {
                    setLoading(false)
                    notify(guessErrorMessage(e), 'error')
                })
        } else {
            // create
            httpPost(`${BASE}/api/v2/content`, { ...state, PageLayout: 'HTML', Settings: {} }, z.string())
                .then((newID) => {
                    notify(`Fragment created: ${state?.Title || ''}`, 'info')
                    setLoading(false)
                    newID && onSuccessfulSave && onSuccessfulSave(newID)
                    onClose()
                })
                .catch((e) => {
                    setLoading(false)
                    notify(guessErrorMessage(e), 'error')
                })
        }
    }

    const closeFragmentEditor = useCallback(() => {
        if (mode == 'update' && editFragmentRef?.current) {
            return editFragmentRef?.current?._close()
        }

        onClose()
    }, [mode, editFragmentRef?.current])

    return (
        <Dialog
            open={open}
            onClose={closeFragmentEditor}
            fullWidth
            maxWidth={dialogMaxWidth || 'xl'}
            disableEscapeKeyDown={true}
        >
            <DialogTitle>{`${mode.toUpperCase()} ${state?.Title || ''}`}</DialogTitle>
            <IconButton
                aria-label='close'
                onClick={() => {
                    closeFragmentEditor()
                }}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500]
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                {warning && hasPermission && !disabled && (
                    <Alert severity='warning'>
                        You're are editing a fragment that might be used in other places. Your changes will be applied
                        everywhere.
                    </Alert>
                )}
                {id ? (
                    <EditFragment
                        ref={editFragmentRef}
                        id={id}
                        onChange={(v) => {
                            v && setState(v)
                        }}
                        formRef={formRendererRef}
                        errors={validationErrors}
                        value={state}
                        disabled={disabled || !hasPermission}
                        onClose={onClose}
                    />
                ) : (
                    <CreateFragment
                        value={state}
                        onChange={(v) => v && setState(v)}
                        fixedStructureID={fixedStructureID}
                        formRef={formRendererRef}
                        errors={validationErrors}
                        disabled={disabled || !hasPermission}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Box display='flex' flexDirection='column' alignItems='flex-end' gap={1}>
                    {moreDialogActions}
                    <Box display='flex' justifyContent='flex-end' sx={{ gap: '25px' }}>
                        <Button
                            disabled={loading}
                            onClick={() => {
                                closeFragmentEditor()
                            }}
                        >
                            {disabled ? 'Close' : 'Cancel'}
                        </Button>
                        {!disabled && hasPermission && (
                            <Button disabled={disabled || loading} onClick={handleSave} variant='contained'>
                                {disabled ? 'View Only' : mode === 'update' ? 'Save changes' : 'Create'}
                            </Button>
                        )}
                    </Box>
                </Box>
            </DialogActions>
        </Dialog>
    )
}
