import { notify } from '../../helpers'
import { useCurrentSite } from '../auth/atoms'

export async function copyToClipboard(link: string, toastText?: string) {
    if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(link).then(() => notify(toastText || `Copied link:\n "${link}"`, 'info'))
    } else {
        // Browser support fallback
        const element = document.createElement('div')
        element.setAttribute('contentEditable', 'true')
        element.innerHTML = link
        element.setAttribute('onfocus', "document.execCommand('selectAll',false,null)")
        document.body.appendChild(element)
        element.focus()
        document.execCommand('copy')
        document.body.removeChild(element)
    }
}

type useGetLinkToDocumentParams = {
    doc: { id: string; filename: string; type: 'folder' | 'document' }
    fullUrl?: boolean
    by?: 'id' | 'name'
}
export function useGetLinkToDocument() {
    const currentSite = useCurrentSite()

    return ({ doc, fullUrl = false, by = 'id' }: useGetLinkToDocumentParams) => {
        if (!doc || !doc.filename || !doc.id) {
            console.warn('Invalid document object passed to useGetLinkToDocument', doc)
            return '#document-not-found'
        }

        let link = doc.type === 'folder' ? '/folder' : `/documents`
        switch (by) {
            case 'id':
                link = link + `/${doc.id}/${encodeURIComponent(doc.filename)}`
                break
            case 'name':
                link = link + `/${encodeURIComponent(doc.filename)}`
                break
            default:
                throw new Error('Invalid by parameter')
        }

        if (fullUrl) {
            if (!currentSite?.PrimaryDomain) {
                throw new Error(`Unable to generate link to document. Site is missing a primary domain`)
            }

            return new URL(link, 'https://' + currentSite?.PrimaryDomain).href
        }

        return link
    }
}
